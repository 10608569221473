import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MainMenu from '../MenuBottom/MainMenu';
import { BsCameraVideoFill, BsPencilSquare, BsChevronLeft } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import moment from 'moment-timezone';
import axios from 'axios';
import NavBar from '../nav/navBar';
import { IoIosShareAlt } from 'react-icons/io';
import HeaderBackground from '../headerBackground';
import ConferenceStudentsModal from '../../Modals/conferenceStudentsModal';
import Loading2 from '../Loading2';
import { FiEdit } from "react-icons/fi";
import { FaRegEyeSlash } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { Helmet } from 'react-helmet-async';

function Course_Details_Page() {

  const location = useLocation();
  const { card: initialCard, teacher_country } = location.state || {};

  const [card, setCard] = useState(initialCard || {});
  // console.log("card",card,teacher_country)
  //console.log(card)
  // console.log("card",card,teacher_country)

  const { Start_Time, End_Time, Title, URL, Description, Price, Conference_Date, _id } = card;

  //console.log("Original Amount ===>",Price.amount)
  //console.log(_id)
  //const [teacher_zone,setTeacher_zone]=useState(teacher_country)
  const navigate = useNavigate();
  const user_type = Cookies.get('user_type')
  const authToken = Cookies.get('authToken');
  const apiUrl = process.env.REACT_APP_API_URL;
  const [original_amount, setAmount] = useState(0);
  const [tax_amount, setTaxAmount] = useState(0);
  const user_loggedInId = Cookies.get('loggedInUserId')
  const [serviceCharges, setServiceCharges] = useState(0);
  const [GST, setGst] = useState(0);
  const [user_data, setUser_data] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isBooked, setIsBooked] = useState(false);
  const [earning, setEarning] = useState();
  const [earning_id, setEarning_id] = useState();
  const [completed, setCompleted] = useState(false)
  const [isClickable, setIsClickable] = useState(true);
  const baseUrl = window.location.origin;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [showPwd, setShowPwd] = useState(card.hide);

  const [isIndia, setIsIndia] = useState()

  const [loading, setLoading] = useState(true)
  const [pageLoadding, SetPageLoadding] = useState(true)
  const [loadingButton, setLoadingButton] = useState(true)
  useEffect(() => {
    //fetch_Booked_Student();
    fetchUserDetails(setIsIndia);
  }, [isIndia])
  useEffect(() => {
    if (card && card._id) {
      fetch_Booked_Student();
    }
  }, []);
  const fetch_Booked_Student = async () => {
    const students_response = await axios.post(
      `${apiUrl}/api/courses/get_no_students_booked`,
      { course_Id: card._id },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    const No_of_Students = students_response.data
    console.log(No_of_Students)
    const updatedCard = {
      ...card,
      No_of_Students: No_of_Students,
    };

    // Update the state with the new card data
    setCard(updatedCard);
    SetPageLoadding(false)
    console.log("After fetching no of students ", card)
  }
  const fetchUserDetails = async (setIsIndia) => {
    try {

      const response = await axios.get(`${apiUrl}/api/auth/user_details/${user_loggedInId}`);
      const user = response.data.user_Details;
      console.log(user.country)
      if (!user.country) {
        console.error('No country name found in user details');
        return;
      }
      if (user.country === 'India') {
        //console.log(user.country)
        setIsIndia(true);
        //console.log(isIndia)
      } else {
        setIsIndia(false);
      }
      setCompleted(true);
      console.log(completed)
    } catch (error) {
      console.error('Error fetching user details:', error);
    } finally {
      setLoading(false)
    }
  };



  // TOTAL PRICE CALCULATION INCLUDING SERVICE CHARGES AND GST

  // const SERVICE_CHARGE_PERCENTAGE = isIndia ? 0.05 : 0.09; // 5% for indians 9% for other countries   
  const SERVICE_CHARGE_PERCENTAGE =  0.09;   
  const GST_PERCENTAGE = isIndia ? 0.18 : 0.15; // 18%     

  const calculateServiceCharge = (amount) => {
    //console.log("modified amount ==>",amount * SERVICE_CHARGE_PERCENTAGE)
    setAmount(amount)
    return Math.ceil(amount * SERVICE_CHARGE_PERCENTAGE);

  };

  const calculateGST = (amount) => {
    //setGst(amount * GST_PERCENTAGE)
    return Math.ceil(amount * GST_PERCENTAGE);
  };


  // const updateButtonState = (Course_Start_Date, Start_Time) => {
  //   console.log("Date and time ==>", Course_Start_Date, Start_Time)
  //   if (!Start_Time || typeof Start_Time !== 'string') {
  //     console.error('Invalid Start_Time:', Start_Time);
  //     return; // Exit early if Start_Time is not valid
  //   }
  //   const startHourMinute = Start_Time.split(':');
  //   const formattedDate = moment(Course_Start_Date).format('YYYY/MM/DD');
  //   console.log(formattedDate)
  //   const now = new window.Date(formattedDate);

  //   const start = new window.Date(
  //     now.getFullYear(),
  //     now.getMonth(),
  //     now.getDate(),
  //     parseInt(startHourMinute[0]), // hours
  //     parseInt(startHourMinute[1]), // minutes
  //     0, // seconds
  //     0 // milliseconds
  //   ).getTime();

  //   // console.log("Start ==> ", start, new window.Date(start).toLocaleString());

  //   const fiveMinutes = 5 * 60 * 1000;
  //   const tenMinutes = 20 * 60 * 1000;
  //   const currentTime = now.getTime();

  //   console.log("Current Time ==> ", currentTime, now.toLocaleString());

  //   console.log("Time range ==> ", start - fiveMinutes, " to ", start + tenMinutes,
  //     new window.Date(start - fiveMinutes).toLocaleString(),
  //     " to ",
  //     new window.Date(start + tenMinutes).toLocaleString());

  //   if (currentTime >= start - fiveMinutes && currentTime <= start + tenMinutes) {
  //     setIsActive(true);
  //     console.log("Button is active: true");
  //   } else {
  //     setIsActive(false);
  //     console.log("Button is active: false");
  //   }

  // };
  const updateButtonState = (Course_Start_Date, Start_Time) => {
    console.log("Original Date and time ==>", Course_Start_Date, Start_Time);

    if (!Start_Time || typeof Start_Time !== 'string') {
      console.error('Invalid Start_Time:', Start_Time);
      return; 
    }

    
    const formattedDate = moment(Course_Start_Date).format('YYYY/MM/DD');
    console.log("Formatted Course_Start_Date:", formattedDate);

    
    const startDateTimeString = `${formattedDate} ${Start_Time}`;
    const startDateTime = new Date(startDateTimeString);

    if (isNaN(startDateTime.getTime())) {
        console.error('Invalid startDateTime:', startDateTimeString);
        return; 
    }

    
    console.log("Start DateTime:", startDateTime, startDateTime.toLocaleString());

    const fiveMinutes = 5 * 60 * 1000;
    const tenMinutes = 10 * 60 * 1000;
    const currentTime = new Date().getTime();

    console.log("Current Time ==> ", currentTime, new Date(currentTime).toLocaleString());

    const start = startDateTime.getTime();

    console.log("Time range ==> ", start - fiveMinutes, " to ", start + tenMinutes,
      new Date(start - fiveMinutes).toLocaleString(),
      " to ",
      new Date(start + tenMinutes).toLocaleString());

    if (currentTime >= start - fiveMinutes && currentTime <= start + tenMinutes) {
      setIsActive(true);
      console.log("Button is active: true");
    } else {
      setIsActive(false);
      console.log("Button is active: false");
    }
};

  const formatDateString = (dateString) => {

    const eventDate = moment.utc(dateString);
    const formattedDate = eventDate.format('MMM DD - YYYY');
    // console.log(`Formatted Date in UTC: ${formattedDate}`); 
    return formattedDate;
  };

  const checkIfStudentBooked = async (courseId, studentId) => {
    try {
      setLoading(true)
      const response = await axios.post(`${apiUrl}/api/courses/checkBooking`, { courseId, studentId }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      const data = await response.data;
      //console.log(data)
      return data;
    } catch (error) {
      console.error('Error checking if student booked:', error);
      return false;
    } finally {
      setLoadingButton(false)
    }
  };

  useEffect(() => {
    fetch_user_Details();
    fetch_earning_details(card._id);
    const conversionRate = 83; // Example conversion rate: 1 USD = 83 INR
    const convertINRtoUSD = (amount) => {
      return Math.ceil(amount / conversionRate);
    };

    const serviceChargeInINR = calculateServiceCharge(card.Price.Fixed_Amount);
    setServiceCharges(serviceChargeInINR);
    console.log("service charge", serviceChargeInINR)
    let amountInLocalCurrency = Number(card.Price.Fixed_Amount);
    console.log("amountInLocalCurrency", amountInLocalCurrency)
    let serviceCharge = serviceChargeInINR;
    //console.log(isIndia,teacher_country)
    if (!isIndia && teacher_country === 'India') {

      amountInLocalCurrency = convertINRtoUSD(amountInLocalCurrency);
      //console.log("Converted one",amountInLocalCurrency)
      console.log("amountInLocalCurrency", amountInLocalCurrency)
      serviceCharge = convertINRtoUSD(serviceChargeInINR);
      console.log("serviceCharge", serviceCharge)
    }

    const updated_amount = amountInLocalCurrency + serviceCharge + card.Price.paypal_fee;
    console.log("updated_amount", updated_amount)
    const gst = calculateGST(updated_amount);
    setGst(gst);
    console.log("gst", gst)
    // Calculate total amount
    console.log(amountInLocalCurrency, serviceCharge, gst)
    const totalAmount = Math.ceil(Number(amountInLocalCurrency) + Number(serviceCharge) + Number(gst) + card.Price.paypal_fee);
    console.log("Total Amount", totalAmount)
    setTaxAmount(totalAmount);
    // console.log(card.Price.paypal_fee)
    // console.log(teacher_country)
    //  console.log("Tax Amount ==>",tax_amount)

    const checkBooking = async () => {
      console.log(card._id)
      const booked = await checkIfStudentBooked(card._id, user_loggedInId);
      setIsBooked(booked.status);
      // console.log("Status",booked)
      // console.log(isBooked)
    };

    if (user_type === 'Student') {
      checkBooking();
    }
    updateButtonState(card.Start_Date, card.Start_Time);

    const interval = setInterval(() => {
      console.log(card.Start_Date, card.Start_Time);
      updateButtonState(card.Start_Date, card.Start_Time);
    }, 60000);


    return () => clearInterval(interval);

  }, [isIndia, completed]);

  const fetch_earning_details = async (courseId) => {
    try {
      // console.log("Fetching earnings details")
      //const course_Id=courseId;
      // console.log(conference_Id)
      const earnings_details = await axios.post(`${apiUrl}/api/earningCourse/fetch_earning_details`, { course_Id: courseId }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      })
      // console.log("earnings ===>",earnings_details.data)
      if (earnings_details.data) {
        setEarning(earnings_details.data);
        let earning_ids;
        if (earnings_details.data.length > 1) {
          earning_ids = earnings_details.data.map((earning) => {
            return earning._id
          })
        }
        else {
          earning_ids = [earnings_details.data[0]._id];
        }
        setEarning_id(earning_ids);
        // console.log(earning_id)
      } else {
        // console.log('No Earnings');
        setEarning(null);
        setEarning_id(null);
      }
    } catch (error) {
      console.error('Error fetching earning details:', error);
    }
  }
  //console.log("Total Amount ==>",totalAmount)

  const fetch_user_Details = async () => {
    try {

      const user_details = await axios.get(`${apiUrl}/api/auth/user_details/${user_loggedInId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      })
      const user = user_details.data.user;
      // console.log(user);
      // console.log("Name",user.fullName)
      // console.log("Eamil",user.email)
      setUser_data(user);


    } catch (error) {
      console.error('Server Error:', error.response.data);
    }
  }
  // BACK NAVIGATION BASE ON LOGGED IN USER TYPE

  const handleback = (e) => {
    if (user_type === 'Teacher') {
      navigate('/calender_page');
    }
    else {
      navigate('/cabinet');
    }

  }

  // FUNCTION TO BOOK A CONFERENCE WHEN LOGGED IN USER TYPE IS STUDENT
  const handlePayment = async (st, et, start_date, end_date, courseId, Currency, currencyCode, original_amount) => {
    console.log("Inside invoice")
    if (original_amount === '0') {
      const Id = courseId;
      // const slot_id=id;
      // await bookConference(Id)
    } else {
      const conversionRate = 83;
      const convertINRtoUSD = (amount) => {
        return amount / conversionRate;
      };

      const serviceChargeInINR = calculateServiceCharge(card.Price.Fixed_Amount);
      setServiceCharges(serviceChargeInINR);

      let amountInLocalCurrency = Number(card.Price.Fixed_Amount);
      let serviceCharge = serviceChargeInINR;
      console.log(isIndia, teacher_country)
      if (!isIndia && teacher_country === 'India') {
        //console.log("Student is not Indian")
        amountInLocalCurrency = Math.ceil(convertINRtoUSD(amountInLocalCurrency));
        serviceCharge = convertINRtoUSD(serviceChargeInINR);
      }

      const updated_amount = amountInLocalCurrency + serviceCharge + card.Price.paypal_fee;

      const gst = calculateGST(updated_amount);
      setGst(gst);
      console.log(teacher_country)
      const paypal_fee = (isIndia && teacher_country === 'India') ? card.Price.paypal_fee : 0.30
      //  console.log("paypal_fee inside invoice",paypal_fee)
      //   console.log(amountInLocalCurrency , serviceCharge , gst,paypal_fee)
      //   console.log(amountInLocalCurrency + serviceCharge + gst+paypal_fee)
      const totalAmount = Math.ceil(amountInLocalCurrency + serviceCharge + gst + paypal_fee);
      //console.log(totalAmount)
      setTaxAmount(totalAmount);

      const formatToTwoDecimalPlaces = (amount) => {
        return parseFloat(amount).toFixed(2);
      };

      const formattedServiceCharge = formatToTwoDecimalPlaces(serviceCharge);
      const formattedCallPrice = formatToTwoDecimalPlaces(amountInLocalCurrency);

      console.log(card)

      const invoice_details = {
        Name: user_data.fullName,
        Email: user_data.email,
        Start_Time: st,
        End_Time: et,
        Date: start_date,
        End_Date: end_date,
        call_Id: courseId,
        Service_Charges: formattedServiceCharge,
        GST: gst,
        Call_Price: (!isIndia && teacher_country === 'India') ? amountInLocalCurrency : Number(card.Price.Fixed_Amount),
        Total_amount: totalAmount,
        Type: "Course Details",
        PaymentType: "Course",
        Currency: (isIndia === false && teacher_country === 'India') ? '$' : Currency,
        CurrencyCode: (isIndia === false && teacher_country === 'India') ? 'USD' : currencyCode,
        paypal_fee: paypal_fee
      };

      console.log(invoice_details);

      navigate("/invoice-details", { state: { invoice_details } })
    }
  };


  //   const bookConference = async (Conference_Id) =>{
  //     const response = await axios.post(`${apiUrl}/api/conference/${Conference_Id}/conference_booked`,
  //     {studentId:user_loggedInId,paymentId:'Free',amountPaid:0},
  //     {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //         'Content-Type': 'application/json',
  //       },
  //     }
  //     );
  //     const data= response.data
  //       // console.log("Conference Booked Details ===>",data)
  //     if (response.status === 200) {
  //       // console.log('Haii...')
  //       await conference_booked(response.data.conferenceId)

  //     }

  //     navigate('/cabinet');

  // };
  //   const conference_booked = async (id) => {
  //     try {
  //       // console.log(id ,"conf id")
  //         const response = await axios.post(`${apiUrl}/api/conference/book_conference/${id}`, {}, {
  //             headers: {
  //                 Authorization: `Bearer ${authToken}`,
  //                 'Content-Type': 'application/json',
  //             },
  //         });
  //         const data = response.data;
  //         // console.log(data);
  //         navigate('/cabinet');
  //     } catch (error) {
  //         console.error('Failed to book conference:', error);
  //     }
  // }


  //   const CalculateAmount = (amount,paypal_fee) => {
  //     const serviceCharge = calculateServiceCharge(Number(amount));
  //     //console.log("service Charges ==> ",serviceCharge)
  //     //const updated_amount = Number(amount) + serviceCharge
  //     //console.log("updated amount ==> ",updated_amount)
  //     const gst = calculateGST(Number(amount));
  //     //console.log("GST ==>",gst)
  //     const totalAmount = Math.round(Number(amount) + serviceCharge + gst+Number(paypal_fee));
  //     //console.log("Total Amount ==>",totalAmount)
  //     return totalAmount
  // }

  const handleMeeting = async () => {
    try {

      const ids = Array.isArray(earning_id) ? earning_id : [earning_id];
      console.log(ids)

      const statusRequests = ids.map(id =>
        axios.put(`${apiUrl}/api/earningCourse/change_meeting_status`, { earning_id: id }, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        })
      );


      const responses = await Promise.all(statusRequests);
      console.log('Status updates:', responses);
    } catch (error) {
      console.error('Error updating meeting status:', error);
    }
  };

  useEffect(() => {
    const checkAvailability = () => {
      const courseStart = moment(card.Start_Date).set({
        hour: Start_Time.split(':')[0],
        minute: Start_Time.split(':')[1],
        second: 0,
        millisecond: 0,
      });

      const courseEnd = moment(card.End_Date).set({
        hour: Start_Time.split(':')[0],
        minute: Start_Time.split(':')[1],
        second: 0,
        millisecond: 0,
      });

      const now = moment();

      if (now.isSameOrAfter(courseEnd)) {
        setIsClickable(false);
      } else {
        setIsClickable(true);
      }
    };

    checkAvailability();
    const interval = setInterval(checkAvailability, 60000);

    return () => clearInterval(interval);
  }, [card.Conference_Date, Start_Time, End_Time]);


  const handleshare = async () => {
    if (isClickable) {
      if (navigator.share) {

        navigator.share({
          title: 'Check out this Course Details!',
          text: 'Course Details',
          url: `${baseUrl}/course_details/${card._id}`,
        })
          .then(() => console.log('Shared successfully'))
          .catch((error) => console.error('Error sharing:', error));
      } else {
        alert('Sharing is not supported on this browser.');
      }
    }
  };


  const ShowStudentDetails = (event) => {
    console.log("students", event.No_of_Students.students)
    event.No_of_Students.students.map((student, index) => {
      // console.log(`Student ${index + 1}:`);
      // console.log('Student Details ===>', student.studentDetails)
      // console.log('User Details ==>', student.userDetails)

      // Add more fields as needed
    });
    setModalData(event.No_of_Students.students);
    setIsModalOpen(true);
  }

  const handleEdit = (card) => {
    navigate('/edit-course', { state: { card } })
  }

  const handleHide = async (card) => {
    try {
      const hideStatus = !showPwd; // Toggle the current hide status
      setShowPwd(hideStatus); // Update the state to reflect the new hide status

      const response = await axios.post(
        `${apiUrl}/api/courses/hide_course/${card._id}`,
        { hideStatus }, // Send hideStatus as a boolean
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log(response.data.message); // Log the success message
    } catch (error) {
      console.error('Error toggling course visibility:', error.response?.data || error.message);
    }
  };


  if (pageLoadding) {
    return <Loading2 />
  }

  return (

    <section class="mb-24 sm:mb-0 font-primary">
      <Helmet>
        <title>Course Details - Career Guidance Buddy</title>
        <meta name="keywords" content="chat with student,chat with teacher, education, online class" />
      </Helmet>
      <NavBar />
      {/* <MetaDecorator title={Title} description={card.Description} imageUrl={conference} keywords={keywords} imageAlt={'Not found'} url={`${baseUrl}/conferece_details/${card._id}`}/> */}
      <div class='sm:mt-12 lg:mt-14 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 text-[#585858]'>
        <div class="sm:grid sm:grid-cols-12 flex flex-col gap-6 sm:min-h-[85vh] sm:py-[55px] justify-center">

          {/* ------------------------- COL SPAN - 1  --------------------------- */}

          <div class="col-span-5 ">

            {/* ------------------------------ HEADER OF THE PAGE ----------------------------- */}
            <HeaderBackground />
            {/* --------------------- NAVIGATING BACK FUNCTIONALITY --------------------------------- */}

            <div className='mt-44 mb-4 px-4 sm:px-0 sm:mt-0'>
              <div style={{ color: '#AFAFAF' }} className='flex items-center gap-4 cursor-pointer' onClick={handleback} >
                <BsChevronLeft />
                <div style={{ fontWeight: 600 }} >Back</div>
              </div>
            </div>

            {/* ---------------------------------- CONFERENCE SHORT DETAILS LIKE TIMINGS, TITLE AND DATE ----------------------- */}

            <div className="flex sm:justify-center">
              <div className="rounded-[20px] bg-white drop-shadow-md mx-2 p-[15px_10px_15px_15px] lg:p-5 ">
                {user_type === 'Teacher' ? (
                  <div className='flex justify-between items-center gap-4'>
                    <div className="flex gap-4">
                      <div className="font-semibold mb-1 ml-2">{formatDateString(card.Start_Date)}</div>
                      {/*<div className="edit" style={{ fontWeight: 600, color: '#AFAFAF' }}>Edit</div>*/}
                      <div className="font-semibold mb-1 ">-</div>
                      <div className="font-semibold mb-1 ">{formatDateString(card.End_Date)}</div>
                    </div>
                    <div className=' flex gap-4 items-center'>
                      <FiEdit style={{ fontSize: '1.5rem' }} onClick={() => handleEdit(card)} className=' cursor-pointer' />
                      {/* <FaBookmark style={{fontSize:'1.5rem'}} onClick={()=>handleHide(card)} className=' cursor-pointer'/> */}
                      <div className="flex items-center cursor-pointer">
                        {showPwd === true ? (
                          <FaRegEyeSlash
                            style={{ fontSize: '1.5rem' }}
                            className=" cursor-pointer"
                            onClick={() => handleHide(card)}
                            title="Un-Hide"
                          />
                        ) : (
                          <MdOutlineRemoveRedEye
                            style={{ fontSize: '1.5rem' }}
                            className="cursor-pointer"
                            onClick={() => handleHide(card)}
                            title="Hide"
                          />
                        )}
                      </div>
                    </div>
                  </div>) : (
                  <div className="flex gap-4">
                    <div className="font-semibold mb-1 ml-2">{formatDateString(card.Start_Date)}</div>
                    {/*<div className="edit" style={{ fontWeight: 600, color: '#AFAFAF' }}>Edit</div>*/}
                    <div className="font-semibold mb-1 ">-</div>
                    <div className="font-semibold mb-1 ">{formatDateString(card.End_Date)}</div>
                  </div>
                )}

                <p className="bg-[#D1FFD0] w-1/2 px-2 py-2 text-center rounded-[20px] font-montserrat font-semibold mb-1 mt-4">Time: {`${Start_Time}`}</p>
                {card.image[0] && <img src={card.image[0].url} alt="course" className="w-[400px] h-[200px] border object-fit rounded-lg mt-2" />}
                {/* <div className="meeting-heading font-medium mt-2 ml-2 text-gray-700">{Title}</div> */}

              </div>
            </div>
          </div>

          {/* -------------------------------------- COL SPAN - 2 ------------------------- */}

          <div class="col-span-7 mx-2 sm:mx-0 bg-white drop-shadow-lg h-fit rounded-[20px] sm:mt-10 p-4 sm:p-3">


            {/* ------------------------------ PRICE FOR THE CONFERENCE ------------------------------------- */}

            <div className='flex justify-end'>
              {/* <div class="mt-4 border-2 border-gray-400 rounded-full px-5 py-2 text-center w-[150px]">
            {user_type === 'Student'?(
              <div>Price : {!isIndia && teacher_country==='India' ? '$' :(card.Price.currency)} {tax_amount}</div>
            ):(
            
              <div>Price : {card.Price.currency} {card.Price.Fixed_Amount}</div>
            )}
            
          </div> */}
              <div
                className={`border-2 border-orange-600 rounded-3xl py-1 px-6 bg-transparent text-orange-500 font-semibold hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 cursor-pointer flex justify-center gap-2 items-center shadow-lg  ${isClickable ? '' : 'pointer-events-none opacity-50'}`}
                onClick={handleshare}
              >
                Share Course
                <IoIosShareAlt style={{ fontSize: '1.5rem' }} />
              </div>
            </div>


            {/* ------------------------------ CONFERENCE DETAILS ------------------------------- */}

            <div class="card  sm:shadow-none py-[15px] sm:px-3 sm:px-0 overflow-y-auto">
              <div>

                {/* ------------------------------- DESCRIPTION FOR THE CONFERENCE ------------------------- */}
                <div className="meeting-heading font-semibold mt-2  text-[#585858]">{Title}</div>
                {/* <div className='font-medium text-[#585858] mt-3'>Description :</div> */}
                <div className='text-[#585858] mb-4 mt-1'>{card.Description}</div>

                {/* --------------------------------------- REMAINDER TIME -------------------------------- */}

                <div className='flex gap-4 items-center mb-2'>
                  <BsPencilSquare className='text-orange-500 text-xl' />
                  <div className='text-gray-600'>Reminder 30min before</div>
                </div>

                <div className="mt-4 border-2 border-gray-400 rounded-full px-5 py-2 text-center w-[150px]">
                  {user_type === 'Student' ? (
                    loading && completed ? (
                      <div
                        className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-orange-500"
                        role="status"
                      >
                      </div>
                    ) : (
                      <div>
                        Fee : {!isIndia && teacher_country === 'India' ? '$' : card.Price.currency} {tax_amount}
                      </div>
                    )
                  ) : (
                    <div>
                      Fee : {card.Price.currency} {card.Price.Fixed_Amount}
                    </div>
                  )}
                </div>


                {/* --------------- BASED ON THE LOGGED IN USER SHOW BUTTON TO START OR BOOK CONFERENCE ------------------------ */}

                {user_type === 'Student' && (
                  loadingButton ? (
                    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-orange" role="status"></div>
                  ) : (
                    isBooked === true ? (

                      <a
                        href={isActive ? URL : ""}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center justify-center w-56 sm:w-64 px-0 py-2.5 bg-gradient text-white text-center rounded-full gap-3.5 mt-2 ${!isActive ? 'opacity-50 pointer-events-none' : ''}`}
                      >
                        <BsCameraVideoFill />
                        Start Meeting {isBooked}
                      </a>
                    ) : (
                      <div
                        className="inline-flex items-center justify-center w-56 sm:w-64 w-64 px-0 py-2.5 bg-gradient text-white text-center rounded-full gap-3.5 mt-2 cursor-pointer"
                        onClick={() => handlePayment(card.Start_Time, card.End_Time, card.Start_Date, card.End_Date, card._id, card.Price.currency, card.Price.Currency_Code, card.Price.Fixed_Amount)}
                      >
                        <BsCameraVideoFill />
                        Enroll Now
                      </div>
                    )
                  )
                )}


                {user_type === 'Teacher' ? (
                  earning_id ? (
                    <div className='flex flex-col sm:flex-row gap-4'>
                      <a
                        href={isActive ? URL : ""}

                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center justify-center w-56 sm:w-64 px-0 py-1.5 sm:py-2.5 bg-gradient text-white text-center rounded-full mt-2 gap-3.5 ${!isActive ? 'opacity-50 pointer-events-none' : ''}`}
                        onClick={earning_id && isActive ? handleMeeting : undefined}
                      >
                        <BsCameraVideoFill />
                        Start Meeting
                      </a>
                      <div className='flex justify-center items-center w-56 sm:w-64 px-0 py-1.5 sm:py-2 bg-gradient text-white text-center rounded-full cursor-pointer mt-2' title='Students List' onClick={() => ShowStudentDetails(card)}>
                        No. of Students Booked: {card.No_of_Students.studentCount}
                      </div>
                    </div>

                  ) : (
                    <div className="ml-[13%] lg:ml-52 text-gray-500">
                      No student booked the course
                    </div>
                  )
                ) : null
                }
                {/* {user_type === 'Student' ?(
                    <>
                     <div
                         className="inline-flex items-center justify-center w-64 px-0 py-2.5 bg-gradient-to-b from-orange-600 to-yellow-400 text-white text-center rounded-full ml-[13%] gap-3.5 cursor-pointer"
                         onClick={() => handlePayment(card.Start_Time, card.End_Time, card.Start_Date,card.End_Date, card._id, card.Price.currency, card.Price.Currency_Code,card.Price.Fixed_Amount)}
                      >
                        <BsCameraVideoFill />
                        Book Now
                       </div>
                    </>
                ):(
                    <>
                    
                      <a
                        // href={isActive ? URL : ""}
                        href = {URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className='inline-flex items-center justify-center w-64 px-0 py-2.5 bg-gradient-to-b from-orange-600 to-yellow-400 text-white text-center rounded-full ml-[13%] gap-3.5 lg:ml-52'
                        onClick={ handleMeeting }
                      >
                        <i className="fas fa-video"></i>
                        Start Meeting 
                      </a>
                    </>
                )} */}

              </div>
            </div>
          </div>

          {/* --------------------------- BOTTOM MENU ---------------------------------- */}

          <div className='flex justify-center'>
            <MainMenu />
          </div>
        </div>
      </div>
      <ConferenceStudentsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        students={modalData} />
    </section>

  );
}

export default Course_Details_Page;

