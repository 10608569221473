import React from 'react'
import background from '../assets/img/background-3.png';
import logo from '../assets/img/logo.svg';

const headerBackground = () => {
    return (
        <div className="sm:hidden bg-cover bg-top bg-no-repeat w-screen h-[185px] min-w-screen fixed left-0 top-0 z-50 sm:mx-auto sm:w-full"
        style={{ backgroundImage: `url(${background})`, width: '100%' }}>
                <div className="flex justify-center items-center pt-[42px]">
                    <img className="w-[189px]" src={logo} alt="logo" />
                </div>
            </div>
    )
}

export default headerBackground