// import Cookies from 'js-cookie'; // Import js-cookie
// import { createContext, useState, useEffect, useContext } from 'react';
// import { io } from 'socket.io-client';

// const SocketContext= createContext();

// export const useSocket=()=>{
//     return useContext(SocketContext)
// }

// export const SocketContextProvider=({children})=>{
//     const url = process.env.REACT_APP_API_URL;
//     const [socket, setSocket]= useState(null);
//     const [onlineUsers, setOnlineUsers]=useState([]);
//     const loggedInUserId = Cookies.get('loggedInUserId');
    
//     // console.log(loggedInUserId)
//     useEffect(()=>{
//         const socket=io(`${url}`,{
//             query:{
//                 userId:loggedInUserId
//             },
//             // transports: ['websocket'],
//         });
//         setSocket(socket);

//         socket.on("getOnlineUsers",(users)=>{
//             setOnlineUsers(users);
//         })
        

//         return () => socket && socket.close();
//     },[loggedInUserId])
//     console.log("Online users",onlineUsers);
//     return(
//         <SocketContext.Provider value={{socket, onlineUsers}}>
//             {children}
//         </SocketContext.Provider>
//     )
// }
import Cookies from 'js-cookie';
import { createContext, useState, useEffect, useContext } from 'react';
import { io } from 'socket.io-client';

const SocketContext = createContext();

export const useSocket = () => {
    return useContext(SocketContext);
};

export const SocketContextProvider = ({ children }) => {
    const url = process.env.REACT_APP_API_URL;
    const [socket, setSocket] = useState(null);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const loggedInUserId = Cookies.get('loggedInUserId');

    useEffect(() => {
        
            const socket = io(`${url}`, {
                query: {
                    userId: loggedInUserId
                },
            });
            setSocket(socket);

            socket.on("getOnlineUsers", (users) => {
                setOnlineUsers(users);
            });

            
            socket.on("unreadCount", (count) => {
                setUnreadCount(count);
            });
            
            return () => socket && socket.close();
        
    }, [loggedInUserId]);

    // console.log("Online users", onlineUsers);
    // console.log("Unread messages", unreadCount);

    return (
        <SocketContext.Provider value={{ socket, onlineUsers, unreadCount, setUnreadCount }}>
            {children}
        </SocketContext.Provider>
    );
};
