import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import bg from '../assets/img/background-2.png';
import logo from '../assets/img/logo.svg';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaRegEyeSlash, FaLock } from "react-icons/fa"
import axios from "axios";
import desktop_logo from "../assets/img/desktop_logo.svg";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import MetaDecorator from "../utils/MetaDecorator";
const apiUrl = process.env.REACT_APP_API_URL;
const ResetPassword = ({ setMessage, setShowMessage }) => {
  const initialData = {
    new_password: '',
    confirm_password: ''
  }
  const [userData, setData] = useState(initialData);
  const [showPwd, setShowPwd] = useState(false)
  const [showConfirmPwd, setShowConfirmPwd] = useState(false)
  const [loading, setLoading] = useState(false);
  const { token } = useParams(); // Assuming you have a route parameter named 'token' for the reset password token
  const navigate = useNavigate();
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (userData.new_password !== userData.confirm_password) {
      alert("Passwords do not match");
      return;
    }
    console.log(userData)
    try {
      setLoading(true);
      // console.log('Token:', token); // Log the token value for debugging
      const response = await axios.post(`${apiUrl}/api/auth/reset-password/${token}`, {
        password: userData.new_password
      });

      setShowMessage(true);
      setMessage("Password reset successful! Login to continue");
      alert('Password reset successful! Login to continue')
      // console.log(response.data); // Log the response data for debugging or handling success messages
      navigate("/login");
    } catch (error) {
      console.error('Error resetting password:', error);
      if (error.response) {
        console.log('Server responded with:', error.response.data);
      }
      // Handle the error as per your application's requirements
    }
    finally {
      setLoading(false);
    }
  };

  const keywords = ["elearning", "education", "online class", "education forum", "education","student","teacher","learning"]; 
  return (
    <>
    <MetaDecorator title={'Reset password - Career Guidance Buddy'} description={''} imageUrl={''} keywords={keywords} imageAlt={''} url={''}/>
      <div className="sm:h-[100vh] sm:w-full sm:flex items-center justify-center sm:px-5 font-primary">
        <div className="sm:grid grid-cols-2 sm:h-[688px] sm:mx-auto max-w-2xl sm:max-w-[1080px] sm:shadow-md sm:rounded-[50px]">
          <div
            className="sm:hidden bg-contain bg-no-repeat w-full h-[280px] sm:mx-auto sm:w-full sm:max-w-md max-[415px]:bg-cover"
            style={{ backgroundImage: `url("${bg}")` }}
          >
            <div className="pl-9 pt-16 max-w-[433px]">
              <img src={logo} alt="logo" />
              <div className="pt-8 text-white">
                <h1 className="text-2xl font-medium">Reset Password</h1>
              </div>
            </div>
          </div>
          <div className="bg-gradient sm:h-full hidden sm:flex flex-col justify-center items-center sm:rounded-s-[50px]">
            <div className="flex justify-center items-center mx-auto w-full">
              <img src={logo} alt="logo" className="sm:hidden w-6/12" />
              <img
                src={desktop_logo}
                alt="logo"
                className="hidden sm:block w-10/12"
              />
            </div>
            <div className="pt-4 sm:pt-[50px] md:pt-[80px] lg:pt-[136px]">
              <h3 className="text-center text-white text-lg md:text-2xl font-semibold">
                Start your journey to the
              </h3>
              <h3 className="text-center text-white text-lg md:text-2xl font-semibold pb-6 sm:pb-20">
                world of learning.
              </h3>
              <div className="fixed sm:relative sm:flex sm:flex-col sm:items-center sm:mx-auto sm:justify-center sm:mt-0 mt-[540px] sm:text-center sm:text-white text-sm md:text-xl pb-6">
                <div className="flex flex-row w-full justify-center gap-4 text-[#585858] text-[14px] mb-2 sm:mb-1">
                  <Link
                    to="/terms-and-conditions"
                    className="sm:text-[16px] text-center sm:text-white"
                  >
                    Terms & Conditions
                  </Link>
                  <Link to="/userAgreement" className="sm:text-[16px] text-center sm:text-white">
                    User Agreement</Link>

                </div>
                <div className="flex flex-row gap-4 justify-center text-[#585858] text-[14px]">
                  <Link
                    to="/paymentPolicy"
                    className="sm:text-[16px] text-center sm:text-white"
                  >
                    Payment Policy
                  </Link>
                  <Link to="/refundPolicy" className="sm:text-[16px] text-center sm:text-white">
                    Refund Policy
                  </Link>

                  <Link to="/privacyPolicy" className="sm:text-[16px] text-center sm:text-white">
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="p-3 w-full mt-[-90px] sm:h-full flex flex-col items-center sm:gap-24 sm:justify-center">

            <div className="mx-auto w-full max-w-[480px] md:max-w-auto sm:w-full lg:w-10/12 mt-[10px] sm:mt-0">
              <Link to="/forgot-password" className="">
                <div className="flex mx-3 sm:mb-20 sm:mt-0 mt-10 items-center justify-start gap-1">
                  <IoArrowBackCircleOutline size={24} className="w-5 h-5 sm:w-6 sm:h-6 text-[#afafaf]" />
                  <span className="text-[14px] sm:text-[20px] text-[#afafaf] align-middle text-left">Back</span>
                </div>
              </Link>
              <h1 className="hidden sm:block text-[40px] sm:mb-8 font-semibold text-center text-gradient leading-[40px]">
                Reset Password
                <br />

              </h1>
              <form className="space-y-6" action="#" method="POST">
                <div>
                  <label
                    htmlFor="new_password"
                    className="block text-sm font-semibold text-[#585858] pl-4"
                  >New Password
                  </label>
                  <div className="relative mt-1">
                    <input
                      id="new_password"
                      name="new_password"
                      type={showPwd === false ? "password" : "text"}
                      value={userData.new_password}
                      onChange={(e) => setData({ ...userData, new_password: e.target.value })}
                      required
                      className="pl-10 pr-4 py-3 border border-[#585858] text-sm w-full rounded-full"
                    />
                    <div
                      className="absolute inset-y-0 left-0 pl-[15px] flex items-center pointer-events-none"
                    >
                      <FaLock color="#808080" />
                    </div>
                    <div
                      className="absolute inset-y-0 right-0 pr-[10px] flex items-center cursor-pointer"
                    >
                      {
                        showPwd === false ? (
                          <FaRegEyeSlash color="#808080" onClick={() => setShowPwd(true)} />
                        ) : (
                          <MdOutlineRemoveRedEye color="#808080" onClick={() => setShowPwd(false)} />
                        )
                      }

                    </div>
                  </div>

                </div>
                <div>
                  <label
                    htmlFor="confirm_password"
                    className="block text-sm font-semibold text-[#585858] pl-4"
                  >Confirm New password
                  </label>
                  <div className="relative mt-1">
                    <input
                      id="confirm_password"
                      name="confirm_password"
                      type={showConfirmPwd === false ? "password" : "text"}
                      value={userData.confirm_password}
                      onChange={(e) => setData({ ...userData, confirm_password: e.target.value })}
                      required
                      className="pl-10 pr-4 py-3 border border-[#585858] text-sm w-full rounded-full"
                    />
                    <div
                      className="absolute inset-y-0 left-0 pl-[15px] flex items-center pointer-events-none"
                    >
                      <FaLock color="#808080" />

                    </div>

                    <div
                      className="absolute inset-y-0 right-0 pr-[10px] flex items-center cursor-pointer"
                    >
                      {
                        showConfirmPwd === false ? (
                          <FaRegEyeSlash color="#808080" onClick={() => setShowConfirmPwd(true)} />
                        ) : (
                          <MdOutlineRemoveRedEye color="#808080" onClick={() => setShowConfirmPwd(false)} />
                        )
                      }
                    </div>

                  </div>

                </div>

                <div className='flex justify-center'>
                  <button type="button" className="flex w-[273px] justify-center rounded-full text-white p-[10px] bg-gradient"
                    onClick={handleFormSubmit}
                  >{loading ?
                    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-orange-400 sm:border-t-transparent sm:border-white" role="status">
                    </div> : 'Change Password'}

                  </button>
                </div>
              </form>

            </div>
            <div className="sm:hidden flex flex-col w-full items-center gap-1 mx-auto justify-center fixed bottom-[20px] text-center md:text-xl pb-4">
              <div className="flex flex-row w-full justify-center gap-4 text-[#585858] text-[14px]">
                <Link
                  to="/terms-and-conditions"
                  className="text-[12px] text-center"
                >
                  Terms & Conditions
                </Link>
                <Link to="/userAgreement" className="text-[12px] text-center">
                  User Agreement</Link>

              </div>
              <div className="flex flex-row w-full gap-3 justify-center text-[#585858] text-[14px]">
                <Link
                  to="/paymentPolicy"
                  className="text-[12px] text-center"
                >
                  Payment Policy
                </Link>
                <Link to="/refundPolicy" className="text-[12px] text-center">
                  Refund Policy
                </Link>

                <Link to="/privacyPolicy" className="text-[12px] text-center">
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>

  );
};

export default ResetPassword;