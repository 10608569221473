import React from 'react';
import { MdBlock } from 'react-icons/md';

const BlockUser = ({ onBlockUser, isBlocked }) => {
  const buttonText = isBlocked ? 'Unblock Teacher' : 'Block Teacher';

  return (
    <div className="flex items-center gap-2 absolute top-16 right-1 bg-white my-1 px-4 py-2 rounded-full shadow-md">
      <MdBlock className='w-5 h-5 font-semibold text-orange-400' />
      <button onClick={onBlockUser} className='text-gradient font-semibold'>
        {buttonText}
      </button>
    </div>
  );
}

export default BlockUser;
