import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import MainMenu from '../MenuBottom/MainMenu';
import { BsCameraVideoFill, BsPencilSquare, BsChevronLeft } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import moment from 'moment-timezone';
import axios from 'axios';
import NavBar from '../nav/navBar';
import { IoIosShareAlt } from 'react-icons/io';
import HeaderBackground from '../headerBackground';
import ConferenceStudentsModal from '../../Modals/conferenceStudentsModal';
import Loading2 from '../Loading2';
import { CiBookmarkRemove } from "react-icons/ci";
import { FaBookmark } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import { load } from '@cashfreepayments/cashfree-js';
import { FaRegEyeSlash } from 'react-icons/fa';
import { MdOutlineRemoveRedEye } from 'react-icons/md';


const Shared_Course_Details = () => {

    const { id } = useParams();
    const [card, setCard] = useState({});
    const [pageLoading, setPageLoading] = useState(true)
    let Start_Time, End_Time, Title, URL, Description, Price, Start_Date, _id, End_Date
    const baseUrl = window.location.origin;
    const [isIndia, setIsIndia] = useState()
    const [completed, setCompleted] = useState(false)
    const [teacher_country, set_Teacher_country] = useState()
    const navigate = useNavigate();
    const user_type = Cookies.get('user_type')
    const authToken = Cookies.get('authToken');
    const apiUrl = process.env.REACT_APP_API_URL;
    const [original_amount, setAmount] = useState(0);
    const [tax_amount, setTaxAmount] = useState(0);
    const user_loggedInId = Cookies.get('loggedInUserId')
    const [serviceCharges, setServiceCharges] = useState(0);
    const [GST, setGst] = useState(0);
    const [user_data, setUser_data] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [isBooked, setIsBooked] = useState(false);
    const [earning, setEarning] = useState();
    const [earning_id, setEarning_id] = useState();
    const [isClickable, setIsClickable] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [showPwd, setShowPwd] = useState(card.hide);
    const [user_country, setUser_country] = useState('')
    const [teacher_time_zone, setTeacher_Time_Zone] = useState('')
    const [user_time, setUser_time] = useState('')
    //console.log(user_type)
    //const formattedConferenceDate = moment(card.Conference_Date).format('MMM DD - YYYY');
    const Date = Start_Date;
    const [loading, setLoading] = useState(false)
    const [loadingButton, setLoadingButton] = useState(true)

    useEffect(() => {
        fetchUserDetails(setIsIndia);
        //fetch_teacher_Details(set_Teacher_country);
    }, [isIndia])

    const fetchUserDetails = async (setIsIndia) => {
        try {
            const response = await axios.get(`${apiUrl}/api/auth/user_details/${user_loggedInId}`);
            const user = response.data.user_Details;
            setUser_country(user.country)

            setUser_time(user.time_zone);
            if (!user.country) {
                console.error('No country name found in user details');
                return; // Exit early if no country name is available
            }
            if (user.country === 'India') {
                setIsIndia(true);
            } else {
                setIsIndia(false);
            }

        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    useEffect(() => {
        if (card && card._id) {
            fetch_Booked_Student();
        }
    }, []);
    const fetch_Booked_Student = async () => {
        const students_response = await axios.post(
            `${apiUrl}/api/courses/get_no_students_booked`,
            { course_Id: card._id },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            }
        );
        const No_of_Students = students_response.data
        console.log(No_of_Students)
        const updatedCard = {
            ...card,
            No_of_Students: No_of_Students,
        };

        // Update the state with the new card data
        setCard(updatedCard);
        setPageLoading(false)
        console.log("After fetching no of students ", card)
    }

    const fetch_teacher_Details = async (set_Teacher_country, id) => {
        try {
            const response = await axios.get(`${apiUrl}/api/auth/user_details/${id}`);
            const user = response.data.user_Details;
            //console.log(user)
            set_Teacher_country(user.country);
            setTeacher_Time_Zone(user.time_zone)

            return user.country;

        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`${apiUrl}/api/courses/getCourseDetails`, { course_id: id }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    },
                });

                const course = response.data.courseData;
                const country = await fetch_teacher_Details(set_Teacher_country, course.userId._id);
                setPageLoading(false)
                setCard(course);
                console.log(course)

                const conversionRate = 83;
                const convertINRtoUSD = (amount) => {
                    return amount / conversionRate;
                };

                const serviceChargeInINR = calculateServiceCharge(course.Price.Fixed_Amount);
                console.log("service charge", serviceChargeInINR)
                let amountInLocalCurrency = Number(course.Price.Fixed_Amount);
                console.log("amountInLocalCurrency", amountInLocalCurrency)
                let serviceCharge = serviceChargeInINR;
                console.log("serviceCharge", serviceCharge)
                if (!isIndia && teacher_country === 'India') {
                    amountInLocalCurrency = Math.ceil(convertINRtoUSD(amountInLocalCurrency));
                    serviceCharge = convertINRtoUSD(serviceChargeInINR);
                    console.log("amountInLocalCurrency", amountInLocalCurrency)
                    console.log("serviceCharge", serviceCharge)
                }

                const updated_amount = amountInLocalCurrency + serviceCharge + course.Price.paypal_fee;
                console.log("updated_amount", updated_amount)
                const gst = calculateGST(updated_amount);
                console.log("gst", gst)
                setGst(gst);

                const totalAmount = Math.ceil(Number(amountInLocalCurrency) + Number(serviceCharge) + Number(gst) + course.Price.paypal_fee);
                console.log("totalAmount", totalAmount)
                setTaxAmount(totalAmount);

                let convertedData;

                if (user_country === teacher_country) {
                    console.log(user_country,teacher_country)
                    convertedData = course; // Assuming course is an object
                } else {
                    const { Start_Time, End_Time, Start_Date, End_Date, ...rest } = course;

                    const startDateTimeInUserTimeZone = moment.tz(`${Start_Date} ${Start_Time}`, 'YYYY-MM-DD HH:mm', teacher_time_zone)
                        .clone()
                        .tz(user_time);

                    const endDateTimeInUserTimeZone = End_Time ? moment.tz(`${End_Date} ${End_Time}`, 'YYYY-MM-DD HH:mm', teacher_time_zone)
                        .clone()
                        .tz(user_time) : null;

                    const adjustedConferenceDate = startDateTimeInUserTimeZone.format('YYYY-MM-DD');
                    const adjustedEndDate = endDateTimeInUserTimeZone ? endDateTimeInUserTimeZone.format('YYYY-MM-DD') : '';

                    const convertedStartTime = startDateTimeInUserTimeZone.format('HH:mm');
                    const convertedEndTime = endDateTimeInUserTimeZone ? endDateTimeInUserTimeZone.format('HH:mm') : '';

                    convertedData = {
                        ...rest,
                        Start_Date: adjustedConferenceDate,
                        End_Date: adjustedEndDate,
                        Start_Time: convertedStartTime,
                        End_Time: convertedEndTime
                    };
                }
                setCard(convertedData);
            } catch (error) {
                console.error('Error fetching conference details:', error);
            } finally {
                setPageLoading(false)
            }
        };

        fetchData();
    }, [id, isIndia, teacher_country, teacher_time_zone, user_country]);

    // const SERVICE_CHARGE_PERCENTAGE = isIndia ? 0.05 : 0.09; // 5% for indians 9% for other countries   
    const SERVICE_CHARGE_PERCENTAGE =  0.09;      
    const GST_PERCENTAGE = isIndia ? 0.18 : 0.15; // 18%     

    const calculateServiceCharge = (amount) => {
        //console.log("modified amount ==>",amount * SERVICE_CHARGE_PERCENTAGE)
        setAmount(amount)
        return Math.ceil(amount * SERVICE_CHARGE_PERCENTAGE);
    };

    const calculateGST = (amount) => {
        //setGst(amount * GST_PERCENTAGE)
        return Math.ceil(amount * GST_PERCENTAGE);
    };

    const updateButtonState = (Course_Start_Date, Start_Time) => {
        console.log("Date and time ==>", Course_Start_Date, Start_Time)
        if (!Start_Time || typeof Start_Time !== 'string') {
            console.error('Invalid Start_Time:', Start_Time);
            return; // Exit early if Start_Time is not valid
        }
        const startHourMinute = Start_Time.split(':');
        const now = new window.Date(Course_Start_Date);

        const start = new window.Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            parseInt(startHourMinute[0]), // hours
            parseInt(startHourMinute[1]), // minutes
            0, // seconds
            0 // milliseconds
        ).getTime();

        // console.log("Start ==> ", start, new window.Date(start).toLocaleString());

        const fiveMinutes = 5 * 60 * 1000;
        const tenMinutes = 10 * 60 * 1000;
        const currentTime = now.getTime();

        console.log("Current Time ==> ", currentTime, now.toLocaleString());

        console.log("Time range ==> ", start - fiveMinutes, " to ", start + tenMinutes,
            new window.Date(start - fiveMinutes).toLocaleString(),
            " to ",
            new window.Date(start + tenMinutes).toLocaleString());

        if (currentTime >= start - fiveMinutes && currentTime <= start + tenMinutes) {
            setIsActive(true);
            console.log("Button is active: true");
        } else {
            setIsActive(false);
            console.log("Button is active: false");
        }

    };
    const formatDateString = (dateString) => {
        const eventDate = moment.utc(dateString);
        const formattedDate = eventDate.format('MMM DD - YYYY');
        // console.log(`Formatted Date in UTC: ${formattedDate}`); 

        return formattedDate;
    };

    const checkIfStudentBooked = async (courseId, studentId) => {
        try {
            setLoading(true)
            const response = await axios.post(`${apiUrl}/api/courses/checkBooking`, { courseId, studentId }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            });
            const data = await response.data;

            return data;
        } catch (error) {
            console.error('Error checking if student booked:', error);
            return false;
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {

        fetch_user_Details();
        if (card) {
            console.log("inside earning")
            fetch_earning_details(_id);
            const checkBooking = async () => {
                console.log(card._id)
                const booked = await checkIfStudentBooked(card._id, user_loggedInId);
                setIsBooked(booked.status);
                // console.log("Status",booked)
                // console.log(isBooked)
            };

            if (user_type === 'Student') {
                checkBooking();
            }
            updateButtonState(card.Start_Date, card.Start_Time);

            const interval = setInterval(() => {
                console.log(card.Start_Date, card.Start_Time);
                updateButtonState(card.Start_Date, card.Start_Time);
            }, 60000);


            return () => clearInterval(interval);
        }

    }, [isIndia, completed]);

    const fetch_earning_details = async (courseId) => {
        try {
            // console.log("Fetching earnings details")
            //const course_Id=courseId;
            // console.log(conference_Id)
            const earnings_details = await axios.post(`${apiUrl}/api/earningCourse/fetch_earning_details`, { course_Id: courseId }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            })
            // console.log("earnings ===>",earnings_details.data)
            if (earnings_details.data) {
                setEarning(earnings_details.data);
                let earning_ids;
                if (earnings_details.data.length > 1) {
                    earning_ids = earnings_details.data.map((earning) => {
                        return earning._id
                    })
                }
                else {
                    earning_ids = [earnings_details.data[0]._id];
                }
                setEarning_id(earning_ids);
                // console.log(earning_id)
            } else {
                // console.log('No Earnings');
                setEarning(null);
                setEarning_id(null);
            }
        } catch (error) {
            console.error('Error fetching earning details:', error);
        }
    }
    //console.log("Total Amount ==>",totalAmount)

    const fetch_user_Details = async () => {
        try {

            const user_details = await axios.get(`${apiUrl}/api/auth/user_details/${user_loggedInId}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            })
            const user = user_details.data.user;
            // console.log(user);
            // console.log("Name",user.fullName)
            // console.log("Eamil",user.email)
            setUser_data(user);


        } catch (error) {
            console.error('Server Error:', error.response.data);
        }
    }
    // BACK NAVIGATION BASE ON LOGGED IN USER TYPE

    const handleback = (e) => {
        if (user_type === 'Teacher') {
            navigate('/calender_page');
        }
        else {
            navigate('/cabinet');
        }

    }

    const handlePayment = async (st, et, start_date, end_date, courseId, Currency, currencyCode, Fixed_Amount) => {
        console.log("Inside invoice")
        if (Fixed_Amount === '0') {
            const Id = courseId;
            // const slot_id=id;
            // await bookConference(Id)
        } else {
            const conversionRate = 83;
            const convertINRtoUSD = (amount) => {
                return amount / conversionRate;
            };

            const serviceChargeInINR = calculateServiceCharge(Fixed_Amount);
            setServiceCharges(serviceChargeInINR);

            let amountInLocalCurrency = Number(Fixed_Amount);
            let serviceCharge = serviceChargeInINR;
            console.log(isIndia, teacher_country)
            if (!isIndia && teacher_country === 'India') {
                //console.log("Student is not Indian")
                amountInLocalCurrency = Math.ceil(convertINRtoUSD(amountInLocalCurrency));
                serviceCharge = convertINRtoUSD(serviceChargeInINR);
            }

            const updated_amount = amountInLocalCurrency + serviceCharge + card.Price.paypal_fee;

            const gst = calculateGST(updated_amount);
            setGst(gst);
            console.log(teacher_country)
            const paypal_fee = (isIndia && teacher_country === 'India') ? card.Price.paypal_fee : 0.30
            //  console.log("paypal_fee inside invoice",paypal_fee)
            //   console.log(amountInLocalCurrency , serviceCharge , gst,paypal_fee)
            //   console.log(amountInLocalCurrency + serviceCharge + gst+paypal_fee)
            const totalAmount = Math.ceil(amountInLocalCurrency + serviceCharge + gst + paypal_fee);
            //console.log(totalAmount)
            setTaxAmount(totalAmount);

            const formatToTwoDecimalPlaces = (amount) => {
                return parseFloat(amount).toFixed(2);
            };

            const formattedServiceCharge = formatToTwoDecimalPlaces(serviceCharge);
            const formattedCallPrice = formatToTwoDecimalPlaces(amountInLocalCurrency);

            console.log(card)

            const invoice_details = {
                Name: user_data.fullName,
                Email: user_data.email,
                Start_Time: st,
                End_Time: et,
                Date: start_date,
                End_Date: end_date,
                call_Id: courseId,
                Service_Charges: formattedServiceCharge,
                GST: gst,
                Call_Price: (!isIndia && teacher_country === 'India') ? amountInLocalCurrency : Number(card.Price.Fixed_Amount),
                Total_amount: totalAmount,
                Type: "Course Details",
                PaymentType: "Course",
                Currency: (isIndia === false && teacher_country === 'India') ? '$' : Currency,
                CurrencyCode: (isIndia === false && teacher_country === 'India') ? 'USD' : currencyCode,
                paypal_fee: paypal_fee
            };

            console.log(invoice_details);

            navigate("/invoice-details", { state: { invoice_details } })
        }
    };
    const handleMeeting = async () => {
        try {

            const ids = Array.isArray(earning_id) ? earning_id : [earning_id];
            console.log(ids)

            const statusRequests = ids.map(id =>
                axios.put(`${apiUrl}/api/earningCourse/change_meeting_status`, { earning_id: id }, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                    },
                })
            );


            const responses = await Promise.all(statusRequests);
            console.log('Status updates:', responses);
        } catch (error) {
            console.error('Error updating meeting status:', error);
        }
    };


    useEffect(() => {
        if (card) {
            const checkAvailability = () => {
                const courseStart = moment(Start_Date).set({
                    hour: Start_Time?.split(':')[0],
                    minute: Start_Time?.split(':')[1],
                    second: 0,
                    millisecond: 0,
                });

                const courseEnd = moment(End_Date).set({
                    hour: Start_Time?.split(':')[0],
                    minute: Start_Time?.split(':')[1],
                    second: 0,
                    millisecond: 0,
                });

                const now = moment();

                if (now.isSameOrAfter(courseEnd)) {
                    setIsClickable(false);
                } else {
                    setIsClickable(true);
                }
            };

            checkAvailability();
            const interval = setInterval(checkAvailability, 60000);

            return () => clearInterval(interval);
        }
    }, [Start_Date, Start_Time, End_Time, card]);


    const handleshare = async () => {
        if (isClickable) {
            if (navigator.share) {
                const baseUrl = window.location.origin;
                navigator.share({
                    title: 'Check out this Conference Details!',
                    text: 'Conference Details',
                    url: `${baseUrl}/conferece_details/${card._id}`,
                })
                    .then(() => console.log('Shared successfully'))
                    .catch((error) => console.error('Error sharing:', error));
            } else {
                alert('Sharing is not supported on this browser.');
            }
        }
    };

    const ShowStudentDetails = (event) => {
        console.log("students", event.No_of_Students.students)
        event.No_of_Students.students.map((student, index) => {
            // console.log(`Student ${index + 1}:`);
            // console.log('Student Details ===>', student.studentDetails)
            // console.log('User Details ==>', student.userDetails)

            // Add more fields as needed
        });
        setModalData(event.No_of_Students.students);
        setIsModalOpen(true);
    }
    const handleEdit = (card) => {
        navigate('/edit-course', { state: { card } })
    }

    const handleHide = async (card) => {
        try {
            const hideStatus = !showPwd; // Toggle the current hide status
            setShowPwd(hideStatus); // Update the state to reflect the new hide status

            const response = await axios.post(
                `${apiUrl}/api/courses/hide_course/${card._id}`,
                { hideStatus }, // Send hideStatus as a boolean
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            console.log(response.data.message); // Log the success message
        } catch (error) {
            console.error('Error toggling course visibility:', error.response?.data || error.message);
        }
    };
    if (pageLoading) {
        return <Loading2 />
    }

    return (

        <section class="mb-24 sm:mb-0 font-primary">
            <NavBar />
            {/* <MetaDecorator title={Title} description={card.Description} imageUrl={conference} keywords={keywords} imageAlt={'Not found'} url={`${baseUrl}/conferece_details/${_id}`}/> */}
            <div class='sm:mt-12 lg:mt-14 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 text-[#585858]'>
                <div class="sm:grid sm:grid-cols-12 flex flex-col gap-6 sm:min-h-[85vh] sm:py-[55px] justify-center">

                    {/* ------------------------- COL SPAN - 1  --------------------------- */}

                    <div class="col-span-5 ">

                        {/* ------------------------------ HEADER OF THE PAGE ----------------------------- */}
                        <HeaderBackground />
                        {/* --------------------- NAVIGATING BACK FUNCTIONALITY --------------------------------- */}

                        <div className='mt-44 mb-4 px-4 sm:px-0 sm:mt-0'>
                            <div style={{ color: '#AFAFAF' }} className='flex items-center gap-4 cursor-pointer' onClick={handleback} >
                                <BsChevronLeft />
                                <div style={{ fontWeight: 600 }} >Back</div>
                            </div>
                        </div>

                        {/* ---------------------------------- CONFERENCE SHORT DETAILS LIKE TIMINGS, TITLE AND DATE ----------------------- */}

                        <div className="flex sm:justify-center">
                            <div className="rounded-[20px] bg-white drop-shadow-md mx-2 p-[15px_10px_15px_15px] lg:p-5 ">
                                {user_type === 'Teacher' ? (
                                    <div className='flex justify-between items-center'>
                                        <div className="flex gap-4">
                                            <div className="font-semibold mb-1 ml-2">{formatDateString(card.Start_Date)}</div>
                                            {/*<div className="edit" style={{ fontWeight: 600, color: '#AFAFAF' }}>Edit</div>*/}
                                            <div className="font-semibold mb-1 ">-</div>
                                            <div className="font-semibold mb-1 ">{formatDateString(card.End_Date)}</div>
                                        </div>
                                        <div className=' flex gap-4 items-center'>
                                            <FiEdit style={{ fontSize: '1.5rem' }} onClick={() => handleEdit(card)} className=' cursor-pointer' />
                                            {/* <FaBookmark style={{fontSize:'1.5rem'}} onClick={()=>handleHide(card)} className=' cursor-pointer'/> */}
                                            <div className="flex items-center cursor-pointer">
                                                {showPwd === true ? (
                                                    <FaRegEyeSlash
                                                        style={{ fontSize: '1.5rem' }}
                                                        className=" cursor-pointer"
                                                        onClick={() => handleHide(card)}
                                                        title="Un Hide"
                                                    />
                                                ) : (
                                                    <MdOutlineRemoveRedEye
                                                        style={{ fontSize: '1.5rem' }}
                                                        className="cursor-pointer"
                                                        onClick={() => handleHide(card)}
                                                        title="Hide"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>) : (
                                    <div className="flex gap-4">
                                        <div className="font-semibold mb-1 ml-2">{formatDateString(card.Start_Date)}</div>
                                        {/*<div className="edit" style={{ fontWeight: 600, color: '#AFAFAF' }}>Edit</div>*/}
                                        <div className="font-semibold mb-1 ">-</div>
                                        <div className="font-semibold mb-1 ">{formatDateString(card.End_Date)}</div>
                                    </div>
                                )}

                                <p className="bg-[#D1FFD0] w-1/2 px-2 py-2 text-center rounded-[20px] font-montserrat font-semibold mb-1 mt-4">Time: {`${card.Start_Time}`}</p>
                                {card.image[0] && <img src={card.image[0].url} alt="course" className="w-[400px] h-[200px] border object-fit rounded-lg mt-2" />}
                                {/* <div className="meeting-heading font-medium mt-2 ml-2 text-gray-700">{Title}</div> */}

                            </div>
                        </div>
                    </div>

                    {/* -------------------------------------- COL SPAN - 2 ------------------------- */}

                    <div class="col-span-7 mx-2 sm:mx-0 bg-white drop-shadow-lg h-fit rounded-[20px] sm:mt-10 p-4 sm:p-3">


                        {/* ------------------------------ PRICE FOR THE CONFERENCE ------------------------------------- */}

                        <div className='flex justify-end'>

                            <div
                                className={`border-2 border-orange-600 rounded-3xl py-1 px-6 bg-transparent text-orange-500 font-semibold hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 cursor-pointer flex justify-center gap-2 items-center shadow-lg  ${isClickable ? '' : 'pointer-events-none opacity-50'}`}
                                onClick={handleshare}
                            >
                                Share Course
                                <IoIosShareAlt style={{ fontSize: '1.5rem' }} />
                            </div>
                        </div>


                        {/* ------------------------------ CONFERENCE DETAILS ------------------------------- */}

                        <div class="card  sm:shadow-none py-[15px] sm:px-3 sm:px-0 overflow-y-auto">
                            <div>

                                {/* ------------------------------- DESCRIPTION FOR THE CONFERENCE ------------------------- */}
                                <div className="meeting-heading font-semibold mt-2  text-[#585858]">{card.Title}</div>
                                {/* <div className='font-medium text-[#585858] mt-3'>Description :</div> */}
                                <div className='text-[#585858] mb-4 mt-1'>{card.Description}</div>

                                {/* --------------------------------------- REMAINDER TIME -------------------------------- */}

                                <div className='flex gap-4 items-center mb-2'>
                                    <BsPencilSquare className='text-orange-500 text-xl' />
                                    <div className='text-gray-600'>Reminder 30min before</div>
                                </div>

                                <div className="mt-4 border-2 border-gray-400 rounded-full px-5 py-2 text-center w-[150px]">
                                    {user_type === 'Student' ? (
                                        loading && completed ? (
                                            <div
                                                className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-orange-500"
                                                role="status"
                                            >
                                            </div>
                                        ) : (
                                            <div>
                                                Fee : {!isIndia && teacher_country === 'India' ? '$' : card.Price.currency} {tax_amount}
                                            </div>
                                        )
                                    ) : (
                                        <div>
                                            Fee : {card.Price.currency} {card.Price.Fixed_Amount}
                                        </div>
                                    )}
                                </div>


                                {/* --------------- BASED ON THE LOGGED IN USER SHOW BUTTON TO START OR BOOK CONFERENCE ------------------------ */}

                                {user_type === 'Student' && (
                                    loading ? (
                                        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-orange" role="status"></div>
                                    ) : (
                                        isBooked === true ? (

                                            <a
                                                href={isActive ? URL : ""}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={`inline-flex items-center justify-center w-56 sm:w-64 px-0 py-2.5 bg-gradient text-white text-center rounded-full gap-3.5 mt-2 ${!isActive ? 'opacity-50 pointer-events-none' : ''}`}
                                            >
                                                <BsCameraVideoFill />
                                                Start Meeting {isBooked}
                                            </a>
                                        ) : (
                                            <div
                                                className="inline-flex items-center justify-center w-56 sm:w-64 w-64 px-0 py-2.5 bg-gradient text-white text-center rounded-full gap-3.5 mt-2 cursor-pointer"
                                                onClick={() => handlePayment(card.Start_Time, card.End_Time, card.Start_Date, card.End_Date, card._id, card.Price.currency, card.Price.Currency_Code, card.Price.Fixed_Amount)}
                                            >
                                                <BsCameraVideoFill />
                                                Enroll Now
                                            </div>
                                        )
                                    )
                                )}


                                {user_type === 'Teacher' ? (
                                    earning_id ? (
                                        <div className='flex flex-col sm:flex-row gap-4'>
                                            <a
                                                href={isActive ? URL : ""}

                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={`inline-flex items-center justify-center w-56 sm:w-64 px-0 py-1.5 sm:py-2.5 bg-gradient text-white text-center rounded-full mt-2 gap-3.5 ${!isActive ? 'opacity-50 pointer-events-none' : ''}`}
                                                onClick={earning_id && isActive ? handleMeeting : undefined}
                                            >
                                                <BsCameraVideoFill />
                                                Start Meeting
                                            </a>
                                            <div className='flex justify-center items-center w-56 sm:w-64 px-0 py-1.5 sm:py-2 bg-gradient text-white text-center rounded-full cursor-pointer' title='Students List' onClick={() => ShowStudentDetails(card)}>
                                                No. of Students Booked: {card.No_of_Students.studentCount}
                                            </div>
                                        </div>

                                    ) : (
                                        <div className="ml-[13%] lg:ml-52 text-gray-500">
                                            No student booked the course
                                        </div>
                                    )
                                ) : null
                                }
                                {/* {user_type === 'Student' ?(
                      <>
                       <div
                           className="inline-flex items-center justify-center w-64 px-0 py-2.5 bg-gradient-to-b from-orange-600 to-yellow-400 text-white text-center rounded-full ml-[13%] gap-3.5 cursor-pointer"
                           onClick={() => handlePayment(card.Start_Time, card.End_Time, card.Start_Date,card.End_Date, card._id, card.Price.currency, card.Price.Currency_Code,card.Price.Fixed_Amount)}
                        >
                          <BsCameraVideoFill />
                          Book Now
                         </div>
                      </>
                  ):(
                      <>
                      
                        <a
                          // href={isActive ? URL : ""}
                          href = {URL}
                          target="_blank"
                          rel="noopener noreferrer"
                          className='inline-flex items-center justify-center w-64 px-0 py-2.5 bg-gradient-to-b from-orange-600 to-yellow-400 text-white text-center rounded-full ml-[13%] gap-3.5 lg:ml-52'
                          onClick={ handleMeeting }
                        >
                          <i className="fas fa-video"></i>
                          Start Meeting 
                        </a>
                      </>
                  )} */}

                            </div>
                        </div>
                    </div>

                    {/* --------------------------- BOTTOM MENU ---------------------------------- */}

                    <div className='flex justify-center'>
                        <MainMenu />
                    </div>
                </div>
            </div>
            <ConferenceStudentsModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                students={modalData} />
        </section>

    );
}


export default Shared_Course_Details