import React, { useState, useEffect } from 'react'
import MainMenu from '../components/MenuBottom/MainMenu'
import NavBar from '../components/nav/navBar';
import Cookies from 'js-cookie';
import axios from 'axios';
import Header from '../components/headerBackground';
import {cashfree} from '../utils/cashfree'
//import {cashfree} from '../utils/cashfree'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Loading2 from '../components/Loading2';
import MetaDecorator from '../utils/MetaDecorator';
// import { cashfree } from '../utils/util';

const InvoiceDetails = () => {
  const location = useLocation();
  const { invoice_details } = location.state || {};
  console.log(invoice_details)
  // console.log(invoice_details.CurrencyCode)
  console.log("Invoice details ===>", invoice_details)
  const [date, SetDate] = useState('')
  //const [number,setNumber]=useState('');
  const [original_amount, setAmount] = useState(0);
  const [tax_amount, setTaxAmount] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [gst, setGst] = useState(0);
  const navigate = useNavigate();
  const [payment_id, setPayment_Id] = useState(0)
  const [invoiceNum, setInvoiceNum] = useState(0)
  const [isIndia, setIsIndia] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const authToken = Cookies.get('authToken');
  const [fetched, setFetched] = useState(false)
  
  const user_loggedInId = Cookies.get('loggedInUserId')
  const file_url = 'https://cgb-storage.blr1.digitaloceanspaces.com';
  let defaultProfile = `${file_url}/profile-pictures/defaultProfile.png`
  const todayDate = new Date().toLocaleDateString();
  //let version = cashfree.version()
  const [loading, setLoading] = useState('')
  const [sessionId, setSessionId] = useState('');
  const [order, setOrder] = useState()
  const [number,setNumber]=useState('');

  function formatDate(dateString) {

    const date = new Date(dateString);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const dayName = days[date.getDay()];
    const monthName = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${dayName}, ${monthName}-${day}-${year}`;
  }
  const formattedDate = formatDate(invoice_details.Date);
  console.log(formattedDate);


  useEffect(() => {
    GENERATE_INVOICE_NUMBER();
    if (invoice_details && invoice_details.Date) {
      const formattedDate = formatDate(invoice_details.Date);
      SetDate(formattedDate);
      setServiceCharge(invoice_details.Service_Charges)
      setGst(invoice_details.GST)
      setTaxAmount(invoice_details.Total_amount)
      setAmount(invoice_details.Call_Price)
      console.log(formattedDate);
    }
    fetchUserDetails(setIsIndia, setFetched);
  }, [invoice_details, fetched]);

  const GENERATE_INVOICE_NUMBER = async () => {
    const prev_invoice_number = await axios.get(`${apiUrl}/api/invoice/get_prev_invoice_number`);
    const { latestInvoiceNumber } = await prev_invoice_number.data;
    const invoiceNumber = latestInvoiceNumber + 1;
    //console.log("Generated Invoice Number ===>",invoiceNumber)  
    setInvoiceNum(invoiceNumber)
  }

  const fetchUserDetails = async (setIsIndia, setFetched) => {
    try {
      const response = await axios.get(`${apiUrl}/api/auth/user_details/${user_loggedInId}`);
      const user = response.data.user_Details;
      console.log(user.country)
      if (!user.country) {
        console.error('No country name found in user details');
        return; // Exit early if no country name is available
      }
      if (user.country === 'India') {
        setIsIndia(true);
      } else {
        setIsIndia(false);
      }
      setFetched(true)
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const handlePayment = async (Id, slot_id) => {
    if (isIndia && invoice_details.Currency === '₹') {
      try {
        Cookies.set('Id', Id);
        console.log(serviceCharge, gst);
        const orderUrl = `${apiUrl}/api/payment/phonepe_order`;
        const response = await axios.post(orderUrl, {
          user_id: user_loggedInId,
          price: invoice_details.Total_amount,
          name: invoice_details.Name,
          id: Id,
          type: invoice_details.PaymentType,
          invoice_number: invoiceNum
        });
        //const { paymentUrl, orderId, totalAmount } = response.data;
        const { paymentUrl, totalAmount, merchantTransactionId } = response.data;

        const invoice_data = {
          invoiceNumber: invoiceNum,
          Name: invoice_details.Name,
          Email: invoice_details.Email,
          payment_mercant_id: merchantTransactionId,
          totalAmount: tax_amount,
          EventPrice: original_amount,
          paymentType: invoice_details.PaymentType,
          serviceCharge: serviceCharge,
          gst: gst,
          relatedId: Id,
          user: user_loggedInId,
          currency: invoice_details.Currency,
          Id: Id,
          slot_id: slot_id,
        };
        localStorage.setItem('invoice_data', JSON.stringify(invoice_data));
        window.location.href = paymentUrl;
      } catch (error) {
        console.error(error);
      }
    }
    else if (!isIndia || invoice_details.Currency !== '₹') {
      console.log('Outside India');
      Cookies.set('Id', Id);

      const orderData = {
        order_id: 'order_' + new Date().getTime(),
        order_amount: invoice_details.Total_amount,
        // order_currency: 'USD',
        order_currency: invoice_details.CurrencyCode,
        customer_details: {
          customer_id: 'cust' + user_loggedInId,
          customer_email: invoice_details.Email,
          customer_phone: number,
        },
        user_id: user_loggedInId,
          price: invoice_details.Total_amount,
          name: invoice_details.Name,
          id: Id,
          type: invoice_details.PaymentType,
          invoice_number: invoiceNum,
          return_url: `${apiUrl}/api/payment/capture-paypal-payment`
      };

      try {
        const response = await axios.post(`${apiUrl}/api/payment/create-paypal-order`, orderData);
        const {orderID, approvalUrl}=response.data
        console.log(orderID)
        const invoice_data = {
          invoiceNumber: invoiceNum,
          Name: invoice_details.Name,
          Email: invoice_details.Email,
          payment_mercant_id: orderID,
          totalAmount: tax_amount,
          EventPrice: original_amount,
          paymentType: invoice_details.PaymentType,
          serviceCharge: serviceCharge,
          gst: gst,
          relatedId: Id,
          user: user_loggedInId,
          currency: invoice_details.Currency,
          Id: Id,
          slot_id: slot_id,
          paypal_fee:invoice_details.paypal_fee,
          gateway:'paypal'
        };
        
        localStorage.setItem('invoice_data', JSON.stringify(invoice_data));
        //console.log("inv saved",invoice_data)
        window.location.href = approvalUrl
      } catch (error) {
        console.error('Error processing payment:', error);
      }
    }
  };
  

  const handleBack = () => {
    navigate('/cabinet')
  }

  if (!fetched) {
    return <Loading2 />
  }
   const handleChange = (event) => {
    setNumber(event.target.value); // Update phone number state
  };
  const keywords = ["elearning", "education", "online class", "education forum", "education","student","teacher","learning"]; 
  return (
    <section className="mb-0 sm:mb-0 font-primary">
      <MetaDecorator title={'Invoice - Career Guidance Buddy'} description={''} imageUrl={''} keywords={keywords} imageAlt={''} url={''}/>
      <NavBar />
      <div className='sm:mt-16 lg:mt-20 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5'>
        <Header />
        <div className='gap-4 sm:min-h-[85vh] sm:py-[40px] justify-center'>
          <div className="flex flex-col mt-[160px] sm:w-full sm:mt-0 items-center gap-1 mx-auto">
            {invoice_details.PaymentType === 'Call' ? (
              <p className='text-[16px] sm:text-[22px] text-gradient font-semibold'>Checkout to Book a Call</p>
            ) : invoice_details.PaymentType === 'Conference' ? (
              <p className='text-[16px] sm:text-[22px] text-gradient font-semibold'>Checkout for Conference</p>
            ) : invoice_details.PaymentType === 'Course' ? (
              <p className='text-[16px] sm:text-[22px] text-gradient font-semibold'>Checkout for Course</p>
            ) :(
              <p className='text-[16px] sm:text-[22px] text-gradient font-semibold'>Checkout for Premium Subscription</p>
            )}
            <div className='sm:grid sm:grid-cols-2 gap-3 items-start py-2 shadow-lg bg-white w-11/12 sm:w-11/12 border border-orange-400 rounded-[25px]'>
              <div className='flex flex-col gap-2 sm:flex sm:flex-col items-center  sm:gap-14'>

                <div className='  text-[#585858] text-[14px] sm:text-[16px] font-medium self-start mt-3 mx-4 justify-start'>Date : {todayDate}</div>

                <div className='flex flex-col justify-start mx-4 self-start sm:hidden lg:hidden'>
                  <div className='  text-[#585858] text-[14px] sm:text-[16px] font-medium '>Invoice Number : {invoiceNum}</div>
                  <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium '>{isIndia ? 'GST No. 08BEHPG9573E1Z9' : ''}</span>
                </div>

                <div className='flex flex-row sm:flex-col pl-3 sm:pl-0 gap-3 items-center self-start sm:self-center'>
                  {/* <img src={defaultProfile} alt="" className='w-10 h-10 sm:w-16 sm:h-16' /> */}
                  <div className='flex flex-col sm:items-center'>
                    <span className='text-gradient text-[16px] sm:text-[22px] font-semibold'>{invoice_details.Name}</span>
                    <span className='text-gradient text-[14px] sm:text-[18px] font-semibold'>{invoice_details.Email}</span>
                  </div>

                </div>
                {/* {!isIndia?
                <form action="post" className='text-[#585858] border border-orange-400 w-11/12 sm:w-11/12 sm:text-center sm:rounded-[50px] sm:py-2 rounded-full px-2 py-1 text-[14px] sm:text-[16px]'>
                <input
                    type="number"
                    maxLength={10}
                    name="phone"
                    placeholder='Enter phone number *'
                    id="phone"
                    value={number}
                    onChange={handleChange}
                    required
                />
                </form>:''} */}

                <div className='flex flex-col sm:items-center gap-2 rounded-[25px] bg-white drop-shadow py-3 pl-3 pr-1 w-11/12  sm:w-11/12'>
                  <span className='text-gradient text-[14px] sm:text-[18px] font-semibold'>{invoice_details.Type}</span>
                  <div className='flex flex-col sm:items-center'>
                    {invoice_details.Type !== 'Course Details' ?(
                      <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>Date: {date}</span>
                     ):(
                      <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>Date: {formatDate(invoice_details.Date)} - {formatDate(invoice_details.End_Date)}</span> 
                     )}
                    
                    <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>Time : {invoice_details.Start_Time} - {invoice_details.End_Time}</span>
                  </div>
                </div>
              </div>
              <div className='flex flex-col gap-2 items-center mt-3 sm:flex sm:flex-col sm:gap-6'>
                <div className='flex flex-col self-end mr-4 sm:block hidden lg:block'>
                  <div className='  text-[#585858] text-[14px] sm:text-[16px] font-medium '>Invoice Number : {invoiceNum}</div>
                  <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium '>{isIndia ? 'GST No. 08BEHPG9573E1Z9' : ''}</span>
                </div>

                <div className='flex flex-col gap-2 rounded-[25px] bg-white drop-shadow py-3 pl-3 pr-1 w-11/12'>
                  <span className='text-gradient text-[14px] sm:text-[18px] font-semibold'>Invoice Details and Price Break-Up</span>
                  <div className='pr-8'>
                    <div className='grid grid-cols-[auto_20px_auto] gap-x-2 gap-y-4'>
                      <div className='flex items-center'>
                        <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>Teacher’s fee</span>
                      </div>
                      <div className='flex justify-center'>
                        <span className='text-[#585858] text-[14px] font-bold'>:</span>
                      </div>
                      <div className='flex justify-start'>
                        <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>{invoice_details.Currency} {Number(invoice_details.Call_Price) + Number(invoice_details.Service_Charges)+ Number(invoice_details.Service_Charges) + Number(invoice_details.paypal_fee)} {isIndia ? '/-' :''}</span>
                      </div>
                      {/* <div className='flex items-center'>
                        <div className='lg:max-w-72 max-w-52'>
                          <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>CGB charges</span>
                          <span className='text-[#585858] text-[11px] sm:text-[14px] font-small'>{' '}{isIndia ? '(5% taken as ceiling to the next integer)' : '(9% taken as ceiling to the next integer)'}</span>
                        </div>
                      </div> */}
                      {/* <div className='flex justify-center'>
                        <span className='text-[#585858] text-[14px] font-bold'>:</span>
                      </div>
                      <div className='flex justify-start'>
                        <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>{invoice_details.Currency} {invoice_details.Service_Charges}{isIndia ? '/-' :''}</span>
                      </div> */}
                      {/* {!isIndia ?(<>
                      <div className='flex items-center'>
                        <div className='lg:max-w-96 max-w-52'>
                          <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>Paypal Charges</span>
                          <span className='text-[#585858] text-[11px] sm:text-[14px] font-small'> {' '} ({invoice_details.paypal_fee} taken as ceiling to the next integer)</span>
                        </div>
                      </div>
                      <div className='flex justify-center'>
                        <span className='text-[#585858] text-[14px] font-bold'>:</span>
                      </div>
                      <div className='flex justify-start'>
                        <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>{invoice_details.Currency} {invoice_details.paypal_fee}</span>
                      </div></>):''} */}

                      <div className='flex items-center'>
                        <div className='lg:max-w-96 max-w-52'>
                          <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>{isIndia ? 'GST ' : 'Tax'}</span>
                          <span className='text-[#585858] text-[11px] sm:text-[14px] font-small'>{' '}{isIndia ? '(GST 18%)' : ' 15%'}</span>
                        </div>
                      </div>
                      <div className='flex justify-center'>
                        <span className='text-[#585858] text-[14px] font-bold'>:</span>
                      </div>
                      <div className='flex justify-start'>
                        <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>{invoice_details.Currency} {invoice_details.GST}{isIndia ? '/-' :''}</span>
                      </div>

                      <div className='flex items-center'>
                        <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>Total Fee</span>
                      </div>
                      <div className='flex justify-center'>
                        <span className='text-[#585858] text-[14px] font-bold'>:</span>
                      </div>
                      <div className='flex justify-start'>
                        <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>{invoice_details.Currency} {invoice_details.Total_amount}{isIndia ? '/-' :''}</span>
                      </div>
                      <div className='flex items-center'>
                        <span className='text-gradient text-[14px] sm:text-[18px] font-semibold'>Fee to pay</span>
                      </div>
                      <div className='flex justify-center'>
                        <span className='text-gradient text-[14px] sm:text-[18px] font-semibold'>:</span>
                      </div>
                      <div className='flex justify-start'>
                        <span className='text-gradient text-[14px] sm:text-[18px] font-semibold'>{invoice_details.Currency} {invoice_details.Total_amount}{isIndia ? '/-' :''}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col gap-4 ml-4 sm:ml-4'>
                  <span className='text-[#585858] text-[10px] sm:text-[13px] font-medium '>* This Invoice is valid after payment is received</span>
                  <span className='text-[#585858] text-[10px] sm:text-[13px] font-medium'>** You will receive your final invoice over email in 3 working days</span>
                </div>

                <div className='flex flex-row items-center w-11/12 justify-between sm:justify-center sm:gap-6'>
                  <button className='rounded-full px-5 py-2 text-[#585858] bg-[#E6E9F2]' onClick={handleBack}>Back</button>
                  <button className='rounded-full px-3 py-2 text-white bg-gradient' onClick={() => handlePayment(invoice_details.call_Id, invoice_details.slot_id)}>Pay Now</button>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        {/* <MainMenu /> */}
      </div>
    </section>
  )
}

export default InvoiceDetails