import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/headerBackground';
import MainMenu from '../components/MenuBottom/MainMenu';
import userimg from '../assets/img/usersm.png';
import report from '../assets/img/report.png';
import { BsFillHandThumbsUpFill } from "react-icons/bs";
import { BsHandThumbsUp } from "react-icons/bs";
import { FaCommentDots } from "react-icons/fa";
import { IoIosShareAlt } from "react-icons/io";
import NewPost from '../components/newPost';
import NewPostDesktop from '../components/newPostDesktop';
import NavBar from '../components/nav/navBar';
import Cookies from 'js-cookie';
import { useNotifications } from '../context/NotificationContext';
import { useLocation } from 'react-router-dom';
import Loading2 from '../components/Loading2';
import forum from '../assets/img/forum-preview.PNG'
import { Helmet } from 'react-helmet-async';
import MetaDecorator from '../utils/MetaDecorator';

const Forum = ({ setMessage, setShowMessage, profilePic, fullName }) => {
    const [posts, setPosts] = useState([]);
    const [selectedPost, setSelectedPost] = useState(null);
    const [newComment, setNewComment] = useState('');
    const authToken = Cookies.get('authToken');
    const user_type = Cookies.get("user_type")
    const [userData, setUserData] = useState({}); // State to hold user data
    const loggedInUserId = Cookies.get('loggedInUserId');
    const [showAllComments, setShowAllComments] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state
    const [editingCommentId, setEditingCommentId] = useState(null);
    const [editedCommentContent, setEditedCommentContent] = useState('');
    const [showInputEdit, setShowInputEdit] = useState(false);
    const [filteredCategory, setFilteredCategory] = useState('');
    const [filteredSubCategory, setFilteredSubCategory] = useState('');
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const url = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const file_url = 'https://cgb-storage.blr1.digitaloceanspaces.com';
    let defaultProfile = `${file_url}/profile-pictures/defaultProfile.png`
    const { forumPostId, setForumPostId } = useNotifications();
    //console.log(forumPostId)
    const handleFilterApply = (category, subCategory) => {
        setFilteredCategory(category);
        setFilteredSubCategory(subCategory);
        if (category === '') {
            setIsFilterApplied(false);
        } else {
            setIsFilterApplied(true);
        }
    };
    const clearFilters = () => {
        setFilteredCategory('');
        setFilteredSubCategory('');
        setIsFilterApplied(false);
    };


    useEffect(() => {
        const fetchPosts = async () => {
            try {
                setLoading(true)
                let apiUrl = `${url}/api/forum/getAllPosts`;
                // Check if filteredCategory and filteredSubCategory are not empty strings
                if (isFilterApplied) {
                    // Append category and subcategory parameters to the API URL
                    apiUrl += `?category=${filteredCategory}&subcategory=${filteredSubCategory}`;
                }
                const response = await axios.get(apiUrl, {
                    headers: {
                        "Authorization": `Bearer ${authToken}`,
                    },
                });
                // console.log(response.data.posts);
                setPosts(response.data.posts.reverse());
            } catch (error) {
                console.error('Failed to fetch posts:', error);
            } finally {
                setLoading(false);
            }
        };
        // Fetch posts only when authToken changes
        fetchPosts();
    }, [authToken, filteredCategory, isFilterApplied, filteredSubCategory]); // Only run this effect when authToken changes

    useEffect(() => {
        const fetchUserData = async (userId) => {
            console.log(userId)
            try {
                const userResponse = await axios.get(`${url}/api/user-info/get-users/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                setUserData((prevUserData) => ({
                    ...prevUserData,
                    [userId]: userResponse.data,
                }));
            } catch (error) {
                console.error('Failed to fetch user data:', error);
            }
        };
        for (const post of posts) {
            // Check if post.comments is an array and not null/undefined
            if (Array.isArray(post.comments)) {
                for (const comment of post.comments) {
                    if (!userData[comment.userId]) {
                        fetchUserData(comment.userId);
                    }
                }
            }
        }
    }, [authToken, posts, userData]); // Run this effect when authToken, posts, or userData changes
    useEffect(() => {
        if (posts.length > 0 && forumPostId) {
            const element = document.getElementById(`post-${forumPostId}`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
                setForumPostId(null); // Reset the forumPostId after scrolling
                // Optionally, you can add a class to highlight the scrolled post using Tailwind CSS
                element.classList.add('border-2', 'border-orange-400', 'transition', 'duration-300');
                element.querySelector('h1').classList.add('text-orange-400');
                // Remove highlight after a delay (optional)
                setTimeout(() => {
                    element.classList.remove('border-2', 'border-orange-400');
                    element.querySelector('h1').classList.remove('text-orange-400');
                }, 3000); // Adjust the delay as needed
            }
        }
    }, [posts, forumPostId, setForumPostId]);
    const handleLike = async (postId) => {
        try {
            const response = await axios.post(`${url}/api/forum/like/${postId}`, null, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            const updatedPosts = posts.map((post) =>
                post.id === postId ? { ...post, likesCount: response.data.likesCount, likes: response.data.likes, } : post
            );
            setPosts(updatedPosts);

        } catch (error) {
            console.error('Failed to like post:', error);
        }
    };
    const handleUnlike = async (postId) => {
        try {
            const response = await axios.delete(`${url}/api/forum/unlike/${postId}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            // console.log(response)
            // Update the like count for the specific post in the UI
            const updatedPosts = posts.map((post) =>
                post.id === postId
                    ? {
                        ...post,
                        likesCount: response.data.likesCount, // Decrement the likes count
                        likes: response.data.likes,
                    }
                    : post
            );
            setPosts(updatedPosts);
        } catch (error) {
            console.error('Failed to unlike post:', error);
        }
    };

    const handleAddComment = async (postId) => {
        try {
            setLoading(true);
            const response = await axios.post(
                `${url}/api/forum/comment/${postId}`,
                { commentContent: newComment, userId: loggedInUserId },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            const updatedPosts = posts.map((post) =>
                post.id === selectedPost.id
                    ? { ...post, commentsCount: post.commentsCount + 1 }
                    : post
            );
            setPosts(updatedPosts);
            setNewComment(''); // Clear the comment input field
            setShowMessage(true);
            setMessage('Comment Added!');
            setLoading(false);
        } catch (error) {
            console.error('Failed to add comment:', error);
            setLoading(false);
        }
    };
    const handleEditComment = async (postId, commentId) => {
        try {
            // console.log('Post ID:', postId);
            // console.log('Comment ID:', commentId);
            // console.log('New Content:', editedCommentContent);

            const response = await axios.put(
                `${url}/api/forum/edit-comment/${postId}/${commentId}`,
                { updatedContent: editedCommentContent },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            const updatedPosts = posts.map((post) =>
                post.id === postId
                    ? {
                        ...post,
                        comments: post.comments.map((comment) =>
                            comment._id === commentId ? { ...comment, content: editedCommentContent } : comment
                        ),
                    }
                    : post
            );
            // console.log("updated post", updatedPosts)
            setPosts(updatedPosts);
            setShowMessage(true);
            setMessage('Comment updated successfully');
            setEditingCommentId(null); // Reset editing state
            setEditedCommentContent(''); // Clear edited content
            // setShowInputEdit(false);
        } catch (error) {
            console.error('Failed to edit comment:', error.response?.data || error.message);
            setShowMessage(true);
            setMessage('Failed to update comment');
        }
    };
    const handleDeleteComment = async (postId, commentId) => {
        try {
            const confirmed = window.confirm('Are you sure you want to delete this comment?');
            if (!confirmed) {
                return; // Do nothing if user cancels the deletion
            }
            // Proceed with deleting the comment if user confirms
            await axios.delete(`${url}/api/forum/delete-comment/${postId}/${commentId}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            const updatedPosts = posts.map((post) =>
                post.id === postId
                    ? {
                        ...post,
                        commentsCount: post.commentsCount - 1,
                        comments: post.comments.filter((comment) => comment._id !== commentId),
                    }
                    : post
            );
            setPosts(updatedPosts);
            setShowMessage(true);
            setMessage('Comment deleted successfully');
        } catch (error) {
            console.error('Failed to delete comment:', error);
            setShowMessage(true);
            setMessage('Failed to delete comment');
        }
    };
    const handleNewPost = (newPost) => {
        // console.log(newPost)
        const updatedNewPost = {
            ...newPost,
            fullName: newPost.fullName || 'User Name', // Use the user's full name from userData if available
            timeElapsed: newPost.timeElapsed,
            profilePic: newPost.profilePic,

        };
        // Add the new post to the posts array
        setPosts([updatedNewPost, ...posts]);
    };
    const togglePostComments = (postId) => {
        setSelectedPost(selectedPost === postId ? null : postId);
        setShowAllComments(false);
    };
    const handleShowMoreComments = () => {
        setShowAllComments(true);
    };
    // Extract postId from URL and set forumPostId when the component mounts
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const postId = params.get('postId');
        setForumPostId(postId);
    }, [location]);

    const handleShare = (postId) => {
        if (navigator.share) {
            navigator.share({
                title: 'My new post on CareerGuidanceBuddy',
                text: 'Check out my new post on CareerGuidanceBuddy and like, comment & share!',
                url: `${window.location.origin}/forum?postId=${postId}`, // Share specific post URL
            })
                .then(() => console.log('Shared successfully'))
                .catch((error) => console.error('Error sharing:', error));
        } else {
            alert('Sharing is not supported on this browser.');
        }
    };
    const isToday = (date) => {
        const today = new Date();
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return isToday(date) ? date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : date.toLocaleDateString();
    };
    const keywords = ["elearning", "education", "online class", "education forum", "education","student","teacher","learning"]; 
    return (
        <>
            <Helmet>
                <title>Forum - Career Guidance Buddy</title>
                <meta name="keywords" content="education fourm,teacher, education, online class,e-learning" />
            </Helmet>
            <MetaDecorator title={'Forum - Career Guidance Buddy'} description={'Post your quries on Career Guidance Buddy Forum'} imageUrl={forum} keywords={keywords} imageAlt={''} url={window.location.href}/>
            <section className="sm:mb-0 sm:mt-0">
                <NavBar />
                <div className='sm:mt-24 lg:mt-28 flex flex-col gap-2 font-primary items-center sm:items-start justify-center sm:justify-start sm:min-h-none sm:mt-16'>
                    <Header />
                    <div className="sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 mb-8">
                        <div className="lg:grid grid-cols-3 gap-4 px-3">
                            {/* new post desktop */}
                            <NewPostDesktop onNewPost={handleNewPost} onFilterApply={handleFilterApply} onClear={clearFilters} />
                            {/* posts display */}
                            <div class="col-span-2 mt-[160px] sm:mt-0 flex flex-col items-center sm:mx-2">
                                {
                                    loading ?
                                        <Loading2 /> :
                                        <div className='overflow-y-auto sm:min-h-[560px] pb-5 w-full pt-5 sm:pt-1 flex flex-col items-center space-y-[10px] px-1 sm:pb-10 sm:w-full' style={{ maxHeight: 'calc(100vh - 260px)' }}>
                                            {/* {loading && <Loading2/>} */}
                                            {isFilterApplied && posts.length === 0 && (
                                                <div className='text-gradient font-semibold text-center'>No posts found for selected category/ sub-category please select different category/ sub-category</div>
                                            )}
                                            {posts.map((post) => (
                                                <div key={post.id} id={`post-${post.id}`} className='card rounded-[25px] sm:rounded-[25px] bg-white p-4 w-full' style={{ boxShadow: '0px 2px 6px 0px #00000040' }}>
                                                    <div className='flex items-center justify-between'>
                                                        <div className='flex items-center justify-between'>
                                                            {/* <div>{`post-${post.id}`}</div> */}
                                                            <img src={post.profilePic !== "" && post.profilePic !== undefined ? post.profilePic : defaultProfile} alt='' className='w-10 h-10 sm:w-12 sm:h-12 rounded-full' />
                                                            <div className='pl-[9px]'>
                                                                <h1 className='text-[16px] sm:text-[18px] font-semibold' style={{ background: 'linear-gradient(180deg, #ff6535 0%, #ff9e48 48.9%, #ffce58 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                                                                    {post.fullName}
                                                                </h1>
                                                                <p className='text-[11px] sm:text-[12px] font-normal text-[#2c2c2c]'>
                                                                    {post.timeElapsed}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* <button><img src={report} alt='' className='w-6 h-8' /></button> */}
                                                    </div>
                                                    <div className='pt-[10px] flex flex-col gap-0.5'>
                                                        <div className='flex flex-row items-center gap-1 justify-start sm:gap-2 text-[11px] w-full sm:text-[14px] font-semibold' style={{ background: 'linear-gradient(180deg, #ff6535 0%, #ff9e48 48.9%, #ffce58 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                                                            <span>{post.category.name}</span>
                                                            <svg width="5" height="5" viewBox="0 0 5 5" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="2.5" cy="2.5" r="2" fill="url(#gradient)" />
                                                                <defs>
                                                                    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                                                        <stop offset="0%" style={{ stopColor: '#ff6535' }} />
                                                                        <stop offset="50%" style={{ stopColor: '#ff9e48' }} />
                                                                        <stop offset="100%" style={{ stopColor: '#ffce58' }} />
                                                                    </linearGradient>
                                                                </defs>
                                                            </svg>
                                                            <span>{post.subCategory.name}</span>
                                                        </div>
                                                        <hr />
                                                        <h1 className='text-[12px] sm:text-[14px] font-semibold' style={{ background: 'linear-gradient(180deg, #ff6535 0%, #ff9e48 48.9%, #ffce58 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                                                            {post.postTitle}
                                                        </h1>
                                                        <p className='text-[12px] sm:text-[14px] font-medium text-[#585858] '>
                                                            {post.postContent}
                                                        </p>
                                                        {post.images && post.images.length > 0 && (
                                                            <div className='flex flex-col items-start border rounded-2xl p-2 object-contain w-fit mt-1 mb-[10px]'>
                                                                {post.images.map((image) => (
                                                                    <img key={image._id} src={image.url} alt={image.filename} className='w-fit h-fit object-contain rounded-lg bg-contain' />
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='flex items-center justify-start mt-1'>
                                                        <div className='flex items-center pr-[15px]'>

                                                            {/* // Inside your map function where you render posts */}
                                                            {post.likes && post.likes.includes(loggedInUserId) ? (
                                                                <button onClick={() => handleUnlike(post.id)}>
                                                                    <BsFillHandThumbsUpFill className='text-orange-400 sm:w-5 sm:h-5 cursor-pointer' /> {/* Display unlike icon if post is liked */}
                                                                </button>
                                                            ) : (
                                                                <button onClick={() => handleLike(post.id)}>
                                                                    <BsFillHandThumbsUpFill className='text-orange-400 sm:w-5 sm:h-5 cursor-pointer' /> {/* Display like icon if post is not liked */}
                                                                </button>
                                                            )}
                                                            <p className='pl-[6px] text-[14px] font-semibold' style={{ background: 'linear-gradient(180deg, #ff6535 0%, #ff9e48 48.9%, #ffce58 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                                                                {post.likesCount}
                                                            </p>
                                                        </div>
                                                        <div className='flex items-center pr-[15px]'>
                                                            <FaCommentDots className='sm:w-5 sm:h-5 text-orange-400 cursor-pointer' onClick={() => togglePostComments(post.id)} />
                                                            <p className='pl-[6px] text-[14px] font-semibold' style={{ background: 'linear-gradient(180deg, #ff6535 0%, #ff9e48 48.9%, #ffce58 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                                                                {post.commentsCount}
                                                            </p>
                                                        </div>
                                                        <div className='flex items-center'>
                                                            <IoIosShareAlt className='text-orange-400 sm:w-6 sm:h-6 cursor-pointer' onClick={() => handleShare(post.id)} />
                                                            <p className='pl-[4px] text-[14px] font-semibold text-gradient'>
                                                                {post.sharesCount}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {selectedPost === post.id && (
                                                        <div className='flex flex-col gap-2'>
                                                            <hr className='text-gray-300 mt-2' />
                                                            <p className='text-gray-600 font-medium text-md'>Comments: </p>
                                                            <div className='flex flex-col gap-2'>
                                                                {showAllComments ? post.comments.map((comment) => (
                                                                    <div key={comment._id} className='flex flex-row'>
                                                                        <img src={userData[comment.userId]?.profilePic || userimg} alt={userData[comment.userId]?.fullName || 'User Profile'} className='basis-auto justify-self-start pt-1 sm:w-10 sm:h-10 w-7 h-7 rounded-full' />
                                                                        <div className='comment-item flex flex-col align-middle ml-1 basis-11/12 bg-gray-50 p-2 rounded-xl'>
                                                                            <div className='flex flex-row justify-between items-center'>
                                                                                <p className='sm:text-base text-sm text-gray-900 font-medium'>{userData[comment.userId]?.fullName || 'User Name'}</p>
                                                                                {comment.userId === loggedInUserId && (
                                                                                    <div className='flex flex-row gap-2 items-center'>
                                                                                        {/* Edit button */}
                                                                                        <span className='text-gray-500 text-xs sm:text-sm cursor-pointer' onClick={() => {
                                                                                            setEditingCommentId(comment._id); // Set editing state for this comment
                                                                                            setEditedCommentContent(comment.content);
                                                                                            setShowInputEdit(!showInputEdit) // Set initial edited content to current comment content
                                                                                        }}>
                                                                                            Edit</span>
                                                                                        {/* Delete button */}
                                                                                        <span className='text-gray-500 text-xs sm:text-sm cursor-pointer' onClick={() => handleDeleteComment(post.id, comment._id)}>Delete</span>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            {showInputEdit && editingCommentId === comment._id ? (
                                                                                // Input field for editing the comment
                                                                                <div className='flex flex-row items-center justify-between gap-1'>
                                                                                    <input
                                                                                        type='text'
                                                                                        value={editedCommentContent}
                                                                                        onChange={(e) => setEditedCommentContent(e.target.value)}
                                                                                        placeholder='Edit your comment...'
                                                                                        className='text-md text-gray-700 border border-gray-400 px-2 rounded-lg bg-white bg-transparent w-10/12'
                                                                                    />
                                                                                    <button
                                                                                        onClick={() => handleEditComment(post.id, comment._id)}
                                                                                        className='text-xs text-blue-500 cursor-pointer focus:outline-none'>Save
                                                                                    </button>
                                                                                </div>
                                                                            ) : (
                                                                                // Display comment content
                                                                                <div className='comment-item flex flex-row items-center justify-between gap-1'>
                                                                                    <p className='text-md text-gray-700'>{comment.content}</p>
                                                                                    <p className='text-xs text-gray-400'>{formatDate(comment.createdAt)}</p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )) : post.comments.slice(0, 3).map((comment) => (
                                                                    <div key={comment._id} className='flex flex-row'>
                                                                        <img src={userData[comment.userId]?.profilePic || userimg} alt={userData[comment.userId]?.fullName || 'User Profile'} className='basis-auto justify-self-start pt-1 sm:w-10 sm:h-10 w-7 h-7 rounded-full' />
                                                                        <div className='comment-item flex flex-col align-middle ml-1 basis-11/12 bg-gray-50 p-2 rounded-xl'>
                                                                            <div className='flex flex-row justify-between items-center'>
                                                                                <p className='sm:text-base text-sm text-gray-900 font-medium'>{userData[comment.userId]?.fullName || 'User Name'}</p>
                                                                                {comment.userId === loggedInUserId && (
                                                                                    <div className='flex flex-row gap-2 items-center'>
                                                                                        {/* Edit button */}
                                                                                        <span className='text-gray-500 text-xs sm:text-sm cursor-pointer' onClick={() => {
                                                                                            setEditingCommentId(comment._id); // Set editing state for this comment
                                                                                            setEditedCommentContent(comment.content);
                                                                                            setShowInputEdit(!showInputEdit) // Set initial edited content to current comment content
                                                                                        }}>Edit</span>
                                                                                        {/* Delete button */}
                                                                                        <span className='text-gray-500 text-xs sm:text-sm cursor-pointer' onClick={() => handleDeleteComment(post.id, comment._id)}>Delete</span>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            {showInputEdit && editingCommentId === comment._id ? (
                                                                                // Input field for editing the comment
                                                                                <div className='flex flex-row items-center justify-between gap-1'>
                                                                                    <input
                                                                                        type='text'
                                                                                        value={editedCommentContent}
                                                                                        onChange={(e) => setEditedCommentContent(e.target.value)}
                                                                                        placeholder='Edit your comment...'
                                                                                        className='sm:text-base text-sm text-gray-800 bg-white border-black focus:outline-none bg-transparent'
                                                                                    />
                                                                                    <button
                                                                                        onClick={() => handleEditComment(post.id, comment._id)}
                                                                                        className='text-xs sm:text-sm text-blue-500 cursor-pointer focus:outline-none'>Save
                                                                                    </button>
                                                                                </div>
                                                                            ) : (
                                                                                // Display comment content
                                                                                <div className='comment-item flex flex-row items-center justify-between gap-1'>
                                                                                    <p className='sm:text-base text-sm text-gray-700'>{comment.content}</p>
                                                                                    <p className='text-xs text-gray-400'>{formatDate(comment.createdAt)}</p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                {!showAllComments && post.comments.length > 3 && (
                                                                    <button
                                                                        onClick={handleShowMoreComments}
                                                                        className='text-blue-500 hover:underline cursor-pointer focus:outline-none'>Show More
                                                                    </button>
                                                                )}
                                                            </div>
                                                            <div className='add-comment-section flex flex-row justify-between gap-1' >
                                                                <input
                                                                    type='text'
                                                                    placeholder='Add a comment...'
                                                                    value={newComment}
                                                                    onChange={(e) => setNewComment(e.target.value)}
                                                                    className='p-2 border focus:border rounded-lg focus:border-orange-500 focus:outline-none w-9/12'
                                                                />
                                                                <button onClick={() => handleAddComment(post.id)}
                                                                    style={{ background: 'linear-gradient(180deg, #ff6535 0%, #ff9e48 48.9%, #ffce58 100%)' }} className='w-3/12 px-2 py-1 rounded-lg text-white'>Post</button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    <NewPost onNewPost={handleNewPost} onFilterApply={handleFilterApply} onClear={clearFilters} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
                    {!isModalOpen && <MainMenu />}
                </div>
            </section>
        </>
    );
}
export default Forum;