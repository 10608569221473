import React from "react";
import { useState } from "react";
import crossBtn from "../assets/img/x.png";
import submitBtn from "../assets/img/submitBtn.png"
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios"
import { MdOutlineEmail } from "react-icons/md";
import desktop_email from "../assets/img/email_icon.png";


const apiUrl = process.env.REACT_APP_API_URL;


const EmailModal = ({setMessage,setShowMessage,profileData,setProfileData}) => {
  // console.log(profileData);
  const user_type =
    Cookies.get("user_type") === "Teacher" ? "Student" : "Teacher";
    const navigate = useNavigate()

  const [user_email, set_user_email] = useState("");

  async function handleSubmit(){

    try{
    const signup_response = await axios.post(`${apiUrl}/api/auth/signup`, {
      l_name: profileData.name,
      l_email:user_email,
      l_social_id:profileData.social_id,
      account_type: profileData.account_type,
      type: user_type,
    });
  
    const signup_data = signup_response.data;
    Cookies.set("authToken", signup_data.token);
    setProfileData({...profileData,"display":"none"})
    navigate("/edit-profile");
  }
  catch(err){
    setShowMessage(true)
    setMessage("Error occured ! Please try again")
  }
  }

  return (
    <div
      className="flex items-center justify-center mt-[100px] sm:px-5 absolute top-[0%] w-full"
      style={{ display: profileData.display }}
    >
      <div className="relative transform overflow-hidden rounded-3xl bg-white px-4 pb-4 pt-5 text-left shadow-2xl  sm:my-8  sm:w-full sm:max-w-[791px] sm:p-6 z-50">
        <div className="absolute right-0 top-0 pr-4 pt-4 sm:block">
          <button type="button">
            <img
              src={crossBtn}
              alt=""
              onClick={() =>
                setProfileData({ ...profileData, display: "none" })
              }
            />
          </button>
        </div>
        <div>
          <h1 className="text-gradient font-semibold">Please enter your email address to continue.</h1>
          <div className="sm:flex gap-4 items-center justify-center">
          <div>
                  <label
                    htmlFor="email"
                    className="block sm:hidden text-sm font-semibold text-[#585858] pl-4"
                  >
                    Email
                  </label>
                  <div className="relative mt-1">
                    <div className="absolute inset-y-0 left-0 pl-[15px] flex items-center pointer-events-none">
                      <MdOutlineEmail className="sm:hidden w-[15px] h-[20px] text-gray-500" />
                      <img
                        className="hidden sm:block"
                        src={desktop_email}
                        alt=""
                      />
                    </div>
                    <input
                      type="email"
                      required
                      className="pl-10 pr-4 py-3 placeholder:text-gray-500 sm:placeholder:text-orange-500 border border-[#585858] sm:border-orange-500 outline-none text-sm w-full rounded-full"
                      placeholder="Enter Email"
                      value={user_email}
                      onChange={(e) =>
                        set_user_email(e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center cursor-pointer">
                  <img src={submitBtn} height={50} alt="" onClick={handleSubmit}/>
              </div>
          </div>

         
        </div>
      </div>
    </div>
  );
};

export default EmailModal;
