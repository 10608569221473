import { useState, useEffect, useRef } from "react";
import { useNavigate ,useLocation} from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import PersonalDetails from "./personalDetails";
import Education from "./education";
import bg from "../../assets/img/background-2.png";
import logo from "../../assets/img/logo.svg";
import NavBar from "../../components/nav/navBar";
import Header from "../../components/headerBackground";
import Nav from '../../components/nav/nav'
const apiUrl = process.env.REACT_APP_API_URL;

const StudentProfile = ({ setMessage, setShowMessage, profilePic, found }) => {
  const navigate = useNavigate();
  const loggedInUserId = Cookies.get('loggedInUserId');
  const [loggedInUser, setLoggedInUser] = useState("");
  const location = useLocation();
  const user_type = Cookies.get("user_type");
  const url = process.env.REACT_APP_API_URL;
  const [currentPage, setCurrentPage] = useState("Personal details");
  const authToken = Cookies.get("authToken"); // Access the authToken from cookie
  const file_url = 'https://cgb-storage.blr1.digitaloceanspaces.com';
  let defaultProfile = `${file_url}/profile-pictures/defaultProfile.png`

  const initialFormData = {
    fullName: "",
    email: "",
    verified: false,
    country: "",
    city: "",
    time_zone: "",
    linkedin_profile: "",
    about: "",
    category: "",
    subCategory: [],
    interest_category: "",
    interest_subCategory: [],
    willing_to_relocate: "no",
    access_google_calendar: "yes",
    preferred_locations: [
      // {
      //   country:'',
      //   city:[]
      // }
    ],
    languages: [
      // {
      //   language:"",
      //   proficiency:""
      // }
    ],
    profilePic: "",
    access_calendar: false,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [selectedFile, setSelectedFile] = useState(null);
  const containerRef = useRef(null);
  // useEffect(() => {
  //   if (containerRef.current) {
  //     containerRef.current.scrollTo(0, 0);
  //   }
  // }, [currentPage]);

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [currentPage]);

  async function getProfileData() {
    try {
      let response = await axios.get(
        `${apiUrl}/api/student/get-student-details`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const student = response.data.student;
      const student_details = response.data.studentDetails;

      // Auto-fill fields

      if (student_details) {
        const {
          country,
          state,
          city,
          time_zone,
          linkedin_profile,
          about,
          category,
          subCategory,
          interest_category,
          interest_subCategory,
          willing_to_relocate,
          preferred_locations,
          languages,
          access_calendar,
        } = student_details;

        setFormData({
          ...formData,
          email: student.email,
          fullName: student.fullName,
          verified: student.verified,
          profilePic: student.profilePic,
          country: country,
          state: state,
          city: city,
          time_zone: time_zone,
          about: about,
          category: category,
          subCategory: subCategory,
          interest_category: interest_category,
          interest_subCategory: interest_subCategory,
          languages: languages,
          willing_to_relocate: willing_to_relocate,
          preferred_locations: preferred_locations,
          linkedin_profile: linkedin_profile,
          access_calendar: access_calendar,
        });
      } else {
        setFormData({
          ...formData,
          email: student.email,
          fullName: student.fullName,
          verified: student.verified,
          profilePic: student.profilePic,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //console.log("handleChange:", name, value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  async function submitForm(e) {
    e.preventDefault();
    // console.log(formData);

    // some validations before submitting the data
    if (
      formData.fullName === "" ||
      formData.time_zone === "" ||
      formData.about === "" ||
      formData.country === "" ||
      formData.category === ""
    ) {
      setShowMessage(true);
      setMessage("Please fill in the mandatory fields.");
      return;
    }
    // else if (
    //   formData.subCategory.length === 0 ||
    //   formData.interest_subCategory.length === 0
    // ) {
    //   setShowMessage(true);
    //   setMessage("Please choose atleast one subcategory.");
    //   return;
    // }
    else if (formData.languages.length === 0) {
      setShowMessage(true);
      setMessage("Please add atleast one language.");
      return;
    } else if (
      formData.willing_to_relocate &&
      formData.preferred_locations.length === 0
    ) {
      setShowMessage(true);
      setMessage(
        "Please add atleast one preferred area of relocation if you are willing to relocate."
      );
      return;
    }

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend[`${key}`] = value;
    });
    // console.log("Form Data to be save ===>", formData)
    try {
      let response = await axios.post(
        `${apiUrl}/api/student/edit-profile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setShowMessage(true);
      setMessage("Profile updated successfully!");
      // console.log(response)
      // navigate("/cabinet");
      const redirectTo = location.state?.from || "/cabinet";
    navigate(redirectTo);
    } catch (err) {
      console.log(err);
      setShowMessage(true);
      setMessage(err.response.data.error);
    }
  }

  const handleLogout = async () => {
    try {
      // Make a request to the backend logout endpoint with the token in the headers
      const response = await axios.post(
        `${apiUrl}/api/auth/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
          },
        }
      );

      // Handle successful logout response
      setShowMessage(true);
      setMessage("Logged out successfully");
      Cookies.remove("loggedInUserId");
      Cookies.remove("authToken");
      Cookies.remove("user_type");
      Cookies.remove("account_type");
      navigate("/login"); // Redirect to the login page or any other appropriate page
    } catch (error) {
      // Handle logout error
      console.error("Error logging out:", error);
    }
  };

  const handleNext = () => {
    // Validate if all required fields are filled
    if (
      formData.fullName !== "" &&
      formData.time_zone !== "" &&
      formData.about !== "" &&
      formData.country !== "" &&
      formData.category !== ""
    ) {
      setCurrentPage("Education");
    } else {
      setShowMessage(true);
      setMessage("Please fill in all required fields in the Personal Details section.");
    }
  };

  useEffect(() => {
    getLoggedInUserDetails();
  }, [authToken, loggedInUserId]);

  const getLoggedInUserDetails = async () => {
    try {
      // console.log(loggedInUserId);
      const response = await axios.get(`${url}/api/user-info/get-users/${loggedInUserId}`, {
        headers: {
          "Authorization": `Bearer ${authToken}`
        }
      });
      // console.log('loggedinuser', response.data);
      setLoggedInUser(response.data);
    } catch (error) {
      console.log('Error fetching user', error);
    }
  };
  const handleEducationalDetailsClick = () => {
    if (formData.verified) {
      setCurrentPage("Education");
    } else {
      alert('Please verify your account to access Professional Details.');
      navigate("/verify", { state: { email: formData.email } });
    }
  }
  
  return (
    <>
      {found ? (
        <NavBar />
      ) : (
        <Nav />
      )}
      <div className="sm:mt-8 lg:mt-14 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 font-primary">
        <div>
          <Header />
          <div className="flex flex-col items-center mt-12 sm:mt-0 sm:px-5 w-full">
            <div className="mt-[100px] h-[65vh] sm:mt-5 flex flex-col w-full md:grid grid-cols-3 gap-3 sm:min-h-[100vh] sm:py-[55px] justify-center">
              <div className="w-full mx-auto h-fit p-[15px] sm:py-9 shadow-md rounded-[30px] sm:border border-orange-500">
                <div className="flex flex-col sm:flex flex-col justify-center items-center ">
                  {selectedFile != null ? (
                    <img
                      src={selectedFile}
                      alt="Profile"
                      className="rounded-full w-20 h-20 sm:w-24 sm:h-24 md:w-24 md:h-24 lg:w-34 lg:h-34"
                    />
                  ) : (
                    <img
                      src={
                        loggedInUser.profilePic !== "" && loggedInUser.profilePic !== undefined
                          ? loggedInUser.profilePic
                          : defaultProfile
                      }
                      alt="Profile"
                      className="rounded-full border w-20 h-20 sm:w-24 sm:h-24 md:w-24 md:h-24 lg:w-34 lg:h-34"
                    />
                  )}
                  <h1 className="font-semibold text-lg sm:text-2xl text-[#585858]">
                    {formData.fullName}
                  </h1>
                  <p className="text-[#afafaf] text-sm font-normal">Student</p>
                </div>
                <div className="flex flex-row sm:flex-col gap-4 items-center justify-center mt-2">
                  <button
                    onClick={() => setCurrentPage("Personal details")}
                    className={
                      currentPage === "Personal details"
                        ? "shadow-md text-white sm:w-full font-semibold text-center w-full text-[12px] sm:text-[14px] lg:text-[18px] py-2 px-2 rounded-full bg-gradient"
                        : "shadow-md bg-white font-semibold sm:w-full text-center w-full text-[12px] sm:text-[14px] lg:text-[18px] py-2 px-2 rounded-full text-gradient"
                    }
                  >
                    Personal Details
                  </button>
                  <button
                    onClick={handleEducationalDetailsClick}
                    className={
                      currentPage === "Education"
                        ? "shadow-md text-white sm:w-full font-semibold text-center py-2 px-2 w-full text-[12px] sm:text-[14px] lg:text-[18px] rounded-full bg-gradient"
                        : "shadow-md bg-white font-semibold sm:w-full text-center py-2 px-2 w-full text-[12px] sm:text-[14px] lg:text-[18px] rounded-full text-gradient"
                    }
                  >
                    Educational Details
                  </button>
                </div>
              </div>

              <div ref={containerRef} className="col-span-2 overflow-y-auto grid grid-cols-1 gap-x-2 gap-y-2 p-2 sm:p-5 bg-white sm:drop-shadow-lg h-fit sm:min-h-[1200px] sm:max-h-fit rounded-[25px]" style={{ maxHeight: 'calc(100vh - 380px)' }}>
                {/* <h1 className="hidden sm:block text-gradient font-semibold text-xl" >
                  Edit Profile
                </h1> */}

                {currentPage === "Personal details" ? (
                  <PersonalDetails
                    formData={formData}
                    setFormData={setFormData}
                    handleChange={handleChange}
                    setSelectedFile={setSelectedFile}
                    setCurrentPage={setCurrentPage}
                  />
                ) : (
                  <Education
                    formData={formData}
                    setFormData={setFormData}
                    handleChange={handleChange}
                    submitForm={submitForm}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default StudentProfile;
