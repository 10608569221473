import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import axios from 'axios';
import { IoIosShareAlt } from 'react-icons/io';
import { AiOutlinePlus } from 'react-icons/ai';
import { BsPencilSquare } from 'react-icons/bs';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import Loading2 from '../../Loading2';


const Calls_Booking_list = (props) => {
  const authToken = Cookies.get('authToken');
  const user_type = Cookies.get('user_type');
  const loggedInUserId = Cookies.get('loggedInUserId');
  const [loading,setLoading]=useState(true)
  const { date } = props;
 
  //  console.log(date)
 
  const [formattedDate, setFormattedDate] = useState(formatDateToYYYYMMDD(date));
  const [userTimeZone, setUserTimeZone] = useState();
  const navigate = useNavigate();
  const [times, setTimes] = useState([{ start_time: '', end_time: '', status: '', conference_id: '', amount: '', currency: '' }]);
  const [fetchCompleted, setFetchCompleted] = useState(0);

  const [currencyAndAmountHandled, setCurrencyAndAmountHandled] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

useEffect(() => {
  const fetchData = async () => {
    await Fetch_Time_Slots();
  };
  // console.log("changed Date",date)
  // setFormattedDate(formatDateToYYYYMMDD(date))
  // console.log("Formatted date",formattedDate)
  // console.log("Dated changed")
  
  fetchData();

  
 
}, [date]); 

useEffect(() => {

  
    handleTimeConversion();
    if (!currencyAndAmountHandled) {
      //handleCurrencyAndAmount();
      setCurrencyAndAmountHandled(true);
    }

  
  
}, [fetchCompleted,date]); 


// FUNCTION TO FORMAT DATE - YYYY-MM-DD

  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // FUNCTION TO FETCH THE TIME SLOT OF USER ON SELECTED DATE

  const Fetch_Time_Slots = async () => {
    try {
      // console.log(formattedDate)
      const response = await axios.post(
        `${apiUrl}/api/calender/usertimeslot`,
        {
          date: formatDateToYYYYMMDD(date),
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const data = response.data;
      // console.log(data)
      setUserTimeZone(data.user_data);

      let timeSlots = [];

      for (const slot of data.data) {
        let slotDetails = {
          start_time: slot.start_time,
          end_time: slot.end_time,
          status: slot.status,
          conference_id: slot.conference_id,
          id:slot._id,
        };
        // console.log("Call id ==> ",slot.call_id)
        if (slot.call_id) {
          const callResponse = await axios.get(
            `${apiUrl}/api/call/details/${slot.call_id}`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
              },
            }
          );
          const callDetails = callResponse.data;
          // console.log("Call Details ===>",callDetails)
          const call_id = callDetails._id
          const url =callDetails.URL
          // console.log("Call Price ===>", callDetails.Price.amount)
          slotDetails = { ...slotDetails, ...callDetails.Price, call_id,url };
          // console.log("Slot Complete Details ==>",slotDetails)
        }

        timeSlots.push(slotDetails);
      }

      data.remainingTimeSlots.forEach((startTime) => {
        timeSlots.push({
          start_time: startTime,
          end_time: '',
          status: 'unavailable',
        });
      });

      setTimes(timeSlots);
      // console.log("Time Slots",times)
      
      setCurrencyAndAmountHandled(true);
      setFetchCompleted(fetchCompleted+1);
      setLoading(false)
      
    } catch (error) {
      if (error.response) {
        console.error('Server Error:', error.response.data);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Request Error:', error.message);
      }
    }
  };

  // CONVERTING TIME SLOTS TO USER TIME ZONE

  const handleTimeConversion = async () => {
    // console.log("times ===>",times)
    const asiaKolkataTimes = times.map(({ start_time, end_time, status, conference_id, id, amount, currency,call_id,url }) => {
      const currentStartTime = moment.tz(start_time, 'HH:mm', 'Asia/Kolkata');
      const convertedStartTime = currentStartTime.clone().tz('Asia/Kolkata');
  
      let convertedEndTime = '';
      if (end_time) {
        const currentEndTime = moment.tz(end_time, 'HH:mm', 'Asia/Kolkata');
        convertedEndTime = currentEndTime.clone().tz('Asia/Kolkata').format('HH:mm');
      }
  
      // if (amount !== undefined) {
      //   amount = CalculateAmount(Number(amount));
      // }
  
      return {
        displayStartTime: convertedStartTime.format('HH:mm'),
        displayEndTime: convertedEndTime,
        status,
        conference_id,
        call_id,
        id,
        amount,
        currency,
        url,
      };
    });
    

    asiaKolkataTimes.sort((a, b) => {
      const timeA = moment(a.displayStartTime, 'HH:mm');
      const timeB = moment(b.displayStartTime, 'HH:mm');
      return timeA.diff(timeB);
    });

    await setTimes(asiaKolkataTimes);
    
  };

  // TOTAL AMOUNT INCLUDING SERVICE CHARGES, GST 

  const SERVICE_CHARGE_PERCENTAGE = 0.05; // 5%
  const GST_PERCENTAGE = 0.18; // 18%
  

  const calculateServiceCharge = (amount) => {
    //console.log("modified amount ==>",amount * SERVICE_CHARGE_PERCENTAGE)
    return Math.ceil(amount * SERVICE_CHARGE_PERCENTAGE);
    
  };
  const calculateGST = (amount) => {
    return Math.ceil(amount * GST_PERCENTAGE);
  };

  const CalculateAmount = (amount) =>{
    const serviceCharge = calculateServiceCharge(amount);
    //console.log("service Charges ==> ",serviceCharge)
    //const updated_amount = Number(amount) + serviceCharge
    //console.log("updated amount ==> ",updated_amount)
    const gst = calculateGST(amount);
    //console.log("GST ==>",gst)
    const totalAmount = Math.ceil(Number(amount) + serviceCharge + gst);
    //console.log("Total Amount ==>",totalAmount)
    return totalAmount
  }

  // ACTION WHEN CLICKED ON THE TIME SLOT

  const handleTimeSlotClick = async (timeSlot) => {
    // console.log(timeSlot)
    if(timeSlot.id !== null && timeSlot.status === 'booked'){
      navigate('/user_events')
    }
    
    else{
    if (timeSlot.status === 'booked' && timeSlot.conference_id != null) {
      try {
        const response = await axios.post(
          `${apiUrl}/api/conference/getdetails`,
          {
            conference_id: timeSlot.conference_id,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          }
        );

        const cardData = response.data;
        const card = cardData.conferenceData;
        navigate('/conferece_details', { state: { card } });
      } catch (error) {
        console.error('Error fetching conference details:', error);
      }
    }
    }
    
  };

  // NAVIGATE TO AVAILABILITY

  const handleCall = () => {
    navigate('/availability',{state:{date}});
  };

  // SHARE

  const handleshare = () => {
    if (navigator.share) {
      const baseUrl = window.location.origin;
      navigator.share({
          title: 'Check out this book list!',
          text: 'Here is an amazing book list you should see:',
          url: `${baseUrl}/book_list/${loggedInUserId}`,
      })
      .then(() => console.log('Shared successfully'))
      .catch((error) => console.error('Error sharing:', error));
  } else {
      alert('Sharing is not supported on this browser.');
  }
  };

  // HANDLE EDIT CONTROL

  const handleEdit = async(id,start_time,end_time,amount,call_id,Url) =>{
    //  console.log(id,start_time,end_time,amount,call_id,Url)
    navigate('/edit_time_slot',{state:{id,start_time,end_time,amount,call_id,Url}})
  }

  // HANDLE DELETE CONTROL

  const handleDelete = async (id,event) => {
    event.stopPropagation(); // Prevent the event from bubbling up to the parent elements
  event.preventDefault(); // Prevent the default behavior of the delete icon click event
    try {
      const response = await axios.delete(`${apiUrl}/api/calender/delete_timeslots/${id}`);
      // console.log(id)
      // Handle the response
      // console.log('Time slot deleted successfully:', response.data);  
      window.alert('Time slot deleted successfully')
    } catch (error) {
      
      console.error('Error deleting time slot:', error.response.data);
      
      
    }
  };

  if(loading){
    return <Loading2/>
  }

  return (
    <div className='flex flex-col lg:h-[470px] justify-center lg:mt-5 w-full  '>
      <div className=''>
        <div className='mb-4  overflow-y-auto lg:h-[500px] h-[300px] '>
          {fetchCompleted &&
            times.map((timeSlot, index) => (
              <div
                key={index}
                className={`text-xs time_line lg:text-sm ${
                  timeSlot.status === 'booked'
                    ? 'booked'
                    : timeSlot.status === 'available'
                    ? 'available'
                    : ''
                }`}
                onClick={() => {
                  if (timeSlot.status === 'booked') {
                    handleTimeSlotClick(timeSlot);
                  }
                }}
              >
                <div className='  text-xs lg:text-md  flex flex-row items-center py-1 rounded-full' >
                  {timeSlot.displayStartTime}{' '}
                  {timeSlot.displayEndTime ? `- ${timeSlot.displayEndTime}` : ''}
                </div>
                <hr className={`${timeSlot.status === 'booked' || timeSlot.status === 'available' ? ' ml-1 w-2/5 lg:w-3/5' : 'ml-1 w-4/5 lg:w-4/5'}`} />
                {timeSlot.status === 'available' ? (
                  
                  <div className='ml-3 mr-2 text-xs lg:text-md '>
                    Price: {timeSlot.amount === '0' ? 'Free' : `${timeSlot.currency} ${timeSlot.amount}`}
                  </div>
                ) : null}
                {user_type === 'Teacher' && timeSlot.status === 'available' ? (
                  <div className='flex gap-1 lg:gap-3 ml-1 lg:ml-2 '>
                    <BsPencilSquare className=' text-xl' onClick={()=>handleEdit(timeSlot.id,timeSlot.displayStartTime,timeSlot.displayEndTime,timeSlot.amount,timeSlot.call_id,timeSlot.url)} />
                    <RiDeleteBin6Fill className=' text-xl' onClick={(event)=>handleDelete(timeSlot.id,event)} />
                  </div>
                ) : timeSlot.status === 'available'? (<div className=' bg-white py-2 px-4'>Book Now</div>):null}
              </div>
            ))}
        </div>

          {/* ------------------- CALL ACTIONS ------------------------ */}

      
          <div className='flex flex-row  gap-2 justify-around items-center mb-5 lg:hidden sm:block  w-full'>
            <div className='border-2 border-orange-500 rounded-full w-56 flex gap-2 justify-center text-sm font-semibold py-2' onClick={handleCall}>
              <span className='text-orange-600'>Set availability</span>
              <AiOutlinePlus style={{ color: 'orange', fontSize: '1.2rem' }} />
            </div>
            <div className='border-2 border-orange-500 rounded-full w-56 flex gap-2 justify-center text-sm font-semibold py-2' onClick={handleshare}>
              <span className='text-orange-600'>Share availability</span>
              <IoIosShareAlt style={{ color: 'orange', fontSize: '1.5rem' }} />
            </div>
          </div>
        
      </div>
    </div>
  );
};

export default Calls_Booking_list;
