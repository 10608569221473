
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { BsChevronRight } from "react-icons/bs";
import Cookies from "js-cookie";
import Empty_data from '../../Empty_data';
import Loading2 from '../../Loading2';
import { useNavigate, useParams } from 'react-router-dom';
import NavBar from '../../nav/navBar';
import '../../Call_And_Conference/calender.css'
import MainMenu from '../../MenuBottom/MainMenu';
import HeaderBackground from '../../headerBackground';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet-async';

const Student_Course_List = () => {

  const { id } = useParams();
  const [cards, SetCard] = useState(0);
  const [teacher_country, setTeacher_country] = useState('')
  const [teacher_time_zone, setTeacher_Time_Zone] = useState('')
  const [loading, setLoading] = useState(true);
  const authToken = Cookies.get("authToken");
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [user_time, setUser_time] = useState('')
  const user_loggedInId = Cookies.get('loggedInUserId')
  const [user_country, setUser_country] = useState('')

  useEffect(() => {
    const fetchInitialData = async () => {
      try {

        await fetch_user_Details();

        await fetch_teacher_Details();
        if (user_country !== '' && teacher_country !== '') {
          await FetchCourseDetails();
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchInitialData();
  }, [teacher_country, user_country, user_time, teacher_time_zone, authToken, id])
  useEffect(() => {

  }, [cards, teacher_country, user_country, user_time, teacher_time_zone])

  const fetch_user_Details = async () => {
    try {

      const user_details = await axios.get(`${apiUrl}/api/auth/user_details/${user_loggedInId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      })
      const user = user_details.data;
      setUser_country(user.user_Details.country)

      setUser_time(user.user_Details.time_zone);
    } catch (error) {
      console.error('Server Error:', error.response.data);
    }
  }

  const FetchCourseDetails = async () => {
    try {
      setLoading(true)
      const response = await axios.get(
        `${apiUrl}/api/courses/get_teacher_courses?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const data = response.data;
      // console.log(data);
      setLoading(false);
      const Data = data.courseWithTimezone
      let convertedData;
      SetCard(Data)
      if (user_country === teacher_country) {
        console.log(user_country, teacher_country)
        // console.log("No need of converstion")
        convertedData = Data
        SetCard(convertedData);
      } else {
        //console.log("Converting to user time zone")
        convertedData = Data.map(conference => {
          const { Start_Time, Start_Date, End_Date, ...rest } = conference;


          const startDateTimeInUserTimeZone = moment.tz(`${Start_Date} ${Start_Time}`, 'YYYY-MM-DD HH:mm', teacher_time_zone)
            .clone()
            .tz(user_time);

          const adjustedConferenceDate = startDateTimeInUserTimeZone.format('YYYY-MM-DD');
          // const adjustedEndDate = endDateTimeInUserTimeZone.format('YYYY-MM-DD');

          const convertedStartTime = startDateTimeInUserTimeZone.format('HH:mm');
          // const convertedEndTime = endDateTimeInUserTimeZone ? endDateTimeInUserTimeZone.format('HH:mm') : '';

          return {
            ...rest,
            Start_Date: adjustedConferenceDate,
            End_Date: End_Date,
            Start_Time: convertedStartTime,
            // End_Time: convertedEndTime
          };
        });
        // Sorting logic
        const sortedCourses = convertedData.sort((a, b) => {
          const startDateA = new Date(a.Start_Date);
          const startDateB = new Date(b.Start_Date);
          const endDateA = new Date(a.End_Date);
          const endDateB = new Date(b.End_Date);

          if (startDateA - startDateB !== 0) {
            return startDateA - startDateB;
          } else {
            return endDateA - endDateB;
          }
        });
        SetCard(sortedCourses);
        setLoading(false);
      }
    } catch (e) {
      console.log(e.response ? e.response.data : e.message);
    }
  };

  const fetch_teacher_Details = async () => {
    try {

      const user_details = await axios.get(`${apiUrl}/api/auth/user_details/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      })
      const user = user_details.data;
      //console.log("userDetails",user.user_Details)
      setTeacher_Time_Zone(user.user_Details.time_zone)
      setTeacher_country(user.user_Details.country)
      //console.log(teacher_country,teacher_time_zone)
      return user.user_Details.country;

    } catch (error) {
      console.error('Server Error:', error.response.data);
    }
  }
  if (cards.length === 0 && !loading) {
    return <Empty_data />
  }

  const handleCardClick = (card) => {
    navigate('/course_details_page', { state: { card, teacher_country } });
  }

  if (loading) {
    return <Loading2 />
  }
  return (
    <section className=" sm:mb-0 font-primary">
      <Helmet>
        <title>Courses - Career Guidance Buddy</title>
        <meta name="keywords" content="chat with student,chat with teacher, education, online class" />
      </Helmet>
      <NavBar />
      <div className='sm:mt-12 lg:mt-18 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 overflow-y-auto' style={{ maxHeight: 'calc(100vh - 80px)' }}>
        <div className=" sm:min-h-[85vh] sm:py-[55px] justify-center overflow-y-auto">
          <div>
            {/* LOGO WITH DESIGN */}
            <HeaderBackground />
            {/* Cards Container */}
            <div className='text-center mt-40 sm:mt-0 font-semibold text-gradient text-base sm:text-lg lg:text-xl'>
              <p>Courses Available</p>
            </div>
            {cards.length === 0 && !loading ? (
              <Empty_data />
            ) : (
              <div className="flex flex-wrap justify-center gap-4 sm:gap-6 items-center w-full mt-10 px-5 text-sm sm:text-base">
                {cards.map((card, index) => (
                  <div
                    key={index}
                    onClick={() => handleCardClick(card)}
                    className="card w-full sm:w-[48%] lg:w-[48%] p-5 cursor-pointer rounded-[20px] mb-5"
                    style={{ boxShadow: "0px 2px 6px 0px #00000040" }}

                  >
                    <div className="flex justify-between items-center">
                      <div className="student_status">{card.Title}</div>
                      <BsChevronRight className="hidden sm:block" />
                    </div>
                    <div>
                      <p className='font-medium text-sm sm:text-base'>Duration: {card.Duration}</p>
                    </div>
                    <div className='flex sm:flex-row flex-col justify-between sm:gap-2 lg:gap-0'>
                      <div className="flex justify-between bg-[#D1FFD0] rounded-[20px] items-center mt-4">
                        <div className="w-fit px-3 py-2 text-left font-medium flex justify-center">
                          {`${new Date(card.Start_Date).toLocaleDateString()} - ${new Date(card.End_Date).toLocaleDateString()} `}
                        </div>
                        <BsChevronRight className="sm:hidden mr-1 font-medium" />
                      </div>
                      <div className="flex justify-between bg-[#D1FFD0] rounded-[20px] items-center mt-4">
                        <div className=" w-18 px-5 py-2 text-left font-medium flex justify-start">
                          {`${card.Start_Time}`}
                        </div>
                      </div>
                    </div>
                    {/*                                     
                    <div className="mt-2">
                        <a href={card.link} className="text-[#AFAFAF] font-montserrat font-normal text-sm whitespace-nowrap overflow-hidden overflow-ellipsis">
                            {card.URL}
                        </a>
                    </div> */}
                  </div>
                ))}
              </div>)}
          </div>
        </div>
        <div className='flex justify-center'>
          <MainMenu />
        </div>
      </div>
    </section>
  )
}

export default Student_Course_List;
