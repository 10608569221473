import React, { useRef, useState } from 'react';
import NavHome from '../../components/nav/navHome';
import Footer from '../../components/nav/footer';
import Header from '../../components/headerBackground';
import Cookies from 'js-cookie';
import NavBar from '../../components/nav/navBar';
import { FaPaperclip } from 'react-icons/fa';
import axios from 'axios';

const ContactUs = ({ setMessage, setShowMessage }) => {
    const isAuthenticated = Cookies.get('authToken') !== undefined;
    const url = process.env.REACT_APP_API_URL;
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
        attachment: null
    });
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleAttachmentChange = (e) => {
        setFormData({
            ...formData,
            attachment: e.target.files[0]
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const data = new FormData();
        data.append('name', formData.name);
        data.append('email', formData.email);
        data.append('subject', formData.subject);
        data.append('message', formData.message);
        data.append('attachment', formData.attachment);

        try {
            const response = await axios.post(`${url}/api/contactUs/send-email`, data);
            // console.log(response.data);
            alert('Message sent successfully!');
        } catch (error) {
            console.error(error);
            alert('Failed to send message. Please try again later.');
        }

        setLoading(false);
        setFormData({
            name: '',
            email: '',
            subject: '',
            message: '',
            attachment: ''
        });
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset file input
        }
    };

    return (
        <div className="">
            {isAuthenticated ? <NavBar /> : <NavHome />}
            <section className="mb-0 sm:mb-0 font-primary ">
                <Header />
                <div className="sm:mt-20 lg:mt-28 mt-[160px] mx-2 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 flex flex-col justify-center gap-4">
                    <h2 className="font-semibold sm:text-2xl lg:text-3xl text-xl text-center text-[#585858]">
                        CONTACT US
                    </h2>
                    <h2 className="font-medium sm:text-lg lg:text-lg text-base text-left text-[#585858] my-4">
                        CareerGuidanceBuddy is a software application owned,
                        developed and run by CGB ORG company. For more details
                        about CGB ORG please visit -{' '}
                        <a
                            href="https://cgborg.com/"
                            className="text-blue-400"
                        >
                            www.cgborg.com
                        </a>
                    </h2>
                    <div className="sm:grid grid-cols-2 items-start gap-10 mb-4 justify-center">
                        <form onSubmit={handleSubmit} className=" text-[#585858]">
                            <h2 className="sm:text-xl text-lg font-medium mb-6 text-[#585858]">
                                Contact Us
                            </h2>
                            <div className="mb-4">
                                <label
                                    htmlFor="name"
                                    className="block lg:text-lg sm:text-base text-sm font-medium text-gray-700"
                                >
                                    Name
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-gradient focus:border-gradient"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label
                                    htmlFor="email"
                                    className="block lg:text-lg sm:text-base text-sm font-medium text-gray-700"
                                >
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-gradient focus:border-gradient"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label
                                    htmlFor="subject"
                                    className="block lg:text-lg sm:text-base text-sm font-medium text-gray-700"
                                >
                                    Subject
                                </label>
                                <input
                                    type="text"
                                    id="subject"
                                    name="subject"
                                    value={formData.subject}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-gradient focus:border-gradient"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label
                                    htmlFor="message"
                                    className="block lg:text-lg sm:text-base text-sm font-medium text-gray-700"
                                >
                                    Message
                                </label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-gradient focus:border-gradient"
                                    rows="4"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label
                                    htmlFor="attachment"
                                    className="block lg:text-lg sm:text-base text-sm font-medium text-gray-700 flex items-center"
                                >
                                    Attachment{' '}
                                    <FaPaperclip className="ml-2" />
                                </label>
                                <input
                                    type="file"
                                    id="attachment"
                                    name="attachment"
                                    onChange={handleAttachmentChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-gradient focus:border-gradient"
                                    ref={fileInputRef} />
                            </div>
                            <button
                                type="submit"
                                className="w-full bg-gradient text-white p-2 rounded-md shadow-md lg:text-lg sm:text-base text-sm hover:bg-gradient mt-4"
                            >
                                {loading ?
                                    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-white" role="status">
                                    </div> : 'Submit'}
                            </button>
                        </form>

                        <div className="text-base sm:text-base lg:text-lg flex flex-col gap-6 text-left mt-2 mb-6 text-[#585858]">
                            <h2 className="font-medium sm:text-xl text-lg text-left text-[#585858] ">
                                Let Us Help!{' '}
                            </h2>
                            <div className="flex flex-col items-start gap-2">
                                <div>
                                    <span className="font-medium">
                                        Address:
                                    </span>
                                    <div className="">15 TONK ROAD,</div>
                                    <div className="">
                                        CHANDRAKAL COLONY, MATA COLONY,
                                    </div>
                                    <div className="">JAIPUR 302016</div>
                                    <div>RAJASTHAN INDIA</div>
                                </div>

                                <div className="font-medium">
                                    Email:
                                    <p className="font-normal">
                                        support@careerguidancebuddy.com
                                    </p>
                                </div>
                            </div>
                            <div className='flex flex-col gap-1'>
                                <span className="font-medium">Hours</span>
                                <div>
                                    <div>Mon 09:00 am – 05:00 pm</div>
                                </div>
                                <div>
                                    <div>Tue 09:00 am – 05:00 pm</div>
                                </div>
                                <div>
                                    <div>Wed 09:00 am – 05:00 pm</div>
                                </div>
                                <div>
                                    <div>Thu 09:00 am – 05:00 pm</div>
                                </div>
                                <div>
                                    <div>Fri 09:00 am – 05:00 pm</div>
                                </div>
                                <div>
                                    <div>Sat Closed</div>
                                </div>
                                <div>
                                    <div>Sun Closed</div>
                                </div>
                            </div>
                            <h2 className="font-medium">
                                Note: All transactions are handled by CGB ORG.
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default ContactUs;
