import React from 'react'
import { Link } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import Cookies from 'js-cookie';

const ChatFixedMenu = ({ otherUser, defaultProfile, handleToggleBlockOptions,isOnline }) => {
    const user_type = Cookies.get("user_type")
    return (
        <div className=' flex justify-between items-center w-full h-full px-2'>
            
            <Link to="/chat">
            <div className='flex flex-row items-center gap-1 cursor-pointer '>
                    <FaChevronLeft size={12} className='text-left text-[12px] sm:text-[16px] align-middle text-[#585858] font-normal' />
                    <p className='text-left text-[14px] sm:text-[16px] font-medium align-middle text-[#585858]'>Back</p>
                </div>
            </Link>
            
            <div className="flex flex-row gap-1 items-center cursor-pointer ">
                <Link to="/viewProfile">
                    <h1 className='text-left align-middle font-primary text-[15px] sm:text-[18px] font-semibold text-[#585858] '>{otherUser ? otherUser.fullName : 'Name'}</h1>
                </Link>
                {isOnline && <span className="bg-green-500 w-[6px] h-[6px] sm:w-[8px] sm:h-[8px] rounded-[100px] mt-[-8px] "></span>}
            </div>
            <div className="flex flex-row items-center justify-center mx-1 ">
                {otherUser && (
                    <img src={otherUser.profilePic !== "" && otherUser.profilePic !== undefined ? otherUser.profilePic : defaultProfile} className='w-[40px] h-[40px] sm:w-[48px] sm:h-[48px] rounded-full cursor-pointer' alt='' />
                )}
                {user_type === 'Student' && (
                    <HiOutlineDotsVertical alt="" title='Block' className='cursor-pointer text-[#585858]' size={20} onClick={handleToggleBlockOptions} />
                )}
            </div>
        </div>
    )
}

export default ChatFixedMenu