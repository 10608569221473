import React,{ useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MainMenu from '../../MenuBottom/MainMenu';
import { BsCameraVideoFill,BsPencilSquare, BsChevronLeft  } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import moment from 'moment-timezone';
import axios from 'axios';
import NavBar from '../../nav/navBar';
import { IoIosShareAlt } from 'react-icons/io';
import HeaderBackground from '../../headerBackground';



function Conference_Details() {

    const location = useLocation(); // GET LOCATION OBJECT TO ACCESS THE STATE
    const { card,teacher_country} = location.state || {}; // GET CARD DETAILS FORM LOCATION STATE
    // console.log(location.state)
    // console.log("card",card,teacher_country)
    const { Start_Time,End_Time ,Title, URL,Description,Price,Conference_Date,_id} = card;
  
    //console.log("Original Amount ===>",Price.amount)
    //console.log(_id)
    const navigate = useNavigate();
    const user_type = Cookies.get('user_type')
    const authToken = Cookies.get('authToken');
    const apiUrl = process.env.REACT_APP_API_URL;
    const [original_amount, setAmount] = useState(0);
    const [tax_amount,setTaxAmount] = useState(0);
    const user_loggedInId=Cookies.get('loggedInUserId')
    const [serviceCharges, setServiceCharges] = useState(0);
    const [GST, setGst] = useState(0);
    const [user_data,setUser_data]=useState('');
    const [isActive, setIsActive] = useState(false);
    const [isBooked, setIsBooked] = useState(false);
    const [earning,setEarning] = useState();
    const [earning_id,setEarning_id] = useState();
    const [isClickable, setIsClickable] = useState(true);
    const baseUrl = window.location.origin;
    const[isIndia,setIsIndia]=useState()
    //console.log(user_type)
    //const formattedConferenceDate = moment(card.Conference_Date).format('MMM DD - YYYY');
    const Date = Conference_Date;
    const [loading,setLoading]=useState(false)

    useEffect(()=>{
     fetchUserDetails(setIsIndia);
    },[isIndia])
    const fetchUserDetails = async (setIsIndia) => {
      try {
        const response = await axios.get(`${apiUrl}/api/auth/user_details/${user_loggedInId}`);
        const user = response.data.user_Details;
        console.log(user.country)
        if (!user.country) {
          console.error('No country name found in user details');
          return; // Exit early if no country name is available
        }
        if (user.country === 'India') {
          setIsIndia(true);
          console.log(isIndia)
        } else {
          setIsIndia(false);
        }
        
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

   

// TOTAL PRICE CALCULATION INCLUDING SERVICE CHARGES AND GST

    // const SERVICE_CHARGE_PERCENTAGE = isIndia ? 0.05 : 0.09; // 5% for indians 9% for other countries   
    const SERVICE_CHARGE_PERCENTAGE =  0.09; 
    const GST_PERCENTAGE = isIndia ? 0.18 :0.15 ; // 18%

const calculateServiceCharge = (amount) => {
  //console.log("modified amount ==>",amount * SERVICE_CHARGE_PERCENTAGE)
  setAmount(amount)
  return Math.ceil(amount * SERVICE_CHARGE_PERCENTAGE);
  
};

const calculateGST = (amount) => {
  //setGst(amount * GST_PERCENTAGE)
  return Math.ceil(amount * GST_PERCENTAGE);
};


const updateButtonState = (Conference_Date) => {
  // Assuming Start_Time is in the format "HH:mm"
  const startHourMinute = Start_Time.split(':');
  const now = new window.Date(Conference_Date);

// Construct a new date using the current date and the Start_Time
const start = new window.Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  parseInt(startHourMinute[0]), // hours
  parseInt(startHourMinute[1]), // minutes
  0, // seconds
  0 // milliseconds
).getTime();

// console.log("Start ==> ", start, new window.Date(start).toLocaleString());

const fiveMinutes = 5 * 60 * 1000;
const tenMinutes = 10 * 60 * 1000;
const currentTime = now.getTime();

console.log("Current Time ==> ", currentTime, now.toLocaleString());

console.log("Time range ==> ", start - fiveMinutes, " to ", start + tenMinutes, 
            new window.Date(start - fiveMinutes).toLocaleString(), 
            " to ", 
            new window.Date(start + tenMinutes).toLocaleString());

if (currentTime >= start - fiveMinutes && currentTime <= start + tenMinutes) {
  setIsActive(true);
  console.log("Button is active: true");
} else {
  setIsActive(false);
  console.log("Button is active: false");
}

};





const formatDateString = (dateString) => {
  
  const eventDate = moment.utc(dateString);

  
  const formattedDate = eventDate.format('MMM DD - YYYY');
  // console.log(`Formatted Date in UTC: ${formattedDate}`); 

  return formattedDate;
};




const checkIfStudentBooked = async (conferenceId, studentId) => {
  try {
    setLoading(true)
    const response = await axios.post(`${apiUrl}/api/conference/checkBooking/`,{conferenceId, studentId},{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    const data = await response.data;

    return data;
  } catch (error) {
    console.error('Error checking if student booked:', error);
    return false;
  }finally{
    setLoading(false)
  }
};

useEffect(() => {
  fetch_user_Details();
  fetch_earning_details(card._id);

  
  const conversionRate = 83; // Example conversion rate: 1 USD = 83 INR

  
  const convertINRtoUSD = (amount) => {
    return Math.ceil(amount / conversionRate);
  };
  
  
  const serviceChargeInINR = calculateServiceCharge(card.Price.amount);
  setServiceCharges(serviceChargeInINR);
  
  
  let amountInLocalCurrency = Number(card.Price.amount);
  console.log(amountInLocalCurrency)
  let serviceCharge = serviceChargeInINR;
  console.log(isIndia,teacher_country)
  if (!isIndia && teacher_country === 'India') {
      
      amountInLocalCurrency = convertINRtoUSD(amountInLocalCurrency);
      console.log("Converted one",amountInLocalCurrency)
      serviceCharge = convertINRtoUSD(serviceChargeInINR);
  }
  
  const updated_amount = amountInLocalCurrency + serviceCharge+card.Price.paypal_fee;
  
  
  const gst = calculateGST(updated_amount);
  setGst(gst);
  
  // Calculate total amount
  console.log(amountInLocalCurrency , serviceCharge , gst)
  const totalAmount = Math.ceil(Number(amountInLocalCurrency) + Number(serviceCharge) + Number(gst)+card.Price.paypal_fee);
  console.log("Total Amount",totalAmount)
  setTaxAmount(totalAmount);
  console.log(tax_amount)
  const checkBooking = async () => {
    const booked = await checkIfStudentBooked(card._id, user_loggedInId);
    setIsBooked(booked.status);
    // console.log("Status",booked)
    // console.log(isBooked)
  };

  if (user_type === 'Student') {
    checkBooking();
  }
  updateButtonState(card.Conference_Date,Start_Time);

  const interval = setInterval(() => {
    console.log(card.Start_Date);
    updateButtonState(card.Conference_Date);
}, 60000); 


return () => clearInterval(interval);

}, [Start_Time,End_Time,isBooked,isIndia]);

  const fetch_earning_details = async (conferenceId) =>{
    try{
      // console.log("Fetching earnings details")
      const conference_Id=conferenceId;
      // console.log(conference_Id)
      const earnings_details = await axios.post(`${apiUrl}/api/earningConference/fetch_earning_details`,{ conference_Id: conferenceId },{
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      })
      // console.log("earnings ===>",earnings_details.data)
      if (earnings_details.data ) {
        setEarning(earnings_details.data);
        let earning_ids;
              if (earnings_details.data.length > 1){
               earning_ids = earnings_details.data.map((earning)=>{
                  return earning._id
                })
              }
              else{
                earning_ids = [earnings_details.data[0]._id];
              }
        setEarning_id(earning_ids);
        // console.log(earning_id)
      } else {
        // console.log('No Earnings');
        setEarning(null);
        setEarning_id(null);
      }
    }catch(error){
      console.error('Error fetching earning details:', error);
    }
  }
    //console.log("Total Amount ==>",totalAmount)
    
    const fetch_user_Details = async () =>{
      try{
  
        const user_details= await axios.get(`${apiUrl}/api/auth/user_details/${user_loggedInId}`,{
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        })
        const user=user_details.data.user;
        // console.log(user);
        // console.log("Name",user.fullName)
        // console.log("Eamil",user.email)
        setUser_data(user);
       
  
      }catch(error){
        console.error('Server Error:', error.response.data);
      }
    }
    // BACK NAVIGATION BASE ON LOGGED IN USER TYPE

    const handleback=(e)=>{
      if(user_type === 'Teacher'){
        navigate('/calender_page');
      }
      else{
        navigate('/cabinet');
      }
      
    }

    // FUNCTION TO BOOK A CONFERENCE WHEN LOGGED IN USER TYPE IS STUDENT
    const handlePayment = async (st,et,date,callId,Currency,currencyCode,original_amount) => {
      if(original_amount === '0'){
        const Id = callId;
        // const slot_id=id;
        await bookConference(Id)
    } else{
      const conversionRate = 83; // Example conversion rate: 1 USD = 83 INR

  
  const convertINRtoUSD = (amount) => {
      return amount / conversionRate;
  };
  
  
  const serviceChargeInINR = calculateServiceCharge(card.Price.amount);
  setServiceCharges(serviceChargeInINR);
  
  
  
  let amountInLocalCurrency = Number(card.Price.amount);
  let serviceCharge = serviceChargeInINR;
  console.log(!isIndia,teacher_country)
  if (!isIndia && teacher_country === 'India') {
      console.log("Student is not Indian")
      amountInLocalCurrency = Math.ceil(convertINRtoUSD(amountInLocalCurrency));
      serviceCharge = convertINRtoUSD(serviceChargeInINR);
  }
  
  const updated_amount = amountInLocalCurrency + serviceCharge+card.Price.paypal_fee;
  
  
  const gst = calculateGST(updated_amount);
  setGst(gst);
  
 const paypal_fee =(isIndia === false && teacher_country === 'India') ? card.Price.paypal_fee: 0.30
  console.log(amountInLocalCurrency , serviceCharge , gst,paypal_fee)
  console.log(amountInLocalCurrency + serviceCharge + gst+paypal_fee)
  const totalAmount = Math.ceil(amountInLocalCurrency + serviceCharge + gst+paypal_fee);
  console.log(totalAmount)
  setTaxAmount(totalAmount);
      
  const formatToTwoDecimalPlaces = (amount) => {
    return parseFloat(amount).toFixed(2);
};


const formattedServiceCharge = formatToTwoDecimalPlaces(serviceCharge);
const formattedCallPrice = formatToTwoDecimalPlaces(amountInLocalCurrency);

console.log(card)

const invoice_details = {
    Name: user_data.fullName,
    Email: user_data.email,
    Start_Time: st,
    End_Time: et,
    Date: Date,
    call_Id: callId,
    Service_Charges: formattedServiceCharge,
    GST: gst, 
    Call_Price: (!isIndia && teacher_country ==='India') ? amountInLocalCurrency:  Number(card.Price.amount),
    Total_amount: totalAmount,
    Type: "Conference Details",
    PaymentType: "Conference",
    Currency: (isIndia === false && teacher_country === 'India') ? '$' : Currency,
    CurrencyCode: (isIndia === false && teacher_country === 'India') ? 'USD' : currencyCode,
    paypal_fee:paypal_fee
};

console.log(invoice_details);

      navigate("/invoice-details",{state:{invoice_details}})
    }   
    };


    const bookConference = async (Conference_Id) =>{
      const response = await axios.post(`${apiUrl}/api/conference/${Conference_Id}/conference_booked`,
      {studentId:user_loggedInId,paymentId:'Free',amountPaid:0},
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      }
      );
      const data= response.data
        // console.log("Conference Booked Details ===>",data)
      if (response.status === 200) {
        // console.log('Haii...')
        await conference_booked(response.data.conferenceId)
        
      }
      
      navigate('/cabinet');
      
  };
  const conference_booked = async (id) => {
    try {
      // console.log(id ,"conf id")
        const response = await axios.post(`${apiUrl}/api/conference/book_conference/${id}`, {}, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
        });
        const data = response.data;
        // console.log(data);
        navigate('/cabinet');
    } catch (error) {
        console.error('Failed to book conference:', error);
    }
}


    const CalculateAmount = (amount,paypal_fee) => {
      const serviceCharge = calculateServiceCharge(Number(amount));
      //console.log("service Charges ==> ",serviceCharge)
      //const updated_amount = Number(amount) + serviceCharge
      //console.log("updated amount ==> ",updated_amount)
      const gst = calculateGST(Number(amount));
      //console.log("GST ==>",gst)
      const totalAmount = Math.round(Number(amount) + serviceCharge + gst+Number(paypal_fee));
      //console.log("Total Amount ==>",totalAmount)
      return totalAmount
  }
    
  const handleMeeting = async () => {
    try {
      
      const ids = Array.isArray(earning_id) ? earning_id : [earning_id];
      console.log(ids)
      
      const statusRequests = ids.map(id =>
        axios.put(`${apiUrl}/api/earningConference/change_meeting_status`, { earning_id: id }, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        })
      );
      
      
      const responses = await Promise.all(statusRequests);
      console.log('Status updates:', responses);
    } catch (error) {
      console.error('Error updating meeting status:', error);
    }
  };

  useEffect(() => {
    const checkAvailability = () => {
      const conferenceStart = moment(Conference_Date).set({
        hour: Start_Time.split(':')[0],
        minute: Start_Time.split(':')[1],
        second: 0,
        millisecond: 0,
      });

      const conferenceEnd = moment(Conference_Date).set({
        hour: End_Time.split(':')[0],
        minute: End_Time.split(':')[1],
        second: 0,
        millisecond: 0,
      });

      const now = moment();

      if (now.isSameOrAfter(conferenceEnd)) {
        setIsClickable(false);
      } else {
        setIsClickable(true);
      }
    };

    checkAvailability();
    const interval = setInterval(checkAvailability, 60000); 

    return () => clearInterval(interval);
  }, [card.Conference_Date, Start_Time, End_Time]);

  
  const handleshare = async() => {
    if(isClickable){
      if (navigator.share) {
        
        navigator.share({
            title: 'Check out this Conference Details!',
            text: 'Conference Details',
            url: `${baseUrl}/conferece_details/${card._id}`,
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
        alert('Sharing is not supported on this browser.');
    }
  }
  };
  
  const keywords = ["elearning", "education", "online class"]; 


    
  return (

    <section class="mb-24 sm:mb-0">
    <NavBar/>
    {/* <MetaDecorator title={Title} description={card.Description} imageUrl={conference} keywords={keywords} imageAlt={'Not found'} url={`${baseUrl}/conferece_details/${card._id}`}/> */}
    <div class='sm:mt-20 lg:mt-14 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5'>
      <div class="lg:grid grid-cols-12 gap-8 sm:min-h-[85vh] sm:py-[55px] justify-center">

        {/* ------------------------- COL SPAN - 1  --------------------------- */}

        <div class="col-span-5">

          {/* ------------------------------ HEADER OF THE PAGE ----------------------------- */}

          <HeaderBackground />
           
          

          {/* --------------------- NAVIGATING BACK FUNCTIONALITY --------------------------------- */}

          <div  className='mt-44 mb-4 px-4 sm:px-0 sm:mt-0'>
            <div style={{ color: '#AFAFAF' }} className='flex items-center gap-4 cursor-pointer' onClick={handleback} >
                <BsChevronLeft />
                <div style={{ fontWeight: 600 }} >Back</div>
            </div>   
          </div>

          {/* ---------------------------------- CONFERENCE SHORT DETAILS LIKE TIMINGS, TITLE AND DATE ----------------------- */}

          <div className="flex sm:justify-center">
            <div className="rounded-[40px] shadow-md w-[420px] p-[20px_10px_15px_25px] lg:p-5 lg:h-36 lg:ml-32">
              <div className="flex justify-between">
                <div className="font-semibold mb-1 ml-2">{formatDateString(card.Conference_Date)}</div>
                {/*<div className="edit" style={{ fontWeight: 600, color: '#AFAFAF' }}>Edit</div>*/}
              </div>
              <div className="bg-[#D1FFD0] w-1/2 px-2 py-2 text-center rounded-[20px] font-montserrat font-semibold mb-1 ml-2">{`${Start_Time} - ${End_Time}`}</div>
              <div className="meeting-heading font-medium mt-2 ml-2 text-gray-700">{Title}</div>
            </div>
          </div>
        </div>

        {/* -------------------------------------- COL SPAN - 2 ------------------------- */}

        <div class="col-span-7 sm:shadow-lg h-fit rounded-[50px] p-4 sm:p-6">
        

        {/* ------------------------------ PRICE FOR THE CONFERENCE ------------------------------------- */}
      
        <div className='flex justify-between items-center'>
          <div class="mt-4 border-2 border-gray-400 rounded-full px-5 py-2 text-center w-[150px]">
            {user_type === 'Student'?(
              <div>Price : {!isIndia && teacher_country==='India' ? '$' :(card.Price.currency)} {tax_amount}</div>
            ):(
            
              <div>Price : {card.Price.currency} {card.Price.amount}</div>
            )}
            
          </div>
          <div
            className={`border-2 border-orange-600 rounded-3xl py-1 px-6 bg-transparent text-orange-500 font-semibold hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 cursor-pointer flex justify-center gap-2 items-center shadow-lg ${isClickable ? '' : 'pointer-events-none opacity-50'}`}
            onClick={handleshare}
          >
            Share Conference
            <IoIosShareAlt style={{ fontSize: '1.5rem' }} />
          </div>
        </div>
        

        {/* ------------------------------ CONFERENCE DETAILS ------------------------------- */}

          <div  class="card  shadow-lg sm:shadow-none py-[15px] px-4 sm:px-0 overflow-y-auto">
            <div>

              {/* ------------------------------- DESCRIPTION FOR THE CONFERENCE ------------------------- */}

              <div className='font-semibold'>Description</div>
              <div className='text-gray-600 leading-8 mb-4'>{Description}</div>

              {/* --------------------------------------- REMAINDER TIME -------------------------------- */}

              <div className='flex gap-4 items-center mb-2'>
                <BsPencilSquare className='text-orange-500 text-xl' />
                <div className='text-gray-600'>Reminder 30min before</div>
             </div>

                {/* --------------- BASED ON THE LOGGED IN USER SHOW BUTTON TO START OR BOOK CONFERENCE ------------------------ */}
                
                {loading ? (
                  <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-orange" role="status"></div>
                ) : (
                  user_type === 'Student' ? (
                    isBooked === true ? (
                      <a
                        href={isActive ? URL : ""}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center justify-center w-64 px-0 py-2.5 bg-gradient-to-b from-orange-600 to-yellow-400 text-white text-center rounded-full ml-[13%] gap-3.5 lg:ml-52 ${!isActive ? 'opacity-50 pointer-events-none' : ''}`}
                      >
                        <i className="fas fa-video"></i>
                        Start Meeting {isBooked}
                      </a>
                    ) : (
                      <div
                        className="inline-flex items-center justify-center w-64 px-0 py-2.5 bg-gradient-to-b from-orange-600 to-yellow-400 text-white text-center rounded-full ml-[13%] gap-3.5 cursor-pointer"
                        onClick={() => handlePayment(card.Start_Time, card.End_Time, card.conferenceDate, card._id, card.Price.currency, card.Price.currencyCode,card.Price.amount)}
                      >
                        <BsCameraVideoFill />
                        Book Now
                      </div>
                    )
                    // <div
                    //     className="inline-flex items-center justify-center w-64 px-0 py-2.5 bg-gradient-to-b from-orange-600 to-yellow-400 text-white text-center rounded-full ml-[13%] gap-3.5 cursor-pointer"
                    //     onClick={() => handlePayment(card.Start_Time, card.End_Time, card.conferenceDate, card._id, card.Price.currency, card.Price.currencyCode,card.Price.amount)}
                    //   >
                    //     <BsCameraVideoFill />
                    //     Book Now
                    //   </div>
                  ) : user_type === 'Teacher' ? (
                    earning_id ? (
                      <a
                        href={isActive ? URL : ""}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center justify-center w-64 px-0 py-2.5 bg-gradient-to-b from-orange-600 to-yellow-400 text-white text-center rounded-full ml-[13%] gap-3.5 lg:ml-52 ${!isActive ? 'opacity-50 pointer-events-none' : ''}`}
                        onClick={earning_id && isActive ? handleMeeting : undefined}
                      >
                        <i className="fas fa-video"></i>
                        Start Meeting 
                      </a>
                    ) : (
                      <div className="ml-[13%] lg:ml-52 text-gray-500">
                        No one student booked the conference
                      </div>
                    )
                  ) : null
                )}

            </div>
          </div>
        </div>

        {/* --------------------------- BOTTOM MENU ---------------------------------- */}

        <div className='flex justify-center'>
            <MainMenu/>
        </div>
      </div>
    </div>
    
  </section>
   
  );
}

export default Conference_Details;

