
import React, { useState, useEffect } from 'react';
import { BsPersonCircle, BsFillCalendar2CheckFill, BsCalendarDateFill } from "react-icons/bs";
import { IoMdTime } from "react-icons/io";
import Cookies from 'js-cookie';
import axios from "axios";
import Loading2 from '../../Loading2';
import Empty_data2 from '../../Empty_data2';
import { MdGroupAdd } from "react-icons/md";
import moment from 'moment-timezone';

const UpcomingEventsCall = () => {
  const [cardsData, setCardsData] = useState([]);
  const [loading, setLoading] = useState(true);  
  const authToken = Cookies.get("authToken");
  const user_type = Cookies.get('user_type');
  const apiUrl = process.env.REACT_APP_API_URL;
  const [hasError, setHasError] = useState(false);
  const [isActive, setIsActive] = useState([]);
  const [user_time, setUser_time] = useState('');
  const [user_data, setUser_data] = useState('');
  const user_loggedInId = Cookies.get('loggedInUserId');

  useEffect(() => {
    fetch_user_Details();
    FetchConferenceDetails();
  }, []);

  const FetchConferenceDetails = async () => {
    try {
      let data;
      if (user_type === 'Teacher') {
        const response = await axios.get(`${apiUrl}/api/call/upcoming_allbooked_calls_teacher`, {
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
        });
        data = response.data;
      } else {
        const response = await axios.get(`${apiUrl}/api/call/upcoming_booked_calls`, {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        });
        data = response.data;
      }

      const now = new Date();
      const filteredData = data.filter((call) => {
        const callDate = new Date(formatDateStringInTimeZone(call.Call_Date,user_time));
        const [startHour, startMinute] = call.startTime.split(':').map(Number);
        callDate.setHours(startHour, startMinute, 0, 0);
  
        // Add 10 minutes to the callDate
        callDate.setMinutes(callDate.getMinutes() + 10);

        return callDate > now;
      })
      // .sort((a, b) => {
      //   const aStartTime = new Date(a.Call_Date);
      //   const [aStartHour, aStartMinute] = a.startTime.split(':').map(Number);
      //   aStartTime.setHours(aStartHour, aStartMinute, 0, 0);
      //   const bStartTime = new Date(b.Call_Date);
      //   const [bStartHour, bStartMinute] = b.startTime.split(':').map(Number);
      //   bStartTime.setHours(bStartHour, bStartMinute, 0, 0);
      //   return bStartTime - aStartTime;
      // });
      let enrichedData;
      if (user_type === 'Teacher') {
        enrichedData = await Promise.all(
          filteredData.map(async (call) => {
            try {
              const earnings_details = await axios.post(
                `${apiUrl}/api/earningCalls/fetch_earning_details`,
                { call_Id: call._id },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                  },
                }
              );
              // console.log("earning ===> ",earnings_details)
              if (earnings_details.data && earnings_details.data._id) {
                // console.log(earnings_details.data)
                return { ...call, earningDetails: earnings_details.data };
              } else {
                return { ...call, earningDetails: null };
              }
            } catch (error) {
              console.error('Error fetching earning details:', error);
              return { ...call, earningDetails: null };
            }
          })
        );
      } else {
        enrichedData = filteredData; 
      }
      enrichedData.sort((a, b) => {
        const dateA = new Date(a.Call_Date);
        const [startHourA, startMinuteA] = a.startTime.split(':').map(Number);
        dateA.setHours(startHourA, startMinuteA, 0, 0);
  
        const dateB = new Date(b.Call_Date);
        const [startHourB, startMinuteB] = b.startTime.split(':').map(Number);
        dateB.setHours(startHourB, startMinuteB, 0, 0);
  
        return dateA - dateB;
      });
      setCardsData(enrichedData);
      setIsActive(new Array(enrichedData.length).fill(false));
    } catch (error) {
      console.error('Error fetching conference details:', error);
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const updateButtonStateForAllCards = () => {
      cardsData.forEach((card, index) => {
        updateButtonState(card, index);
      });
    };
    if (cardsData.length > 0) {
      updateButtonStateForAllCards();
    }

    const interval = setInterval(() => {
      if (cardsData.length > 0) {
        updateButtonStateForAllCards();
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [cardsData]);

  const updateButtonState = (card, index) => {
    const Start_time=formattedStartTime(card.startTime)
    const [startHour, startMinute] = Start_time.split(':').map(Number);
    // console.log(card.Call_Date)
    const conferenceDate = new Date(formatDateStringInTimeZone2(card.Call_Date,card.startTime,card.teacher_time_zone,user_time));
    const now = new Date();
    // console.log(conferenceDate)
    const start = new Date(
      conferenceDate.getFullYear(),
      conferenceDate.getMonth(),
      conferenceDate.getDate(),
      startHour,
      startMinute,
      0,
      0
    ).getTime();
  
    const fiveMinutes = 5 * 60 * 1000;
    const tenMinutes = 10 * 60 * 1000;
    const currentTime = now.getTime();
  
    // console.log("Time range ==> ", start - fiveMinutes, " to ", start + tenMinutes, 
    //   new Date(start - fiveMinutes).toLocaleString(), 
    //   " to ", 
    //   new Date(start + tenMinutes).toLocaleString());
  
    if (currentTime >= start - fiveMinutes && currentTime <= start + tenMinutes &&
        now.getDate() === conferenceDate.getDate() &&
        now.getMonth() === conferenceDate.getMonth() &&
        now.getFullYear() === conferenceDate.getFullYear()) {
      setIsActive(prevState => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
      // console.log(`Button is active: true for card index: ${index}`);
    }else if (currentTime > start + tenMinutes) {
      
      // console.log(`Call is completed for card index: ${index}`);
      
    } else {
      setIsActive(prevState => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
      console.log(`Button is active: false for card index: ${index}`);
    }
  };
  

  const handleMeeting = async(earning_id) => {
    // console.log(earning_id)
    const status = await axios.put(`${apiUrl}/api/earningCalls/change_meeting_status`, { earning_id }, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });
    // console.log("earnings made true ==>",status.data);
    //console.log(event)

  };

  const formatDateString = (dateString) => {
    const eventDate = new Date(dateString);
    const options = { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(eventDate);
    const [weekday, date] = formattedDate.split(', ');
    const formattedDateWithDashes = date.replace(/\//g, '-');
    return `${weekday}, ${formattedDateWithDashes}`;
  };

  const fetch_user_Details = async () => {
    try {
      const user_details = await axios.get(`${apiUrl}/api/auth/user_details/${user_loggedInId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      const user = user_details.data;
      // console.log(user);
      setUser_data(user);
      setUser_time(user.user_Details.time_zone);
      // console.log("Time Zone", user_time);
    } catch (error) {
      console.error('Server Error:', error.response.data);
    }
  };

  const formattedStartTime =(startTime)=>{
    const startParts = startTime.split(':').map(part => parseInt(part));
    const startDate = new Date();
    startDate.setHours(startParts[0], startParts[1], 0, 0);

   
    const startOptions = { timeZone: user_time, hour12: false, hour: '2-digit', minute: '2-digit' };
    const formattedStartTime = new Intl.DateTimeFormat('en-US', startOptions).format(startDate);

    return formattedStartTime
  }

  const formatTime = (startTime, endTime) => {
    //console.log("Start time before conversion ==>", startTime);
    const startParts = startTime.split(':').map(part => parseInt(part));
    const startDate = new Date();
    startDate.setHours(startParts[0], startParts[1], 0, 0);

   
    const startOptions = { timeZone: user_time, hour12: false, hour: '2-digit', minute: '2-digit' };
    const formattedStartTime = new Intl.DateTimeFormat('en-US', startOptions).format(startDate);

    //console.log("End time before conversion ==>", endTime);
    let formattedEndTime = null;
    if (endTime) {
        const endParts = endTime.split(':').map(part => parseInt(part));
        const endDate = new Date();
        endDate.setHours(endParts[0], endParts[1], 0, 0);

        
        const endOptions = { timeZone: user_time, hour12: false, hour: '2-digit', minute: '2-digit' };
        formattedEndTime = new Intl.DateTimeFormat('en-US', endOptions).format(endDate);
        //console.log(user_time);
        //console.log("Start and end time after conversion ==>", formattedStartTime, formattedEndTime);
    }

    return `${formattedStartTime} - ${formattedEndTime}`;
};


const formatDateStringInTimeZone = (dateString, timeZone) => {
  return moment.tz(dateString, timeZone).format('YYYY-MM-DD');
};

const formatDateStringInTimeZone2 = (dateString, startTime, teacherTimeZone, studentTimeZone) => {
  console.log(dateString, startTime, teacherTimeZone, studentTimeZone)
  const dateTimeString = `${dateString}T${startTime}`;
  

  const utcDateTime = moment.tz(dateTimeString, teacherTimeZone).utc();
  

  const convertedDateTime = utcDateTime.clone().tz(studentTimeZone);
  

  const convertedDate = convertedDateTime.format('YYYY-MM-DD');

 

  // Return the converted date
  return convertedDate;
};

  if (loading) {
    return <Loading2 />;
  }

  if (hasError || cardsData.length === 0) {
    return <Empty_data2 />;
  }

  return (
    <div className="flex flex-col items-center h-screen mb-10 sm:mb-20 w-full font-primary" style={{ maxHeight: 'calc(100vh - 140px)' }}>
      {cardsData.map((event, index) => (
        <div
          key={event._id}
          className="rounded-[40px] p-[20px] mb-6 mt-1 flex flex-col lg:mr-2 w-[100%] sm:w-[85%] lg:w-[65%]"
          style={{ boxShadow: '0px 2px 6px 0px #00000040' }}
        >
          <div className="pb-[20px] flex flex-col justify-center">
            <div className="flex items-center ">
              <BsPersonCircle className='text-orange-400 text-[1.2rem]'/>
              <p className="pl-[5px] text-sm sm:text-base font-normal text-[#585858]">
                {event.userName}
              </p>
            </div>
            <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 sm:justify-between mt-3">
              <div className="flex items-center">
                <BsCalendarDateFill className='text-orange-400 text-[1.2rem]' />
                <p className="pl-[5px] text-sm sm:text-base font-normal text-[#585858] pr-[8px]">
                  Date: {formatDateString(formatDateStringInTimeZone2(event.Call_Date,event.startTime,event.teacher_time_zone,user_time))}
                </p>
              </div>
              <div className="flex items-center">
                <IoMdTime className='text-orange-400 text-[1.3rem]' />
                {/* <p className="pl-[5px] text-sm sm:text-base lg:text-lg font-normal text-[#585858]">
                  {user_type === 'Student' ?(
                    <div>
                      {`Time: ${formatTime(event.startTime, event.endTime)}`}
                    </div>
                    
                  ):(
                    <div>
                        Time: {event.startTime} - {event.endTime}
                    </div>
                    
                  )}
                  
                </p> */}
                <p className="pl-[5px] text-sm sm:text-base lg:text-lg font-normal text-[#585858]">
                  {user_type === 'Student' ? (
                    <>
                      {event.teacher_time_zone !== user_time ? (
                        <div>
                          {`Time: ${formatTime(event.startTime, event.endTime)}`}
                        </div>
                      ) : (
                        <div>
                          {`Time: ${event.startTime} - ${event.endTime}`}
                        </div>
                      )}
                    </>
                  ) : (
                    <div>
                      {`Time: ${event.startTime} - ${event.endTime}`}
                    </div>
                  )}
              </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div className="flex justify-center items-center gap-2">
              {user_type === 'Teacher' ? (
                <a
                  href={isActive[index] ? event.url : "#"}
                  target={isActive[index] ? "_blank" : ""}
                  rel="noopener noreferrer"
                  className={`inline-flex items-center justify-center w-64 px-0 py-2.5 bg-gradient-to-b from-orange-600 to-yellow-400 text-white text-center rounded-full ml-[13%] gap-3.5 lg:ml-52 ${!isActive[index] ? 'opacity-50 pointer-events-none' : ''}`}
                  onClick={isActive[index] ? () => handleMeeting(event.earningDetails._id) : undefined}
                >
                  Start
                </a>
              ) : (
                <div className={`border-2 border-orange-600 rounded-3xl py-2 px-6 bg-transparent text-orange-500 hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-transparent  w-32 flex justify-center shadow-lg gap-2 items-center cursor-pointer  ${!isActive[index] ? 'opacity-50 pointer-events-none' : ''}`} >
                {/* <a
                  href={isActive[index] ? event.url : "#"}
                  target={isActive[index] ? "_blank" : ""}
                  rel="noopener noreferrer"
                  className={`inline-flex items-center justify-center w-64 px-0 py-2.5 bg-gradient-to-b from-orange-600 to-yellow-400 text-white text-center rounded-full ml-[13%] gap-3.5 lg:ml-52 ${!isActive[index] ? 'opacity-50 pointer-events-none' : ''}`}
                >
                  Join
                </a> */}
                <MdGroupAdd className='text-[1.2rem]'/><span className='font-medium text-sm sm:text-base'>
                <a href={isActive[index] ? event.url : "#"}
                  target={isActive[index] ? "_blank" : ""}
                 rel="noopener noreferrer">
                  Join
                </a>
                </span>
                </div>
              )}
              {/* {!isActive[index] && (
                <div className="ml-4">
                  <p className="text-orange-600 text-sm font-semibold">
                    Session starts in less than 5 minutes
                  </p>
                </div>
              )} */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UpcomingEventsCall;


