import React from 'react'
import logo from '../../assets/img/logo.svg'
import { Link } from 'react-router-dom'
const NavHome = () => {
  return (
    <div className=''>
        <nav className="hidden fixed top-0 z-50 sm:flex items-center justify-center h-[72px] w-full bg-gradient px-5 lg:px-[72px]">
            <div className="w-full max-w-[1300px] mx-auto sm:flex flex-wrap md:gap-10 items-center justify-center lg:justify-between">
                <div className="hidden lg:block">
                    <img className="w-[288px]" src={logo} alt="logo" />
                </div>
                <div className="flex items-center justify-center gap-10">
                    
                    <Link to='/login'>
                    <button className='bg-white py-1 px-6 rounded-full'>
                        <span className='text-gradient font-semibold text-[17px]'>Log In</span>
                    </button>
                    </Link>
                    {/* <Link to='/signup' >
                    <button className='bg-white py-1 px-6 rounded-full'>
                        <span className='text-gradient font-semibold text-[17px]'>Sign Up</span>
                    </button>
                    </Link> */}
                </div>
            </div>
        </nav>
        
    </div>
  )
}

export default NavHome