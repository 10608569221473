import React from 'react'
import NavHome from '../../components/nav/navHome';
import Footer from '../../components/nav/footer';
import Header from '../../components/headerBackground'
import Cookies from "js-cookie";
import NavBar from '../../components/nav/navBar';
import user from '../../assets/img/useragree.PNG';

const UserAgreement = () => {
  const isAuthenticated = Cookies.get("authToken") !== undefined;
  // const url = process.env.REACT_APP_API_URL;
  return (
    <div className=''>
      {isAuthenticated ? <NavBar /> : <NavHome />}
      <section className="mb-0 sm:mb-0 font-primary ">
        <Header />
        <div className='sm:mt-20 lg:mt-28 mt-[160px] mx-2 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 flex flex-col justify-center gap-4'>
          <h2 className='font-semibold sm:text-2xl lg:text-3xl text-xl text-center text-[#585858]'>USER AGREEMENT SUMMARY</h2>
          <div className='sm:flex flex-cols gap-6 items-start justify-center mt-10'>
            <div className='sm:w-[40%] w-[100%] flex items-center justify-center'>
              <img src={user} alt="refund" className='sm:w-[400px] sm:h-[400px] lg:w-[450px] lg:h-[450px] h-[250px] w-[250px]' />
            </div>

            <div className='sm:w-[60%] text-base sm:text-base lg:text-lg flex flex-col gap-6 text-left mt-2 mb-6 mx-2'>

              <p>This is the User Agreement between CGB ORG and its subsidiaries.
                CareerGuidanceBuddy is a sole subsidiary of CGB ORG</p>
              <p>
                Below is a summary of the key terms of the “Contract” that applies to
                your use of our Services. When you use our Services, you agree to our
                “User Agreement.” Your use of our Services is also subject to our
                Professional Community Policies (which covers your conduct while using
                the services) as well as our Privacy Policy and Cookie Policy (which
                together cover how we collect, use, share, and store your personal data)
                along with Terms and Conditions (which covers how you will deliver,
                consume and use the service) and Refund and Cancellation Policy (which
                covers how refund and cancellation is handled) and Payment Policy (only
                for Teachers and Subject Matter Experts, which covers how your earning is
                reimbursed).
                We can each end the Contract, but some rights and obligations will
                survive.
              </p>
              <p className='font-semibold'>Here are some key obligations that you agree to in the Contract:</p>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }} className='font-medium'>
                <li>
                  <p className='font-normal'>You’re eligible to enter into the Contract and you are at least our
                    “Minimum Age.”</p>
                </li>
                <li>
                  <p className='font-normal'>You will keep your password a secret.</p>
                </li>
                <li>
                  <p className='font-normal'>You will not share an account with anyone else.</p>
                </li>
                <li>
                  <p className='font-normal'>You will follow our “Dos and Don’ts” and our Professional
                    Community Policies. Our Professional Community Policies have three
                    main elements:</p>
                  <ol type='1' style={{ listStyleType: 'decimal', paddingLeft: '20px' }} className='font-medium'>
                    <li>
                      <h3 className='font-medium'>Be Safe:</h3>
                      <p className='font-normal'>Do not post harassing content and do not speak
                        insulting, disturbing, harmful or harassing language over calls
                        and conferences; do not threaten, incite, or promote violence;
                        do not share material depicting the exploitation of children; do
                        not promote, do not mislead or misguide, sell or attempt to
                        purchase illegal or dangerous goods or services; do not share
                        content promoting dangerous organizations or individuals.</p>
                    </li>
                    <li>
                      <h3 className='font-medium'>Be Trustworthy: :</h3>
                      <p className='font-normal'>Do not share false or misleading content; do
                        not create a fake profile or falsify information about yourself;
                        do not scam, defraud, deceive others; do not mislead over
                        calls and conferences.</p>
                    </li>
                    <li>
                      <h3 className='font-medium'>Be Professional:</h3>
                      <p className='font-normal'>Do not be hateful, do not engage in sexual
                        innuendos or unwanted advances; do not share harmful or
                        shocking material; do not spam members or the platform; do
                        not abuse other members particularly on calls and
                        conferences.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p className='font-normal'>You will comply with the law.</p>
                </li>
                <li>
                  <p className='font-normal'>You will keep your contact information up to date.</p>
                </li>
                <li>
                  <p className='font-normal'>Your CareerGuidanceBuddy profile will be truthful.</p>
                </li>
                <li>
                  <p className='font-normal'>You’re okay with us providing notices and messages to you through our websites, apps, and contact information you provided to us.</p>
                </li>
                <li>
                  <p className='font-normal'>If you get a service from us, You’ll honour your payment obligations and you are okay with us storing your payment information.</p>
                  <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }} className='font-normal'>
                    <li>You understand that there may be fees and taxes that are added to our prices and that refunds are subject to our refund policy detailed in Refund and Cancellation Policy.</li>
                    <li>You understand that your payouts are scheduled on monthly basis after deduction of taxes and fees and are subject to our payout policy detailed in Payment Policy.</li>
                  </ul>
                </li>
                <li>
                  <p className='font-normal'>You agree that we have limited liability under the contract and have disclaimed warranties regarding our Services.</p>
                </li>
                <li>
                  <p className='font-normal'>You agree that we can use data and information about you to make relevant uggestions to you and others.</p>
                </li>
                <li>
                  <p className='font-normal'>You agree and you know that we are not responsible in any way for any communication between you and other users in any forms.</p>
                </li>
                <li>
                  <p className='font-normal'>You agree that your information can be used to promote in other forms and      platforms by other users and by CGB ORG and CareerGuidanceBuddy.  </p>
                </li>
              </ul>

              <p className='font-semibold'>Here are some things that you acknowledge about our Services:</p>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }} className='font-normal'>
                <li>When you share information on our Services, you understand that
                  others can see, copy, share and use that information.</li>
                <li>While we offer many choices about how your personal data is used
                  in connection with the Service, you agree that we can use your
                  personal data subject to this Contract, our Privacy Policy and notices
                  and consents we provide in the context of the Service.</li>
                <li>You grant us a non-exclusive right to use the information and
                  content you share on our Services.</li>
                <li>Your use of others’ content and information in all forms on our
                  Services is at your own risk. CareerGuidanceBuddy is not
                  responsible for the third-party solution, comments, advice, products
                  and services offered through our platform.</li>
                <li>We have the right to limit how you connect and interact on our
                  Services.</li>
                <li>We may change or end any part of the Service.</li>
                <li>We may make changes to the Contract (and our prices and payout
                  dates) which will have a prospective effect from the date of change.</li>
                <li>If we have a legal dispute, you agree to use the courts and law
                  identified in the Contract.</li>
              </ul>

              <p className='font-semibold'>Here are some key obligations that CareerGuidanceBuddy agrees to in the Contract:</p>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }} className='font-normal'>
                <li>Posting original content or providing feedback and personal data
                  through our Service does not change ownership in this content and
                  information and so you would continue to own what is yours.</li>
                <li>We will honour the terms of the non-exclusive license that you grant
                  to us for your original content and feedback.</li>
                <li>We’ll honour the choices that you make about who gets to see your
                  content, including how and if it can be used for ads.</li>
                <li>We’ll honour the terms of our Privacy Policy and the choices that
                  you make about your personal data through our settings.</li>
                <li>We agree to provide you notice and an explanation if we believe
                  your content violates our Contract and will provide you an
                  opportunity to get a further review.</li>
                <li>If we have a legal dispute, we agree to use the courts and law
                  identified in the Contract.</li>
              </ul>

             
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default UserAgreement