import { useState, useEffect } from "react";
import axios from "axios"
import SelectedLanguages from "../../components/selectedLanguages";
import saveIcon from "../../assets/img/tick.png";
import arrowIcon from "../../assets/img/Vectoryy.png";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { FaRegArrowAltCircleLeft } from "react-icons/fa";

const Profession = ({ formData, setFormData, handleChange, submitForm, setCurrentPage, referralCode }) => {

  const [categories, set_categories] = useState([])
  const [subCategories, set_subCategories] = useState([])
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  console.log("Form Data ===>", formData)

  useEffect(() => {
    get_categories()

    if (formData.category != "") {
      get_subCategories(formData.category)
    }

  }, [])

  async function get_categories() {

    let response = await fetch(`${apiUrl}/api/categories/getAllCategory`)
    let data = await response.json()

    // let categories = [];

    // for (let c of data)
    //   categories.push(c.name)

    // categories.sort((a, b) => a.localeCompare(b));

    // set_categories(categories)
    data = data.map(c => ({
      ...c,
      order: Number(c.order)
  }));

  data.sort((a, b) => a.order - b.order);

  let categories = data.map(c => c.name);
  //console.log("categories ==>", categories);
  
  set_categories(categories);

  }

  const category = categories.map((category) => {
    return (
      <>
        <option value={category}>{category}</option>
      </>
    )
  })

  async function get_subCategories(category) {
    try {
      let response = await axios.post(`${apiUrl}/api/subCategories/getParticularSubCategory`, { "category_name": category })
      let data = response.data
      let subcategories = []

      for (let sc of data) {
        subcategories.push(sc.name)
      }

      // console.log(subcategories)
      subcategories.sort((a, b) => a.localeCompare(b));
      set_subCategories(subcategories)

    }
    catch (err) {
      console.log(err)
    }
  }


  function handleSubCategory(e, index) {
    let updatedData = { ...formData };
    let field_name = e.target.getAttribute("field_name")

    if (e.target.checked) {
      updatedData[field_name].push(e.target.value);
    } else {
      const index = updatedData[field_name].indexOf(e.target.value);
      if (index > -1) {
        // only splice array when item is found
        updatedData[field_name].splice(index, 1);
      }
    }
    setFormData(updatedData);
  }

  let subCategory =
    ((formData.category === "") || (formData.category === undefined))
      ? ""
      : subCategories.map((subcategory, index) => {
        return (
          <>
            <div className="flex mr-2 mt-2 text-[#585858] text-sm sm:text-base lg:text-lg">

              <input
                className="mr-1"
                type="checkbox"
                value={subcategory}
                name={subcategory}
                field_name="subCategory"
                checked={formData.subCategory.includes(subcategory)}
                onChange={(e) => handleSubCategory(e, index)}
              />
              <div >{subcategory}</div>
            </div>
          </>
        );
      });

  // const handleSubscription =() =>{
  //   navigate('/subscription')
  // }

  console.log("passed referral code", referralCode)

  return (
    <div>
      <form className="mt-2 sm:mt-[20px] grid grid-cols-1 gap-x-6 gap-y-2 sm:gap-y-4 sm:grid-cols-6 px-2" onSubmit={submitForm}>
        <div className="sm:col-span-6 ">
          <label
            htmlFor="qualification"
            className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]"
          >Qualification *
          </label>

          <div className="mt-2">
            <input
              type="text"
              name="qualification"
              className="w-full border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
              value={formData.qualification}
              onChange={(e) => handleChange(e)}
            ></input>
          </div>
        </div>
        <div className="sm:col-span-6 ">

          <label
            htmlFor="institute"
            className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]"
          >Institute associated with *
          </label>

          <div className="mt-2">
            <input
              type="text"
              name="institute"
              className="w-full border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
              value={formData.institute}
              onChange={(e) => handleChange(e)}
            ></input>
          </div>
        </div>

        <div className="sm:col-span-6 text-sm sm:text-base lg:text-lg">
          <label
            htmlFor="category"
            className="block font-semibold leading-6 text-gradient pl-[14px]"
          >
            Expert in category *
          </label>

          <div className="flex flex-col min-[375px]:flex-row justify-between">
            <div className="w-[100%] sm:w-[100%] mt-2 relative">
              <select
                type="text"
                name="category"
                defaultValue="Select a category"
                className="w-full border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px] appearance-none"
                value={formData.category}
                onChange={(e) => {
                  setFormData({ ...formData, category: e.target.value })
                  get_subCategories(e.target.value)
                }}
              >
                <option value="" selected disabled>
                  Select a course
                </option>

                {category}
              </select>
              <img
                src={arrowIcon}
                alt=""
                className="absolute top-[18px] right-[18px]"
              />
            </div>
          </div>
        </div>

        {formData.category != "" ? (
          <div className="sm:col-span-6 text-[14px] mb-3 text-sm sm:text-base lg:text-lg">
            <div className="font-semibold leading-6 text-gradient pl-[14px]">Select subcategories you are expert in</div>
            <div className="px-3 flex flex-wrap items-center">
              {subCategory}
            </div>
          </div>
        ) : ""
        }

        <div className="sm:col-span-6 ">

          <label
            htmlFor="awards_and_certifications"
            className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]"
          >Awards_and_certfifications (if any)
          </label>

          <div className="mt-2">
            <textarea
              name="awards_and_certifications"
              className="w-full border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px] resize-none"
              value={formData.awards_and_certifications}
              onChange={(e) => handleChange(e)}
            ></textarea>
          </div>
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="instagram_profile"
            className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]"
          >Instagram profile
          </label>
          <div className="mt-2">
            <input
              name="instagram_profile"
              type="url"
              autoComplete="url"
              className="w-full border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
              value={formData.instagram_profile}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="facebook_profile"
            className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]"
          >Facebook profile
          </label>
          <div className="mt-2">
            <input
              name="facebook_profile"
              type="url"
              autoComplete="url"
              className="w-full border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
              value={formData.facebook_profile}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="x_profile"
            className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]"
          >X profile
          </label>
          <div className="mt-2">
            <input
              name="x_profile"
              type="url"
              autoComplete="url"
              className="w-full border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
              value={formData.x_profile}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="personal_website"
            className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]"
          >Personal_website
          </label>
          <div className="mt-2">
            <input
              name="personal_website"
              type="url"
              autoComplete="url"
              className="w-full border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
              value={formData.personal_website}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="sm:col-span-6  text-sm sm:text-base lg:text-lg">
          <SelectedLanguages formData={formData} setFormData={setFormData} />
        </div>

        <div className="flex items-center sm:col-span-6 justify-between gap-2 sm:flex sm:justify-between w-full sm:w-full mb-[10px] pb-6">
          <div className="w-4/12 flex items-center">
            <button className=" w-[100px] flex drop-shadow-md py-1.5 px-3 rounded-full items-center justify-center gap-2 bg-white border-2 border-orange-400"
              onClick={() => setCurrentPage("Personal details")}>
              <FaRegArrowAltCircleLeft className="sm:w-[20px] sm:h-[20px] text-orange-400" />
              <span className="text-[12px] sm:text-[14px] lg:text-[17px] text-gradient font-bold">Back</span>
            </button>
          </div>
          {/* {referralCode ? (
            <button className="flex drop-shadow-md text-white py-2 sm:py-2.5 px-1 sm:px-4 rounded-full w-8/12 sm:w-6/12 lg:w-2/12 items-center justify-center bg-gradient">
              <span className="mr-2">
                <FaCheckCircle />
              </span>
              <span className="text-[12px] sm:text-[14px] font-medium" >Save</span>
            </button>
          ) : (
            <button className="flex drop-shadow-md text-white py-2 sm:py-2.5 px-3 sm:px-6 rounded-full w-8/12 sm:w-6/12 lg:w-5/12 items-center justify-center bg-gradient">
              <span className="mr-2">
                <FaCheckCircle />
              </span>
              <span className="text-[12px] sm:text-[14px] font-medium" >Save and Subscribe</span>
            </button>)
          } */}
          <button className="flex drop-shadow-md text-white py-2 sm:py-2.5 px-1 sm:px-4 rounded-full w-8/12 sm:w-6/12 lg:w-2/12 items-center justify-center bg-gradient">
              <span className="mr-2">
                <FaCheckCircle />
              </span>
              <span className="text-[12px] sm:text-[14px] font-medium" >Save</span>
            </button>

        </div>


      </form>
    </div>
  );
};

export default Profession;
