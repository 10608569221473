// import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import Cookies from "js-cookie";
// import { TbUpload } from "react-icons/tb";
// import { MdLockOutline, MdVerifiedUser } from "react-icons/md";
// import { FaRegArrowAltCircleRight } from "react-icons/fa";
// //import { CountrySelect, StateSelect, CitySelect } from "react-country-state-city";
// import {
//   GetCountries,
//   GetState,
//   GetCity,

// } from "react-country-state-city";
// //import { lang } from "moment-timezone";

// const apiUrl = process.env.REACT_APP_API_URL;

// const PersonalDetails = ({ formData, setFormData, handleChange, setSelectedFile, setCurrentPage }) => {
//   const account_type = Cookies.get("account_type");
//   const [countryid, setCountryid] = useState(0);
//   const [stateid, setStateid] = useState(0);
//   const [cityid, setCityid] = useState(0);
//  // const [countriesList, setCountriesList] = useState([]);
//   const [stateList, setStateList] = useState([]);
//   const [cityList, setCityList] = useState([]);
//   const [latx, setLatx] = useState(null);
//   const [longx, setLongx] = useState(null);
//   const [statezone, setStatezone] = useState(null);
//   const [usertime, setUserTime] = useState(formData.time_zone);
//   const [formcountry, setFormCountry] = useState(formData.country ? true : false)
//   const [formState, setFormState] = useState(formData.state ? true : false)
//   const [formCity, setFormCity] = useState(formData.city ? true : false)
//   const [countriesList, setCountriesList] = useState([]);
//   const [statesList, setStatesList] = useState([]);
//   const [citiesList, setCitiesList] = useState([]);

//   const [selectedCountry, setSelectedCountry] = useState('');
//   const [selectedState, setSelectedState] = useState('');
//   const [selectedCity, setSelectedCity] = useState('');
//   const [countryDetails,SetCountryDetails]=useState('');
//   const [timezone,setTimezone]=useState('')

  

//   const handleFileUpload = (e) => {
//     const file = e.target.files[0];
//     setFormData({ ...formData, profilePic: file });

//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setSelectedFile(reader.result);
//       };
//       reader.readAsDataURL(file);
//     }
//   };
//   //const passed_country=formData.country
//   // console.log("formData.country:", formData.country);



//   const chooseFile = () => {
//     document.getElementById("file_input").click();
//   };

//   const fetchTimezone = async () => {
//     try {
//       if (latx !== null && longx !== null && !isNaN(parseFloat(latx)) && !isNaN(parseFloat(longx))) {
//         const response = await axios.get(
//           `https://api.ipgeolocation.io/timezone?apiKey=018288f4247c4100afeb7b5bc46a5aec&lat=${latx}&long=${longx}`
//         );
//         const time_zone = response.data.timezone;
//         setStatezone(time_zone);
//         setFormData((prevData) => ({
//           ...prevData,
//           time_zone,
//         }));
//       }
//     } catch (error) {
//       console.error("Error fetching timezone:", error);
//     }
//   };

//   useEffect(() => {
//     GetCountries().then((result) => {
//       setCountriesList(result);
      
//       const indiaIndex = result.findIndex(country => country.name === 'India');
      
      
//       if (indiaIndex !== -1) {
//         setCountryid(indiaIndex);
//         GetState(result[indiaIndex].id).then((stateResult) => {
//           setStateList(stateResult);
//         });
       
//       }  
//       if(!formcountry){
//       fetchCountryDetails('India').then(() => {
//         setFormData((prevData) => ({
//           ...prevData,
//           country: 'India',
          
//         }));
//       });
//     }
//     });
    
//   }, []);

//   useEffect(() => {
//     if (formData.country) setFormCountry(true);
//     if (formData.state) setFormState(true);
//     if (formData.city) setFormCity(true);
//   }, [formData]);

//   const handleCountryChange = (country) => {
//     setSelectedCountry(country);
//     //console.log("Selected country ==>",country.name)
//     //console.log("jelloo")
//     setSelectedState(''); // Reset selected state and city when country changes
//     setSelectedCity('');
    
//     fetchCountryDetails(country.name);
    
//     console.log(timezone)
//     setFormData((prevData) => ({
//       ...prevData,
//       country:country.name,
//       state: '',
//       city: '',
//       time_zone:timezone
//     }));  
//     console.log(formData)
//   };

//   const handleStateChange = (state) => {
//     setSelectedState(state);
//     setSelectedCity(''); 
    
//     setFormData((prevData) => ({
//       ...prevData,
//       state: state.name,
//       city: '',
    
//     }));
//   };

//   const handleCityChange = (city) => {
//     setSelectedCity(city);
//     setFormData((prevData) => ({
//       ...prevData,
//       city: city.name,
     
//     }));
//   };

//   const fetchCountryDetails = async (countryName) => {
//     try {
//       // console.log("Haiii...");
//       const response = await axios.get(`${apiUrl}/api/country/get_country_data`, {
//         params: { name: countryName }
//       });
//       const country = response.data;
//       // console.log(country);
//       SetCountryDetails(country);
      
//       let timeZoneValue = '';
//       if (country && country.timezones && country.timezones.length === 1) {
//         timeZoneValue = country.timezones[0].zoneName;
//         // console.log(timeZoneValue);
//       }
      
//       setFormData((prevData) => ({
//         ...prevData,
//         time_zone: timeZoneValue,
//       }));  
//       // console.log("data after ==> ", formData);
//       // console.log("Fetched country details:", country);
//     } catch (error) {
//       console.error('Error fetching country details:', error);
//     }
//   };
  
 

//   const getTimezoneDisplay = () => {
//     const timeZones = countryDetails?.timezones || [];

//     if (timeZones.length === 1) {
//       // If there is only one timezone, display its abbreviation
//       return (
//         <div className="w-full appearance-none border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]">
//           {timeZones[0].zoneName}
//         </div>
//       );
//     } else if (timeZones.length > 1) {
//       // If there are multiple timezones, display them in a select dropdown
//       return (
//         <select
//           name="time_zone"
//           className="w-full appearance-none border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
//           onChange={(e) => {
//             const selectedTimeZone = e.target.value;
//             setFormData((prevData) => ({
//               ...prevData,
//               time_zone: selectedTimeZone,
//             }));
//           }}
//         >
//           {timeZones.map((timezone, index) => (
//             <option key={index} value={timezone.zoneName}>
//               {timezone.zoneName}
//             </option>
//           ))}
//         </select>
//       );
//     } else {
//       return (
//         <div className="w-full appearance-none border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]">
//           {formData.time_zone}
//         </div>
//       );
//     }
//   };



//   return (
//     <div id="personal" className="font-primary">
//       <form className="mt-2 sm:mt-[20px] grid grid-cols-1 gap-x-6 gap-y-2 sm:gap-y-4 sm:grid-cols-6 px-2">
//         {/* Full Name Input */}
//         <div className="sm:col-span-6">
//           <label htmlFor="full-name" className="block text-sm sm:text-base lg:text-lg font-semibold leading-2 text-gradient pl-[14px]">
//             Full Name *
//           </label>
//           <div className="mt-2">
//             <input
//               type="text"
//               name="fullName"
//               autoComplete="given-name"
//               className="block w-full rounded-m border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
//               value={formData.fullName}
//               onChange={(e) => handleChange(e)}
//             />
//           </div>
//         </div>

//         {/* Email Input */}
//         <div className="sm:col-span-6">
//           <div className="flex justify-between">
//             <label htmlFor="email" className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">
//               Email address *
//             </label>
//             <div className="flex text-gradient text-[14px] font-semibold">
//               {formData.verified ? (
//                 <>
//                   <div className="mr-2">Verified</div>
//                   <MdVerifiedUser color="orange" size={18} />
//                 </>
//               ) : (
//                 <Link to="/verify" state={{ email: formData.email }}>Verify now</Link>
//               )}
//             </div>
//           </div>
//           <div className="mt-2">
//             <input
//               name="email"
//               type="email"
//               disabled
//               value={formData.email}
//               autoComplete="email"
//               className="w-full border border-[#afafaf] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
//               onChange={(e) => handleChange(e)}
//             />
//           </div>
//         </div>

//         {/* Access Calendar Checkbox */}
//         {/* <div className="flex ml-2 mt-2 gap-1 text-gradient w-72">
//           <input
//             type="checkbox"
//             className=""
//             checked={formData.access_calendar}
//             onChange={(e) => handleChange(e)}
//             name="access_calendar"
//           />
//           <div className="text-sm sm:text-base lg:text-lg font-semibold leading-6 pl-[14px]">Access Google Calendar</div>
//         </div> */}
//         {/* editing cities profiles  */}

//         <div className="sm:col-span-6 flex flex-col gap-2 w-full">

//           {formcountry ? (<div className="sm:col-span-6" onClick={() => { setFormCountry(false) }}>
//             <div>
//               <label htmlFor="time_zone" className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">
//                 Country *
//               </label>
//               <div className="mt-2 relative">
//                 <div
//                   name="time_zone"
//                   className="w-full appearance-none border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
//                 >
//                   {formData.country}
//                 </div>
//               </div>
//             </div>
//           </div>) : (

//             <div className="flex gap-2 flex-col w-full" >
//               <h6 className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">Country</h6>

//               <select
//         onChange={(e) => {
//           const country = countriesList[e.target.value]; //here you will get full country object.
//           setCountryid(country.id);
//           GetState(country.id).then((result) => {
//             setStateList(result);
//           });
//           handleCountryChange(country)
//           //fetchTimezone()

//         }}
//         value={countryid}
//         className="w-full appearance-none border border-[#585858] text-[#585858] text-sm rounded-full px-[15px] py-[10px]"

//       >
//         {countriesList.map((item, index) => (
//           <option key={index} value={index}>
//             {item.name}
//           </option>
//         ))}
//       </select>
//             </div>
//           )
//           }
//           {formState ? (
//             <div className="sm:col-span-6" onClick={() => { setFormState(false) }}>
//               <div>
//                 <label htmlFor="time_zone" className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">
//                   State
//                 </label>
//                 <div className="mt-2 relative">
//                   <div
//                     name="time_zone"
//                     className="w-full appearance-none border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
//                   >
//                     {formData.state}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ) : (
//             <div className="flex gap-2 flex-col w-full" >
//               <h6 className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">State</h6>

//               <select
//                 onChange={(e) => {
//                   const state = stateList[e.target.value]; //here you will get full state object.
//                   setStateid(state.id);
//                   console.log("State selected", state.name)
//                   GetCity(countryid, state.id).then((result) => {
//                     setCityList(result);
//                   });
//                   handleStateChange(state);
                 
//                 }}
//                 value={stateid}
//                 className="w-full appearance-none border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
//               >
//                 {stateList.map((item, index) => (
//                   <option key={index} value={index}>
//                     {item.name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           )
//           }

//           {formCity ? (
//             <div className="sm:col-span-6" onClick={() => { setFormCity(false) }}>
//               <div>
//                 <label htmlFor="time_zone" className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">
//                   City
//                 </label>
//                 <div className="mt-2 relative">
//                   <div
//                     name="time_zone"
//                     className="w-full appearance-none border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
//                   >
//                     {formData.city}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ) : (
//             <div className="flex gap-2 flex-col w-full" >
//               <h6 className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">City</h6>

//               <select
//                 onChange={(e) => {
//                   const city = cityList[e.target.value];
//                   console.log("city selected", city.name)
//                   setCityid(city.id);
//                   handleCityChange(city);
//                   fetchTimezone();
//                 }}
//                 value={cityid}
//                 className="w-full appearance-none border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
//               >
//                 {cityList.map((item, index) => (
//                   <option key={index} value={index}>
//                     {item.name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           )
//           }
//         </div>

//         {/* Timezone */}
//         <div className="sm:col-span-6">
//           <label htmlFor="time_zone" className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">
//             Time Zone
//           </label>
//           <div className="mt-2 relative">
//             {getTimezoneDisplay()}
//           </div>
//         </div>

//         {/* LinkedIn Profile Input */}
//         <div className="sm:col-span-6">
//           <label htmlFor="linkedin_profile" className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">
//             LinkedIn profile
//           </label>
//           <div className="mt-2">
//             <input
//               name="linkedin_profile"
//               type="url"
//               autoComplete="url"
//               className="w-full border border-[#afafaf] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
//               value={formData.linkedin_profile}
//               onChange={(e) => handleChange(e)}
//             />
//           </div>
//         </div>

//         {/* About Input */}
//         <div className="sm:col-span-6 mb-3">
//           <label htmlFor="about" className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">
//             About *
//           </label>
//           <div className="mt-2">
//             <textarea
//               name="about"
//               className="w-full border border-[#afafaf] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] pt-[10px] resize-none"
//               value={formData.about}
//               onChange={(e) => handleChange(e)}
//             ></textarea>
//           </div>
//         </div>
//       </form>

//       {/* <hr className="h-px mb-[15px] mx-[15px] bg-[#E2E2E2] border-0 dark:bg-gray-700 opacity-25" /> */}

//       <div style={{ display: account_type !== "custom" ? "none" : "grid" }}
//         className="flex flex-wrap w-full sm:grid grid-cols-2 gap-2 sm:gap-10 sm:w-8/12 mx-auto sm:mx-none sm:w-full items-center justify-center mb-4">
//         <Link to="/change-password"
//           className="flex w-full shadow-md text-white py-2 rounded-full items-center justify-center mr-1 bg-gradient"
//         >
//           <MdLockOutline className="mr-1 sm:w-[20px] sm:h-[20px]" />
//           <span className="text-[10px] sm:text-[12px] lg:text-[14px] font-medium">Change Password</span>
//         </Link>
//         <div className="relative flex gap-2 sm:flex sm:gap-4 items-center w-full">
//           <input
//             className="w-[100px] hidden ml-3 mt-1"
//             type="file"
//             accept="image/*"
//             id="file_input"
//             name="profilePic"
//             onChange={handleFileUpload}
//           />
//           <button className="absolute w-full flex shadow-md text-white py-2 px-1 rounded-full items-center justify-center bg-gradient"
//             onClick={chooseFile}
//           >
//             <TbUpload className="mr-1 sm:w-[20px] sm:h-[20px] sm:mr-2" />
//             <span className="text-[10px] sm:text-[12px] lg:text-[14px] font-medium">Upload Profile Picture</span>
//           </button>
//         </div>
//       </div>
//       <div className="w-full flex items-center justify-end mb-2">
//         <button
//           className=" w-[100px] flex drop-shadow-md py-1 px-3 rounded-full items-center justify-center gap-2 bg-white border-2 border-orange-400"
//           onClick={() => setCurrentPage("Profession")}
//         >
//           <span className="text-[12px] sm:text-[14px] lg:text-[17px] text-gradient font-bold">Next</span>
//           <FaRegArrowAltCircleRight className="sm:w-[20px] sm:h-[20px] text-orange-400" />
//         </button>
//       </div>
//     </div>

//   );
// };

// export default PersonalDetails;

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { TbUpload } from "react-icons/tb";
import { MdLockOutline, MdVerifiedUser } from "react-icons/md";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
//import { CountrySelect, StateSelect, CitySelect } from "react-country-state-city";
import {
  GetCountries,
  GetState,
  GetCity,

} from "react-country-state-city";
//import { lang } from "moment-timezone";

const apiUrl = process.env.REACT_APP_API_URL;

const PersonalDetails = ({ formData, setFormData, handleChange, setSelectedFile, setCurrentPage, found }) => {
  const account_type = Cookies.get("account_type");
  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [cityid, setCityid] = useState(0);
 // const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [latx, setLatx] = useState(null);
  const [longx, setLongx] = useState(null);
  const [statezone, setStatezone] = useState(null);
  const [usertime, setUserTime] = useState(formData.time_zone);
  const [formcountry, setFormCountry] = useState(formData.country ? true : false)
  const [formState, setFormState] = useState(formData.state ? true : false)
  const [formCity, setFormCity] = useState(formData.city ? true : false)
  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [countryDetails,SetCountryDetails]=useState('');
  const [timezone,setTimezone]=useState('')

  //console.log(formcountry)
  //console.log("Form Data in personal profile ===>",formData)
  //console.log(formData.country,formData.state,formData.city)

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, profilePic: file });

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFile(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  //const passed_country=formData.country
  // console.log("formData.country:", formData.country);



  const chooseFile = () => {
    document.getElementById("file_input").click();
  };

  const fetchTimezone = async () => {
    try {
      if (latx !== null && longx !== null && !isNaN(parseFloat(latx)) && !isNaN(parseFloat(longx))) {
        const response = await axios.get(
          `https://api.ipgeolocation.io/timezone?apiKey=018288f4247c4100afeb7b5bc46a5aec&lat=${latx}&long=${longx}`
        );
        const time_zone = response.data.timezone;
        setStatezone(time_zone);
        setFormData((prevData) => ({
          ...prevData,
          time_zone,
        }));
      }
    } catch (error) {
      console.error("Error fetching timezone:", error);
    }
  };

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
    //fetchCountries();
  }, []);

  useEffect(() => {
    if (formData.country) setFormCountry(true);
    if (formData.state) setFormState(true);
    if (formData.city) setFormCity(true);
  }, [formData]);

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    //console.log("Selected country ==>",country.name)
    //console.log("jelloo")
    setSelectedState(''); // Reset selected state and city when country changes
    setSelectedCity('');
    
    fetchCountryDetails(country.name);
    
    console.log(timezone)
    setFormData((prevData) => ({
      ...prevData,
      country:country.name,
      state: '',
      city: '',
      time_zone:timezone
    }));  
    console.log(formData)
  };

  const handleStateChange = (state) => {
    setSelectedState(state);
    setSelectedCity(''); 
    
    setFormData((prevData) => ({
      ...prevData,
      state: state.name,
      city: '',
    
    }));
  };

  const handleCityChange = (city) => {
    setSelectedCity(city);
    setFormData((prevData) => ({
      ...prevData,
      city: city.name,
     
    }));
  };

  const fetchCountryDetails = async (countryName) => {
    try {
      console.log("Haiii...");
      const response = await axios.get(`${apiUrl}/api/country/get_country_data`, {
        params: { name: countryName }
      });
      const country = response.data;
      console.log(country);
      SetCountryDetails(country);
      
      let timeZoneValue = '';
      if (country && country.timezones && country.timezones.length === 1) {
        timeZoneValue = country.timezones[0].zoneName;
        console.log(timeZoneValue);
      }
      
      setFormData((prevData) => ({
        ...prevData,
        time_zone: timeZoneValue,
      }));  
      console.log("data after ==> ", formData);
      console.log("Fetched country details:", country);
    } catch (error) {
      console.error('Error fetching country details:', error);
    }
  };
  
  useEffect(() => {
    // Automatically assign the first timezone if available
    const timeZones = countryDetails?.timezones || [];
    if (timeZones.length > 0 && !formData.time_zone) {
      setFormData((prevData) => ({
        ...prevData,
        time_zone: timeZones[0].zoneName,
      }));
    }
  }, [countryDetails, setFormData]);


  const getTimezoneDisplay = () => {
    const timeZones = countryDetails?.timezones || [];

    if (timeZones.length === 1) {
      // If there is only one timezone, display its abbreviation
      return (
        <div className="w-full appearance-none border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]">
          {timeZones[0].zoneName}
        </div>
      );
    } else if (timeZones.length > 1) {
      // If there are multiple timezones, display them in a select dropdown
      return (
        <select
          name="time_zone"
          className="w-full appearance-none border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
          value={formData.time_zone || timeZones[0].zoneName}
          onChange={(e) => {
            const selectedTimeZone = e.target.value ;
            setFormData((prevData) => ({
              ...prevData,
              time_zone: selectedTimeZone,
            }));
          }}
        >
          {timeZones.map((timezone, index) => (
            <option key={index} value={timezone.zoneName}>
              {timezone.zoneName}
            </option>
          ))}
        </select>
      );
    } else {
      return (
        <div className="w-full appearance-none border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]">
          {formData.time_zone}
        </div>
      );
    }
  };



  return (
    <div id="personal" className="font-primary">
      <form className="mt-2 sm:mt-[20px] grid grid-cols-1 gap-x-6 gap-y-2 sm:gap-y-4 sm:grid-cols-6 px-2">
        {/* Full Name Input */}
        <div className="sm:col-span-6">
          <label htmlFor="full-name" className="block text-sm sm:text-base lg:text-lg font-semibold leading-2 text-gradient pl-[14px]">
            Full Name *
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="fullName"
              autoComplete="given-name"
              className="block w-full rounded-m border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
              value={formData.fullName}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        {/* Email Input */}
        <div className="sm:col-span-6">
          <div className="flex justify-between">
            <label htmlFor="email" className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">
              Email address *
            </label>
            <div className="flex text-gradient text-[14px] font-semibold">
              {formData.verified ? (
                <>
                  <div className="mr-2">Verified</div>
                  <MdVerifiedUser color="orange" size={18} />
                </>
              ) : (
                <Link to="/verify" state={{ email: formData.email }}>Verify now</Link>
              )}
            </div>
          </div>
          <div className="mt-2">
            <input
              name="email"
              type="email"
              disabled
              value={formData.email}
              autoComplete="email"
              className="w-full border border-[#afafaf] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        {/* Access Calendar Checkbox */}
        {/* <div className="flex ml-2 mt-2 gap-1 text-gradient w-72">
          <input
            type="checkbox"
            className=""
            checked={formData.access_calendar}
            onChange={(e) => handleChange(e)}
            name="access_calendar"
          />
          <div className="text-sm sm:text-base lg:text-lg font-semibold leading-6 pl-[14px]">Access Google Calendar</div>
        </div> */}
        {/* editing cities profiles  */}

        <div className="sm:col-span-6 flex flex-col gap-2 w-full">

          {formcountry ? (<div className="sm:col-span-6" onClick={() => { setFormCountry(false) }}>
            <div>
              <label htmlFor="time_zone" className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">
                Country *
              </label>
              <div className="mt-2 relative">
                <div
                  name="time_zone"
                  className="w-full appearance-none border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
                >
                  {formData.country}
                </div>
              </div>
            </div>
          </div>) : (

            <div className="flex gap-2 flex-col w-full" >
              <h6 className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">Country</h6>

              <select
        onChange={(e) => {
          const country = countriesList[e.target.value]; //here you will get full country object.
          setCountryid(country.id);
          GetState(country.id).then((result) => {
            setStateList(result);
          });
          handleCountryChange(country)
          //fetchTimezone()

        }}
        value={countryid}
        className="w-full appearance-none border border-[#585858] text-[#585858] text-sm rounded-full px-[15px] py-[10px]"

      >
        {countriesList.map((item, index) => (
          <option key={index} value={index}>
            {item.name}
          </option>
        ))}
      </select>
            </div>
          )
          }
          {formState ? (
            <div className="sm:col-span-6" onClick={() => { setFormState(false) }}>
              <div>
                <label htmlFor="time_zone" className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">
                  State
                </label>
                <div className="mt-2 relative">
                  <div
                    name="time_zone"
                    className="w-full appearance-none border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
                  >
                    {formData.state}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex gap-2 flex-col w-full" >
              <h6 className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">State</h6>

              <select
                onChange={(e) => {
                  const state = stateList[e.target.value]; //here you will get full state object.
                  setStateid(state.id);
                  console.log("State selected", state.name)
                  GetCity(countryid, state.id).then((result) => {
                    setCityList(result);
                  });
                  handleStateChange(state);
                 
                }}
                value={stateid}
                className="w-full appearance-none border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
              >
                {stateList.map((item, index) => (
                  <option key={index} value={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          )
          }

          {formCity ? (
            <div className="sm:col-span-6" onClick={() => { setFormCity(false) }}>
              <div>
                <label htmlFor="time_zone" className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">
                  City
                </label>
                <div className="mt-2 relative">
                  <div
                    name="time_zone"
                    className="w-full appearance-none border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
                  >
                    {formData.city}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex gap-2 flex-col w-full" >
              <h6 className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">City</h6>

              <select
                onChange={(e) => {
                  const city = cityList[e.target.value];
                  console.log("city selected", city.name)
                  setCityid(city.id);
                  handleCityChange(city);
                  fetchTimezone();
                }}
                value={cityid}
                className="w-full appearance-none border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
              >
                {cityList.map((item, index) => (
                  <option key={index} value={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          )
          }
        </div>

        {/* Timezone */}
        <div className="sm:col-span-6">
          <label htmlFor="time_zone" className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">
            Time Zone
          </label>
          <div className="mt-2 relative">
            {getTimezoneDisplay()}
          </div>
        </div>

        {/* LinkedIn Profile Input */}
        <div className="sm:col-span-6">
          <label htmlFor="linkedin_profile" className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">
            LinkedIn profile
          </label>
          <div className="mt-2">
            <input
              name="linkedin_profile"
              type="url"
              autoComplete="url"
              className="w-full border border-[#afafaf] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] py-[10px]"
              value={formData.linkedin_profile}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        {/* About Input */}
        <div className="sm:col-span-6 mb-3">
          <label htmlFor="about" className="text-sm sm:text-base lg:text-lg font-semibold leading-6 text-gradient pl-[14px]">
            About *
          </label>
          <div className="mt-2">
            <textarea
              name="about"
              className="w-full border border-[#afafaf] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-[15px] pt-[10px] resize-none"
              value={formData.about}
              onChange={(e) => handleChange(e)}
            ></textarea>
          </div>
        </div>
      </form>

      {/* <hr className="h-px mb-[15px] mx-[15px] bg-[#E2E2E2] border-0 dark:bg-gray-700 opacity-25" /> */}

      <div style={{ display: account_type !== "custom" ? "none" : "grid" }}
        className="flex flex-wrap w-full sm:grid grid-cols-2 gap-2 sm:gap-10 sm:w-8/12 mx-auto sm:mx-none sm:w-full items-center justify-center mb-4">
        <Link to="/change-password"
          className="flex w-full shadow-md text-white py-2 rounded-full items-center justify-center mr-1 bg-gradient"
        >
          <MdLockOutline className="mr-1 sm:w-[20px] sm:h-[20px]" />
          <span className="text-[10px] sm:text-[12px] lg:text-[14px] font-medium">Change Password</span>
        </Link>
        <div className="relative flex gap-2 sm:flex sm:gap-4 items-center w-full">
          <input
            className="w-[100px] hidden ml-3 mt-1"
            type="file"
            accept="image/*"
            id="file_input"
            name="profilePic"
            onChange={handleFileUpload}
          />
          <button className="absolute w-full flex shadow-md text-white py-2 px-1 rounded-full items-center justify-center bg-gradient"
            onClick={chooseFile}
          >
            <TbUpload className="mr-1 sm:w-[20px] sm:h-[20px] sm:mr-2" />
            <span className="text-[10px] sm:text-[12px] lg:text-[14px] font-medium">Upload Profile Picture</span>
          </button>
        </div>
      </div>
      {formData.verified ?
      <div className="w-full flex items-center justify-end mb-2">
      <button
        className=" w-[100px] flex drop-shadow-md py-1 px-3 rounded-full items-center justify-center gap-2 bg-white border-2 border-orange-400"
        onClick={() => setCurrentPage("Profession")}
      >
        <span className="text-[12px] sm:text-[14px] lg:text-[17px] text-gradient font-bold">Next</span>
        <FaRegArrowAltCircleRight className="sm:w-[20px] sm:h-[20px] text-orange-400" />
      </button>
    </div>:
    ''}
    </div>

  );
};

export default PersonalDetails;
