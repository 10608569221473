import React, { useState, useEffect } from 'react';
import '../../App.css'
import { FaCalendarCheck, FaComments } from 'react-icons/fa';
import { IoPersonCircle, IoNotifications } from 'react-icons/io5';
import { HiMiniUserGroup } from 'react-icons/hi2';
import { RiLogoutBoxRLine, RiSearch2Fill } from "react-icons/ri";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useSocket } from '../../context/SocketContext';
import Notifications from '../../pages/notification';
import { useNotifications } from '../../context/NotificationContext';

const MenuBottom = ({ setMessage, setShowMessage }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const authToken = Cookies.get('authToken');
    const url = process.env.REACT_APP_API_URL;
    const loggedInUserId = Cookies.get('loggedInUserId');
    const { unreadCount, unreadChat } = useNotifications();

    // State variables for tracking active status of each menu item
    const [isActiveCalendar, setIsActiveCalendar] = useState(false);
    const [isActiveComments, setIsActiveComments] = useState(false);
    //const [isActiveProfile, setIsActiveProfile] = useState(false);
    //const [isActiveForum, setIsActiveForum] = useState(false);
    const [isActiveDots, setActiveDot] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isActiveSearch, setActiveSearch] = useState(false);

    const [isHoveredProfile, setIsHoveredProfile] = useState(false);
    const [isHoveredEvents, setIsHoveredEvents] = useState(false);
    const [isHoveredForum, setIsHoveredForum] = useState(false);
    const [isHoverLogout, setHoverLogout] = useState(false)
    const [showNotifications, setShowNotifications] = useState(false);  // State to manage notification dropdown visibility


    const handleMouseEnterProfile = () => {
        setIsHoveredProfile(true);
    };

    const handleMouseLeaveProfile = () => {
        setIsHoveredProfile(false);
    };
    const handleMouseEnterEvents = () => {
        setIsHoveredEvents(true);
    };

    const handleMouseLeaveEvents = () => {
        setIsHoveredEvents(false);
    };
    const handleMouseEnterForum = () => {
        setIsHoveredForum(true);
    };

    const handleMouseLeaveForum = () => {
        setIsHoveredForum(false);
    };
    const handleMouseEnterLogout = () => {
        setHoverLogout(true);
    };

    const handleMouseLeaveLogout = () => {
        setHoverLogout(false);
    };


    const handleCalendarClick = () => {
        if (isLoggedIn()) {
            navigate('/user_events');
            setIsActiveCalendar(true);
            setIsActiveComments(false);
            //setIsActiveProfile(false);
            //setIsActiveForum(false);
        }
    };

    const handleCommentsClick = () => {
        if (isLoggedIn()) {
            navigate('/chat');
            setIsActiveCalendar(false);
            setIsActiveComments(true);
            //setIsActiveProfile(false);
            //setIsActiveForum(false);
        }
    };

    const handlePersonCircleClick = () => {
        if (isLoggedIn()) {
            navigate('/edit-profile');
            setIsActiveCalendar(false);
            setIsActiveComments(false);
            //setIsActiveProfile(true);
            //setIsActiveForum(false);
        }
    };

    const handleUserGroupClick = () => {
        if (isLoggedIn()) {
            navigate('/forum');
            setIsActiveCalendar(false);
            setIsActiveComments(false);
            //setIsActiveProfile(false);
            //setIsActiveForum(true);
            setActiveDot(false)
            setActiveSearch(false)
        }
    };

    const handleSearchClick = () => {
        if (isLoggedIn()) {
            navigate('/cabinet');
            setIsActiveCalendar(false);
            setIsActiveComments(false);
            //setIsActiveProfile(false);
            //setIsActiveForum(false);
            setActiveDot(false)
            setActiveSearch(true)
        }
    }
    const handleDotClick = () => {
        if (isLoggedIn()) {

            setIsActiveCalendar(false);
            setIsActiveComments(false);
            setActiveSearch(false);
            setActiveDot(true);
            setIsOpen(!isOpen);

        }
    }

    const hadleNotification = () => {

    }
    const isLoggedIn = () => {
        return Cookies.get('authToken') !== null;
    }
    const handleLogout = async () => {
        isLoggedIn()
        try {
            // Make a request to the backend logout endpoint with the token in the headers
            const response = await axios.post(
                `${url}/api/auth/logout`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
                    },
                }
            );
            // // Handle successful logout response
            // setShowMessage(true);
            // setMessage("Logged out successfully");
            Cookies.remove("loggedInUserId");
            Cookies.remove("authToken");
            Cookies.remove("user_type");
            navigate("/login"); // Redirect to the login page or any other appropriate page
        } catch (error) {
            // Handle logout error
            console.error("Error logging out:", error);
        }
    };

    return (
        <>
            <div
                className="sm:hidden flex flex-row mx-auto justify-between shadow-md items-center px-[32px] rounded-[100px] w-10/12 h-[58px] mb-3"
                style={{
                    background: 'linear-gradient(180deg, #ff6535 0%, #ff9e48 48.9%, #ffce58 100%)',
                    position: 'fixed',
                    bottom: 0,
                }}
            >
                <RiSearch2Fill
                    className={`text-3xl ${isActiveSearch ? 'text-white' : 'text-[#F3C699]'} cursor-pointer hover:text-white`}
                    onClick={handleSearchClick}
                />

                <FaCalendarCheck
                    className={`text-3xl ${isActiveCalendar ? 'text-white' : 'text-[#F3C699]'} cursor-pointer hover:text-white`}
                    onClick={handleCalendarClick}
                />
                <div className='relative'>
                <FaComments
                    className={`text-4xl ${isActiveComments ? 'text-white' : 'text-[#F3C699]'} cursor-pointer hover:text-white`}
                    onClick={handleCommentsClick}
                />
                {(unreadChat > 0) && (
                    <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full w-4 h-4 lg:w-5 lg:h-5 text-white text-xs flex items-center justify-center">
                        {unreadChat}
                    </div>
                )}
                </div>
                <BsThreeDotsVertical
                    className={`text-4xl ${isActiveDots ? 'text-white' : 'text-[#F3C699]'} cursor-pointer hover:text-white`}
                    onClick={handleDotClick}
                />
                {isOpen ? (
                    <div className="absolute bottom-14  right-0 w-34 bg-white p-4 shadow-md rounded-lg mt-2 mb-4 flex flex-col gap-2">
                        <div className='border-2 border-orange-500 rounded-full  items-center w-48 h-10 flex justify-center gap-1 cursor-pointer' onClick={handleUserGroupClick}
                            onMouseEnter={handleMouseEnterForum}
                            onMouseLeave={handleMouseLeaveForum}
                        >
                            <div>

                            </div>
                            <HiMiniUserGroup
                                style={{ color: isHoveredForum ? 'white' : 'orange', fontSize: '1.5rem' }}
                            />
                            <span className='text-[14px] text-gradient font-bold'>Forum</span>
                        </div>
                        <div className='border-2 border-orange-500 rounded-full  items-center w-48 h-10 flex justify-center gap-1 cursor-pointer' onClick={handlePersonCircleClick}
                            onMouseEnter={handleMouseEnterProfile}
                            onMouseLeave={handleMouseLeaveProfile}>
                            <IoPersonCircle style={{ color: isHoveredProfile ? 'white' : 'orange', fontSize: '1.5rem' }} />
                            <span className=' text-[14px] text-gradient font-bold'>Profile</span>
                        </div>
                        <div className='relative border-2 border-orange-500 rounded-full  items-center w-48 h-10 flex justify-center gap-1 cursor-pointer' onClick={hadleNotification}
                            onMouseEnter={handleMouseEnterEvents}
                            onMouseLeave={handleMouseLeaveEvents}
                        >
                            <div>
                                {(unreadChat > 0 || unreadCount > 0) && (
                                    <div className=" bg-red-500 rounded-full w-6 h-6 lg:w-5 lg:h-5 text-white text-xs flex items-center justify-center">
                                        {unreadCount}
                                    </div>
                                )}
                            </div>
                            <IoNotifications
                                style={{ color: isHoveredEvents ? 'white' : 'orange', fontSize: '1.5rem' }}
                            />
                            <span className=' text-[14px] text-gradient font-bold'>Notification</span>
                        </div>
                        <div className='border-2 border-orange-500 rounded-full  items-center w-48 h-10 flex justify-center gap-1 cursor-pointer' onClick={handleLogout}
                            onMouseEnter={handleMouseEnterLogout}
                            onMouseLeave={handleMouseLeaveLogout}
                        >
                            <RiLogoutBoxRLine
                                style={{ color: isHoverLogout ? 'white' : 'orange', fontSize: '1.5rem' }}
                            />
                            <span className=' text-[14px] text-gradient font-bold'>Logout</span>
                        </div>


                    </div>
                ) : (null)
                }

            </div>
            {showNotifications && <Notifications setShowNotifications={setShowNotifications} />}  </>
    );
};

export default MenuBottom;