import React from 'react'
import NavHome from '../../components/nav/navHome';
import Footer from '../../components/nav/footer';
import Header from '../../components/headerBackground'
import Cookies from "js-cookie";
import NavBar from '../../components/nav/navBar';
import support from '../../assets/img/support2.jpg';
import { BsBook, BsPeopleFill, BsPersonGear } from 'react-icons/bs';
const AboutUs = () => {
    const isAuthenticated = Cookies.get("authToken") !== undefined;
    return (
        <div className=''>
            {isAuthenticated ? <NavBar /> : <NavHome />}
            <section className="mb-0 sm:mb-0 font-primary ">
                <Header />
                <div className='sm:mt-20 lg:mt-28 mt-[160px] mx-2 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 flex flex-col justify-center gap-4'>
                    <h2 className='font-semibold sm:text-2xl lg:text-3xl text-xl text-center uppercase text-[#585858]'>ABOUT career guidance buddy</h2>
                    <h2 className='font-medium sm:text-lg lg:text-lg text-base text-left text-[#585858] my-4'>CareerGuidanceBuddy is a software application owned, developed and run by CGB ORG company. For more details about CGB ORG please visit -
                        <a href="https://cgborg.com/" className='text-blue-400'>www.cgborg.com</a>
                    </h2>
                    <div className='flex items-center justify-center'>

                        <div className='text-base sm:text-base lg:text-lg flex flex-col gap-6 text-left mt-2 mb-6'>
                            <div className="mx-auto mt-6 max-w-2xl sm:mt-20 lg:mt-16 lg:max-w-6xl">
                                <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-24">
                                    <div className="relative pl-16">
                                        <dt className="text-lg font-semibold leading-7 text-gradient">
                                            <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-gradient">
                                                <BsBook className="h-6 w-6 text-white" />
                                            </div>
                                            Our Philosophy
                                        </dt>
                                        <dd className="mt-4 text-base leading-7 text-gray-600">At CGBORG, we believe in this world education comes first. Education can only build a sustainable future for us. We will support and encourage students and teachers to make a healthy world together.</dd>
                                    </div>
                                    <div className="relative pl-16">
                                        <dt className="text-lg font-semibold leading-7 text-gradient">
                                            <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-gradient">
                                                <BsPersonGear className="h-6 w-6 text-white" />
                                            </div>
                                            Our Approach
                                        </dt>
                                        <dd className="mt-4 text-base leading-7 text-gray-600">We believe in enhancing and bringing in new ways for communication between Students and Teachers. We are devotional dedicated and disciplined to open doors for every student and teacher in this world.</dd>
                                    </div>
                                    <div className="relative pl-16">
                                        <dt className="text-lg font-semibold leading-7 text-gradient">
                                            <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-gradient">
                                                <BsPeopleFill className="h-6 w-6 text-white" />
                                            </div>
                                            Our Team
                                        </dt>
                                        <dd className="mt-4 text-base leading-7 text-gray-600">We are growing group of Software developers and new comers from college who are passionate to continuously innovate the world around us. </dd>
                                    </div>

                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default AboutUs