import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import Cookies from "js-cookie";

const SearchChat = ({ searchQuery, setSearchQuery, handleSearch, handleClearSearch }) => {
    const [isFocused, setIsFocused] = useState(false);
    let authToken = Cookies.get("authToken");
    let user_type = Cookies.get("user_type");
    let user = user_type === "Student" ? "Teacher" : "Student";
    const handleInputFocus = () => {
        setIsFocused(true);
    };

    const handleInputBlur = () => {
        setIsFocused(false);
    };

    const handleChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        if (query.trim() === '') {
            handleClearSearch(); // Call handleClearSearch if search query is empty
        } else {
            handleSearch(query); // Trigger the search logic in the parent component with the current query
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission on Enter key press
            // You can add additional logic here, such as triggering search on Enter
        }
    };


    return (
        <>
        {/* <div className='flex flex-col fixed items-left top-[160px] mx-6 gap-2'>
            <div className='mt-2'>
                <p className='text-left text-[22px] text-[#585858] font-semibold'>Messages</p>
            </div>
            <div className="flex flex-col sm:flex-row items-center justify-between sm:px-12 mb-2 sm:mb-[18px]">
                <form action="#" method="POST" className="mb-2 sm:mb-0 sm:mr-12 sm:w-1/2">
                    <div className={`relative `}>
                        <div className="absolute inset-y-0 left-0 pl-[15px] flex items-center pointer-events-none">
                            <FaSearch className={`text-md ${isFocused ? 'text-gradient' : 'text-[#AFAFAF]'}`} />
                        </div>
                        <input
                            type="text"
                            className={`pl-10 pr-4 py-3 border border-[#AFAFAF] text-md w-full rounded-full min-w-[300px] 
                            ${isFocused ? 'border border-orange-500 rounded-full' : 'border-[#AFAFAF]'}`}
                            placeholder="Search Chats"
                            aria-label="Search Chats"
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            value={searchQuery}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown} // Handle key events
                        />
                    </div>
                </form>
            </div>
        </div>
         */}
         <div className='mt-[160px] sm:mt-[48px] flex flex-col items-center justify-center sm:flex-row sm:items-center sm:justify-center mx-2 sm:mx-auto gap-2 sm:w-full max-w-[1000px]'>
                <div className='flex items-start self-start ml-4 mt-2 sm:hidden'>
                    <p className='text-left text-[22px] text-[#585858] font-semibold'>Messages</p>
                </div>
            <div className="flex flex-col w-full sm:flex-row items-start mb-2 sm:mb-[5px] sm:w-full">
                <form action="#" method="POST" className="mb-2 w-full mx-2 sm:mb-0 sm:w-full sm:flex sm:gap-4 items-center justify-center">
                    <div className={`relative border-[#AFAFAF] flex-grow`} onFocus={handleInputFocus}>
                        <div className="absolute inset-y-0 left-0 pl-[15px] flex items-center pointer-events-none">
                            <FaSearch className={`text-md ${isFocused ? 'text-gradient' : 'text-[#AFAFAF]'}`} />
                        </div>
                        <input
                            type="text"
                            className={`pl-10 pr-4 py-2 border border-[#AFAFAF] text-md w-11/12 rounded-full min-w-[300px] sm:w-full 
                            ${isFocused ? 'border border-orange-500 rounded-full' : 'border-[#AFAFAF]'}`}
                            placeholder={`Search Chat / Type ${user} name to start a search`}
                            aria-label="Search Chats"
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            value={searchQuery}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown} // Handle key events
                        />
                    </div>
                </form>
            </div>
        </div>
        </>
    );
}

export default SearchChat;

// responsive
{/* <div className='mt-[140px] sm:mt-[268px] flex flex-col sm:flex-row items-left sm:items-center sm:justify-center mx-6 sm:mx-auto gap-2 sm:w-full max-w-[1000px]'>
                <div className='mt-2 sm:hidden'>
                    <p className='text-left text-[22px] text-[#585858] font-semibold'>Messages</p>
                </div>
            <div className="flex flex-col sm:flex-row items-start mb-2 sm:mb-[18px] sm:w-full">
                <form action="#" method="POST" className="mb-2 sm:mb-0 sm:w-full sm:flex sm:gap-4 items-center justify-center">
                    <div className={`relative border-[#AFAFAF] flex-grow`}>
                        <div className="absolute inset-y-0 left-0 pl-[15px] flex items-center pointer-events-none">
                            <FaSearch className={`text-md ${isFocused ? 'text-gradient' : 'text-[#AFAFAF]'}`} />
                        </div>
                        <input
                            type="text"
                            className={`pl-10 pr-4 py-3 border border-[#AFAFAF] text-md w-full rounded-full min-w-[300px]
                            ${isFocused ? 'border border-orange-500 rounded-full' : 'border-[#AFAFAF]'}`}
                            placeholder="Search Chats"
                            aria-label="Search Chats"
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            value={searchQuery}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown} // Handle key events
                        />
                    </div>
                </form>
            </div>
        </div> */}