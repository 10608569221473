import React, { useEffect, useState } from 'react'
import NavHome from '../components/nav/navHome';
import Footer from '../components/nav/footer';
import Header from '../components/headerBackground';
import teacher from '../assets/img/teacher.jpg';
import home from '../assets/img/login-preview.PNG'
import student from '../assets/img/student.jpg';
import { MdOutlinePeopleAlt } from "react-icons/md";
import { TbUserStar } from "react-icons/tb";
import { FaUserTie } from "react-icons/fa";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { BsSearch, BsBook, BsCheckCircle } from "react-icons/bs";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import NavBar from '../components/nav/navBar';
import { Helmet } from 'react-helmet-async';
import MetaDecorator from '../utils/MetaDecorator';
import axios from 'axios';
const Home = () => {
    const isAuthenticated = Cookies.get("authToken") !== undefined;
    const apiUrl = process.env.REACT_APP_API_URL;
    const authToken = Cookies.get("authToken");
    const user_type = Cookies.get("user_type");
    const [found, setFound] = useState();
    const [verified, setVerified] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if (authToken) {
            if (user_type === 'Teacher') {
                Get_Teacher_Details();
            } else {
                Get_Student_Details();
            }
        }
    }, []);

    const Get_Teacher_Details = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/teacher/get-details`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            setFound(response.data.found);
            setVerified(response.data.verified);
        } catch (error) {
            console.error("Error fetching teacher details", error);
        }
    };

    const Get_Student_Details = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/student/get-details`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            setFound(response.data.found);
            setVerified(response.data.verified);
        } catch (error) {
            console.error("Error fetching student details", error);
        }
    };
    const hostname = 'https://careerguidancebuddy.com';
    const keywords = ["elearning", "education", "online class", "education", "student", "teacher", "learning"];
    return (
        <div className=''>
            {/* <MetaDecorator title={'Career Guidance Buddy'} description={'Social Media App Connecting Students & Teachers'} imageUrl={home} keywords={keywords} imageAlt={''} url={window.location.href} /> */}
            <Helmet>
                <title>Career Guidance Buddy - Career Guidance Buddy</title>
                <meta name="description" content='Social Media App Connecting Students & Teachers'/>
                <meta name="keywords" content="education fourm,teacher, education, online class,e-learning" />
                <meta property="og:title" content='Career Guidance Buddy' />
                <meta property="og:description" content='Social Media App Connecting Students & Teachers' />
                <meta property="og:site_nmae" content="Career GuidanceBuddy" />
                <meta property="og:image" content={hostname + home} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:width" content="300" />
                <meta property="og:image:height" content="300" />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content='Career Guidance Buddy' />
                <meta name="twitter:description" content='Social Media App Connecting Students & Teachers' />
                <meta name="twitter:image" content={hostname + home} />
                <meta name="twitter:image:width" content="1200" />
                <meta name="twitter:image:height" content="630" />
                <meta name="twitter:url" content={window.location.href} />
            </Helmet>
            {isAuthenticated && found && verified ? <NavBar /> : navigate('/edit-profile')}
            {!isAuthenticated ? <NavHome /> : ''}
            <section className="mb-0 sm:mb-0 mt-20 sm:mt-20 font-primary ">
                <Header />
                <div className=''>
                    <Carousel showArrows={true}
                        showThumbs={true}
                        showStatus={false}
                        infiniteLoop={true}
                        autoPlay={true}
                        interval={3000}
                        showIndicators={true}
                        transitionTime={500}
                        className="w-full sm:mt-0 ">
                        <div className=''>

                            <div className='mt-24 sm:mt-10'>

                                <span className='text-gradient text-center font-bold text-md sm:text-2xl'>A SOCIAL MEDIA APP CONNECTING STUDENTS AND TEACHERS</span>
                            </div>
                            <div className='flex flex-col sm:flex-row justify-center items-center sm:w-full h-[440px] sm:h-[500px]'>
                                <div className='w-10/12 h-[200px] sm:w-4/12 sm:h-[500px]'>
                                    <img src={student} alt="" className='w-10/12 h-[200px] sm:w-[100px] sm:h-[500px]' />

                                </div>
                                <div className='flex flex-col items-center justify-center gap-1 sm:gap-4 h-[360px] sm:h-[500px]'>
                                    <div>
                                        <p className='text-gradient font-bold text-lg sm:text-3xl'>Join as a Student</p>
                                        <p className='text-gradient font-bold text-lg sm:text-2xl'>&</p>
                                    </div>
                                    <div>
                                        <p className="text-[#585858] text-md sm:text-xl font-semibold sm:font-bold">Explore Learn and become Exceptional with best experts & guides.</p>
                                        <p className="text-[#afafaf] text-sm sm:text-xl font-semibold sm:font-bold">Zero joining fee for students.</p>
                                    </div>
                                    <Link to='/signup-student' state={{ user_type: "Student" }}>
                                        <button className='bg-gradient text-white px-6 py-2 rounded-full font-semibold text-sm sm:text-xl'>Join Today & Start Learning</button>
                                    </Link>
                                    <Link to='/login'>
                                        <button className='sm:hidden bg-white py-1 px-6 rounded-full'>
                                            <span className='text-gradient font-semibold text-[17px]'>Log In</span>
                                        </button>
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div className=''>
                            <div className='mt-24 sm:mt-10'>
                                <span className='text-gradient text-center font-bold text-md sm:text-2xl'>A SOCIAL MEDIA APP CONNECTING STUDENTS AND TEACHERS</span>
                            </div>
                            <div className='flex flex-col sm:flex-row justify-center items-center sm:w-full h-[440px] sm:h-[500px]'>
                                <div className='w-10/12 h-[200px] sm:w-5/12 sm:h-[500px]'>
                                    <img src={teacher} alt="" className='w-10/12 h-[200px] sm:w-[100px] sm:h-[500px]' />

                                </div>
                                <div className='flex flex-col items-center justify-center gap-2 sm:gap-4 h-[360px] sm:h-[500px]'>
                                    <div>
                                        <p className='text-gradient font-bold text-lg sm:text-3xl'>Join as a Teacher</p>
                                        <p className='text-gradient font-bold text-lg sm:text-2xl'>&</p>
                                    </div>
                                    <div>
                                        <p className="text-[#585858] text-md sm:text-xl font-semibold sm:font-bold">Become A Guide,Become A Mentor Become A Buddy. </p>
                                        <p className="text-[#585858] text-md sm:text-xl font-semibold sm:font-bold">Create your own brand</p>
                                    </div>
                                    <Link to='/signup-teacher' state={{ user_type: "Teacher" }}>
                                        <button className='bg-gradient text-white px-6 py-2 rounded-full font-semibold text-sm sm:text-xl'>Join Today & Start Earning</button>
                                    </Link>
                                    <Link to='/login'>
                                        <button className='sm:hidden bg-white py-1 px-6 rounded-full'>
                                            <span className='text-gradient font-semibold text-[17px]'>Log In</span>
                                        </button>
                                    </Link>
                                </div>

                            </div>
                        </div>

                    </Carousel>
                </div>
                <div className='sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5'>
                    <div className="bg-white py-10 sm:py-20">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <div className="mx-auto max-w-2xl lg:text-center">
                                <h2 className="text-lg font-semibold leading-7 text-gradient">Student's First</h2>
                                <p className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-3xl">FOR OUR STUDENTS AND LEARNERS</p>
                                <p className="mt-4 text-lg leading-8 text-gray-600">This is a web platform for the students to unlock the world of knowledge and explore the world.</p>
                            </div>
                            <div className="mx-auto mt-6 max-w-2xl sm:mt-20 lg:mt-16 lg:max-w-6xl">
                                <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-24">
                                    <div className="relative pl-16">
                                        <dt className="text-lg font-semibold leading-7 text-gradient">
                                            <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-gradient">
                                                <BsSearch className="h-6 w-6 text-white" />
                                            </div>
                                            Explore Here
                                        </dt>
                                        <dd className="mt-4 text-base leading-7 text-gray-600">This is a web platform for the students to unlock the world of knowledge and explore the world. As a student, you can search among hundreds of teachers across the globe for the any subject of your choice. You can read the forum post or create a new post to discuss on a topic. You can also communicate with teachers over chat.</dd>
                                    </div>
                                    <div className="relative pl-16">
                                        <dt className="text-lg font-semibold leading-7 text-gradient">
                                            <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-gradient">
                                                <BsBook className="h-6 w-6 text-white" />
                                            </div>
                                            Learn here
                                        </dt>
                                        <dd className="mt-4 text-base leading-7 text-gray-600">A student can browse a teacher's calander, and book a call with a teacher to clarify his/her doubts and learn more from a subject matter expert.
                                            A student can also see the upcoming conferences/online-classes created by various teachers and subject matter expert. The students can join those conferences or classes to learn from a subject matter expert. </dd>
                                    </div>
                                    <div className="relative pl-16">
                                        <dt className="text-lg font-semibold leading-7 text-gradient">
                                            <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-gradient">
                                                <BsCheckCircle className="h-6 w-6 text-white" />
                                            </div>
                                            Student's First
                                        </dt>
                                        <dd className="mt-4 text-base leading-7 text-gray-600">We're committed to our students. That is why if a Teacher do not join any call or any online class. The students get 100% refund of their payments.

                                            After the call and conference/class each student get a option to share their experience about the teacher which is publicly visible for other students. </dd>
                                    </div>

                                </dl>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white py-10 sm:py-16">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <div className="mx-auto max-w-4xl lg:text-center">
                                <h2 className="text-lg font-semibold leading-7 text-gradient">Guide and Earn</h2>
                                <p className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-3xl">FOR OUR TEACHERS AND SUBJECT MATTER EXPERTS</p>
                                <p className="mt-4 text-lg leading-8 text-gray-600">This is a web platform for the teachers to connect with students and earn more.</p>
                            </div>
                            <div className="mx-auto mt-6 max-w-2xl sm:mt-20 lg:mt-16 lg:max-w-6xl">
                                <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-24">
                                    <div className="relative pl-16">
                                        <dt className="text-lg font-semibold leading-7 text-gradient">
                                            <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-gradient">
                                                <FaUserTie className="h-6 w-6 text-white" />
                                            </div>
                                            Become A Guide
                                        </dt>
                                        <dd className="mt-4 text-base leading-7 text-gray-600">This web platform enables the teachers and subject matter experts to connect with students across the world. As a teacher, you can answer the students questions over chat and forum. Create and share new relevant posts to make the students aware.</dd>
                                    </div>
                                    <div className="relative pl-16">
                                        <dt className="text-lg font-semibold leading-7 text-gradient">
                                            <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-gradient">
                                                <MdOutlinePeopleAlt className="h-6 w-6 text-white" />
                                            </div>
                                            Become A Buddy
                                        </dt>
                                        <dd className="mt-4 text-base leading-7 text-gray-600">As a teacher and subject matter expert you can share your calendar availability for the students to help them by explain a topic or elaborating and clarifying a given question.You can also create online conferences and classes for the students. </dd>
                                    </div>
                                    <div className="relative pl-16">
                                        <dt className="text-lg font-semibold leading-7 text-gradient">
                                            <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-gradient">
                                                <TbUserStar className="h-6 w-6 text-white" />
                                            </div>
                                            Create your own brand
                                        </dt>
                                        <dd className="mt-4 text-base leading-7 text-gray-600">Go-Live, interact with students help them to learn and establish your expertise to enable and enlighten them.</dd>
                                    </div>

                                </dl>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <Footer />
        </div>
    )
}

export default Home