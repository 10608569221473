import React, { useEffect } from 'react'
import logo from '../../assets/img/logo.svg'
import Cookies from "js-cookie";
import { FaFacebookF, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { Link, useLocation } from 'react-router-dom';
const Footer = () => {
    const isAuthenticated = Cookies.get("authToken") !== undefined;
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <div>
            <footer className={`bg-[#585858] dark:bg-gray-900 font-primary`}>

                <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-6">
                    <div className="md:flex md:justify-between">
                        <div className="flex flex-col gap-6 justify-between mb-6 md:mb-0">
                            <a href="https://careerguidancebuddy.com/" className="flex items-center">
                                <img src={logo} className="h-8 me-3" alt="CGB Logo" />
                            </a>
                            <div>
                                <h2 className="mb-4 text-sm font-medium text-white uppercase dark:text-white">Social Links</h2>
                                <div className="flex mt-4 sm:justify-start items-center sm:gap:10 sm:mt-0">
                                    <a href="https://www.facebook.com/profile.php?id=61561539420150" target='_self' className="text-gray-100 hover:text-white dark:hover:text-white">
                                        <FaFacebookF className='w-6 h-5' />
                                        <span className="sr-only">Facebook page</span>
                                    </a>
                                    <a href="https://www.linkedin.com/company/careerguidancebuddy/" className="text-gray-100 hover:text-white dark:hover:text-white ms-5">
                                        <FaLinkedinIn className='w-6 h-6' />
                                        <span className="sr-only">LinkedIn page</span>
                                    </a>
                                    <a href="https://www.youtube.com/@CGBORG-0502" className="text-gray-100 hover:text-white dark:hover:text-white ms-5">
                                        <FaYoutube className='w-6 h-6 ' />
                                        <span className="sr-only">YouTube channel</span>
                                    </a>

                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-1">
                            <div>
                                <h2 className="mb-4 sm:mb-6 text-sm font-medium text-white uppercase dark:text-white">Quick Links</h2>
                                <ul className="grid grid-cols-2 gap-x-6 text-gray-200 dark:text-gray-200">
                                    <li className="mb-2 sm:mb-4">
                                        <Link to="/terms-and-conditions" className="hover:underline text-xs sm:text-base ">Terms & Conditions</Link>
                                    </li>
                                    <li>
                                        <Link to="/refundPolicy" className="hover:underline text-xs sm:text-base ">Refund Policy</Link>
                                    </li>
                                    <li className="mb-2 sm:mb-4">
                                        <Link to="/privacyPolicy" className="hover:underline text-xs sm:text-base">Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/paymentPolicy" className="hover:underline text-xs sm:text-base">Payment Policy</Link>
                                    </li>
                                    <li className="mb-2">
                                        <Link to="/userAgreement" className="hover:underline text-xs sm:text-base"> User Agreement</Link>
                                    </li>
                                    <li className="mb-2 sm:mb-4">
                                        <Link to="/supportPolicy" className="hover:underline text-xs sm:text-base">Support Policy</Link>
                                    </li>
                                    <li className="mb-2">
                                        <Link to="/contactUs" className="hover:underline text-xs sm:text-base">Contact Us</Link>
                                    </li>
                                    <li className="mb-2 sm:mb-4">
                                        <Link to="/shippingPolicy" className="hover:underline text-xs sm:text-base">Shipping Policy</Link>
                                    </li>
                                    <li >
                                        <Link to="/aboutUs" className="hover:underline text-xs sm:text-base">About Us</Link>
                                    </li>
                                    <li >
                                        <Link to="/pricing" className="hover:underline text-xs sm:text-base">Pricing</Link>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-4" />
                    <div className="sm:flex sm:flex-col flex flex-col gap-2 items-center text-center sm:gap-2 sm:items-center sm:justify-between">
                        <span className="text-sm sm:text-base text-center text-gray-200 sm:text-center dark:text-gray-400">
                            A social media application developed and owned by <a href="https://cgborg.com" className='hover:underline'>CGB ORG</a>
                        </span>
                        <span className="text-sm sm:text-base text-gray-200 sm:text-center dark:text-gray-400">© 2024 <a href="https://careerguidancebuddy.com/" className="hover:underline">CareerGuidanceBuddy™</a>. All Rights Reserved.
                        </span>
                    </div>
                </div>
            </footer>

        </div>
    )
}

export default Footer