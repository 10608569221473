import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios'; // Import Axios for making HTTP requests
import { FiEdit } from "react-icons/fi";
import Cookies from 'js-cookie';
import cancle from '../assets/img/xxx.png'
import { FiChevronDown } from 'react-icons/fi'
import { FaCheckCircle } from "react-icons/fa";
import { BsFilterCircleFill } from "react-icons/bs";

const NewPost = ({ onNewPost, onFilterApply, onClear, isModalOpen, setIsModalOpen }) => {
  const [showModal, setShowModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [postTitle, setPostTitle] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [filteredCategory, setFilteredCategory] = useState('');
  const [filteredSubCategory, setFilteredSubCategory] = useState('');
  const [filteredSelectedCategory, setFilteredSelectedCategory] = useState('');
  const [filteredSelectedSubCategory, setFilteredSelectedSubCategory] = useState('');
  const [postContent, setPostContent] = useState('');
  const [image, setImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null); // Define fileInputRef using useRef
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const authToken = Cookies.get('authToken');
  const loggedInUserId = Cookies.get('loggedInUserId');
  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const user_type = Cookies.get("user_type");
  const url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    // Fetch categories
    axios.get(`${url}/api/categories/getAllCategory`)
      .then(response => {

        setCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });

    // Fetch subcategories
    axios.get(`${url}/api/subCategories/getAllSubcategory`)
      .then(response => {
        setSubCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching subcategories:', error);
      });
  }, []);


  const handleFilterModalOpen = () => {
    setShowFilterModal(((prevState) => !prevState));
  }
  const handleFilterModalClose = () => {
    setFilterLoading(true); // Set filterLoading to true when starting the filter cancelling process
    setTimeout(() => {
      setShowFilterModal(false);
      setFilterLoading(false); // Set filterLoading to false when the filter cancelling process is complete
    }, 500);
  }
  const handleModalOpen = () => {
    setPostTitle('');
    setCategory(filteredCategory); // Set default category from filtered category
    setSubCategory(filteredSubCategory); // Set default subcategory from filtered subcategory
    setPostContent('');
    setImage(null);
    setSelectedCategoryId(filteredCategory); // Set selected category ID
    setShowModal(true);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setPostTitle('');
    setCategory('');
    setSubCategory('');
    setPostContent('');
    setImage(null);
    setShowModal(false);
    setIsModalOpen(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setSelectedImage(file ? URL.createObjectURL(file) : null); // Check if file exists before setting image state

  };
  useEffect(() => {
    getLoggedInUserDetails()
  }, [authToken, loggedInUserId])
  const getLoggedInUserDetails = async () => {
    try {
      // console.log(loggedInUserId)
      const response = await axios.get(`${url}/api/user-info/get-users/${loggedInUserId}`, {
        headers: {
          "Authorization": `Bearer ${authToken}`
        }
      });
      // console.log('loggedinuser', response.data);
      setLoggedInUser(response.data);
    } catch (error) {
      console.log('Error fetching user', error);
    }
  }
  const handleCreatePost = async (e) => {
    e.preventDefault();
    if (!postTitle) {
      alert("Title is required.");
      return;
    }
    if (!postContent) {
      alert("Content is required.");
      return;
    }
    if (!category) {
      alert("Category is required.");
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("postTitle", postTitle);
      formData.append('category', category);
      if (subCategory) { // Only append subCategory if it's provided
        formData.append('subCategory', subCategory);
      }

      formData.append('postContent', postContent);
      formData.append('image', image);
      // console.log(formData)
      const response = await axios.post(`${url}/api/forum/create`,
        formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      // console.log(response.data); // Log the response from the server

      // Clear form fields and close modal after successful creation
      setPostTitle('');
      setCategory('');
      setSubCategory('');
      setPostContent('');
      setImage(null);
      onNewPost(response.data.post);
      setShowModal(false);
      setIsModalOpen(false);
    } catch (error) {
      console.error(error);
      // Handle error appropriately, e.g., show error message to the user
    } finally {
      setLoading(false); // Set loading to false when the post creation process is complete
    }
  };

  useEffect(() => {
    setFilteredSelectedSubCategory(''); // Reset filteredSelectedSubCategory when filteredSelectedCategory changes
  }, [filteredSelectedCategory]);

  const clearCategorySelections = async () => {
    setFilteredSelectedCategory('');
    setFilteredCategory('');
    setFilteredSubCategory('');
    setIsFilterApplied(false); // Reset filter application when categories are cleared
    onClear(filteredCategory, filteredSubCategory)

  };

  const clearSubCategorySelections = async () => {
    setFilteredSubCategory('');
    setFilteredSelectedSubCategory('');
    setIsFilterApplied(false); // Reset filter application when subcategories are cleared
    onClear(filteredCategory, filteredSubCategory)
  };

  const handleFilterApply = async () => {
    setFilterLoading(true); // Set filterLoading to true when starting the filter application process
    setTimeout(() => {
      setShowFilterModal(false);
      setIsFilterApplied(true); // Set filter application to true when filter is applied
      onFilterApply(filteredCategory, filteredSubCategory);
      setFilterLoading(false); // Set filterLoading to false when the filter application process is complete
    }, 500); // Simulate a small delay
  };

  return (
    <>
      <div className='flex flex-col bg-white'>
        {/* select filters section */}
        {showFilterModal && (
          <div className="sm:hidden flex flex-col w-full mx-auto p-1 fixed bottom-36 left-0 right-0 mt-[-70px] mb-[-30px] bg-white text-center">
            <h1
              className="text-[18px] text-left mx-4 mt-1 font-semibold"
              style={{
                background: 'linear-gradient(180deg, #ff6535 0%, #ff9e48 48.9%, #ffce58 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Filter Posts:
            </h1>
            <div className="flex flex-row items-center justify-between mt-1 mx-2">
              <div
                className="flex items-center justify-center px-2  shadow-md mx-auto mb-1 border border-orange-500 rounded-full w-11/12 mr-1"
              // style={{ boxShadow: '0px 2px 6px 0px #00000080' }}
              >
                <div className="relative w-1/2">
                  <select className="appearance-none focus:outline-none bg-no-repeat text-gradient font-bold w-full py-2 rounded-md"
                    value={filteredCategory}
                    onChange={(e) => { setFilteredCategory(e.target.value); setFilteredSelectedCategory(e.target.options[e.target.selectedIndex].text) }}
                  >
                    <option value="" className="text-left text-[11px] w-1/2">Category</option>
                    {categories.map((cat) => (
                      <option className="w-screen" key={cat._id} value={cat._id}>
                        {cat.name}
                      </option>
                    ))}
                  </select>
                  <FiChevronDown className='absolute top-1/2 right-5 transform -translate-y-1/2 text-orange-500'></FiChevronDown>
                </div>
                <div className="relative w-1/2">
                  <select
                    className="appearance-none focus:outline-none py-2 w-full text-base text-gradient font-bold bg-no-repeat rounded-md"
                    value={filteredSubCategory}
                    onChange={(e) => { setFilteredSubCategory(e.target.value); setFilteredSelectedSubCategory(e.target.options[e.target.selectedIndex].text) }}
                  >
                    <option value="" className='text-left w-1/2'>Subcategory</option>
                    {subCategories
                      .filter((subcat) => subcat.categoryId === filteredCategory)
                      .map((subcat) => (
                        <option className='w-screen' key={subcat._id} value={subcat._id}>
                          {subcat.name}
                        </option>
                      ))}
                  </select>
                  <FiChevronDown className='absolute top-1/2 right-0 transform -translate-y-1/2 text-orange-500'></FiChevronDown>
                </div>
              </div>
              <div className='w-1/12 ml-2 text-center'>
                {filterLoading ? <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-orange-500" role="status">
                </div> : <FaCheckCircle className="w-6 h-8 text-orange-400 cursor-pointer"
                  onClick={handleFilterApply}
                  title='apply filters'
                  
                />}

              </div>
            </div>
          </div>
        )}
        {/* show filters tag */}
        <div className="fixed top-[190px] md:top-[130px] flex items-center justify-center">
          {filteredSelectedCategory && (
            <div
              style={{ boxShadow: '0px 2px 8px 0px #000000b2' }}
              className="rounded-full px-2 py-[10px] flex justify-center items-center bg-white mr-5 shadow-md"
            >
              <p
                style={{
                  background: 'linear-gradient(180deg, #ff6535 0%, #ff9e48 48.9%, #ffce58 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
                className="pr-2 text-[15px] font-medium"
              >
                {filteredSelectedCategory}
              </p>
              <img src={cancle} alt="" onClick={clearCategorySelections} />
            </div>
          )}
          {filteredSelectedSubCategory && (
            <div
              style={{ boxShadow: '0px 2px 8px 0px #000000b2' }}
              className="rounded-full px-2 py-[10px] flex justify-center items-center bg-white mr-5 shadow-md"
            >
              <p
                style={{
                  background: 'linear-gradient(180deg, #ff6535 0%, #ff9e48 48.9%, #ffce58 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
                className="pr-2 text-[15px] font-medium"
              >
                {filteredSelectedSubCategory}
              </p>
              <img src={cancle} alt="" onClick={clearSubCategorySelections} />
            </div>
          )}

        </div>
        {/* create post input */}
        <div className="flex sm:hidden bg-white justify-center items-center fixed left-0 bottom-[70px] gap-1 px-2 w-full py-1">

          <div className="relative flex" onClick={handleModalOpen}>
            <span className="absolute inset-y-0 flex items-center">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-gray-500 focus:outline-none"
                style={{
                  background:
                    'linear-gradient(180deg, #ff6535 0%, #ff9e48 48.9%, #ffce58 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                <FiEdit className='text-orange-400' />
              </button>
            </span>

            <div className="p-[1px] rounded-full input-wrap">
              <input
                type="text"
                placeholder="Create a post"

                value={postTitle}
                onChange={(e) => setPostTitle(e.target.value)}
                className="w-[268px] border border-orange-500 rounded-full focus:outline-none text-sm focus:placeholder-[#afafaf] text-[#afafaf] placeholder-[#afafaf] pl-12 bg-white py-2"
              />
            </div>
          </div>

          <button type="button" className="w-[28px] h-[28px]" title='filter posts'>
            <BsFilterCircleFill className="w-[28px] h-[28px] text-orange-400" onClick={handleFilterModalOpen} />
          </button>

          {/* Modal for creating post */}
          {showModal && (
            <form method='POST' onSubmit={handleCreatePost} encType="multipart/form-data" action={`${url}/api/forum/create`} className="w-full inset-0 fixed top-2 left-0 z-50 mt-10 h-full flex justify-center items-center bg-black bg-opacity-50">
              <div className="bg-white px-6 py-4 rounded-lg w-11/12">
                <h2 className="text-[20px] font-semibold mb-4" style={{
                  background: 'linear-gradient(180deg, #ff6535 0%, #ff9e48 48.9%, #ffce58 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}>Create Post</h2>
                <input
                  type="text"
                  placeholder="Post Title"
                  value={postTitle}
                  onChange={(e) => setPostTitle(e.target.value)}
                  className="mb-2 w-full border border-gray-300 rounded-md p-2 focus:outline-none"
                // required
                />
                <textarea
                  placeholder="Content"
                  value={postContent}
                  onChange={(e) => setPostContent(e.target.value)}
                  className="mb-2 w-full border border-gray-300 rounded-md p-2 focus:outline-none"
                  rows="4"
                // required
                />
                <select
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);

                    setSelectedCategoryId(e.target.value); // Update selected category ID
                  }}
                  className="mb-2 w-full border border-gray-300 rounded-md p-2 focus:outline-none"
                // required
                >
                  <option value="">Select Category</option>
                  {categories.map((cat) => (
                    <option key={cat._id} value={cat._id}> {/* Concatenate ID and name */}
                      {cat.name}
                    </option>
                  ))}
                </select>

                <select
                  value={subCategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                  className="mb-2 w-full border border-gray-300 rounded-md p-2 focus:outline-none"
                >
                  <option value="">Select Subcategory</option>
                  {subCategories
                    .filter((subcat) => subcat.categoryId === selectedCategoryId)
                    .map((subcat) => (
                      <option key={subcat._id} value={subcat._id}>
                        {subcat.name}
                      </option>
                    ))}
                </select>

                <div className="flex justify-start items-center mb-2">
                  {image && (
                    <img
                      src={selectedImage}
                      alt="Selected Post Image"
                      className="w-20 h-20 object-contain border border-gray-300"
                    />
                  )}
                </div>
                <div className='flex flex-col gap-2'>
                  <input
                    type="file" accept="image/*" onChange={handleImageChange} ref={fileInputRef}
                    className="border border-gray-300 px-2 py-2 text-gray-400 text-left rounded-md"
                  />
                  <div className='flex flex-row items-center'>
                  <button type="submit" className={`text-white px-4 py-2 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} style={{ background: 'linear-gradient(180deg, #ff6535 0%, #ff9e48 48.9%, #ffce58 100%)' }} 
                                disabled={loading} >{loading ? "Creating post..." : "Create"}</button>
                    <button type="button" className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md ml-2" onClick={handleModalClose}>Cancel</button>
                  </div>
                </div>
              </div>
            </form >
          )}
        </div>
      </div>
    </>
  );
};

export default NewPost;
