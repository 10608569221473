import React, { useEffect, useState } from 'react';
import Header from '../../components/headerBackground';
import Calls from '../../components/teacher/callEarnings';
import Conference from '../../components/teacher/conferenceEarnings';
import Referrals from '../../components/teacher/referralEarnings';
import MenuBottom from '../../components/menuBottom';
import Cookies from 'js-cookie'; // Import js-cookie
import MainMenu from '../../components/MenuBottom/MainMenu'
import NavBar from '../../components/nav/navBar';
import axios from 'axios';
import MetaDecorator from '../../utils/MetaDecorator';

const TeacherEarnings = () => {
  const components = ['calls', 'conference', 'referrals']; // Array of component names
  const [activeIndex, setActiveIndex] = useState(0); // State to track active component index
  const [totalCallEarnings, setTotalCallEarnings] = useState(0); // State to store total call earnings
  const [totalConferenceEarnings, setTotalConferenceEarnings] = useState(0);
  const [totalReferralEarnings, setTotalReferralEarnings] = useState(0);
  const authToken = Cookies.get('authToken'); // Access the authToken from cookie
  const user_type = Cookies.get("user_type");
  
  const [totalEarningsConference, setTotalEarningsConference] = useState(0); //to set in useEffect
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchEarnings = async () => {
      try {
        const response = await axios.get(`${url}/api/earningConference/earning_conference`, {
          headers: {
            "Authorization": `Bearer ${authToken}`
          }
        });        
        setTotalEarningsConference(response.data.totalEarnings);
      } catch (err) {
        console.error('Error fetching earnings data', err);
      }
    };
    fetchEarnings();
  }, []);

  const handleNext = () => {

    setActiveIndex((prevIndex) => (prevIndex + 1) % components.length); // Cycle through components
    // console.log('nxt')
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + components.length) % components.length); // Cycle through components in reverse
  };
  const handleCallClick = () => {
    setActiveIndex(0);
  }
  const handleConferenceClick = () => {
    setActiveIndex(1);
  }
  const handleReferralClick = () => {
    setActiveIndex(2);
  }
  const keywords = ["elearning",  "education","student","teacher","learning", "online class"]; 
  return (
    <>
    <MetaDecorator title={'Earnings - Career Guidance Buddy'} description={`Resolve student's doubts and earn!`} imageUrl={''} keywords={keywords} imageAlt={''} url={''}/>
    <div className='flex flex-col gap-2 sm:gap-0 font-primary items-center min-h-screen'>
      <NavBar />
      <div className='mt-40 sm:mt-24'>
        {/* <p className='sm:text-xl text-base border-2 border-orange-400 bg-white text-gradient rounded-full px-6 py-1.5 font-semibold'>Total Earnings: ₹ {totalCallEarnings + totalConferenceEarnings + totalReferralEarnings}</p> */}
        <p className='sm:text-xl text-base text-white bg-gradient rounded-full px-6 py-1.5 font-semibold'>Total Earnings: ₹ {totalCallEarnings + totalEarningsConference + totalReferralEarnings}</p>
      </div>
      {components[activeIndex] === 'calls' && (
        <Calls handleNext={handleNext} handlePrev={handlePrev} handleCallClick={handleCallClick} handleConferenceClick={handleConferenceClick} handleReferralClick={handleReferralClick} setTotalEarnings={setTotalCallEarnings} />
      )}
      {components[activeIndex] === 'conference' && (
        <Conference handleNext={handleNext} handlePrev={handlePrev} handleCallClick={handleCallClick} handleConferenceClick={handleConferenceClick} handleReferralClick={handleReferralClick} setTotalEarnings={setTotalConferenceEarnings} />
      )}
      {components[activeIndex] === 'referrals' && (
        <Referrals handleNext={handleNext} handlePrev={handlePrev} handleCallClick={handleCallClick} handleConferenceClick={handleConferenceClick} handleReferralClick={handleReferralClick} setTotalEarnings={setTotalReferralEarnings} />
      )}

      <MainMenu />
    </div>
    </>
  );
};

export default TeacherEarnings;
