import React,{useState,useEffect} from 'react'
import Calls_Booking_list from './Teacher_Calls_Booking_list';
import Cookies from 'js-cookie';
import { AiOutlinePlus } from "react-icons/ai";
import axios from "axios";
import Loading2 from '../../Loading2';
import Empty_data from '../../Empty_data';
import { useNavigate } from 'react-router-dom';
import { IoIosShareAlt } from 'react-icons/io';


const Main_Call_Page = (props) => {
  const{date}=props
  //const { date } = useContext(DateContext);
  // console.log(date)
  
  const [cardsData, setCardsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const formattedDate = formatDateToYYYYMMDD(date);
  const [showConferenceBookingList, setShowConferenceBookingList] = useState(false);
  const authToken = Cookies.get("authToken"); 
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isActive, setIsActive] = useState(false);
  const [earning,setEarning] = useState();
  const [earning_id,setEarningId] = useState();
  const [isCompleted, setIsCompleted] = useState(false);
  const loggedInUserId = Cookies.get('loggedInUserId');
  const navigate = useNavigate();


  // FUNCTION TO FETCH THE LIST OF CONFERENCES ON THE PARTICULAR DATE

   
    useEffect(() => {
      const fetchBookedCalls = async () => {
        try {
          // console.log(date)
          const response = await axios.post(`${apiUrl}/api/call/call_students_booked_list`, {
            date: formatDateToYYYYMMDD(date),
          }, {
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          });
  
          // console.log("Booked Calls by Student List ===>", response.data);
          setCardsData(response.data);
          // if (cardsData.length > 0) {
          //   cardsData.forEach(card => {
          //     fetchEarningDetails(card._id);
          //   });
          // }
        } catch (error) {
          console.error(error);
          setHasError(true);
        } finally {
          setLoading(false);
        }
      };
  
      fetchBookedCalls();
    }, [date]);
  
    
    // Update button state at intervals
    // useEffect(() => {
    //   const updateButtonStateForAllCards = () => {
    //     cardsData.forEach(card => {
    //       updateButtonState(card);
    //     });
    //   };
    
    //   if (cardsData.length > 0) {
    //     updateButtonStateForAllCards();
    //   }
    
    //   const interval = setInterval(() => {
    //     if (cardsData.length > 0) {
    //       updateButtonStateForAllCards();
    //     }
    //   }, 60000);
    
    //   return () => clearInterval(interval);
    // }, [cardsData]);
    
    // const updateButtonState = (card) => {
    //   const startHourMinute = card.startTime.split(':');
    //   const startDateComponents = card.date.split('-'); // Assuming date format is DD-MM-YY
    //   const now = new Date();
    
    //   // Construct a new date using the card's date and time
    //   const start = new Date(
    //     2000 + parseInt(startDateComponents[2]), // Year (assuming it's 2000-2099)
    //     parseInt(startDateComponents[1]) - 1, // Month (subtract 1 since months are 0-indexed)
    //     parseInt(startDateComponents[0]), // Day
    //     parseInt(startHourMinute[0]), // hours
    //     parseInt(startHourMinute[1]), // minutes
    //     0, // seconds
    //     0 // milliseconds
    //   ).getTime();
    
    //   console.log("Start ==> ", start, new Date(start).toLocaleString());
    
    //   const currentTime = now.getTime();
    
    //   console.log("Current Time ==> ", currentTime, now.toLocaleString());
    
    //   if (start > currentTime) {
    //     const fiveMinutes = 5 * 60 * 1000;
    //     const tenMinutes = 10 * 60 * 1000;
    
    //     console.log("Time range ==> ", start - fiveMinutes, " to ", start + tenMinutes, 
    //                 new Date(start - fiveMinutes).toLocaleString(), 
    //                 " to ", 
    //                 new Date(start + tenMinutes).toLocaleString());
    
    //     if (currentTime >= start - fiveMinutes && currentTime <= start + tenMinutes) {
    //       setIsActive(true);
    //       console.log("Button is active: true");
    //     } else {
    //       setIsActive(false);
    //       console.log("Button is active: false");
    //     }
    //   } else {
    //     // Show that the call is completed
    //     setIsCompleted(true);
    //     console.log("Call is completed");
    //   }
    // };
    
    
  
    // const fetchEarningDetails = async (callId) => {
    //   try {
    //     console.log(callId);
    //     const response = await axios.post(`${apiUrl}/api/earningCalls/fetch_earning_details`, {
    //       call_Id: callId,
    //     }, {
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${authToken}`,
    //       },
    //     });
  
    //     if (response.data && response.data._id) {
    //       setEarning(response.data);
    //       setEarningId(response.data._id);
    //     } else {
    //       console.log('No Earnings');
    //       setEarning(null);
    //       setEarningId(null);
    //     }
    //   } catch (error) {
    //     console.error('Error fetching earning details:', error);
    //   }
    // };

      // SHOWS THE TIME SLOT STATUS ON THE SELECTED DATE

  //     const showTimeLine =  () =>{
  //       setShowConferenceBookingList(prev => !prev);
  //     }

  //     const handleMeeting = async() =>{
  //       const status = await axios.put(`${apiUrl}/api/earningCalls/change_meeting_status`,{earning_id},{
  //        headers: {
  //          Authorization: `Bearer ${authToken}`,
  //          'Content-Type': 'application/json',
  //        },
  //       })
  //       console.log(status.data)
  //  }
   
   // Function to format date to YYYY-MM-DD

   function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  const handleCall = () => {
    navigate('/availability',{state:{date}});
  };

  // SHARE FUNCTIONALITY


  const handleshare = () => {
    if (navigator.share) {
      const baseUrl = window.location.origin;
      navigator.share({
          title: 'Check out this book list!',
          text: 'Here is an amazing book list you should see:',
          url: `${baseUrl}/book_list/${loggedInUserId}`,
      })
      .then(() => console.log('Shared successfully'))
      .catch((error) => console.error('Error sharing:', error));
  } else {
      alert('Sharing is not supported on this browser.');
  }
  };

  


      if (loading) {
        return  <Loading2/>                     
      }

      

  return (
    
     <div className='w-full flex flex-col justify-center items-center'>
    {/* //   <div className='flex justify-start gap-10 mt-3 ml-5' onClick={showTimeLine}>
    //         <div className='flex justify-center items-center gap-3 cursor-pointer'>
    //             <span className='text-[#585858] text-sm'>Set Availability</span>
    //             <AiOutlinePlus className='text-[#585858] text-sm' />
    //         </div>
    //   </div> */}

      {/* ------------------- BY DEFAULT SHOW THE BOOKED CALL DETAILS OR ELSE SHOW THE TIME SLOTS ON THE SELECTED DATE ------------------------ */}

        {/* {showConferenceBookingList ? (
        <  Calls_Booking_list date={date}/>
      ) : (
        <>
        {(hasError || cardsData.length === 0) && (
            <Empty_data/>
          )}
        <div id="cards_container" className="flex flex-col  items-center px-5 overflow-y-auto h-[550px] w-screen sm:h-[496px] sm:ml-5">
          
           <div className="flex gap-5 flex-col justify-center items-center w-screen" style={{ marginTop: "10px" }}>
             
                {cardsData.map((card, index) => (
                    <div key={index} className="card rounded-[40px] p-3 cursor-pointer  lg:w-[600px] sm:w-[380px] lg:rounded-[20px] flex justify-start gap-2" style={{ boxShadow: "0px 2px 6px 0px #00000040", marginBottom: "20px" , maxWidth:'calc(100vw-400px)' }}
                    //onClick={() => handleCardClick(card)}
                    >
                         <div className="student_status ml-2">{card.userName}</div>
                        <div className="flex justify-between items-center" >
                            <div className='bg-[#D1FFD0] w-1/2 px-2 py-2 text-center rounded-[20px] font-montserrat '>{`${card.startTime} - ${card.endTime}`}</div>  
                            <a
                      href={isActive ? URL : ""}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`inline-flex items-center justify-center w-64 px-0 py-2.5 bg-gradient-to-b from-orange-600 to-yellow-400 text-white text-center rounded-full ml-[13%] gap-3.5 lg:ml-52 ${
                        !isActive ? 'opacity-50 pointer-events-none' : ''
                      }`}
                      onClick={earning_id && isActive ? handleMeeting : undefined}
                    >
                      <i className="fas fa-video"></i>
                      Start Meeting
                    </a>
                        </div>
                       
                        <a href={card.url} target="_blank" rel="noopener noreferrer" className="link ml-2">{card.url}</a>
                    </div>
                ))}
            </div> 
            
         </div> 
         </>
             
      )} */} 
      
      <div className='hidden lg:block '>  
        <div className='flex justify-center items-center gap-6 mt-0  '>
            <div className='border-2 border-orange-600 rounded-3xl py-1 px-6 bg-transparent text-orange-500 font-semibold  hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 flex justify-center gap-2 items-center shadow-lg  cursor-pointer'  onClick={handleCall}>
              Set availability <AiOutlinePlus style={{fontSize:'1rem'}}/>
            </div>
            <div className='border-2 border-orange-600 rounded-3xl py-1 px-6 bg-transparent text-orange-500 font-semibold  hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 cursor-pointer  flex justify-center gap-2 items-center shadow-lg  ' onClick={handleshare}>
              Share availability
            <IoIosShareAlt style={{ fontSize: '1.5rem' }} />
          </div>
        </div>
      </div>
      <Calls_Booking_list date={date}/>
      
        
    </div>
    
    
  )
}

export default Main_Call_Page