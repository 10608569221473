import React from 'react'
import NavHome from '../../components/nav/navHome';
import Footer from '../../components/nav/footer';
import Header from '../../components/headerBackground'
import Cookies from "js-cookie";
import NavBar from '../../components/nav/navBar';
import payment from '../../assets/img/price.jpg';

const PaymentPolicy = () => {
  const isAuthenticated = Cookies.get("authToken") !== undefined;
  // const url = process.env.REACT_APP_API_URL;
  return (
    <div className=''>
      {isAuthenticated ? <NavBar /> : <NavHome />}
      <section className="mb-0 sm:mb-0 font-primary ">
        <Header />
        <div className='sm:mt-20 lg:mt-28 mt-[160px] mx-2 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 flex flex-col justify-center gap-4'>
          <h2 className='font-semibold sm:text-2xl lg:text-3xl text-xl text-center text-[#585858]'>PAYMENT POLICY</h2>
          <div className='sm:flex flex-cols gap-6 items-start justify-center mt-10'>
            <div className='sm:w-[40%] w-[100%] flex items-center justify-center'>
              <img src={payment} alt="refund" className='sm:w-[400px] sm:h-[400px] lg:w-[450px] lg:h-[450px] h-[250px] w-[250px]' />
            </div>

            <div className='sm:w-[60%] text-base sm:text-base lg:text-lg flex flex-col gap-6 text-left mt-2 mb-6 mx-2'>
              <p>There are two types of Subscriptions available for Teachers and Subject
                Matter Experts</p>
              <ol type='1' style={{ listStyleType: 'lower-roman', paddingLeft: '20px' }} className='font-medium'>
                <li>
                  <h3 className='font-medium'>Premium Subscription</h3>
                </li>
                <li>
                  <h3 className='font-medium'>Community Edition</h3>
                </li>
              </ol>
              <p>All the Teachers and Subject Matter Experts can view their detailed
                earnings on their profile page. Depending on the subscription type
                (Premium or Community user) there can be 3 types of earnings –</p>

              <ol type='1' style={{ listStyleType: 'decimal', paddingLeft: '20px' }} className='font-medium flex flex-col gap-4'>
                <li>
                  <h3 className='font-medium'>Earning from Calls:</h3>
                  <p className='font-normal'>It is available to both Premium and Community
                    Teachers or Subject Matter Experts. Teachers can share their
                    availability for Calls to solve Students problem and set their own
                    prices. Students/Parents might have to pay to book a call. CGB ORG
                    charges 5% service charge on the calls booked.</p>
                </li>
                <li>
                  <h3 className='font-medium'>Earning from Conference/Class:</h3>
                  <p className='font-normal'>It is available to only Premium
                    Teachers or Subject Matter Experts. Teachers or Subject Matter
                    Experts can schedule online classes or conferences and set their
                    price for a particular class or conference. Students/Parents might
                    have to pay for booking a Class or Conference. CGB ORG charges
                    5% service charge on the conferences/classes booked.</p>
                </li>
                <li>
                  <h3 className='font-medium'>Earning from Referrals:</h3>
                  <p className='font-normal'> It is available to only Premium Teachers or
                    Subject Matter Experts. Teachers or Subject Matter Experts may
                    refer other individuals to join as Teacher or Subject Matter Expert. If
                    anyone joins as Teacher or Subject Matter Expert and chooses
                    Premium Subscription using existing Teacher’s or Subject matter
                    Expert’s referral code, then the existing Teacher or Subject Matter
                    Expert earn a referral bonus.</p>
                </li>
              </ol>

              <p>All the earnings of Teachers and Subject Matter Experts from Calls and
                Conferences for a period of a month is processed on the 15th of the
                following month for reimbursement. Reimbursement time is dependent on
                Banks and other payment mechanisms. CareerGuidanceBuddy (CGB ORG)
                do not have any control over the 3rd parties involved.
                CareerGuidanceBuddy (CGB ORG) will share the payment details with the
                respective Members over email and on the site or application.</p>
              <p>CareerGuidanceBuddy (CGB ORG) reserves the right to change the prices
                and Service Charge % on a time to time basis without any prior intimation
                to Teachers/Subject Matter Experts or Students/Parents.</p>
              <p>All Teacher’s and Subject Matter Experts are required by law to deposit
                their taxes (including but not limited to personal or corporate income
                taxes) to governmental agencies, and other similar municipal, state,
                federal and national level.</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default PaymentPolicy