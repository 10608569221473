import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './calender.css'
import logo from '../../assets/img/logo.svg'
import bg from '../../assets/img/background-2.png'
import { IoIosArrowDown, IoIosShareAlt } from "react-icons/io";
import { BsChevronLeft } from "react-icons/bs";
import Cookies from "js-cookie";
import axios from "axios";
import { getParamByISO } from 'iso-country-currency'
import { useLocation } from 'react-router-dom';
import MainMenu from '../MenuBottom/MainMenu';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';
import { addHours, addMinutes, format, parse } from 'date-fns';
import NavBar from '../nav/navBar';
import {FaCheckCircle } from 'react-icons/fa';
import Loading2 from '../Loading2';



const Event = ({ setMessage, setShowMessage }) => {
  const location = useLocation();
  // console.log(location); 
  const { timeSlot } = location.state;
  const { date } = location.state;
  
  // console.log(timeSlot.displayStartTime)
  
  const [selectedPrice, setSelectedPrice] = useState('---');
  const [selectedDuration, setSelectDuration] = useState('1 Hr');
  const [isOpenRemainder, setIsOpenRemainder] = useState(false);
  const [selectedReminder, setSelectedReminder] = useState('Set Remainder');
  const [isOpenDuration, setIsOpenDuration] = useState(false)
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(date);
  //console.log("Selected Date===>",date)
  const [selectedTime, setSelectedTime] = useState(String(timeSlot.displayStartTime));
  const [endTime, setEndTime] = useState(calculateEndTime(selectedTime, selectedDuration));
  const authToken = Cookies.get("authToken"); 
  const [currency, setCurrency] = useState('$');
  const [currencyCode, setCurrencyCode] = useState('USD');
  const[paypal_fee,setPaypal_fee]=useState(0.30);
  const [isOpenCurrency, setIsOpenCurrency] = useState(false);
  const [User,setUser]=useState('');
  const [error,setError]=useState(false)
  const [loading, setLoading] = useState(false);


  const [title, setTitle] = useState('');

  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');
  const [isIndia, setIsIndia] = useState(false);
  const [fetchcountry,setFetchcountry]=useState(true);

  const loggedInUserId = Cookies.get('loggedInUserId');
  const apiUrl = process.env.REACT_APP_API_URL;
  
  
  const fetchUserDetails = async (setCurrency, setCurrencyCode, setIsIndia,setFetchcountry,setError,setUser,User) => {
    try {
      // console.log(loggedInUserId)
      const response = await axios.get(`${apiUrl}/api/auth/user_details/${loggedInUserId}`);
      const user = response.data.user_Details;
      // console.log(response.data.user)
      setUser(response.data.user)
      
      // console.log(user.country)
      if (!user.country) {
        console.error('No country name found in user details');
        return; // Exit early if no country name is available
      }
  
      if (user.country === 'India') {
        setCurrency('₹');
        setCurrencyCode('INR');
        setPaypal_fee(0)
        setIsIndia(true);
       
      } else {
        setIsIndia(false);
      }
      setFetchcountry(false)
      console.log(User)
      console.log("user referral",User.referralCode)
      const details = await SubscriptionDetails();
      console.log("Subscription Details ==>",details)
      if(details  === null){
        //console.log("Not found subscription details")
        setLoading(false)
        setError(true)
      }else{
        if(response.data.user.referralCode === null || !details.isActive){
          //console.log("premium status",details.isActive)
          setLoading(false)
          setError(true)
          }
          else{
        //console.log("found subscription details")
        setLoading(false)}
        
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    // const fetchCurrency = async (countryName) => {
    //   try {
    //     console.log("Inside the fetch currency ===>", countryName);
    //     const response = await fetch(`https://restcountries.com/v3.1/name/${countryName}`);
    //     const data = await response.json();
    //     console.log("API Response:", data);
  
    //     if (data.length > 0) {
    //       // Filter to get the exact match
    //       const country = data.find(
    //         item => item.name.common.toLowerCase() === countryName.toLowerCase()
    //       );
    //       if (country) {
    //         const currencyCode = Object.keys(country.currencies)[0]; // Get the first currency code
    //         setCountryCode(currencyCode);
    //         const symbol = country.currencies[currencyCode].symbol;
    //         setCurrency(symbol);
    //         console.log("Currency Symbol:", symbol);
    //       } else {
    //         setCurrency('Currency symbol not found');
    //       }
    //     } else {
    //       setCurrency('Currency symbol not found');
    //     }
    //   } catch (error) {
    //     console.error('Error fetching country data:', error);
    //     setCurrency('Error fetching currency information');
    //   }
    // };

    
    

    fetchUserDetails(setCurrency, setCurrencyCode, setIsIndia,setFetchcountry,setError,setUser,User);
  }, [loggedInUserId]);


  const SubscriptionDetails = async () => {
    try {

      const response = await axios.get(`${url}/api/subscription/getusersubscriptiondetails`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      console.log(response.data.User_Subscription_Details)
      const Subscription = response.data.User_Subscription_Details;
      //setEndDate(convertDate(Subscription.endDate))
      return Subscription;

    } catch (e) {
      console.log(e);
    }
  }


  //CONFERENCE DATA OPERATIONS
  // console.log("User",User)
  const handleInputChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleUrlChange= (e)=>{
    setUrl(e.target.value);
  }

  const handleDoneClick = async () => {
    try {
      setLoading(true);
  
      
      const formattedDate = formatDateToYYYYMMDD(selectedDate);
      function formatDateToYYYYMMDD(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
  
      
      const slotResponse = await axios.post(
        `${apiUrl}/api/calender/create_slot`,
        {
          date: formattedDate,
          start_time: selectedTime,
          end_time: endTime,
          status: 'booked',
          type: 'Conference'
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
        }
      );
  
      // console.log('Time slot created:', slotResponse.data.newTimeSlot);
        //console.log(selectedDate,date)
      //console.log("paypal_fee",paypal_fee)
      const response = await axios.post(
        `${apiUrl}/api/conference/createConference`,
        {
          Title: title,
          Date: selectedDate,
          Duration: selectedDuration,
          Conference_Date: formattedDate,
          Start_Time: selectedTime,
          End_Time: endTime,
          Remainder: selectedReminder,
          Price: {
            currency: currency,
            amount: selectedPrice,
            currencyCode: currencyCode,
            paypal_fee:paypal_fee
          },
          Description: description,
          URL: url,
          SlotId: slotResponse.data.newTimeSlot._id 
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
        }
      );
  
      // console.log('Conference created:', response.data);
  
      if (response.data.access_calendar) {
        const googleCalendarAccessToken = localStorage.getItem("googleAccessToken");
        // console.log(googleCalendarAccessToken);
        // console.log(selectedDate, selectedTime, endTime);
  
        if (googleCalendarAccessToken) {
          const year = selectedDate.getFullYear();
          const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
          const day = String(selectedDate.getDate()).padStart(2, '0');
  
          const isoDateString = `${year}-${month}-${day}`;
          const event = {
            summary: title,
            description: description,
            start: {
              dateTime: `${isoDateString}T${selectedTime}:00`,
              timeZone: 'Asia/Kolkata',
            },
            end: {
              dateTime: `${isoDateString}T${endTime}:00`,
              timeZone: 'Asia/Kolkata',
            }
          };
  
          const eventResponse = await axios.post(
            'https://www.googleapis.com/calendar/v3/calendars/primary/events',
            event,
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${googleCalendarAccessToken}`,
              },
            }
          );
  
          // console.log('Event created on Google Calendar:', eventResponse.data);
        }
      }
  
      navigate('/calender_page');
      setShowMessage(true);
      setMessage("Conference Successfully Created");
    } catch (error) {
      setShowMessage(true);
      if (error.response) {
        setMessage(error.response.data.error);
        console.error('Response data:', error.response.data);
      } else if (error.request) {
        setMessage("No response received from server");
        console.error('No response received:', error.request);
      } else {
        setMessage("Error setting up the request");
        console.error('Error setting up the request:', error.message);
      }
    } finally {
      setLoading(false);
    }
  };
  // CALENDER SHOULD STARTS FORM TOMORROW
  const tomorrow = addDays(new Date(), 1);
  



  const handlePriceChange = (price) => {
    setSelectedPrice(price);
  };

  const toggleDurationDropdown = () => {
    setIsOpenDuration(!isOpenDuration)
  }


  const handleBack = () => {
    navigate('/calender_page')
  }

  // const handleSubscription = () =>{
  //   navigate('/subscription')
  // }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  

  // BASED ON DURATION  AND START TIME CHANGE THE END TIME

  const handleDurationSelection = (duration) => {
    setSelectDuration(duration);
    setIsOpenDuration(false);
    setEndTime(calculateEndTime(selectedTime, duration));
  }

  // DURATION SELECTION

  function calculateEndTime(startTime, duration) {
    const parsedStartTime = parse(startTime, 'HH:mm', new Date());

    let endTime = new Date(parsedStartTime);

    switch (duration) {
      case '1 Hr':
        endTime = addHours(endTime, 1);
        break;
      case '30 Min':
        endTime = addMinutes(endTime, 30);
        break;
      case '1:30 Min':
        endTime = addMinutes(endTime, 90);
        break;
      case '2 Hr':
        endTime = addHours(endTime, 2); // For example, 6-7 hours added
        break;
      default:
        break;
    }

    return format(endTime, 'HH:mm');
  };


  const currencyOptions = [
   
    { symbol: 'A$', code: 'AUD' , paypal_fee:0.30 }, // Australian Dollar
    { symbol: 'C$', code: 'CAD',paypal_fee:0.30 }, // Canadian Dollar
    { symbol: '€', code: 'EUR',paypal_fee:0.35 }, // European Euro
    { symbol: '£', code: 'GBP',paypal_fee:0.20 }, // Pound Sterling
    { symbol: '¥', code: 'JPY',paypal_fee:40.00  }, // Japanese Yen
    { symbol: '₽', code: 'RUB',paypal_fee:10.00 },  // Russian ruble
    { symbol: 'S$', code: 'SGD',paypal_fee:0.50  }, // Singapore Dollar
    { symbol: '$', code: 'USD',paypal_fee:0.30 }, // United States Dollar
   
];



  const handleCurrency = (symbol, code,paypal_fee) => {
    setCurrency(symbol);
    setCurrencyCode(code);
    setIsOpenCurrency(false);
    console.log("selected paypal_fee",paypal_fee)
    setPaypal_fee(paypal_fee)
  };


  const toggleDropdown = () => {
    setIsOpenCurrency(!isOpenCurrency);
  };
  

  

  
  
  // BASED ON USER LOCATION GET THE CURRENCY 
 
// useEffect(() => {

//   const getLocationAndFetchCurrency = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(async (position) => {
//         const { latitude, longitude } = position.coords;
//         try {
//           const response = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`);
//           const data = await response.json();
//           setCountry(data.countryCode);

//           const currencyCode = await getParamByISO(data.countryCode, 'symbol');
//           setCurrency(currencyCode);
          
//         } catch (error) {
//           console.error('Error:', error);
//         }
//       }, (err) => {
//         console.error('Geolocation error:', err);
//       });
//     } else {
//       console.log('Geolocation is not supported by this browser.');
//     }
//   }
//   getLocationAndFetchCurrency();
// }, []); // Depend on selectedDate to re-run if it changes

if(fetchcountry){
  return <Loading2/>
}

// if(error){
//   return(
//     <div className="flex flex-col justify-center items-center min-h-screen gap-3">
//       <div className=' text-lg'>Please Subscribe to create Conference</div>
//       <div className=' text-lg'>Click Upgrade to Premium</div>
//       <div className='flex justify-center items-center gap-4'>
//         <div onClick={handleBack} className='bg-slate-300 rounded-full py-2 px-6 cursor-pointer'>Back</div>
//         <div className="flex items-center gap-2 justify-center px-3 sm:px-5 py-1 sm:py-2 rounded-full bg-gradient text-white cursor-pointer" onClick={handleSubscription}>
//           <FaCheckCircle className="text-white" />
//           <span className="text-md sm:text-lg">Upgrade to premium</span>
//         </div>
//       </div>
      
//     </div>
//   )
// }



  return (
    <section class="mb-24 sm:mb-0">
      <NavBar />
      <div class='sm:mt-16 lg:mt-20 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5'>
          <div class="sm:hidden bg-contain bg-cover bg-no-repeat w-full h-[280px] sm:mx-auto sm:w-full"
            style={{backgroundImage: `url("${bg}")`}}>
            <div class="flex justify-center items-center pt-[52px]">
              <img class="w-[189px]" src={logo} alt="logo" />
            </div>
          </div>
          
         
  <div className="lg:grid grid-cols-3 gap-4 sm:min-h-[85vh] sm:py-[55px] justify-center px-0">
      
        <div>
          <div className="flex items-center justify-between w-full mb-8">
            <div
              style={{ color: "#AFAFAF" }}
              className="flex items-center gap-4 cursor-pointer"
              onClick={handleBack}
            >
              <BsChevronLeft />
              <div style={{ fontWeight: 600 }}>Back</div>
            </div>
            <div className=" w-32 text-center  text-orange-400 font-bold">
              New Event
            </div>
          </div>

          <div
            className=" w-auto lg:w-11/12 h-12 rounded-full ml-2"
            style={{
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}
          >
            <input
              type="text"
              placeholder="Title"
              className=" w-82 ml-8 mt-3 outline-none font-semibold text-lg text-black"
              onChange={handleInputChange}
            />
          </div>

          <div
            className="w-auto lg:w-11/12 h-96 mb-5 mt-4 rounded-3xl"
            style={{
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}
          >
            <div className="px-5 py-4 text-slate-500 font-semibold">
              Description
            </div>
            <hr className="w-full text-slate-600 h-1" />
            <textarea
              placeholder="Conference Details . . . . . . "
              className="w-full px-5 py-2 rounded-3xl outline-none overflow-y-auto resize-none h-80"
              onChange={handleDescptionChange}
            ></textarea>
          </div>
        </div>

        <div className="col-span-2">
          <div className="flex items-center sm:justify-center gap-5 mt-7">
            <div
              className="w-60 h-12 rounded-full ml-2 sm:mt-2 px-4 py-3"
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              }}
            >
              Duration
            </div>
            <div>
              <div className="relative inline-block">
                <div
                  className="bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 text-center py-3 rounded-full text-white w-32 h-12 mt-3 flex justify-center items-center gap-3 cursor-pointer"
                  onClick={toggleDurationDropdown}
                >
                  {selectedDuration}{" "}
                  <IoIosArrowDown
                    className={`transform ${
                      isOpenDuration ? "rotate-180" : ""
                    } transition-transform duration-200`}
                  />
                </div>
                {isOpenDuration && (
                  <div className="absolute mt-1 w-32 bg-white border rounded-lg shadow-lg z-10">
                    <div
                      className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleDurationSelection("30 Min")}
                    >
                      30 Min
                    </div>
                    <div
                      className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleDurationSelection("1 Hr")}
                    >
                      1 Hr
                    </div>
                    <div
                      className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleDurationSelection("1:30 Min")}
                    >
                      1:30 Min
                    </div>
                    <div
                      className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleDurationSelection("2 Hr")}
                    >
                      2 Hr
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-around gap-5 ml-2 mt-5">
            <div className="font-bold">Start</div>
            <div className="font-bold">End</div>
          </div>
          <div className="flex justify-around items-center mt-3 sm:-ml-8 ">
            <div className="flex lg:gap-4 gap-1">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                className="bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 py-2 px-2 rounded-full text-white text-center text-md w-32 h-12 custom-date-picker"
                dateFormat="d MMM yyyy"
                minDate={tomorrow}
                maxDate={addDays(new Date(), 15)}
                withPortal
              />
              <div className="custom-time-picker">
                <input
                  type="text"
                  value={selectedTime}
                  readOnly
                  className="bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 text-center rounded-full text-white text-md lg:w-32 w-20 h-12 py-2  cursor-pointer"
                />
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <div className="bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 py-2 px-5 text-center rounded-full text-white text-md lg:w-32 sm-w-16 h-12 lg:ml-10 ml-[-3] flex justify-center items-center">
                {endTime}
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row items-center justify-center gap-10 w-full lg:mt-10 mt-0">
            <div className="flex justify-center items-center"></div>
            <div className="flex justify-center items-center gap-2 mb-4">
              <div
                className="lg:w-44 w-32 text-center h-12 rounded-full  lg:ml-2 sm:ml-5  lg:mt-5 mt-0 px-4 py-3"
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                Set Price
              </div>
              <div>
                <div className="flex items-center lg:mt-5 mt-0">
                  <div className="relative inline-block mr-2">
                    <div
                      className={`bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 text-center py-3 rounded-full text-white lg:w-32 w-24  h-12 flex justify-center items-center lg:gap-10 gap-5 cursor-pointer ${
                        isIndia ? "cursor-not-allowed" : ""
                      }`}
                      onClick={toggleDropdown}
                      disabled={isIndia}
                    >
                      {currency}{" "}
                      {!isIndia && (
                        <IoIosArrowDown
                          className={`transform ${
                            isOpenCurrency ? "rotate-180" : ""
                          } transition-transform duration-200`}
                        />
                      )}
                    </div>
                    {isOpenCurrency && !isIndia && (
                      <div className="absolute right-0 mt-1 w-32 bg-white border rounded-lg shadow-lg">
                        {currencyOptions.map((cur) => (
                          <div
                            key={cur.code}
                            className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleCurrency(cur.symbol, cur.code,cur.paypal_fee)}
                          >
                            {cur.symbol} {cur.code}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="inline-block">
                    <div
                      className="bg-white text-center py-3 rounded-full text-gray-800 lg:w-32 sm:w-20 h-12 flex justify-center items-center"
                      style={{
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                      }}
                    >
                      <span className="text-gray-800 px-2">{currency}</span>
                      <input
                        type="number"
                        className="bg-transparent text-gray-800 text-center w-16 placeholder-gray-500"
                        placeholder="Amount"
                        value={selectedPrice.replace(/[^\d.-]/g, "")}
                        onChange={(e) => handlePriceChange(e.target.value)}
                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-full h-16 mb-5 sm:mt-4 sm:rounded-2xl rounded-3xl lg:w-[750px] lg:mt-8 flex"
            style={{
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}
          >
            <div className="px-5 py-4 text-slate-500 font-semibold">
              Meeting Link
            </div>
            <div className="h-8 w-[1px] bg-slate-400 mt-4"></div>
            <textarea
              placeholder="Link...."
              className="w-9/12 px-5 py-4 outline-none overflow-y-auto resize-none"
              onChange={handleUrlChange}
            ></textarea>
          </div>
          <div className="flex lg:justify-center justify-around gap-10 mb-5 lg:mt-8 ">
            <div
              className="border-2 border-orange-600 rounded-3xl py-2 px-12 bg-transparent text-orange-500 font-semibold hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 cursor-pointer flex justify-center gap-2 items-center shadow-lg"
              onClick={handleBack}
            >
              <span>Cancel</span>
            </div>
            <div
              className="border-2 border-orange-600 rounded-3xl py-2 px-12 bg-transparent text-orange-500 font-semibold hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 cursor-pointer flex justify-center gap-2 items-center shadow-lg"
              onClick={handleDoneClick}
            >
              <span>{loading ?
                              <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-white" role="status">
                              </div> : 'Done'}</span>
            </div>
          </div>
        </div>
     
  </div>


{/* {error &&(
  <div className="flex flex-col justify-center items-center min-h-screen">
  <div>Please Subscribe to create Conference</div>
  
  <div className="flex items-center gap-2 justify-center px-3 sm:px-5 py-1 sm:py-2 rounded-full bg-gradient text-white cursor-pointer">
    <FaCheckCircle className="text-white" />
    <span className="text-md sm:text-lg">Upgrade to premium</span>
  </div>
</div>)} */}

        {/* --------------------------------- BOTTOM MENU ----------------------------------- */}

        <div class="flex justify-center mt-10 sm:block">
          <MainMenu/>
        </div>
      </div>
      
    </section>

  );
};

export default Event;