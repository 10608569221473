import React, { useState, useEffect } from 'react';
import '.././calender.css'
import logo from '../../../assets/img/logo.svg'
import bg from '../../../assets/img/background-2.png'
import MainMenu from '../../MenuBottom/MainMenu';
import Cookies from "js-cookie";
import Calendar from 'react-calendar';
import { useParams } from 'react-router-dom';
import { BsChevronRight } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import NavStudent from '../../nav/navStudent';
import moment from 'moment-timezone';
import Loading2 from '../../Loading2';
import Empty_data from '../../Empty_data';

import NavBar from '../../nav/navBar';
const Student_Conference_Page = () => {
    const { id } = useParams();
    const [date, setDate] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState('');
    const [currentShortDate, setCurrentShortDate] = useState('');
    const [calendarDays, setCalendarDays] = useState([]);
    const [currentDayIndex, setCurrentDayIndex] = useState(null);
    const [showcalender,SetShowCalender] = useState(false)
    const user_type = Cookies.get('user_type')
    const[hasError,setHasError]=useState(true)
    const [loading,setLoading]=useState(false)
    // console.log(user_type)
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const fifteenDaysLater = new Date();
    fifteenDaysLater.setDate(fifteenDaysLater.getDate() + 15);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [user_data, setUser_data] = useState('');
    const user_loggedInId = Cookies.get('loggedInUserId')
    const [user_time,setUser_time]=useState('')
    const [teacher_country,set_Teacher_country]=useState('')
       

    // FUNCTION TO PRINTS THE WEEK OF THE MONTH
    
    useEffect(() => {
      
      displayCurrentWeek();
     

    }, []);
    //console.log('Active Button:', activeButton);
    const displayCurrentWeek = () => {
      const currentDate = new Date();
      const currentMonthFullName = getCurrentMonthName(currentDate.getMonth())[0];
      const currentMonthShortName = getCurrentMonthName(currentDate.getMonth())[1];
      const currentDay = currentDate.getDate();
      const currentDayOfWeek = getDayOfWeekName(currentDate.getDay(), false); // Full day name
      const dayIndex = currentDate.getDay();
  
      setCurrentMonth(currentMonthFullName);
      setCurrentShortDate(`${currentMonthShortName} ${currentDay} - ${currentDayOfWeek}`);
      setCurrentDayIndex(dayIndex);
  
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
  
      const days = [];
      for (let i = 0; i < 7; i++) {
        const day = new Date(startOfWeek);
        day.setDate(startOfWeek.getDate() + i);
        days.push(day);
      }
  
      setCalendarDays(days);
    };
  
    const getCurrentMonthName = (monthIndex) => {
      const months = [
        ["January", "Jan"], ["February", "Feb"], ["March", "Mar"], ["April", "Apr"],
        ["May", "May"], ["June", "Jun"], ["July", "Jul"], ["August", "Aug"],
        ["September", "Sep"], ["October", "Oct"], ["November", "Nov"], ["December", "Dec"]
      ];
      return months[monthIndex];
    };
  
    const getDayOfWeekName = (dayIndex, abbreviated = true) => {
      const abbreviatedDaysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      
      if (abbreviated) {
        return abbreviatedDaysOfWeek[dayIndex];
      } else {
        return daysOfWeek[dayIndex];
      }
    };

    const showCalender = () =>{
      SetShowCalender(!showcalender)
    }

    const [cardsData, setCardsData] = useState([]);
    
    const authToken = Cookies.get("authToken");
    const navigate = useNavigate();
    useEffect(() => {
      fetch_user_Details();
      fetch_teacher_Details();
      FetchConferenceDetails();
     
       }, [date,user_time,id]);



       const fetch_user_Details = async () => {
        try {

            const user_details = await axios.get(`${apiUrl}/api/auth/user_details/${user_loggedInId}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            })
            const user = user_details.data;
            // console.log(user);
            // console.log("Name", user.user.fullName)
            // console.log("Eamil", user.user.email)
            setUser_data(user);
            setUser_time(user.user_Details.time_zone);
            // console.log("Time Zone",user_time)


        } catch (error) {
            console.error('Server Error:', error.response.data);
        }
    }

    const fetch_teacher_Details = async () =>{
      try {

        const user_details = await axios.get(`${apiUrl}/api/auth/user_details/${id}`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
        })
        const user = user_details.data;
        set_Teacher_country(user.user_Details.country)
        //console.log(user.user_Details.country)
       //console.log(teacher_country)


    } catch (error) {
        console.error('Server Error:', error.response.data);
    }
    }

    
    const FetchConferenceDetails = async () => {
      try {
        const response = await axios.post(
          `${apiUrl}/api/conference/get_conferences`,
          { id: id },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
    
        const data = response.data;
        const teacher_time_zone = data.userTimezone;
        const conferences = data.conferencesWithTimezone;
    
        
        const selectedDate = moment(date).format('YYYY-MM-DD');
    
        
        const filteredData = conferences.filter(conference => {
          
          const conferenceStartDateTimeInTeacherTimeZone = moment.tz(
            `${conference.Conference_Date} ${conference.Start_Time}`,
            'YYYY-MM-DD HH:mm',
            teacher_time_zone
          );
    
          
          const conferenceStartInStudentTimeZone = conferenceStartDateTimeInTeacherTimeZone
            .clone()
            .tz(user_time);
    
          
          const conferenceStartDate = conferenceStartInStudentTimeZone.format('YYYY-MM-DD');
    
          
          const isConferenceOnSelectedDate = selectedDate === conferenceStartDate;
    
          // console.log("Selected Date:", selectedDate);
          // console.log("Conference Start Date:", conferenceStartDate);
          // console.log("Is Conference On Selected Date:", isConferenceOnSelectedDate);
    
          return isConferenceOnSelectedDate;
        });
    
        const convertedData = filteredData.map(conference => {
          const { Start_Time, End_Time, Conference_Date, ...rest } = conference;
        
          
          const startDateTimeInUserTimeZone = moment.tz(`${Conference_Date} ${Start_Time}`, 'YYYY-MM-DD HH:mm', teacher_time_zone)
            .clone() 
            .tz(user_time); 
        
          const endDateTimeInUserTimeZone = End_Time ? moment.tz(`${Conference_Date} ${End_Time}`, 'YYYY-MM-DD HH:mm', teacher_time_zone)
            .clone() 
            .tz(user_time) : null; 
        
          
          const adjustedConferenceDate = startDateTimeInUserTimeZone.format('YYYY-MM-DD');
        
          
          const convertedStartTime = startDateTimeInUserTimeZone.format('HH:mm');
          const convertedEndTime = endDateTimeInUserTimeZone ? endDateTimeInUserTimeZone.format('HH:mm') : '';
        
          return {
            ...rest,
            Conference_Date: adjustedConferenceDate,
            Start_Time: convertedStartTime,
            End_Time: convertedEndTime
          };
        });
        
    
        console.log("Converted Data:", convertedData);
        setCardsData(convertedData);
        setLoading(false);
        setHasError(convertedData.length === 0);
    
      } catch (error) {
        console.error('Error fetching conference details:', error);
      }
    };
    
      
      const handleCardClick = (card) => {
        // Navigate to Meeting_Des and pass selected card's details in state
        
          navigate('/conferece_details', { state: { card,teacher_country} });
        };

        const today = new Date();
    
        const handleDateChange = (newDate) => {
          console.log(newDate);
          setDate(newDate);
          SetShowCalender(false); 
        };
        
        const formatDate = (date) => {
          const options = { month: 'long', day: '2-digit', year: 'numeric' };
          return date.toLocaleDateString('en-US', options).replace(',', ' -');
        };
        const handleDateClick = (day) => {
          console.log(day)
          const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      // Check if the selected day is not a past date
      if (day >= currentDate) {
        setDate(day);
        displayCurrentWeek(day);
      }
        };
    
  return (
    <section class="mb-20 sm:mb-0">
      <NavBar/>
      <div class='sm:mt-20 lg:mt-24 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 overflow-y-auto'>
        <div class="lg:grid grid-cols-2 gap-4 sm:min-h-[85vh] sm:py-[55px] justify-center overflow-y-auto">
          <div>
          {/* LOGO WITH DESIGN */}
            <div
              className=" sm:hidden bg-contain bg-no-repeat w-full h-[280px] sm:mx-auto sm:w-full sm:max-w-md max-[420px]:bg-cover flex justify-center"
              style={{backgroundImage: `url("${bg}")`}}
              >
              <div className='mt-14'>
                <img src={logo} alt="logo" />
              </div>
            </div>
          {/* CURRENT MONTH WITH NOTIFICATON AND SETTINGS */}
          
              <div className='flex justify-center gap-20  lg:mt-2 mb-8 items-center'>
              <h1 class="text-[#AFAFAF] text-lg font-bold">
                    {date && date.toDateString() === today.toDateString() ? 'Today' : formatDate(date)}
                    </h1>
                <h1 id="month-header" className="bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] text-white px-7 py-2 rounded-full font-medium cursor-pointer" onClick={showCalender}>{currentMonth}</h1>
              </div>
        
        {/* SHOWING WEEK CALENDER */}

        <div class="flex justify-center items-center " >
            <div id="calendar" className="ml-[-16px] sm:ml-0 px-[25px] py-[15px] shadow-md lg:rounded-full sm:rounded-[40px]  text-lg font-bold  w-fit text-center" >
            <table>
                        <thead>
                          <tr>
                            {calendarDays.map((day, index) => {
                              const currentDate = new Date();
                              const isPastDate = day < new Date(currentDate.setHours(0, 0, 0, 0));
                              return (
                                <th
                                  key={index}
                                  className={`${index === currentDayIndex ? 'current-day cursor-pointer' : 'cursor-pointer'} ${
                                    isPastDate ? 'past-date' : ''
                                  }`}
                                  onClick={() => !isPastDate && handleDateClick(day)}
                                  style={{ cursor: isPastDate ? 'not-allowed' : 'pointer' }}
                                >
                                  {getDayOfWeekName(index)}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {calendarDays.map((day, index) => {
                              const currentDate = new Date();
                              const isPastDate = day < new Date(currentDate.setHours(0, 0, 0, 0));
                              return (
                                <td
                                  key={index}
                                  className={`${day.toDateString() === today.toDateString() ? 'current-date cursor-pointer' : 'cursor-pointer'} ${
                                    isPastDate ? 'past-date' : ''
                                  }`}
                                  onClick={() => !isPastDate && handleDateClick(day)}
                                  style={{ cursor: isPastDate ? 'not-allowed' : 'pointer' }}
                                >
                                  {day.getDate()}
                                </td>
                              );
                            })}
                          </tr>
                        </tbody>
                      </table>
            </div>
        </div>

        {showcalender ? (
          <div className='calendar-container flex justify-center'>
          <Calendar
            onChange={handleDateChange}
            value={date}
            minDate={tomorrow} // Set minimum selectable date to tomorrow
        maxDate={fifteenDaysLater} // Set maximum selectable date to 15 days later
        showNavigation={false}
        />
        </div>

        ) : null}
      </div>
          
            
      {loading ? (
  <Loading2 /> // Show loading component when loading is true
) : hasError || cardsData.length === 0 ? (
  <Empty_data /> // Show empty data component when there is an error or no data
) : (
  <div className="">
    <div className="flex flex-col gap-2 font-primary sm:rounded-[50px] mt-14 sm:mt-0">
      <div className="max-w-[625px] sm:max-w-none mx-auto sm:mx-0 w-full">
        <div id="cards_container" className="flex flex-col w-full items-center px-5 overflow-y-auto h-[450px] sm:h-[496px]">
          <div id="cards" className="flex gap-5 flex-col justify-center items-center w-full">
            {cardsData.map((card, index) => (
              <div
                key={index}
                className="card rounded-[40px] p-5 cursor-pointer w-full lg:rounded-[20px]"
                style={{ boxShadow: "0px 2px 6px 0px #00000040", marginBottom: "20px" }}
                onClick={() => handleCardClick(card)}
              >
                <div className="lg:hidden">
                  <div className="flex justify-between items-center">
                    <div className="bg-[#D1FFD0] w-1/2 px-2 py-2 text-center rounded-[20px] font-montserrat">
                      {`${card.Start_Time} - ${card.End_Time}`}
                    </div>
                    <BsChevronRight />
                  </div>
                  <div className="student_status">{card.Title}</div>
                  <div>
                    <a
                      href={card.link}
                      className="text-[#AFAFAF] font-montserrat font-normal w-[10px] whitespace-nowrap overflow-hidden overflow-ellipsis text-sm"
                    >
                      {card.URL}
                    </a>
                  </div>
                </div>

                <div className="hidden md:hidden lg:block">
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col">
                      <div className="flex items-center gap-3">
                        <div className="student_status">{card.Title}</div>
                        <BsChevronRight />
                      </div>
                      <div>
                        <a
                          href={card.link}
                          className="text-[#AFAFAF] font-montserrat font-normal w-[10px] whitespace-nowrap overflow-hidden overflow-ellipsis text-sm"
                        >
                          {card.URL}
                        </a>
                      </div>
                    </div>
                    <div className="flex justify-end mt-auto w-full">
                      <div className="bg-[#D1FFD0] w-52 px-2 py-2 text-center rounded-[20px] font-montserrat">
                        {`${card.Start_Time} - ${card.End_Time}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
)}

      </div>
    </div>

     
      <div className='flex justify-center'>
        <MainMenu/>
      </div>
    </section>
    
      
      
      
    
  );
};

export default Student_Conference_Page;

