import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import AdminInvoiceModal from './adminInvoiceModal';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactToPrint from 'react-to-print';

const AdminDashboard = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reportType, setReportType] = useState('students');
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const tableRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [reportType, startDate, endDate]);

  const fetchData = async () => {
    try {
      let url = '';
      switch (reportType) {
        case 'students':
          url = `${process.env.REACT_APP_API_URL}/api/adminUsers/getAllStudents`;
          break;
        case 'teachers':
          url = `${process.env.REACT_APP_API_URL}/api/adminUsers/getAllTeachers`;
          break;
        case 'calls':
          url = `${process.env.REACT_APP_API_URL}/api/adminCalls/getAllCalls`;
          break;
        case 'conferences':
          url = `${process.env.REACT_APP_API_URL}/api/adminConferences/getAllConferences`;
          break;
        case 'callInvoices':
          url = `${process.env.REACT_APP_API_URL}/api/adminInvoices/calls`;
          break;
        case 'conferenceInvoices':
          url = `${process.env.REACT_APP_API_URL}/api/adminInvoices/conferences`;
          break;
        case 'subscriptionInvoices':
          url = `${process.env.REACT_APP_API_URL}/api/adminInvoices/subscriptions`;
          break;
        case 'callReviews':
          url = `${process.env.REACT_APP_API_URL}/api/adminReview/call-reviews`;
          break;
        case 'conferenceReviews':
          url = `${process.env.REACT_APP_API_URL}/api/adminReview/conference-reviews`;
          break;
        default:
          break;
      }
      const response = await axios.get(url, {
        params: { startDate, endDate }
      });
      setData(response.data);
      console.log(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  const today = new Date().toISOString().split('T')[0];

  const handleStartDateChange = (e) => {
    const value = e.target.value;
    if (new Date(value) > new Date(endDate)) {
      setEndDate(value);
    }
    setStartDate(value);
  };

  const handleEndDateChange = (e) => {
    const value = e.target.value;
    if (new Date(value) < new Date(startDate)) {
      setStartDate(value);
    }
    setEndDate(value);
  };
  const handleApproveClick = async (callId, studentId, reviewId) => {
    console.log("approval ids", { callId, studentId, reviewId })
    try {
      // ${process.env.REACT_APP_API_URL}/api/adminReview/approveCallReview

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/adminReview/approveCallReview`, {
        callId,
        studentId,
        reviewId
      });

      if (response.status === 200) {
        alert('Review approved successfully');
        window.location.reload();
      } else {
        alert('Error approving review');
      }
    } catch (error) {
      console.log("error in aprroval", error);
    }
  }
  const handleConferenceApproveClick = async (conferenceId, studentId, reviewId) => {
    console.log("approval ids", { conferenceId, studentId, reviewId })
    try {
      // ${process.env.REACT_APP_API_URL}/api/adminReview/approveCallReview

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/adminReview/approveConferenceReview`, {
        conferenceId,
        studentId,
        reviewId
      });

      if (response.status === 200) {
        alert('Review approved successfully');
        window.location.reload();
      } else {
        alert('Error approving review');
      }
    } catch (error) {
      console.log("error in aprroval", error);
    }
  }

  const openModal = (invoice) => {
    setSelectedInvoice({
      invoiceNumber: invoice.invoiceNumber,
      user: invoice.user,
      name: invoice.name,
      email: invoice.email,
      date: new Date(invoice.createdAt).toLocaleDateString(),
      serviceCharges: invoice.serviceCharges,
      GST: invoice.GST,
      EventPrice: invoice.EventPrice,
      totalAmount: invoice.totalAmount,
      paymentType: invoice.paymentType,
      currency: invoice.currency,
      paymentStatus: invoice.paymentStatus,
      phonepeTranctionId: invoice.phonepeTranctionId,
      paypalTransactionId: invoice.paypalTransactionId,
      teacherName: invoice.call?.userId?.fullName || invoice.conference?.userId?.fullName,
      teacherID: invoice.call?.userId?._id || invoice.conference?.userId?._id,
      paypal_fee: invoice.paypal_fee,
      isPaypal: invoice.paypalTransactionId ? 'yes' : 'no',
    });
    console.log(invoice)
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedInvoice(null);
  };

  return (
    <div className="container mx-auto p-4 mt-14 lg:max-w-[1296px]">
      <h1 className="text-2xl text-[#585858] font-semibold mb-4 text-center">Dashboard</h1>

      <div className='sm:flex flex-row justify-center gap-10 items-end'>
        <div className="sm:w-[25%] mb-4 sm:mb-0">
          <label className="block text-gray-700 mb-2" htmlFor="startDate">Start Date</label>
          <input
            type="date"
            id="startDate"
            value={startDate}
            onChange={handleStartDateChange}
            className="border rounded px-4 py-2 w-full"
            max={today}
            placeholder="YYYY-MM-DD"
          />
        </div>

        <div className="sm:w-[25%] mb-4 sm:mb-0">
          <label className="block text-gray-700 mb-2" htmlFor="endDate">End Date</label>
          <input
            type="date"
            id="endDate"
            value={endDate}
            onChange={handleEndDateChange}
            className="border rounded px-4 py-2 w-full"
            max={today}
            placeholder="YYYY-MM-DD"
          />
        </div>

        <div className="sm:w-[25%] mb-4 sm:mb-0">
          <label className="block text-gray-700 mb-2" htmlFor="reportType">Report Type</label>
          <select
            id="reportType"
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
            className="border rounded px-4 py-2 w-full focus:outline-none"
          >
            <option value="students">Students Joined</option>
            <option value="teachers">Teachers Joined</option>
            <option value="calls">Calls</option>
            <option value="conferences">Conferences</option>
            <option value="callInvoices">Call Invoices</option>
            <option value="conferenceInvoices">Conference Invoices</option>
            <option value="subscriptionInvoices">Subscription Invoices</option>
            <option value="callReviews">Call Reviews</option>
            <option value="conferenceReviews">Conference Reviews</option>
          </select>
        </div>

        <button
          onClick={fetchData}
          className="sm:w-[25%] bg-gradient text-white px-4 py-2 rounded font-medium"
        >
          Get Report
        </button>
      </div>

      <div className="mt-6 overflow-x-auto">
        <h2 className="text-xl font-semibold mb-4 text-[#585858]">Report</h2>
        {/* <table className="min-w-full divide-y divide-gray-200 rounded-lg overflow-hidden border border-gray-200" ref={tableRef}>
          <thead className="bg-gray-50 font-semibold align-top">
            <tr>
              {
                (reportType === 'calls' || reportType === 'conferences') ? (
                  <>
                    <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Sr No</th>
                    <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">User ID & Name</th>
                    <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">{reportType === 'calls' ? 'Call Date' : 'Conference Date'}</th>
                    <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Start Time</th>
                    <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">End Time</th>
                    <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Price</th>
                    
                    <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Attended</th>
                  </>
                ) : (
                  (reportType === 'callInvoices' || reportType === 'conferenceInvoices' || reportType === 'subscriptionInvoices') ? (
                    <>
                    <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Sr No</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Invoice Number</th>
                      {(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Teacher ID & Name</th> : ""}
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">{(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? "Student ID & Name " : "Teacher ID & Name"}</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">{(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? "Student Email" : "Teacher Email"}</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Date</th>
                      {(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Service Charges</th> : ""}
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">GST</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">{(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? "Event price" : "Subscription price"}</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Total Amount</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Payment Type</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Currency</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Payment Status</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Payment ID</th>
                    </>
                  ) : (
                    <>
                    <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Sr No</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Full Name</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Email</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Joined Date</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Account Type</th>
                      {
                        reportType === 'students' ?
                          <>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Category</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">SubCategory</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Interested Category</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Interested SubCategory</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">No. of calls booked</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">No. of conferences booked</th>
                          </>
                          :
                          <>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Category</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">SubCategory</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Rating</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Referral Code</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Institute</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Qualification</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">No. of Calls Attended</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">No. of Calls Not Attended</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">No. of Conferences Attended</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">No. of Conferences Not Attended</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Total Call Earnings</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Total Conference Earnings</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Total Referral Earnings</th>
                          </>
                      }
                    </>
                  )
                )
              }
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 text-left whitespace-nowrap">{index+1}</td>
                  {
                    (reportType === 'calls' || reportType === 'conferences') ? (
                      <>
                        <td className="px-6 py-4 text-left whitespace-nowrap">{item.userId} - {item.user_fullName}</td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">{new Date(item.Conference_Date || item.Call_Date).toLocaleDateString()}</td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">{item.Start_Time}</td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">{item.End_Time}</td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">{`${item.Price?.currency} ${item.Price?.amount} `}</td>
                        
                        <td className="px-6 py-4 text-left whitespace-nowrap">{item.meeting_attended ? 'Yes' : 'No'}</td>
                      </>
                    ) : (
                      (reportType === 'callInvoices' || reportType === 'conferenceInvoices' || reportType === 'subscriptionInvoices') ? (
                        <>
                          <td className="px-6 py-4 text-left whitespace-nowrap cursor-pointer" title='View Invoice' onClick={() => openModal(item)}>{item.invoiceNumber}</td>
                          {(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? <td className="px-6 py-4 text-left whitespace-wrap">{`${item.call?.userId?._id || item.conference?.userId?._id || '-'} ${item.call?.userId?.fullName || item.conference?.userId?.fullName || '-'}`}</td> : ""}
                          <td className="px-6 py-4 text-left whitespace-wrap">{item.user}  {item.name}</td>
                          <td className="px-6 py-4 text-left whitespace-nowrap">{item.email}</td>
                          <td className="px-6 py-4 text-left whitespace-nowrap">{new Date(item.createdAt).toLocaleDateString()}</td>
                          {(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? <td className="px-6 py-4 text-left whitespace-nowrap">{item.serviceCharges}</td> : ''}
                          <td className="px-6 py-4 text-left whitespace-nowrap">{item.GST}</td>
                          <td className="px-6 py-4 text-left whitespace-nowrap">{item.EventPrice}</td>
                          <td className="px-6 py-4 text-left whitespace-nowrap">{item.totalAmount}</td>
                          <td className="px-6 py-4 text-left whitespace-nowrap">{item.paymentType}</td>
                          <td className="px-6 py-4 text-left whitespace-nowrap">{item.currency}</td>
                          <td className="px-6 py-4 text-left whitespace-nowrap">{item.paymentStatus}</td>
                          <td className="px-6 py-4 text-left whitespace-nowrap">{item.phonepeTranctionId}</td>
                        </>
                      ) : (
                        <>
                          <td className="px-6 py-4 whitespace-nowrap text-left font-medium text-gray-900">{item.fullName}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.email}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{new Date(item.createdAt).toLocaleDateString()}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.account_type}</td>
                          {reportType === 'students' ?
                            <>
                              <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.studentDetails?.category || '-'}</td>
                              <td className="px-6 py-4 whitespace-pre-line text-left text-gray-500">{item.studentDetails?.subCategory?.join(', ') || '-'}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.studentDetails?.interest_category || '-'}</td>
                              <td className="px-6 py-4 whitespace-pre-line text-left text-gray-500">{item.studentDetails?.interest_subCategory?.join(', ') || '-'}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-gray-500">{item.studentDetails?.booked_calls?.length || 0}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-gray-500">{item.studentDetails?.booked_conferences?.length || 0}</td>
                            </>
                            :
                            <>
                              <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.teacherDetails?.category || '-'}</td>
                              <td className="px-6 py-4 whitespace-pre-line text-left text-gray-500">{item.teacherDetails?.subCategory?.join(', ') || '-'}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.teacherDetails?.rating || '-'}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.referralCode || '-'}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.teacherDetails?.institute || '-'}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.teacherDetails?.qualification || '-'}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.callAttendance?.attendedCalls}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.callAttendance?.notAttendedCalls}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.callAttendance?.attendedConferences}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.callAttendance?.notAttendedConferences}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.callAttendance?.totalCallEarnings}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.callAttendance?.totalConferenceEarnings}</td>
                            </>
                          }
                        </>
                      )
                    )
                  }
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={reportType === 'calls' || reportType === 'conferences' ? 8 : 14} className="px-6 py-4 text-center">No data available</td>
              </tr>
            )}
          </tbody>
        </table> */}
        <table className="min-w-full divide-y divide-gray-200 rounded-lg overflow-hidden border border-gray-200" ref={tableRef}>
          <thead className="bg-gray-50 font-semibold align-top">
            <tr>
              {
                (reportType === 'calls' || reportType === 'conferences') ? (
                  <>
                    <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Sr No</th>
                    <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">User ID & Name</th>
                    <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">{reportType === 'calls' ? 'Call Date' : 'Conference Date'}</th>
                    <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Start Time</th>
                    <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">End Time</th>
                    <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Price</th>
                    <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Attended</th>
                  </>
                ) : (
                  (reportType === 'callInvoices' || reportType === 'conferenceInvoices' || reportType === 'subscriptionInvoices') ? (
                    <>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Sr No</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Invoice Number</th>
                      {(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Teacher ID & Name</th> : ""}
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">{(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? "Student ID & Name " : "Teacher ID & Name"}</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">{(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? "Student Email" : "Teacher Email"}</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Date</th>
                      {(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Service Charges</th> : ""}
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">GST/ Tax</th>
                      {(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Paypal Charges</th> : ""}

                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">{(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? "Event price" : "Subscription price"}</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Total Amount</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Payment Type</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Currency</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Payment Status</th>
                      <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Payment ID</th>
                    </>
                  ) : (
                    (reportType === 'students' || reportType === 'teachers') ? (
                      <>
                        <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Sr No</th>
                        <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Full Name</th>
                        <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Email</th>
                        <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Joined Date</th>
                        <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Account Type</th>
                        {
                          reportType === 'students' ? (
                            <>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Category</th>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">SubCategory</th>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Interested Category</th>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Interested SubCategory</th>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">No. of calls booked</th>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">No. of conferences booked</th>
                            </>
                          ) : (
                            <>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Category</th>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">SubCategory</th>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Rating</th>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Referral Code</th>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Institute</th>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Qualification</th>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">No. of Calls Attended</th>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">No. of Calls Not Attended</th>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">No. of Conferences Attended</th>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">No. of Conferences Not Attended</th>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Total Call Earnings</th>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Total Conference Earnings</th>
                              <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Total Referral Earnings</th>
                            </>
                          )
                        }
                      </>
                    ) : (
                      (reportType === 'callReviews') ? (
                        <>
                          <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Sr No</th>
                          <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Review ID</th>
                          <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Review Date</th>
                          <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Rating</th>
                          <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Comments</th>
                          <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Call ID</th>
                          <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Student Name & ID</th>
                          <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Teacher Name & ID</th>
                          <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Approval Status</th>
                        </>
                      ) : (
                        (reportType === 'conferenceReviews') ?
                          <>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Sr No</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Review ID</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Review Date</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Rating</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Comments</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Conference ID</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Student Name & ID</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Teacher Name & ID</th>
                            <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider">Approval Status</th>
                          </>
                          : null
                      )
                    )
                  )
                )
              }
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 text-left whitespace-nowrap">{index + 1}</td>
                  {
                    (reportType === 'calls' || reportType === 'conferences') ? (
                      <>
                        <td className="px-6 py-4 text-left whitespace-nowrap">{item.userId} - {item.user_fullName}</td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">{new Date(item.Conference_Date || item.Call_Date).toLocaleDateString()}</td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">{item.Start_Time}</td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">{item.End_Time}</td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">{`${item.Price?.currency} ${item.Price?.amount} `}</td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">{item.meeting_attended ? 'Yes' : 'No'}</td>
                      </>
                    ) : (
                      (reportType === 'callInvoices' || reportType === 'conferenceInvoices' || reportType === 'subscriptionInvoices') ? (
                        <>
                          <td className="px-6 py-4 text-left whitespace-nowrap cursor-pointer" title='View Invoice' onClick={() => openModal(item)}>{item.invoiceNumber}</td>
                          {(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? <td className="px-6 py-4 text-left whitespace-wrap">{`${item.call?.userId?._id || item.conference?.userId?._id || '-'} ${item.call?.userId?.fullName || item.conference?.userId?.fullName || '-'}`}</td> : ""}
                          <td className="px-6 py-4 text-left whitespace-wrap">{item.user}  {item.name}</td>
                          <td className="px-6 py-4 text-left whitespace-nowrap">{item.email}</td>
                          <td className="px-6 py-4 text-left whitespace-nowrap">{new Date(item.createdAt).toLocaleDateString()}</td>
                          {(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? <td className="px-6 py-4 text-left whitespace-nowrap">{item.serviceCharges}</td> : ''}
                          <td className="px-6 py-4 text-left whitespace-nowrap">{item.GST}</td>
                          {(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? <td className="px-6 py-4 text-left whitespace-nowrap">{item.paypal_fee ? item.paypal_fee : '-'}</td> : ''}

                          <td className="px-6 py-4 text-left whitespace-nowrap">{item.EventPrice}</td>
                          <td className="px-6 py-4 text-left whitespace-nowrap">{item.totalAmount}</td>
                          <td className="px-6 py-4 text-left whitespace-nowrap">{item.paymentType}</td>
                          <td className="px-6 py-4 text-left whitespace-nowrap">{item.currency}</td>
                          <td className="px-6 py-4 text-left whitespace-nowrap">{item.paymentStatus}</td>
                          <td className="px-6 py-4 text-left whitespace-nowrap">{item.phonepeTranctionId || item.paypalTransactionId}</td>
                        </>
                      ) : (
                        (reportType === 'students' || reportType === 'teachers') ? (
                          <>
                            <td className="px-6 py-4 whitespace-nowrap text-left font-medium text-gray-900">{item.fullName}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.email}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{new Date(item.createdAt).toLocaleDateString()}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.account_type}</td>
                            {
                              reportType === 'students' ? (
                                <>
                                  <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.studentDetails?.category || '-'}</td>
                                  <td className="px-6 py-4 whitespace-pre-line text-left text-gray-500">{item.studentDetails?.subCategory?.join(', ') || '-'}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.studentDetails?.interest_category || '-'}</td>
                                  <td className="px-6 py-4 whitespace-pre-line text-left text-gray-500">{item.studentDetails?.interest_subCategory?.join(', ') || '-'}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-gray-500">{item.studentDetails?.booked_calls?.length || 0}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-gray-500">{item.studentDetails?.booked_conferences?.length || 0}</td>
                                </>
                              ) : (
                                <>
                                  <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.teacherDetails?.category || '-'}</td>
                                  <td className="px-6 py-4 whitespace-pre-line text-left text-gray-500">{item.teacherDetails?.subCategory?.join(', ') || '-'}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.teacherDetails?.rating || '-'}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.referralCode || '-'}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.teacherDetails?.institute || '-'}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.teacherDetails?.qualification || '-'}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.callAttendance?.attendedCalls}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.callAttendance?.notAttendedCalls}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.callAttendance?.attendedConferences}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.callAttendance?.notAttendedConferences}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.callAttendance?.totalCallEarnings}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-left text-gray-500">{item.callAttendance?.totalConferenceEarnings}</td>
                                </>
                              )
                            }
                          </>
                        ) : (
                          (reportType === 'callReviews') ? (
                            <>
                              <td className="px-6 py-4 text-left whitespace-nowrap">
                                {item.reviews?.map((review, reviewIndex) => (
                                  <div key={reviewIndex}>
                                    <span>{review._id}</span>
                                  </div>
                                ))}
                              </td>
                              <td className="px-6 py-4 text-left whitespace-nowrap">{new Date(item.createdAt).toLocaleDateString()}</td>
                              <td className="px-6 py-4 text-left whitespace-nowrap">
                                {item.reviews?.map((review, reviewIndex) => (
                                  <div key={reviewIndex}>
                                    <span>{review.rating}</span>
                                  </div>
                                ))}
                              </td>
                              <td className="px-6 py-4 text-left whitespace-nowrap">
                                {item.reviews?.map((review, reviewIndex) => (
                                  <div key={reviewIndex}>
                                    <span>{review.review}</span>
                                  </div>
                                ))}
                              </td>
                              <td className="px-6 py-4 text-left whitespace-nowrap">{item.callId}</td>
                              <td className="px-6 py-4 text-left whitespace-nowrap">{item.studentId?.fullName} - {item.studentId?._id}</td>
                              <td className="px-6 py-4 text-left whitespace-nowrap">{item.userId?.fullName} - {item.userId?._id}</td>
                              <td className="px-6 py-4 text-left whitespace-nowrap">
                                {item.reviews?.map((review, reviewIndex) => (
                                  <div key={reviewIndex}>
                                    {review.approved ?
                                      <span className='bg-green-400 rounded-xl px-4 py-2 text-white font-semibold'>Approved</span> :
                                      <button className='bg-blue-400 rounded-xl px-4 py-2 text-white font-semibold' onClick={() => handleApproveClick(item.callId, item.studentId._id, review._id)}>
                                        Approve Now
                                      </button>}
                                  </div>
                                ))}
                              </td>
                            </>
                          ) : (<>
                            <td className="px-6 py-4 text-left whitespace-nowrap">
                              {item.reviews?.map((review, reviewIndex) => (
                                <div key={reviewIndex}>
                                  <span>{review._id}</span>
                                </div>
                              ))}
                            </td>
                            <td className="px-6 py-4 text-left whitespace-nowrap">{new Date(item.createdAt).toLocaleDateString()}</td>
                            <td className="px-6 py-4 text-left whitespace-nowrap">
                              {item.reviews?.map((review, reviewIndex) => (
                                <div key={reviewIndex}>
                                  <span>{review.rating}</span>
                                </div>
                              ))}
                            </td>
                            <td className="px-6 py-4 text-left whitespace-nowrap">
                              {item.reviews?.map((review, reviewIndex) => (
                                <div key={reviewIndex}>
                                  <span>{review.review}</span>
                                </div>
                              ))}
                            </td>
                            <td className="px-6 py-4 text-left whitespace-nowrap">{item.conferenceId}</td>
                            <td className="px-6 py-4 text-left whitespace-nowrap">{item.studentId?.fullName} - {item.studentId?._id}</td>
                            <td className="px-6 py-4 text-left whitespace-nowrap">{item.userId?.fullName} - {item.userId?._id}</td>
                            <td className="px-6 py-4 text-left whitespace-nowrap">
                              {item.reviews?.map((review, reviewIndex) => (
                                <div key={reviewIndex}>
                                  {review.approved ?
                                    <span className='bg-green-400 rounded-xl px-4 py-2 text-white font-semibold'>Approved</span> :
                                    <button className='bg-blue-400 rounded-xl px-4 py-2 text-white font-semibold' onClick={() => handleConferenceApproveClick(item.conferenceId, item.studentId._id, review._id)}>
                                      Approve Now
                                    </button>}
                                </div>
                              ))}
                            </td>
                          </>
                          )
                        )
                      )
                    )
                  }
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="100%" className="px-6 py-4 text-center text-gray-500">No data available</td>
              </tr>
            )}
          </tbody>
        </table>

      </div>

      <div className='flex gap-4'>
        <ReactToPrint
          trigger={() => <button className="mt-4 bg-gradient text-white px-4 py-2 rounded font-medium" >Download PDF</button>}
          content={() => tableRef.current}
        />
        <DownloadTableExcel
          filename={`${reportType}_report_${today}`}
          sheet={reportType}
          currentTableRef={tableRef.current}
        >
          <button className="mt-4 bg-gradient text-white px-4 py-2 rounded font-medium">Download Excel Sheet</button>
        </DownloadTableExcel>
      </div>
      {isModalOpen ? (
        <AdminInvoiceModal closeModal={closeModal} selectedInvoice={selectedInvoice} reportType={reportType} />
      ) : ''}
    </div>
  );
};

export default AdminDashboard;
