import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SocketContextProvider } from './context/SocketContext';
import { NotificationProvider } from './context/NotificationContext';
import { HelmetProvider } from 'react-helmet-async';
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <GoogleOAuthProvider clientId={googleClientId}>
    <SocketContextProvider>
      <NotificationProvider>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </NotificationProvider>
    </SocketContextProvider>
  </GoogleOAuthProvider>

);