import React, { useEffect } from "react";
import { Helmet } from 'react-helmet-async'

const hostname = 'https://careerguidancebuddy.com';
// const hostname = 'http://localhost:3000';

const MetaDecorator = ({ title, description, imageUrl, keywords = [], imageAlt, url }) => {
  //console.log(title, description, imageUrl, keywords = [], imageAlt, url);
  // useEffect(() => {
  //   // Set document title
  //   if (title) {
  //     document.title = title;
  //   }
  //console.log(title, description, imageUrl, keywords = [], imageAlt, url);
  // useEffect(() => {
  //   // Set document title
  //   if (title) {
  //     document.title = title;
  //   }

  //   // Set meta tags
  //   setMetaTag('name', 'description', description);
  //   setMetaTag('name', 'keywords', keywords.join(', '));
  //   setMetaTag('name', 'title', title);
  //   setMetaTag('property', 'og:title', title);
  //   setMetaTag('property', 'og:description', description);
  //   setMetaTag('property','og:keywords',keywords.join(', '))
  //   setMetaTag('property','og:type','website');
  //   setMetaTag('property', 'og:image', `${hostname}${imageUrl}`);
  //   setMetaTag('property','og:image:type','image/png')
  //   setMetaTag('property', 'og:image:width', '1200');
  //   setMetaTag('property', 'og:image:height', '630');
  //   setMetaTag('property', 'og:url', url || window.location.href);
  //   setMetaTag('name', 'twitter:card', 'summary_large_image');
  //   setMetaTag('name', 'twitter:title', title);
  //   setMetaTag('name', 'twitter:description', description);
  //   setMetaTag('name', 'twitter:image', `${hostname}${imageUrl}`);
  //   setMetaTag('name','twitter:image:type','image/png')
  //   setMetaTag('name', 'twitter:image:width', '1200');
  //   setMetaTag('name', 'twitter:image:height', '630');
  //   setMetaTag('name', 'twitter:url', url || window.location.href);
  //   // Set meta tags
  //   setMetaTag('name', 'description', description);
  //   setMetaTag('name', 'keywords', keywords.join(', '));
  //   setMetaTag('name', 'title', title);
  //   setMetaTag('property', 'og:title', title);
  //   setMetaTag('property', 'og:description', description);
  //   setMetaTag('property','og:keywords',keywords.join(', '))
  //   setMetaTag('property','og:type','website');
  //   setMetaTag('property', 'og:image', `${hostname}${imageUrl}`);
  //   setMetaTag('property','og:image:type','image/png')
  //   setMetaTag('property', 'og:image:width', '1200');
  //   setMetaTag('property', 'og:image:height', '630');
  //   setMetaTag('property', 'og:url', url || window.location.href);
  //   setMetaTag('name', 'twitter:card', 'summary_large_image');
  //   setMetaTag('name', 'twitter:title', title);
  //   setMetaTag('name', 'twitter:description', description);
  //   setMetaTag('name', 'twitter:image', `${hostname}${imageUrl}`);
  //   setMetaTag('name','twitter:image:type','image/png')
  //   setMetaTag('name', 'twitter:image:width', '1200');
  //   setMetaTag('name', 'twitter:image:height', '630');
  //   setMetaTag('name', 'twitter:url', url || window.location.href);

  //   // Clean up meta tags on component unmount
  //   return () => {
  //     cleanupMetaTag('name', 'description');
  //     cleanupMetaTag('name', 'keywords');
  //     cleanupMetaTag('name', 'title');
  //     cleanupMetaTag('property', 'og:title');
  //     cleanupMetaTag('property', 'og:description');
  //     cleanupMetaTag('property', 'og:image');
  //     cleanupMetaTag('property', 'og:image:width');
  //     cleanupMetaTag('property', 'og:image:height');
  //     cleanupMetaTag('property', 'og:url');
  //     cleanupMetaTag('name', 'twitter:card');
  //     cleanupMetaTag('name', 'twitter:title');
  //     cleanupMetaTag('name', 'twitter:description');
  //     cleanupMetaTag('name', 'twitter:image');
  //     cleanupMetaTag('name', 'twitter:image:width');
  //     cleanupMetaTag('name', 'twitter:image:height');
  //     cleanupMetaTag('name', 'twitter:url');
  //   };
  // }, [title, description, imageUrl, keywords, imageAlt, url]);
  //   // Clean up meta tags on component unmount
  //   return () => {
  //     cleanupMetaTag('name', 'description');
  //     cleanupMetaTag('name', 'keywords');
  //     cleanupMetaTag('name', 'title');
  //     cleanupMetaTag('property', 'og:title');
  //     cleanupMetaTag('property', 'og:description');
  //     cleanupMetaTag('property', 'og:image');
  //     cleanupMetaTag('property', 'og:image:width');
  //     cleanupMetaTag('property', 'og:image:height');
  //     cleanupMetaTag('property', 'og:url');
  //     cleanupMetaTag('name', 'twitter:card');
  //     cleanupMetaTag('name', 'twitter:title');
  //     cleanupMetaTag('name', 'twitter:description');
  //     cleanupMetaTag('name', 'twitter:image');
  //     cleanupMetaTag('name', 'twitter:image:width');
  //     cleanupMetaTag('name', 'twitter:image:height');
  //     cleanupMetaTag('name', 'twitter:url');
  //   };
  // }, [title, description, imageUrl, keywords, imageAlt, url]);

  // const setMetaTag = (attr, key, content) => {
  //   if (content) {
  //     let element = document.querySelector(`meta[${attr}="${key}"]`);
  //     if (!element) {
  //       element = document.createElement('meta');
  //       element.setAttribute(attr, key);
  //       document.head.appendChild(element);
  //     }
  //     element.setAttribute('content', content);
  //   }
  // };
  // const setMetaTag = (attr, key, content) => {
  //   if (content) {
  //     let element = document.querySelector(`meta[${attr}="${key}"]`);
  //     if (!element) {
  //       element = document.createElement('meta');
  //       element.setAttribute(attr, key);
  //       document.head.appendChild(element);
  //     }
  //     element.setAttribute('content', content);
  //   }
  // };

  // const cleanupMetaTag = (attr, key) => {
  //   const element = document.querySelector(`meta[${attr}="${key}"]`);
  //   if (element) {
  //     document.head.removeChild(element);
  //   }
  // };
  // const cleanupMetaTag = (attr, key) => {
  //   const element = document.querySelector(`meta[${attr}="${key}"]`);
  //   if (element) {
  //     document.head.removeChild(element);
  //   }
  // };

  // return null; // No UI to render
  return (
    <Helmet>
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_nmae" content="Career GuidanceBuddy" />
      <meta property="og:image" content={hostname + imageUrl} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="300" />
      <meta property="og:image:height" content="300" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={hostname + imageUrl} />
      <meta name="twitter:image:width" content="1200" />
      <meta name="twitter:image:height" content="630" />
      <meta name="twitter:url" content={url} />

      {/* General Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  )
};

export default MetaDecorator;
