import React, { useEffect, useState } from 'react'
import { FaChevronLeft, FaChevronRight, FaEnvelope, FaFileDownload  } from 'react-icons/fa';
import Header from '../headerBackground';
import { TbDatabaseDollar } from "react-icons/tb";
import axios from 'axios';
import Cookies from 'js-cookie';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
const ConferenceEarnings = ({ handleNext, handlePrev, handleCallClick, handleConferenceClick, handleReferralClick, setTotalEarnings }) => {
  const [earnings, setEarnings] = useState([]);
  const [totalEarnings, setTotalEarningsConference] = useState(0);
  const [monthEarnings, setMonthEarnings] = useState(0);
  const loggedInUserId = Cookies.get('loggedInUserId');
  const authToken = Cookies.get('authToken');
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchEarnings = async () => {
      try {
        const response = await axios.get(`${url}/api/earningConference/earning_conference`, {
          headers: {
            "Authorization": `Bearer ${authToken}`
          }
        });
        // Sort earnings by createdAt in descending order
        const sortedEarnings = response.data.earnings.sort((a, b) => new Date(b.conference_id.Conference_Date) - new Date(a.conference_id.Conference_Date));

        setEarnings(sortedEarnings);
        setTotalEarningsConference(response.data.totalEarnings);
        setTotalEarnings(response.data.totalEarnings)
        setMonthEarnings(response.data.currentMonthTotalEarnings)
        console.log(response.data)
      } catch (err) {
        console.error('Error fetching earnings data', err);
      }
    };

    fetchEarnings();
  }, []);
 
  const currentDate = new Date();
  let nextPaymentDate;
  if (currentDate.getDate() > 15) {
    // Set the date to the 15th of the next month
    nextPaymentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 15);
  } else {
    // Set the date to the 15th of the current month
    nextPaymentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 15);
  }
  // Manually format the date to avoid timezone issues give this for getting 15th of next month {formattedDate}
  const formattedDate = nextPaymentDate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const monthName = currentDate.toLocaleString('default', { month: 'long' });
  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text(`Conference Earnings Report ${monthName} - ${currentYear}`, 20, 10);

    const tableColumn = ["Sr No.","Student Name", "Conference", "Date", "Time", "Earning"];
    const tableRows = [];

    earnings.forEach((earning, index) => {
      const conferenceDate = new Date(earning.conference_id.Conference_Date);
      const earningMonth = conferenceDate.getMonth();
      const earningYear = conferenceDate.getFullYear();

      if (earningMonth === currentMonth && earningYear === currentYear) {
        const earningData = [
          index + 1,
          earning.name,
          earning.conference_id.Title,
          conferenceDate.toLocaleDateString().slice(0, 10),
          `${earning.conference_id.Start_Time} - ${earning.conference_id.End_Time}`,
          `${earning.currency} ${earning.amount}`
        ];
        tableRows.push(earningData);
      }
    });

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.save(`${monthName}_${currentYear}_Conference_Earnings.pdf`);
  }
  return (
    <div className='sm:mx-auto sm:mt-0 sm:w-full sm:max-w-[1296px] sm:px-5'>
      <div className="lg:grid grid-cols-3 gap-4 sm:min-h-[85vh] sm:py-[55px] justify-center">
        <div>
          <Header />
          <div className='w-11/12 sm:w-full mx-auto'>
            <div
              className="relative flex justify-center rounded-[25px] w-full py-4 sm:py-8 shadow-md bg-gradient items-center sm:px-10 px-6 text-white gap-4 mb-1 overflow-hidden">
              <FaChevronLeft className="absolute left-4 transform-translate-y-1/2 cursor-pointer fill-white w-3 h-4 sm:w-5 sm:h-6" onClick={handlePrev} title='call earnings' />
              <FaChevronRight className="absolute right-4 fill-white transform-translate-y-1/2 cursor-pointer w-3 h-4 sm:w-5 sm:h-6" onClick={handleNext} title='referral earnings' />
              <div className=' flex flex-col items-center font-primary'>
                <p className='sm:hidden text-white text-[18px] sm:text-[24px] font-semibold'>Conference Earnings</p>
                <p className='hidden sm:block text-white text-[34px] font-semibold'>Earnings</p>
                <p className='text-white text-[28px] sm:text-[68px] font-bold'>₹ {monthEarnings}</p>
                <p className='text-white text-[12px] sm:text-[17px] mt-[-5px] font-medium'>Earned this
                  month</p>
              </div>
            </div>
            <div className='mt-4'>
              <span className='sm:text-lg text-sm text-gradient font-semibold'>Total Conference Earnings: ₹ {totalEarnings}</span>
            </div>
            <div className='flex flex-row mt-2 sm:mt-0 sm:flex-col justify-between items-center gap-1 align-middle'>
              <button onClick={generatePDF}
                className=' bg-gradient w-1/2 sm:w-full flex flex-row items-center justify-center mt-2 sm:mt-4 focus:outline-none h-[48px] gap-2 sm:gap-4 text-white px-4 py-2 rounded-full'>
                <FaFileDownload className='order-first items-center pointer-events-none w-4 h-4 sm:w-[30px] sm:h-[28px]' />
                <p className='text-[12px] sm:text-[20px] sm:font-semibold'>Download Report</p>
              </button>
              <div className='flex flex-col gap-1 w-1/2 sm:w-full self-center sm:mt-4'>
                <label htmlFor='dateRange' className='text-[10px] font-semibold text-gradient sm:text-[20px] sm:mb-2'>Upcoming
                  Payment
                  Date:</label>

                <input type="text" name="" id="dateRange"
                  className='border border-gray-400 sm:text-left text-sm sm:text-[18px] rounded-full p-2 sm:p-4'
                  defaultValue='15/09/2024' readOnly />
              </div>
            </div>
            {/* <div className="flex sm:w-full items-center justify-center mt-2 sm:mt-4">
              <button
                className="sm:w-full bg-white shadow-md text-gradient text-[14px] sm:text-[20px] font-semibold px-4 py-1 sm:px-4 sm:py-4 rounded-full border border-orange-400">Add/
                update your payment details</button>
            </div> */}
          </div>
        </div>
        <div className="col-span-2">
          <style>
            {`
          .active_option_by_toybur {
            background: linear-gradient(180deg,
              #ff6535 0%,
              #ff9e48 48.9%,
              #ffce58 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border-bottom: 3px solid orange;
          }
        `}
          </style>
          <div className="hidden sm:flex items-center gap-10 justify-center mt-8 lg:mt-0 mb-10">
            <div className="w-fit text-[23px] font-bold text-[#B8B8B8] cursor-pointer" onClick={handleCallClick}>
              <p>Calls</p>
            </div>
            <div className="w-fit text-[23px] pb-2 font-bold active_option_by_toybur cursor-pointer" onClick={handleConferenceClick}>
              <p>Conferences</p>
            </div>
            <div className="w-fit text-[23px] font-bold text-[#B8B8B8] cursor-pointer" onClick={handleReferralClick}>
              <p>Courses</p>
            </div>
          </div>
          <div
            className='overflow-y-auto mt-2 sm:mt-0 py-2 w-screen sm:w-auto max-h-[500px] sm:min-h-[65vh] sm:max-h-[80vh]' style={{ maxHeight: 'calc(100vh - 480px)' }}>
            <div className='flex flex-col gap-2  items-center'>
              {earnings.length === 0 ? (
                <p className='text-center text-gray-500 text-lg'>No conference earnings found</p>
              ) : (
                earnings.map((earning, index) => {
                  const conferenceDate = new Date(earning.conference_id.Conference_Date);
                  const earningMonth = conferenceDate.getMonth();
                  const earningYear = conferenceDate.getFullYear();
                  const isCurrentMonth = earningMonth === currentMonth && earningYear === currentYear;
                  return (
                    <>
                    <div key={index} className='flex flex-row p-2 justify-between items-center bg-white w-11/12 rounded-xl shadow-md'>
                      <div className='flex items-center flex-row gap-2 sm:gap-4 sm:px-4 sm:py-2'>
                        <TbDatabaseDollar className='h-10 w-8 text-orange-400 sm:h-[50px] sm:w-[42px]' />
                        <div className='flex flex-col'>
                          <p className='text-[12px] sm:text-[20px] font-semibold text-gray-400'>{earning.name}</p>
                          <p className='text-[11px] sm:text-[16px] text-gray-400'>Conference: {' '}{earning.conference_id.Title}</p>
                          <div className='flex flex-row gap-4'>
                            <p className='text-[11px] sm:text-[16px] text-gray-400'>Date: {' '} {new Date(earning.conference_id.Conference_Date).toISOString().slice(0, 10)}</p>
                            <p className='text-[11px] sm:text-[16px] text-gray-400'>Time: {' '}{earning.conference_id.Start_Time} - {earning.conference_id.End_Time}</p>
                          </div>
                        </div>
                      </div>
                      <div className='flex flex-col items-center gap-1'>
                      <div className={`sm:w-[172px] text-center rounded-xl p-2 sm:py-2 sm:px-3 sm:text-[18px] sm:font-semibold sm:text-lg text-white ${isCurrentMonth ? 'bg-gradient' : 'bg-[#afafaf]'}`}>
                        <p className=''>
                          {earning.currency} {earning.amount} 
                          
                        </p>
                        {/* <p className='text-[16px]'>original earning</p> */}
                        
                      </div>
                      {/* <p className='text-[16px] text-gradient font-semibold'>Less 5% CGB Charge: {earning.currency} {earning.reducedAmount}</p> */}
                      </div>
                    </div>
                    
                    </>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConferenceEarnings