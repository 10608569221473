import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import crossBtn from "../assets/img/x.png";
import { MdEmojiEmotions } from "react-icons/md";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TbUpload, TbPhoneCall,TbCertificate } from "react-icons/tb";
import { BsFillChatDotsFill, BsPersonVideo2 } from "react-icons/bs";
import { IoPersonCircleSharp } from "react-icons/io5";
import { ChatContext } from "../App";
import {
  BsEmojiSmileFill,
  BsFillEmojiNeutralFill,
  BsFillEmojiWinkFill,
  BsEmojiFrownFill, BsFillEmojiLaughingFill
} from "react-icons/bs";


const ProfileModal = ({ profileData, setProfileData }) => {
  console.log(profileData)
  const apiUrl = process.env.REACT_APP_API_URL;
  const authToken = Cookies.get('authToken');
  const { setOtherUserID } = useContext(ChatContext);
  const [reviews_rating, SetReview_Rating] = useState('')
  const file_url = 'https://cgb-storage.blr1.digitaloceanspaces.com';
  let defaultProfile = `${file_url}/profile-pictures/defaultProfile.png`
  let user_type = Cookies.get("user_type");
  const profile_type = Cookies.get("user_type") === "Teacher" ? "Student" : "Teacher"
  const loggedInUserId = Cookies.get('loggedInUserId');
  const navigate = useNavigate();
  const handleBook = (id) => {
    // console.log(id)
    navigate(`/book_list/${id}`)
  }
  const handleConference = (id) => {
    navigate(`/teacher_conferences/${id}`, { state: { profileData } })
  }
  const handleCourses = (id) => {
    navigate(`/teacher_courses/${id}`, { state: { profileData } })
  }
  const handleViewProfile = () => {
    // console.log('view clicked ', profileData)
    navigate("/viewProfile", { state: { profileData, reviews_rating } });
  };

  const handleshare = () => {
    if (navigator.share) {
      const baseUrl = window.location.origin;
      navigator.share({
        title: 'Check out this book list!',
        text: 'Here is an amazing book list you should see:',
        url: `${baseUrl}/book_list/${profileData.id}`,
      })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      alert('Sharing is not supported on this browser.');
    }
  };

  const handleshareTeacher = () => {
    if (navigator.share) {
      const baseUrl = window.location.origin;
      navigator.share({
        title: 'Check out this book list!',
        text: 'Here is an amazing book list you should see:',
        url: `${baseUrl}/book_list/${loggedInUserId}`,
      })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      alert('Sharing is not supported on this browser.');
    }
  }
  const id = profileData.id
  useEffect(() => {
    console.log("Inside UseEffect");

    const FetchReview_Rating = async () => {
      try {
        const Review_Rating = await axios.post(`${apiUrl}/api/eventReview/getrating&reviews/${id}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        });
        console.log("Review_Rating of Teacher ===>", Review_Rating.data);
        SetReview_Rating(Review_Rating.data);
      } catch (error) {
        console.error("Error fetching review rating:", error);
      }
    };

    FetchReview_Rating();
  }, [id]);
  const emojiComponents = {
    '1': <BsEmojiFrownFill className="text-orange-500" />,
    '2': <BsFillEmojiNeutralFill className="text-orange-500" />,
    '3': <BsFillEmojiWinkFill className="text-orange-500" />,
    '4': <BsEmojiSmileFill className="text-orange-500" />,
    '5': <BsFillEmojiLaughingFill className="text-orange-500" />,
  };
  const handleStartChat = async (targetUserId) => {
    // Check if a conversation with this user already exists
    try {
      const response = await axios.get(`${apiUrl}/api/messages`, {
        headers: {
          "Authorization": `Bearer ${authToken}`
        },
      });

      const conversations = response.data;
      const existingConversation = conversations.find(conversation =>
        conversation.participants.some(participant => participant._id === targetUserId)
      );

      if (existingConversation) {
        setOtherUserID(targetUserId);
        localStorage.setItem('otheruserID', targetUserId);
        navigate('/open-chat');
        // If a conversation exists, navigate to it
        // navigate(`/open-chat/${targetUserId}`);
      } else {
        // If no conversation exists, create a new one
        const createResponse = await axios.post(`${apiUrl}/api/messages`, {
          userId: loggedInUserId,
          otherUserId: targetUserId
        }, {
          headers: {
            "Authorization": `Bearer ${authToken}`
          }
        });

        console.log('New conversation created:', createResponse.data);
        setOtherUserID(targetUserId);
        localStorage.setItem('otheruserID', targetUserId);
        navigate('/open-chat');
        // navigate(`/open-chat/${targetUserId}`);
      }
    } catch (error) {
      console.error('Error fetching or creating conversation:', error);
    }
  };
  return (
    <div class="flex items-center font-primary justify-center sm:px-5 fixed right-0 left-0 top-10 bottom-[10%] w-full" style={{ display: profileData.display }}>
      <div class="relative transform overflow-hidden rounded-3xl bg-white mx-2 px-4 pb-4 pt-5 text-left shadow-2xl sm:my-8  sm:w-[80%] sm:p-6 z-50 ">
        <div class="absolute right-0 top-0 pr-4 pt-4 sm:block">
          <button type="button" class="  ">
            <img
              src={crossBtn}
              alt=""
              onClick={() =>
                setProfileData({ ...profileData, display: "none" })
              }
            />
          </button>
        </div>
        <div className="sm:flex flex-col">

          <div className="sm:flex sm:gap-4">
            <img src={profileData.profilePic !== '' && profileData.profilePic !== undefined ? profileData.profilePic : defaultProfile} alt="" className="flex justify-center lg:w-[80px] lg:h-[80px] w-[60px] h-[60px] mx-auto sm:mx-0 rounded-full" />
            <div className="flex-grow gap-1 basis-8/12">
              <h1 className="text-center sm:text-start font-semibold text-xl sm:text-2xl text-[#585858]">
                {profileData.fullName}
              </h1>
              <p className="text-center sm:text-start font-normal text-[11px] sm:text-[14px] text-[#585858] max-w-[257px] sm:max-w-full mx-auto pb-5">
                {profileData.about}
              </p>
            </div>
          </div>
          {user_type === "Student" ? (
            <div className="flex flex-col gap-2 lg:mt-4">
              <div className="flex items-center justify-center gap-2 mt-2">
                <button className="ml-1 bg-gradient rounded-full flex items-center p-2 justify-center sm:w-72 w-56" onClick={() => handleBook(profileData.id)}>
                  <TbPhoneCall size={20} className="mr-2 text-white font-semibold lg:w-6 lg:h-6" />
                  <span className="text-white font-medium text-[14px] sm:text-[16px]">Book a Call</span>
                </button>
                <div>
                  {reviews_rating && reviews_rating.combinedAverageRating > 0 && (
                    <button className="text-gradient p-1 border-2 border-orange-400 rounded-full flex items-center justify-center w-16">
                      <span className="mr-1 font-semibold">{profileData.rating}</span>
                      {emojiComponents[reviews_rating.Rating_Ceil_Value]}
                    </button>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-center">
                <button className="ml-1 bg-gradient rounded-full flex items-center p-2 justify-center w-72 sm:w-[22.5rem]" onClick={() => handleConference(profileData.id)}>
                  <BsPersonVideo2 size={20} className="mr-2 text-white font-semibold lg:w-6 lg:h-6" />
                  <span className="text-white font-medium text-[14px] sm:text-[16px]">Book a Conference</span>
                </button>
              </div>
              <div className="flex items-center justify-center">
                <button className="ml-1 bg-gradient rounded-full flex items-center p-2 justify-center w-72 sm:w-[22.5rem]" onClick={() => handleCourses(profileData.id)}>
                  <TbCertificate size={20} className="mr-2 text-white font-semibold lg:w-6 lg:h-6" />
                  <span className="text-white font-medium text-[14px] sm:text-[16px]">Enroll to Course</span>
                </button>
              </div>
              <div className="flex items-center justify-center gap-1 mt-1 w-full">
                <button className="border-2 border-orange-400 rounded-full flex items-center p-2 w-40 sm:w-44 lg:w-44 justify-center" onClick={handleViewProfile}>
                  <IoPersonCircleSharp size={20} className="mr-2 text-orange-400 font-semibold lg:w-6 lg:h-6" />
                  <span className=" text-gradient font-semibold text-[13px] sm:text-[16px] lg:text-[18px]">View Profile</span>

                </button>
                <button className="ml-1 border-2 border-orange-400 rounded-full flex items-center p-2 w-40 lg:w-44 justify-center" onClick={handleshare}>
                  <TbUpload className="mr-2 text-orange-400 lg:w-6 lg:h-6" size={20} />
                  <span className="text-gradient font-semibold text-[13px] sm:text-[16px] lg:text-[18px]">Share</span>

                </button>
              </div>
            </div>) :
            (<div className="flex flex-col gap-1">
              <div className="flex items-center justify-center gap-1 mt-1 w-full" onClick={handleshareTeacher}>
                <button className="ml-1 bg-gradient rounded-full flex items-center p-2 w-72 justify-center">
                  <TbUpload size={20} className="mr-2 text-white font-semibold lg:w-6 lg:h-6" />
                  <span className="text-white font-medium text-[14px] sm:text-[16px] lg:text-[18px]">Share Calendar</span>
                </button>
              </div>
              <div className="flex items-center justify-center gap-1 mt-1 w-full">
                <button
                  className="ml-1 border-2 border-orange-400 rounded-full flex items-center p-2 w-40 sm:-[10.5rem] lg:w-44 justify-center"
                  onClick={handleViewProfile}
                >
                  <IoPersonCircleSharp size={20} className="mr-1 text-orange-400 font-semibold lg:w-6 lg:h-6" />
                  <span className=" text-gradient font-semibold text-[13px] sm:text-[15px] lg:text-[18px]">
                    View Profile
                  </span>
                </button>
                <button className="ml-1 border-2 border-orange-400 rounded-full flex items-center p-2 w-40 sm:w-[10.5rem] lg:w-44 justify-center" onClick={() => handleStartChat(profileData.id)}>
                  <BsFillChatDotsFill className="mr-2 text-orange-400 lg:w-6 lg:h-6" />
                  <span className=" text-gradient font-semibold text-[13px] sm:text-[15px] lg:text-[18px]">Start a Chat</span>

                </button>
              </div>
            </div>)}

        </div>
      </div>
    </div>
  );
};

export default ProfileModal;