import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import axios from "axios";
import Empty_data2 from '../../Empty_data2';
import { BsPersonCircle, BsCalendarDateFill } from "react-icons/bs";
import { SiGoogleclassroom } from "react-icons/si";
import { IoMdTime } from "react-icons/io";
import { IoIosShareAlt } from "react-icons/io";
import { MdGroupAdd } from "react-icons/md";
import Loading2 from '../../Loading2';
import moment from 'moment-timezone';
import ConferenceStudentsModal from '../../../Modals/conferenceStudentsModal';

const UpcomingEventsCourses = () => {
  const authToken = Cookies.get("authToken");
  const [cards, setCards] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const user_type = Cookies.get('user_type')
  const [isClickable, setIsClickable] = useState(true);
  const user_loggedInId = Cookies.get('loggedInUserId');
  const [user_time, setUser_time] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [earning, setEarning] = useState('')
  const [isActive, setIsActive] = useState(false);
  const [earning_ids, setEarning_id] = useState('')

  useEffect(() => {
    fetch_upcoming_courses();
  }, [authToken])

  const fetch_upcoming_courses = async () => {
    try {
      let enrichedData = [];
      if (user_type === 'Teacher') {
        const response = await axios.get(`${apiUrl}/api/courses/get_upcoming_courses`, {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        });

        const datas = response.data;

        enrichedData = await Promise.all(
          datas.map(async (data) => {
            let earning_ids = [];

            // Fetch earnings details
            const earnings_details = await axios.post(
              `${apiUrl}/api/earningCourse/fetch_earning_details`,
              { course_Id: data._id },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${authToken}`,
                },
              }
            );

            if (earnings_details.data.length >= 1) {
              earning_ids = earnings_details.data.map((earning) => earning._id);
            }

            // Fetch number of students booked
            const students_response = await axios.post(
              `${apiUrl}/api/courses/get_no_students_booked`,
              { course_Id: data._id },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${authToken}`,
                },
              }
            );

            return {
              ...data,
              earningDetails: earning_ids.length > 0 ? earning_ids : null,
              No_of_Students: students_response.data,
            };
          })
        );
      }

      // Setting the enriched data to state
      setCards(enrichedData);

      // Set isActive state
      setIsActive(new Array(enrichedData.length).fill(false));

      setPageLoading(false);
      console.log("Enriched Data ===> ", enrichedData);
    } catch (error) {
      console.error("Error fetching upcoming courses: ", error);
      setPageLoading(false); // Ensure the loading state is turned off even if an error occurs
    }
  };

  const formatTime = (startTime, endTime) => {
    //console.log("Start time before conversion ==>", startTime);
    const startParts = startTime.split(':').map(part => parseInt(part));
    const startDate = new Date();
    startDate.setHours(startParts[0], startParts[1], 0, 0);


    const startOptions = { timeZone: user_time, hour12: false, hour: '2-digit', minute: '2-digit' };
    const formattedStartTime = new Intl.DateTimeFormat('en-US', startOptions).format(startDate);

    //console.log("End time before conversion ==>", endTime);
    let formattedEndTime = null;
    if (endTime) {
      const endParts = endTime.split(':').map(part => parseInt(part));
      const endDate = new Date();
      endDate.setHours(endParts[0], endParts[1], 0, 0);


      const endOptions = { timeZone: user_time, hour12: false, hour: '2-digit', minute: '2-digit' };
      formattedEndTime = new Intl.DateTimeFormat('en-US', endOptions).format(endDate);
      //console.log(user_time);
      //console.log("Start and end time after conversion ==>", formattedStartTime, formattedEndTime);
    }

    return `${formattedStartTime} - ${formattedEndTime}`;
  };

  const ShowStudentDetails = (event) => {
    console.log("students", event.No_of_Students.students)
    event.No_of_Students.students.map((student, index) => {
      // console.log(`Student ${index + 1}:`);
      // console.log('Student Details ===>', student.studentDetails)
      // console.log('User Details ==>', student.userDetails)

      // Add more fields as needed
    });
    setModalData(event.No_of_Students.students);
    setIsModalOpen(true);
  }


  const formatDateStringInTimeZone = (dateString, timeZone) => {
    return moment.tz(dateString, timeZone).format('YYYY-MM-DD');
  };

  const formatDateStringInTimeZone2 = (dateString, startTime, teacherTimeZone, studentTimeZone) => {
    // Combine the date and time into a single string in the format "YYYY-MM-DDTHH:mm"
    const dateTimeString = `${dateString}T${startTime}`;

    // Convert the combined date and time string from the teacher's time zone to UTC
    const utcDateTime = moment.tz(dateTimeString, teacherTimeZone).utc();

    // Convert the UTC time to the student's time zone
    const convertedDateTime = utcDateTime.clone().tz(studentTimeZone);

    // Format the converted date (only date)
    const convertedDate = convertedDateTime.format('YYYY-MM-DD');

    console.log("Original Date:", dateString);
    console.log("Original Start Time:", startTime);
    console.log("Converted Date in Student's Time Zone:", convertedDate);

    // Return the converted date
    return convertedDate;
  };

  const handleMeeting = async () => {

  };
  const baseUrl = window.location.origin;
  const handleshare = async (id) => {
    console.log("course id", id)
    if (isClickable) {
      if (navigator.share) {

        navigator.share({
          title: 'Check out this Course Details!',
          text: 'Course Details',
          url: `${baseUrl}/course_details/${id}`,
        })
          .then(() => console.log('Shared successfully'))
          .catch((error) => console.error('Error sharing:', error));
      } else {
        alert('Sharing is not supported on this browser.');
      }
    }
  };

  if (pageLoading) {
    return <Loading2 />
  }
  // if(cards.length === 0){

  //     return<Empty_data2/>
  // }
  return (
    <div className="overflow-y-auto w-full lg:w-full flex flex-col items-center font-primary h-screen mb-14 ml-1 mr-2" style={{ maxHeight: 'calc(100vh - 140px)' }}>
      {cards.map((event, index) => (
        <div
          key={event.id}
          className="rounded-[40px] p-[20px] mb-6 mt-1 flex flex-col w-[95%] sm:w-[90%] lg:w-[80%] lg:mr-2 "
          style={{ boxShadow: '0px 2px 6px 0px #00000040' }}
        >
          <div className="pb-[20px]">
            <div className="flex items-center pb-[8px]">
              <SiGoogleclassroom className='text-orange-400 text-[1.2rem]' />
              <p className="pl-[5px] text-sm sm:text-base font-normal text-[#585858]">
                {user_type === 'Student' ? (
                  <div>Title: {event.conferenceData.Title}</div>
                ) : (
                  <div>Title: {event.Title}</div>
                )}
              </p>
            </div>
            {user_type === 'Student' ? (
              <div className="flex items-center mt-3">
                <BsPersonCircle className='text-orange-400 sm:text-[1.2rem] text-[1rem]' />
                <p className="pl-[5px] text-sm sm:text-base font-normal text-[#585858]">

                  <div>Teacher Name: {event.conferenceData.userId.fullName}</div>

                </p>
              </div>) : ('')
            }
            <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 sm:justify-between mt-3">
              <div className="flex items-center">
                <BsCalendarDateFill className='text-orange-400 text-[1.2rem]' />
                <p className="pl-[5px] text-sm sm:text-base font-normal text-[#585858] pr-[8px]">
                  {user_type === 'Student' ? (
                    <div>
                      Date: {formatDateStringInTimeZone2(event.conferenceData.Start_Date, event.conferenceData.Start_Time, event.conferenceData.teacher_time_zone, user_time)}
                    </div>
                  ) : (
                    <div>
                      Date: {formatDateStringInTimeZone(event.Start_Date, event.Start_Time, user_time)}
                    </div>
                  )}
                </p>              </div>
              <div className="flex items-center">
                <IoMdTime className='text-orange-400 text-[1.2rem]' />
                <p className="pl-[5px] text-sm sm:text-base font-normal text-[#585858]">
                  {user_type === 'Student' ? (
                    <>
                      {event.conferenceData.teacher_time_zone !== user_time ? (
                        <div>
                          {`Time: ${formatTime(event.conferenceData.Start_Time)}`}
                        </div>
                      ) : (
                        <div>
                          {`Time: ${event.conferenceData.Start_Time} `}
                        </div>
                      )}
                    </>
                  ) : (
                    <div>
                      {`Time: ${event.Start_Time} `}
                    </div>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className='justify-center items-center'>
            {user_type === 'Teacher' ? (
              <div className='flex flex-col lg:flex-row justify-center items-center  gap-2 '>
                {event.No_of_Students.studentCount !== 0 ? (
                  <>
                    <div className='flex justify-center items-center w-60 sm:w-64 px-0 py-1.5 sm:py-2.5 bg-gradient text-white text-center rounded-full cursor-pointer' title='Students List' onClick={() => ShowStudentDetails(event)}>
                      No. of Students Booked: {event.No_of_Students.studentCount}
                    </div>
                    <a
                      href={isActive[index] ? event.URL : "#"}
                      target={isActive[index] ? "_blank" : ""}
                      rel="noopener noreferrer"
                      className={`inline-flex items-center justify-center w-60 sm:w-64 px-0 py-1.5 sm:py-2.5 bg-gradient text-white text-center rounded-full cursor-pointer  ${!isActive[index] ? 'opacity-50 pointer-events-none' : ''}`}
                      onClick={isActive[index] ? () => handleMeeting(event.earningDetails) : undefined}
                    >
                      <i className="fas fa-video"></i>
                      Start
                    </a>
                    <div className='inline-flex items-center gap-2 justify-center w-60 sm:w-64 px-0 py-1.5 sm:py-2.5 border-2 border-orange-400 text-center cursor-pointer rounded-full bg-transparent text-center text-orange-500 hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 ' onClick={() => handleshare(event._id)}>
                      <IoIosShareAlt style={{ fontSize: '1rem' }} /><span className='font-medium text-sm lg:text-md w-36' >Share Course</span>
                    </div>
                  </>
                ) : (
                  <div className="ml-[13%] lg:ml-52 text-gray-500">
                    No one student booked the conference
                  </div>
                )}
              </div>
            ) : (
              <div className='flex flex-col sm:flex-row  justify-center items-center gap-2'>
                <div className={`border-2 border-orange-600 rounded-3xl py-2 px-6 bg-transparent text-orange-500 hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-transparent  w-32 flex justify-center shadow-lg gap-2 items-center cursor-pointer ${!isActive[index] ? 'opacity-50 pointer-events-none' : ''} `}><MdGroupAdd /><span className='font-medium text-sm lg:text-md'>
                  <a
                    href={isActive[index] ? event.conferenceData.URL : "#"}
                    target={isActive[index] ? "_blank" : ""}

                    rel="noopener noreferrer">
                    Join
                  </a>
                </span></div>
                <div className='border-2 border-orange-600 rounded-3xl py-2 px-6  bg-transparent text-orange-500 hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-transparent  flex gap-1 cursor-pointer shadow-lg' onClick={() => handleshare(event._id)}>
                  <IoIosShareAlt style={{ fontSize: '1rem' }} /><span className='font-medium text-sm lg:text-md w-36' >Share Course</span>
                </div>
              </div>
            )}
          </div>

        </div>
      ))}
      <ConferenceStudentsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        students={modalData} />
    </div>
  )
}

export default UpcomingEventsCourses