import React, { useState, useEffect } from 'react'
import Header from '../../headerBackground';
import MainMenu from '../../MenuBottom/MainMenu';
import Cookies from "js-cookie";
import moment from 'moment-timezone';
import '../../Call_And_Conference/calender.css'
import NavStudent from '../../nav/navStudent';
import axios from 'axios';
import { BsChevronLeft } from "react-icons/bs";
import { useLocation, useParams } from 'react-router-dom';
import Calendar from 'react-calendar';
import { useNavigate } from 'react-router-dom';
import {
    BsEmojiSmileFill,
    BsFillEmojiNeutralFill,
    BsFillEmojiWinkFill,
    BsEmojiFrownFill, BsFillEmojiLaughingFill,
} from "react-icons/bs";
import rating from '../../../assets/img/rating-5.png';
import NavBar from '../../nav/navBar';

const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
};


const Student_Call_Book_list = ({ setMessage, setShowMessage }) => {
    const { id } = useParams();
    // console.log(id)
    const [date, setDate] = useState(getTomorrowDate());
    const [currentMonth, setCurrentMonth] = useState('');
    const [currentShortDate, setCurrentShortDate] = useState('');
    const [calendarDays, setCalendarDays] = useState([]);
    const [currentDayIndex, setCurrentDayIndex] = useState(null);
    const [original_amount, setAmount] = useState(0);
    const [teacher_time_zone, Set_teacher_time_zone] = useState();
    const [tax_amount, setTaxAmount] = useState(0);
    const [serviceCharge, setServiceCharge] = useState(0);
    const [gst, setGst] = useState(0);
    const [user_data, setUser_data] = useState('');
    const loggedInUserId = Cookies.get('loggedInUserId');
    const [user_teacher, setUser_teacher] = useState();
    const [loading, setLoading] = useState(false)
    const [profileData, setProfileData] = useState();
    const [isIndia, setIsIndia] = useState()
    const [teacher_country, set_Teacher_country] = useState('')
    const file_url = 'https://cgb-storage.blr1.digitaloceanspaces.com';
    let defaultProfile = `${file_url}/profile-pictures/defaultProfile.png`

    //     const location = useLocation();
    //   const profileData = location.state.profileData;
    //   console.log("profile data", profileData);
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    //setDate(tomorrow)
    const fifteenDaysLater = new Date();
    fifteenDaysLater.setDate(fifteenDaysLater.getDate() + 15);

    const apiUrl = process.env.REACT_APP_API_URL;
    // FUNCTION TO PRINTS THE WEEK OF THE MONTH

    useEffect(() => {
        const fetchData = async () => {
            displayCurrentWeek();


            try {
                const response = await axios.post(`${apiUrl}/api/teacher/get-teacher-details_id/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                const profileData = response.data;
                //console.log("Teacher Profile ===> ",profileData);
                set_Teacher_country(profileData.userDetails.country)
                Set_teacher_time_zone(profileData.userDetails.time_zone)
                // console.log(profileData.userDetails.time_zone)
                setProfileData(profileData);
            } catch (error) {
                console.error('Error fetching teacher details:', error);
            }

        };

        fetchData();

    }, []);

    useEffect(() => {
        console.log("Profile Data", profileData);
        //console.log(profileData.teacher.profilePic)
        fetchUserDetails(setIsIndia);
    }, [profileData]);
    //console.log('Active Button:', activeButton);

    const fetchUserDetails = async (setIsIndia) => {
        try {
            const response = await axios.get(`${apiUrl}/api/auth/user_details/${user_loggedInId}`);
            const user = response.data.user_Details;
            console.log(user.country)
            if (!user.country) {
                console.error('No country name found in user details');
                return; // Exit early if no country name is available
            }
            if (user.country === 'India') {
                setIsIndia(true);
            } else {
                setIsIndia(false);
            }

        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };


    const displayCurrentWeek = () => {
        const currentDate = new Date();
        const currentMonthFullName = getCurrentMonthName(currentDate.getMonth())[0];
        const currentMonthShortName = getCurrentMonthName(currentDate.getMonth())[1];
        const currentDay = currentDate.getDate();
        const currentDayOfWeek = getDayOfWeekName(currentDate.getDay(), false); // Full day name
        const dayIndex = currentDate.getDay();

        setCurrentMonth(currentMonthFullName);
        setCurrentShortDate(`${currentMonthShortName} ${currentDay} - ${currentDayOfWeek}`);
        setCurrentDayIndex(dayIndex);

        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());

        const days = [];
        for (let i = 0; i < 7; i++) {
            const day = new Date(startOfWeek);
            day.setDate(startOfWeek.getDate() + i);
            days.push(day);
        }

        setCalendarDays(days);
    };

    const getCurrentMonthName = (monthIndex) => {
        const months = [
            ["January", "Jan"], ["February", "Feb"], ["March", "Mar"], ["April", "Apr"],
            ["May", "May"], ["June", "Jun"], ["July", "Jul"], ["August", "Aug"],
            ["September", "Sep"], ["October", "Oct"], ["November", "Nov"], ["December", "Dec"]
        ];
        return months[monthIndex];
    };

    const getDayOfWeekName = (dayIndex, abbreviated = true) => {
        const abbreviatedDaysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        if (abbreviated) {
            return abbreviatedDaysOfWeek[dayIndex];
        } else {
            return daysOfWeek[dayIndex];
        }
    };

    const authToken = Cookies.get('authToken');
    const user_type = Cookies.get('user_type');
    const user_loggedInId = Cookies.get('loggedInUserId')
    const [timeSlot, setTimeSlot] = useState([]);

    const [formattedDate, setFormattedDate] = useState(formatDateToYYYYMMDD(date));
    const [userTimeZone, setUserTimeZone] = useState();
    const navigate = useNavigate();
    const [times, setTimes] = useState([{ start_time: '', end_time: '', status: '', conference_id: '', amount: '', currency: '', }]);
    const [fetchCompleted, setFetchCompleted] = useState(false);
    //setDate(tomorrow)
    // FETCH THE TIME SLOT STATUS OF THE PARTICULAR USER(TEACHER)

    useEffect(() => {
        const fetchData = async () => {
            await Fetch_Time_Slots();
        };

        fetchData();
        fetch_user_Details();

    }, [date]);

    useEffect(() => {
        if (fetchCompleted) {
            handleTimeConversion();
        }
    }, [fetchCompleted]);

    useEffect(() => {
        setFetchCompleted(false);
    }, [date]);


    // FORMATING DATE IN THE FORMAT YYYY-DD-MM

    function formatDateToYYYYMMDD(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    const fetch_user_Details = async () => {
        try {

            const user_details = await axios.get(`${apiUrl}/api/auth/user_details/${user_loggedInId}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            })
            const user = user_details.data;
            //  console.log(user);
            // console.log("Name", user.user.fullName)
            // console.log("Eamil", user.user.email)
            setUser_data(user);


        } catch (error) {
            console.error('Server Error:', error.response.data);
        }
    }




    // BACKEND API CALL TO GET THE TIME SLOT STATUS OF USER(TEACHER)

    const Fetch_Time_Slots = async () => {
        try {
            console.log("Teacher Id ==>", id)
            const response = await axios.post(
                `${apiUrl}/api/calender/teacher_slots/${id}`,
                {
                    date: formatDateToYYYYMMDD(date),
                },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            const data = response.data;
            //console.log("user Data ==>",data)
            setUserTimeZone(data.user_data);
            //console.log("Time Zone ===>",data.user_data)

            let timeSlots = [];

            for (const slot of data.data) {
                let slotDetails = {
                    start_time: slot.start_time,
                    end_time: slot.end_time,
                    status: slot.status,
                    conference_id: slot.conference_id,
                    id: slot._id,
                    date: slot.date
                };

                if (slot.call_id) {

                    // GET CALL DETAILS

                    const callResponse = await axios.get(
                        `${apiUrl}/api/call/details/${slot.call_id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${authToken}`,
                                'Content-Type': 'application/json',
                            },
                        }
                    );
                    const callDetails = callResponse.data;
                    //console.log(callDetails)
                    const call_id = callDetails._id
                    slotDetails = { ...slotDetails, ...callDetails.Price, ...callDetails.currency, call_id };
                }

                timeSlots.push(slotDetails);
            }

            data.remainingTimeSlots.forEach((startTime) => {
                timeSlots.push({
                    start_time: startTime,
                    end_time: '',
                    status: 'unavailable',
                });
            });
            timeSlots = timeSlots.sort((a, b) => moment(a.start_time, 'HH:mm').diff(moment(b.end_time, 'HH:mm')));
            setTimes(timeSlots);

            setFetchCompleted(true);
        } catch (error) {
            if (error.response) {
                console.error('Server Error:', error.response.data);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Request Error:', error.message);
            }
        }
    };
    const handleTimeConversion = async () => {
        //  console.log("User Time Zone (Student):", user_data.user_Details.time_zone);
        // console.log("Teacher Time Zone:", teacher_time_zone);

        const Student_Time_Zone = user_data.user_Details.time_zone;
        const Teacher_Time_Zone = teacher_time_zone;

        if (Student_Time_Zone !== Teacher_Time_Zone) {
            const convertTime = (time, format, date) => {
                console.log(Teacher_Time_Zone, Student_Time_Zone)
                return moment.tz(time, format, Teacher_Time_Zone).tz(Student_Time_Zone);
            };

            const timesToUse = times.map(({ start_time, end_time, status, conference_id, id, call_id, currency, amount, currencyCode, date, paypal_fee }) => {
                var displayStartTime;
                var displayEndTime
                if (status === 'booked' || status === 'available') {
                    const convertedStartTime = convertTime(start_time, 'HH:mm', date);
                    const convertedEndTime = end_time ? convertTime(end_time, 'HH:mm', date) : null;

                    displayStartTime = convertedStartTime.format('HH:mm');
                    displayEndTime = convertedEndTime ? convertedEndTime.format('HH:mm') : null;
                    //  console.log(`Original Start Time: ${start_time}, Converted Start Time: ${displayStartTime}`);
                    // console.log(`Original End Time: ${end_time}, Converted End Time: ${displayEndTime}`);
                } else {
                    displayStartTime = start_time;
                    displayEndTime = end_time;
                }
                if (!isIndia && teacher_country === 'India') {
                    amount = ((Number(amount) / 83));
                    currency = '$';
                    currencyCode = 'USD'
                    paypal_fee = (!isIndia && teacher_country === 'India') ? 0.30 : 0
                }
                const original_amount = amount
                if (amount !== undefined) {
                    setAmount(Number(amount));
                    amount = CalculateAmount(Number(amount), paypal_fee);
                    console.log("Total_amount", amount)
                    setTaxAmount(amount);
                }

                return {
                    displayStartTime,
                    displayEndTime,
                    status,
                    conference_id,
                    id,
                    call_id,
                    currency,
                    amount,
                    currencyCode,
                    original_amount,
                    paypal_fee
                };
            });

            timesToUse.sort((a, b) => moment(a.displayStartTime, 'HH:mm').diff(moment(b.displayStartTime, 'HH:mm')));

            await setTimes(timesToUse);
            // await console.log("Converted times:", timesToUse);
        } else {
            const timesSorted = times.map(({ start_time, end_time, status, conference_id, id, call_id, currency, amount, currencyCode, date, paypal_fee }) => {
                var displayStartTime = start_time;
                var displayEndTime = end_time;

                const original_amount = amount;
                if (amount !== undefined) {
                    setAmount(Number(amount));
                    amount = CalculateAmount(Number(amount), Number(paypal_fee));
                    setTaxAmount(amount);
                }

                return {
                    displayStartTime,
                    displayEndTime,
                    status,
                    conference_id,
                    id,
                    call_id,
                    currency,
                    amount,
                    currencyCode,
                    original_amount,
                    paypal_fee
                };
            });

            timesSorted.sort((a, b) => moment(a.start_time, 'HH:mm').diff(moment(b.start_time, 'HH:mm')));

            await setTimes(timesSorted);
            // console.log("No time conversion needed, times:", timesSorted);
        }
    };

    // BACK NAVIGATION TO THE CABINET PAGE

    const handleBack = () => {
        navigate("/cabinet")
    }

    const handleConference = (id) => {
        navigate(`/teacher_conferences/${id}`, { state: { profileData } })
    }


    const handlePayment = async (st, et, date, callId, id, Currency, currencyCode, amounts, original_amount, paypal_fee) => {
        if (original_amount === '0') {
            const Id = callId;
            const slot_id = id;
            await bookCall(Id, slot_id);
        }
        else {
            // console.log("callId in Frontend", callId)
            const amount = Number(original_amount);
            const serviceCharge = calculateServiceCharge(amount);
            const gst = calculateGST(amount);
            const totalAmount = CalculateAmount(amount, Number(paypal_fee));

            const invoice_details = {
                Name: user_data.user.fullName,
                Email: user_data.user.email,
                Start_Time: st,
                End_Time: et,
                Date: date,
                call_Id: callId,
                slot_id: id,
                Service_Charges: serviceCharge,
                GST: gst,
                Call_Price: Math.round(amount),
                Total_amount: totalAmount,
                Type: "Call Details",
                PaymentType: "Call",
                Currency: Currency,
                CurrencyCode: currencyCode,
                paypal_fee: paypal_fee

            };
            // console.log(invoice_details);
            navigate("/invoice-details", { state: { invoice_details } })
        }
    };


    const bookCall = async (callId, slotId) => {
        try {
            setLoading(true)
            //   console.log("Book call id ==> ", callId);

            const callResponse = await axios.put(
                `${apiUrl}/api/call/call_book/${slotId}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );

            //   console.log(callResponse.data);

            if (callResponse.status === 200) {
                // console.log('Haii...')
                await Call_Booked(callId)

            }
            navigate('/cabinet');
        } catch (error) {

            console.error('Server Error:', error.response.data);
        } finally {
            setLoading(false)
        }
    };
    const Call_Booked = async (id) => {
        const response = await axios.post(`${apiUrl}/api/call/${id}/call_booked`,
            { studentId: user_loggedInId, paymentId: 'Free', amountPaid: tax_amount },
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        const data = response.data;
        //   console.log(data);
        navigate('/cabinet');
        setShowMessage(true);
        setMessage(`Call Booked`);
    }




    // TOTAL PRICE CALCULATION INCLUDING SERVICE CHARGES AND GST

    // const SERVICE_CHARGE_PERCENTAGE = isIndia ? 0.05 : 0.09; // 5%
    const SERVICE_CHARGE_PERCENTAGE = 0.09;
    const GST_PERCENTAGE = isIndia ? 0.18 : 0.15; // 18%

    const calculateServiceCharge = (amount) => {
        // console.log("modified amount ==>",amount * SERVICE_CHARGE_PERCENTAGE)
        setServiceCharge(Math.ceil(amount * SERVICE_CHARGE_PERCENTAGE))
        return Math.ceil(amount * SERVICE_CHARGE_PERCENTAGE);

    };
    const calculateGST = (amount) => {
        setGst(Math.ceil(amount * GST_PERCENTAGE))
        //console.log(amount,GST_PERCENTAGE)
        //console.log("Updated GST",amount * GST_PERCENTAGE)
        return Math.ceil(amount * GST_PERCENTAGE);
    };

    const CalculateAmount = (amount, paypal_fee) => {
        //console.log(amount,paypal_fee)
        const serviceCharge = calculateServiceCharge(amount);
        //console.log("service Charges ==> ",serviceCharge)
        const updated_amount = Number(amount) + serviceCharge + Number(paypal_fee)
        //console.log("updated amount ==> ",updated_amount)
        const gst = calculateGST(updated_amount);
        //console.log("GST ==>",gst)
        const totalAmount = Math.ceil(Number(amount) + serviceCharge + gst + paypal_fee);
        //console.log("Total Amount ==>",totalAmount)
        return totalAmount
    }




    const emojiComponents = {
        '1': <BsEmojiFrownFill className="text-orange-500" />,
        '2': <BsFillEmojiNeutralFill className="text-orange-500" />,
        '3': <BsFillEmojiWinkFill className="text-orange-500" />,
        '4': <BsEmojiSmileFill className="text-orange-500" />,
        '5': <BsFillEmojiLaughingFill className="text-orange-500" />,
    };



    return (
        <section className="mb-0 sm:mb-0 font-primary min-h-[100vh] ">
            {/* ------------------------ NAVBAR --------------------- */}
            <NavBar />
            <div className='sm:mt-10 lg:mt-16 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5'>
                <div className='md:grid grid-cols-6 xl:grid-cols-8 gap-4 sm:min-h-[85vh] sm:py-[25px] justify-center'>
                    <div class="col-span-2 h-full min-h-full hidden sm:flex justify-center items-start pt-5 sm:pb-5 md:pb-0">
                        {profileData ? (<div
                            class="sm:rounded-[50px] sm:shadow-md sm:w-7/12 md:w-11/12 sm:border-2 sm:h-fit sm:border-orange-500 sm:flex flex-col items-center justify-center sm:px-3 lg:px-8 sm:py-5">
                            <div class="flex flex-col justify-center items-center mb-4">
                                <div class="w-[90px] border h-[90px] rounded-full">
                                    <img className="h-full w-full overflow-hidden rounded-full" src={profileData.teacher.profilePic || defaultProfile} alt="" />
                                </div>
                                <div class="flex gap-2 justify-center items-center px-2 py-1  rounded-full border border-orange-500 mt-2">
                                    <p class="font-medium text-[15px] text-gradient">
                                        {profileData.userDetails.rating}

                                    </p>
                                    {emojiComponents[Math.round(profileData.teacher.rating)]}

                                </div>
                                <div className='flex flex-col gap-1 items-center justify-center'>
                                    <h1 className="font-semibold text-2xl text-gradient text-center">{profileData.teacher.fullName}</h1>
                                    <p className="text-gradient text-sm font-medium">{profileData.teacher.email}</p>
                                    <p className="text-[#585858] text-md font-medium text-center">Country: <span className="font-normal text-[#585858]">{profileData.userDetails.country}</span>, City:
                                        <span className="font-normal text-md text-[#585858]">{profileData.userDetails.city}</span>
                                    </p>
                                    <p className="text-[#585858] font-medium">Timezone: <span
                                        className="font-normal text-[#585858]">{profileData.userDetails.time_zone}</span>
                                    </p>
                                </div>

                            </div>
                            <div class="flex flex-col gap-4 items-center justify-center sm:w-full px-8 sm:px-0">
                                <button type="submit" class="mr-3 sm:mr-0 bg-gradient rounded-full text-white py-2 px-4 w-full">
                                    Book a Call
                                </button>
                                <button type="submit" class="mr-3 sm:mr-0 border border-orange-500 font-medium text-gradient rounded-full text-white py-2 px-4 w-full" onClick={() => handleConference(profileData.teacher._id)}>
                                    Book a Conference
                                </button>
                            </div>
                        </div>) : (<div>Loading.....</div>)}
                    </div>

                    <div class="col-span-4 xl:col-span-6 mt-[200px] lg:mt-0">
                        <div>
                            <Header />
                            {/* -------------------------- BACK NAVIGATION ---------------------------------- */}
                            <div class="flex flex-col sm:flex-row items-start gap-2 pt-5">
                                <div class="flex flex-row items-center gap-2 mt-[-40px] sm:mt-0 ml-2 cursor-pointer" onClick={handleBack}>
                                    <BsChevronLeft className='text-[#AFAFAF] text-[14px] text-lg' />
                                    <span class="text-[#afafaf] text-[14px] md:text-lg font-medium">Back</span>
                                </div>

                                <div class="flex-grow text-center w-full flex flex-col items-center my-2 font-semibold text-gradient text-[14px] sm:text-lg md:text-xl">
                                    <span>Book a call now! </span>
                                    <span>Resolve your doubts and learn more</span>
                                </div>
                            </div>
                        </div>
                        <div className='sm:drop-shadow-md sm:rounded-[50px] bg-white xl:grid grid-cols-2 md:px-5 md:h-fit md:py-5 px-3'>
                            <div className='flex xl:flex-col justify-center xl:justify-start items-center'>
                                <div >

                                    <h1 id="month-header" className="hidden xl:block mb-5 px-[25px] py-[15px] shadow-md text-gradient rounded-full text-[#585858] text-lg font-bold" >{currentMonth}
                                    </h1>

                                </div>

                                <div className='flex justify-center items-center w-full'>
                                    <Calendar
                                        onChange={setDate}
                                        value={getTomorrowDate()}
                                        minDate={tomorrow}
                                        maxDate={fifteenDaysLater}
                                        showNavigation={false}
                                        className=''
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col gap-2 font-primary sm:rounded-[50px] mt-16 sm:mt-0">
                                <div className=" sm:max-w-none lg:h-[450px] mx-auto sm:mx-0 w-full overflow-y-auto">
                                    <div className='flex flex-col  justify-center lg:mt-0 w-full lg:w-full'>
                                        <div className='time-lines'>
                                            <div className='mb-4 lg:h-[500px] h-[280px] lg:mt-0'>
                                                {fetchCompleted &&
                                                    times.map((timeSlot, index) => (
                                                        <div
                                                            key={index}
                                                            className={`time_line text-xs ${timeSlot.status === 'booked'
                                                                ? 'booked'
                                                                : timeSlot.status === 'available'
                                                                    ? 'available'
                                                                    : 'hover:cursor-not-allowed'
                                                                }`}>
                                                            <div className='text-xs  flex flex-row items-center'>
                                                                {/* {user_data.user_Details.time_zone !== teacher_time_zone?(
                                                                    <div>
                                                                        {timeSlot.displayStartTime}{' '}
                                                                        {timeSlot.displayEndTime ? `- ${timeSlot.displayEndTime}` : ''}
                                                                    </div>
                                                                ):(
                                                                    <div>
                                                                        {timeSlot.start_time}{' '}
                                                                        {timeSlot.end_time ? `- ${timeSlot.end_time}` : ''}
                                                                    </div>
                                                                ) } */}

                                                                {timeSlot.displayStartTime}{' '}
                                                                {timeSlot.displayEndTime ? `- ${timeSlot.displayEndTime}` : ''}

                                                            </div>
                                                            <hr className={`h-px  ${timeSlot.status === 'booked' || timeSlot.status === 'available' ? 'w-2/5 lg:w-3/5' : 'w-4/5 lg:w-4/5 '}`} />

                                                            {timeSlot.status === 'available' ? (
                                                                <>
                                                                    <div className=' ml-0 mr-1 lg:ml-3 lg:mr-2 text-xs w-3/12'>
                                                                        {/* Price: {timeSlot.currency}
                                                                        {timeSlot.amount} */} Price: {timeSlot.amount === 0 ? 'Free' : `${timeSlot.currency} ${timeSlot.amount}`}
                                                                    </div>
                                                                    {loading ? (<div className="spinner-border animate-spin inline-block w-4 h-4 border-4 rounded-full border-t-transparent border-red-500" role="status"></div>) : (
                                                                        <div className='text-[12px] bg-white text-black font-medium text-center w-4/12 py-1 px-2 rounded-full hover:bg-red-500 hover:text-white ' onClick={() => handlePayment(
                                                                            timeSlot.displayStartTime,
                                                                            timeSlot.displayEndTime,
                                                                            formattedDate,
                                                                            timeSlot.call_id,
                                                                            timeSlot.id,
                                                                            timeSlot.currency,
                                                                            timeSlot.currencyCode,
                                                                            timeSlot.amount,
                                                                            timeSlot.original_amount,
                                                                            timeSlot.paypal_fee
                                                                        )}> Book Now
                                                                        </div>)}
                                                                </>
                                                            ) : null}
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='flex justify-center'>
                <MainMenu />
            </div>
        </section>

    )
}

export default Student_Call_Book_list