import React from 'react'
import NavHome from '../../components/nav/navHome';
import Footer from '../../components/nav/footer';
import Header from '../../components/headerBackground'
import Cookies from "js-cookie";
import NavBar from '../../components/nav/navBar';
import terms from '../../assets/img/terms.PNG';


const Terms_and_conditions = () => {
  const isAuthenticated = Cookies.get("authToken") !== undefined;
  // const url = process.env.REACT_APP_API_URL;
  return (
    <div className=''>
      {isAuthenticated ? <NavBar /> : <NavHome />}
      <section className="mb-0 sm:mb-0 font-primary ">
        <Header />
        <div className='sm:mt-20 lg:mt-28 mt-[160px] mx-2 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 flex flex-col items-center justify-center gap-4'>
          <h2 className='font-semibold sm:text-2xl lg:text-3xl text-xl text-center text-[#585858]'>TERMS & CONDITIONS</h2>
          <div className='sm:flex flex-cols gap-4 items-start justify-center mt-10 w-full'>
            <div className='sm:w-[40%] w-[100%] flex items-center justify-center'>
              <img src={terms} alt="refund" className='sm:w-[400px] sm:h-[400px] lg:w-[450px] lg:h-[450px] h-[250px] w-[250px]' />
            </div>
            
            <div className='sm:w-[70%] w-full text-base sm:text-base lg:text-lg flex flex-col gap-6 text-left mt-2 mb-6 text-[#585858] ' >
              <h3 className='font-semibold text-center sm:text-start'>CareerGuidanceBuddy is an application developed and owned by CGB ORG</h3>
              <div className='overflow-y-auto pl-2 sm:pl-4'>
                <ol style={{ listStyleType: 'decimal', paddingLeft: '20px' }} className='font-medium sm:flex flex-col gap-6 '>                 
                  <li>
                    <h3 className='font-medium'>INTRODUCTION</h3>
                    <ol type='1' style={{ listStyleType: 'decimal', paddingLeft: '20px' }} className='font-normal'>
                      <li>CGB ORG is a software company which develops software for students, teachers and subject matter experts. CareerGuidanceBuddy is one of the web applications developed by and owned by CBG ORG.</li>
                      <li> CareerGuidanceBuddy provides online platform where Students can search Teachers and Subject Matter Experts, chat or post in the forum, book a call and join conferences or classes to clear their doubts a learn more about different courses online. Teachers can chat, post in the forum, share availability, attend calls and conduct online classes or conferences, live demo, doubt clearing sessions for the student’s seeking advice and guidance. Services are accessible at https://cgborg.com and https://www.careerguidancebuddy.com/ and any other websites through which CareerGuidanceBuddy makes the Services available (collectively, the "Site") and as applications for mobile, tablet and other smart devices and application program interfaces (collectively, the "Applications").</li>
                      <li>By accessing or using the Site, Application or Services or by downloading or posting any content from or on the Site, or by scheduling or opting for calls and classes via the Applications, you would be indicating that you have read, and that you understand and agree to be bound by these terms and receive our Services (“ Terms of Services” or “Terms”), whether or not you have registered with the Site and/or Application.</li>
                      <li>Therefore, please read these Terms of service before accessing or using the Site, Application or Services or downloading or posting any content from or on the Site, via the Application or through the Services, carefully as they contain important information regarding your legal rights, remedies, and obligations.</li>
                      <li>If you do not agree to these Terms, then you have no right to access or use the Site, Application, Services, or Collective Content (as defined below).</li>
                      <li>If you are using the Site, Application or Services then these Terms of Service are binding between you and CGB ORG</li>
                    </ol>
                  </li>
                  <li>
                    <h3 className='font-medium'>DEFINITION</h3>
                    <p className='font-normal'>In Addition to Other Words And Expressions That May Be Defined Elsewhere In These Terms, Unless The Context Otherwise Requires, The Following Capitalized Terms Wherever Used In The Agreement Shall Have The Meanings As Ascribed Here under:</p>
                    <ol type='1' style={{ listStyleType: 'decimal', paddingLeft: '20px' }} className='font-normal'>
                      <li>“Student” means any person of any age who wish to learn more about one or more subjects and registers on CareerGuidanceBuddy’s website or Application.</li>
                      <li>“Parent” means any person who on behalf of their legal dependent wish to learn more about one or more subjects and registers on CareerGuidanceBuddy (CGB ORG)’s website or Application..</li>
                      <li>“Teacher” means any person who wants to teach and assist others to learn and registers on CareerGuidanceBuddy (CGB ORG)’s website or Application.</li>
                      <li> “Subject Matter Expert” means any person who has knowledge and wish to teach and assist others to learn and registers on CareerGuidanceBuddy (CGB ORG)’s website or Application.</li>
                      <li> “Registration” means sharing of information including personal information with CareerGuidanceBuddy (CGB ORG) knowingly that it will be visible to other members and 3rd parties to provide service and accepting CareerGuidanceBuddy’s (CGB ORG)’s Terms and Condition.</li>
                      <li>“Member” means who completes CareerGuidanceBuddy’s account registration process, including but not limited to Teachers/Subject matter Expert and Students/Parents, as described under Account Registration " in Clause 7, 8, 9 and 10 below. </li>
                      <li>“Chat” means any one-to-one communication in written format containing picture, videos, 3rdparty URLs between Parent/Student and Teacher/Subject Matter Expert.</li>
                      <li> “Post” means any one-to-many communications in written format containing picture, videos, 3rd party URLs between Parents, Students, Teachers and Subject Matter Experts.</li>
                      <li> “Call” means one-to-one voice or video communication depending on 3rd party applications like Google Meet, Zoom, or any other which are pre-scheduled between Student/Parent and Techer/Subject Matter Expert. Booking or Scheduling of Calls may involve monetary transaction between the members and CGB ORG.</li>
                      <li> “Conference”/”Class” means one-to-many voice or video communication depending on 3rd party applications like Google Meet, Zoom, or any other which are pre-scheduled between Students/Parents and Techers and/or Subject Matter Experts. Joining or Scheduling of Conference/Class may involve monetary transaction between the members and CGB ORG.</li>
                      <li>“Subscription” means a fee paid by Teacher or Subject Matter Expert to use the services like sharing calendar for calls and creation of conferences/classes on CareerGuidanceBuddy.</li>
                      <li>“Book” means to allocate an available slot on a particular Teacher’s/Subject Matter Expert’s calendar for a limited period of time. It may involve monetary transaction between the members and CGB ORG. </li>
                      <li>“Join” means to stsrt/opt for a particular conference/class scheduled by a teacher. It may involve monetary transaction between the members and CGB ORG. </li>
                      <li>“Call Charge” means the amount of money a Student/Parent has to pay to book a call excluding service charges and taxes.</li>
                      <li> “Joining Fee” means the amount of money a Student/Parent has to pay to join a conference or class excluding service charges and taxes.</li>
                      <li>"Tax" or "Taxes" mean any sales taxes, value added taxes (VAT), goods and services taxes (GST), service tax, that CareerGuidanceBuddy may be required by law to collect and remit togovernmental agencies, and other similar municipal, state, federal and national indirect or other withholding and personal or corporate income taxes. </li>
                      <li>"Collective Content" means Member Content and CareerGuidanceBuddy Content.</li>
                      <li>“Content" means text, graphics, images, music, software (excluding the Application), audio, video, information, or other materials.</li>
                      <li>“Calendar Availability” means Teacher’s/Subject Matter Expert’s availability for Student’s/Parent’s to join over a call.</li>
                      <li> “Listing" means Members who registered as Teachers/Subject Matter Expert or Students/Parents on CareerGuidanceBuddy as available via the Site, Application, and Services.</li>
                      <li> “Member Content" means all Content that a Member posts, uploads, publishes, submits, transmits, or includes in their Listing, Member profile or CareerGuidanceBuddy (CGB ORG)’s promotional campaign to be made available through the Site, Application or Services.</li>
                      <li> “Payment Method" means a payment method that you have added to your CareerGuidanceBuddy (CGB ORG) Account, such as a credit card, debit card or net banking or any other means for monetary transaction.</li>
                      <li>“Service Charge” means the charges collected by CareerGuidanceBuddy (CGB ORG) on transactions happening between Members.</li>
                      <li>“Rating” means after every Call, Conference and Class Students/Parents may rate the Teacher’s/Subject Matter Experts and leave a comment visible to other members.</li>
                      <li> “Student" means a Member who registers on CareerGuidanceBuddy (CGB ORG)’s Application or Site, in case of minor student the parent or guardian who enrol the Student or ward for the Membership on Application or Site.</li>
                      <li>“CareerGuidanceBuddy Content" means all Content that CareerGuidanceBuddy (CGB ORG) makes available through the Site, Application, Services, or its related promotional campaigns and official social media channels, including any Content licensed from a third party, including Students/Parents and Teacher/Subject Matter Expert Content.</li>
                      <li>"Subscription Fee" means the fee paid by the Teachers/Subject Matter Experts while choosing Subscription Type.</li>

                    </ol>
                  </li>
                  <li>
                    <h3 className='font-medium'>GDPR COMPLIANCE STATEMENT</h3>
                    <p className='font-normal'>CareerGuidanceBuddy(CGB ORG) Respects and Complies With The EU General Data Protection Regulations (GDPR). Some Of the Keyways We Comply With These Regulations Are:</p>
                    <ol type='1' style={{ listStyleType: 'decimal', paddingLeft: '20px' }} className='font-normal'>
                      <li>
                        <h3 className='font-medium'>Consent: </h3>
                        <p className='font-normal'>We explain what you’re consenting to clearly and without ‘legalese’, and ask that you explicitly consent to contact from us and our other members.</p>
                      </li>
                      <li>
                        <h3 className='font-medium'>Breach Notification :</h3>
                        <p className='font-normal'>In the event of a breach, we will notify affected users within 72 hours of first having become aware of the breach.</p>
                      </li>
                      <li>
                        <h3 className='font-medium'>Right to Access :</h3>
                        <p className='font-normal'>Users can request confirmation as to whether or not personal data concerning them is being processed, where and for what purpose. Further, we shall provide a copy of the personal data, in an electronic format.</p>
                      </li>
                      <li>
                        <h3 className='font-medium'>Right to be Forgotten :</h3>
                        <p className='font-normal'> Once we have compared your rights to the public interest in the availability of the data, we may delete your personal data where you have requested this.</p>
                      </li>
                      <li>
                        <h3 className='font-medium'>Listing:</h3>
                        <p className='font-normal'>“Listing" means Members who registered as Teachers/Subject Matter Expert or Students/Parents on CareerGuidanceBuddy as available via the Site, Application, and Services. </p>
                      </li>
                      <li>
                        <h3 className='font-medium'> Data Portability :</h3>
                        <p className='font-normal'>We allow you to receive the personal data concerning you, which we will provide in a 'commonly used and machine readable format' and you have the right to transmit that data to another ‘controller’</p>
                      </li>
                      <li>
                        <h3 className='font-medium'>Privacy by Design : </h3>
                        <p className='font-normal'>We implement appropriate measures, in an effective way and protect the rights of data subjects. We hold and process only the data absolutely necessary for the completion of our duties, as well as limiting the access to personal data to those needing to act out the processing.</p>
                      </li>
                      <li>
                        <h3 className='font-medium'>Eligibility :</h3>
                        <p className='font-normal'>Use of the Site, Application and Services is available only to persons who can form legally binding contracts under Indian laws. The Website is intended solely for persons who are 18 years of age or older. If they are below 18, then their parent or guardian can open an account and help you enrol in the services that are appropriate for them.</p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h3 className='font-medium'>COMMUNITY</h3>
                    <ol type='1' style={{ listStyleType: 'decimal', paddingLeft: '20px' }} className='font-normal'>
                      <li>CareerGuidanceBuddy will create Listing of various Members and the details about the Members will be listed on the Website. Listings will be made publicly available on the Website.</li>
                      <li>Kindly note that CareerGuidanceBuddy assumes no responsibility for a Student's/Parent’s or Teacher’s/Subject Matter Expert’s compliance with any agreements with or duties to third parties, applicable laws, rules and regulations. CareerGuidanceBuddy reserves the right, at any time and without prior notice, to remove or disable access to any Member for any reason, that CareerGuidanceBuddy, in its sole discretion, considers to be objectionable for any reason, in violation of these Terms.</li>
                    </ol>
                  </li>
                  <li>
                    <h3 className='font-medium'>NO ENDORSEMENT</h3>
                    <p className='font-normal'>By Using The Website, You Accept That Any Legal Liability That You Seek To Obtain For Actions Of Other Members Or Other Third Parties Will Be Limited To A Claim Against The Particular Members Or Other Third Parties Who Caused You Harm. You Agree Not To Attempt To Impose Liability On Or Seek Any Legal Remedy From CareerGuidanceBuddy With Respect To Such Actions.</p>
                  </li>

                  <li>
                    <h3 className='font-medium'>MEMBERS CONDUCT</h3>
                    <ol type='1' style={{ listStyleType: 'decimal', paddingLeft: '20px' }} className='font-normal flex flex-col gap-4'>
                      <li>You understand and agree that you are solely responsible for compliance with any and all laws, rules, regulations, and Tax obligations that may apply to your use of the Website. In connection with your use of the Website, you agree that you will not:

                        <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }} className='font-normal'>
                          <li>Violate Any Local, State, National, Or Other Law Or Regulation, Or Any Order Of A      Court, Including, Without Limitation, Tax Regulations;</li>
                          <li>Use Manual Or Automated Software, Devices, Scripts, Robots, Backdoors Or Other      Means Or Processes To Access, "Scrape," "Crawl" Or  "Spider" Any Web Pages Or Other Services Contained In The Website;</li>
                          <li>Use The Website For Any Commercial Or Other Purposes That Are Not Expressly      Permitted By These Terms Or In A Manner That Falsely Implies CareerGuidanceBuddy      (CGB ORG’s) Endorsement;</li>
                          <li>Copy, Store Or Otherwise Access Or Use Any Information Contained On The Website      For Purposes Not Expressly Permitted By These Terms;</li>
                          <li>Infringe The Rights Of CareerGuidanceBuddy (CGB ORG) Or The Rights Of Any Other Member Or Entity, Including Without Limitation, Their Intellectual Property,      Privacy, Publicity Or Contractual Right</li>
                          <li>Interfere With Or Damage Our Website Through The Use Of Viruses Or Similar Methods Or Technology;</li>
                          <li>Use Our Website To Transmit, Distribute, Post Or Submit Any Information      Concerning Any Other Person Or Entity, Including Without Limitation, Photographs Of Others Without Their Permission, Personal Contact Information Or Credit, Debit, Calling Card Or Account Numbers;</li>
                          <li>Use Our Website In Connection With The Distribution Of Unsolicited Spam Emails Or Advertisements</li>
                          <li>Recruit Or Otherwise Solicit Any Member To Join Third-Party Services Or Websites      That Are Competitive To CareerGuidanceBuddy (CGB ORG’s), Without CareerGuidanceBuddy's (CGB ORG’s) Prior Written Approval;</li>
                          <li>Impersonate Any Person Or Entity, Or Falsify Or Otherwise Misrepresent Yourself Or      Your Affiliation With Any Person Or Entity;</li>
                          <li>Use Automated Scripts To Collect Information From Or Otherwise Interact With The Website;</li>
                          <li>Violate These Terms or CareerGuidanceBuddy's (CGB ORG’s) Then-Current Policies And Or Standards;</li>
                          <li>Engage In Disruptive, Circumventive, Abusive Or Harassing Behaviour In Any Area      Or Aspect Of Our Website;</li>
                          <li>Systematically Retrieve Data Or Other Content From Our Website To Create A Collection, Compilation, Database, Directory Or The Like, Whether By Manual Methods,      Through The Use Of Bots, Crawlers, Or Spiders, Or Otherwise;</li>
                          <li>Use Or Display Website Or Any Individual Element Within The Website, CGB ORG’s      Name, CareerGuidanceBuddy's Name, Any CGB ORG’s Trademark, Logo Or Other      Proprietary Information, Or The Layout And Design Of Any Page Or Form      Contained On A Page In The Website Without CGB ORG’s Express Written      Consent;</li>
                          <li>Access, Tamper With, Or Use Non-Public Areas Of The Website, CareerGuidanceBuddy's (CGB ORG’s) Computer Systems, Or The Technical Delivery Systems Or CareerGuidanceBuddy's (CGB ORG’s) Providers;</li>
                          <li>Attempt To Probe, Scan, Or Test The Vulnerability Of Any CareerGuidanceBuddy (CGB ORG’s) System Or Network Or Breach Any Security Or Authentication Measures;</li>
                          <li>Forge Any TCP/IP Packet Header Or Any Part Of The Header Information In Chat or      Forum or Any Email Or Newsgroup Posting, Or In Any Way Use The Website To      Send Altered, Deceptive Or False Source-Identifying Information;</li>
                          <li>Attempt To Decipher Or Reverse Engineer Any Of The Software Used To Provide The      Website;</li>
                          <li>Advocate, Encourage, Or Assist Any Third Party In Doing Any Of The Foregoing;</li>
                        </ul>
                      </li>
                      <li>CareerGuidanceBuddy (CGB ORG) has the right to investigate and prosecute violations of any of the above to the fullest extent of the law. In addition, and as set in these Terms, CareerGuidanceBuddy (CGB ORG) may take a range of actions against you for a violation of this Section or these Terms.</li>
                      <li>CareerGuidanceBuddy (CGB ORG) may access, preserve and disclose any of your information if we are required to do so by law, or if we believe in good faith that it is reasonably necessary to (i) respond to claims asserted against CareerGuidanceBuddy (CGB ORG) (ii) comply with legal process, (iii) enforce our agreements with users, (iii) for fraud prevention, or (iv) protect the rights, property or safety of CareerGuidanceBuddy (CGB ORG) and its users.</li>
                      <li>You acknowledge that CareerGuidanceBuddy (CGB ORG) has right to monitor your access to or use of the Website,  or to review or edit any Member Content, but has the right to do so for the purpose of operating and improving the Website. </li>
                    </ol>
                  </li>
                  <li>
                    <h3 className='font-medium'>STUDENT SAFETY POLICY</h3>
                    <ol type='1' style={{ listStyleType: 'decimal', paddingLeft: '20px' }} className='font-normal flex flex-col gap-4'>
                      <li>This policy is applicable to all persons and organizations associated with CareerGuidanceBuddy (CGB ORG), referred to as CareerGuidanceBuddy’s (CGB ORG’s) Members, Stakeholders, including:
                        <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }} className='font-normal'>
                          <li>Employees Of CareerGuidanceBuddy (CGB ORG), Who Maybe Full-Time Or Part-Time      Employees, Permanent Or Temporary Employee, Regular Permanent Employee Or      Employed On Contract</li>
                          <li>Organizations And Persons Belonging To That Organization, Who May Partner And/ Or Work With CareerGuidanceBuddy (CGB ORG) In Any Capacity, Even For A Limited      Time Period Or Job</li>
                          <li>Volunteers Who May Work With CareerGuidanceBuddy (CGB ORG) Even For A Limited Period Of Time</li>
                          <li>Anyone Enrolled As Student in CareerGuidanceBuddy(CGB ORG) And Their Parents Or      Guardians Who Associate With The Student In The Official Records Of CareerGuidanceBuddy (CGB ORG)</li>
                          <li>Anyone Enrolled As Teacher/Subject Matter Exoert in CareerGuidanceBuddy (CGB ORG) Who Associate With The Teacher In The Official Records Of CareerGuidanceBuddy (CGB ORG)</li>
                          <li>And Their Parents Or Guardians Who Associate With The Student In The Official      Records Of CareerGuidanceBuddy (CGB ORG)</li>
                          <li>Student/Teachers Who May Not Be Enrolled But Are Reached Out Through Marketing Or Sales Of The Product/Service And Their Parents Or Guardians Who Associate With The Student</li>
                          <li>Any Other Person Or Organisations Who May Be Officially Associated With CareerGuidanceBuddy (CGB ORG) And Its Ambit Of Work And Activity</li>

                        </ul>
                      </li>
                      <li>In instances and circumstances where CareerGuidanceBuddy (CGB ORG) may not have required control over the person alleged of any concern under this policy, including an instance of Student abuse, CareerGuidanceBuddy (CGB ORG) shall provide the required cooperation to such student, parent or guardian of the affected Student, in approaching any external, legal mechanism including the Police and/or the Courts, though CareerGuidanceBuddy (CGB ORG) is itself not personally liable in such instances. CareerGuidanceBuddy expects all its stakeholders falling within the scope of this policy to adhere to the following Student safety code of conduct.</li>
                      <li>If Anyone Is Acting Improperly And (I) Engages In Offensive or Sexually Improper Behaviour, Or (Ii) Engages In Misconduct, You Should Instantly Report It To Concerned Authorities, Properly rate the Teachers/Subject Matter Expert and properly describe in the comment And Then To CareerGuidanceBuddy (CGB ORG) By Contacting Us With Your Police Station And Report Number; Provided That Your Report Will Not Obligate Us To Take Any Action Beyond That Required By Law.</li>
                    </ol>
                  </li>
                  <li>
                    <h3 className='font-medium'>EXPECTED BEHAVIOUR AND ACTIONS</h3>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }} className='font-normal'>
                      <li>Listen To The Student</li>
                      <li>Treat Every Student With Empathy And Respect Regardless Of His/Her Race, Colour,      Gender, Sexuality, Religion, Language, Heritage, Religious Belief, Social Origin, Or Any Point That Discriminated A Student</li>
                      <li>Use Appropriate Language While Interacting With The Student</li>
                      <li>Create A Conducive Environment That Enables Student To Learn, Share And Express      Freely</li>
                      <li>Always Take Permission From Student or Parent or Guardian Before Taking Photos Or      Videos Of A Student</li>
                      <li>Keep All Personal Information Of Student, Their Parents And Guardians Confidential And Secure, Such Information Shall Only Be Shared With Authorised Individuals</li>
                      <li>The Live Online Calls, Conferences and Classes And The Content, Including But Not Limited To Audio Visual Content Is Age Appropriate And Culturally Appropriate</li>
                    </ul>
                  </li>
                  <li>
                    <h3 className='font-medium'>PROHIBITED BEHAVIOURS AND ACTIONS</h3>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }} className='font-normal'>
                      <li>Do Not Develop Any Emotional, Online/Offline Physical Abuse Or Sexual Relationship With Student In Any Way</li>
                      <li>Do Not Use Or Encourage The Use Of Alcohol, Drugs, Cigarettes Or Other      Intoxicating Substance In Any Of Your Interaction With Student</li>
                      <li>Do Not Develop Any Form Of Relationship Or Arrangement With Student Including But      Not Limited To Financial, Which Could Be Deemed To Be Exploitative Or Abusive</li>
                      <li>Do Not Share With Or Show Student Online/Offline Any Inappropriate Content      Including Pornographic Material Or Material That Encourages Crime, Hatred, Violence, Racism, Sexism, Self-Harm, Suicide, Cruelty</li>
                      <li>Always Take Permission From Student or Parent or Guardian Before Taking Photos Or      Videos Of A Student</li>
                      <li>Do Not Use Language Or Behaviour Towards Student That Is Inappropriate, Harassing, Abusive, Sexually Provocative, Demeaning, Intimidating, Discriminatory, Or Culturally Insensitive</li>
                    </ul>
                  </li>
                  <li>
                    <h3 className='font-medium'>PRIVACY</h3>
                    <p className='font-normal'>In Order To Provide You Services We Gather Your Personal Information. We Describe This Collection And Use Of Personal Information In Our Privacy Policy. Kindly Review This Policy. You Must Agree To The Processing Of Your Personal Information As Mentioned In CareerGuidanceBuddy's (CGb ORG’s) Privacy Policy. You Accept That CareerGuidanceBuddy (CGB ORG) May Disclose Personal Information Provided To Us, Including The Data Entered Into The Website, If Required To By Law Or Government Request Such As A Warrant, Or As Specified In The Privacy Policy . You Must Be 18 Years Or Older To Use This Service by yourself or you take the help of your Parents or Local Guardian. You Yourself Are Responsible For Preventing Unauthorized Access To Your Account.</p>
                  </li>
                  <li>
                    <h3 className='font-medium'>INTELLECTUAL PROPERTY OWNERSHIP AND RIGHTS NOTICES</h3>
                    <p className='font-normal'>The Website Is Protected By Intellectual Property Rights Like Copyright, Trademark, And Other Laws. You Acknowledge And Agree That The Website, Including All Associated Intellectual Property Rights, Are The Exclusive Property Of CareerGuidanceBuddy (CGB ORG) And Its Members. You Will Not Change Any Copyright, Trademark, Service Mark Or Other Proprietary Rights Notices Incorporated In The Website. All Trademarks, Service Marks, Logos, Trade Names, And Any Other Proprietary Designations Of CareerGuidanceBuddy (CGB ORG) Used On Or In Connection With The Website, And CareerGuidanceBuddy Content Are Trademarks Or Registered Trademarks Of CareerGuidanceBuddy (CGB ORG) In India And Abroad. Trademarks, Service Marks, Logos, Trade Names And Any Other Proprietary Designations Of Third Parties Used On Or In Connection With The Website And CareerGuidanceBuddy Content Are Used For Identification Purposes Only And May Be The Property Of Their Respective Owners. As A Member, You Understand And Agree That You Are Bound By The Additional Terms, Guidelines And Policies That Apply To Your Use Of The Website, Including CareerGuidanceBuddy's (CGB ORG’s) Trademark & Branding Guidelines.</p>
                  </li>
                  <li>
                    <h3 className='font-medium'>ADDITIONAL TERMS</h3>
                    <p className='font-normal'>Our Site, Application And Services Have Different Products, Features And Offerings, So Sometimes Additional Terms Or Product Requirements May Apply To Your Use Of Those Products, Features Or Offerings. If Additional Terms Are Available For The Relevant Product Or Services You Use, Those Additional Terms Become Part Of These Terms.</p>
                  </li>
                  <li>
                    <h3 className='font-medium'>CAREERGUIDANCEBUDDY CONTENT AND MEMBER CONTENT LICENSE</h3>
                    <p className='font-normal'>Subject To Your Compliance With These Terms, CareerGuidanceBuddy Grants You A Limited, Non-Exclusive, Non-Transferable License, To (A) Access And View Any CareerGuidanceBuddy (CGB ORG) Content Solely For Your Personal And Non-Commercial Purposes And (B) Access And View Any Member Content To Which You Are Permitted Access, Solely For Your Personal And Non-Commercial Purposes. You Have No Right To Sublicense The License Rights Granted In This Section.</p>
                  </li>
                  <li>
                    <h3 className='font-medium'>HYPERLINKS</h3>
                    <p className='font-normal'>The Site, Application And Services May Contain details of members, Links To Third-Party Websites Or Resources. You Acknowledge And Agree That CareerGuidanceBuddy (CGB ORG) Is Not Responsible Or Liable For: (I) The Availability Or Accuracy Of Such Websites Or Resources Or Member details; Or (Ii) The Content, Products, Or Services On Or Available From Such Websites Or Resources OR Memebers, Links To Such Websites Or Resources Do Not Imply Any Endorsement By CareerGuidanceBuddy (CGB ORG) Of Such Websites Or Resources Or The Content, Products, Or Services Available From Such Websites Or Resources Or Members. You Acknowledge Sole Responsibility For Use Of Any Such Websites and Member information.</p>
                  </li>
                  <li>
                    <h3 className='font-medium'>COPYRIGHT POLICY</h3>
                    <p className='font-normal'>CareerGuidanceBuddy (CGB ORG) Respects Copyright Law And Expects Its Users To Do The Same. It Is CareerGuidanceBuddy's (CGB ORG’s) Policy To Terminate In Appropriate Circumstances The CareerGuidanceBuddy (CGB ORG) Accounts Of Members Or Other Account Holders Who Repeatedly Infringe Or Are Believed To Be Repeatedly Infringing The Rights Of Copyright Holders.</p>
                  </li>
                  <li>
                    <h3 className='font-medium'>TERM AND TERMINATION, SUSPENSION AND OTHER MEASURES</h3>
                    <p className='font-normal'> This Agreement shall be effective for till the time Members access or use the Website or by downloading or posting any content from or on the Website, through the Services Until such time when you or CareerGuidanceBuddy (CGB ORG) terminate the Agreement as described below.</p>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }} className='font-normal'>
                      <li>CareerGuidanceBuddy (CGB ORG) may instantly, without notice terminate this Agreement if (i) you have materially breached these Terms or our Policies, (ii) you have provided incorrect information during the CareerGuidanceBuddy (CGB ORG) Account registration, (iii) you have violated applicable laws and regulations, or (iv) CareerGuidanceBuddy (CGB ORG) believes in good faith that such action is important to protect the safety or property of other Members, CareerGuidanceBuddy or third parties, for fraud prevention and security purposes</li>
                      <li>In case of non-material breaches and where appropriate, you will be given notice of any measure by CareerGuidanceBuddy (CGB ORG) and an opportunity to resolve the issue to CareerGuidanceBuddy's (CGB ORG)’s reasonable satisfaction.</li>
                      <li>In case of Termination or Suspension of Members/Users; there is strict no refund. </li>
                    </ul>
                  </li>
                  <li>
                    <h3 className='font-medium'>SURVIVAL</h3>
                    <p className='font-normal'>CareerGuidanceBuddy (CGB ORG) Respects Copyright Law And Expects Its Users and Members To Do The Same. It Is CareerGuidanceBuddy's (CGB ORG’s) Policy To Terminate In Appropriate Circumstances The CareerGuidanceBuddy Accounts Of Members Or Other Account Holders Who Repeatedly Infringe Or Are Believed To Be Repeatedly Infringing The Rights Of Copyright Holders.</p>
                  </li>
                  <li>
                    <h3 className='font-medium'>DISCLAIMERS</h3>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }} className='font-normal'>
                      <li>CareerGuidanceBuddy do not conduct any background verification their members.</li>
                      <li>If you choose to use the Website, you do so at your sole risk. You acknowledge and agree that CareerGuidanceBuddy (CGB ORG) does not have an obligation      to conduct background checks on any Member, but may conduct such background checks in its sole discretion. If we choose to conduct such checks, to the extent permitted by applicable law, we disclaim warranties of any kind that such checks will identify prior misconduct by a Member or guarantee that a user will not engage in misconduct in the future.</li>
                      <li>The Website is provided "as is", without warranty of any kind. Without limiting the foregoing, CareerGuidanceBuddy (CGB ORG) explicitly disclaims any warranties of merchantability, fitness for a particular purpose, quiet enjoyment or non-infringement, and any warranties arising out of course of dealing or usage of trade. CareerGuidanceBuddy (CGB ORG) makes no warranty that the Website will meet your requirements or be available on an uninterrupted or secure basis. CareerGuidanceBuddy makes no warranty regarding the Members, Teachers/Subject Matter Expert, Students/Parents or the Services obtained through the Website.</li>
                      <li>No advice or information, whether oral or written, obtained from CareerGuidanceBuddy (CGB ORG) or through the Website will create any warranty not expressly made herein.</li>
                      <li>You are solely responsible for all of your communications and interactions with other Members of the Website and with other persons with whom you interact as a result of your use of the Website. You understand that CareerGuidanceBuddy (CGB ORG) does not make any attempt to verify the statements of Members of the Website or to review any Listing or Chat or Post or Calls or Conferences or Classes and makes no representations or warranties as to the conduct of Members of the Website or their compatibility with any users/Members of the Website. You agree to take reasonable precautions in all communications and interactions with other users of the site,      application or services and with other Members/persons with whom you communicate or interact as a result of your use of the Website, particularly if you decide to meet online or in person regardless of whether such meetings are organized on CareerGuidanceBuddy (CGB ORG) site. CareerGuidanceBuddy (CGB ORG) explicitly disclaims all liability for any act or omission of any Student/Parent or Teacher or Subject Matter Expert or other third party.</li>
                    </ul>
                  </li>
                  <li>
                    <h3 className='font-medium'>LIMITATION OF LIABILITY</h3>
                    <p className='font-normal'>You Acknowledge And Agree That, To The Maximum Extent Permitted By Law, The Entire Risk Arising Out Of Your Access To And Use Of The Website, site and Any Contact You Have With Other Members/Users Of CareerGuidanceBuddy Whether In Person Or Online Remains With You. Neither CareerGuidanceBuddy (CGB ORG) Nor Any Other Party Involved In Creating, Producing, Or Delivering The Website Will Be Liable For Any Damages.</p>
                  </li>
                  <li>
                    <h3 className='font-medium'>INDEMNIFICATION</h3>
                    <p className='font-normal'>You Agree To Indemnify CareerGuidanceBuddy (CGB ORG) And Its Affiliates And Subsidiaries, And Their Officers, Directors, Employees And Agents, Harmless From And Against Any Claims, Liabilities, Damages, Losses, And Expenses, Including, Without Limitation, Reasonable Legal And Accounting Fees, Arising Out Of Or In Any Way Connected With (A) Your Access To Or Use Of The Website Or Your Violation Of These Terms; (B) Your Member Content; (C) Your Interaction With Any Member.</p>
                  </li>
                  <li>
                    <h3 className='font-medium'>ENTIRE AGREEMENT</h3>
                    <p className='font-normal'>Except As They May Be Supplemented By Additional CareerGuidanceBuddy (CGB ORG) Policies or Privacy Policy, CareerGuidanceBuddy(CGB ORG) User Agreement, CareerGuidanceBuddy (CGB ORG) Payment Policy and CareerGuidanceBuddy (CGB ORG) Refund Policy Summarise These Terms Constitute The Entire Agreement Between CareerGuidanceBuddy (CGB ORG) And You Regarding The Website, Sites And These Terms Supersede And Replace Any And All Prior Oral Or Written Understandings Or Agreements Between CareerGuidanceBuddy (CGB ORG) And You Regarding The Website(s) and application(s).</p>
                  </li>
                  <li>
                    <h3 className='font-medium'>NOTICES</h3>
                    <p className='font-normal'>Any Notices Or Other Communications Permitted Or Required Hereunder, Including Those Regarding Modifications To These Terms, Will Be In Writing And Given By CareerGuidanceBuddy (CGB ORG) (I) Via Email Or (Ii) By Posting To The Website. For Notices Made By E-Mail, The Date Of Receipt Will Be Deemed The Date On Which Such Notice Is Transmitted.</p>
                  </li>
                  <li>
                    <h3 className='font-medium'>GOVERNING LAW AND JURISDICTION</h3>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }} className='font-normal'>
                      <li>These Terms and your use of the Services will be interpreted in accordance with the laws of India excluding its rules on conflicts of laws. You and we agree to submit any dispute arising under these Terms to the personal jurisdiction of a court located in Jaipur, India for any actions for which the parties retain the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party's copyrights,      trademarks, trade secrets, patents, or other intellectual property rights.</li>
                      <li>Any dispute, claim or controversy arising out of or relating to this Terms including the determination of the scope or applicability of this Terms to arbitrate, or your use of the Application or information to which it gives access, shall be determined by arbitration in Jaipur India, before a sole arbitrator mutually appointed by Members and CareerGuidanceBuddy (CGB ORG). Arbitration shall be conducted in accordance with the Indian Arbitration and Conciliation Act, 1996. The seat of such arbitration shall be Jaipur, India. All proceedings of such arbitration, including, without limitation, any awards, shall be in the English language. The award shall be final and binding on the parties to the dispute.</li>
                      <li>If you are a resident of the European Economic Area (EEA) you have the right to      complain to a Data Protection Authority about our collection and use of your personal information. For more information, please contact your local data protection authority in the EEA.</li>
                    </ul>
                  </li>
                  <li>
                    <h3 className='font-medium'>NO WAIVER</h3>
                    <p className='font-normal'>The Failure Of CareerGuidanceBuddy(CGB ORG) To Enforce Any Right Or Provision Of These Terms Will Not Constitute A Waiver Of Future Enforcement Of That Right Or Provision. The Waiver Of Any Such Right Or Provision Will Be Effective Only If In Writing And Signed By A Duly Authorized Representative Of CareerGuidanceBuddy (CGB ORG). Except As Expressly Set Forth In These Terms, The Exercise By Either Party Of Any Of Its Remedies Under These Terms Will Be Without Prejudice To Its Other Remedies Under These Terms Or Otherwise. If For Any Reason A Court Of Competent Jurisdiction Finds Any Provision Of These Terms Invalid Or Unenforceable, That Provision Will Be Enforced To The Maximum Extent Permissible And The Other Provisions Of These Terms Will Remain In Full Force And Effect.</p>
                  </li>
                  <li>
                    <h3 className='font-medium'>MISCELLANEOUS</h3>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }} className='font-normal'>
                      <li>CareerGuidanceBuddy (CGB ORG) shall have no liability of any nature, whether in contract, or otherwise, for any losses whatsoever and howsoever caused, from or in any manner connected with any of the Services provided by Us or our Members. </li>
                      <li>CareerGuidanceBuddy (CGB ORG) is not liable for any failure or delay of performance (or otherwise) arising out of a cause beyond CareerGuidanceBuddy’s (CGB ORG)’s reasonable control.</li>
                      <li>You may not assign or transfer these Terms without CareerGuidanceBuddy's  (CGB ORG)’s prior written consent. Any attempt by you to assign or transfer these Terms, without such consent, will be of no effect. CareerGuidanceBuddy  (CGB ORG) may assign or transfer these Terms, at its sole discretion, without restriction. Subject to the      foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.</li>
                      <li>CareerGuidanceBuddy (CGB ORG) reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Services provided by us      with or without notice. You accept that CareerGuidanceBuddy  (CGB ORG) shall not be liable to You or to any third party for any modification of such Services. It is your      responsibility to review these Terms periodically for updates and changes.</li>
                      <li>You agree not to reproduce, duplicate, copy, sell, resell or exploit for any commercial purposes, any portion of the information provided by us or our Members, including any intellectual property rights of CareerGuidanceBuddy (CGB ORG) or its Members any person firm or corporation having posted information for availability through the      Services provided by us.</li>
                      <li>You agree that in the event your post or your information violates any provision of these Terms, We shall have the right to refuse to provide you or any person acting on your behalf, access to the Website, terminate and/or suspend your access if applicable in the future.</li>
                    </ul>
                  </li>
                </ol>

              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Terms_and_conditions