import React, { useEffect, useState } from 'react';
import { FaComments } from "react-icons/fa";
import { IoPersonCircle, IoNotifications } from "react-icons/io5";
import { HiMiniUserGroup } from "react-icons/hi2";
import { RiLogoutBoxRLine, RiSearch2Fill } from "react-icons/ri";
import { TbCalendarTime } from "react-icons/tb";
import Cookies from 'js-cookie';
import logo from "../../assets/img/dlogo.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useNotifications } from '../../context/NotificationContext'; 
import Notifications from '../../pages/notification';  // Import the Notifications component

const apiUrl = process.env.REACT_APP_API_URL;

function NavStudent() {
  const navigate = useNavigate();
  const authToken = Cookies.get('authToken');
  const { unreadCount,unreadChat } = useNotifications(); 
  
  const [isActiveCalendar, setIsActiveCalendar] = useState(false);
  const [isActiveComments, setIsActiveComments] = useState(false);
  const [isActiveProfile, setIsActiveProfile] = useState(false);
  const [isActiveForum, setIsActiveForum] = useState(false);
  const [isActiveSearch, setActiveSearch] = useState(false);
  const [isActiveNotification, setActiveNotification] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const loggedInUserId = Cookies.get('loggedInUserId');

  
  const handleNavigationClick = (path, setActiveState) => {
    navigate(path);
    setIsActiveCalendar(false);
    setIsActiveComments(false);
    setIsActiveProfile(false);
    setIsActiveForum(false);
    setActiveSearch(false);
    setActiveNotification(false);
    setActiveState(true);
  };

  const handleNotificationClick = () => {
    setShowNotifications(!showNotifications);
    setActiveNotification(!isActiveNotification);
  };

  const handleLogout = async () => {
    try {
      await axios.post(`${apiUrl}/api/auth/logout`, {}, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      Cookies.remove("loggedInUserId");
      Cookies.remove("authToken");
      Cookies.remove("account_type");
      Cookies.remove("user_type");
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <nav className="hidden sm:flex items-center justify-between lg:h-[72px] md:h-[64px] sm:h-[64px] w-full bg-gradient lg:px-[72px] md:px-6 sm:px-4">
      <div className="w-full max-w-[1300px] mx-auto sm:flex md:gap-10 items-center sm:justify-between lg:justify-between md:justify-between">
        <div className="hidden lg:block md:block sm:block">
          <img className="lg:w-[288px] sm:w-[200px] md:w-[240px]" src={logo} alt="logo" />
        </div>
        <div className="flex items-center justify-center lg:gap-10 sm:gap-4 md:gap-6">
          <div className="relative">
            <RiSearch2Fill
              title="Search"
              className={`${isActiveSearch ? 'text-[#f3c699]' : 'text-white'} cursor-pointer bg-transparent hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-1 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7`}
              size={28}
              onClick={() => handleNavigationClick('/cabinet', setActiveSearch)}
            />
          </div>
          <div className="relative">
            <TbCalendarTime
              title="Events"
              className={`${isActiveCalendar ? 'text-[#f3c699]' : 'text-white'} cursor-pointer bg-transparent hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-1.5 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7`}
              size={28}
              onClick={() => handleNavigationClick('/user_events', setIsActiveCalendar)}
            />
          </div>
          <div className="relative">
            <FaComments
              title="Chats"
              className={`${isActiveComments ? 'text-[#f3c699]' : 'text-white'} cursor-pointer bg-transparent hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-2 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7`}
              size={28}
              onClick={() => handleNavigationClick('/chat', setIsActiveComments)}
            />
            {unreadChat > 0 && (
              <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full w-4 h-4 lg:w-5 lg:h-5 text-white text-xs flex items-center justify-center">
                {unreadChat}
              </div>
            )}
          </div>
          <div className="relative">
            <HiMiniUserGroup
              title="Forum"
              className={`${isActiveForum ? 'text-[#f3c699] !important' : 'text-white'} cursor-pointer bg-transparent hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-1 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7`}
              size={28}
              onClick={() => handleNavigationClick('/forum', setIsActiveForum)}
            />
          </div>
          <div className="relative">
            <IoPersonCircle
              title="Profile"
              className={`${isActiveProfile ? 'text-[#f3c699] !important' : 'text-white'} cursor-pointer bg-transparent hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-1 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7`}
              size={28}
              onClick={() => handleNavigationClick('/edit-profile', setIsActiveProfile)}
            />
          </div>
          <div className="relative">
            <IoNotifications
              title="Notifications"
              className={`${isActiveNotification ? 'text-[#f3c699]' : 'text-white'} cursor-pointer bg-transparent hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-1 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7 relative`}
              size={28}
              onClick={handleNotificationClick}
            />

           {(unreadChat > 0 || unreadCount > 0) && (
              <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full w-4 h-4 lg:w-6 lg:h-6 lg:text-sm sm:h-5 sm:w-5 text-white text-xs flex items-center justify-center">
                { unreadCount}
              </div>
            )}
          </div>
          <div className="relative">
            <RiLogoutBoxRLine
              title="Logout"
              className={`cursor-pointer text-white bg-transparent hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-1 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7`}
              size={28}
              onClick={handleLogout}
            />
          </div>
        </div>
        {showNotifications && (
          <Notifications
            setShowNotifications={setShowNotifications}
          />
        )}
      </div>
    </nav>
  );
}

export default NavStudent;


