import React, { useContext, useEffect, useState } from "react";
import { BsFillChatDotsFill, BsPersonVideo2 } from "react-icons/bs";
import { TbUpload, TbPhoneCall,TbCertificate } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie"
import NavBar from "../components/nav/navBar";
import Header from "../components/headerBackground";
import MainMenu from "../components/MenuBottom/MainMenu";
import { useNavigate } from "react-router-dom";
import {
  BsEmojiSmileFill,
  BsFillEmojiNeutralFill,
  BsFillEmojiWinkFill,
  BsEmojiFrownFill, BsFillEmojiLaughingFill
} from "react-icons/bs";
import axios from "axios";
import { ChatContext } from "../App";
import MetaDecorator from "../utils/MetaDecorator";
const ViewProfile = () => {
  const location = useLocation();
  const profileData = location.state.profileData;
  const Rating_Review = location.state.reviews_rating;
  // console.log("Rating_Review", Rating_Review)
  // console.log("profile data", profileData);
  const apiUrl = process.env.REACT_APP_API_URL;
  const profile_type = Cookies.get("user_type");
  const [users, setUsers] = useState([]);
  const authToken = Cookies.get('authToken');
  const file_url = 'https://cgb-storage.blr1.digitaloceanspaces.com';
  let defaultProfile = `${file_url}/profile-pictures/defaultProfile.png`
  const [activeTab, setActiveTab] = useState('Calls');
  const loggedInUserId = Cookies.get('loggedInUserId');
  const { setOtherUserID } = useContext(ChatContext);

  const navigate = useNavigate();
  console.log("Profile Data", profileData)
  const emojiComponents = {
    '1': <BsEmojiFrownFill className="text-orange-500" />,
    '2': <BsFillEmojiNeutralFill className="text-orange-500" />,
    '3': <BsFillEmojiWinkFill className="text-orange-500" />,
    '4': <BsEmojiSmileFill className="text-orange-500" />,
    '5': <BsFillEmojiLaughingFill className="text-orange-500" />,
  };

  const handleBook = () => {

    navigate(`/book_list/${profileData.userId}`)
  }
  const handleConference = () => {
    navigate(`/teacher_conferences/${profileData.userId}`, { state: { profileData } })
  }
  const handleCourses = (id) => {
    navigate(`/teacher_courses/${profileData.userId}`, { state: { profileData } })
  }

  const handleshareTeacher = () => {
    if (navigator.share) {
      const baseUrl = window.location.origin;
      navigator.share({
        title: 'Check out this book list!',
        text: 'Here is an amazing book list you should see:',
        url: `${baseUrl}/book_list/${loggedInUserId}`,
      })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      alert('Sharing is not supported on this browser.');
    }
  }


  const renderReviews = (reviews) => (
    reviews.map((review, index) => (
      <div key={index} className="flex flex-row bg-white rounded-[25px] sm:rounded-[50px] items-start sm:items-center shadow-md px-2 py-2 sm:px-4 sm:py-4 justify-start gap-4 font-bold text-[#585858] text-[14px] text-center md:text-start">
        <div className="flex gap-2 justify-center items-center px-2 py-1 rounded-full border border-orange-500 mt-2">
          <p className="font-medium text-[15px] text-gradient">{review.rating}.0</p>
          {emojiComponents[review.rating]}
        </div>
        <p className="font-semibold">
          Rating Description:{" "}
          <span className="font-normal">{review.review}</span>
        </p>
      </div>
    ))
  );
  const handleStartChat = async (targetUserId) => {
    // Check if a conversation with this user already exists
    try {
      const response = await axios.get(`${apiUrl}/api/messages`, {
        headers: {
          "Authorization": `Bearer ${authToken}`
        },
      });

      const conversations = response.data;
      const existingConversation = conversations.find(conversation =>
        conversation.participants.some(participant => participant._id === targetUserId)
      );

      if (existingConversation) {
        // If a conversation exists, navigate to it
        setOtherUserID(targetUserId);
        localStorage.setItem('otheruserID', targetUserId);
        navigate('/open-chat');
        // navigate(`/open-chat/${targetUserId}`);
      } else {
        // If no conversation exists, create a new one
        const createResponse = await axios.post(`${apiUrl}/api/messages`, {
          userId: loggedInUserId,
          otherUserId: targetUserId
        }, {
          headers: {
            "Authorization": `Bearer ${authToken}`
          }
        });

        // console.log('New conversation created:', createResponse.data);
        setOtherUserID(targetUserId);
        localStorage.setItem('otheruserID', targetUserId);
        navigate('/open-chat');
        // navigate(`/open-chat/${targetUserId}`);
      }
    } catch (error) {
      console.error('Error fetching or creating conversation:', error);
    }
  };
  const keywords = ["elearning", "education", "online class", "education forum", "education", "student", "teacher", "learning"];
  return (
    <>
      <MetaDecorator title={'View Profile - Career Guidance Buddy'} description={''} imageUrl={''} keywords={keywords} imageAlt={''} url={''} />
      <div>
        <NavBar />
        <div className="sm:mt-24 lg:mt-24 sm:mx-auto mt-[160px] sm:w-full sm:max-w-[1296px] px-3 sm:px-5 mb-0 font-primary">
          <Header />
          <div className="overflow-y-auto md:grid grid-cols-3 gap-8 sm:min-h-[85vh]" style={{ maxHeight: 'calc(100vh - 220px)' }}>
            <div className="min-h-full min-h-full flex flex-col justify-center sm:justify-start items-start sm:pb-5 md:pb-0 gap-6">
              {/* <!-- personal details both s & t--> */}
              <div className="h-fit w-full">
                <div className="rounded-[50px] sm:rounded-[25px] shadow-md w-full border h-fit border-orange-500 flex flex-col items-center justify-center px-3 sm:px-3 lg:px-8 py-3">
                  <div className="flex flex-col justify-center items-center sm:mb-2">
                    <div className="sm:w-[80px] sm:h-[80px] lg:w-[90px] lg:h-[90px] w-[70px] h-[70px] rounded-full">
                      <img className="h-full w-full overflow-hidden rounded-full border"
                        src={profileData.profilePic !== '' && profileData.profilePic !== undefined ? profileData.profilePic : defaultProfile} alt="" />
                    </div>
                    {profile_type === 'Student' && profileData.rating ? <div className="flex gap-2 justify-center items-center px-2 py-1 rounded-full border border-orange-500 mt-2">
                      <p className="font-medium text-[15px] text-gradient">{profileData.rating}</p>
                      {emojiComponents[Math.round(profileData.rating)]}
                    </div> : ''}
                    <h1 className="font-semibold sm:text-xl lg:text-2xl text-xl text-gradient text-center">
                      {profileData.fullName}
                    </h1>
                    {profile_type === 'Student' ? <p className="text-gradient text-[14px] lg:text-[16px] sm:text-[14px] font-semibold">
                      {profileData.email}
                    </p> : ""}
                    <div className="flex flex-col gap-1 items-center justify-center">
                      <div className="flex gap-2 sm:gap-3 justify-center items-center">
                        <p className="text-[#585858] font-medium text-[13px] lg:text-[16px] sm:text-[13px]">
                          Country:{" "}
                          <span className="font-normal text-[#585858]">
                            {profileData.country}
                          </span>{" "}
                        </p>
                        <p className="text-[#585858] font-medium text-[13px] lg:text-[16px] sm:text-[13px]">
                          City:{" "}
                          <span className="font-normal text-[#585858]">
                            {profileData.city}
                          </span>
                        </p>
                      </div>
                      <p className="text-[#585858] font-medium text-[13px] lg:text-[16px] sm:text-[13px]">
                        Timezone:{" "}
                        <span className="font-normal text-[#585858]">
                          {profileData.time_zone}
                        </span>
                      </p>
                      {profile_type === 'Teacher' && profileData.linkedin_profile ? <div className="text-center font-semibold text-[#585858] text-[13px] lg:text-[16px] sm:text-[13px]">
                        <p className="text-[#585858] font-medium">Linkedin Profile</p>
                        <p className="sm:mx-6 font-normal break-all">{profileData.linkedin_profile}</p>

                      </div> : ''}
                    </div>

                  </div>
                  <div className="my-2 text-center text-[14px] sm:hidden">
                    {profileData.about}..{" "}
                    {/* <span className="text-blue-500">read more</span> */}
                  </div>
                  {profile_type === 'Student' ? <div className="flex flex-col gap-2 items-center justify-center w-full sm:w-full px-6 sm:px-0">
                    <div className="flex flex-col gap-2 sm:gap-4 items-center justify-center w-full px-0 sm:px-0">
                      <button className="flex flex-row items-center justify-center gap-3 w-full bg-gradient px-2 py-2 drop-shadow-md rounded-full" onClick={handleBook}>
                        <TbPhoneCall className="cursor-pointer w-6 h-6 text-white" />
                        <span className="text-[16px] lg:text-[18px] sm:text-[14px] text-white font-medium">
                          Book a Call </span>
                      </button>
                      <button className="flex flex-row items-center justify-center gap-3 sm:gap-2 lg:gap-3 w-full bg-gradient sm:px-1 px-2 py-2 drop-shadow-md rounded-full" onClick={handleConference}>
                        <BsPersonVideo2 className="cursor-pointer w-6 h-6 text-white" />
                        <span className="text-[16px] lg:text-[18px] sm:text-[14px] text-white font-medium">
                          Book a Conference </span>
                      </button>
                      <button className="flex flex-row items-center justify-center gap-3 sm:gap-2 lg:gap-3 w-full bg-gradient sm:px-1 px-2 py-2 drop-shadow-md rounded-full" onClick={handleCourses}>
                        <TbCertificate className="cursor-pointer w-6 h-6 text-white" />
                        <span className="text-[16px] lg:text-[18px] sm:text-[14px] text-white font-medium">
                          Enroll to Course </span>
                      </button>
                    </div>
                  </div> :
                    <div className="flex flex-col gap-2 sm:gap-4 items-center justify-center w-full px-8 sm:px-0">
                      <button className="flex flex-row items-center justify-center gap-3 w-full bg-gradient px-2 py-2 drop-shadow-md rounded-full" onClick={handleshareTeacher}>
                        <TbUpload className="cursor-pointer w-6 h-6 text-white" />
                        <span className="text-[16px] lg:text-[18px] sm:text-[16px] text-white font-medium">
                          Share Calendar </span>
                      </button>
                      <button className="flex flex-row items-center justify-center gap-3 w-full bg-gradient px-2 py-2 drop-shadow-md rounded-full" onClick={() => handleStartChat(profileData.id || profileData.userId)}>
                        <BsFillChatDotsFill className="cursor-pointer w-6 h-6 text-white" />
                        <span className="text-[16px] lg:text-[18px] sm:text-[16px] text-white font-medium" >
                          Start a Chat </span>
                      </button>
                    </div>}
                </div>
              </div>

              {/* <!-- social only t --> */}
              {profile_type === 'Student' ? <div className="hidden md:block w-full">
                <div className="border-2 bg-white rounded-[50px] sm:rounded-[25px] drop-shadow-md py-3 px-5 sm:px-2">
                  <div className="flex justify-center sm:justify-start">
                    <h1 className="border-2 sm:border-none border-orange-400 font-semibold shadow-md sm:shadow-none sm:text-left rounded-full px-2 py-1 text-center text-gradient text-[18px] md:text-2xl">
                      Social Links
                    </h1>
                  </div>

                  <div className="flex flex-col gap-2 mt-4 p-1">
                    <div className="flex flex-col sm:flex-row flex-wrap font-bold text-[#585858] text-[14px] text-center">
                      Linkedin Profile :
                      <span className="font-normal break-all text-left">
                        {profileData.linkedin_profile}
                      </span>
                    </div>
                    <div className="flex flex-col sm:flex-row flex-wrap font-bold text-[#585858] text-[14px] text-center">
                      Facebook Profile :
                      <span className="font-normal break-all text-left">
                        {profileData.facebook_profile}
                      </span>
                    </div>
                    <div className="flex flex-col sm:flex-row flex-wrap font-bold text-[#585858] text-[14px] text-center">
                      Instagram Profile :
                      <span className="font-normal break-all text-left">
                        {profileData.instagram_profile}
                      </span>
                    </div>
                    <div className="flex flex-col sm:flex-row flex-wrap font-bold text-[#585858] text-[14px] text-center">
                      X Profile :
                      <span className="font-normal break-all text-left">{profileData.x_profile}</span>
                    </div>
                    <div className="flex flex-col sm:flex-row flex-wrap font-bold text-[#585858] text-[14px] text-center">
                      Personal Website :
                      <span className="font-normal break-all text-left">
                        {profileData.personal_website}
                      </span>
                    </div>
                  </div>
                </div>
              </div> : ''}

              {/* <!-- start chat button only t--> */}
              {profile_type === 'Student' ? <div className="hidden md:flex flex-col items-center justify-center mt-1 w-full">
                <button className="flex flex-row items-center justify-center gap-4 w-full bg-white px-2 py-2 drop-shadow-md border-2 border-orange-500 rounded-full cursor-pointer" onClick={() => handleStartChat(profileData.id)}>
                  <BsFillChatDotsFill className="w-6 h-6 text-orange-400" />
                  <span className="text-[16px] md:text-[18px] text-gradient font-semibold" >
                    Start a Chat
                  </span>
                </button>
              </div> : ''}
            </div>
            {profile_type === 'Student' ? <div className="col-span-2 flex flex-col w-full gap-6">
              {/* <!-- About only t--> */}
              <div className="hidden sm:block">
                <div className="border-2 bg-white rounded-[50px] sm:rounded-[25px] drop-shadow-md p-3 sm:py-2 sm:px-4 lg:py-4">
                  <div className="flex justify-center sm:justify-start">
                    <h1 className="border-2 sm:border-none border-orange-400 font-semibold shadow-md sm:shadow-none sm:text-left rounded-full px-2 md:px-0 py-1 text-center text-gradient text-[18px] sm:text-[20px] lg:text-[24px] ">
                      About
                    </h1>
                  </div>
                  <div className="">
                    <p className="text-center sm:text-left font-normal text-[14px] sm:text-[16px] lg:text-[18px] max-w-[683px]">
                      {profileData.about}{" "}
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- professional only t--> */}
              <div className="">
                <div className="border-2 bg-white sm:rounded-[25px] rounded-[25px] drop-shadow-md mt-8 sm:mt-0 p-3 sm:py-2 sm:px-4 lg:py-4">
                  <div className="flex justify-center sm:justify-start">
                    <h1 className="border-2 sm:border-none border-orange-400 font-semibold shadow-md sm:shadow-none sm:text-left rounded-full px-2 md:px-0 py-1 text-center text-gradient text-[18px] sm:text-[20px] lg:text-[24px] ">
                      Professional Details
                    </h1>
                  </div>
                  <div className="flex flex-col gap-2 mt-4">
                    <div className="grid md:grid-cols-2 gap-2 ">
                      <div className="font-semibold text-[#585858] text-[14px] sm:text-[14px] lg:text-[16px] text-center md:text-left sm:text-left">
                        Institute Name:
                        <span className="text-center md:text-left font-normal text-[14px] sm:text-[14px] lg:text-[16px]">
                          {profileData.institute}
                        </span>
                      </div>

                      <div className="text-[#585858]">
                        <div className="font-bold text-[14px] sm:text-[14px] lg:text-[16px] text-center md:text-start sm:text-left">
                          Expert in
                        </div>
                        <div className="flex flex-col gap-1 text-[14px] sm:text-[14px] lg:text-[16px] text-center md:text-start sm:text-left">
                          <div>
                            <span className="font-medium">Category:</span> {profileData.category}
                          </div>
                          <div>
                            <span className="font-medium">SubCategory:</span> {profileData.subCategory && profileData.subCategory.map((subCategory) => {
                              return <span>{" "}{subCategory}{","}</span>;
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-2 gap-2">
                      <div>
                        <div className="font-bold text-[14px] sm:text-[14px] lg:text-[16px] text-[#585858] text-center md:text-left sm:text-left">
                          Awards and Certifications
                        </div>
                        <div className="text-[14px] sm:text-[14px] lg:text-[16px] text-center md:text-left sm:text-left">
                          {profileData.awards_and_certifications}
                        </div>
                        {/* <div className="text-[14px] sm:text-[14px] lg:text-[16px] text-center md:text-left">
                        Certification Details
                      </div> */}
                      </div>
                      <div>
                        <div className="font-bold text-[14px] sm:text-[14px] lg:text-[16px] text-[#585858] text-center md:text-left sm:text-left">
                          Languages Known
                        </div>
                        {profileData.languages &&
                          profileData.languages.map((language) => {
                            return (
                              <div className="text-[14px] sm:text-[14px] lg:text-[16px] text-center md:text-left sm:text-left">
                                {language.language} ({language.proficiency})
                              </div>
                            )
                          })
                        }
                        {/* <div className="text-[14px] sm:text-[14px] lg:text-[16px] text-center md:text-left">
                        French (Fluent)
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- social only t --> */}
              <div className="block md:hidden">
                <div className="border-2 bg-white rounded-[25px] drop-shadow-md p-3">
                  <div className="flex justify-center sm:justify-start">
                    <button className="border-2 border-orange-400 sm:border-none sm:shadow-none font-semibold shadow-md rounded-full px-2 py-1 text-center sm:text-start text-gradient text-[18px]">
                      Social Links
                    </button>
                  </div>

                  <div className="flex flex-col gap-2 mt-4 p-1">
                    <div className="flex flex-col font-bold text-[#585858] text-[14px] text-center sm:text-start">
                      Linkedin Profile :
                      <span className="font-normal break-all">{profileData.linkedin_profile}</span>
                    </div>
                    <div className="flex flex-col font-bold text-[#585858] text-[14px] text-center sm:text-start">
                      Facebook Profile :
                      <span className="font-normal break-all">{profileData.facebook_profile}</span>
                    </div>
                    <div className="flex flex-col font-bold text-[#585858] text-[14px] text-center sm:text-start">
                      Instagram Profile :
                      <span className="font-normal break-all">{profileData.instagram_profile}</span>
                    </div>
                    <div className="flex flex-col font-bold text-[#585858] text-[14px] text-center sm:text-start">
                      X Profile :
                      <span className="font-normal break-all">{profileData.x_profile}</span>
                    </div>
                    <div className="flex flex-col font-bold text-[#585858] text-[14px] text-center sm:text-start">
                      Personal Website :
                      <span className="font-normal break-all">{profileData.personal_website}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- ratings only t --> */}
              <div>
                <div className="border-2 bg-white rounded-[25px] sm:rounded-[25px] drop-shadow-md p-3 sm:p-6">
                  <div className="flex justify-center md:justify-start">
                    <h1 className="border-2 sm:border-none border-orange-400 font-semibold shadow-md sm:shadow-none sm:text-left rounded-full px-2 md:px-0 py-1 text-center text-gradient text-[18px] md:text-2xl">
                      Ratings
                    </h1>
                  </div>
                  <div className="flex flex-row justify-center gap-2 mt-4">
                    <button
                      className={`w-1/2 md:max-w-[137px] px-2 py-2 text-[16px] font-semibold rounded-full ${activeTab === 'Calls'
                        ? 'text-white bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58]'
                        : 'text-orange-500 bg-transparent border border-orange-600 hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58]'
                        }`}
                      onClick={() => setActiveTab('Calls')}
                    >
                      Calls
                    </button>

                    <button
                      className={`w-1/2 md:max-w-[137px] px-2 py-2 text-[16px] font-semibold rounded-full ${activeTab === 'Conferences'
                        ? 'text-white bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58]'
                        : 'text-orange-500 bg-transparent border border-orange-600 hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58]'
                        }`}
                      onClick={() => setActiveTab('Conferences')}
                    >
                      Conferences
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 mt-4 p-1">
                    {Rating_Review && activeTab === 'Calls' && renderReviews(Rating_Review.callReviews.reviews)}
                    {Rating_Review && activeTab === 'Conferences' && renderReviews(Rating_Review.conferenceReviews.reviews)}
                  </div>
                </div>
              </div>
              {/* <!-- start chat button only t--> */}
              <div className="flex md:hidden flex-col items-center justify-center">
                <button className="flex flex-row items-center justify-center gap-2 w-10/12 bg-white px-2 py-2 drop-shadow-md border border-orange-500 rounded-full" onClick={() => handleStartChat(profileData.id || profileData.userId)}>
                  <BsFillChatDotsFill
                    className="cursor-pointer w-6 h-6 text-orange-400"
                  />
                  <span className="text-[16px] text-gradient font-semibold" >
                    Start a Chat
                  </span>
                </button>
              </div>
            </div> :
              <div className="col-span-2">
                <div className="hidden sm:block border-2 bg-white rounded-[50px] sm:rounded-[25px] drop-shadow-md p-3 sm:py-2 sm:px-4 lg:py-4">
                  <div className="flex justify-center sm:justify-start">
                    <button
                      className="border-2 sm:border-0 border-orange-400 rounded-full px-2 sm:px-0 py-1 text-center text-gradient sm:font-bold text-[18px] sm:text-[20px] lg:text-[24px] ">
                      About
                    </button>
                  </div>
                  <div>
                    <div className="text-[14px] sm:text-[16px] lg:text-[18px] max-w-[683px] text-center sm:text-start text-[#585858]">{profileData.about}</div>
                  </div>
                </div>
                {profile_type === 'Teacher' ? <div>
                  <div className="border-2 bg-white rounded-[25px] sm:rounded-[25px] drop-shadow-md mt-3 p-3 sm:py-2 sm:px-4 lg:py-4">
                    <div className="flex justify-center sm:justify-start">
                      <button
                        className="border-2 sm:border-0 border-orange-400 rounded-full px-2 sm:px-0 py-1 mb-2 text-center text-gradient sm:font-bold font-semibold text-[18px] sm:text-[20px] lg:text-[24px] ">
                        Education Details
                      </button>
                    </div>
                    <div>
                      <div>
                        <div className="font-bold text-[14px] sm:text-[14px] lg:text-[16px] text-center sm:text-start text-[#585858]">Currently Pursuing</div>
                        <div className="flex flex-col gap-1 text-[14px] sm:text-[14px] lg:text-[16px] text-center sm:text-start">
                          <div>
                            <span className="font-medium">Category:</span> {profileData.category}
                          </div>
                          <div>
                            <span className="font-medium">SubCategory:</span> {profileData.subCategory && profileData.subCategory.map((subCategory) => {
                              return <span>{" "}{subCategory}{","}</span>;
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <div className="font-bold text-[14px] sm:text-[14px] lg:text-[16px] text-center sm:text-start text-[#585858]">Interested in
                        </div>
                        <div className="flex flex-col gap-1 text-[14px] sm:text-[14px] lg:text-[16px] text-center sm:text-start">
                          <div>
                            <span className="font-medium">Interested Category:</span> {profileData.interest_category}
                          </div>
                          <div>
                            <span className="font-medium">Interested SubCategory:</span> {profileData.interest_subCategory && profileData.interest_subCategory.map((interest_subCategory) => {
                              return <span>{" "}{interest_subCategory}{","}</span>;
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <div className="font-bold text-[14px] sm:text-[14px] lg:text-[16px] text-[#585858] text-center sm:text-start">Languages Known</div>
                        {profileData.languages && profileData.languages.map((language, index) => (
                          <div key={index} className="text-[14px] sm:text-[14px] lg:text-[16px] text-center sm:text-start">
                            {language.language} ({language.proficiency})
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="border-2 w-full bg-white rounded-[25px] sm:rounded-[25px] drop-shadow-md mt-3 p-3 sm:py-2 sm:px-4 lg:py-4">
                    <div className="flex justify-center sm:justify-start">
                      <button className="border-2 sm:border-0 border-orange-400 rounded-full px-2 sm:px-0 py-1 mb-2 text-center text-gradient sm:font-bold font-semibold text-[18px] sm:text-[20px] lg:text-[24px]">
                        Relocation Details
                      </button>
                    </div>
                    <div className="mt-3 sm:mt-0">
                      <div>
                        <div className="font-bold text-[14px] sm:text-[14px] lg:text-[16px] text-center sm:text-start text-[#585858]">
                          {/* Countries to relocate */}
                        </div>
                        <div className="text-[14px] sm:text-[14px] lg:text-[16px] text-center sm:text-start">
                          {/* {profileData.preferred_locations &&
                          profileData.preferred_locations.map((location) => location.country).join(", ")} */}
                        </div>
                      </div>
                      <div className="mt-3">
                        <div className="font-bold text-[14px] sm:text-[14px] lg:text-[16px] text-center sm:text-start text-[#585858]">
                          {/* Cities to relocate */}
                        </div>
                        <div className="text-[14px] sm:text-[14px] lg:text-[16px] text-center sm:text-start">
                          {/* {profileData.preferred_locations &&
                          profileData.preferred_locations.map((location) => location.cities).join(", ")} */}
                          {profileData.preferred_locations?.map((location, index) => (
                            <div key={location._id} style={{ marginBottom: '20px' }} className="flex flex-col">
                              <h3 className="font-bold text-[14px] sm:text-[14px] lg:text-[16px] text-center sm:text-start text-[#585858]">Country:<span className="text-[14px] sm:text-[14px] lg:text-[16px] text-center sm:text-start font-medium">{' '} {location.country}</span></h3>
                              <h4 className="font-bold text-[14px] sm:text-[14px] lg:text-[16px] text-center sm:text-start text-[#585858]">State:<span className="text-[14px] sm:text-[14px] lg:text-[16px] text-center sm:text-start font-medium">{' '} {location.state}</span></h4>
                              <h5 className="font-bold text-[14px] sm:text-[14px] lg:text-[16px] text-center sm:text-start text-[#585858]">Cities:<span className="text-[14px] sm:text-[14px] lg:text-[16px] text-center sm:text-start font-medium">{' '}
                                {(location.cities).join(", ")}
                                {' '} </span>
                              </h5>
                            </div>
                          ))}

                        </div>
                      </div>
                    </div>
                  </div>
                </div> : ''}
              </div>}
          </div>
          <MainMenu />
        </div>
      </div>
    </>
  );
};

export default ViewProfile;
