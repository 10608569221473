import React from 'react'
import NavHome from '../../components/nav/navHome';
import Footer from '../../components/nav/footer';
import Header from '../../components/headerBackground'
import Cookies from "js-cookie";
import NavBar from '../../components/nav/navBar';
import price from '../../assets/img/price-2.jpg';

const pricingPage = () => {
    const isAuthenticated = Cookies.get("authToken") !== undefined;
    // const url = process.env.REACT_APP_API_URL;
    return (
        <div className=''>
            {isAuthenticated ? <NavBar /> : <NavHome />}
            <section className="mb-0 sm:mb-0 font-primary ">
                <Header />
                <div className='sm:mt-20 lg:mt-28 mt-[160px] mx-2 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 flex flex-col justify-center gap-4'>
                    <h2 className='font-semibold sm:text-2xl lg:text-3xl text-xl text-center text-[#585858]'>PRICING</h2>
                    <div className='sm:flex flex-cols gap-6 items-start justify-center mt-10'>
                        <div className='sm:w-[40%] w-[100%] flex items-center justify-center'>
                            <img src={price} alt="price" className='sm:w-[400px] sm:h-[400px] lg:w-[450px] lg:h-[450px] h-[250px] w-[250px]' />
                        </div>

                        <div className='sm:w-[60%] text-base sm:text-base lg:text-lg flex flex-col gap-6 text-left mt-2 mb-6 mx-2'>
                            <p className='font-medium underline'>For India</p>
                            <p className='font-medium pl-[20px]'>Subscription Fees (for Teachers and Subject Matter Experts)–</p>
                            <ul type='1' style={{ listStyleType: 'disc', paddingLeft: '40px' }} className='font-normal'>
                                <li>
                                    <p className='font-normal'>Premium Subscription: Rs. 499/- per month</p>
                                </li>

                            </ul >
                            <p className='font-medium pl-[20px]'>Service Charge –</p>

                            <ol type='1' style={{ listStyleType: 'lower-roman', paddingLeft: '40px' }} className='font-normal flex flex-col gap-4'>
                                <li>
                                    <p className='font-normal'>5% Service Charge on the call charges set by Teachers and Subject Matter
                                        Experts.</p>
                                </li>
                                <li>
                                    <p className='font-normal'>5% Service Charge on the conference/class charges set by Teachers and
                                        Subject Matter Experts.</p>
                                </li>
                            </ol>
                            <p className='font-medium underline'>For All Other Countries</p>
                            <p className='font-medium pl-[20px]'>Subscription Fees (for Teachers and Subject Matter Experts)–</p>
                            <ul type='1' style={{ listStyleType: 'disc', paddingLeft: '40px' }} className='font-normal'>
                                <li>
                                    <p className='font-normal'>Premium Subscription: $6 (USD) per month + Taxes (as applicable)</p>
                                </li>

                            </ul >
                            <p className='font-medium pl-[20px]'>Service Charge –</p>

                            <ol type='1' style={{ listStyleType: 'lower-roman', paddingLeft: '40px' }} className='font-normal flex flex-col gap-4'>
                                <li>
                                    <p className='font-normal'>9% Service Charge on the call charges set by Teachers
                                        and Subject Matter Experts.</p>
                                </li>
                                <li>
                                    <p className='font-normal'>9% Service Charge on the conference/class charges set
                                        by Teachers and Subject Matter Experts.</p>
                                </li>
                            </ol>

                            <p>Teachers and Subject Matter experts can set their own Call and Conference/Class
                                charges. Teachers can also create free calls and conferences for students.</p>
                            <p>CareerGuidanceBuddy (CGB ORG) reserves the right to change the prices and
                                Service Charge % on a time-to-time basis without any prior intimation to
                                Teachers/Subject Matter Experts or Students/Parents</p>
                            <p><span className='font-medium'>Note:</span> Taxes will be added as applicable on all invoices including
                                Subscription, Call and Conference/Class charges.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default pricingPage