import React, { useState,useEffect } from 'react';
import logo from '../../assets/img/logo.svg';
import { useLocation }  from 'react-router-dom';
import bg from '../../assets/img/background-2.png';
import { BsChevronLeft } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import {getParamByISO} from 'iso-country-currency'
import Cookies from 'js-cookie';
import axios from "axios";
import NavBar from '../nav/navBar';
import { addHours, addMinutes, format, parse } from 'date-fns';
import MainMenu from '../MenuBottom/MainMenu';
import { useNavigate } from 'react-router-dom';





const Edit_Time_Slot = ({setMessage, setShowMessage}) => {
  const location = useLocation();
  const {id,start_time,end_time,amount,call_id,Url}=location.state
  // console.log("Edit page :",id,start_time,end_time,amount,call_id,Url)
  const [selectedTime, setSelectedTime] = useState(String(start_time)); // Initial time
  const [endTime, setEndTime] = useState(String(end_time));
  const [selectedDuration, setSelectDuration] = useState('1 Hr');
  const [isOpenDuration, setIsOpenDuration] = useState(false)
  const [currency, setCurrency] = useState('$');
  const [currencyCode, setCurrencyCode] = useState('USD');
  const [isOpenCurrency, setIsOpenCurrency] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(amount);
  const [paypal_fee,setPaypal_fee]=useState(0.30)
  const [url,setUrl]=useState(Url)
  const [isIndia, setIsIndia] = useState(false);
  const [fetched,setFetched]=useState(false);

  const loggedInUserId = Cookies.get('loggedInUserId');
  const apiUrl = process.env.REACT_APP_API_URL;


const fetchUserDetails = async (setCurrency, setCurrencyCode, setIsIndia,setFetched) => {
  try {
    const response = await axios.get(`${apiUrl}/api/auth/user_details/${loggedInUserId}`);
    const user = response.data.user_Details;
    // console.log(user.country)
    if (!user.country) {
      // console.error('No country name found in user details');
      return; // Exit early if no country name is available
    }

    if (user.country === 'India') {
      setCurrency('₹');
      setCurrencyCode('INR');
      setPaypal_fee(0)
      setIsIndia(true);
    } else {
      setIsIndia(false);
    }
    setFetched(true)
  } catch (error) {
    console.error('Error fetching user details:', error);
  }
};

  
  const authToken = Cookies.get("authToken");
  const navigate = useNavigate();

   // BASED ON USER LOCATION GET THE CURRENCY 
 
   useEffect(() => {
    // const fetchCurrency = async (countryName) => {
    //   try {
    //     console.log("Inside the fetch currency ===>", countryName);
    //     const response = await fetch(`https://restcountries.com/v3.1/name/${countryName}`);
    //     const data = await response.json();
    //     console.log("API Response:", data);
  
    //     if (data.length > 0) {
    //       // Filter to get the exact match
    //       const country = data.find(
    //         item => item.name.common.toLowerCase() === countryName.toLowerCase()
    //       );
    //       if (country) {
    //         const currencyCode = Object.keys(country.currencies)[0]; // Get the first currency code
    //         setCountryCode(currencyCode);
    //         const symbol = country.currencies[currencyCode].symbol;
    //         setCurrency(symbol);
    //         console.log("Currency Symbol:", symbol);
    //       } else {
    //         setCurrency('Currency symbol not found');
    //       }
    //     } else {
    //       setCurrency('Currency symbol not found');
    //     }
    //   } catch (error) {
    //     console.error('Error fetching country data:', error);
    //     setCurrency('Error fetching currency information');
    //   }
    // };

    fetchUserDetails(setCurrency, setCurrencyCode, setIsIndia,setFetched);
  }, [loggedInUserId,fetched]);

  const handleBack = () => {
    // Handle back navigation
    navigate('/calender_page')
  };
  

  const handlePriceChange = (value) => {
    // Handle the price change here
    setSelectedPrice(value);
  };

  const handleDone = async() =>{
    // console.log("Inside Done");
    //  console.log("updated startTime ",selectedTime)
    //  console.log("updated endtime",endTime)
    //  console.log("updated price",selectedPrice)
    //  console.log("updated currency",currency)
    //  console.log("updated CurrencyCode",currencyCode)
    //  console.log("is Indain",isIndia)
    const updatedData ={
      start_time : selectedTime,
      end_time : endTime,
      price: selectedPrice,
      call_id : call_id,
      currency:currency,
      currencyCode:currencyCode,
      paypal_fee:paypal_fee,
      Url:url,
    }
      try{
        // console.log("Inside try")
        const updatedResponse = await  axios.put(`${apiUrl}/api/calender/update/timeslots/${id}`,updatedData,{
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        });
        
          console.log("Successfully updated call Details and time slot ===> ",updatedResponse)
          navigate('/calender_page');  // Navigate to calendar page upon success
          setShowMessage(true);
          setMessage("Updated Successfully");
      

      }catch(error){
         console.log(error)
      }
    
  }


  const toggleDurationDropdown = () => {
    setIsOpenDuration(!isOpenDuration)
  }


  // BASED ON DURATION  AND START TIME CHANGE THE END TIME

  const handleDurationSelection = (duration) => {
    setSelectDuration(duration);
    setIsOpenDuration(false);
    setEndTime(calculateEndTime(selectedTime, duration));
  }

  // DURATION SELECTION

  function calculateEndTime(startTime, duration) {
    const parsedStartTime = parse(startTime, 'HH:mm', new Date());

    let endTime = new Date(parsedStartTime);

    switch (duration) {
      case '1 Hr':
        endTime = addHours(endTime, 1);
        break;
      case '30 Min':
        endTime = addMinutes(endTime, 30);
        break;
      
      default:
        break;
    }

    return format(endTime, 'HH:mm');
  };



  const currencyOptions = [
   
    { symbol: 'A$', code: 'AUD' , paypal_fee:0.30 }, // Australian Dollar
    { symbol: 'C$', code: 'CAD',paypal_fee:0.30 }, // Canadian Dollar
   
    { symbol: '€', code: 'EUR',paypal_fee:0.35 }, // European Euro
    { symbol: '£', code: 'GBP',paypal_fee:0.20 }, // Pound Sterling
  
    { symbol: '¥', code: 'JPY',paypal_fee:40.00  }, // Japanese Yen
   
    { symbol: 'S$', code: 'SGD',paypal_fee:0.50  }, // Singapore Dollar
  
    { symbol: '$', code: 'USD',paypal_fee:0.30 }, // United States Dollar
   
];


  const handleCurrency = (symbol, code,paypal_fee) => {
    setCurrency(symbol);
    setCurrencyCode(code);
    setPaypal_fee(paypal_fee)
    setIsOpenCurrency(false);
  };


  const toggleDropdown = () => {
    setIsOpenCurrency(!isOpenCurrency);
  };

  const handleUrlChange = (e) =>{
    setUrl(e.target.value);
  }

  useEffect(() => {
    setEndTime(calculateEndTime(selectedTime, selectedDuration));
  }, [selectedTime, selectedDuration]);



  return (
    <div className=''>
      {/* ----------------------- LOGO WITH DESIGN ---------------------- */}

      <div
          className=" sm:hidden bg-contain bg-no-repeat w-full h-[280px] sm:mx-auto sm:w-full sm:max-w-md max-[420px]:bg-cover flex justify-center"
          style={{backgroundImage: `url("${bg}")`}}
        >
          <div className='mt-14'>
            <img src={logo} alt="logo" />
          </div>
        </div>

        <NavBar />


        <div className='lg:flex lg:justify-center'>
          {/* --------------------------------- SETTING  AVAILABILITY FOR CALLS ------------------------------- */}

          <div className='sm:border border-orange-500 rounded-[50px] sm:max-w-[746px] sm:mx-auto sm:w-full px-3 sm:px-6 sm:py-6 sm:mt-14 mb-10 h-fit'>
            <div className='flex gap-1 items-center mt-[-0px] sm:mt-0 mb-[10px]'>

              {/* --------------------- BACK NAVIGATION ----------------------------- */}

              <div className='flex cursor-pointer' onClick={handleBack}>
                <BsChevronLeft className='text-[#AFAFAF] text-2xl'  />
                <div className='text-[#AFAFAF] font-semibold text-lg'>Back</div>
              </div>
              <div className='text-orange-400 sm:text-[19px] font-bold flex-grow text-center ml-[-50px]'>
                Edit Call
              </div>
            </div>

            {/* ---------------------- Edit Start Time , End Time and Price ----------------------------- */}

            <div className=' flex justify-center items-center'>
              <div className="w-[450px]  max-h-fit shadow-lg rounded-3xl pl-3 py-10 mt-2 flex flex-col justify-center items-center  ">
              <div className='flex items-center sm:justify-center gap-5 mb-2'>
                <div className=' w-56 h-12 rounded-full ml-2 sm:mt-2 px-4 py-3 bg-white text-center ' style={{boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}>
                    Duration
                </div>
                <div>
                  <div className="relative inline-block">
                    <div
                      className="bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 text-center py-3 rounded-full text-white w-32 h-12 mt-3 flex justify-center items-center gap-3 cursor-pointer"
                      onClick={toggleDurationDropdown}
                      >
                      {selectedDuration} <IoIosArrowDown
                          className={`transform ${isOpenDuration ? 'rotate-180' : ''} transition-transform duration-200` }
                          />
                    </div>
                    {isOpenDuration && (
                      <div className="absolute  mt-1 w-32 bg-white border rounded-lg shadow-lg z-10">
                        <div
                          className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleDurationSelection('1 Hr')}
                          >
                            1 Hr
                        </div>
                        <div
                          className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                          onClick={() => handleDurationSelection('30 Min')}
                          >
                            30 Min
                        </div>
                        
                      </div>
                    )}
                  </div>
                </div>
              </div>
                <div className='flex  gap-3'>
                  <div className='flex flex-col'>
                  <div class="text-sm sm:text-[16px] px-5 py-4 sm:px-[20px] sm:py-[23px] border border-orange-500 rounded-[25px]">
                  <p class="text-[#585858 text-center">Start Time</p>
                  
                    <input
                    type="time"
                    className='bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 py-1 px-5 rounded-full text-white mt-2 input-time'
                    value={selectedTime}
                    onChange={(e) => setSelectedTime(e.target.value)}
                    />
                  
                </div>
                  </div> 
                  <div class="text-sm sm:text-[16px] px-5 py-4 sm:px-[25px] sm:py-[22px] border border-orange-500 rounded-[25px]">
                  <p class="text-[#585858 text-center">End Time</p>
                  
                  <div
                    class="bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 py-[6px] px-7 rounded-full  mt-2">
                    {endTime}
                  </div>
                  
                </div>
              
                </div>
                
                <div className='bg-gray-300 w-80 text-center mt-5 mb-3 rounded-full p-2'>
                        Added {selectedTime} - {endTime}
                </div>
                <div className='flex mb-3'>
                  <div className='flex  justify-center items-center gap-2'>
                      <div className='w-32 h-12 rounded-full ml-2 mt-5 px-4 py-3 text-center' style={{boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}>
                        Set Price
                      </div>
                  </div>
                  {fetched ? (
                    <>
                  <div className="relative inline-block mr-2 mt-5 ml-2">
                      <div
                        className={`bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 text-center py-3 rounded-full text-white w-32 h-12 flex justify-center items-center gap-10 cursor-pointer ${isIndia ? 'cursor-not-allowed' : ''}`}
                        onClick={toggleDropdown}
                        disabled={isIndia}
                      >
                        {currency} {!isIndia && <IoIosArrowDown className={`transform ${isOpenCurrency ? 'rotate-180' : ''} transition-transform duration-200`} />}
                      </div>
                      {isOpenCurrency && !isIndia && (
                        <div className="absolute right-0 mt-1 w-32 bg-white border rounded-lg shadow-lg">
                          {currencyOptions.map((cur) => (
                            <div
                              key={cur.code}
                              className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                              onClick={() => handleCurrency(cur.symbol, cur.code,cur.paypal_fee)}
                            >
                              {cur.symbol} {cur.code}
                            </div>
                          ))}
                        </div>
                        )}
                      </div>
                    <div className="inline-block mt-5">
                      <div className="bg-white text-center py-3 rounded-full text-gray-800 w-32 h-12 flex justify-center items-center"
                        style={{boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}
                      >
                        <span className="text-gray-800 px-2">{currency}</span>
                        <input
                          type="number"
                          className="bg-transparent text-gray-800 text-center w-16 placeholder-gray-500"
                          placeholder="Amount"
                          value={selectedPrice.replace(/[^\d.-]/g, '')}
                          onChange={(e) => handlePriceChange(e.target.value)}
                          onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                        />
                      </div>
                     
                    </div>
                    </>
                      ):('')}
                  </div>
                  <div className='w-full' >
                        <input type='text' placeholder='URL' onChange={handleUrlChange} value={url} className=' mt-10 rounded-3xl border-2 border-orange-600 p-2 w-full'/>  
                      </div>

                  {/* -------------------- OPERATIONS -------------------------- */}
          
                  <div className="flex justify-center mt-2" style={{ gap: '2rem' }}>
                    <div className='border-2 border-orange-600 rounded-3xl py-2 px-10 bg-transparent text-orange-500 font-semibold  hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 cursor-pointer  flex justify-center gap-2 items-center shadow-lg  ' onClick={handleBack}>Cancel</div>
                    <div className='border-2 border-orange-600 rounded-3xl py-2 px-10 bg-transparent text-orange-500 font-semibold  hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 cursor-pointer  flex justify-center gap-2 items-center shadow-lg ' onClick={handleDone} >Done</div>
                  </div>
              
                </div>
              </div>
            </div>
          </div>

          {/* -------------------------- BOTTOM MENU ---------------------------------------- */}

          <div className='flex justify-center'>
            <MainMenu />
          </div>
    </div>
  );
};

export default Edit_Time_Slot;
