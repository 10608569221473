import React from 'react'
import NavHome from '../../components/nav/navHome';
import Footer from '../../components/nav/footer';
import Header from '../../components/headerBackground'
import Cookies from "js-cookie";
import NavBar from '../../components/nav/navBar';
import refund from '../../assets/img/refund.jpg';
  
const RefundPolicy = () => {
  const isAuthenticated = Cookies.get("authToken") !== undefined;
  // const url = process.env.REACT_APP_API_URL;
  return (
    <div className=''>
      {isAuthenticated ? <NavBar /> : <NavHome />}
      <section className="mb-0 sm:mb-0 font-primary ">
        <Header />
        <div className='sm:mt-20 lg:mt-28 mt-[160px] mx-2 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 flex flex-col justify-center gap-4'>
          <h2 className='font-semibold sm:text-2xl lg:text-3xl text-xl text-center text-[#585858]'>REFUND POLICY</h2>
          <div className='sm:grid grid-cols-2 items-center justify-center'>
            <img src={refund} alt="refund" className='sm:w-[400px] sm:h-[400px] lg:w-[450px] lg:h-[450px] h-[250px] w-[250px]' />
            <div className='text-base sm:text-base lg:text-lg flex flex-col gap-6 text-left mt-2 mb-6'>

              <p>There is a strict no refund policy and cancellation policy for Teacher’s and Subject Matter Expert’s Subscription Fee.</p>

              <p>For Students/Parents if a Teacher or Subject Matter Expert do not join a Call or Conference or Class then the charges paid for the Call or Conference or Class is refunded in 7 working days. </p>

              <p>CGB ORG will intimate the Parents/Students in 24 hours over email about the Class or Call or Conference cancellation.
                They can also enquire about the same via email to support@cgborg.com </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default RefundPolicy