import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Header from '../components/headerBackground';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import SearchChat from '../components/searchChat';
import MainMenu from '../components/MenuBottom/MainMenu';
import NavBar from '../components/nav/navBar';
import SideBar from '../components/SideBar';
import { useSocket } from '../context/SocketContext';
import Loading2 from '../components/Loading2';
import { useNotifications } from '../context/NotificationContext';
// import { encryptId } from '../utils/encryptId';
import { ChatContext } from '../App';
import { Helmet } from 'react-helmet-async';
import MetaDecorator from '../utils/MetaDecorator';

const Chat = ({ fullName, profilePic }) => {
    const [conversations, setConversations] = useState([]);
    const [filteredConversations, setFilteredConversations] = useState([]);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const loggedInUserId = Cookies.get('loggedInUserId');
    const authToken = Cookies.get('authToken');
    const user_type = Cookies.get("user_type");
    const user_type_opp = user_type === 'Teacher' ? "Student" : "Teacher";
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [isEmptySearch, setIsEmptySearch] = useState(true);
    const { socket, onlineUsers, unreadCount, setUnreadCount } = useSocket();
    const url = process.env.REACT_APP_API_URL;

    const navigate = useNavigate();
    const file_url = 'https://cgb-storage.blr1.digitaloceanspaces.com';
    let defaultProfile = `${file_url}/profile-pictures/defaultProfile.png`;
    const { setOtherUserID } = useContext(ChatContext);
    const { markChatNotificationsAsRead } = useNotifications();

    useEffect(() => {
        fetchConversations();
        getUsers();
        if (!socket) return;

        socket.on('lastMessage', (updatedConversation) => {
            setConversations((prevConversations) => {
                const updatedConversations = prevConversations.map((conversation) =>
                    conversation._id === updatedConversation._id ? updatedConversation : conversation
                );

                // Sort conversations based on the latest message timestamp
                const sortedConversations = updatedConversations.sort((a, b) => {
                    const lastMessageA = a.messages.length > 0 ? new Date(a.messages[a.messages.length - 1].createdAt) : new Date(a.createdAt);
                    const lastMessageB = b.messages.length > 0 ? new Date(b.messages[b.messages.length - 1].createdAt) : new Date(b.createdAt);
                    return lastMessageB - lastMessageA;
                });

                setFilteredConversations(sortedConversations);
                return sortedConversations;
            });
        });

        socket.on('unreadCount', (count) => {
            setUnreadCount(count);
        });


        return () => {
            socket.off('lastMessage');
            socket.off('unreadCount');
            // socket.off('messageRead');
        };
    }, [authToken, socket]);

    useEffect(() => {
        // Update isEmptySearch state based on searchQuery
        setIsEmptySearch(searchQuery.trim() === '');
    }, [searchQuery]);

    useEffect(() => {

        markChatNotificationsAsRead(loggedInUserId);
    }, []);

    const fetchConversations = async () => {
        try {
            const response = await axios.get(`${url}/api/messages`, {
                headers: {
                    "Authorization": `Bearer ${authToken}`
                },
            });
            // console.log(response.data);
            const sortedConversations = response.data
                .filter(conversation => conversation.messages.length > 0)
                .sort((a, b) => {
                    const lastMessageA = a.messages.length > 0 ? new Date(a.messages[a.messages.length - 1].createdAt) : new Date(a.createdAt);
                    const lastMessageB = b.messages.length > 0 ? new Date(b.messages[b.messages.length - 1].createdAt) : new Date(b.createdAt);
                    return lastMessageB - lastMessageA;
                });

            setConversations(sortedConversations);
            setFilteredConversations(sortedConversations); // Initialize filtered conversations with sorted conversations
        } catch (error) {
            console.error('Error fetching conversations:', error);
        } finally {
            setLoading(false);
        }
    };

    const getUsers = async () => {
        try {
            const response = await axios.get(`${url}/api/user-info/get-users`, {
                headers: {
                    "Authorization": `Bearer ${authToken}`
                }
            });
            // console.log(response.data);
            const filteredUsers = response.data.filter(user =>
                user._id !== loggedInUserId &&
                user.type !== user_type // Filter out users with the same user type as the logged-in user
            );
            setFilteredUsers(filteredUsers);
            setUsers(filteredUsers);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (query) => {
        setIsSearching(true);

        const filtered = users.filter(user =>
            user.fullName.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredUsers(filtered);
    };

    const handleUserClick = async (userId) => {
        // Check if a conversation with this user already exists
        const existingConversation = conversations.find(conversation =>
            conversation.participants.some(participant => participant._id === userId)
        );
        // console.log(existingConversation)
        if (existingConversation) {
            const otherParticipant = existingConversation.participants.find(participant => participant._id !== loggedInUserId);
            if (otherParticipant) {
                setOtherUserID(otherParticipant._id);
                localStorage.setItem('otheruserID', otherParticipant._id);
                navigate('/open-chat');
                // navigate(`/open-chat/${otherParticipant._id}`); // Navigate to existing conversation
            }
        } else {
            // Create a new conversation
            try {
                const response = await axios.post(`${url}/api/messages`, {
                    userId: loggedInUserId,
                    otherUserId: userId // Use userId directly since it's the ID of the clicked user
                }, {
                    headers: {
                        "Authorization": `Bearer ${authToken}`
                    }
                });
                // console.log('New conversation created:', response.data);
                // After creating the conversation, navigate to the chat
                // navigate(`/open-chat/${userId}`);
                setOtherUserID(userId);
                localStorage.setItem('otheruserID', userId);
                navigate('/open-chat');
            } catch (error) {
                console.error('Error creating conversation:', error);
            }
        }
        setUnreadCount((prevUnreadCount) => {
            if (!existingConversation || !existingConversation._id) return prevUnreadCount;
            const newUnreadCount = { ...prevUnreadCount };
            newUnreadCount[existingConversation._id] = 0;
            return newUnreadCount;
        });
    };

    const handleClearSearch = () => {
        setSearchQuery(''); // Clear the search query
        setIsSearching(false); // Set searching state to false
    };

    const formatDateTime = (dateString) => {
        const today = new Date();
        const messageDate = new Date(dateString);
        if (
            today.getDate() === messageDate.getDate() &&
            today.getMonth() === messageDate.getMonth() &&
            today.getFullYear() === messageDate.getFullYear()
        ) {
            return messageDate.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' });
        } else {
            return messageDate.toLocaleDateString([], { year: 'numeric', month: 'numeric', day: 'numeric' });
        }
    };
    const keywords = ["elearning", "education", "online class", "student","teacher","learning"]; 
    return (
        <>
            {/* <Helmet>
                <title>Chat - Career Guidance Buddy</title>
                <meta name="keywords" content="chat with student,chat with teacher, education, online class" />
            </Helmet> */}
            <MetaDecorator title={'Chat - Career Guidance Buddy'} description={'Chat with teacher and resolve doubts!'} imageUrl={''} keywords={keywords} imageAlt={''} url={''}/>
            <section className="mb-0 sm:mb-0 font-primary">
                <NavBar />
                <div className='sm:mt-24 lg:mt-28 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5'>
                    <Header />
                    <SearchChat
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        handleSearch={handleSearch}
                        handleClearSearch={handleClearSearch} // Add handleClearSearch prop
                    />
                    {loading ? (
                        <Loading2 />
                    ) : (
                        <div className='md:grid grid-cols-3 gap-4 sm:min-h-[85vh] sm:py-[40px] justify-center'>
                            <SideBar profilePic={profilePic} fullName={fullName} />
                            {isEmptySearch ? (
                                <div className='overflow-y-auto drop-shadow-md bg-white rounded-[40px] py-2 mx-auto sm:mx-0 w-11/12 sm:w-auto col-span-2 sm:h-full sm:rounded-[25px] sm:shadow-md min-h-full sm:mt-6 md:mt-0' style={{ maxHeight: 'calc(100vh - 350px)' }}>
                                    {filteredConversations.length > 0 ? (
                                        filteredConversations.map(conversation => {
                                            const otherParticipant = conversation.participants.find(participant => participant._id !== loggedInUserId);
                                            if (!otherParticipant) return null;
                                            const isOnline = onlineUsers.includes(otherParticipant._id);
                                            const lastMessage = conversation.messages && conversation.messages.length > 0 ? conversation.messages[conversation.messages.length - 1] : null;

                                            const displayDate = lastMessage ? formatDateTime(lastMessage.createdAt) : formatDateTime(conversation.createdAt); // Use conversation creation date if no last message

                                            const unreadCountForConversation = unreadCount[conversation._id];

                                            return (
                                                <div key={conversation._id} className="flex items-center pl-[10px] py-[10px] px-4 justify-between mx-2 sm:mx-4 mb-2 border-b border-[#e2e2e2] cursor-pointer" onClick={() => handleUserClick(otherParticipant._id)}>
                                                    <div className="flex items-center">
                                                        <img src={otherParticipant.profilePic !== "" && otherParticipant.profilePic !== undefined ? otherParticipant.profilePic : defaultProfile} alt="" className="w-[40px] h-[40px] mr-0.5 rounded-full" />
                                                        <div className="ml-[10px]">
                                                            <div className="flex flex-row gap-1 items-center">
                                                                <h1 className="font-semibold text-[#585858] text-[13px] sm:text-[14px] lg:text-[16px]">{otherParticipant.fullName}</h1>
                                                                {isOnline ? <span className="bg-green-500 w-[5.5px] h-[6px] sm:w-[8px] sm:h-[8px] rounded-[100px] "></span> : ''}

                                                            </div>
                                                            {lastMessage ? (
                                                                <div>
                                                                    {lastMessage.documents && lastMessage.documents.length > 0 ? (
                                                                        <p className="font-normal text-[12px] sm:text-[14px] text-[#585858]">Document: {lastMessage.documents[0].filename}</p>
                                                                    ) : (
                                                                        <p className="font-normal text-[12px] sm:text-[14px] text-[#585858]">{lastMessage.message}</p>
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <p className="font-normal text-[11px] sm:text-[14px] text-[#afafaf]">No messages yet</p> // Display if no messages
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-row gap-2 sm:gap-4 lg:gap-6 items-center">
                                                        {unreadCountForConversation > 0 && (
                                                            <span className="bg-gradient w-4 h-4 lg:w-6 lg:h-6 text-center flex items-center justify-center text-white rounded-full text-xs lg:text-sm font-semibold">{unreadCountForConversation}</span>
                                                        )}
                                                        <p className="font-medium text-[11px] sm:text-[13px] text-[#afafaf]">{displayDate}</p>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <p className="text-center text-[#585858] mt-5 font-normal text-base sm:text-lg">You don't have any conversations. Start new conversation by searching for a {user_type_opp}</p>
                                    )}
                                </div>
                            ) : (
                                <div className='overflow-y-auto shadow-lg rounded-[50px] py-2 mx-auto sm:mx-0 w-full sm:w-auto col-span-2 sm:h-full sm:rounded-[25px] sm:shadow-md min-h-full sm:mt-6 md:mt-0' style={{ maxHeight: 'calc(100vh - 364px)' }}>
                                    {filteredUsers.map(user => (
                                        <div key={user._id} className="flex items-center pl-[10px] py-[10px] px-4 justify-start mx-5 mb-2 border-b border-[#e2e2e2] cursor-pointer" onClick={() => handleUserClick(user._id)}>
                                            <img src={user.profilePic !== "" && user.profilePic !== undefined ? user.profilePic : defaultProfile} alt="" className="w-[40px] h-[40px] mr-0.5 rounded-full" />
                                            <div className="ml-[10px]">
                                                <div className="flex flex-row gap-1 items-center">
                                                    <h1 className="font-semibold text-[#585858] text-[13px] sm:text-[14px] lg:text-[16px]">{user.fullName}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    <MainMenu />
                </div>
            </section>
        </>
    )
}

export default Chat;
