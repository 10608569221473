import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

const AdminLogin = ({ setMessage, setShowMessage }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const initialData = {
    email: "",
    password: "",
  };
  const [userData, setData] = useState(initialData);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({ ...userData, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiUrl}/api/adminAuth/login`, userData);
      const data = response.data;
      // console.log(data)
      const userId = data._id;
      Cookies.set("adminUserId", userId, { expires: 30 }); // Store userId in a cookie
      const authToken = data.token;
      Cookies.set("adminAuthToken", authToken, { expires: 30 }); // Store authToken in a cookie with expiration

      setIsLoggedIn(true);
      navigate("/admin/dashboard");
      setShowMessage(true);
      setMessage("Logged in successfully");
    } catch (err) {
      setShowMessage(true);
      setMessage(err.response?.data?.error || "An error occurred");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 font-primary">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold text-center text-gradient">Admin Login</h2>
        <form onSubmit={handleFormSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={userData.email}
              onChange={handleInputChange}
              required
              className="w-full px-3 py-2 mt-1 border rounded shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={userData.password}
              onChange={handleInputChange}
              required
              className="w-full px-3 py-2 mt-1 border rounded shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
            />
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 font-bold text-white bg-gradient rounded hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
          >
            Log In
          </button>
          {/* <div className="w-full sm:w-full max-w-[480px] sm:max-w-[640px] flex justify-center flex-col items-center px-0 py-2 mt-2 sm:mt-4">
            <Link to="/admin/signup">
              <p className="pb-[15px] w-full text-[15px] sm:text-[16px] text-white sm:text-[#2D2D2D] text-center">
                Sign up
              </p>
            </Link>
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
