// import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import Cookies from 'js-cookie';
// import { useSocket } from './SocketContext';

// const NotificationContext = createContext();

// export const useNotifications = () => useContext(NotificationContext);

// const apiUrl = process.env.REACT_APP_API_URL;

// export const NotificationProvider = ({ children }) => {
//   const [notifications, setNotifications] = useState([]);
//   const [unreadCount, setUnreadCount] = useState(0);
//   const [chatNotifications, setChatNotifications] = useState([]);
//   const [forumNotifications, setForumNotifications] = useState([]);
//   const [forumPostId, setForumPostId] = useState(null);
//   const [unreadChat, setUnreadChat] = useState(0);
//   const loggedInUserId = Cookies.get('loggedInUserId');
//   const { socket } = useSocket();
//   const socketRef = useRef();
//   const fetchIntervalRef = useRef(null);

//   const fetchNotifications = async () => {
//     try {
//       console.log('Fetching notifications...');
//       const response = await axios.get(`${apiUrl}/api/notification/${loggedInUserId}/notifications`);
//       const sortedNotifications = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
//       setNotifications(sortedNotifications);
//       const chatNotif = response.data.filter(notif => notif.type === 'chat');
//       setChatNotifications(chatNotif);
//       setUnreadCount(response.data.filter(notif => !notif.read).length);
//       setUnreadChat(response.data.filter(notif => notif.type === 'chat' && !notif.read).length);
//     } catch (error) {
//       console.error('Error fetching notifications:', error);
//     }
//   };

//   useEffect(()=>{
//     fetchNotifications();
//   },[])

//   useEffect(() => {
//     //fetchNotifications();

//     if (socket ) {
//      ;

//       socket.on('newForumNotification', (notification) => {
//         setNotifications(prevNotifications => [notification, ...prevNotifications]);
//         setUnreadCount(prevCount => prevCount + 1);
//       });

//       socket.on('newChatMessage', (notification) => {
//         setNotifications(prevNotifications => [notification, ...prevNotifications]);
//         setUnreadCount(prevCount => prevCount + 1);
//         setUnreadChat(prevCount => prevCount + 1);
//       });

//       socket.on('newConferenceBooking', (notification) => {
//         setNotifications(prevNotifications => [notification, ...prevNotifications]);
//         setUnreadCount(prevCount => prevCount + 1);
//       });

//       socket.on('newCallBooking', (notification) => {
//         setNotifications(prevNotifications => [notification, ...prevNotifications]);
//         setUnreadCount(prevCount => prevCount + 1);
//       });

//       socket.on('callReminder', (notification) => {
//         setNotifications(prevNotifications => [notification, ...prevNotifications]);
//         setUnreadCount(prevCount => prevCount + 1);
//       });

//       socket.on('conferenceReminder', (notification) => {
//         setNotifications(prevNotifications => [notification, ...prevNotifications]);
//         setUnreadCount(prevCount => prevCount + 1);
//       });

//       socket.on('markChatNotificationsAsRead', async (chatUserId) => {
//         try {
//           const chatNotifToMarkAsRead = chatNotifications.filter(notif => notif.receiverId === chatUserId && !notif.read);
//           await Promise.all(chatNotifToMarkAsRead.map(notif => axios.put(`${apiUrl}/api/notification/${chatUserId}/notifications/${notif._id}/read`)));
//           setChatNotifications(prevNotifications => prevNotifications.map(notif => {
//             if (notif.receiverId === chatUserId) {
//               return { ...notif, read: true };
//             }
//             return notif;
//           }));
//           setUnreadCount(prevCount => prevCount - chatNotifToMarkAsRead.length);
//           setUnreadChat(prevUnreadChat => Math.max(prevUnreadChat - chatNotifToMarkAsRead.length, 0));
//         } catch (error) {
//           console.error('Error marking chat notifications as read:', error);
//         }
//       });
//     }

//     // Clear interval on component unmount
//     return () => {
//       if (fetchIntervalRef.current) {
//         clearInterval(fetchIntervalRef.current);
//       }
//       if (socket) {
//         socket.off('newForumNotification');
//         socket.off('newChatMessage');
//         socket.off('newConferenceBooking');
//         socket.off('newCallBooking');
//         socket.off('callReminder');
//         socket.off('conferenceReminder');
//         socket.off('markChatNotificationsAsRead');
        
//       }
//     };
//   }, [loggedInUserId, socket, chatNotifications]);

//   // Polling interval - adjust as needed
//   useEffect(() => {
//     if (!fetchIntervalRef.current) {
//       console.log('Setting up polling interval...');
//       fetchIntervalRef.current = setInterval(fetchNotifications, 5 * 60000); // 1 minute interval
//     }
//     return () => clearInterval(fetchIntervalRef.current);
//   }, [loggedInUserId]);

//   const markAsRead = async (notificationId) => {
//     try {
//       await axios.put(`${apiUrl}/api/notification/${loggedInUserId}/notifications/${notificationId}/read`);
//       setNotifications(prevNotifications => prevNotifications.map(notification => {
//         if (notification._id === notificationId) {
//           return { ...notification, read: true };
//         }
//         return notification;
//       }));
//       setUnreadCount(prevCount => prevCount - 1);
//     } catch (error) {
//       console.error('Error marking notification as read:', error);
//     }
//   };

//   const markChatNotificationsAsRead = async (chatUserId) => {
//     try {
//       const chatNotifToMarkAsRead = chatNotifications.filter(notif => notif.receiverId === chatUserId && !notif.read);
//       await Promise.all(chatNotifToMarkAsRead.map(notif => axios.put(`${apiUrl}/api/notification/${chatUserId}/notifications/${notif._id}/read`)));
//       setChatNotifications(prevNotifications => prevNotifications.map(notif => {
//         if (notif.receiverId === chatUserId) {
//           return { ...notif, read: true };
//         }
//         return notif;
//       }));
//       setUnreadCount(prevCount => prevCount - chatNotifToMarkAsRead.length);
//       setUnreadChat(prevCount => prevCount - chatNotifToMarkAsRead.length);
//     } catch (error) {
//       console.error('Error marking chat notifications as read:', error);
//     }
//   };

//   return (
//     <NotificationContext.Provider value={{
//       notifications, unreadCount, unreadChat, chatNotifications,
//       forumNotifications, markAsRead, markChatNotificationsAsRead,
//       forumPostId, setForumPostId
//     }}>
//       {children}
//     </NotificationContext.Provider>
//   );
// };

// export default NotificationContext;
import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useSocket } from './SocketContext';

const NotificationContext = createContext();

export const useNotifications = () => useContext(NotificationContext);

const apiUrl = process.env.REACT_APP_API_URL;

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [chatNotifications, setChatNotifications] = useState([]);
  const [unreadChat, setUnreadChat] = useState(0);
  const [forumPostId, setForumPostId] = useState(null);
  const loggedInUserId = Cookies.get('loggedInUserId');
  const { socket } = useSocket();
  const socketRef = useRef();

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/notification/${loggedInUserId}/notifications`);
      const sortedNotifications = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setNotifications(sortedNotifications);
      const chatNotif = response.data.filter(notif => notif.type === 'chat');
      setChatNotifications(chatNotif);
      setUnreadCount(response.data.filter(notif => !notif.read).length);
      setUnreadChat(response.data.filter(notif => notif.type === 'chat' && !notif.read).length);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  
 useEffect(()=>{
  fetchNotifications();
 },[loggedInUserId]);
  
  useEffect(() => {
    // fetchNotifications();

    if (socket && !socketRef.current) {
      socketRef.current = socket;

      const handleNewNotification = (notification) => {
        fetchNotifications();
        setNotifications(prevNotifications => [notification, ...prevNotifications]);
        setUnreadCount(prevCount => prevCount + 1);
        if (notification.type === 'chat') {
          setChatNotifications(prevNotifications => [notification, ...prevNotifications]);
          setUnreadChat(prevCount => prevCount + 1);
        }
      };

      socket.on('newForumNotification', handleNewNotification);
      socket.on('newChatMessage', handleNewNotification);
      socket.on('newConferenceBooking', handleNewNotification);
      socket.on('newCallBooking', handleNewNotification);
      socket.on('callReminder', handleNewNotification);
      socket.on('conferenceReminder', handleNewNotification);

      socket.on('markChatNotificationsAsRead', async (chatUserId) => {
        try {
          const chatNotifToMarkAsRead = chatNotifications.filter(notif => notif.receiverId === chatUserId && !notif.read);
          await Promise.all(chatNotifToMarkAsRead.map(notif => axios.put(`${apiUrl}/api/notification/${chatUserId}/notifications/${notif._id}/read`)));
          setChatNotifications(prevNotifications => prevNotifications.map(notif => {
            if (notif.receiverId === chatUserId) {
              return { ...notif, read: true };
            }
            return notif;
          }));
          setUnreadCount(prevCount => prevCount - chatNotifToMarkAsRead.length);
          setUnreadChat(prevUnreadChat => Math.max(prevUnreadChat - chatNotifToMarkAsRead.length, 0));
        } catch (error) {
          console.error('Error marking chat notifications as read:', error);
        }
      });

      return () => {
        socketRef.current.off('newForumNotification', handleNewNotification);
        socketRef.current.off('newChatMessage', handleNewNotification);
        socketRef.current.off('newConferenceBooking', handleNewNotification);
        socketRef.current.off('newCallBooking', handleNewNotification);
        socketRef.current.off('callReminder', handleNewNotification);
        socketRef.current.off('conferenceReminder', handleNewNotification);
        socketRef.current.off('markChatNotificationsAsRead');
        socketRef.current = null;
      };
    }
  }, [loggedInUserId, socket,chatNotifications]);

  const markAsRead = async (notificationId) => {
    try {
      await axios.put(`${apiUrl}/api/notification/${loggedInUserId}/notifications/${notificationId}/read`);
      setNotifications(prevNotifications => prevNotifications.map(notification => {
        if (notification._id === notificationId) {
          return { ...notification, read: true };
        }
        return notification;
      }));
      setUnreadCount(prevCount => prevCount - 1);
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const markChatNotificationsAsRead = async (chatUserId) => {
    try {
      const chatNotifToMarkAsRead = chatNotifications.filter(notif => notif.receiverId === chatUserId && !notif.read);
      await Promise.all(chatNotifToMarkAsRead.map(notif => axios.put(`${apiUrl}/api/notification/${chatUserId}/notifications/${notif._id}/read`)));
      setChatNotifications(prevNotifications => prevNotifications.map(notif => {
        if (notif.receiverId === chatUserId) {
          return { ...notif, read: true };
        }
        return notif;
      }));
      setUnreadCount(prevCount => prevCount - chatNotifToMarkAsRead.length);
      setUnreadChat(prevCount => prevCount - chatNotifToMarkAsRead.length);
    } catch (error) {
      console.error('Error marking chat notifications as read:', error);
    }
  };

  return (
    <NotificationContext.Provider value={{
      notifications, unreadCount, unreadChat, chatNotifications,forumPostId, setForumPostId,
      markAsRead, markChatNotificationsAsRead
    }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
