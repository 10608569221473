import React from "react";
import { FaTrashAlt } from "react-icons/fa";
import { CitySelect, CountrySelect, StateSelect } from "react-country-state-city";

const RelocateAreas = ({ formData, setFormData }) => {
  const handleAddLocation = () => {
    const newLocations = [
      ...formData.preferred_locations,
      { country: "", state: "", cities: [] },
    ];
    setFormData({ ...formData, preferred_locations: newLocations });
  };

  const handleRemoveLocation = (index) => {
    const currentLocations = formData.preferred_locations;
    const updatedLocations = [
      ...currentLocations.slice(0, index),
      ...currentLocations.slice(index + 1),
    ];
    setFormData({ ...formData, preferred_locations: updatedLocations });
  };

  const handleLocationChange = (index, key, value) => {
    const newLocations = [...formData.preferred_locations];
    if (key === "country") {
      newLocations[index] = { country: value, state: "", cities: [] };
    } else if (key === "state") {
      newLocations[index] = { ...newLocations[index], state: value, cities: [] };
    } else if (key === "cities") {
      newLocations[index] = { ...newLocations[index], cities: value };
    }
    setFormData({ ...formData, preferred_locations: newLocations });
  };

  const handleCityChange = (index, city) => {
    const newLocations = [...formData.preferred_locations];
    const cities = newLocations[index].cities;
    const cityIndex = cities.findIndex((c) => c.id === city.id);

    if (cityIndex > -1) {
      cities.splice(cityIndex, 1);
    } else {
      cities.push(city);
    }

    newLocations[index] = { ...newLocations[index], cities };
    setFormData({ ...formData, preferred_locations: newLocations });
  };

  return (
    <div className="col-span-6 text-sm">
      <div className="flex items-center justify-between gap-4 sm:justify-between sm:mt-4">
        <label className="font-semibold leading-6 text-gradient text-sm sm:text-base lg:text-lg pl-[14px] sm:pl-0">
          Preferred Areas of Relocation
        </label>

        <button
          type="button"
          className="rounded-full bg-gradient text-white w-[150px] h-fit py-2 px-3 text-sm sm:text-base lg:text-lg"
          onClick={handleAddLocation}
        >
          {formData.preferred_locations.length > 0 ? "Add More +" : "Add"}
        </button>
      </div>

      {formData.preferred_locations.map((location, index) => (
        <div key={index} className="flex flex-col gap-2 sm:gap-4 px-1 text-sm sm:text-base lg:text-lg">
          <div className="flex flex-col sm:flex-row gap-2 min-[375px]:flex-row sm:justify-between items-center mt-4">
            <div className="sm:w-[30%] w-[100%]">
              <div className="relative">
                <CountrySelect
                  value={location.country}
                  onChange={(value) => handleLocationChange(index, "country", value)}
                  placeHolder={location.country || "Select Country"}
                  inputClassName="stdropdown-input"
                />
              </div>
            </div>

            <div className="sm:w-[30%] w-[100%]">
              <div className="relative">
                <StateSelect
                  countryid={location.country ? location.country.id : 0}
                  value={location.state}
                  onChange={(value) => handleLocationChange(index, "state", value)}
                  placeHolder={location.state || "Select State"}
                  inputClassName="stdropdown-input"
                />
              </div>
            </div>

            <div className="sm:w-[30%] w-[100%]">
              <div className="relative">
                <CitySelect
                  countryid={location.country ? location.country.id : 0}
                  stateid={location.state ? location.state.id : 0}
                  value={""}
                  onChange={(value) => handleCityChange(index, value)}
                  placeHolder={location.cities[0]?.name || "Select City"}
                  inputClassName="stdropdown-input"
                  isMulti={true}
                />
              </div>
            </div>

            <FaTrashAlt
              color="#afafaf"
              size={20}
              className="hidden sm:block sm:w-[10%] cursor-pointer"
              onClick={() => handleRemoveLocation(index)}
            />
          </div>
          <div className="flex flex-row flex-wrap">
            {location.cities.map((city, i) => (
              <span key={i} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                {city?.name || city}
              </span>
            ))}
            <FaTrashAlt
              color="#afafaf"
              size={20}
              className="sm:hidden w-[10%] cursor-pointer"
              onClick={() => handleRemoveLocation(index)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default RelocateAreas;
