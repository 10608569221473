import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import {FaLock,FaUserCircle,FaRegEyeSlash, FaLinkedin, FaFacebook,} from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FcGoogle } from "react-icons/fc";
import logo from "../assets/img/logo.svg";
import desktop_user from "../assets/img/user_icon.png";
import desktop_logo from "../assets/img/desktop_logo.svg";
import desktop_lock from "../assets/img/lock_icon.png";
import desktop_email from "../assets/img/email_icon.png";
import bg from "../assets/img/background.png";
import { SocketContextProvider } from "../context/SocketContext";
import { Helmet } from "react-helmet-async";

const apiUrl = process.env.REACT_APP_API_URL;

const linkedin_client_id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
const facebook_client_id = process.env.REACT_APP_FACEBOOK_CLIENT_ID;

const SignUpStudent = ({ setMessage, setShowMessage, setProfilePic, profileData, setProfileData, setFullName }) => {
  const location = useLocation();
  const user_type = location.state ? location.state.user_type : 'Student';
  const from = location.state?.from || "/cabinet";
  // console.log(user_type)
  if (user_type) {
    Cookies.set("user_type", user_type, { expires: 30 }); // Save user type Teacher or Student
  } else {
    // Handle the case when user_type is not available in location.state
    console.error("User type not found in location state.");
    // You might want to show an error message or redirect the user to a different page
  }

  const initialData = {
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    type: user_type,
    agree: true,
    profilePic: "",
    account_type: "custom",
  };

  const [userData, setData] = useState(initialData);
  const navigate = useNavigate();

  const [showPwd, setShowPwd] = useState(false);
  const [showConfirmPwd, setShowConfirmPwd] = useState(false);


  // -------------------------------------- CREATE ACCOUNT ----------------------------------------------------------

  async function handleFormSubmit(e) {
    // console.log("form submitted")
    e.preventDefault();

    if (userData.agree === false) {
      setShowMessage(true);
      setMessage("Please agree to CGB ORG terms and conditions to continue.");
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/api/auth/signup`, userData);
      // console.log(response);
      const data = response.data;
      const userId = data._id;
      Cookies.set("loggedInUserId", userId);
      const authToken = data.token;
      Cookies.set("authToken", authToken);
      Cookies.set("account_type", "custom");
      setProfilePic(data.profilePic)
      setFullName(data.fullName)
      navigate("/verify", { state: { email: userData.email,from } });
      setShowMessage(true);
      setMessage("Signup successful.");
    } catch (err) {
      setShowMessage(true);
      setMessage(err.response.data.error);
      window.alert('Email already exists! Please enter valid email address')
    }
  }

  // -------------------------------------------------- CREATE ACCOUNT USING FACEBOOK ------------------------------------------------

  const responseFacebook = async (data) => {
    // console.log(data);
    setFullName(data.name)
    let picture = data.picture.data.url
    // console.log(picture)
    setProfilePic(picture)
    try {
      let signup_data = {};

      if (data._id) signup_data.f_social_id = data.userId;

      signup_data.f_email = data.email ? data.email : "";


      signup_data.picture = picture ? picture : "";


      signup_data.fullName = data.name ? data.name : "";

      signup_data.verified = true;
      signup_data.account_type = "facebook";
      signup_data.type = Cookies.get("user_type");

      Cookies.set("account_type", "facebook");

      const signup_response = await axios.post(
        `${apiUrl}/api/auth/signup`,
        signup_data
      );
      // console.log(signup_response);

      if (signup_response.status === 201) {
        setShowMessage(true);
        setMessage("Signup Successful");

        setProfilePic(data.picture);

        Cookies.set("authToken", signup_response.data.token);
        navigate("/edit-profile");
      }
    } catch (err) {
      const data = err.response.data
      // console.log(data)
      if (err.response.status === 422) {
        setProfileData({
          ...profileData,
          display: "flex",
          name: data.f_name,
          social_id: data.f_social_id,
          account_type: "facebook"
        })
      }
      else {
        console.log(err)
        setMessage(data.error)
        setShowMessage(true)
      }
    }

  };

  // ----------------------------------------------- CREATE ACCOUNT USING GOOGLE -----------------------------------------------------

  const googleSignUp = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      // console.log(tokenResponse);
      const response = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`
      );
      // console.log(response);
      const data = response.data;
      // console.log(data);

      setProfilePic(data.picture);
      setFullName(data.name);

      try {
        let signup_data = {};

        if (data._id) signup_data.social_id = data._id;

        signup_data.email = data.email ? data.email : "";
        signup_data.profilePic = data.picture ? data.picture : "";
        signup_data.fullName = data.name ? data.name : "";

        signup_data.verified = true;
        signup_data.account_type = "google";
        signup_data.type = Cookies.get("user_type");

        Cookies.set("account_type", "google");

        const signup_response = await axios.post(
          `${apiUrl}/api/auth/signup`,
          signup_data
        );
        // console.log(signup_response);

        if (signup_response.status === 201) {
          setShowMessage(true);
          setMessage("Signup Successful");
          const userId = signup_response.data._id;
          Cookies.set("loggedInUserId", userId);
          Cookies.set("authToken", signup_response.data.token);
          navigate("/edit-profile");
        }
      } catch (err) {
        setShowMessage(true);
        setMessage(err.response.data.error);
      }
    },
  });

  // ------------------------------------------------ CREATE ACCOUNT USING LINKEDIN -----------------------------------------------

  const { linkedInLogin } = useLinkedIn({
    clientId: linkedin_client_id,
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: async (code) => {
      // console.log("success");

      try {
        const signup_response = await axios.post(`${apiUrl}/api/auth/signup`, {
          linkedin_code: code,
          account_type: "linkedin",
          type: user_type,
        });

        const signup_data = signup_response.data;
        Cookies.set("authToken", signup_data.token);

        setProfilePic(signup_data.profilePic);
        setFullName(signup_data.fullName);

        navigate("/edit-profile");
      } catch (err) {
        const data = err.response.data
        // console.log(data)
        if (err.response.status == 422) {
          setProfileData({
            ...profileData,
            display: "flex",
            name: data.l_name,
            social_id: data.l_social_id,
            account_type: "linkedin"
          })
        }
        else {
          console.log(err)
          setMessage(data.error)
          setShowMessage(true)
        }
      }
    },
    onError: (error) => {
      console.log(error);
    },
    scope: "email,profile,openid",
  });

  return (
    <SocketContextProvider>
      <Helmet>
        <title>Sign Up - Career Guidance Buddy</title>
        <meta name="keywords" content="sign up, education, online class" />
      </Helmet>
      <section className="sm:h-[70vh] sm:p-5 sm:mt-40 lg:mt-0 lg:h-[100vh] sm:w-full sm:flex items-center justify-center sm:px-5 font-primary">
        <div className="sm:grid grid-cols-2 sm:h-[688px] sm:mx-auto max-w-2xl sm:max-w-[1080px] sm:shadow-md sm:rounded-[50px]">
          <div
            className="sm:hidden bg-cover bg-right-bottom bg-no-repeat h-[230px] sm:mx-auto sm:w-full sm:max-w-md"
            style={{ backgroundImage: `url("${bg}")` }}
          >
            <div className="pl-9 pt-10 max-w-[433px]">
              <img className="w-[189px]" src={logo} alt="logo" />
              <div className="pt-4 text-white">
                <h1 className="text-2xl font-medium">Sign Up</h1>
                <p className="text-[15px]">as a {user_type}</p>
              </div>
            </div>
          </div>

          <div className="bg-gradient sm:h-full hidden sm:flex flex-col justify-center items-center sm:rounded-s-[50px]">
            <div className="flex justify-center items-center mx-auto w-full">
              <img src={logo} alt="logo" className="sm:hidden w-6/12" />
              <img
                src={desktop_logo}
                alt="logo"
                className="hidden sm:block w-10/12"
              />
            </div>
            <div className="pt-4 sm:pt-[50px] md:pt-[80px] lg:pt-[136px]">
              <h3 className="text-center text-white text-lg md:text-2xl font-semibold">
                Start your journey to the
              </h3>
              <h3 className="text-center text-white text-lg md:text-2xl font-semibold pb-6 sm:pb-20">
                world of learning.
              </h3>
              <div className="fixed sm:relative sm:flex sm:flex-col sm:items-center sm:mx-auto sm:justify-center sm:mt-0 mt-[540px] sm:text-center sm:text-white text-sm md:text-xl pb-6">
                <div className="flex flex-row w-full justify-center gap-4 text-[#585858] text-[14px] mb-2 sm:mb-1">
                  <Link
                    to="https://careerguidancebuddy.com/terms-and-conditions"
                    className="sm:text-[14px] lg:text-[16px] text-center sm:text-white"
                  >
                    Terms & Conditions
                  </Link>
                  <Link to="https://careerguidancebuddy.com/userAgreement" className="sm:text-[14px] lg:text-[16px] text-center sm:text-white">
                    User Agreement</Link>

                </div>
                <div className="flex flex-row gap-4 justify-center text-[#585858] text-[14px]">
                  <Link
                    to="https://careerguidancebuddy.com/paymentPolicy"
                    className="sm:text-[14px] lg:text-[16px] text-center sm:text-white"
                  >
                    Payment Policy
                  </Link>
                  <Link to="https://careerguidancebuddy.com/refundPolicy" className="sm:text-[14px] lg:text-[16px] text-center sm:text-white">
                    Refund Policy
                  </Link>

                  <Link to="https://careerguidancebuddy.com/privacyPolicy" className="sm:text-[14px] lg:text-[16px] text-center sm:text-white">
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* ------------------------------------------------ CREATE ACCOUNT USING NAME, EAMIL & PASSWORD ------------------------------------------ */}

          <div className="p-3 w-full mt-[-90px] sm:mt-0 sm:h-full sm:flex items-center justify-center">
            <div className="mx-auto w-full max-w-[480px] md:max-w-auto sm:w-full lg:w-10/12 mt-[40px] sm:mt-0">
              <Link to="/">
                <div className="flex mx-6 mb-2 items-center justify-start gap-1">
                  <IoArrowBackCircleOutline size={24} className="w-5 h-5 sm:w-6 sm:h-6 text-[#afafaf]" />
                  <span className="text-[14px] sm:text-[20px] text-[#afafaf] align-middle text-left">Back</span>
                </div>
              </Link>
              <h1 className="hidden sm:block text-[40px] mb-6 font-semibold text-center text-gradient leading-[40px]">
                Sign Up
                <br />
                <span className="text-[24px]">As a {user_type}</span>
              </h1>
              <div className="sm:px-[29px] px-3">
                <form className="flex flex-col gap-2 sm:gap-4" onSubmit={handleFormSubmit}>
                  <div>
                    <label
                      for="name"
                      className="block sm:hidden text-[14px] font-semibold text-[#585858] pl-4"
                    >
                      Name
                    </label>
                    <div className="relative mt-1">
                      <div className="absolute inset-y-0 left-0 pl-[15px] flex items-center pointer-events-none">
                        <FaUserCircle
                          className="sm:hidden w-[20px] h-[20px] text-gray-500"
                          alt=""
                        />
                        <img
                          className="hidden sm:block"
                          src={desktop_user}
                          alt=""
                        />
                      </div>
                      <input
                        type="text"
                        maxLength={25}
                        className="pl-10 placeholder:text-gray-500 sm:placeholder:text-orange-500 pr-4 py-2 sm:py-3 border border-[#585858] sm:border-orange-500 text-sm w-full rounded-full outline-none"
                        placeholder="Name"
                        required
                        value={userData.fullName}
                        onChange={(e) =>
                          setData({ ...userData, fullName: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="email"
                      className="block sm:hidden text-[14px] font-semibold text-[#585858] pl-4"
                    >
                      Email
                    </label>
                    <div className="relative mt-1">
                      <div className="absolute inset-y-0 left-0 pl-[15px] flex items-center pointer-events-none">
                        <MdEmail className="sm:hidden w-[16px] h-[20px] text-gray-500" />
                        <img
                          className="hidden sm:block"
                          src={desktop_email}
                          alt=""
                        />
                      </div>
                      <input
                        type="email"
                        maxLength={30}
                        required
                        className="pl-10 pr-4 py-2 sm:py-3 placeholder:text-gray-500 sm:placeholder:text-orange-500 border border-[#585858] sm:border-orange-500 outline-none text-sm w-full rounded-full"
                        placeholder="Email"
                        value={userData.email}
                        onChange={(e) =>
                          setData({ ...userData, email: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="password"
                      className="block sm:hidden text-[14px] font-semibold text-[#585858] pl-4"
                    >
                      Password
                    </label>
                    <div className="relative mt-1">
                      <div className="absolute inset-y-0 left-0 pl-[15px] flex items-center pointer-events-none">
                        <FaLock
                          className="sm:hidden w-[15px] h-[20px] text-gray-500"
                          alt=""
                        />
                        <img
                          src={desktop_lock}
                          className="hidden sm:block"
                          alt="pass"
                        />
                      </div>
                      <input
                        id="password"
                        name="password"
                        maxLength={20}
                        type={showPwd === false ? "password" : "text"}
                        value={userData.password}
                        onChange={(e) =>
                          setData({ ...userData, password: e.target.value })
                        }
                        required
                        className="pl-10 pr-4 py-2 sm:py-3 placeholder:text-gray-500 sm:placeholder:text-orange-500 border border-[#585858] sm:border-orange-500 outline-none text-sm w-full rounded-full"
                        placeholder="Password"
                      />
                      <div className="absolute inset-y-0 right-0 pr-[10px] flex items-center cursor-pointer">
                        {showPwd === false ? (
                          <FaRegEyeSlash
                            color="#808080"
                            onClick={() => setShowPwd(true)}
                          />
                        ) : (
                          <MdOutlineRemoveRedEye
                            color="#808080"
                            onClick={() => setShowPwd(false)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      for="password"
                      className="block sm:hidden text-[14px] font-semibold text-[#585858] pl-4"
                    >
                      Confirm password
                    </label>
                    <div className="relative mt-1">
                      <div className="absolute inset-y-0 left-0 pl-[15px] flex items-center pointer-events-none">
                        <FaLock
                          className="sm:hidden w-[15px] h-[20px] text-gray-500"
                          alt="pass"
                        />
                        <img
                          src={desktop_lock}
                          className="hidden sm:block"
                          alt=""
                        />
                      </div>
                      <input
                        id="password"
                        name="password"
                        maxLength={20}
                        type={showConfirmPwd === false ? "password" : "text"}
                        value={userData.confirmPassword}
                        onChange={(e) =>
                          setData({
                            ...userData,
                            confirmPassword: e.target.value,
                          })
                        }
                        required
                        className="pl-10 pr-4 py-2 sm:py-3 placeholder:text-gray-500 sm:placeholder:text-orange-500 border border-[#585858] sm:border-orange-500 outline-none text-sm w-full rounded-full"
                        placeholder="Confirm Password"
                      />
                      <div className="absolute inset-y-0 right-0 pr-[10px] flex items-center cursor-pointer">
                        {showConfirmPwd === false ? (
                          <FaRegEyeSlash
                            color="#808080"
                            onClick={() => setShowConfirmPwd(true)}
                          />
                        ) : (
                          <MdOutlineRemoveRedEye
                            color="#808080"
                            onClick={() => setShowConfirmPwd(false)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className=" pt-0.5">
                    <div className="flex flex-row gap-1 w-full justify-center mx-1">
                      <div className="basis-1/12">
                        <input
                          type="checkbox"
                          name="agree"
                          id="agree"
                          checked={userData.agree}
                          onChange={(e) =>
                            setData({ ...userData, agree: e.target.checked })
                          }
                        />
                      </div>
                      <div className="basis-11/12 text-[10px] text-left sm:text-[12px]">
                        By Signing Up you accept CareerGuidancyBuddy (CGB ORG)’s
                        <Link to="">Terms and Conditions</Link> and other
                        <Link to="">Policies</Link>.
                      </div>
                    </div>
                    <div className="flex flex-row gap-1 w-full justify-center mx-1">
                      <div className="basis-1/12">
                        <input
                          type="checkbox"
                          name="agree"
                          id="agree"
                          checked={userData.agree}
                          onChange={(e) =>
                            setData({ ...userData, agree: e.target.checked })
                          }
                        />
                      </div>
                      <div className="basis-11/12 text-[10px] text-left sm:text-[12px]">
                        {user_type === 'Student' ? <span>I am above 18 years of age or my parent/guardian is with me</span> :
                          <span className="text-[10px] text-left sm:text-[12px]">I am above legal age to work</span>}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <button
                      // type="submit"
                      className="px-6 py-2 text-white w-10/12 sm:w-full bg-gradient-to-b from-[#ff6535] via-[#ff9e48] to-[#ffce58] rounded-full drop-shadow-md"
                    >
                      Sign Up
                    </button>
                  </div>
                </form>

                {/* ------------------------------------------ CREATE ACCOUNT USING SOCIAL MEDIA ACCOUNTS ---------------------------------------- */}

                <div className="sm:flex flex-col sm:mt-4 gap-4">
                  {/* <div className="flex sm:hidden w-full justify-center pt-0.5">
                    <p className="font-normal text-sm">-or-</p>
                  </div>
                  <div className="flex items-center justify-center pt-1">
                    <div className="mr-5 rounded-full bg-white cursor-pointer">
                      <FacebookLogin
                        appId={facebook_client_id}
                        callback={responseFacebook}
                        fields="name,email,picture"
                        render={(renderProps) => (
                          <FaFacebook
                            onClick={renderProps.onClick}
                            // size={35}
                            color={"#1877F2"}
                            className="w-6 h-6 sm:w-8 sm:h-8"
                          />
                        )}
                      />
                    </div>
                    <div>
                      <FcGoogle
                        size={35}

                        className="cursor-pointer w-6 h-6 sm:w-8 sm:h-8"
                        onClick={() => googleSignUp()}
                      />
                    </div>

                    <div className="ml-5">
                      <FaLinkedin
                        onClick={linkedInLogin}
                        size={35}
                        className="cursor-pointer w-6 h-6 sm:w-8 sm:h-8"
                        color={"#0075b5"}
                      />
                    </div>
                  </div> */}

                  {/*---------------------------------- LOGIN IF ALREADY HAVE AN ACCOUNT -------------------------------------------- */}

                  <div>
                    <p className="my-2 sm:my-2 text-sm sm:text-[16px] text-[#585858] sm:text-[#2D2D2D] sm:font-medium flex items-center justify-center text-center">
                      Already have an account?
                      <Link to="/login">
                        <span className='text-gradient font-primary font-bold mx-1 text-sm sm:text-[16px]'> Log In</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:hidden flex flex-col w-full items-center gap-1 mx-auto justify-center mt-[20px] text-center md:text-xl pb-4">
              <div className="flex flex-row w-full justify-center gap-4 text-[#585858] text-[14px]">
                <Link
                  to="https://careerguidancebuddy.com/terms-and-conditions"
                  className="text-[12px] text-center"
                >
                  Terms & Conditions
                </Link>
                <Link to="https://careerguidancebuddy.com/userAgreement" className="text-[12px] text-center">
                  User Agreement</Link>

              </div>
              <div className="flex flex-row w-full gap-3 justify-center text-[#585858] text-[14px]">
                <Link
                  to="https://careerguidancebuddy.com/paymentPolicy"
                  className="text-[12px] text-center"
                >
                  Payment Policy
                </Link>
                <Link to="https://careerguidancebuddy.com/refundPolicy" className="text-[12px] text-center">
                  Refund Policy
                </Link>

                <Link to="https://careerguidancebuddy.com/privacyPolicy" className="text-[12px] text-center">
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </SocketContextProvider>
  );
};

export default SignUpStudent;
