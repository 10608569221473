import React, { useState, useRef, useEffect } from 'react';
import { FiEdit } from "react-icons/fi";
import axios from 'axios'; // Import Axios for making HTTP requests
import Cookies from 'js-cookie';
import { BsFilterCircleFill } from "react-icons/bs";
import { FiChevronDown } from 'react-icons/fi';

const NewPostDesktop = ({ onNewPost, onFilterApply, onClear }) => {
    const [showModal, setShowModal] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [postTitle, setPostTitle] = useState('');
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [filteredCategory, setFilteredCategory] = useState('');
    const [filteredSubCategory, setFilteredSubCategory] = useState('');
    const [filteredSelectedCategory, setFilteredSelectedCategory] = useState('');
    const [filteredSelectedSubCategory, setFilteredSelectedSubCategory] = useState('');
    const [postContent, setPostContent] = useState('');
    const [image, setImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null); // Define fileInputRef using useRef
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [loading, setLoading] = useState(false);
    const [filterLoading, setFilterLoading] = useState(false);
    const [clearLoading, setClearLoading] = useState(false);
    const authToken = Cookies.get('authToken');
    const url = process.env.REACT_APP_API_URL;

    useEffect(() => {
        // Fetch categories
        axios.get(`${url}/api/categories/getAllCategory`)
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error('Error fetching categories:', error);
            });

        // Fetch subcategories
        axios.get(`${url}/api/subCategories/getAllSubcategory`)
            .then(response => {
                setSubCategories(response.data);
            })
            .catch(error => {
                console.error('Error fetching subcategories:', error);
            });
    }, []);

    const handleModalOpen = () => {
        setPostTitle('');
        setCategory(filteredCategory); // Set default category from filtered category
        setSubCategory(filteredSubCategory); // Set default subcategory from filtered subcategory
        setPostContent('');
        setImage(null);
        setSelectedCategoryId(filteredCategory); // Set selected category ID
        setShowModal(true);
    };

    const handleModalClose = () => {
        setPostTitle('');
        setCategory('');
        setSubCategory('');
        setPostContent('');
        setImage(null);
        setShowModal(false);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        setSelectedImage(file ? URL.createObjectURL(file) : null); // Check if file exists before setting image state
    };

    const handleCreatePost = async (e) => {
        e.preventDefault();
        // Validate required fields
        if (!postTitle) {
            alert("Title is required.");
            return;
        }
        if (!postContent) {
            alert("Content is required.");
            return;
        }
        if (!category) {
            alert("Category is required.");
            return;
        }
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("postTitle", postTitle);
            formData.append('category', category);

            if (subCategory) { // Only append subCategory if it's provided
                formData.append('subCategory', subCategory);
            }

            formData.append('postContent', postContent);
            formData.append('image', image);

            const response = await axios.post(`${url}/api/forum/create`,
                formData, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            // console.log(response.data); // Log the response from the server

            // Clear form fields and close modal after successful creation
            setPostTitle('');
            setCategory('');
            setSubCategory('');
            setPostContent('');
            setImage(null);
            onNewPost(response.data.post);
            // console.log("new post",response.data.post)
            setShowModal(false);
        } catch (error) {
            console.error(error);
            // Handle error appropriately, e.g., show error message to the user
        } finally {
            setLoading(false); // Set loading to false when the post creation process is complete
        }
    };

    useEffect(() => {
        setFilteredSelectedSubCategory(''); // Reset filteredSelectedSubCategory when filteredSelectedCategory changes
    }, [filteredSelectedCategory]);

    const handleFilterApply = async () => {
        setFilterLoading(true); // Set filterLoading to true when starting the filter application process
        setTimeout(() => {
            setShowFilterModal(false);
            setIsFilterApplied(true); // Set filter application to true when filter is applied
            onFilterApply(filteredCategory, filteredSubCategory);
            setFilterLoading(false); // Set filterLoading to false when the filter application process is complete
        }, 2000);
    };

    const handleClear = async () => {
        setClearLoading(true); // Set clearLoading to true when starting the clear process
        setTimeout(() => {
            setIsFilterApplied(false);
            setFilteredCategory('');
            setFilteredSubCategory('');
            onClear(filteredCategory, filteredSubCategory);
            setClearLoading(false); // Set clearLoading to false when the clear process is complete
        }, 2000);
    };

    return (
        <div>
            {/* new section desktop */}
            <div className="hidden sm:block mb-16">
                <button className="text-xl text-white bg-gradient w-full py-2 rounded-full mb-6 flex items-center justify-center gap-4" onClick={handleModalOpen}>
                    <FiEdit className="w-[28px] h-[25px]" />
                    <span>Create a post</span>
                </button>

                <div className="sm:flex hidden flex-col w-full items-center bg-white rounded-[25px] border border-orange-500 py-6 px-4">
                    <div className="text-[20px] font-semibold text-left self-start ml-4 mb-4 flex items-center gap-3">
                        <BsFilterCircleFill size={24} className='text-orange-400' />
                        <span className="text-gradient">Filter Posts:</span>
                    </div>
                    <div className="flex flex-col gap-4 items-center w-full mx-4">
                        <div className="py-1 w-full flex lg:flex-col gap-4">
                            <div className="relative border border-orange-500 w-full flex justify-center rounded-full px-4">
                                <select
                                    className="appearance-none focus:outline-none bg-no-repeat text-gradient font-bold w-full py-2 rounded-md"
                                    value={filteredCategory}
                                    onChange={(e) => { setFilteredCategory(e.target.value); setFilteredSelectedCategory(e.target.options[e.target.selectedIndex].text) }}
                                >
                                    <option value="" className="text-left w-1/2">Category</option>
                                    {categories.map((cat) => (
                                        <option className="w-screen" key={cat._id} value={cat._id}>
                                            {cat.name}
                                        </option>
                                    ))}
                                </select>
                                <FiChevronDown className='absolute top-1/2 right-5 transform -translate-y-1/2 text-orange-500'></FiChevronDown>
                            </div>
                            <div className="relative border border-orange-500 w-full flex justify-center rounded-full px-4">
                                <select
                                    className="appearance-none focus:outline-none py-2 w-full text-base text-gradient font-bold bg-no-repeat rounded-md"
                                    value={filteredSubCategory}
                                    onChange={(e) => { setFilteredSubCategory(e.target.value); setFilteredSelectedSubCategory(e.target.options[e.target.selectedIndex].text) }}
                                >
                                    <option value="" className='text-left w-1/2'>Subcategory</option>
                                    {subCategories
                                        .filter((subcat) => subcat.categoryId === filteredCategory)
                                        .map((subcat) => (
                                            <option className='w-screen' key={subcat._id} value={subcat._id}>
                                                {subcat.name}
                                            </option>
                                        ))}
                                </select>
                                <FiChevronDown className='absolute top-1/2 right-4 transform -translate-y-1/2 text-orange-500'></FiChevronDown>
                            </div>
                        </div>
                        <div className='sm:flex lg:flex-col w-full gap-2'>
                            <button className={`text-xl text-white bg-gradient w-full py-2 rounded-full ${filterLoading? 'opacity-50 cursor-not-allowed':''}`} onClick={handleFilterApply}
                                title='apply filters' disabled={filterLoading}>{filterLoading ? "Applying filters..." : "Select"}</button>
                            <button onClick={handleClear} className={`text-xl text-white bg-gradient w-full py-2 rounded-full ${clearLoading ? 'opacity-50 cursor-not-allowed':''}`} disabled={clearLoading}>{clearLoading ? "Clearing..." : "Clear Filters"}</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal for creating post */}
            {showModal && (
                <form method='POST' onSubmit={handleCreatePost} encType="multipart/form-data" action={`${url}/api/forum/create`} className="w-full fixed top-0 left-0 z-50  h-full flex justify-center items-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg w-6/12">
                        <h2 className="text-[20px] font-semibold mb-4" style={{
                            background: 'linear-gradient(180deg, #ff6535 0%, #ff9e48 48.9%, #ffce58 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}>Create Post</h2>
                        <input
                            type="text"
                            placeholder="Post Title"
                            value={postTitle}
                            onChange={(e) => setPostTitle(e.target.value)}
                            className="mb-2 w-full border border-gray-300 rounded-md p-2 focus:outline-none"
                            required
                        />
                        <textarea
                            placeholder="Content"
                            value={postContent}
                            onChange={(e) => setPostContent(e.target.value)}
                            className="mb-2 w-full border border-gray-300 rounded-md p-2 focus:outline-none"
                            rows="4"
                            required
                        />
                        <select
                            required
                            value={category}
                            onChange={(e) => {
                                setCategory(e.target.value);
                                setSelectedCategoryId(e.target.value); // Update selected category ID
                            }}
                            className="mb-2 w-full border border-gray-300 rounded-md p-2 focus:outline-none"
                        >
                            <option value="">Select Category</option>
                            {categories.map((cat) => (
                                <option key={cat._id} value={cat._id}> {/* Concatenate ID and name */}
                                    {cat.name}
                                </option>
                            ))}
                        </select>

                        <select
                            value={subCategory}
                            onChange={(e) => setSubCategory(e.target.value)}
                            className="mb-2 w-full border border-gray-300 rounded-md p-2 focus:outline-none"
                        >
                            <option value="">Select Subcategory</option>
                            {subCategories
                                .filter((subcat) => subcat.categoryId === selectedCategoryId)
                                .map((subcat) => (
                                    <option key={subcat._id} value={subcat._id}>
                                        {subcat.name}
                                    </option>
                                ))}
                        </select>

                        <div className="flex justify-start items-center mb-2">
                            {image && (
                                <img
                                    src={selectedImage}
                                    alt="Selected Post Image"
                                    className="w-20 h-20 object-contain border border-gray-300"
                                />
                            )}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <input
                                type="file" accept="image/*" onChange={handleImageChange} ref={fileInputRef}
                                className="border border-gray-300 px-2 py-2 text-gray-400 text-left rounded-md"
                            />
                            <div className='flex flex-row items-center'>
                                <button type="submit" className={`text-white px-4 py-2 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} style={{ background: 'linear-gradient(180deg, #ff6535 0%, #ff9e48 48.9%, #ffce58 100%)' }} 
                                disabled={loading} >{loading ? "Creating post..." : "Create"}</button>
                                <button type="button" className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md ml-2" onClick={handleModalClose}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </form >
            )}
        </div>
    );
}

export default NewPostDesktop;
