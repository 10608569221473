import React from 'react'
import NavHome from '../../components/nav/navHome';
import Footer from '../../components/nav/footer';
import Header from '../../components/headerBackground';
import privacy from '../../assets/img/privacy.jpg'
import { Link, Navigate } from 'react-router-dom';
import Cookies from "js-cookie";
import NavBar from '../../components/nav/navBar';

const PrivacyPolicy = () => {
    const isAuthenticated = Cookies.get("authToken") !== undefined;
    const url = process.env.REACT_APP_API_URL;
    return (
        <div className=''>
            {isAuthenticated ? <NavBar /> : <NavHome />}
            <section className="mb-0 sm:mb-0 font-primary ">
                <Header />
                <div className='sm:mt-20 lg:mt-28 mt-[160px] mx-2 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 flex flex-col justify-center gap-4'>
                    <h2 className='font-semibold sm:text-2xl lg:text-3xl text-xl text-center text-[#585858]'>PRIVACY POLICY </h2>
                    <div className='sm:flex flex-cols gap-6 items-start sm:mt-10 mb-10'>
                        <div className='sm:w-[40%] flex items-center justify-center'>
                            <img src={privacy} alt="" className='border-2 rounded-lg sm:w-[400px] sm:h-[400px] lg:w-[450px] lg:h-[400px] h-[250px] w-[250px]' />
                        </div>
                        <div className='sm:w-[60%] text-base sm:text-base lg:text-lg flex flex-col gap-6 text-left mt-2 mb-6 mx-2'>
                            <div className='w-full overflow-y-auto flex flex-col gap-1 text-left'>
                                <h3 className='font-semibold'>Your Privacy Matters</h3>
                                <p>CGB ORG is a software company which develops software for students,
                                    teachers and subject matter experts. CareerGuidanceBuddy is one of the
                                    web applications developed by and owned by CBG ORG.
                                    CareerGuidanceBuddy’s mission is to connect the world’s Students with
                                    Teachers and Subject Matter Expert to allow them to be more productive
                                    and successful. Central to this mission is our commitment to be
                                    transparent about the data we collect about you, how it is used and with
                                    whom it is shared.
                                    This Privacy Policy applies when you use our Services (described below).
                                    We offer our users choices about the data we collect, use and share as
                                    described in this Privacy Policy, Cookie Policy, Settings and our Help
                                    Center.</p>
                            </div>
                            <div className='w-full overflow-y-auto flex flex-col gap-1 text-start'>
                                <h3 className='font-semibold'>Table of Contents</h3>
                                <p>1. Data We Collect </p>
                                <p>2. How We Use Your Data</p>
                                <p>3. How We Share Information</p>
                                <p>4. Your Choices and Obligations</p>
                                <p>5. Other Important Information</p>
                            </div>
                            <div className='w-full overflow-y-auto flex flex-col gap-1'>
                                <h3 className='font-semibold' >Introduction</h3>
                                <p>We are a social network and online platform for Students, Parents,
                                    Individuals and Teachers and Subject Matter Experts and
                                    professionals. People use our Services to find and be found for
                                    business and educational opportunities, to connect with others and
                                    find information. Our Privacy Policy applies to any Member or
                                    Our registered users (“Members”) share their professional identities,
                                    engage with their network, exchange knowledge and professional
                                    insights, post and view relevant content, learn (including Calls and
                                    Conferences) and develop skills, and find business and career
                                    opportunities. Content and data on some of our Services is viewable
                                    to non-members (“Visitors”).</p>
                            </div>
                            <div className='w-full overflow-y-auto flex flex-col gap-1'>
                                <h3 className='font-semibold'>Services</h3>
                                <p>This Privacy Policy, including our Cookie Policy applies to your use of
                                    our Services.   </p>
                                <p> This Privacy Policy applies to CareerGuidanceBuddy.com, CGBORG-
                                    branded, CGB-branded apps, CGBORG-branded apps, CGBORG
                                    Learning and other CGBORG-related sites, apps, communications,
                                    and services (“Services”), including off-site Services, such as our ad
                                    services and the “Apply with CareerGudanceBuddy CGBORG” and
                                    “Share with CareerGuidanceBuddy CGB ORG” plugins, but excluding
                                    services that state that they are offered under a different privacy
                                    policy.</p>
                            </div>
                            <div className='w-full overflow-y-auto flex flex-col gap-1'>
                                <h3 className='font-semibold'>Data Controllers and Contracting Parties</h3>
                                <p>CGBORG will be the controller of your personal data provided to, or
                                    collected by or for, or processed in connection with, our Services.
                                    As a Visitor or Member of our Services, the collection, use and
                                    sharing of your personal data is subject to this Privacy Policy and
                                    other documents referenced in this Privacy Policy, as well as
                                    updates.</p>
                            </div>
                            <div className='w-full overflow-y-auto flex flex-col gap-1'>
                                <h3 className='font-semibold'>Change</h3>
                                <p>Changes to the Privacy Policy apply to your use of our Services after
                                    the “effective date.”</p>
                                <p>CGBORG / CareerGuidanceBuddy (“we” or “us”) can modify this
                                    Privacy Policy, and if we make material changes to it, we will
                                    provide notice through our Services, or by other means, to provide
                                    you the opportunity to review the changes before they become
                                    effective. If you object to any changes, you may close your account.
                                    You acknowledge that your continued use of our Services after we
                                    publish or send a notice about our changes to this Privacy Policy
                                    means that the collection, use and sharing of your personal data is
                                    subject to the updated Privacy Policy, as of its effective date.</p>
                            </div>
                            <div className='w-full overflow-y-auto flex flex-col gap-1'>
                                <ol type='1' style={{ listStyleType: 'decimal', paddingLeft: '20px' }} className='font-semibold flex flex-col gap-6 text-[#585858]'>
                                    <li>
                                        Data We Collect
                                        <ol type='1' style={{ listStyleType: 'lower-roman', paddingLeft: '20px' }} className='font-semibold flex flex-col gap-4 mt-2'>
                                            <li>
                                                <h3>Data You Provide To Us</h3>
                                                <p className='font-normal'>You provide data to create an account with us.</p>
                                                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }} className='font-semibold flex flex-col gap-2'>
                                                    <li>
                                                        <h3>Registration</h3>
                                                        <p className='font-normal'>To create an account you need to provide data including your name, email address general location (e.g., city), and a password. If you register for a premium subscription or platinum subscription, you will need to provide payment (e.g., credit card, UPID) and billing information. You create your CareerGuidanceBuddy profile (a complete profile helps you get the most from our Services).</p>
                                                    </li>
                                                    <li>
                                                        <h3>Profile</h3>
                                                        <p className='font-normal'>You have choices about the information on your profile, such as your education, work experience, skills, photo, city or area and endorsements. You don’t have to provide additional information on your profile; however, profile information helps you to get more from our Services, including helping members and business opportunities find you. It’s your choice whether to include sensitive information on your profile and to make that sensitive information public. Please do not post or add personal data to your profile that you would not want to be publicly available. You give other data to us, such as by syncing your address book or calendar.</p>
                                                    </li>
                                                    <li>
                                                        <h3>Posting and Uploading</h3>
                                                        <p className='font-normal'>We collect personal data from you when you provide, post or upload it to our Services, such as when you fill out a form, (e.g., with demographic data), respond to a survey, or booking a call or conference on our Services. If you opt to import your address book, we receive your contacts (including contact information your service provider(s) or app automatically added to your address book when you communicated with addresses or numbers not already in your list). If you sync your contacts or calendars with our Services, we will collect your address book and calendar meeting information to keep growing your network by suggesting connections for you and others, and by providing information about events, e.g. times, places, attendees and contacts. You don’t have to post or upload personal data; though if you don’t, it may limit your ability to grow and engage with your network over our Services.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>Data From Others</h3>
                                                <p className='font-normal'>Others may post or write about you.</p>
                                                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }} className='font-semibold flex flex-col gap-2'>
                                                    <li>
                                                        <h3>Content and News</h3>
                                                        <p className='font-normal'>You and others may post content that includes information about you (as part of articles, posts, comments, pictures) on our Services. We also may collect public information about you, such as professional-related news and accomplishments, and make it available as part of our Services, including, in notifications to others of mentions in the news.Others may sync their contacts or calendar with our Services</p>
                                                    </li>
                                                    <li>
                                                        <h3>Contact and Calendar Information</h3>
                                                        <p className='font-normal'>We receive personal data (including contact information) about you when others import or sync their contacts or calendar with our Services, associate their contacts with Member profiles, scan and upload business cards, or send messages using our Services (including invites for conferences or call requests). If you or others opt-in to sync email accounts with our Services, we will also collect “email header” information that we can associate with Member profiles. Customers and partners may provide data to us.</p>
                                                    </li>
                                                    <li>
                                                        <h3>Partners</h3>
                                                        <p className='font-normal'>We receive personal data (e.g., your job title and work email address) about you when you use the services of our customers and partners, such as Teachers or Students and providing call and conference review data.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>Service Use</h3>
                                                <p className='font-normal'>We log your visits and use of our Services. </p>
                                                <p className='font-normal mt-4'>We log usage data when you visit or otherwise use our Services, including our sites and platform technology, such as when you view or click on content (e.g., learning video) or ads (on or off our sites and apps), perform a search, install or update one of our mobile apps, share articles or calendar or conference or book a call or opt for a conference. We use log-ins, cookies, device information and internet protocol (“IP”) addresses to identify you and log your use.</p>
                                            </li>
                                            <li>
                                                <h3>Cookies and Similar Technologies</h3>
                                                <p className='font-normal'>We collect data through cookies and similar technologies.</p>
                                                <p className='font-normal mt-4'>As further described in our Cookie Policy, we use cookies and similar technologies (e.g., pixels and ad tags) to collect data (e.g., device IDs) to recognize you and your device(s) on, off and across different services and devices where you have engaged with our Services. We also allow some others to use cookies as described in our Cookie Policy. We also collect (or rely on others who collect) information about your device where you have not engaged with our Services (e.g., ad ID, IP address, operating system and browser information) so we can provide our Members with relevant ads/information and better understand their effectiveness. </p>
                                            </li>
                                            <li>
                                                <h3>Your Device and Location</h3>
                                                <p className='font-normal'>We receive data through cookies and similar technologies.</p>
                                                <p className='font-normal mt-4'>When you visit or leave our Services (including some plugins and our cookies or similar technology on the sites of others), we receive the URL of both the site you came from and the one you go to and the time of your visit. We also get information about your network and device (e.g., IP address, proxy server, operating system, web browser and add-ons, device identifier and features, cookie IDs and/or ISP, or your mobile carrier). If you use our Services from a mobile device, that device will send us data about your location based on your phone settings. We will ask you to opt-in before we use GPS or other tools to identify your precise location. </p>
                                            </li>
                                            <li>
                                                <h3>Messages</h3>
                                                <p className='font-normal'>If you communicate through our Services, we learn about that.</p>
                                                <p className='font-normal mt-4'>We collect information about you when you send, receive, or engage with messages in connection with our Services. For example, if you get a Chat message, we track whether you have acted on it and will send you reminders. We also use automatic scanning technology on messages to support and protect our site. For example, manage or block content that violates our User Agreement or Professional Community Policies from our Services.</p>
                                            </li>
                                            <li>
                                                <h3>Work Information</h3>
                                                <p className='font-normal'>When Student/Parent buys a premium Service from you (Teacher/Subject Matter Expert) to use, they give us data about you.</p>
                                                <p className='font-normal mt-4'>After the call and after the conference the Students/Parents can rate your premium service. That information will be available in the form of Rating and Review and visible to other students/parents for future reference.</p>
                                            </li>
                                            <li>
                                                <h3>Other</h3>
                                                <p className='font-normal'>We are improving our Services, which means we get new data and create new ways to use data.</p>
                                                <p className='font-normal mt-4'>Our Services are dynamic, and we often introduce new features, which may require the collection of new information. If we collect materially different personal data or materially change how we collect, use or share your data, we will notify you and may also modify this Privacy Policy.</p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        How We Use Your Data
                                        <p className='font-normal'>We use your data to provide, support, personalize and develop our Services.</p>
                                        <p className='font-normal mt-4'>How we use your personal data will depend on which Services you use, how you use those Services. We use the data that we have about you to provide and personalize our Services, including with the help of automated systems and inferences we make, so that our Services (including ads) can be more relevant and useful to you and others.</p>
                                        <ol type='1' style={{ listStyleType: 'lower-roman', paddingLeft: '20px' }} className='font-semibold flex flex-col gap-4 mt-2'>
                                            <li>
                                                <h3>Services</h3>
                                                <p className='font-normal'>Our Services help you connect with others, find and be found for learning and new opportunities, stay informed, get training and be more productive.</p>
                                                <p className='font-normal mt-4'>We use your data to authorize access to our Services.</p>
                                                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }} className='font-semibold flex flex-col gap-2'>
                                                    <li>
                                                        <h3>Stay Connected</h3>
                                                        <p className='font-normal'>Our Services allow you to stay in touch and up to date with fellow students, juniors, seniors and Teachers and Subject matter expert. To do so, you can “search” with the professionals who you choose, and who also wish to “chat”, accept calls and allow you on conferences. Subject to your and their preferences, when you search and connect with other Members through Forum or Chat, you will be able to search each others’ availability connections in order to exchange professional opportunities.</p>
                                                        <p className='font-normal'>We use data about you (such as your profile, profiles you have viewed or data provided through address book uploads or partner integrations) to help others find your profile, suggest you and others (e.g. Members who are expert in your domain or looking for expert advice) and enable you to invite others to become a Member and connect with you. You can also opt-in to allow us to use your precise location or proximity to others for certain tasks (e.g. to suggest other nearby Members for you to connect with, calculate the commute to a new study, or notify your connections that you are at a professional event).</p>
                                                    </li>
                                                    <li>
                                                        <h3>Stay Informed</h3>
                                                        <p className='font-normal'>Our Services allow you to stay informed about news, events and ideas regarding professional and study topics you care about, and from professionals you can learn. Our Services also allow you to improve your professional skills, or learn new ones. We use the data we have about you (e.g., data you provide, data we collect from your engagement with our Services and inferences we make from the data we have about you), to personalize our Services for you, such as by recommending or ranking relevant content and conversations on our Services. We also use the data we have about you to suggest skill development you would like to add to your profile and skills that you might need to pursue your next opportunity. So, if you let us know, presently you are pursuing a particular study that you are interested in a new skill (e.g., Presently in Category – Mid School and Interested in Category – High Scholl Sub category -Physics or Category – Life Style Sub category – Time Management), we will use this information to personalize content in your feed, suggest that you follow certain members on our site, or suggest related learning content to help you towards that new skill. We use your content, activity and other data, including your name and photo, to provide notices to your network and others. We use the details shared in your personal information to make you searchable to other members on other social media platforms. We highly recommend LinkedIn data as a reference point for professionals and every member on CareerGuidanceBuddy.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>Premium Services</h3>
                                                <p className='font-normal'>Our premium and platinum membership/subscription help users (Teachers/Subject Matter Expert) to share their calendar and solve student’s problem over a paid call and create paid conferences where more than 1 student can learn together and promote content through social media.</p>
                                                <p className='font-normal'>However, if a teacher do not join the call or conference the students are re-funded and after every call and conference students share their feedback and review which is available to all other members on the platform. </p>
                                            </li>
                                            <li>
                                                <h3>Communications</h3>
                                                <p className='font-normal'>We contact you and enable communications between Members. </p>
                                                <p className='font-normal mt-4'>We will contact you through email, mobile phone, notices posted on our websites or apps, messages to your chat inbox, and other ways through our Services, including text messages and push notifications. We will send you messages about the availability of our Services, security, or other service-related issues. We also send messages about how to use our Services, network updates, reminders, member suggestions and promotional messages from us and our partners. Please be aware that you cannot opt out of receiving service messages from us, including security and legal notices.</p>
                                                <p className='font-normal'>We also enable communications between you and others members through our Services.</p>
                                            </li>
                                            <li>
                                                <h3>Marketing</h3>
                                                <p className='font-normal'>We promote our Services to you and others.</p>
                                                <p className='font-normal mt-4'>In addition to advertising our Services, we use Members’ data and content for invitations and communications promoting membership and network growth, engagement and our Services, such as by re-sharing your information on other social media platforms or other media platforms as well.</p>
                                            </li>
                                            <li>
                                                <h3>Developing Services and Research</h3>
                                                <p className='font-normal'>We develop our Services and conduct research</p>
                                            </li>
                                            <li>
                                                <h3>Customer Support</h3>
                                                <p className='font-normal'>We use data to help you and fix problems.</p>
                                                <p className='font-normal mt-4'>We use data (which can include your communications) to investigate, respond to and resolve complaints and for Service issues (e.g., bugs).</p>
                                            </li>
                                            <li>
                                                <h3>Insights That Do Not Identify You</h3>
                                                <p className='font-normal'>We use data to generate insights that do not identify you. </p>
                                                <p className='font-normal mt-4'>We use your data to produce and share insights that do not identify you. For example, we may use your data to generate statistics about our members, their profession or industry, to calculate ad impressions served or clicked on, or to publish visitor demographics for a Service or create demographic student or teacher insights.</p>
                                            </li>
                                            <li>
                                                <h3>Security and Investigations</h3>
                                                <p className='font-normal'>We use data for security, fraud prevention and investigations.</p>
                                                <p className='font-normal mt-4'>We use your data (including your communications) for security purposes or to prevent or investigate possible fraud or other violations of our User Agreement and/or attempts to harm our Members, Visitors or others.</p>
                                            </li>

                                        </ol>
                                    </li>
                                    <li>
                                        How We Share Information
                                        <ol type='1' style={{ listStyleType: 'lower-roman', paddingLeft: '20px' }} className='font-semibold flex flex-col gap-4 mt-2'>
                                            <li>
                                                <h3>Our Services</h3>
                                                <p className='font-normal'>Any data that you include on your profile and any content you post or social action (e.g., likes, follows, comments, shares) you take on our Services will be seen by others.</p>
                                                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }} className='font-semibold flex flex-col gap-2'>
                                                    <li>
                                                        <h3>Profile</h3>
                                                        <p className='font-normal'>Student’s profile is fully visible to the teachers and subject matter expert and Teacher or Subject Matter Experts’s profile is fully visible to the student’s. Your profile is fully visible to all Members and customers of our Services. It can also be visible to others on or off of our Services (e.g., Visitors to our Services or users of third- party search engines). Access channels and search types (e.g., by name or by keyword) impact the availability of your profile and whether they can view certain fields in your profile.</p>
                                                        <p className='font-normal'>CareerGidanceBuddy never ask students or parents to share their phone or mobile numbers. </p>
                                                    </li>
                                                    <li>
                                                        <h3>Posts, Likes, Follows, Comments, Messages</h3>
                                                        <p className='font-normal'>Our Services allow viewing and sharing information including through posts, likes, follows and comments.</p>
                                                        <p className='font-normal mt-4'>When you share an article on the forum a post (e.g., an update, image, or article) publicly it can be viewed by everyone and re-shared anywhere. Members, Visitors and others will be able to find and see your shared content, including your name (and photo if you have provided one). Any information you share as feedback or rating after a call or conference you attended is visible to other members and available publicly.</p>
                                                        <p className='font-normal'>When you like or re-share or comment on another’s content (including ads, profile, calendar), others will be able to view these “social actions” and associate it with you (e.g., your name, profile and photo if you provided it).</p>
                                                    </li>
                                                    <li>
                                                        <h3></h3>
                                                        <p className='font-normal'></p>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>Service Providers</h3>
                                                <p className='font-normal'>We may use others to help us with our Services. </p>
                                                <p className='font-normal mt-4'>We use others to help us provide our Services (e.g., maintenance, analysis, audit, payments, fraud detection, marketing and development). They will have access to your information as reasonably necessary to perform these tasks on our behalf and are obligated not to disclose or use it for other purposes.</p>
                                                <p className='font-normal'>Teachers and Subject Matter Experts use their choice of other platforms to take calls and host conferences and they will have access to your information according to their policy terms.</p>
                                            </li>
                                            <li>
                                                <h3>Legal Disclosures</h3>
                                                <p className='font-normal'>We may need to share your data when we believe it’s required by law or to help protect the rights and safety of you, us or others.</p>
                                                <p className='font-normal'>It is possible that we will need to disclose information about you when required by law, subpoena, or other legal process or if we have a good faith belief that disclosure is reasonably necessary to (1) investigate, prevent or take action regarding suspected or actual illegal activities or to assist government enforcement agencies; (2) enforce our agreements with you; (3) investigate and defend ourselves against any third-party claims or allegations; (4) protect the security or integrity of our Services (such as by sharing with companies facing similar threats); or (5) exercise or protect the rights and safety of CareerGuidanceBuddy, our Members, personnel or others. We attempt to notify Members about legal demands for their personal data when appropriate in our judgment, unless prohibited by law or court order or when the request is an emergency. We may dispute such demands when we believe, in our discretion, that the requests are overbroad, vague or lack proper authority, but we do not promise to challenge every demand.</p>
                                            </li>
                                            <li>
                                                <h3>Change in Control or Sale</h3>
                                                <p className='font-normal'>We may share your data when our business is sold to others, but it must continue to be used in accordance with this Privacy Policy.</p>
                                                <p className='font-normal'> We can also share your personal data as part of a sale, merger or change in control, or in preparation for any of these events. Any other entity which buys us or part of our business will have the right to continue to use your data, but only in the manner set out in this Privacy Policy unless you agree otherwise.</p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        Your Choices & Obligations
                                        <ol type='1' style={{ listStyleType: 'lower-roman', paddingLeft: '20px' }} className='font-semibold flex flex-col gap-4 mt-2'>
                                            <li>
                                                <h3> Data Retention</h3>
                                                <p className='font-normal'>We keep most of your personal data for as long as your account is open.</p>
                                                <p className='font-normal mt-4'>We generally retain your personal data as long as you keep your account open or as needed to provide you Services. This includes data you or others provided to us and data generated or inferred from your use of our Services. Even if you only use our Services when you want to know more about any subject or decipline every few years, we will retain your information and keep your profile open, unless you close your account. In some cases we choose to retain certain information (e.g., insights about Services use) in a depersonalized or aggregated form.</p>
                                            </li>
                                            <li>
                                                <h3>Account Closure</h3>
                                                <p className='font-normal'>We keep some of your data even after you close your account.</p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        Other Important Information
                                        <ol type='1' style={{ listStyleType: 'lower-roman', paddingLeft: '20px' }} className='font-semibold flex flex-col gap-4 mt-2'>
                                            <li>
                                                <h3>Security</h3>
                                                <p className='font-normal'>We monitor for and try to prevent security breaches. Please use the security features available through our Services.</p>
                                                <p className='font-normal mt-4'>We implement security safeguards designed to protect your data, such as HTTPS. We regularly monitor our systems for possible vulnerabilities and attacks. However, we cannot warrant the security of any information that you send us. There is no guarantee that data may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. </p>
                                            </li>
                                            <li>
                                                <h3> Cross-Border Data Transfers</h3>
                                                <p className='font-normal'>We store the data in India and other countries. We may store and use your data outside your own country. As a member of a Global platform your personal data which you shared over the platform may be transferred to other countries than your own. You are well aware of this fact, and you accept this.</p>
                                                <p className='font-normal mt-4'>We process data both inside and outside of India. and rely on legally-provided mechanisms to lawfully transfer data across borders. Countries where we share data may have laws which are different from, and potentially not as protective as, the laws of your own country.</p>
                                            </li>
                                            <li>
                                                <h3>Lawful Bases for Processing</h3>
                                                <p className='font-normal'>We have lawful bases to collect, use and share data about you. You have choices about our use of your data. At any time, you can withdraw consent and leave the platform.</p>
                                            </li>
                                            <li>
                                                <h3>Contact Information</h3>
                                                <p className='font-normal'> You can contact us or use other options to resolve any complaints. Please feel free to reach-out to us at support@cgborg.com or support@carrerguidancebuddy.com</p>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy
