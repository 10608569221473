import { useState, useEffect} from "react";
import Cookies from "js-cookie"
import StudentProfile from './student/studentProfile';
import TeacherProfile from './teacher/teacherProfile';
import MainMenu from "../components/MenuBottom/MainMenu"
import axios from "axios";
import Loading2 from "../components/Loading2";
import { Helmet } from "react-helmet-async";
import MetaDecorator from "../utils/MetaDecorator";

const EditProfile = ({setShowMessage,setMessage,profilePic}) => {

  const user_type=Cookies.get("user_type")
  const apiUrl = process.env.REACT_APP_API_URL;
  const authToken = Cookies.get("authToken"); 
  const [loading,setLoading]=useState(true)
  const [found,setFound]=useState()
  console.log(user_type)

  useEffect(() => {
    if(user_type === 'Teacher'){
      Get_Teacher_Details();
    }else{
      Get_Student_Details();
    }
    
    
  }, []);

  const Get_Teacher_Details = async () =>{
    const response = await axios.get(`${apiUrl}/api/teacher/get-details`,{
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    // console.log("Details ",response.data.found)
    setLoading(false)
    setFound(response.data.found)
  }

  const Get_Student_Details = async()=>{
    const response = await axios.get(`${apiUrl}/api/student/get-details`,{
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    // console.log("Details ",response.data.found)
    setLoading(false)
    setFound(response.data.found)
  }

  if(loading){
    return <Loading2/>
  }
  const keywords = ["elearning", "education", "online class"]; 
  return (
    <>
    {/* <Helmet>
        <title>Profile - Career Guidance Buddy</title>
        <meta name="keywords" content="education fourm,search teacher,search student, education, online class,e-learning" />
      </Helmet> */}
      <MetaDecorator title={'Profile - Career Guidance Buddy'} description={''} imageUrl={''} keywords={keywords} imageAlt={''} url={''}/>
    {
        user_type == "Student" ? (
            <StudentProfile setShowMessage={setShowMessage} setMessage={setMessage} profilePic={profilePic} found={found}/>
        ) : (
            <TeacherProfile setShowMessage={setShowMessage} setMessage={setMessage} profilePic={profilePic} found={found}/>
        )
    }
    {/* menu component */}
    {found ?(
      <div className='pt-9'>
        <MainMenu/>
      </div>
    ):(null)}
    
  </>
  )
}

export default EditProfile;