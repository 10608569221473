import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const SideBar = () => {
    const loggedInUserId = Cookies.get('loggedInUserId');
    const [loggedInUser, setLoggedInUser] = useState("");
    const authToken = Cookies.get('authToken');
    const user_type = Cookies.get("user_type");
    const url = process.env.REACT_APP_API_URL;
    const file_url = 'https://cgb-storage.blr1.digitaloceanspaces.com';
    let defaultProfile = `${file_url}/profile-pictures/defaultProfile.png`
    const navigate = useNavigate();

    useEffect(() => {
        getLoggedInUserDetails();
    }, [authToken, loggedInUserId])

    const getLoggedInUserDetails = async () => {
        try {
            // console.log(loggedInUserId)
            const response = await axios.get(`${url}/api/user-info/get-users/${loggedInUserId}`, {
                headers: {
                    "Authorization": `Bearer ${authToken}`
                }
            });
            // console.log('loggedinuser', response.data);
            setLoggedInUser(response.data);
        } catch (error) {
            console.log('Error fetching user', error);
        }
    }

    return (
        <div className="hidden sm:flex justify-center items-start min-h-full ">
            <div className="w-full sm:w-11/12 mx-auto h-fit p-4 shadow-lg rounded-[25px] border border-2 border-orange-500 sm:mb-5">
                <div className="flex flex-col justify-center items-center">
                    <img
                        src={loggedInUser.profilePic && loggedInUser.profilePic !== "" ? loggedInUser.profilePic : defaultProfile}
                        alt="profilepic"
                        className='w-20 h-20 sm:w-20 sm:h-20 md:w-20 md:h-20 lg:w-28 lg:h-28 rounded-full border'
                    />
                    <h1 className="font-semibold text-xl sm:text-xl text-center lg:text-3xl text-[#585858] mt-2">{loggedInUser.fullName}</h1>

                    {user_type === 'Teacher' && <p className="text-[#585858] text-sm sm:text-xs lg:text-base font-normal text-center">{loggedInUser.referralCode 
                              ? `Referral Code : ${loggedInUser.referralCode}` 
                              : 'Get referral code on subscription'}</p>}
                    <p className="text-[#afafaf] text-sm lg:text-base sm:text-sm font-normal">{loggedInUser.type}</p>
                </div>
                <div className="flex flex-wrap sm:flex-col gap-4 items-center justify-center mt-4">
                    <Link
                        to='/edit-profile'
                        className="shadow-md sm:text-sm lg:text-lg text-gradient font-semibold w-full sm:w-11/12 text-center py-2 px-4 rounded-full bg-white border border-2 border-orange-500 btn-toggle active"
                    >
                        Personal Details
                    </Link>
                    <Link
                        to='/edit-profile'
                        className="shadow-md sm:text-sm lg:text-lg text-gradient font-semibold w-full sm:w-11/12 text-center py-2 px-4 sm:px-2 rounded-full bg-white border border-2 border-orange-500 btn-toggle active"
                    >
                        {user_type === "Teacher" ? 'Professional Details' : 'Educational Details'}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default SideBar;
