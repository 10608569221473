import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import MainMenu from "../components/MenuBottom/MainMenu";
import Header from '../components/headerBackground';
import ProfileModal from "../Modals/ProfileModal";
import bg from "../assets/img/background-2.png";
import logo from "../assets/img/logo.svg";
import { MdEmojiEmotions } from "react-icons/md";
import { IoMdSearch } from "react-icons/io";
import NavBar from "../components/nav/navBar";
import Loading2 from "../components/Loading2"; // Import your loading spinner component

import {
  BsEmojiSmileFill,
  BsFillEmojiNeutralFill,
  BsFillEmojiWinkFill,
  BsEmojiFrownFill, BsFillEmojiLaughingFill
} from "react-icons/bs";
import { Helmet } from "react-helmet-async";
import MetaDecorator from "../utils/MetaDecorator";

const Cabinet = ({ fullName, profilePic }) => {
  // console.log(profilePic);
  const apiUrl = process.env.REACT_APP_API_URL;

  let initialProfileData = {
    display: "none",
    about: "",
    profilePic: "",
    rating: "",
    id: "",
    email: "",
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [profileData, setProfileData] = useState(initialProfileData);
  const loggedInUserId = Cookies.get('loggedInUserId');
  const [loggedInUser, setLoggedInUser] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Introduce loading state
  let authToken = Cookies.get("authToken");
  let user_type = Cookies.get("user_type");
  let search = user_type === "Student" ? "Teacher" : "Student";
  const url = process.env.REACT_APP_API_URL;
  const file_url='https://cgb-storage.blr1.digitaloceanspaces.com';
  let defaultProfile = `${file_url}/profile-pictures/defaultProfile.png`

  useEffect(() => {
    findUsers();
  }, []);

  const handleUser = () => {
    // console.log("clicked")
  }

  const emojiComponents = {
    '1': <BsEmojiFrownFill className="text-orange-500" />,
    '2': <BsFillEmojiNeutralFill className="text-orange-500" />,
    '3': <BsFillEmojiWinkFill className="text-orange-500" />,
    '4': <BsEmojiSmileFill className="text-orange-500" />,
    '5': <BsFillEmojiLaughingFill className="text-orange-500" />,
  };

  const suggestedUsers = users && users.length > 0 ? (
    users.map((user) => {
      // console.log(user)
      return (
       
        <div
          key={user.userId}
          onClick={() => {
            setProfileData({
              ...profileData,
              display: "block",
              id: user.userId,
              ...user,
              display: "flex",
              profilePic:
                user.profilePic !== "" && user.profilePic !== undefined
                  ? user.profilePic
                  : defaultProfile,
              email: user.email,
            });
          }}
          className="flex flex-row justify-between shadow-md rounded-full mx-[6px] pl-[10px] py-[10px] px-4 relative z-5 mb-2"
        > 
          <div className="flex items-center cursor-pointer gap-2" onClick={handleUser}>
            <img
              src={user.profilePic !== '' && user.profilePic !== undefined ? user.profilePic : defaultProfile}
              alt="Profile photo"
              className="rounded-full w-[50px] h-[50px] border"
            />
            <div className="">
              <h1 className="font-semibold text-[#585858] text-sm sm:text-base">
                {user.fullName}
              </h1>
              <p className="font-normal text-[11px] sm:text-[15px] text-[#585858]">
                {user.about.length > 80 ? (
                  <>{user.about.slice(0, 80) + ".."}</>
                ) : (
                  <>{user.about}</>
                )}
              </p>
            </div>
          </div>
          {user_type === "Student" ? (
            <div className="ml-auto flex flex-col justify-center items-center">
              {user.rating ? (
                <div>
                  {emojiComponents[Math.round(user.rating)]}
                  <p className="font-medium text-[15px] text-gradient">{user.rating}</p>
                </div>
              ) : (null)}
            </div>
          ) : (
            ""
          )}
        </div>
      );
    })
  ) : (<p>No users found. </p>
    
  );

  const findUsers = async () => {
    setLoading(true); // Start loading
    let response;

    try {
      if (user_type === "Teacher") {
        response = await axios.get(
          `${apiUrl}/api/student/search?name=${searchTerm}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
      } else {
        // console.log(searchTerm)
        response = await axios.get(
          `${apiUrl}/api/teacher/search?fullName=${searchTerm}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
      }

      if (response && response.data && response.data.users_list) {
        let users = response.data.users_list;

        if (user_type === "Teacher") {
          users = users.sort((a, b) => b.rating - a.rating);
          setUsers(users);
        } else {
          setUsers(response.data.users_list);
        }
      } else {
        setUsers([]);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setUsers([]);
    }
    finally{
    setLoading(false); // End loading
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    findUsers();
  };

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/auth/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      Cookies.remove("loggedInUserId");
      Cookies.remove("authToken");
      Cookies.remove("user_type");
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  useEffect(() => {
    getLoggedInUserDetails();
  }, [authToken, loggedInUserId]);

  const getLoggedInUserDetails = async () => {
    try {
      const response = await axios.get(`${url}/api/user-info/get-users/${loggedInUserId}`, {
        headers: {
          "Authorization": `Bearer ${authToken}`
        }
      });
      setLoggedInUser(response.data);
    } catch (error) {
      console.error('Error fetching user', error);
    }
  }
  const keywords = ["elearning", "education", "online class", "education","student","teacher","learning","search student","search teacher"];
  return (
    <>
    {/* <Helmet>
        <title>Cabinet - Career Guidance Buddy</title>
        <meta name="keywords" content="search student,search teacher, education, online class" />
      </Helmet> */}
      <MetaDecorator title={'Cabinet - Career Guidance Buddy'} description={'Search students and teachers on Career Guidance Buddy'} imageUrl={''} keywords={keywords} imageAlt={''} url={''}/>
      <section className="mb-20 sm:mb-0 font-primary">
        <NavBar />
        <div className="sm:mt-10">
          <div
            className="sm:hidden bg-contain bg-cover bg-no-repeat w-full h-[240px] sm:mx-auto sm:w-full"
            style={{ backgroundImage: `url("${bg}")` }}
          >
            <div className="flex justify-center items-center pt-[52px]">
              <img className="w-[189px]" src={logo} alt="logo" />
            </div>
          </div>

          {loading ?
          <Loading2/>:
            <div className="sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 mb-8">
            <div
              className="absolute w-full h-full z-5 top-0 left-0 bg-[rgb(0,0,0,0.1)]"
              style={{ display: profileData.display }}
            />
            <div className="mt-[-55px] overflow-y-auto sm:mt-0 md:grid grid-cols-3 gap-4 sm:min-h-[85vh] sm:py-[55px] mb-20" style={{ maxHeight: "calc(100vh - 200px)" }}>
              <div className="h-full sm:rounded-[25px] sm:drop-shadow-md sm:bg-white mx-1 min-h-full sm:flex justify-center items-start pt-5 sm:pb-5 md:pb-0">
                <div className="sm:rounded-[25px] sm:shadow-md sm:w-7/12 md:w-11/12 sm:border-2 sm:h-[352px] sm:border-orange-500 sm:flex flex-col items-center justify-center sm:px-3 lg:px-8">
                  <div className="flex flex-col justify-center items-center mb-4">
                    <img
                      src={
                        loggedInUser.profilePic !== "" && loggedInUser.profilePic !== undefined
                          ? loggedInUser.profilePic
                          : defaultProfile
                      }
                      alt="Profile Picture"
                      className="rounded-full border sm:w-[90px] sm:h-[90px] w-[60px] h-[60px]"
                    />
                    <h1 className="font-semibold xl:text-2xl lg:text-xl text-center text-lg text-[#585858]">
                      {loggedInUser.fullName}
                    </h1>
                    <p className="text-[#afafaf] text-sm font-normal">
                      {user_type}
                    </p>
                  </div>
                  <div className="flex items-center justify-center sm:w-full px-2 w-full sm:px-0">
                    <Link
                      to="/search"
                      className="mr-0 sm:mr-0 bg-gradient text-center rounded-full text-white py-2 px-4 w-10/12 sm:w-full"
                    >
                      Go to detailed search
                    </Link>
                  </div>

                  <div className="flex flex-row gap-2 items-center justify-center mt-4 pb-3 shadow-md px-8 sm:px-0 sm:shadow-none rounded-b-[50px] sm:rounded-b-none w-full">
                    <form onSubmit={handleSearch} className="w-full">
                      <div className="flex">
                        <div className="relative mt-1 mr-2">
                          <div className="absolute inset-y-0 left-0 pl-[15px] flex items-center pointer-events-none">
                            <IoMdSearch color="#afafaf" size={20} />
                          </div>
                          <input
                            type="text"
                            className="pl-10 pr-4 py-3 border border-[#585858] text-sm w-full rounded-full"
                            placeholder={`Search by ${search}'s name`}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </div>
                        <button
                          type="submit"
                          className="mr-0 bg-gradient rounded-full text-white py-1 sm:py-2 px-4 w-3/12 sm:w-4/12"
                        >
                          Go
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="px-3 col-span-2 sm:h-full sm:rounded-[25px] bg-white mx-2 sm:drop-shadow-md min-h-full">
                <h1 className="mt-5 mb-4 font-semibold text-[#585858] text-sm sm:text-xl pl-5">
                  Suggested for you:
                </h1>
                <div className="overflow-y-auto relative" style={{ maxHeight: "calc(100vh - 200px)" }}>
                  {suggestedUsers}
                
                  <ProfileModal
                    profileData={profileData}
                    setProfileData={setProfileData}
                  />
                </div>
              </div>
            </div>
          </div>}
        </div>
      </section>
      <MainMenu />
    </>
  );
};

export default Cabinet;
