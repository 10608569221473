import React,{useState,useEffect} from 'react'
import { BsChevronRight } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from "axios";
import Loading2 from '../../Loading2';
import Empty_data from '../../Empty_data';
import moment from 'moment-timezone';


const Conference_list = (props) => {
  
  //console.log(date)
  const { date } = props;
  //console.log(date)
    const [cardsData, setCardsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [user_data,setUser_data]=useState();
    const user_type = Cookies.get('user_type');
    const [time_zone,setUser_time]=useState()
    const apiUrl = process.env.REACT_APP_API_URL;
    const user_loggedInId = Cookies.get('loggedInUserId')
    
    
    const authToken = Cookies.get("authToken");
    const navigate = useNavigate();
    useEffect(() => {
       fetch_user_Details();
        FetchConferenceDetails();
     
       }, [date]);


       const fetch_user_Details = async () => {
        try {

            const user_details = await axios.get(`${apiUrl}/api/auth/user_details/${user_loggedInId}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            })
            const user = user_details.data;
            // console.log(user);
            // console.log("Name", user.user.fullName)
            // console.log("Eamil", user.user.email)
            setUser_data(user);
            setUser_time(user.user_Details.time_zone);
            // console.log("Time Zone",user_time)


        } catch (error) {
            console.error('Server Error:', error.response.data);
        }
    }

       

     const FetchConferenceDetails = async() =>{
        try {
          
          const response = await axios.get(`${apiUrl}/api/conference/getConferenceDetails`, {
            headers: {
              
              'Authorization': `Bearer ${authToken}`,
            },
          });
          const data = response.data;
  
          // FILTER CARD BASED ON CURRENT DATE
        const currentDate = date;
        //console.log(date)
        let formattedCurrentDate
        if(user_type === 'Student'){
        formattedCurrentDate = moment(currentDate).tz(time_zone).format('YYYY-MM-DD');
        }else{
          formattedCurrentDate = moment(currentDate).format('YYYY-MM-DD');
        }
  
        console.log("formatted Date",formattedCurrentDate);


        const filteredData = data.filter((conference) => {
          //CONFERENCE DATE IN THIS FORMAT YYYY-MM-DD
          //console.log(conference)
          console.log("converted time==>",conference.Conference_Date.split('T')[0],formattedCurrentDate)
          return conference.Conference_Date.split('T')[0] === formattedCurrentDate;
        });
  
          
          
          console.log('Conference details fetched:', filteredData);
          setCardsData(filteredData);
          setHasError(false)
          //console.log(cardsData)
        } catch (error) {
          console.error('Error fetching conference details:', error);
          setHasError(true);
        } finally {
          setLoading(false);
        }
      }
      const handleCardClick = (card) => {
        // Navigate to Meeting_Des and pass selected card's details in state
          navigate('/conferece_details', { state: { card} });
        };

        useEffect(()=>{
          //console.log(cardsData)
        },[cardsData])


        if (loading) {
          return  <Loading2/>                     
        }
      
        if (hasError || cardsData.length === 0) {
          // console.log(cardsData.length)
          // console.log(hasError)
          // console.log(cardsData)
            return <Empty_data/>
        }
      
  return (

    <div id="cards_container" className="flex flex-col w-full items-center px-5 overflow-y-auto h-[450px] sm:h-[496px] sm:ml-5" >
            <div className="flex gap-5 flex-col justify-center items-center w-full " style={{ marginTop: "10px" }}>
                {cardsData.map((card, index) => (
                    <div key={index} className="card rounded-[40px] p-5 cursor-pointer w-full lg:rounded-[20px] sm:flex sm:justify-between sm:flex-row-reverse" style={{ boxShadow: "0px 2px 6px 0px #00000040", marginBottom: "20px" }}
                    onClick={() => handleCardClick(card)}
                    >
                        {/* <div className='lg:hidden'>
                          <div className="flex justify-between items-center" >
                            <div className='bg-[#D1FFD0] w-1/2 px-2 py-2 text-center rounded-[20px] font-montserrat '>{`${card.Start_Time} - ${card.End_Time}`}</div>  
                            <BsChevronRight />
                          </div>
                          <div className="student_status">{card.Title}</div>
                          <div><a href={card.link} className="text-[#AFAFAF] font-montserrat font-normal w-[10px] whitespace-nowrap overflow-hidden overflow-ellipsis text-sm">{card.URL}</a></div>
                        </div> */}
                        <div className="flex justify-end items-center mt-auto w-40">
                            <div className='bg-[#D1FFD0] w-full px-2 py-2 text-center rounded-[20px] font-montserrat flex-grow '>
                              {`${card.Start_Time} - ${card.End_Time}`}
                            </div>
                            <BsChevronRight className='sm:hidden ' />
                          </div>
                        <div className=''>
                          <div className='sm:flex items-center gap-6'>
                          <div className='flex flex-col'>
                            <div className="flex items-center  gap-3" >
                              <div className="student_status">{card.Title}</div>
                              <BsChevronRight className='hidden sm:block ' />
                            </div>
                            <div><a href={card.link} className="text-[#AFAFAF] font-montserrat font-normal w-[10px] whitespace-nowrap overflow-hidden overflow-ellipsis text-sm">{card.URL}</a></div>
                          </div>
                          
                          </div>
                          
                        </div>
                        
                    </div>
                ))}
            </div>
        </div>
  )
}

export default Conference_list
