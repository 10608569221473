import React, { useState, useEffect } from 'react';
import { BsPersonCircle, BsFillCalendar2CheckFill, BsCalendarDateFill } from "react-icons/bs";
import { SiGoogleclassroom } from "react-icons/si";
import { IoMdTime } from "react-icons/io";
import { IoIosShareAlt } from "react-icons/io";
import { MdGroupAdd } from "react-icons/md";
import Cookies from 'js-cookie';
import axios from "axios";
import Loading2 from '../../Loading2';
import Empty_data2 from '../../Empty_data2';
import ConferenceStudentsModal from '../../../Modals/conferenceStudentsModal';
import moment from 'moment-timezone';

const UpcomingEventsConference = () => {

  const [cardsData, setCardsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const authToken = Cookies.get("authToken");
  const today = new Date();
  const user_type = Cookies.get('user_type')
  const loggedInUserId = Cookies.get('loggedInUserId');
  const [hasError, setHasError] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isActive, setIsActive] = useState(false);
  const [user_data, setUser_data] = useState('');
  const user_loggedInId = Cookies.get('loggedInUserId');
  const [user_time, setUser_time] = useState('');
  const [userDetails, setUserDetails] = useState([]);
  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  useEffect(() => {
    fetch_user_Details();
    FetchConferenceDetails();

  }, []);
  const FetchConferenceDetails = async () => {
    try {
      let data;
      if (user_type === 'Teacher') {
        const response = await axios.get(`${apiUrl}/api/conference/getConferenceDetails`, {
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
        });
        data = response.data;
      } else {
        const response = await axios.post(`${apiUrl}/api/conference/upcoming_booked_conferences`, { studentId: loggedInUserId }, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        });
        data = response.data;
      }
      // console.log("Conference Details before filter in Upcoming  ===> ", data)

      if (user_type !== 'Teacher') {
        const detailedData = await Promise.all(data.map(async (conference) => {
          const conference_id = conference.students[0].conferenceId;
          console.log("confernceId", conference_id)
          const detailResponse = await axios.post(`${apiUrl}/api/conference/getdetails`, { conference_id }, {
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          });
          return detailResponse.data;
        }));

        data = detailedData;
      }
      console.log("Conference Details after fetching details of each conference in Upcoming  ===> ", data)

      const now = new Date();
      let filteredData;

      if (user_type === 'Student') {


        filteredData = data.filter((conference) => {
          // console.log("Inside Filter  ==>", conference)
          if (conference.conferenceData !== null) {
            const conferenceDate = new Date(formatDateStringInTimeZone(conference.conferenceData.Conference_Date,user_time));
            // console.log("Start Time", conference.conferenceData.Start_Time)
            const [startHour, startMinute] = conference.conferenceData.Start_Time.split(':').map(Number);
            conferenceDate.setHours(startHour, startMinute, 0, 0);

            return conferenceDate > now;
          }
        });

        if (user_type === 'Teacher') {
          // console.log("Inside list of student")
        }

        // console.log("filtered Data ==>", filteredData)


        const updatedData = filteredData.map((conference) => {
          return {
            ...conference,
            Conference_Date: formatDateString(conference.conferenceData.Conference_Date)
          };
        });

        setCardsData(updatedData);
      }
      else {

        filteredData = data.filter((conference) => {
          // console.log("Inside Filter  ==>", conference)
          //const conference=conferences.conferenceData;
          const conferenceDate = new Date(formatDateStringInTimeZone(conference.Conference_Date,user_time));
          // console.log("Start Time", conference.Start_Time)
          const [startHour, startMinute] = conference.Start_Time.split(':').map(Number);
          conferenceDate.setHours(startHour, startMinute, 0, 0);
          console.log("Conference Date (Parsed) ==> ", conferenceDate, conferenceDate.toLocaleString());
          console.log("Current Time ==> ", now, now.toLocaleString());
          
          conferenceDate.setMinutes(conferenceDate.getMinutes() + 10)
          if(conferenceDate>now){
            console.log("True")
          }  // Add 10 minutes to the callDate
          // callDate.setMinutes(callDate.getMinutes() + 10);

          // return callDate > now;
          else{
            console.log("False")
          }
          return conferenceDate > now;
        });
      }


      // const updatedData = filteredData.map((conference) => {
      //   return {
      //     ...conference,
      //     Conference_Date: formatDateString(conference.Conference_Date)
      //   };
      // });

      let enrichedData;
      if (user_type === 'Teacher') {
        // Fetch earning details for each call
        enrichedData = await Promise.all(
          filteredData.map(async (conference) => {
            try {
              const earnings_details = await axios.post(
                `${apiUrl}/api/earningConference/fetch_earning_details`,
                { conference_Id: conference._id },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                  },
                }
              );
              // console.log("booked earnings",earnings_details.data)
              let earning_ids;
              if (earnings_details.data.length >= 1) {
                earning_ids = earnings_details.data.map((earning) => {
                  return earning._id
                })
              }
              const students_response = await axios.post(
                `${apiUrl}/api/conference/get_no_of_booked_Students`,
                { conference_Id: conference._id },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                  },
                }
              );

              if (earnings_details.data) {
                return {
                  ...conference,
                  earningDetails: earning_ids,
                  No_of_Students: students_response.data
                };
                //return { ...conference, earningDetails: earnings_details.data };
              } else {
                return { ...conference, earningDetails: null, No_of_Students: students_response.data };
              }
            } catch (error) {
              console.error('Error fetching earning details:', error);
              return { ...conference, earningDetails: null };
            }
          })
        );
      } else {
        enrichedData = filteredData;

        // enrichedData.sort((a, b) => {
        //   const dateA = new Date(a.Conference_Date);
        //   const [startHourA, startMinuteA] = a.Start_Time.split(':').map(Number);
        //   dateA.setHours(startHourA, startMinuteA, 0, 0);

        //   const dateB = new Date(b.Conference_Date);
        //   const [startHourB, startMinuteB] = b.Start_Time.split(':').map(Number);
        //   dateB.setHours(startHourB, startMinuteB, 0, 0);

        //   console.log(`Comparing: ${dateA} with ${dateB}`);

        //   return dateA - dateB;
        // });


      }

      console.log("Enriched Data ===> ", enrichedData);
      setCardsData(enrichedData);
      setIsActive(new Array(cardsData.length).fill(false));

    } catch (error) {
      console.error('Error fetching conference details:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetch_user_Details = async () => {
    try {
      const user_details = await axios.get(`${apiUrl}/api/auth/user_details/${user_loggedInId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      const user = user_details.data;
      // console.log(user);
      setUser_data(user);
      setUser_time(user.user_Details.time_zone);
      // console.log("Time Zone", user_time);
    } catch (error) {
      console.error('Server Error:', error.response.data);
    }
  };



  useEffect(() => {
    const updateButtonStateForAllCards = () => {
      cardsData.forEach((card, index) => {
        updateButtonState(card, index);
      });
    };

    if (cardsData.length > 0) {
      updateButtonStateForAllCards();
    }

    const interval = setInterval(() => {
      if (cardsData.length > 0) {
        updateButtonStateForAllCards();
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [cardsData]);



  useEffect(() => {
    const updateButtonStateForAllCards = () => {
      cardsData.forEach((card, index) => {
        updateButtonState(card, index);
      });
    };

    if (cardsData.length > 0) {
      updateButtonStateForAllCards();
    }

    const interval = setInterval(() => {
      //console.log("Haiii....")
      if (cardsData.length > 0) {
        updateButtonStateForAllCards();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [cardsData]);

  const updateButtonState = (card, index) => {
    let startHourMinute, conferenceDate;
    if (user_type === 'Student') {
      const starttime=formatStartTime(card.conferenceData.Start_Time)
      startHourMinute = starttime.split(':').map(Number);
      conferenceDate = new Date(formatDateStringInTimeZone2(card.conferenceData.Conference_Date,card.conferenceData.Start_Time,card.conferenceData.teacher_time_zone,user_time));
    } else {
      startHourMinute = card.Start_Time.split(':').map(Number);
      conferenceDate = new Date(formatDateStringInTimeZone(card.Conference_Date,user_time));
    }

    const now = new Date();
    const start = new Date(
      conferenceDate.getFullYear(),
      conferenceDate.getMonth(),
      conferenceDate.getDate(),
      parseInt(startHourMinute[0]),
      parseInt(startHourMinute[1]),
      0,
      0
    ).getTime();

    const fiveMinutes = 5 * 60 * 1000;
    const tenMinutes = 10 * 60 * 1000;
    const currentTime = now.getTime();

    // console.log("Time range ==> ", start - fiveMinutes, " to ", start + tenMinutes, 
    //   new Date(start - fiveMinutes).toLocaleString(), 
    //   " to ", 
    //   new Date(start + tenMinutes).toLocaleString());

    if (currentTime >= start - fiveMinutes && currentTime <= start + tenMinutes &&
      now.getDate() === conferenceDate.getDate() &&
      now.getMonth() === conferenceDate.getMonth() &&
      now.getFullYear() === conferenceDate.getFullYear()) {
      setIsActive(prevState => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
      // console.log(`Button is active: true for card index: ${index}`);
    } else {
      setIsActive(prevState => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
      // console.log(`Button is active: false for card index: ${index}`);
    }
  };

  // const handleMeeting = async (earning_id) => {
  //   const status = await axios.put(`${apiUrl}/api/earningConference/change_meeting_status`, { earning_id }, {
  //     headers: {
  //       Authorization: `Bearer ${authToken}`,
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //   console.log(status.data)
  // }

  const handleMeeting = async (earning_ids) => {
    try {
      const status = earning_ids.map(earning_id =>
        axios.put(`${apiUrl}/api/earningConference/change_meeting_status`, { earning_id }, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        })
      );
      const responses = await Promise.all(status);
      // console.log('Status updates:', responses);
    } catch (error) {
      console.error('Error updating meeting status:', error);
    }
  }





  // Function to format date as "DD-MM-YYYY"
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  // Function to format date as "Thursday, 24-04-2024"
  const formatDateString = (dateString) => {
    //   console.log(dateString)
    //   if (!dateString) {
    //   console.error('dateString is undefined or null:', dateString);
    //   return 'Invalid Date'; // or handle it appropriately
    // }
    const datePart = dateString.split('T')[0];
    const eventDate = new Date(datePart);


    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const options = {
      timeZone: userTimeZone,
      weekday: 'long',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };

    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(eventDate).replace(/\//g, '-');
    // console.log(formattedDate)

    return formattedDate;
  };

  const formatStartTime =(startTime)=>{
    const startParts = startTime.split(':').map(part => parseInt(part));
    const startDate = new Date();
    startDate.setHours(startParts[0], startParts[1], 0, 0);


    const startOptions = { timeZone: user_time, hour12: false, hour: '2-digit', minute: '2-digit' };
    const formattedStartTime = new Intl.DateTimeFormat('en-US', startOptions).format(startDate);
    return formattedStartTime
  }


  
  const formatTime = (startTime, endTime) => {
    //console.log("Start time before conversion ==>", startTime);
    const startParts = startTime.split(':').map(part => parseInt(part));
    const startDate = new Date();
    startDate.setHours(startParts[0], startParts[1], 0, 0);


    const startOptions = { timeZone: user_time, hour12: false, hour: '2-digit', minute: '2-digit' };
    const formattedStartTime = new Intl.DateTimeFormat('en-US', startOptions).format(startDate);

    //console.log("End time before conversion ==>", endTime);
    let formattedEndTime = null;
    if (endTime) {
      const endParts = endTime.split(':').map(part => parseInt(part));
      const endDate = new Date();
      endDate.setHours(endParts[0], endParts[1], 0, 0);


      const endOptions = { timeZone: user_time, hour12: false, hour: '2-digit', minute: '2-digit' };
      formattedEndTime = new Intl.DateTimeFormat('en-US', endOptions).format(endDate);
      //console.log(user_time);
      //console.log("Start and end time after conversion ==>", formattedStartTime, formattedEndTime);
    }

    return `${formattedStartTime} - ${formattedEndTime}`;
  };


  const handleshare = () => {
    if (navigator.share) {
      const baseUrl = window.location.origin;
      navigator.share({
        title: 'Check out this book list!',
        text: 'Here is an amazing book list you should see:',
        url: `${baseUrl}/book_list/${loggedInUserId}`,
      })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      alert('Sharing is not supported on this browser.');
    }
  };

  const ShowStudentDetails = (event) => {
    console.log("students", event.No_of_Students.students)
    event.No_of_Students.students.map((student, index) => {
      // console.log(`Student ${index + 1}:`);
      // console.log('Student Details ===>', student.studentDetails)
      // console.log('User Details ==>', student.userDetails)

      // Add more fields as needed
    });
    setModalData(event.No_of_Students.students);
    setIsModalOpen(true);
  }


  const formatDateStringInTimeZone = (dateString, timeZone) => {
    return moment.tz(dateString, timeZone).format('YYYY-MM-DD');
  };

  const formatDateStringInTimeZone2 = (dateString, startTime, teacherTimeZone, studentTimeZone) => {
    // Combine the date and time into a single string in the format "YYYY-MM-DDTHH:mm"
    const dateTimeString = `${dateString}T${startTime}`;
    
    // Convert the combined date and time string from the teacher's time zone to UTC
    const utcDateTime = moment.tz(dateTimeString, teacherTimeZone).utc();
    
    // Convert the UTC time to the student's time zone
    const convertedDateTime = utcDateTime.clone().tz(studentTimeZone);
    
    // Format the converted date (only date)
    const convertedDate = convertedDateTime.format('YYYY-MM-DD');

    console.log("Original Date:", dateString);
    console.log("Original Start Time:", startTime);
    console.log("Converted Date in Student's Time Zone:", convertedDate);

    // Return the converted date
    return convertedDate;
};

  if (loading) {
    return <Loading2 />
  }


  if (hasError || cardsData.length === 0) {
    // eslint-disable-next-line react/jsx-pascal-case
    return <Empty_data2 />
  }


  return (
    <div className="overflow-y-auto w-full lg:w-full flex flex-col items-center font-primary h-screen mb-14 ml-1 mr-2" style={{ maxHeight: 'calc(100vh - 140px)' }}>
      {cardsData.map((event, index) => (
        <div
          key={event.id}
          className="rounded-[40px] p-[20px] mb-6 mt-1 flex flex-col w-[95%] sm:w-[90%] lg:w-[80%] lg:mr-2 "
          style={{ boxShadow: '0px 2px 6px 0px #00000040' }}
        >
          <div className="pb-[20px]">
            <div className="flex items-center pb-[8px]">
              <SiGoogleclassroom className='text-orange-400 text-[1.2rem]' />
              <p className="pl-[5px] text-sm sm:text-base font-normal text-[#585858]">
                {user_type === 'Student' ? (
                  <div>Title: {event.conferenceData.Title}</div>
                ) : (
                  <div>Title: {event.Title}</div>
                )}
              </p>
            </div>
            {user_type === 'Student' ? (
              <div className="flex items-center mt-3">
                <BsPersonCircle className='text-orange-400 sm:text-[1.2rem] text-[1rem]' />
                <p className="pl-[5px] text-sm sm:text-base font-normal text-[#585858]">

                  <div>Teacher Name: {event.conferenceData.userId.fullName}</div>

                </p>
              </div>) : ('')
            }
            <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 sm:justify-between mt-3">
              <div className="flex items-center">
                <BsCalendarDateFill className='text-orange-400 text-[1.2rem]' />
                  <p className="pl-[5px] text-sm sm:text-base font-normal text-[#585858] pr-[8px]">
                    {user_type === 'Student' ? (
                      <div>
                        Date: {formatDateStringInTimeZone2(event.conferenceData.Conference_Date, event.conferenceData.Start_Time,event.conferenceData.teacher_time_zone, user_time)}
                      </div>
                    ) : (
                      <div>
                        Date: {formatDateStringInTimeZone(event.Conference_Date,event.Start_Time,  user_time)}
                      </div>
                    )}
                  </p>              </div>
              <div className="flex items-center">
                <IoMdTime className='text-orange-400 text-[1.2rem]' />
                <p className="pl-[5px] text-sm sm:text-base font-normal text-[#585858]">
                  {user_type === 'Student' ? (
                    <>
                      {event.conferenceData.teacher_time_zone !== user_time ? (
                        <div>
                          {`Time: ${formatTime(event.conferenceData.Start_Time, event.conferenceData.End_Time)}`}
                        </div>
                      ) : (
                        <div>
                          {`Time: ${event.conferenceData.Start_Time} - ${event.conferenceData.End_Time}`}
                        </div>
                      )}
                    </>
                  ) : (
                    <div>
                      {`Time: ${event.Start_Time} - ${event.End_Time}`}
                    </div>
                  )}
              </p>
              </div>
            </div>
          </div>
          <div className='justify-center items-center'>
            {user_type === 'Teacher' ? (
              <div className='flex flex-col lg:flex-row justify-center items-center  gap-2 '>
                {event.No_of_Students.studentCount !== 0 ? (
                  <>
                    <div className='flex justify-center items-center w-60 sm:w-64 px-0 py-1.5 sm:py-2.5 bg-gradient text-white text-center rounded-full cursor-pointer' title='Students List' onClick={() => ShowStudentDetails(event)}>
                      No. of Students Booked: {event.No_of_Students.studentCount}
                    </div>
                    <a
                      href={isActive[index] ? event.URL : "#"}
                      target={isActive[index] ? "_blank" : ""}
                      rel="noopener noreferrer"
                      className={`inline-flex items-center justify-center w-60 sm:w-64 px-0 py-1.5 sm:py-2.5 bg-gradient text-white text-center rounded-full cursor-pointer  ${!isActive[index] ? 'opacity-50 pointer-events-none' : ''}`}
                      onClick={isActive[index] ? () => handleMeeting(event.earningDetails) : undefined}
                    >
                      <i className="fas fa-video"></i>
                      Start
                    </a>
                    <div className='inline-flex items-center gap-2 justify-center w-60 sm:w-64 px-0 py-1.5 sm:py-2.5 border-2 border-orange-400 text-center cursor-pointer rounded-full bg-transparent text-center text-orange-500 hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 ' onClick={handleshare}>
                      <IoIosShareAlt style={{ fontSize: '1rem' }} /><p className='font-medium text-sm lg:text-md'>Share Conference</p>
                    </div>
                  </>
                ) : (
                  <div className="ml-[13%] lg:ml-52 text-gray-500">
                    No one student booked the conference
                  </div>
                )}
              </div>
            ) : (
              <div className='flex flex-col sm:flex-row  justify-center items-center gap-2'>
                <div className={`border-2 border-orange-600 rounded-3xl py-2 px-6 bg-transparent text-orange-500 hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-transparent  w-32 flex justify-center shadow-lg gap-2 items-center cursor-pointer ${!isActive[index] ? 'opacity-50 pointer-events-none' : ''} `}><MdGroupAdd /><span className='font-medium text-sm lg:text-md'>
                  <a 
                  href={isActive[index] ? event.conferenceData.URL : "#"}
                  target={isActive[index] ? "_blank" : ""}
                   
                    rel="noopener noreferrer">
                    Join
                  </a>
                </span></div>
                <div className='border-2 border-orange-600 rounded-3xl py-2 px-6  bg-transparent text-orange-500 hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-transparent  flex gap-1 cursor-pointer shadow-lg'>
                  <IoIosShareAlt style={{ fontSize: '1rem' }} /><span className='font-medium text-sm lg:text-md w-36' onClick={handleshare}>Share Conference</span>
                </div>
              </div>
            )}
          </div>

        </div>
      ))}
      <ConferenceStudentsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        students={modalData} />
    </div>
  );
}

export default UpcomingEventsConference;
