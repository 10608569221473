import React, { useEffect } from 'react'
import NavHome from '../../components/nav/navHome';
import Footer from '../../components/nav/footer';
import Header from '../../components/headerBackground'
import Cookies from "js-cookie";
import NavBar from '../../components/nav/navBar';
import services from '../../assets/img/services.png';
import { useLocation } from 'react-router-dom';
  // const url = process.env.REACT_APP_API_URL;
  const ScrollToTop = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on every navigation
    return null;
  };
const ShippingPolicy = () => {
  const isAuthenticated = Cookies.get("authToken") !== undefined;
  
  return (
    <div className=''>
      {isAuthenticated ? <NavBar /> : <NavHome />}
      <ScrollToTop/>
      <section className="mb-0 sm:mb-0 font-primary ">
        <Header />
        <div className='sm:mt-20 lg:mt-28 mt-[160px] mx-2 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 flex flex-col justify-center gap-4'>
          <h2 className='font-semibold sm:text-2xl lg:text-3xl text-xl text-center text-[#585858]'>SHIPPING POLICY</h2>
          <div className='sm:grid grid-cols-2 items-center justify-center'>
            <img src={services} alt="refund" className='sm:w-[400px] sm:h-[400px] lg:w-[450px] lg:h-[450px] h-[250px] w-[250px]' />
            <div className='text-base sm:text-base lg:text-lg flex flex-col gap-6 text-left mt-2 mb-6'>

              <p>There are two main services provided to the students by the teachers.</p>

              <p>The first one is calls - which the students can book by looking at the teachers calendar. The join option connects the students and teachers over 3rd party meeting applications like - Google Meet, Zoom, etc as set by the teachers.</p>

              <p>The second service is a conference or online classes pre-scheduled by the teachers. A student can also join a conference similar to the calls. </p>
              <p>In both the cases Students may have to pay fees to the teachers to avail the service. If the teachers do not join then the students are refunded as per refund policy.</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default ShippingPolicy