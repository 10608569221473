import { useState, useEffect } from "react";
import axios from "axios";

import SelectedLanguages from "../../components/selectedLanguages";
import RelocateAreas from "../../components/relocateAreas";

import saveIcon from "../../assets/img/tick.png";
import arrowIcon from "../../assets/img/Vectoryy.png";
import { FaCheckCircle, FaRegArrowAltCircleLeft } from "react-icons/fa";

const apiUrl = process.env.REACT_APP_API_URL;

const Education = ({ formData, setFormData, handleChange, submitForm, setCurrentPage }) => {
  const [categories, set_categories] = useState([]);
  const [subCategories, set_subCategories] = useState([]);
  const [interest_subCategories, set_interest_subCategories] = useState([]);
  // console.log("Form data in Education", formData)
  async function get_subCategories(category) {
    try {
      let response = await axios.post(
        `${apiUrl}/api/subCategories/getParticularSubCategory`,
        { category_name: category }
      );

      let data = response.data;
      let subcategories = data.map(sc => sc.name);
      subcategories.sort((a, b) => a.localeCompare(b));
      set_subCategories(subcategories);
      return subcategories;
    } catch (err) {
      console.log(err);
    }
  }

  async function get_interest_subCategories(category) {
    try {
      let response = await axios.post(
        `${apiUrl}/api/subCategories/getParticularSubCategory`,
        { category_name: category }
      );
      let data = response.data;
      let interest_subcategories = data.map(sc => sc.name);
      interest_subCategories.sort((a, b) => a.localeCompare(b));
      set_interest_subCategories(interest_subcategories);
      return interest_subcategories;
    } catch (err) {
      console.log(err);
    }
  }

  function handleSubCategory(e) {
    let updatedData = { ...formData };
    let field_name = e.target.getAttribute("field_name");

    if (e.target.checked) {
      updatedData[field_name].push(e.target.value);
    } else {
      const index = updatedData[field_name].indexOf(e.target.value);
      if (index > -1) {
        updatedData[field_name].splice(index, 1);
      }
    }
    setFormData({ ...updatedData });
  }

  let subCategory =
    formData.category === "" || formData.category === undefined
      ? ""
      : subCategories.map((subcategory) => {
        return (
          <label class="inline-flex items-center mr-2">
            <input
              type="checkbox"
              value={subcategory}
              name={subcategory}
              field_name="subCategory"
              class="form-checkbox h-4 w-4 text-indigo-600"
              checked={formData.subCategory.includes(subcategory)}
              onChange={handleSubCategory}
            />
            <span class="ml-1 text-gray-700">{subcategory}</span>
          </label>
        );
      });

  let interest_subCategory =
    formData.interest_category === "" ||
      formData.interest_category === undefined
      ? ""
      : interest_subCategories.map((subcategory) => {
        return (
          <label class="inline-flex items-center">
            <input
              type="checkbox"
              value={subcategory}
              name={subcategory}
              field_name="interest_subCategory"
              class="form-checkbox h-4 w-4 text-indigo-600 "
              checked={formData.interest_subCategory.includes(subcategory)}
              onChange={handleSubCategory}
            />
            <span class="ml-1 mr-2 text-gray-700">{subcategory}</span>
          </label>
        );
      });

  useEffect(() => {
    get_categories();
    if (formData.category !=="") {
      get_subCategories(formData.category);
    }

    if (formData.interest_category !== "") {
      get_interest_subCategories(formData.interest_category);
    }
  }, []);

  async function get_categories() {
    let response = await fetch(`${apiUrl}/api/categories/getAllCategory`);
    let data = await response.json();
    data = data.map(c => ({
      ...c,
      order: Number(c.order)
  }));

  data.sort((a, b) => a.order - b.order);

  let categories = data.map(c => c.name);
  //console.log("categories ==>", categories);
  
  set_categories(categories);
  }

  const category = categories.map((category) => {
    return (
      <option value={category}>{category}</option>
    );
  });

  return (
    <div className="education" id="education">
      <form className="mt-2 sm:mt-[10px] grid grid-cols-1 gap-x-6 gap-y-2 sm:gap-y-4 sm:grid-cols-6 sm:px-4 px-2"
        onSubmit={submitForm}>
        <div className="col-span-6 text-sm sm:text-base lg:text-lg">
          <label
            htmlFor="category"
            className="block font-semibold leading-6 text-gradient"
          >
            Currently Pursuing
          </label>

          <div className="flex flex-col sm:flex-row sm:w-full justify-between">
            <div className="w-full mt-2 relative mr-4">
              <select
                type="text"
                name="category"
                className="w-full border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-4 py-3 "
                value={formData.category}
                onChange={async (e) => {
                  let category = e.target.value;
                  let subcategories = await get_subCategories(category);
                  setFormData({
                    ...formData,
                    category: category,
                    subCategory: [],
                  });
                }}
              >
                <option value="" disabled>
                  Select a course
                </option>
                {category}
              </select>
              {/* <img
                src={arrowIcon}
                alt=""
                className="absolute top-[18px] right-[18px]"
              /> */}
            </div>
          </div>
        </div>

        <div className="col-span-6 text-sm sm:text-base lg:text-lg">
          <div className="font-semibold leading-6 text-gradient">
            Select subcategories you are pursuing
          </div>
          <div className="flex gap-2 flex-wrap items-center gap-1">
            {subCategory}
          </div>
        </div>

        <div className="col-span-6 text-sm sm:text-base lg:text-lg">
          <label
            htmlFor="interest_category"
            className="block font-semibold leading-6 text-gradient"
          >
            Courses Interested
          </label>

          <div className="flex flex-col sm:flex-row justify-between">
            <div className="mt-2 w-full mr-4">
              <select
                name="interest_category"
                autoComplete="category-name"
                className=" w-full border border-[#585858] text-[#585858] text-sm sm:text-base lg:text-lg rounded-full px-4 py-3"
                value={formData.interest_category}
                onChange={async (e) => {
                  let interest_category = e.target.value;
                  let interest_subcategories = await get_interest_subCategories(
                    interest_category
                  );
                  setFormData({
                    ...formData,
                    interest_category: interest_category,
                    interest_subCategory: [],
                  });
                }}
              >
                <option value="" disabled>
                  Select a course
                </option>
                {category}
              </select>
              {/* <img
                src={arrowIcon}
                alt=""
                className="absolute top-[18px] right-[18px]"
              /> */}
            </div>
          </div>
        </div>

        <div className="col-span-6 text-sm sm:text-base lg:text-lg">
          <div className="font-semibold leading-6 text-gradient mb-2">
            Select subcategories you are interested in
          </div>
          <div className="flex flex-wrap items-center gap-1">
            {interest_subCategory}
          </div>
        </div>

        <div className="col-span-6 text-sm sm:text-base lg:text-lg sm:flex items-center mt-4">
          <label
            htmlFor="radio-btn"
            className="block font-semibold leading-6 text-gradient "
          >
            Willing to relocate:
          </label>
          <div className="flex mt-2 sm:mt-0">
            <div className="flex items-center pl-4 text-[#585858]">
              <span className="mr-2">Yes</span>
              <input
                name="willing_to_relocate"
                type="radio"
                value="yes"
                className="w-5 h-5 border-gradient"
                checked={formData.willing_to_relocate === true}
                onChange={() => {
                  setFormData({ ...formData, willing_to_relocate: true });
                }}
              />
            </div>

            <div className="flex items-center pl-7 text-[#585858]">
              <div className="mr-2">No</div>
              <input
                name="willing_to_relocate"
                type="radio"
                value="no"
                className="w-5 h-5 border-gradient"
                checked={formData.willing_to_relocate === false}
                onChange={() => {
                  setFormData({
                    ...formData,
                    willing_to_relocate: false,
                    preferred_locations: [],
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div
          className="col-span-6 w-[100%] text-sm sm:text-base lg:text-lg"
          style={{
            display: formData.willing_to_relocate ? "block" : "none",
          }}
        >
          <RelocateAreas formData={formData} setFormData={setFormData} />
        </div>

        <div className="col-span-6 text-sm sm:text-base lg:text-lg">
          <SelectedLanguages formData={formData} setFormData={setFormData} />
        </div>

        <div className="flex col-span-6 w-full items-center justify-between mb-10 sm:mt-3">
          <div className="w-4/12 flex items-center">
            <button
              type="button"
              className="w-[100px] flex drop-shadow-md py-1.5 px-3 rounded-full items-center justify-center gap-2 bg-white border-2 border-orange-400"
              onClick={() => setCurrentPage("Personal details")}
            >
              <FaRegArrowAltCircleLeft className="sm:w-[20px] sm:h-[20px] text-orange-400" />
              <span className="text-[12px] sm:text-[14px] lg:text-[17px] text-gradient font-bold">
                Back
              </span>
            </button>
          </div>
          <button
            type="submit"
            className="flex shadow-md text-white py-2 px-3 sm:py-3 rounded-full items-center justify-center bg-gradient sm:w-6/12 max-w-[200px] sm:max-w-[268px]"
          >
            <span className="mr-2">
              <FaCheckCircle />
            </span>
            <span className="text-xs sm:text-[14px] font-medium">
              Save Changes
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Education;
