import React, { useEffect, useState } from 'react';
import { FaComments, FaCalendarCheck } from "react-icons/fa";
import { FaCalendarDays } from "react-icons/fa6";
import subscription from '../../assets/img/subscription.svg';
import { IoPersonCircle, IoNotifications } from "react-icons/io5";
import { HiMiniUserGroup } from "react-icons/hi2";
import { RiLogoutBoxRLine, RiSearch2Fill } from "react-icons/ri";
import { TbCalendarTime, TbDatabaseDollar, TbUserStar } from "react-icons/tb";
import Cookies from 'js-cookie';
import logo from "../../assets/img/dlogo.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useNotifications } from '../../context/NotificationContext'; 
import Notifications from '../../pages/notification';

const NavTeacher = () => {
    const navigate = useNavigate();
    const authToken = Cookies.get('authToken');
    const url = process.env.REACT_APP_API_URL;
    const { unreadCount,unreadChat } = useNotifications(); 
    const loggedInUserId = Cookies.get('loggedInUserId');
   

    const [isActiveCalendar, setIsActiveCalendar] = useState(false);
    const [isActiveEvents, setIsActiveEvents] = useState(false);
    // const [isActiveSubscription, setisActiveSubscription] = useState(false);
    const [isActiveComments, setIsActiveComments] = useState(false);
    const [isActiveProfile, setIsActiveProfile] = useState(false);
    const [isActiveForum, setIsActiveForum] = useState(false);
    const [isActiveSearch, setActiveSearch] = useState(false);
    const [isActiveEarnings, setActiveEarnings] = useState(false);
    const [isActiveNotification, setActiveNotification] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);  // State to manage notification dropdown visibility
   

    const handleCalendarClick = () => {
        navigate('/calender_page');
        setIsActiveCalendar(true);
        setIsActiveComments(false);
        setIsActiveProfile(false);
        setIsActiveForum(false);
        setActiveSearch(false);
        setActiveNotification(false);
    };
    const handleEventsClick = () => {
        navigate('/user_events');
        setIsActiveEvents(true);
        setActiveEarnings(false);
        setIsActiveCalendar(false);
        setIsActiveComments(false);
        setIsActiveProfile(false);
        setIsActiveForum(false);
        setActiveSearch(false);
        setActiveNotification(false);
    };
    // const handleSubscriptionClick = () => {
    //     navigate('/subscription-details');
    //     // setisActiveSubscription(true);
    //     setActiveEarnings(false);
    //     setIsActiveCalendar(false);
    //     setIsActiveComments(false);
    //     setActiveSearch(false);
    //     setIsActiveProfile(false);
    //     setIsActiveForum(false);
    //     setActiveNotification(false);
    // }
    const handleEarningsClick = () => {
        navigate('/earnings');
        setActiveEarnings(true);
        setIsActiveCalendar(false);
        setIsActiveComments(false);
        setActiveSearch(false);
        setIsActiveProfile(false);
        setIsActiveForum(false);
        setActiveNotification(false);
    }
    const handleCommentsClick = () => {
        navigate('/chat');
        setIsActiveComments(true);
        setIsActiveCalendar(false);
        setIsActiveProfile(false);
        setIsActiveForum(false);
        setActiveSearch(false);
        setActiveNotification(false);
    };

    const handlePersonCircleClick = () => {
        navigate('/edit-profile');
        setIsActiveProfile(true);
        setIsActiveCalendar(false);
        setIsActiveComments(false);
        setIsActiveForum(false);
        setActiveSearch(false);
        setActiveNotification(false);
    };

    const handleUserGroupClick = () => {
        navigate('/forum');
        setIsActiveForum(true);
        setIsActiveCalendar(false);
        setIsActiveComments(false);
        setIsActiveProfile(false);
        setActiveSearch(false);
        setActiveNotification(false);
    };

    const handleSearchClick = () => {
        navigate('/cabinet');
        setActiveSearch(true);
        setIsActiveCalendar(false);
        setIsActiveComments(false);
        setIsActiveProfile(false);
        setIsActiveForum(false);
        setActiveNotification(false);
    }

    const handleNotificationClick = () => {
        setShowNotifications(!showNotifications);  // Toggle notification dropdown
        setActiveNotification(!isActiveNotification);
        setActiveSearch(false);
        setIsActiveCalendar(false);
        setIsActiveComments(false);
        setIsActiveProfile(false);
        setIsActiveForum(false);
    }

    const handleLogout = async () => {
        try {
            const response = await axios.post(
                `${url}/api/auth/logout`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            Cookies.remove("loggedInUserId");
            Cookies.remove("authToken");
            Cookies.remove("account_type");
            Cookies.remove("user_type");
            navigate("/login");
        } catch (error) {
            console.error("Error logging out:", error);
        }
    };

    return (
        <nav className="hidden sm:flex items-center justify-between lg:h-[72px] md:h-[64px] sm:h-[64px] w-full bg-gradient lg:px-[72px] md:px-6 sm:px-4">
            <div className="w-full max-w-[1300px] mx-auto sm:flex md:gap-10 items-center sm:justify-between lg:justify-between md:justify-between">
                <div className="hidden lg:block md:block sm:block">
                    <img className="lg:w-[288px] sm:w-[200px] md:w-[240px]" src={logo} alt="logo" />
                </div>
                <div className="flex items-center justify-center lg:gap-8 xl:gap-10 sm:gap-4 md:gap-6">

                    <RiSearch2Fill title="Search"
                        className={`${isActiveSearch ? 'text-[#f3c699]' : 'text-white'} cursor-pointer bg-transparent hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-1 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7`}
                        size={28} onClick={handleSearchClick} style={{ fontSize: '16px' }} />

                    <FaCalendarDays title="Calendar"
                        className={`${isActiveCalendar ? 'text-[#f3c699]' : 'text-white'} cursor-pointer bg-transparent hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-1 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7`}
                        size={26} onClick={handleCalendarClick} />

                    <TbDatabaseDollar title="Earnings"
                        className={`${isActiveEarnings ? 'text-[#f3c699]' : 'text-white'} cursor-pointer bg-transparent hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-1 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7`}
                        size={26} onClick={handleEarningsClick} />

                    {/* <TbUserStar title="Subscription" className={`${isActiveSubscription ? 'text-[#f3c699]' : 'text-white'} cursor-pointer bg-transparent hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-1 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7`}
                        size={28} onClick={handleSubscriptionClick} /> */}

                    <TbCalendarTime title="Events" className={`${isActiveEvents ? 'text-[#f3c699]' : 'text-white'} cursor-pointer bg-transparent hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-1.5 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7`}
                        size={28} onClick={handleEventsClick} />

                    <div className='relative'>
                        <FaComments title="Chats"
                            className={`${isActiveComments ? 'text-[#f3c699]' : 'text-white'} focus:text-white cursor-pointer bg-transparent hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-2 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7`}
                            size={28} onClick={handleCommentsClick} />
                        {unreadChat > 0 && (
                            <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full w-4 h-4 lg:w-5 lg:h-5 text-white text-xs flex items-center justify-center">
                                {unreadChat}
                            </div>
                        )}
                    </div>

                    <div className='relative'>
                        <HiMiniUserGroup title="Forum"
                            className={`${isActiveForum ? 'text-[#f3c699]' : 'text-white'} cursor-pointer bg-transparent hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-1 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7`}
                            size={26} onClick={handleUserGroupClick} />
                        
                    </div>

                    <IoPersonCircle title="Profile"
                        className={`${isActiveProfile ? 'text-[#f3c699]' : 'text-white'} cursor-pointer bg-transparent hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-1 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7`}
                        size={26} onClick={handlePersonCircleClick} />

                    <div className='relative'>
                        <IoNotifications
                            title="Notifications"
                            className={`${isActiveNotification ? 'text-[#f3c699]' : 'text-white'} cursor-pointer bg-transparent hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-1 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7`}
                            size={26}
                            onClick={handleNotificationClick}
                        />
                        {(unreadChat > 0 || unreadCount > 0) && (
                            <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full w-4 h-4 lg:w-5 lg:h-5 text-white text-xs flex items-center justify-center">
                                {unreadCount}
                            </div>
                        )}
                    </div>

                    <RiLogoutBoxRLine title="Logout"
                        className="text-white cursor-pointer bg-transparent hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-1 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7"
                        size={26} onClick={handleLogout} />
                </div>
            </div>
            {showNotifications && <Notifications setShowNotifications={setShowNotifications} />}
        </nav>
    );
};

export default NavTeacher;
