import React, { useState, useEffect } from 'react';
import { FaComments } from 'react-icons/fa';
import { FaCalendarDays } from "react-icons/fa6";
import { IoPersonCircle, IoNotifications } from 'react-icons/io5';
import { HiMiniUserGroup } from 'react-icons/hi2';
import { RiLogoutBoxRLine, RiSearch2Fill } from "react-icons/ri";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate, useLocation, useRouteLoaderData } from 'react-router-dom';
import { TbCalendarTime, TbDatabaseDollar, TbUserStar } from "react-icons/tb";
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNotifications } from '../../context/NotificationContext';
import Notifications from '../../pages/notification';


const earning = require("../../assets/img/earningicon.png");
const subscription = require("../../assets/img/subscription.png");

const MenuBottomTeacher = ({ setMessage, setShowMessage }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const authToken = Cookies.get('authToken');
    const url = process.env.REACT_APP_API_URL;
    const { unreadCount, unreadChat } = useNotifications();
    const loggedInUserId = Cookies.get('loggedInUserId');

    // State variables for tracking active status of each menu item
    const [isActiveCalendar, setIsActiveCalendar] = useState(false);
    const [isActiveComments, setIsActiveComments] = useState(false);
    const [isActiveProfile, setIsActiveProfile] = useState(false);
    const [isActiveEvents, setActiveEvents] = useState(false);
    const [isActiveForum, setIsActiveForum] = useState(false);
    const [isActiveDots, setActiveDot] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isActiveSearch, setActiveSearch] = useState(false);
    const [isHoveredProfile, setIsHoveredProfile] = useState(false);
    const [isHoveredEvents, setIsHoveredEvents] = useState(false);
    const [isHoveredForum, setIsHoveredForum] = useState(false);
    const [isHoverLogout, setHoverLogout] = useState(false)
    const [showNotifications, setShowNotifications] = useState(false);  // State to manage notification dropdown visibility
    // const [unreadChatCount, setUnreadChatCount] = useState(0);
    // const [unreadForumCount, setUnreadForumCount] = useState(0);


    // useEffect(() => {
    //     const fetchUnreadCounts = async () => {
    //         try {
    //             const response = await axios.get(`${url}/api/notification/${loggedInUserId}/notifications`);
    //             const chatUnreadCount = response.data.filter(notif => notif.type === 'chat' && !notif.read).length;
    //             const forumUnreadCount = response.data.filter(notif => notif.type === 'forum' && !notif.read).length;
    //             const sortedNotifications = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    //             setUnreadChatCount(chatUnreadCount);
    //             setUnreadForumCount(forumUnreadCount);
    //         } catch (error) {
    //             console.error('Error fetching unread counts:', error);
    //         }
    //     };

    //     fetchUnreadCounts();

    //     if (socket) {
    //         socket.on('newChatMessage', () => {
    //             setUnreadChatCount(prevCount => prevCount + 1);
    //         });

    //         socket.on('newForumNotification', () => {
    //             setUnreadForumCount(prevCount => prevCount + 1);
    //         });
    //         socket.on('newConferenceBooking', () => {
    //             setUnreadForumCount(prevCount => prevCount + 1);
    //         });

    //         socket.on('newCallBooking', () => {
    //             setUnreadForumCount(prevCount => prevCount + 1);
    //         });

    //         socket.on('conferenceReminder',()=>{
    //             setUnreadForumCount(prevCount => prevCount + 1);
    //           });
    //           socket.on('callReminder',()=>{
    //             setUnreadForumCount(prevCount => prevCount + 1);
    //           });

    //         return () => {
    //             if (socket) {
    //                 socket.off('newChatMessage');
    //                 socket.off('newForumNotification');
    //                 socket.off('newConferenceBooking');
    //                 socket.off('newCallBooking');
    //                 socket.off('conferenceReminder');
    //                 socket.off('callReminder')
    //             }
    //         };
    //     }
    // }, [loggedInUserId, socket]);

    const handleMouseEnterProfile = () => {
        setIsHoveredProfile(true);
    };

    const handleMouseLeaveProfile = () => {
        setIsHoveredProfile(false);
    };
    const handleMouseEnterEvents = () => {
        setIsHoveredEvents(true);
    };

    const handleMouseLeaveEvents = () => {
        setIsHoveredEvents(false);
    };
    const handleMouseEnterForum = () => {
        setIsHoveredForum(true);
    };

    const handleMouseLeaveForum = () => {
        setIsHoveredForum(false);
    };
    const handleMouseEnterLogout = () => {
        setHoverLogout(true);
    };

    const handleMouseLeaveLogout = () => {
        setHoverLogout(false);
    };
    const handleCalendarClick = () => {
        if (isLoggedIn()) {
            navigate('/calender_page');
            setIsActiveCalendar(true);
            setIsActiveComments(false);
            //setIsActiveProfile(false);
            //setIsActiveForum(false);
        }
    };

    const handleCommentsClick = () => {
        if (isLoggedIn()) {
            navigate('/chat');
            setIsActiveCalendar(false);
            setIsActiveComments(true);
            //setIsActiveProfile(false);
            //setIsActiveForum(false);
        }
    };

    const handlePersonCircleClick = () => {
        if (isLoggedIn()) {
            navigate('/edit-profile');
            setIsActiveCalendar(false);
            setIsActiveComments(false);
            //setIsActiveProfile(true);
            //setIsActiveForum(false);
        }
    };

    const handleUserGroupClick = () => {
        if (isLoggedIn()) {
            navigate('/forum');
            setIsActiveCalendar(false);
            setIsActiveComments(false);
            //setIsActiveProfile(false);
            //setIsActiveForum(true);
        }
    };

    const handleEventsClick = () => {
        if (isLoggedIn()) {
            navigate('/user_events');
            setIsActiveCalendar(false);
            setIsActiveComments(false);
            //setIsActiveProfile(false);
            //setIsActiveForum(true);
        }
    };

    const handleSearchClick = () => {
        if (isLoggedIn()) {
            navigate('/cabinet');
            setIsActiveCalendar(false);
            setIsActiveComments(false);
            setActiveEvents(false)
            //setIsActiveProfile(false);
            //setIsActiveForum(false);
            setActiveDot(false)
            setActiveSearch(true)
        }
    }
    const handleEarningsClick = () => {
        if (isLoggedIn()) {
            navigate('/earnings');
            setIsActiveCalendar(false);
            setIsActiveComments(false);
            setActiveSearch(false);
            setActiveDot(true);
            setIsOpen(!isOpen);

        }
    }
    // const handleSubscriptionClick = () => {
    //     if (isLoggedIn()) {
    //         navigate('/subscription-details');
    //         setIsActiveCalendar(false);
    //         setIsActiveComments(false);
    //         setActiveSearch(false);
    //         setActiveDot(true);
    //         setIsOpen(!isOpen);

    //     }
    // }
    const handleDotClick = () => {
        if (isLoggedIn()) {

            setIsActiveCalendar(false);
            setIsActiveComments(false);
            setActiveSearch(false);
            setActiveDot(true);
            setIsOpen(!isOpen);

        }
    }

    const handleNotificationClick = () => {
        setShowNotifications(!showNotifications);  // Toggle notification dropdown


    }
    const isLoggedIn = () => {
        return Cookies.get('authToken') !== null;
    }
    const handleLogout = async () => {
        isLoggedIn()
        try {
            // Make a request to the backend logout endpoint with the token in the headers
            const response = await axios.post(
                `${url}/api/auth/logout`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
                    },
                }
            );
            // // Handle successful logout response
            // setShowMessage(true);
            // setMessage("Logged out successfully");
            Cookies.remove("loggedInUserId");
            Cookies.remove("authToken");
            Cookies.remove("user_type");
            navigate("/login"); // Redirect to the login page or any other appropriate page
        } catch (error) {
            // Handle logout error
            console.error("Error logging out:", error);
        }
    };
    return (
        <>
            <div
                className="sm:hidden flex flex-row justify-between shadow-md items-center px-[20px] rounded-[100px] w-10/12 h-[58px] mb-3"
                style={{
                    background: 'linear-gradient(180deg, #ff6535 0%, #ff9e48 48.9%, #ffce58 100%)',
                    position: 'fixed',
                    bottom: 0,
                }}
            >
                <RiSearch2Fill
                    className={`text-3xl ${isActiveSearch ? 'text-white' : 'text-[#F3C699]'} cursor-pointer hover:text-white`}
                    onClick={handleSearchClick}
                />
                <FaCalendarDays
                    className={`text-3xl ${isActiveCalendar ? 'text-white' : 'text-[#F3C699]'} cursor-pointer hover:text-white`}
                    onClick={handleCalendarClick}
                />
                <FaComments
                    className={`text-4xl ${isActiveComments ? 'text-white' : 'text-[#F3C699]'} cursor-pointer hover:text-white`}
                    onClick={handleCommentsClick}
                />
                {(unreadChat > 0) && (
                    <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full w-4 h-4 lg:w-5 lg:h-5 text-white text-xs flex items-center justify-center">
                        {unreadChat}
                    </div>
                )}

                <TbCalendarTime
                    className={`text-4xl ${isActiveEvents ? 'text-white' : 'text-[#F3C699]'} cursor-pointer hover:text-white`}
                    onClick={handleEventsClick}
                />

                <BsThreeDotsVertical
                    className={`text-4xl ${isActiveDots ? 'text-white' : 'text-[#F3C699]'} cursor-pointer hover:text-white`}
                    onClick={handleDotClick}
                />

                {isOpen ? (
                    <div className="absolute bottom-14 right-0 w-34 bg-transparent p-4 rounded-lg mt-2 mb-4 flex flex-col gap-2 bg-white">
                        <div className='border-2 border-orange-500 rounded-full  items-center py-1 w-48 h-10 flex justify-center gap-1 cursor-pointer' onClick={handlePersonCircleClick}
                            onMouseEnter={handleMouseEnterProfile}
                            onMouseLeave={handleMouseLeaveProfile}>
                            <IoPersonCircle style={{ color: isHoveredProfile ? 'white' : 'orange', fontSize: '1.5rem' }} className='text-orange-400' />
                            <span className=' text-[14px] text-gradient font-bold hover:text-white'>Profile</span>
                        </div>

                        <div className='relative border-2 border-orange-500 rounded-full  items-center py-1 w-48 h-10 flex justify-center gap-1 cursor-pointer' onClick={handleUserGroupClick}
                            onMouseEnter={handleMouseEnterForum}
                            onMouseLeave={handleMouseLeaveForum}>
                            {/* <div>
                                {unreadChat > 0 && (
                                    <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full w-4 h-4 lg:w-5 lg:h-5 text-white text-xs flex items-center justify-center">
                                        {unreadChat}
                                    </div>
                                )}
                            </div> */}
                            <HiMiniUserGroup style={{ color: isHoveredForum ? 'white' : 'orange', fontSize: '1.5rem' }} />
                            <span className=' text-[14px] text-gradient font-bold'>Forum</span>
                        </div>

                        <div className='border-2 border-orange-500 rounded-full  items-center py-1 w-48 h-10 bg-white flex justify-center gap-1 cursor-pointer' onClick={handleEarningsClick}>
                            <TbDatabaseDollar style={{ color: isHoveredForum ? 'white' : 'orange', fontSize: '1.5rem' }} />
                            <span className=' text-[14px] text-gradient font-bold'>Earnings</span>
                        </div>

                        {/* <div className='border-2 border-orange-500 rounded-full  items-center py-1 w-48 h-10 flex justify-center gap-1 cursor-pointer' onClick={handleSubscriptionClick}>
                            <TbUserStar style={{ color: isHoveredForum ? 'white' : 'orange', fontSize: '1.5rem' }} />
                            <span className=' text-[14px] text-gradient font-bold'>Subscriptions</span>
                        </div> */}

                        <div className='relative border-2 border-orange-500 rounded-full  items-center py-1 w-48 h-10 flex justify-center gap-1 cursor-pointer' onClick={handleNotificationClick}
                            onMouseEnter={handleMouseEnterEvents}
                            onMouseLeave={handleMouseLeaveEvents}>
                                <div>
                                {(unreadChat > 0 || unreadCount > 0) && (
                                    <div className=" bg-red-500 rounded-full p-2 w-6 h-6 lg:w-5 lg:h-5 text-white text-xs flex items-center justify-center">
                                        {unreadCount}
                                    </div>
                                )}
                            </div>
                            <IoNotifications style={{ color: isHoveredEvents ? 'orange' : 'orange', fontSize: '1.5rem' }} />
                            <span className=' text-[14px] text-gradient font-bold'>Notification</span>
                            

                        </div>

                        <div className='border-2 border-orange-500 rounded-full  items-center py-1 w-48 h-10 flex justify-center gap-1 cursor-pointer hover:text-white' onClick={handleLogout}
                            onMouseEnter={handleMouseEnterLogout}
                            onMouseLeave={handleMouseLeaveLogout}>
                            <RiLogoutBoxRLine style={{ color: isHoverLogout ? 'white' : 'orange', fontSize: '1.5rem' }} />
                            <span className=' text-[14px] text-gradient font-bold'>Logout</span>
                        </div>
                    </div>
                ) : (null)
                }


            </div>
            {showNotifications && <Notifications setShowNotifications={setShowNotifications} />}</>
    );
};

export default MenuBottomTeacher;
