import React from 'react';
import no_data from '../assets/img/data_not_found2.jpg'

const Empty_data2 = () => {
  return (
    <div className="empty-state">
    <div className="empty-state__content">
      <div className="empty-state__icon flex items-center justify-center">
        <img src={no_data} alt="No data"/>
      </div>
      <h1 className="empty-state__title">No Data Available Today</h1>
      <p className="empty-state__message">We couldn't find any data to display.</p>
     
    </div>
  </div>
  )
}

export default Empty_data2