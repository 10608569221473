import React from 'react'
import Cookies from "js-cookie";
import NavStudent from './navStudent';
import NavTeacher from './navTeacher';

const NavBar = () => {
    const user_type = Cookies.get('user_type')
    //console.log(user_type)
    return (
        <div className='fixed top-0 z-50 w-full flex items-center justify-center mx-auto'>
            {user_type === 'Student' ? (
                <NavStudent />
            ) : user_type === 'Teacher' ? (
                <NavTeacher />
            ) : null}
        </div>
    )
}

export default NavBar