import React, { useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import axios from 'axios';
import { BsPencilSquare } from 'react-icons/bs';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import Loading2 from '../../Loading2';


const Conference_Booking_list = (props) => {
  const authToken = Cookies.get('authToken');
  const user_type = Cookies.get('user_type');
  const { date } =props;
  const [loading, setLoading] = useState(true);
  // console.log(date)
  const [formattedDate, setFormattedDate] = useState(formatDateToYYYYMMDD(date));
  // console.log(formattedDate)
  const [userTimeZone, setUserTimeZone] = useState();
  const navigate = useNavigate();
  const [times, setTimes] = useState([{ start_time: '', end_time: '', status: '', conference_id: '', amount: '', currency: '' }]);
  const [fetchCompleted, setFetchCompleted] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  


  useEffect(() => {
    // setFormattedDate(formatDateToYYYYMMDD(date));
    // console.log(date)
  }, [date]);

  useEffect(() => {
    const fetchData = async () => {
      await Fetch_Time_Slots();
    };

    fetchData();
  }, [date]);

  useEffect(() => {
    if (fetchCompleted) {
      handleTimeConversion();
    }
  }, [fetchCompleted]);

  useEffect(() => {
    setFetchCompleted(false);
  }, [date]);

  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const Fetch_Time_Slots = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/calender/usertimeslot`,
        { date: formatDateToYYYYMMDD(date) },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      // console.log("Hai...")
      const data = response.data;
      // console.log(data)
      setUserTimeZone(data.user_data);
      

      let timeSlots = [];

      for (const slot of data.data) {
        let slotDetails = {
          start_time: slot.start_time,
          end_time: slot.end_time,
          status: slot.status,
          conference_id: slot.conference_id,
          id: slot._id,
          call_id:slot.call_id
        };
        // console.log(slot.call_id)
        if (slot.call_id) {
          const callResponse = await axios.get(
            `${apiUrl}/api/call/details/${slot.call_id}`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
              },
            }
          );
          const callDetails = callResponse.data;
          const url=callDetails.URL;
          slotDetails = { ...slotDetails, ...callDetails.Price,url };
        }

        timeSlots.push(slotDetails);
      }

      data.remainingTimeSlots.forEach((startTime) => {
        timeSlots.push({
          start_time: startTime,
          end_time: '',
          status: 'unavailable',
        });
      });

      setTimes(timeSlots);
    
      setFetchCompleted(true);
    } catch (error) {
      if (error.response) {
        console.error('Server Error:', error.response.data);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Request Error:', error.message);
      }
    }
  };

  //console.log("user Time zone", userTimeZone)

  // console.log("User Time Zone", userTimeZone);

 const handleTimeConversion = async () => {
    const asiaKolkataTimes = times.map(({ start_time, end_time, status, conference_id, id, amount, currency,call_id,url }) => {
      const currentStartTime = moment.tz(start_time, 'HH:mm', userTimeZone);
      const convertedStartTime = currentStartTime.clone().tz(userTimeZone);
  
      let convertedEndTime = '';
      if (end_time) {
        const currentEndTime = moment.tz(end_time, 'HH:mm', userTimeZone);
        convertedEndTime = currentEndTime.clone().tz(userTimeZone).format('HH:mm');
      }
  
      // if (amount !== undefined) {
      //   amount = CalculateAmount(Number(amount));
      // }
  
      return {
        displayStartTime: convertedStartTime.format('HH:mm'),
        displayEndTime: convertedEndTime,
        status,
        conference_id,
        id,
        call_id,
        amount,
        currency,
        url
      };
    });
  
    asiaKolkataTimes.sort((a, b) => {
      const timeA = moment(a.displayStartTime, 'HH:mm');
      const timeB = moment(b.displayStartTime, 'HH:mm');
      return timeA.diff(timeB);
    });
  
    setTimes(asiaKolkataTimes);
    setLoading(false)
  };
  

  // TOTAL PRICE CALCULATION INCLUDING SERVICE CHARGES AND GST

  const SERVICE_CHARGE_PERCENTAGE = 0.05; // 5%
  const GST_PERCENTAGE = 0.18; // 18%

  const calculateServiceCharge = (amount) => {
    //console.log("modified amount ==>",amount * SERVICE_CHARGE_PERCENTAGE)
    return Math.round(amount * SERVICE_CHARGE_PERCENTAGE);
    
  };
  const calculateGST = (amount) => {
    return Math.round(amount * GST_PERCENTAGE);
  };

  const CalculateAmount = (amount) =>{
    const serviceCharge = calculateServiceCharge(amount);
    // console.log("service Charges ==> ",serviceCharge)
    const updated_amount = Number(amount) + serviceCharge
    // console.log("updated amount ==> ",updated_amount)
    const gst = calculateGST(amount);
    // console.log("GST ==>",gst)
    const totalAmount = Math.round(Number(amount) + serviceCharge + gst);
    // console.log("Total Amount ==>",totalAmount)
    return totalAmount
  }


  // WHAT ACTION SHOULD BE TAKEN WHEN CLICK ON THE TIME SLOT
  
  const handleTimeSlotClick = async (timeSlot) => {
    
    if(timeSlot.id !== null && timeSlot.status === 'booked'){
      navigate('/user_events')
    }
    
    else{
    if (timeSlot.status === 'booked' && timeSlot.conference_id != null) {
      try {
        const response = await axios.post(
          `${apiUrl}/api/conference/getdetails`,
          {
            conference_id: timeSlot.conference_id,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          }
        );

        const cardData = response.data;
        const card = cardData.conferenceData;
        navigate('/conferece_details', { state: { card } });
      } catch (error) {
        console.error('Error fetching conference details:', error);
      }
    }
    else {
      navigate('/events', { state: { timeSlot, date } });
    }
    }
    
  };

  


  const handleEdit = async(id,start_time,end_time,amount,call_id,timeSlot,Url) =>{
      console.log(timeSlot)
     console.log(id,start_time,end_time,amount,call_id,Url)
    navigate('/edit_time_slot',{state:{id,start_time,end_time,amount,call_id,Url}})
  }

  // HANDLE DELETE CONTROL

  const handleDelete = async (id,event) => {
    event.stopPropagation(); // Prevent the event from bubbling up to the parent elements
  event.preventDefault(); // Prevent the default behavior of the delete icon click event
    try {
      const response = await axios.delete(`${apiUrl}/api/calender/delete_timeslots/${id}`);
      // console.log(id)
      // Handle the response
      // console.log('Time slot deleted successfully:', response.data);  
      window.alert('Time slot deleted successfully')
    } catch (error) {
      console.error('Error deleting time slot:', error.response.data); 
    }
  };


  if(loading){
    return <Loading2/>
  }


  return (
    <div className='flex flex-col lg:h-[470px] justify-center lg:mt-5 w-full' >
      <div className=''>

        {/* TIME SLOTS WITH STATUS IN COLOR , TIMINGS , PRICE , EDIT AND DELETE OPTIONS FOR TEACHERS ONLY */}

        <div className='mb-4  overflow-y-auto lg:h-[500px] h-[300px] '>
          {fetchCompleted &&
            times.map((timeSlot, index) => (
              <div
                key={index}
                className={`time_line  text-xs lg:text-md ${
                  timeSlot.status === 'booked'
                    ? 'booked'
                    : timeSlot.status === 'available'
                    ? 'available'
                    : ''
                }`}
                onClick={() => {
                  if (timeSlot.status !== 'available' && timeSlot.status !== 'booked') {
                    handleTimeSlotClick(timeSlot);
                  }
                }}
              > 
                <div className='text-xs lg:text-md   flex flex-row items-center py-1 rounded-full'>
                  {timeSlot.displayStartTime}{' '}
                  {timeSlot.displayEndTime ? `- ${timeSlot.displayEndTime}` : ''}
                </div>
                <hr className={`h-px  ml-1 ${timeSlot.status === 'booked' ? 'w-3/5 lg:w-4/5' : timeSlot.status === 'available' ? 'w-2/5 lg:w-3/5' : 'w-4/5 lg:w-4/5 '}`} />
                {timeSlot.status === 'available' ? (
                  <div className='ml-3 lg:mr-2 mr-0  text-xs lg:text:md'>
                    Price: {timeSlot.amount === '0' ? 'Free' : `${timeSlot.currency} ${timeSlot.amount}`}
                  </div>
                ) : null}
                {user_type === 'Teacher' && timeSlot.status === 'available' ? (
                  <div className='flex lg:gap-3 gap-1 ml-2'>
                    <BsPencilSquare className=' text-xl' onClick={()=>handleEdit(timeSlot.id,timeSlot.displayStartTime,timeSlot.displayEndTime,timeSlot.amount,timeSlot.call_id,timeSlot,timeSlot.url)} />
                    <RiDeleteBin6Fill className=' text-xl' onClick={(event)=>handleDelete(timeSlot.id,event)} />
                  </div>
                ) : timeSlot.status === 'available'? (<div className=' bg-white py-2 px-4'>Book Now</div>):null}
              </div>
            ))}
        </div>    
        
      </div>
    </div>
  );
};

export default Conference_Booking_list;
