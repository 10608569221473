import React, { useEffect, useState } from 'react'
import { MdCancel } from 'react-icons/md';

const AdminInvoiceModal = ({ closeModal, selectedInvoice, reportType }) => {
    const getTitle = () => {
        switch (reportType) {
            case 'callInvoices':
                return 'Call Invoice';
            case 'conferenceInvoices':
                return 'Conference Invoice';
            case 'subscriptionInvoices':
                return 'Subscription Invoice';
            default:
                return 'Invoice';
        }
    };
    console.log(selectedInvoice)
    return (
        <div>
            <div className="w-full fixed top-0 left-0 z-50  h-full flex justify-center items-center bg-black bg-opacity-50">
                <div className=''>
                    <div className="flex flex-col gap-4 justify-center sm:w-11/12 lg:w-full bg-white py-[10px] sm:py-[20px] sm:mt-0 shadow-lg items-center gap-1 mx-auto border border-orange-400 rounded-[25px]">
                        <MdCancel className='self-end mx-4 text-center rounded-full text-orange-400 w-6 h-6 cursor-pointer' onClick={closeModal}></MdCancel>
                        <p className='text-[16px] sm:text-[22px] text-gradient font-semibold'>{getTitle()}</p>
                        <div className=' sm:grid sm:grid-cols-2 gap-3 py-2 w-11/12 sm:w-11/12 '>
                            <div className='flex flex-col gap-2 sm:flex sm:flex-col items-start mt-4 sm:gap-6'>
                                <div className='flex flex-col sm:items-start'>
                                    <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>Invoice Number: {selectedInvoice.invoiceNumber}</span>
                                    <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>Payment ID.: {selectedInvoice.phonepeTranctionId || selectedInvoice.paypalTransactionId}</span>
                                </div>
                                <div className='hidden sm:flex flex-row sm:flex-col pl-3 sm:pl-0 gap-3 items-start self-start sm:self-start'>
                                    {/* <img src={defaultProfile} alt="" className='w-10 h-10 sm:w-16 sm:h-16' /> */}
                                    <div className='flex flex-col sm:items-start'>
                                        <span className='text-gradient text-[16px] sm:text-[18px] font-semibold'>{(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? "Student Details " : "Teacher Details"}</span>
                                        <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>Name: {selectedInvoice.name}</span>
                                        <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>ID: {selectedInvoice.user}</span>
                                    </div>
                                    {(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ?
                                        (<div className='flex flex-col sm:items-start'>
                                            <span className='text-gradient text-[16px] sm:text-[18px] font-semibold'>Teacher Details</span>
                                            <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>Name: {selectedInvoice.teacherName}</span>
                                            <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>ID: {selectedInvoice.teacherID}</span>
                                        </div>)
                                        : ('')}
                                </div>
                            </div>
                            <div className='flex flex-col gap-2 items-center mt-3 sm:flex sm:flex-col sm:gap-6'>
                                <div className='flex flex-col justify-start self-start sm:justify-end text-[#585858] text-[14px] sm:text-[16px] font-medium sm:self-end '>
                                    <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>Date: {selectedInvoice.date}</span>
                                    <span className='text-[#585858] text-[14px] sm:text-[16px] font-medium'>GST No.: 08BEHPG9573E1Z9</span>
                                </div>
                                <div className='flex flex-col gap-2 rounded-[25px] bg-white drop-shadow py-3 pl-3 pr-3 lg:w-[400px]'>
                                    <span className='text-gradient text-[14px] sm:text-[18px] font-semibold'>Invoice Details and Price Break-Up</span>
                                    <div className='flex flex-col space-y-2 text-left'>

                                        <div className='flex justify-between gap-1 text-[#585858] text-[14px] sm:text-[16px] font-medium'>
                                            <span>{(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ? "Teacher’s fee:" : "Price"}</span>
                                            <span className='text-left self-start'>{selectedInvoice.currency} {selectedInvoice.EventPrice}/-</span>
                                        </div>
                                        {(reportType === 'callInvoices' || reportType === 'conferenceInvoices') ?
                                            <div className='flex justify-between gap-1 text-[#585858] text-[14px] sm:text-[16px] font-medium'>
                                                <span>CGB charges 5%:</span>
                                                <span className='text-left self-start'>{selectedInvoice.currency} {selectedInvoice.serviceCharges}/-</span>
                                            </div> :
                                            <div className='flex justify-between gap-1 text-[#585858] text-[14px] sm:text-[16px] font-medium'>
                                                <span>CGB Discount:</span>
                                                <span className='text-left self-start'>{selectedInvoice.currency} {selectedInvoice.EventPrice - selectedInvoice.totalAmount}/-</span>
                                            </div>}
                                        <div className='flex justify-between gap-1 text-[#585858] text-[14px] sm:text-[16px] font-medium'>
                                            <span>{!selectedInvoice.isPaypal === 'yes' ? 'GST 18%' : 'Tax 15%'}</span>
                                            <span className='text-left self-start'>{selectedInvoice.currency} {selectedInvoice.GST}/-</span>
                                        </div>
                                        {selectedInvoice.isPaypal === 'yes' ? (<>
                                            <div className='flex justify-between gap-1 text-[#585858] text-[14px] sm:text-[16px] font-medium'>
                                                <span>Paypal Charges:</span>
                                                <span className='text-left self-start'>{selectedInvoice.currency} {selectedInvoice.paypal_fee}/-</span>
                                            </div></>) : ''}
                                        <div className='flex justify-between gap-1 text-[#585858] text-[14px] sm:text-[16px] font-medium'>
                                            <span>Total Amount:</span>
                                            <span className='text-left self-start'>{selectedInvoice.currency} {selectedInvoice.totalAmount}/-</span>
                                        </div>
                                    </div>
                                    <span className='text-gradient text-[14px] sm:text-[18px] font-semibold'>Amount to pay: {selectedInvoice.currency} {selectedInvoice.totalAmount}/-</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminInvoiceModal