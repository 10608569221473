import React, { createContext } from "react";
import { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate,useLocation } from "react-router-dom";
import Chat from "./pages/chat";
import ChatOpen from "./pages/chatOpen";
import Login from "./pages/login";
import SignUp from "./pages/signupTeacher";
import SignUpStudent from "./pages/signupStudent";
import EditProfile from "./pages/editProfile";
import Otp from "./pages/otp";
import Edit_Time_Slot from "./components/Call_And_Conference/Edit_Time_Slot";
import FlashMessage from "./components/flashMessage";
import ChangePassword from "./pages/changePassword";
import ForgotPassword from "./pages/forgotPassword";
import Availability from "./components/Call_And_Conference/Availability";
import Student_Conference_Page from "./components/Call_And_Conference/Conference/Student_Conference_Page";
import ViewProfile from "./pages/viewProfile";
import EmailModal from "./Modals/EmailModal";
import Calender_page from "./components/Call_And_Conference/Calender_page";
import Student_Call_Book_list from "./components/Call_And_Conference/Calls/Student_Call_Book_list";
import Conference_details from "./components/Call_And_Conference/Conference/Conference_details";
import Event from "./components/Call_And_Conference/Event";
import Conference_Call_Events from "./components/Call_And_Conference/conference";
import Cabinet from "./pages/cabinet";
import Search from "./pages/search";
import Forum from "./pages/forum";
//import NewSubscription from './pages/teacher/newSubscription'
import Cookies from "js-cookie";
import "./App.css";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import TeacherEarnings from "./pages/teacher/teacherEarnings";
import ResetPassword from "./pages/resetPassword";
//import SubscriptionDetails from "./pages/teacher/subscriptionDetails";
import InvoiceDetails from "./pages/invoiceDetails";
//import InvoicSubscriptioneDetails from "./pages/invoice_subscription_Details"
import Home from './pages/home'
import Notification from './pages/notification.jsx'
import PrivacyPolicy from "./pages/policyPages/privacyPolicy.jsx";
import RefundPolicy from "./pages/policyPages/refundPolicy.jsx";
import PaymentPolicy from "./pages/policyPages/paymentPolicy.jsx";
import UserAgreement from "./pages/policyPages/userAgreement.jsx";
import Terms_and_conditions from "./pages/policyPages/terms_and_conditions.jsx";
import AdminLayout from "./pages/adminPages/adminLayout.jsx";
import AdminLogin from "./pages/adminPages/adminLogin.jsx";
import AdminSignup from "./pages/adminPages/adminSignUp.jsx";
import AdminDashboard from "./pages/adminPages/adminDashboard.jsx";
import ContactUs from "./pages/policyPages/contactUs.jsx";
import ShippingPolicy from "./pages/policyPages/shippingPolicy.jsx";
import SupportPolicy from "./pages/policyPages/supportPolicy.jsx";
import AboutUs from "./pages/policyPages/aboutUs.jsx";
import PricingPage from "./pages/policyPages/pricingPage.jsx";
import Gateway from "./pages/gateway.jsx";
import Shared_Conference_Details from "./components/Call_And_Conference/Conference/Shared_Conferene_Details.jsx";
import Create_Course from "./components/Course/Teacher/create_course.jsx";
import Student_Course_List from "./components/Course/Student/Student_Course_List.jsx";
import Course_Details_Page from "./components/Course/Course_Details_page.jsx";
import Shared_Course_Details from "./components/Course/Shared_Course_Details.jsx";
import Edit_Course from "./components/Course/Teacher/Edit_Course.jsx";


export const ChatContext = createContext(null);

function App() {

  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [profilePic, setProfilePic] = useState(`https://cgb-storage.blr1.digitaloceanspaces.com/profile-pictures/defaultProfile.png`);
  const [fullName, setFullName] = useState("")
  const [profileData, setProfileData] = useState({ "display": "none", "name": "" })
  const [conversationId, setConversationId] = useState(null);
  const [otheruserID, setOtherUserID] = useState(null); // Example for other user ID
  const isAuthenticated = Cookies.get("authToken") !== undefined;

  const PrivateRoute = ({ element, ...rest }) => {
    const location = useLocation();
  return isAuthenticated 
    ? element 
    : <Navigate to="/login" state={{ from: location.pathname }} />;
  };

  return (
    <Router>
      <ChatContext.Provider value={{ conversationId, setConversationId, otheruserID, setOtherUserID }}>
        <FlashMessage message={message} showMessage={showMessage} setShowMessage={setShowMessage} />
        <EmailModal setMessage={setMessage} setShowMessage={setShowMessage} profileData={profileData} setProfileData={setProfileData} />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route exact path="/linkedin" element={<LinkedInCallback />} />
          <Route path="/login" element={<Login setMessage={setMessage} setShowMessage={setShowMessage} setProfilePic={setProfilePic} setFullName={setFullName} />} />
          <Route
            path="verify"
            element={
              isAuthenticated ? (
                <Otp setMessage={setMessage} setShowMessage={setShowMessage} />
              ) : (
                <Login setMessage={setMessage} setShowMessage={setShowMessage} setProfilePic={setProfilePic} setFullName={setFullName} />
              )
            }
          />
          <Route path="signup-teacher" element={<SignUp setMessage={setMessage} setShowMessage={setShowMessage} setProfilePic={setProfilePic} profileData={profileData} setProfileData={setProfileData} setFullName={setFullName} />
          }
          />
          <Route path="signup-student" element={<SignUpStudent setMessage={setMessage} setShowMessage={setShowMessage} setProfilePic={setProfilePic} profileData={profileData} setProfileData={setProfileData} setFullName={setFullName} />
          }
          />
          <Route
            path="change-password"
            element={
              isAuthenticated ? (
                <ChangePassword
                  setMessage={setMessage}
                  setShowMessage={setShowMessage}
                />
              ) : (
                <Login
                  setMessage={setMessage}
                  setShowMessage={setShowMessage}
                  setProfilePic={setProfilePic}
                  setFullName={setFullName}
                />
              )
            }
          />
          <Route
            path="forgot-password"
            element={
              <ForgotPassword setMessage={setMessage} setShowMessage={setShowMessage} />
            }
          />
          <Route
            path="reset-password/:token"
            element={
              <ResetPassword setMessage={setMessage} setShowMessage={setShowMessage} />
            }
          />
          <Route
            path="edit-profile"
            element={
              isAuthenticated ? (
                <EditProfile
                  setMessage={setMessage}
                  setShowMessage={setShowMessage}
                  profilePic={profilePic}
                />
              ) : (
                <Login
                  setMessage={setMessage}
                  setShowMessage={setShowMessage}
                  setProfilePic={setProfilePic}
                  setFullName={setFullName}
                />
              )
            }
          />
          <Route
            path="cabinet"
            element={
              isAuthenticated ? (
                <Cabinet profilePic={profilePic} fullName={fullName} />
              ) : (
                <Login
                  setMessage={setMessage}
                  setShowMessage={setShowMessage}
                  setProfilePic={setProfilePic}
                  setFullName={setFullName}
                />
              )
            }
          />
          <Route
            path="search"
            element={
              isAuthenticated ? (
                <Search profilePic={profilePic} fullName={fullName} />
              ) : (
                <Login
                  setMessage={setMessage}
                  setShowMessage={setShowMessage}
                  setProfilePic={setProfilePic}
                  setFullName={setFullName}
                />
              )
            }
          />
          <Route
            path="chat"
            element={
              isAuthenticated ? (
                <Chat setMessage={setMessage} setShowMessage={setShowMessage} profilePic={profilePic} fullName={fullName} />
              ) : (
                <Login
                  setMessage={setMessage}
                  setShowMessage={setShowMessage}
                  setProfilePic={setProfilePic}
                  setFullName={setFullName}
                />
              )
            }
          />

          <Route
            path="open-chat/"
            element={
              <PrivateRoute
                element={
                  <ChatOpen
                    setMessage={setMessage}
                    setShowMessage={setShowMessage}
                    profilePic={profilePic} fullName={fullName}
                  />
                }
              />
            }
          />
          <Route path="forum" element={<PrivateRoute element={<Forum setMessage={setMessage} setShowMessage={setShowMessage} />} />} />
          <Route path="calender_page"
            element={<PrivateRoute
              element={
                <Calender_page />
              }
            />
            }


          />
          <Route path="conferece_details/:id"
            element={<PrivateRoute
              element={<Shared_Conference_Details />}
            />
            }
          />

          <Route path="/conferece_details" element={<PrivateRoute element={<Conference_details />} />} />


          <Route path="events"
            element={<PrivateRoute
              element={<Event
                setMessage={setMessage}
                setShowMessage={setShowMessage}
              />}

            />
            }
          />
          <Route path="availability"
            element={<PrivateRoute
              element={<Availability
                setMessage={setMessage}
                setShowMessage={setShowMessage}
              />}
            />}
          />
          <Route path="edit_time_slot"
            element={<PrivateRoute
              element={<Edit_Time_Slot
                setMessage={setMessage}
                setShowMessage={setShowMessage}
              />}
            />}
          />
          <Route path="/edit-course"
            element={<PrivateRoute
              element={<Edit_Course
                setMessage={setMessage}
                setShowMessage={setShowMessage}
              />}
            />}
          />
          <Route path="/book_list/:id"
            element={<PrivateRoute
              element={<Student_Call_Book_list
                setMessage={setMessage}
                setShowMessage={setShowMessage}
              />}
            />}
          />
          <Route path="/teacher_conferences/:id"
            element={<PrivateRoute
              element={<Student_Conference_Page />}
            />}
          />
          <Route path="/teacher_courses/:id"
            element={<PrivateRoute
              element={<Student_Course_List />}
            />}
          />
          <Route path="/user_events"
            element={<PrivateRoute
              element={<Conference_Call_Events />}
            />}
          />
          <Route path='/payment/processing/:transactionId' element={<Gateway/>}/>
          {/* <Route path="subscription" element={<PrivateRoute element={<NewSubscription setMessage={setMessage} setShowMessage={setShowMessage} />} />} />
          <Route path="subscription-details" element={<PrivateRoute element={<SubscriptionDetails setMessage={setMessage} setShowMessage={setShowMessage} />} />} /> */}
          <Route path="invoice-details" element={<PrivateRoute element={<InvoiceDetails setMessage={setMessage} setShowMessage={setShowMessage} />} />} />
          {/* <Route path="invoice-subscription-details" element={<PrivateRoute element={<InvoicSubscriptioneDetails setMessage={setMessage} setShowMessage={setShowMessage} />} />} /> */}
          <Route path="earnings" element={<PrivateRoute element={<TeacherEarnings />}></PrivateRoute>}></Route>
          <Route path="course_creation" element={<PrivateRoute element={<Create_Course setMessage={setMessage} setShowMessage={setShowMessage}/>}></PrivateRoute>}></Route>
          <Route path="course_details_page" element={<PrivateRoute element={<Course_Details_Page/>}></PrivateRoute>}></Route>
          <Route path="course_details/:id" element={<PrivateRoute element={<Shared_Course_Details/>}></PrivateRoute>}></Route>
          <Route path="viewProfile"
            element={<PrivateRoute
              element={<ViewProfile />}
            />}
          />
          {/* <Route path="notification" element={<Notification/>}/> */}
          <Route path="privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="refundPolicy" element={<RefundPolicy />} />
          <Route path="paymentPolicy" element={<PaymentPolicy />} />
          <Route path="terms-and-conditions" element={<Terms_and_conditions />} />
          <Route path="userAgreement" element={<UserAgreement />} />
          <Route path="contactUs" element={<ContactUs />} setMessage={setMessage} setShowMessage={setShowMessage}/>
          <Route path="shippingPolicy" element={<ShippingPolicy />} />
          <Route path="supportPolicy" element={<SupportPolicy />} />
          <Route path="aboutUs" element={<AboutUs />} />
          <Route path="pricing" element={<PricingPage />} />
          {/* Admin Routes */}
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="login" element={<AdminLogin setMessage={setMessage} setShowMessage={setShowMessage} />} />
            <Route path="signup" element={<AdminSignup setMessage={setMessage} setShowMessage={setShowMessage} />}></Route>
            <Route path="dashboard" element={<AdminDashboard setMessage={setMessage} setShowMessage={setShowMessage} />} />
          </Route>
        </Routes>
      </ChatContext.Provider>
    </Router>
  );
}

export default App;
