import React from 'react'
import { MdCancel } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const ConferenceStudentsModal = ({ isOpen, onClose, students }) => {
    const navigate = useNavigate();

    if (!isOpen) return null;
    const handleViewProfile = (profileData) => {
        navigate("/viewProfile", { state: { profileData } });
    };
    const file_url = 'https://cgb-storage.blr1.digitaloceanspaces.com';
    let defaultProfile = `${file_url}/profile-pictures/defaultProfile.png`;
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 font-primary">
            <div className="bg-white rounded-lg p-4 w-11/12 sm:w-8/12 lg:w-1/2">
                <div className="flex justify-between items-center">
                    <h2 className="text-2xl font-semibold mb-4 text-[#585858]">Students list</h2>
                    <button className="mb-4" onClick={onClose}>
                        <MdCancel className='self-end  text-center rounded-full text-orange-400 w-6 h-6 cursor-pointer'></MdCancel>
                    </button>
                </div>
                <div className="overflow-y-auto" style={{ maxHeight: '70vh' }}>
                    {students.map((student, index) => {
                        const profileData = {
                            ...student.userDetails,
                            ...student.studentDetails
                        };
                        console.log(profileData, "profile data")
                        return (
                            <div key={index} >
                                <div className="flex items-center my-4 cursor-pointer px-4 py-2 drop-shadow-md bg-white mx-1 rounded-full" onClick={() => handleViewProfile(profileData)}>
                                    <img
                                        src={student.userDetails.profilePic || defaultProfile}
                                        alt={student.userDetails.fullName}
                                        className="sm:w-12 sm:h-12 w-10 h-10 rounded-full mr-4 border"
                                    />
                                    <div>
                                        <p className="font-semibold text-[#585858] text-12px sm:text-14px lg:text-20px">{student.userDetails.fullName}</p>
                                        {/* Add more student details here if needed */}
                                        <p className="font-medium text-[#afafaf] text-11px sm:text-12px lg:text-18px">
                                            {student.studentDetails.about.length > 80 ? (
                                                <>{student.studentDetails.about.slice(0, 80) + ".."}</>
                                            ) : (
                                                <>{student.studentDetails.about}</>
                                            )}
                                        </p>
                                    </div>
                                </div>
                                {/* <hr /> */}
                            </div>)
                    })}
                </div>
            </div>
        </div>
    )
}

export default ConferenceStudentsModal