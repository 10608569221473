import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Loading2 from '../components/Loading2';
import Cookies from 'js-cookie';

const Gateway = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const authToken = Cookies.get('authToken');
  const user_loggedInId = Cookies.get('loggedInUserId');
  const invoice_details = JSON.parse(localStorage.getItem('invoice_data'));
  const [loading, setLoading] = useState(true);
  const { transactionId } = useParams();
  
  console.log("transaction id===>", transactionId);
  useEffect(() => {
    // console.log(invoice_details);
    handleBookandInvoiceDetails(transactionId);
  }, [transactionId]); // Add transactionId as a dependency to run useEffect only when transactionId changes

  const handleBookandInvoiceDetails = async (transactionId) => {
    try {
      console.log(invoice_details.paymentType);
      if (invoice_details.paymentType === 'Call') {
        // console.log("inside call");
        await bookCall(invoice_details.Id, invoice_details.slot_id);
        // console.log("invoice details", invoice_details);
        await GENERATE_INVOICE(invoice_details, transactionId);
        setLoading(false);
      } else if(invoice_details.paymentType === 'Conference') {
        // console.log("inside conference");
        await bookConference(invoice_details.Id);
        await GENERATE_INVOICE(invoice_details, transactionId);
        setLoading(false);
      }else if(invoice_details.paymentType === 'Course') {
        // console.log("inside conference");
        await bookCourse(invoice_details.Id);
        await GENERATE_INVOICE(invoice_details, transactionId);
        setLoading(false);
      }
      else{
        await GENERATE_INVOICE(invoice_details, transactionId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GENERATE_INVOICE = async (invoice_details, transactionId) => {
    // console.log("Inside Invoice", invoice_details, transactionId);
    const generatedInvoice = await axios.post(`${apiUrl}/api/invoice/save_invoice_data`, {
      invoice_details,
      transactionId
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      }
    });
    // console.log("Result of saved invoice: ", generatedInvoice.data);
    if (invoice_details.paymentType === 'Subscription'){
      navigate('/subscription-details')
    }
  };

  const bookCall = async (callId, slotId) => {
    try {
      // console.log("Book call id ==> ", callId);
      const callResponse = await axios.put(
        `${apiUrl}/api/call/call_book/${callId}`,
        {slot_id: slotId.toString()},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      // console.log(callResponse.data);
      if (callResponse.status === 200) {
        console.log('Call booked');
        await Call_Booked(callId);
      }
      
    } catch (error) {
      console.error('Server Error:', error.response.data);
    }
  };

  const Call_Booked = async (id) => {
    const response = await axios.post(`${apiUrl}/api/call/${id}/call_booked`, {
      studentId: user_loggedInId,
      paymentId: invoice_details.payment_mercant_id,
      amountPaid: invoice_details.totalAmount
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      }
    });
    const data = response.data;
    // console.log(data);
    //navigate(`/book_list/${}`)
    navigate('/cabinet')
  };

  const bookConference = async (Conference_Id) => {
    const response = await axios.post(`${apiUrl}/api/conference/${Conference_Id}/conference_booked`, {
      studentId: user_loggedInId,
      paymentId: invoice_details.payment_mercant_id,
      amountPaid: invoice_details.totalAmount
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      }
    });
    if (response.status === 200) {
      // console.log('Conference booked');
      const data = response.data;
      // console.log("Conference Booked Details ===>", data);
      await conference_booked(response.data.conferenceId)

    }
    navigate('/cabinet')
  };
  const conference_booked = async (id) => {
    try {
      // console.log(id ,"conf id")
        const response = await axios.post(`${apiUrl}/api/conference/book_conference/${id}`, {}, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
        });
        const data = response.data;
        // console.log(data);
        navigate('/cabinet');
    } catch (error) {
        console.error('Failed to book conference:', error);
    }
}
const bookCourse = async (Course_Id) => {
  const response = await axios.post(`${apiUrl}/api/courses/${Course_Id}/course_booking`, {
    studentId: user_loggedInId,
    paymentId: invoice_details.payment_mercant_id,
    amountPaid: invoice_details.totalAmount
  }, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    }
  });
  if (response.status === 200) {
    // console.log('Conference booked');
    const data = response.data;
    // console.log("Conference Booked Details ===>", data);
    await course_booked(response.data.courseId)

  }
  navigate('/cabinet')
};

const course_booked = async (id) => {
  try {
    // console.log(id ,"conf id")
      const response = await axios.post(`${apiUrl}/api/courses/book_course/${id}`, {}, {
          headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
          },
      });
      const data = response.data;
      // console.log(data);
      navigate('/cabinet');
  } catch (error) {
      console.error('Failed to book conference:', error);
  }
}
  if (loading) {
    return <Loading2 />;
  }

  return (
    <div>
      Processing...
    </div>
  );
};

export default Gateway;
