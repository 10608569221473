import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import NavAdmin from '../../components/nav/navAdmin';
import Footer from '../../components/nav/footer';


const AdminLayout = () => {
    // const isAuthenticated = Cookies.get("adminAuthToken") !== undefined;

    // if (!isAuthenticated) {
    //   return <Navigate to="/admin/login" />;
    // }
  
    return (
      <div className='w-full font-primary'>
        <header>
          {/* Add your admin header or navigation here */}
          <NavAdmin/>
        </header>
        <main className='overflow-y-auto sm:min-h-screen sm:max-h-fit sm:mt-6 md:mt-0'>
          <Outlet />
        </main>
        <footer className=''>
          {/* Add your admin footer here */}
          <Footer/>
        </footer>
      </div>
    );
  };

export default AdminLayout