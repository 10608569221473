
import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import logo from "../assets/img/logo.svg";
import desktop_logo from "../assets/img/desktop_logo.svg";
import bg from "../assets/img/background.png";
import MetaDecorator from "../utils/MetaDecorator";

const apiUrl = process.env.REACT_APP_API_URL;

const ForgotPassword = ({ setMessage, setShowMessage }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setShowMessage(true)
      setMessage("Email is required");
      return;
    }
    sendResetEmail(email);
  }
  const sendResetEmail = async (email) => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/api/auth/forgot-password`, { email });

      // console.log(response.data); // Log the response data for debugging or handling success messages
      setShowMessage(true);
      setMessage(`Reset password link sent to ${email}`);
      alert(`Reset password link sent to ${email}`);
    }
    catch (error) {
      setShowMessage(true);
      if (error.response && error.response.status === 404) {
        setMessage('Email does not exist');
      } else {
        setMessage('Failed to send reset email. Check if your email is registered');
      }
      console.error('Error sending reset email:', error);
    }
    finally {
      setLoading(false);
    }
  };
  const keywords = ["elearning", "education", "online class", "education forum", "education","student","teacher","learning"]; 
  return (

    <section className="sm:h-[100vh] sm:w-full sm:flex items-center justify-center sm:px-5 font-primary">
      <MetaDecorator title={'Forgot password - Career Guidance Buddy'} description={''} imageUrl={''} keywords={keywords} imageAlt={''} url={''}/>
      <div className="sm:grid grid-cols-2 sm:h-[688px] sm:mx-auto max-w-2xl sm:max-w-[1080px] sm:shadow-md sm:rounded-[50px]">
        <div
          className="sm:hidden bg-cover bg-right-bottom bg-no-repeat h-[230px] sm:mx-auto sm:w-full sm:max-w-md"
          style={{ backgroundImage: `url("${bg}")` }}
        >
          <div className="pl-9 pt-10 max-w-[433px]">
            <img className="w-[189px]" src={logo} alt="logo" />
            <div className="pt-4 text-white">
              <h1 className="text-2xl font-medium">Forgot Password</h1>

            </div>
          </div>
        </div>

        <div className="bg-gradient sm:h-full hidden sm:flex flex-col justify-center items-center sm:rounded-s-[50px]">
          <div className="flex justify-center items-center mx-auto w-full">
            <img src={logo} alt="logo" className="sm:hidden w-6/12" />
            <img
              src={desktop_logo}
              alt="logo"
              className="hidden sm:block w-10/12"
            />
          </div>
          <div className="pt-4 sm:pt-[50px] md:pt-[80px] lg:pt-[136px]">
            <h3 className="text-center text-white text-lg md:text-2xl font-semibold">
              Start your journey to the
            </h3>
            <h3 className="text-center text-white text-lg md:text-2xl font-semibold pb-6 sm:pb-20">
              world of learning.
            </h3>
            <div className="fixed sm:relative sm:flex sm:flex-col sm:items-center sm:mx-auto sm:justify-center sm:mt-0 mt-[540px] sm:text-center sm:text-white text-sm md:text-xl pb-6">
              <div className="flex flex-row w-full justify-center gap-4 text-[#585858] text-[14px] mb-2 sm:mb-1">
                <Link
                  to="/terms-and-conditions"
                  className="sm:text-[16px] text-center sm:text-white"
                >
                  Terms & Conditions
                </Link>
                <Link to="/userAgreement" className="sm:text-[16px] text-center sm:text-white">
                  User Agreement</Link>

              </div>
              <div className="flex flex-row gap-4 justify-center text-[#585858] text-[14px]">
                <Link
                  to="/paymentPolicy"
                  className="sm:text-[16px] text-center sm:text-white"
                >
                  Payment Policy
                </Link>
                <Link to="/refundPolicy" className="sm:text-[16px] text-center sm:text-white">
                  Refund Policy
                </Link>

                <Link to="/privacyPolicy" className="sm:text-[16px] text-center sm:text-white">
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------------------------------ CREATE ACCOUNT USING NAME, EAMIL & PASSWORD ------------------------------------------ */}

        <div className="p-3 w-full mt-[-90px] sm:h-full flex flex-col items-center sm:gap-24 sm:justify-center">
          <div className="mx-auto w-full max-w-[480px] md:max-w-auto sm:w-full lg:w-10/12 mt-[10px] sm:mt-0">
            <Link to="/login" className="">
              <div className="flex mx-3 sm:mb-20 sm:mt-0 mt-10 items-center justify-start gap-1">
                <IoArrowBackCircleOutline size={24} className="w-5 h-5 sm:w-6 sm:h-6 text-[#afafaf]" />
                <span className="text-[14px] sm:text-[20px] text-[#afafaf] align-middle text-left">Back</span>
              </div>
            </Link>
            <h1 className="hidden sm:block text-[40px] sm:mb-8 font-semibold text-center text-gradient leading-[40px]">
              Forgot Password
              <br />

            </h1>
            <div className="sm:mt-[50px] mt-[50px] sm:px-[10px] px-4">

              <form className="flex flex-col gap-6 sm:gap-6" onSubmit={handleSubmit}>

                <div>

                  <label
                    for="email"
                    className="block sm:mt-0 text-gradient text-left text-[14px] sm:text-[18px] font-semibold "
                  >
                    Enter Email to reset the password
                  </label>

                  <div className="relative mt-1">
                    <div className="absolute inset-y-0 left-0 pl-[15px] flex items-center pointer-events-none">
                      <MdEmail className=" w-[16px] h-[20px] text-orange-400" />

                    </div>
                    <input
                      type="email"
                      required
                      className="pl-10 pr-4 py-2 sm:py-3 placeholder:text-orange-500 border border-orange-500 outline-none text-[14px] sm:text-[16px] w-full rounded-full"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>



                <div className="flex justify-center">
                  <button
                    // type="submit"
                    className="sm:px-6 px-4 py-2 sm:py-3 sm:text-[16px] sm:font-medium text-white w-8/12 sm:w-full bg-gradient-to-b from-[#ff6535] via-[#ff9e48] to-[#ffce58] rounded-full drop-shadow-md"
                  >{loading ?
                    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-orange-400 sm:border-t-transparent sm:border-white" role="status">
                    </div> : 'Submit'}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="sm:hidden flex flex-col w-full items-center gap-1 mx-auto justify-center fixed bottom-[20px] text-center md:text-xl pb-4">
            <div className="flex flex-row w-full justify-center gap-4 text-[#585858] text-[14px]">
              <Link
                to="/terms-and-conditions"
                className="text-[12px] text-center"
              >
                Terms & Conditions
              </Link>
              <Link to="/userAgreement" className="text-[12px] text-center">
                User Agreement</Link>

            </div>
            <div className="flex flex-row w-full gap-3 justify-center text-[#585858] text-[14px]">
              <Link
                to="/paymentPolicy"
                className="text-[12px] text-center"
              >
                Payment Policy
              </Link>
              <Link to="/refundPolicy" className="text-[12px] text-center">
                Refund Policy
              </Link>

              <Link to="/privacyPolicy" className="text-[12px] text-center">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
};

export default ForgotPassword;
