
import React,{useState} from 'react'
import { AiOutlinePlus } from "react-icons/ai";
import Conference_list from './Conference_list';
import Conference_Booking_list from './Conference_Booking_list';
import Cookies from "js-cookie";



const Main_Conference = (props) => {

  const { date } = props;
    const currentDate = date;
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const currentShortDate = `${monthNames[currentDate.getMonth()]} - ${currentDate.getDate().toString().padStart(2, '0')} ${dayNames[currentDate.getDay()]}`;
    const user_type = Cookies.get('user_type')
    const [showBookingList, setShowBookingList] = useState(false);

 
  // FUNCTION TO SHOW TIME SLOTS STATUS ON PARTICULAR DATE
    
  const handleCreateConferenceClick = () => {
    setShowBookingList((prevShowBookingList) => !prevShowBookingList);
  };

  // CONVERTING TODAY DATE IN THE FORMAT DD-MM-YYY

  const isToday = (someDate) => {
    const today = new Date();
    return someDate.getDate() === today.getDate() &&
           someDate.getMonth() === today.getMonth() &&
           someDate.getFullYear() === today.getFullYear();
  };
  

  return (
    <div className='flex flex-col  justify-center lg:justify-start ml-5 md:-ml-11 mt-3 mb-3 lg:w-full lg:h-full  '>

      {/* ---------------------------- SHOWING DATE AND TIME SLOTS ON THAT DATE -------------------------------- */}

      <div className='sm:flex items-center justify-between'>
        <div class="flex flex-col justify-start items-start px-8 mb-2 mt-5 sm:ml-5 sm:mt-0"   >
            <div id="short_date_form" className="text-lg font-semibold">{currentShortDate}</div>
            <span className='text-[#AFAFAF] text-xs'>Daily Tasks</span>
        </div>

        {/* ----------- IF DATE IS TODAY DATE THEN USER CAN'T CREATE A CONFERENCE OTHER THEN TODAY USER CAN CREATE A CONFERENCE --------------- */}

        {user_type === 'Teacher' && !isToday(date) && (
          <div className='flex justify-start gap-10 mt-3 ml-5 md:ml-5 lg:ml-0 lg:mb-4'>
            <div className='flex justify-center items-center gap-4 cursor-pointer lg:mb-4' onClick={handleCreateConferenceClick}>
              <span className='text-[#585858] text-sm'>Create a Conference</span>
              <AiOutlinePlus className='text-[#585858] text-sm' />
            </div>
          </div>
        )}
        </div>

        {/* ------------------------ MY DEFAULT SHOW BOOKED CONFERENCES LIST OR ELSE SHOWS THE TIME SLOTS OF THE DATE --------------------------  */}
        
        {showBookingList ? <Conference_Booking_list date={date}/> : <Conference_list date={date}/>}

    </div>
  )
}

export default Main_Conference