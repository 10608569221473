import React, { useState, useEffect } from 'react';
import './calender.css'
import logo from '../../assets/img/logo.svg'
import bg from '../../assets/img/background-2.png'
import Main_Conference from './Conference/Main_Conference';
import Main_Call_Page from './Calls/Main_Call_Page';
import MainMenu from '../MenuBottom/MainMenu';
import Cookies from "js-cookie";
import Calendar from 'react-calendar';
import { IoIosShareAlt } from 'react-icons/io';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import NavBar from '../nav/navBar';
import MetaDecorator from '../../utils/MetaDecorator';
import { SiBookstack } from "react-icons/si";
import Main_course_page from '../Course/Teacher/Main_course_page';


const Calender_page = () => {
  const [ date, setDate ] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState('');
    const [currentShortDate, setCurrentShortDate] = useState('');
    const [calendarDays, setCalendarDays] = useState([]);
    const [currentDayIndex, setCurrentDayIndex] = useState(null);
    const [activeButton, setActiveButton] = useState('Conferences');
    const [showcalender,SetShowCalender] = useState(false)
    const user_type = Cookies.get('user_type')
    const loggedInUserId = Cookies.get('loggedInUserId');
    const authToken = Cookies.get("authToken");
    const apiUrl = process.env.REACT_APP_API_URL
    const navigate = useNavigate();
    //console.log(user_type)
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // IN CALENDAR USER CAN ABLE TO SELECT DATES FORM NEXT DATE
    const fifteenDaysLater = new Date();
    fifteenDaysLater.setDate(fifteenDaysLater.getDate() + 15);  // USER CAN ABLE TO SELECT AFTER 15 DAYS FROM THE CURRENT DATE
   

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
      };
    

    // FUNCTION TO PRINTS THE WEEK OF THE MONTH
    
    useEffect(() => {
      
      displayCurrentWeek();
      Get_Teacher_Details()

    }, []);

    //console.log('Active Button:', activeButton);

    // DISPLAY THE WEEK CALENDAR OF THAT WEEK IN A MONTH

    const displayCurrentWeek = () => {
      const currentDate = new Date();
      const currentMonthFullName = getCurrentMonthName(currentDate.getMonth())[0];
      const currentMonthShortName = getCurrentMonthName(currentDate.getMonth())[1];
      const currentDay = currentDate.getDate();
      const currentDayOfWeek = getDayOfWeekName(currentDate.getDay(), false); // Full day name
      const dayIndex = currentDate.getDay();
  
      setCurrentMonth(currentMonthFullName);
      setCurrentShortDate(`${currentMonthShortName} ${currentDay} - ${currentDayOfWeek}`);
      setCurrentDayIndex(dayIndex);
  
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
  
      const days = [];
      for (let i = 0; i < 7; i++) {
        const day = new Date(startOfWeek);
        day.setDate(startOfWeek.getDate() + i);
        days.push(day);
      }
  
      setCalendarDays(days);
    };
  
    const getCurrentMonthName = (monthIndex) => {
      const months = [
        ["January", "Jan"], ["February", "Feb"], ["March", "Mar"], ["April", "Apr"],
        ["May", "May"], ["June", "Jun"], ["July", "Jul"], ["August", "Aug"],
        ["September", "Sep"], ["October", "Oct"], ["November", "Nov"], ["December", "Dec"]
      ];
      return months[monthIndex];
    };
  
    const getDayOfWeekName = (dayIndex, abbreviated = true) => {
      const abbreviatedDaysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      
      if (abbreviated) {
        return abbreviatedDaysOfWeek[dayIndex];
      } else {
        return daysOfWeek[dayIndex];
      }
    };

    // SHOW MONTH CALENDAR

    const showCalender = () =>{
      
      SetShowCalender(!showcalender)
      
    }

    const Get_Teacher_Details = async()=>{
      const details = await axios.get(`${apiUrl}/api/teacher/get-teacher-details`,{
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
      },
      });
      const profileData=details.data;
      //console.log(profileData)
      localStorage.setItem('profileData', JSON.stringify(profileData));
      
    }

    // SET A TIME SLOT AVAILABLE FOR CALLS

    const handleCourse = () => {
      navigate('/course_creation');
    };

    // SHARE FUNCTIONALITY

    const today = new Date();
  
    const handleshare = () => {
      if (navigator.share) {
        const baseUrl = window.location.origin;
        navigator.share({
            title: 'Check out this book list!',
            text: 'Here is an amazing book list you should see:',
            url: `${baseUrl}/book_list/${loggedInUserId}`,
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
        alert('Sharing is not supported on this browser.');
    }
    };

    const handleDateChange = (newDate) => {
      //console.log(newDate);
      setDate(newDate);
      SetShowCalender(false); 
    };
    
    const formatDate = (date) => {
      const options = { month: 'long', day: '2-digit', year: 'numeric' };
      return date.toLocaleDateString('en-US', options).replace(',', ' -');
    };
    const handleDateClick = (day) => {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      // Check if the selected day is not a past date
      if (day >= currentDate) {
        setDate(day);
        displayCurrentWeek(day);
      }
    };
    const keywords = ["elearning", "education", "online class","student","teacher","learning"]; 
  return (
    <>
    <MetaDecorator title={'Calendar - Career Guidance Buddy'} description={''} imageUrl={''} keywords={keywords} imageAlt={''} url={''}/>
      <section class="mb-20 sm:mb-0">
      {/* ------- NavBar -------- */}
      <NavBar/>
      <div class='sm:mt-10 lg:mt-16 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 overflow-y-auto'>
        <div class="lg:grid grid-cols-2 gap-4 sm:min-h-[85vh] sm:py-[55px] justify-center overflow-y-auto">

            {/* COL SPAN -1  */}

            <div>

              {/* ----------------------------- HEADER OF THE PAGE ----------------------------------- */}

              <div class="sm:hidden bg-contain bg-cover bg-no-repeat w-full h-[280px] sm:mx-auto sm:w-full"
                style={{ backgroundImage: `url("${bg}")` }}>
                <div class="flex justify-center items-center pt-[52px]">
                  <img class="w-[189px]" src={logo} alt="logo" />
                </div>
              </div>
              
              {/* ---------------- SHOW WEEK CALENDAR , SHOW CALENDER , MONTH -------------------------  */}
              
              <div class="flex flex-col-reverse items-center justify-end">
                <div class="flex flex-col gap-4 justify-center items-center w-screen sm:w-auto mt-4 px-5">
                {activeButton !== 'Course' ?(
                  <>
                    <div class="flex items-center sm:justify-center sm:gap-5 w-full">
                      <h1 class="text-[#AFAFAF] text-lg font-bold">
                      {date && date.toDateString() === today.toDateString() ? 'Today' : formatDate(date)}
                      </h1>
                      <div class="flex-grow sm:flex-grow-0 flex justify-center">
                        <h1 id="month-header" className="bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] text-white px-7 py-2 rounded-full font-medium cursor-pointer" onClick={showCalender}>{currentMonth}</h1>
                      </div>
                    </div>

                  {/* -------------------------------- WEEK CALENDAR ------------------------------------------------ */}

                  <div class="flex justify-center items-center " >
                    <div id="calendar" className="ml-[-16px] sm:ml-0 px-[25px] py-[15px] shadow-md lg:rounded-full sm:rounded-[40px]  text-lg font-bold  w-fit text-center"  >
                      <table>
                        <thead>
                          <tr>
                            {calendarDays.map((day, index) => {
                              const currentDate = new Date();
                              const isPastDate = day < new Date(currentDate.setHours(0, 0, 0, 0));
                              return (
                                <th
                                  key={index}
                                  className={`${index === currentDayIndex ? 'current-day cursor-pointer' : 'cursor-pointer'} ${
                                    isPastDate ? 'past-date' : ''
                                  }`}
                                  onClick={() => !isPastDate && handleDateClick(day)}
                                  style={{ cursor: isPastDate ? 'not-allowed' : 'pointer' }}
                                >
                                  {getDayOfWeekName(index)}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {calendarDays.map((day, index) => {
                              const currentDate = new Date();
                              const isPastDate = day < new Date(currentDate.setHours(0, 0, 0, 0));
                              return (
                                <td
                                  key={index}
                                  className={`${day.toDateString() === today.toDateString() ? 'current-date cursor-pointer' : 'cursor-pointer'} ${
                                    isPastDate ? 'past-date' : ''
                                  }`}
                                  onClick={() => !isPastDate && handleDateClick(day)}
                                  style={{ cursor: isPastDate ? 'not-allowed' : 'pointer' }}
                                >
                                  {day.getDate()}
                                </td>
                              );
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* -------------------------------------------------- MONTH CALENDAR ------------------------------------- */}

                  {showcalender ? (
                    <div className='flex justify-center '>
                      <Calendar
                        onChange={handleDateChange}
                        value={date}
                        minDate={tomorrow} 
                        maxDate={fifteenDaysLater} 
                        showNavigation={false}
                      />
                    </div>
                  ) : null}</>):(null)}

                  {/* TOGGLE BUTTON BETWEEN CALLS AND CONFERENCES FOR STUDENT AND TEACHER */}         
                  <div className="flex justify-center mt-5">
                    <button className="flex justify-between w-52 md:w-52 lg:w-96 h-11 px-6 border-2 border-orange-400 rounded-full">
                      <div
                        className={`${activeButton === 'Calls'
                          ? 'bg-gradient text-white py-2 ml-[-15.3%] md:ml-[-15.3%] lg:ml-[-7.7%] w-[190px] h-[42px] rounded-l-full focus:outline-none cursor-pointer flex items-center justify-center'
                          : 'bg-transparent text-gradient-to-r from-orange-500 to-yellow-500 py-2 w-32 md:w-32 lg:w-52 focus:outline-none cursor-pointer flex items-center justify-center'
                        }`}
                        onClick={() => handleButtonClick('Calls')}
                      >
                        <span className="relative z-1 font-semibold ">{activeButton === 'Calls' ? 'Calls' : <span className="font-semibold text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-yellow-500">Calls</span>}</span>
                      </div>
                      {activeButton === 'Course' && (
                        <div className="border-l-2 border-orange-300 h-full mx-2"></div>
                      )}
                      <div
                        className={`${activeButton === 'Conferences'
                          ? 'bg-gradient text-white py-2 mr-[-16%] lg:mr-[-8%] w-[190px] md:w-[170px] lg:w-[280px] h-[42px] rounded-r-full focus:outline-none cursor-pointer flex items-center justify-center'
                          : 'bg-transparent text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-yellow-500 py-2 w-32 md:w-32 lg:w-52 focus:outline-none cursor-pointer flex items-center justify-center '
                        }`}
                        onClick={() => handleButtonClick('Conferences')}
                      >
                        <span className="relative z-1 font-semibold ">{activeButton === 'Conferences' ? 'Conferences' : <span className=" font-semibold text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-yellow-500">Conferences</span>}</span>
                      </div>
                    </button>
                  </div>
                  <div className={`${activeButton !== 'Course' ? 'border-2 border-orange-600 rounded-3xl font-semibold  py-2 px-8 w-52 md:w-52 lg:w-96 h-11  bg-transparent text-orange-500 font-semibold  hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 flex justify-center gap-2 items-center shadow-lg  cursor-pointer':'text-white bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] flex justify-center rounded-3xl py-2 px-8 w-52 md:w-52 lg:w-96 h-11'}`}  onClick={() => handleButtonClick('Course')}>
                     Courses 
                  </div>

                    {/* ------------------------ CALL FUNCTIONALITIES ------------------------------ */}

                    {/* {activeButton === 'Calls' && (
                      <div className='hidden lg:block '>  
                        <div className='flex flex-col justify-center items-center gap-6 mt-4  '>
                          <div className='border-2 border-orange-600 rounded-3xl py-2 px-6 bg-transparent text-orange-500 font-semibold  hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 flex justify-center gap-2 items-center shadow-lg w-96 cursor-pointer'  onClick={handleCall}>
                            Set availability <AiOutlinePlus style={{fontSize:'1rem'}}/>
                          </div>
                          <div className='border-2 border-orange-600 rounded-3xl py-2 px-6 bg-transparent text-orange-500 font-semibold  hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 cursor-pointer  flex justify-center gap-2 items-center shadow-lg  w-96' onClick={handleshare}>
                            Share availability
                            <IoIosShareAlt style={{ fontSize: '1.5rem' }} />
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
                
                {activeButton === 'Course' ?(
              <>
              
              <div className='flex flex-col gap-4 justify-center items-center w-screen sm:w-auto mt-4 px-5'>
                <div className='border-2 border-orange-600 rounded-3xl  py-2 px-8 w-52 md:w-52 lg:w-96 h-11 bg-transparent text-orange-500 font-semibold  hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 flex justify-center gap-2 items-center shadow-lg  cursor-pointer'  onClick={handleCourse}>
                  Create Course <SiBookstack style={{fontSize:'1.5rem'}}/>
                </div>
              </div>
              
              </>
              ) :('')}
            </div>
            
            {/* ------------------------------ COL SPAN - 2 ----------------------------------- */}

            <div class="">
              <div class="flex flex-col gap-2 font-primary sm:rounded-[50px] mt-14 sm:mt-0">
                <div class="max-w-[625px] sm:max-w-none mx-auto sm:mx-0 w-full">
                  { user_type === 'Teacher'?(
                    <>
                    {activeButton === 'Conferences' && <Main_Conference  date={date}/>} 
                    {activeButton === 'Calls' &&<Main_Call_Page date={date} />}
                    {activeButton === 'Course' && <Main_course_page />}
                  </>
                    ):null}
                </div>
              </div>
            </div>
        </div>

        {/* ---------------------------------- BOTTOM MENU ---------------------------------------- */}

        <div className='flex justify-center'>
          <MainMenu/>
        </div>
      
      </div>
    </section>
    </>

  );
};

export default Calender_page;



















