import React, { useState,useEffect} from 'react';
import logo from '../../assets/img/logo.svg';
import { useLocation }  from 'react-router-dom';
import bg from '../../assets/img/background-2.png';
import { BsChevronLeft } from "react-icons/bs";
import { IoIosShareAlt,IoIosArrowDown } from "react-icons/io";
import { AiOutlinePlus, AiOutlineClockCircle } from "react-icons/ai";
import { CiCircleRemove } from "react-icons/ci";
import {getParamByISO} from 'iso-country-currency'
import Cookies from 'js-cookie';
import axios from "axios";
import MainMenu from '../MenuBottom/MainMenu';
import { useNavigate } from 'react-router-dom';
import NavBar from '../nav/navBar';
import { addHours, addMinutes, format, parse } from 'date-fns';
import dayjs from 'dayjs';
import {FaCheckCircle } from 'react-icons/fa';
import Loading2 from '../Loading2';




const Availability = ({setMessage, setShowMessage}) => {
  //let { date, start_time } = useParams();
  const location = useLocation();
  // console.log(location); // Check the location object
  let {date}=location.state
   date=formatDateToYYYYMMDD(date)
   
   // FUNCTION TO FORMAT DATE 

  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  //console.log(date)
 
  //console.log(timeSlot.displayStartTime)
  const [open, setOpen] = useState(false);
  const [startTime, setStartTime] = useState('08:00');
  const [endTime, setEndTime] = useState('20:00');
  const [bookedHours, setBookedHours] = useState([]);
  const authToken = Cookies.get("authToken");
  const navigate = useNavigate();
  const [selectedDuration, setSelectDuration] = useState('1 Hr');
  const [isOpenDuration, setIsOpenDuration] = useState(false)
  const [selectedDays, setSelectedDays] = useState([]);
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [upcomingDates, setUpcomingDates] = useState(Array(7).fill([]));
  const [currency, setCurrency] = useState('$');
  const [currencyCode, setCurrencyCode] = useState('USD');
  const [isOpenCurrency, setIsOpenCurrency] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState('');
  const [paypal_fee,setPaypal_fee]=useState(0.30);
  const [isIndia, setIsIndia] = useState(false);
  const [allEmpty,SetEmpty]=useState(0)
  const [fetched,setFetched]=useState(false)
  const [loading, setLoading] = useState(false);
  const [error,setError]=useState(false)
  const [pageloading,setPageLoading]=useState(true)
  
  const loggedInUserId = Cookies.get('loggedInUserId');
const apiUrl = process.env.REACT_APP_API_URL;
  

const fetchUserDetails = async (setCurrency, setCurrencyCode, setIsIndia,setFetched) => {
  try {
    //console.log(loggedInUserId)
    const response = await axios.get(`${apiUrl}/api/auth/user_details/${loggedInUserId}`);
    const user = response.data.user_Details;
    //console.log(user.country)
    if (!user.country) {
      console.error('No country name found in user details');
      return; // Exit early if no country name is available
    }

    if (user.country === 'India') {
      setCurrency('₹');
      setCurrencyCode('INR');
      setPaypal_fee(0)
      setIsIndia(true);
    } else {
      setIsIndia(false);
    }
    const details = await SubscriptionDetails();
      //console.log("Subscription Details ==>",details)
      //console.log(response.data.user)
      if(details  === null){
        //console.log("Not found subscription details")
        setPageLoading(false)
        setError(true)
      }else{
        if(response.data.user.referralCode === null || !details.isActive){
          //console.log("premium status",details.isActive)
          setPageLoading(false)
          setError(true)
          }
          else{
        //console.log("found subscription details")
        setPageLoading(false)}
        
      }
    setFetched(true)
  } catch (error) {
    console.error('Error fetching user details:', error);
  }
};


const SubscriptionDetails = async () => {
  try {

    const response = await axios.get(`${url}/api/subscription/getusersubscriptiondetails`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });
    //console.log(response.data.User_Subscription_Details)
    const Subscription = response.data.User_Subscription_Details;
    //setEndDate(convertDate(Subscription.endDate))
    return Subscription;

  } catch (e) {
    console.log(e);
  }
}


  const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  useEffect(() => {
    setCurrentDate(dayjs());
  }, []);

  const handleWeek = (day) => {
    setSelectedDays((prevSelectedDays) => {
      const currentSelectedDays = prevSelectedDays || [];
      const dayIndex = days.indexOf(day);  // Assuming 'days' is an array like ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
      const today = new Date();
  
      if (currentSelectedDays.includes(day)) {
        // Day is already selected, so deselect it
        const newSelectedDays = currentSelectedDays.filter((selectedDay) => selectedDay !== day);
        const newUpcomingDates = [...upcomingDates];
        
        // Remove dates associated with this dayIndex for the next three months
        newUpcomingDates[dayIndex] = [];
  
        setUpcomingDates(newUpcomingDates);
        console.log('Deselected day:', day);
        console.log('Upcoming dates after deselect:', newUpcomingDates);
        return newSelectedDays;
      } else {
        // Day is not selected, so select it
        const newSelectedDays = [...currentSelectedDays, day];
        const newUpcomingDates = [...upcomingDates];
        newUpcomingDates[dayIndex] = [];
  
        // Add dates for the specified dayIndex for the next three months
        for (let monthOffset = 0; monthOffset < 3; monthOffset++) {
          const currentMonth = new Date(today.getFullYear(), today.getMonth() + monthOffset, 1);
          const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
          
          for (let i = 1; i <= daysInMonth; i++) {
            const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), i);
            if (date.getDay() === dayIndex && date >= today) {
              const formattedDate = dayjs(date).format('YYYY-MM-DD');
              newUpcomingDates[dayIndex].push(formattedDate);
            }
          }
        }
  
        setUpcomingDates(newUpcomingDates);
        console.log('Selected day:', day);
        console.log('Upcoming dates after select:', newUpcomingDates);
        return newSelectedDays;
      }
    });
  };
  
  
  

  
  
  const renderDays = () => {
    const todayIndex = currentDate.day();
    return days.map((day, index) => {
      const isToday = todayIndex === index;
      const isSelected = selectedDays && selectedDays.includes(day);
  
      return (
        <div
          key={day}
          onClick={() => handleWeek(day)}
          className={`border-2 border-orange-500 rounded-full h-[40px] w-[40px] flex justify-center items-center
            ${isSelected ? 'hover:bg-gradient bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 text-white border-none hover:text-white hover:scale-75' : 'hover:bg-gradient bg-gradient-to-b from-orange-500 via-orange-500 text-transparent bg-clip-text hover:text-orange-500 hover:scale-75'}
            ${isToday ? '' : 'hover:bg-gradient bg-gradient-to-b from-orange-500 via-orange-500'}
          `}
        >
          {day}
        </div>
      );
    });
  };


  useEffect(() => {
    fetchUserDetails(setCurrency, setCurrencyCode, setIsIndia,setFetched);
    const Empty = upcomingDates.every((dates) => dates.length === 0);
    SetEmpty(Empty)
    console.log(isIndia,fetched)
  }, [loggedInUserId,upcomingDates,fetched]);

  // FUNCTION TO SET START AND END TIME

  const handleSetTimes = () => {
    const newHour = `${startTime} - ${endTime}`;
    setBookedHours([...bookedHours, { Hours: newHour }]);
    //console.log('Selected Start Time:', startTime);
    //console.log('Selected End Time:', endTime);
    setOpen(!open);
  };

  // MODEL OPEN FUNCTION

  const openModel = () => {
    setOpen(!open);
    setIsChecked(false)

  };

  // FUNCTION TO REMOVE THE SELECTED TIME

  const handleRemoveHour = (indexToRemove) => {
    setBookedHours((prevHours) =>
      prevHours.filter((_, index) => index !== indexToRemove)
    );
  };

  // BACK NAVIGATION

  const handleBack = () => {
    // Handle back navigation
    navigate('/calender_page')
  };



  // const handleWeek=()=>{
  //   console.log("clicked")
  // }

  

  //const date='2024-04-25'

  // User current Location 
  


  

  // Function to get currency symbol based on country code
  // useEffect(() => {
    
  
  //   const getLocationAndFetchCurrency = () => {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(async (position) => {
  //         const { latitude, longitude } = position.coords;
  //         try {
  //           const response = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`);
  //           const data = await response.json();
  //           setCountry(data.countryCode);
  
  //           const currencyCode = await getParamByISO(data.countryCode, 'symbol');
  //           setCurrency(currencyCode);
  //         } catch (error) {
  //           console.error('Error:', error);
  //         }
  //       }, (err) => {
  //         console.error('Geolocation error:', err);
  //       });
  //     } else {
  //       console.log('Geolocation is not supported by this browser.');
  //     }
  //   };
  
  //   getLocationAndFetchCurrency();
  // }, []); 
  
//const date='2024-04-22';

// SAVE CALL DETAILS TO THE BACKEND

const handleDone = async (req, res) => {

  const createSlot = async (slotData) => {
    const slotResponse = await axios.post(`${apiUrl}/api/calender/create_slot`, slotData, {
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (slotResponse.status >= 200 && slotResponse.status < 300) {
      return slotResponse.data.newTimeSlot;
    } else {
      throw new Error('Failed to create slot');
    }
  };

  const createCall = async (callData) => {
    const callResponse = await axios.post(`${apiUrl}/api/call/create_call`, callData, {
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (callResponse.status >= 200 && callResponse.status < 300) {
      return callResponse.data.newCall;
    } else {
      throw new Error('Failed to create call');
    }
  };

  const createCallsForDates = async (data) => {
    const callResponse = await axios.post(`${apiUrl}/api/call/create_call_multiple_dates`, data, {
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (callResponse.status >= 200 && callResponse.status < 300) {
      return callResponse.data.newCalls;
    } else {
      throw new Error('Failed to create calls');
    }
  };

  try {
    setLoading(true)
    let slotData;
    if (isChecked) {
      const flattenedDates = upcomingDates.flat().filter(date => date);
      slotData = {
        dates: flattenedDates,
        start_time: startTime,
        end_time: endTime,
        status: 'available',
        currency: currency,
        amount: selectedPrice,
        currencyCode:currencyCode,
        paypal_fee:paypal_fee,
        url,
      };

      const newSlots = await Promise.all(flattenedDates.map(date => createSlot({
        date,
        start_time: startTime,
        end_time: endTime,
        status: 'available',
        currency,
        amount: selectedPrice,
        currencyCode,
        paypal_fee:paypal_fee,
        url,
      })));
      //const created_slot=newSlots.data.newTimeSlot
      const slotIds = newSlots.map(slot => slot._id);
      const callData = { dates: flattenedDates, start_time: startTime, end_time: endTime, status: 'available', currency, amount: selectedPrice, currencyCode, url, paypal_fee, slotIds };
      await createCallsForDates(callData);

    } else {
      slotData = {
        date,
        start_time: startTime,
        end_time: endTime,
        status: 'available',
        currency: currency,
        amount: selectedPrice,
        currencyCode:currencyCode,
        paypal_fee:paypal_fee,
        url,
      };

      const newSlot = await createSlot(slotData);

      const callData = { date, start_time: startTime, end_time: endTime, status: 'available', currency, amount: selectedPrice, currencyCode, url, slotId: newSlot._id, paypal_fee };
      await createCall(callData);
    }
    navigate('/calender_page'); 
    setShowMessage(true);
    setMessage(`Call Slot [${startTime} - ${endTime}] Successfully Created on ${isChecked ? 'selected dates' : date}`);
    
  } catch (error) {
    setShowMessage(true);
    setMessage(error.response ? error.response.data.error : error.message);
    console.error('Error:', error);
  } finally {
    setLoading(false);
  }
};




// FUNCTION TO SET PRICE

const handlePriceChange = (value) => {

  setSelectedPrice(value);
};

// FUNCTION TO SET URL

const [url,setUrl]=useState('');
const handleUrlChange = (e) =>{
  setUrl(e.target.value);
}

// FUNCTION TO MAINTAIN THE STATE OF THE CHEKCBOX

const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
    setOpen(true)
    
  }

  const toggleDurationDropdown = () => {
    setIsOpenDuration(!isOpenDuration)
  }


  // BASED ON DURATION  AND START TIME CHANGE THE END TIME

  const handleDurationSelection = (duration) => {
    setSelectDuration(duration);
    setIsOpenDuration(false);
    setEndTime(calculateEndTime(startTime, duration));
  }

  // DURATION SELECTION

  function calculateEndTime(startTime, duration) {
    const parsedStartTime = parse(startTime, 'HH:mm', new Date());

    let endTime = new Date(parsedStartTime);

    switch (duration) {
      case '1 Hr':
        endTime = addHours(endTime, 1);
        break;
      case '30 Min':
        endTime = addMinutes(endTime, 30);
        break;
      
      default:
        break;
    }

    return format(endTime, 'HH:mm');
  };

  useEffect(() => {
    setEndTime(calculateEndTime(startTime, selectedDuration));
  }, [startTime, selectedDuration]);


  const currencyOptions = [
   
    { symbol: 'A$', code: 'AUD' , paypal_fee:0.30 }, // Australian Dollar
    { symbol: 'C$', code: 'CAD',paypal_fee:0.30 }, // Canadian Dollar
    { symbol: '€', code: 'EUR',paypal_fee:0.35 }, // European Euro
    { symbol: '£', code: 'GBP',paypal_fee:0.20 }, // Pound Sterling
    { symbol: '¥', code: 'JPY',paypal_fee:40.00  }, // Japanese Yen
    { symbol: '₽', code: 'RUB',paypal_fee:10.00 },  // Russian ruble
    { symbol: 'S$', code: 'SGD',paypal_fee:0.50  }, // Singapore Dollar
    { symbol: '$', code: 'USD',paypal_fee:0.30 }, // United States Dollar
   
];


  const handleCurrency = (symbol, code,paypal_fee) => {
    setCurrency(symbol);
    setCurrencyCode(code);
    setIsOpenCurrency(false);
    setPaypal_fee(paypal_fee)
  };


  const toggleDropdown = () => {
    setIsOpenCurrency(!isOpenCurrency);
  };
  
  const formatTo24Hour = (time) => {
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    // Ensuring hours and minutes are two digits
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  const handleTimeChange = (e) => {
    const time = e.target.value; // Time is in HH:mm format
    setStartTime(formatTo24Hour(time)); // Ensure it's in 24-hour format
  };

  

  const handleSubscription = () =>{
    navigate('/subscription')
  }

  if(pageloading){
    return <Loading2/>
  }




  // if(error){
  //   return(
  //     <div className="flex flex-col justify-center items-center min-h-screen gap-3">
  //       <div className=' text-lg'>Please Subscribe to create Call</div>
  //       <div className=' text-lg'>Click Upgrade to Premium</div>
  //       <div className='flex justify-center items-center gap-4'>
  //         <div onClick={handleBack} className='bg-slate-300 rounded-full py-2 px-6 cursor-pointer'>Back</div>
  //         <div className="flex items-center gap-2 justify-center px-3 sm:px-5 py-1 sm:py-2 rounded-full bg-gradient text-white cursor-pointer" onClick={handleSubscription}>
  //           <FaCheckCircle className="text-white" />
  //           <span className="text-md sm:text-lg">Upgrade to premium</span>
  //         </div>
  //       </div>
        
  //     </div>
  //   )
  // }
  
  return (

    <div className=''>

      {/* ----------------------- LOGO WITH DESIGN ---------------------- */}

      <div
          className=" sm:hidden bg-contain bg-no-repeat w-full h-[280px] sm:mx-auto sm:w-full sm:max-w-md max-[420px]:bg-cover flex justify-center"
          style={{backgroundImage: `url("${bg}")`}}
        >
          <div className='mt-14'>
            <img src={logo} alt="logo" />
          </div>
        </div>

        <NavBar />

      <div className='sm:mt-20 lg:mt-14 lg:flex lg:justify-center'>

        {/* --------------------------------- SETTING  AVAILABILITY FOR CALLS ------------------------------- */}

        <div className='sm:border border-orange-500 rounded-[50px] sm:max-w-[746px] sm:mx-auto sm:w-full px-3 sm:px-6 sm:py-6 sm:mt-14 mb-10'>
          <div className='flex gap-1 items-center mt-[-30px] sm:mt-0 mb-5'>

            {/* --------------------- BACK NAVIGATION ----------------------------- */}
            <div className='flex justify-between w-full'>
              <div className='flex gap-2 cursor-pointer ' onClick={handleBack}>
                <BsChevronLeft className='text-[#AFAFAF] text-2xl  '/>
                <span className='text-[#AFAFAF] font-semibold text-lg  '>Back</span>
              </div>
              <div className='text-orange-400 sm:text-[19px] font-bold     '>
                <span>Availability for Calls</span>
              </div>
              <div className='text-[#AFAFAF] text-md font-semibold '>
                {date}
              </div>
            </div>
            
          </div>
          <div  className="h-fit w-full lg:h-max overflow-scroll   shadow-md rounded-3xl flex flex-col gap-5 items-center mt-5 overflow-y-auto max-h-transition">
            <div className='flex flex-col sm:flex-col-reverse lg:flex-col'>
              < div className="flex flex-row cursor-pointer mb-8  mt-4 shadow-[0_3px_8px_rgba(0,0,0,0.24)] lg:shadow-none " style={{ gap: '10px', padding: '10px 20px', borderRadius: '30px', }}>
              {renderDays()}
                </div> 
              <div className="flex lg:flex-row flex-col md:flex-col justify-center items-center gap-3 mt-4 mb-8">
                <AiOutlineClockCircle style={{ color: 'orange', fontSize: '45px' }} />
                <div className="bg-gradient text-white px-8 py-[7px] text-xl rounded-full flex justify-center items-center">Available hours</div>
              </div>

            </div>
            

            {/* ----------------------------- USE SAME HOURS FUNCTIONALITY TOGGLE --------------------------------*/}

            <div class="flex  justify-between  items-center p-2 rounded-full gap-5 mb-2 lg:w-[500px] w-[360px]" style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
              <span class="text-sm font-semibold text-gray-600 ml-2">Use same hours for all days</span>
              <label className='themeSwitcherTwo relative inline-flex cursor-pointer select-none items-center'>
                <input
                  type='checkbox'
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  className='sr-only mr-2'
                  
                />
        
                <span
                  className={`slider mx-4 flex h-8 w-[60px] items-center rounded-full p-1 duration-200 ${
                  isChecked ? 'bg-orange-500' : 'bg-[#efd9b2]'
                }`}>
                  <span
                    className={`dot h-6 w-6 rounded-full bg-white  duration-200 ${
                    isChecked ? 'translate-x-[28px] ' : 'bg-orange-500'
                    }`}>
                  </span>
                </span>
        
              </label>
            </div>
            {isChecked || !allEmpty ||bookedHours.length >=1 ?(''):(<div className='mt-4  flex gap-5 justify-center items-center'>
              <div className='text-sm font-semibold text-gray-600'>Set Availability</div>
              <AiOutlinePlus style={{ color: 'orange', fontSize: '1.2rem' }} className='cursor-pointer' onClick={openModel} />
            </div>)}
            


            {/* ----------------------- SELECTED TIME SLOTS MEANS START TIME AND END TIME ----------------- */}

            <div className="flex flex-col  mt-3 w-[400px]" >
              {bookedHours.map((hour, index) => (
                  <div key={index} className='flex-col justify-around items-center mb-5'>
                    <div className='flex justify-center gap-20 items-center'>
                      <div className='flex  gap-10 justify-center items-center ml-8'>
                      <div>Hours</div>
                      <div className="flex items-center" style={{ gap: '1rem' }}>
                          <div className="time-custom">{hour.Hours}</div>
                          <CiCircleRemove  className='cursor-pointer text-orange-500 text-xl' onClick={() => handleRemoveHour(index)}/>
                      </div>
                      </div>
                      </div>
                      <div>
                      <div className='flex  justify-center items-center gap-2 mt-5 mb-5'>
                        <div className='w-40 h-12 rounded-full ml-2 mt-5 px-4 py-3 text-center' style={{boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}>
                          Set Price
                        </div>
                      <div>
                      {/* <div className="relative inline-block ml-2">
                      <div className="bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 text-center py-3 rounded-full text-white w-32 h-12 mt-5 flex justify-center items-center cursor-pointer  ">
                        <div className='text-white px-2'>{currency}</div>
                          <input
                            type="number"
                            className="bg-transparent text-white text-center w-7 placeholder-white"
                            placeholder="- - -"
                            value={selectedPrice.replace(/[^\d.-]/g, '')} 
                            onChange={(e) => handlePriceChange(e.target.value)}
                          />  
                        </div>
                      </div> */}
                      {fetched ?(
                      <div className="flex items-center mt-5">
                    {/* Currency Selection */}
                    <div className="relative inline-block mr-2">
                      <div
                        className={`bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 text-center py-3 rounded-full text-white w-32 h-12 flex justify-center items-center gap-10 cursor-pointer ${isIndia ? 'cursor-not-allowed' : ''}`}
                        onClick={toggleDropdown}
                        disabled={isIndia}
                      >
                        {currency} {!isIndia && <IoIosArrowDown className={`transform ${isOpenCurrency ? 'rotate-180' : ''} transition-transform duration-200`} />}
                      </div>
                      {isOpenCurrency && !isIndia && (
                        <div className="absolute right-0 mt-1 w-32 bg-white border rounded-lg shadow-lg">
                          {currencyOptions.map((cur) => (
                            <div
                              key={cur.code}
                              className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                              onClick={() => handleCurrency(cur.symbol, cur.code,cur.paypal_fee)}
                            >
                              {cur.symbol} {cur.code}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    {/* Amount Entering */}
                    <div className="inline-block">
                      <div className="bg-white text-center py-3 rounded-full text-gray-800 w-32 h-12 flex justify-center items-center"
                        style={{boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}
                      >
                        <span className="text-gray-800 px-2">{currency}</span>
                        <input
                          type="number"
                          className="bg-transparent text-gray-800 text-center w-16 placeholder-gray-500 price-input"
                          placeholder="Amount"
                          value={selectedPrice.replace(/[^\d.-]/g, '')}
                          onChange={(e) => handlePriceChange(e.target.value)}
                          onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                        />
                      </div>
                    </div>
                   </div>):('')}
                    </div> 
                  </div>

                {/* ------------------ URL ADDING PART ----------------- */}  
                         
                <div className='' >
                <textarea  placeholder='URL' onChange={handleUrlChange} className='ml-2 mb-2 mt-10 rounded-3xl border-2 border-orange-600 p-2 w-full focus:outline-none'></textarea>  
                </div>
                  </div>
                </div>
              ))}
          </div>
          {/* ----------------------------- AVAILABILITY PAGE FUNCTIONALITY ---------------------------- */}

          <div className="lg:hidden mb-5">
            <div className="flex" style={{ gap: '2rem' }}>
            <div className='border-2 border-orange-600 rounded-3xl py-2 px-12 bg-transparent text-orange-500 font-semibold  hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 cursor-pointer  flex justify-center gap-2 items-center shadow-lg  ' onClick={handleBack}>Cancel</div>
              <div className='border-2 border-orange-600 rounded-3xl py-2 px-12 bg-transparent text-orange-500 font-semibold  hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 cursor-pointer  flex justify-center gap-2 items-center shadow-lg ' onClick={handleDone}>Done</div>
            </div>
            
          </div>  

            
          {/* ------------------------------- MODEL TO SLECTE START TIME AND END TIME ------------------------- */}

          {open && (
          <div className='fixed inset-0 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg z-10 flex justify-center items-center'>
            <div className="w-[420px] h-[400px] shadow-lg rounded-3xl pl-3 py-10 mt-2  flex flex-col justify-center items-center ">

              {/* -------------------------------- SELECT DURATION----------------------------------------- */}

              <div className='flex items-center sm:justify-center gap-5 mb-2'>
                <div className=' w-56 h-12 rounded-full ml-2 sm:mt-2 px-4 py-3 bg-white text-center ' style={{boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}>
                    Duration
                </div>
                <div>
                  <div className="relative inline-block">
                    <div
                      className="bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 text-center py-3 rounded-full text-white w-32 h-12 mt-3 flex justify-center items-center gap-3 cursor-pointer"
                      onClick={toggleDurationDropdown}
                      >
                      {selectedDuration} <IoIosArrowDown
                          className={`transform ${isOpenDuration ? 'rotate-180' : ''} transition-transform duration-200` }
                          />
                    </div>
                    {isOpenDuration && (
                      <div className="absolute  mt-1 w-32 bg-white border rounded-lg shadow-lg z-10">
                        <div
                          className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleDurationSelection('30 Min')}
                          >
                            30 Min
                        </div>
                        <div
                          className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                          onClick={() => handleDurationSelection('1 Hr')}
                          >
                            1 Hr
                        </div>
                        
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* ------------------------- SELECT START TIME ----------------------- */}

              <div className='flex  gap-3'>
                <div className='flex flex-col'>
                <div class="text-sm sm:text-[16px] px-5 py-4 sm:px-[20px] sm:py-[23px] border border-orange-500 rounded-[25px]">
                  <p class="text-[#585858 text-center">Start Time</p>
                  
                  <input
                  type="time"
                  className='bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 py-1 px-3 text-center rounded-full text-white mt-2 input-time'
                  value={startTime}
                  onChange={handleTimeChange}

                />
                  
                </div>
                   
                   
                </div> 

                  {/* ------------------- BASED ON DURATION AND SELECTED START TIME END TIME WILL BE CALCULATED -------------------- */}

                <div class="text-sm sm:text-[16px] px-5 py-4 sm:px-[25px] sm:py-[22px] border border-orange-500 rounded-[25px]">
                  <p class="text-[#585858 text-center">End Time</p>
                  
                  <div
                    class="bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 py-[6px] px-7 rounded-full  mt-2">
                    {endTime}
                  </div>
                  
                </div>
                
              </div> 
              <div className='bg-gray-300 w-80 text-center mt-5 mb-5 rounded-full p-2'>
                      Added {startTime} - {endTime}
              </div>
              <div className='bg-gradient-to-b from-orange-500 via-orange-400 to-yellow-400 py-2 px-20 rounded-full w-80 text-center text-white cursor-pointer' onClick={handleSetTimes}>
                Set these times
              </div>
              <div className='bg-gradient-to-b from-orange-500 via-orange-400 to-yellow-400 py-2 px-20 rounded-full text-center text-white cursor-pointer mt-4 w-80'>
                <span className=' cursor-pointer' style={{ left: '40%' }} onClick={openModel}>Cancel</span>
              </div>
            </div>
          </div>
          )}
        </div>

          {/* ------------------------ AVAILABILITY PAGE FUNCTIONALITY ------------------------- */}

          <div className="hidden  sm:max-w-[746px] lg:block mt-5">
            <div className="flex justify-center" style={{ gap: '2rem' }}>
              <div className='border-2 border-orange-600 rounded-3xl py-2 px-12 bg-transparent text-orange-500 font-semibold  hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 cursor-pointer  flex justify-center gap-2 items-center shadow-lg  ' onClick={handleBack}>Cancel</div>
              <div className='border-2 border-orange-600 rounded-3xl py-2 px-12 bg-transparent text-orange-500 font-semibold  hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 cursor-pointer  flex justify-center gap-2 items-center shadow-lg ' onClick={handleDone}>
                {loading ?
                  <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-white" role="status">
                  </div> : 'Done'
                }
              </div>
            </div>
            
          </div>
        </div>
      </div>

      {/* -------------------------- BOTTOM MENU ----------------------------- */}

      <div className='flex justify-center'>
        <MainMenu />
      </div>
      
    </div>


  );
};

export default Availability;