// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import Cookies from "js-cookie";
// import { useSocket } from '../context/SocketContext';
// import { useNavigate } from 'react-router-dom'; // Import useHistory

// const apiUrl = process.env.REACT_APP_API_URL;

// const Notifications = () => {
//   const [notifications, setNotifications] = useState([]);
//   const [unreadCount, setUnreadCount] = useState(0);
//   const authToken = Cookies.get("authToken");
//   const loggedInUserId = Cookies.get('loggedInUserId');
//   const [chatNotifications, setChatNotifications] = useState([]);
//   const { socket } = useSocket();
//   const history = useNavigate();

//   useEffect(() => {
//     const fetchNotifications = async () => {
//       try {
//         const response = await axios.get(`${apiUrl}/api/notification/${loggedInUserId}/notifications`);
//         const sortedNotifications = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
//         setNotifications(sortedNotifications);
//         const chatNotif = response.data.filter(notif => notif.type === 'chat');
//         setChatNotifications(chatNotif);
//         setUnreadCount(response.data.filter(notif => !notif.read).length);
//       } catch (error) {
//         console.error('Error fetching notifications:', error);
//       }
//     };

//     fetchNotifications();

//     if (socket) {
//       socket.on('newForumNotification', (notification) => {
//         setNotifications(prevNotifications => [notification, ...prevNotifications]);
//         setUnreadCount(prevCount => prevCount + 1);
//         fetchNotifications();
//       });

//       socket.on('newMessage', (notification) => {
//         setNotifications(prevNotifications => [notification, ...prevNotifications]);
//         setUnreadCount(prevCount => prevCount + 1);
//         fetchNotifications();
//       });

//       socket.on('newConferenceBooking', (notification) => {
//         setNotifications(prevNotifications => [notification, ...prevNotifications]);
//         setUnreadCount(prevCount => prevCount + 1);
//         fetchNotifications();
//       });

//       socket.on('newCallBooking', (notification) => {
//         setNotifications(prevNotifications => [notification, ...prevNotifications]);
//         setUnreadCount(prevCount => prevCount + 1);
//         fetchNotifications();
//       });
//       socket.on('markChatNotificationsAsRead', async(chatUserId) =>{
//         try {
//           console.log("Watching...")
//           const chatNotifToMarkAsRead = chatNotifications.filter(notif => notif.receiverId === chatUserId && !notif.read);
//           await Promise.all(chatNotifToMarkAsRead.map(notif => axios.put(`${apiUrl}/api/notification/${chatUserId}/notifications/${notif._id}/read`)));
//           setChatNotifications(prevNotifications => prevNotifications.map(notif => {
//             if (notif.receiverId === chatUserId) {
//               return { ...notif, read: true };
//             }
//             return notif;
//           }));
//           setUnreadCount(prevCount => prevCount - chatNotifToMarkAsRead.length);
//         } catch (error) {
//           console.error('Error marking chat notifications as read:', error);
//         }
//       })

//       return () => {
//         if (socket) {
//           socket.off('newForumNotification');
//           socket.off('newMessage');
//           socket.off('newConferenceBooking');
//           socket.off('newCallBooking');
//           socket.off('markChatNotificationsAsRead');
//         }
//       };
//     }
//   }, [loggedInUserId, socket]);

//   const markAsRead = async (notificationId) => {
//     try {
//       await axios.put(`${apiUrl}/api/notification/${loggedInUserId}/notifications/${notificationId}/read`);
//       setNotifications(prevNotifications => prevNotifications.map(notification => {
//         if (notification._id === notificationId) {
//           return { ...notification, read: true };
//         }
//         return notification;
//       }));
//       setUnreadCount(prevCount => prevCount - 1);
//     } catch (error) {
//       console.error('Error marking notification as read:', error);
//     }
//   };

//   const handleForumNotificationClick = (notificationId) => {
//     markAsRead(notificationId);
//     history('/forum');
//   };

//   const handleConference = async (notificationId, notification) => {
//     markAsRead(notificationId);
//     try {
//       const response = await axios.post(`${apiUrl}/api/conference/getdetails`, { conference_id: notification.conferenceId }, {
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${authToken}`,
//         },
//       });
//       const card = response.data.conferenceData;
//       history('/conferece_details', { state: { card } });
//     } catch (e) {
//       console.log(e);
//     }
//   };

//   const handleNotificationClick = (notification) => {
//     if (notification.type === 'chat') {
//       markAsRead(notification._id);
//     } else if (notification.type === 'forum') {
//       handleForumNotificationClick(notification._id);
//     } else if (notification.type === 'conference') {
//       handleConference(notification._id, notification);
//     }
//   };

//   return (
//     <div>
//       <h3>Notifications ({unreadCount})</h3>
//       <ul>
//         {notifications.map((notification, index) => (
//           <li key={index} className="cursor-pointer" onClick={() => handleNotificationClick(notification)}>
//             {notification.message}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default Notifications;
// Notifications.js

import React, { useContext } from 'react';
import axios from 'axios';
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { ChatContext } from '../App';
import { useNotifications } from '../context/NotificationContext';
import { FaTimes } from 'react-icons/fa';
import { IoIosNotifications } from "react-icons/io";

const Notifications = ({ setShowNotifications }) => {
  const { notifications, unreadCount, markAsRead, setForumPostId } = useNotifications();
  const isValidDate = (date) => !isNaN(new Date(date).getTime());
  const history = useNavigate();
  const authToken = Cookies.get("authToken");
  const apiUrl = process.env.REACT_APP_API_URL;
  const { setOtherUserID } = useContext(ChatContext);
  // console.log("notification", notifications)

  const handleNotificationClick = async (notification) => {
    if (notification.type === 'chat') {
      setOtherUserID(notification.senderId);
      localStorage.setItem('otheruserID',notification.senderId); 
      await markAsRead(notification._id);
      setShowNotifications(false)
      history(`/open-chat`);
    } else if (notification.type === 'forum') {
      await markAsRead(notification._id);
      setForumPostId(notification.postId);
      // console.log("notification", notification)
      // console.log("post Id", notification.postId)
      setShowNotifications(false)
      history('/forum');
    } else if (notification.type === 'conference') {
      await markAsRead(notification._id);
      try {
        const response = await axios.post(`${apiUrl}/api/conference/getdetails`, { conference_id: notification.conferenceId }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
        });
        const card = response.data.conferenceData;
        setShowNotifications(false)
        history('/conferece_details', { state: { card } });
      } catch (error) {
        console.error('Error fetching conference details:', error);
      }
    } else {
      setShowNotifications(false)
      history(`/user_events`)
      await markAsRead(notification._id);
    }
  };

  const handleClose = () => {

    setShowNotifications(false);
  };

  return (
    <div className="fixed top-20 right-4 max-w-[500px] max-h-[600px] bg-white border border-gray-300 shadow-lg rounded-lg overflow-hidden z-50 animate-slideDown">
      <div className="flex justify-between items-center bg-gradient text-white px-4 py-2">
        <h2 className="text-lg font-semibold">Notifications</h2>
        <button
          onClick={handleClose}
          className="text-white hover:text-gray-300"
          aria-label="Close"
        >
          <FaTimes />
        </button>
      </div>
      <ul className="h-screen overflow-y-auto">
        {notifications.map((notification) => (
          <li
            key={notification.id}
            className={`px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center rounded-md mt-4 mb-4 ml-2 mr-2 h-[100px] ${notification.read ? 'bg-white' : 'bg-gray-200'
              }`}
            onClick={() => handleNotificationClick(notification)}
            style={{ boxShadow: '0px 2px 6px 0px #00000040' }}
          >
            <IoIosNotifications className="text-orange-500 text-xl mr-4" />
            <div className='flex flex-col gap-3 justify-center items-start'>
              {/* <p>{notification.createdAt}</p> */}
              <p className="font-semibold">{notification.title}</p>
              <p className="text-sm text-gray-600">{notification.message}</p>
              <p className="text-xs text-gray-500"> {new Date(notification.createdAt).toLocaleString()}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Notifications;

