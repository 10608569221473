import React, { useState } from 'react';
import './calender.css'
import UpcomingEventsConference from './Events/upcoming_events_conference.jsx';
import FinishedEventsConference from './Events/finished_events_conference.jsx';
import NavBar from '../nav/navBar.jsx'
import UpcomingEventsCall from './Events/upcoming_events_call.jsx';
import FinishedEventsCall from './Events/finished_events_call.jsx'
import MainMenu from '../MenuBottom/MainMenu.jsx';
import moment from 'moment-timezone';
import Header from '../headerBackground.jsx';
import UpcomingEventsCourses from './Events/upcoming_event_course.jsx';
import FinishedEventsCourses from './Events/finished_event_course.jsx';
import OngoingEventsCourses from './Events/ongoing_event_course.jsx';

import MetaDecorator from '../../utils/MetaDecorator.jsx';


const Conference_Call_Events = () => {
  const [activeTab, setActiveTab] = useState('Call'); // Default active tab for Call or Conference
  const [subTab, setSubTab] = useState('Upcoming'); // Default sub-tab for Upcoming or Finished

  // Handler to change the active tab (Call or Conference)
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setSubTab('Upcoming'); // Reset sub-tab to Upcoming when changing the main tab
  };

  // Handler to change the sub-tab (Upcoming or Finished)
  const handleSubTabClick = (subTabName) => {
    setSubTab(subTabName);
  };

  
  const renderComponent = () => {
    switch (activeTab) {
      case 'Call':
        return subTab === 'Upcoming' ? <UpcomingEventsCall /> : <FinishedEventsCall />;
      case 'Conference':
        return subTab === 'Upcoming' ? <UpcomingEventsConference /> : <FinishedEventsConference />;
      case 'Course':
        switch (subTab) {
          case 'Upcoming':
            return <UpcomingEventsCourses />;
          case 'Ongoing':
            return <OngoingEventsCourses />;
          case 'Finished':
            return <FinishedEventsCourses />;
          default:
            return null;
        }    
      default:
        return null; // or a default component
    }
  };
  const keywords = ["elearning", "education", "online class"];
  return (
    <>
      {/* <Helmet>
        <title>Upcoming Events - Career Guidance Buddy</title>
        <meta name="keywords" content="education fourm,search teacher,search student, education, online class,e-learning" />
      </Helmet> */}
      <MetaDecorator title={'Upcoming Events - Career Guidance Buddy'} description={''} imageUrl={''} keywords={keywords} imageAlt={''} url={''} />
      <section className="mb-10 sm:mb-0 font-primary">
        <NavBar />
        <div className='sm:my-14 lg:mt-16 mt-0 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5'>
          <Header />
          <div className="overflow-y-auto lg:grid grid-cols-12 mt-44 sm:mt-28 gap-8 sm:min-h-[85vh] justify-center" style={{ maxHeight: 'calc(100vh - 200px)' }}>

            <div className="lg:col-span-12">
              <div className='sm:flex sm:flex-col sm:items-center gap-4 sm:gap-2'>
                <span className='hidden sm:font-medium sm:text-2xl sm:text-center sm:text-gradient'>Upcoming Events</span>
                <div className="flex items-center justify-center px-9 sm:mb-2 gap-4 w-full">
                  <div
                    className={`border-2 border-orange-600 rounded-[30px] sm:w-full text-center text-sm md:text-lg font-semibold py-2 px-6 bg-transparent text-orange-500 hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-transparent cursor-pointer w-1/2 sm:max-w-[232px] flex justify-center shadow-lg ${activeTab === 'Call' ? 'text-white bg-gradient border-none' : 'border-2'}`}
                    onClick={() => handleTabClick('Call')}
                    style={activeTab === 'Call' ? { color: 'white' } : {}}>
                    <span className='font-medium text-sm lg:text-md'>Calls</span>
                  </div>
                  <div
                    className={`border-2 border-orange-600 rounded-[30px] sm:w-full text-center text-sm md:text-lg font-semibold py-2 px-6 bg-transparent text-orange-500 hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-transparent  cursor-pointer w-1/2 sm:max-w-[232px] flex justify-center shadow-lg ${activeTab === 'Conference' ? 'text-white bg-gradient border-none' : 'border-2'}`}
                    onClick={() => handleTabClick('Conference')}
                    style={activeTab === 'Conference' ? { color: 'white' } : {}}
                  >
                    <span className='font-medium text-sm lg:text-md'>Conferences</span>
                  </div>
                  <div
                    className={`border-2 border-orange-600 rounded-[30px] sm:w-full text-center text-sm md:text-lg font-semibold py-2 px-6 bg-transparent text-orange-500 hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-transparent  cursor-pointer w-1/2 sm:max-w-[232px] flex justify-center shadow-lg ${activeTab === 'Course' ? 'text-white bg-gradient border-none' : 'border-2'}`}
                    onClick={() => handleTabClick('Course')}
                    style={activeTab === 'Course' ? { color: 'white' } : {}}
                  >
                    <span className='font-medium text-sm lg:text-md'>Course</span>
                  </div>
                </div>
                <div className='flex flex-row gap-4 items-center justify-center px-[26px] pt-[10px] pb-[15px] w-full'>
                  <div
                    className={`border-2 rounded-3xl py-1 px-6 cursor-pointer text-[11px] sm:text-sm md:text-lg font-semibold px-2 sm:px-6  py-1 sm:max-w-[232px] sm:w-full text-center ${subTab === 'Upcoming'
                      ? 'text-white bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] border-0'
                      : 'border-orange-600 bg-transparent text-orange-500 hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0'
                      }`}
                    onClick={() => handleSubTabClick('Upcoming')}>
                    <span className='font-medium text-sm lg:text-md'>Upcoming</span>
                  </div>
                  {activeTab === 'Course'?(
                    <div
                    className={`border-2 rounded-3xl py-1 px-6 cursor-pointer text-[11px] sm:text-sm md:text-lg font-semibold px-2 sm:px-6  py-1 sm:max-w-[232px] sm:w-full text-center ${subTab === 'Ongoing'
                      ? 'text-white bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] border-0'
                      : 'border-orange-600 bg-transparent text-orange-500 hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0'
                      }`}
                    onClick={() => handleSubTabClick('Ongoing')}>
                    <span className='font-medium text-sm lg:text-md'>Ongoing</span>
                  </div>
                  ):('')}
                  <div
                    className={`border-2 rounded-3xl py-1 px-6 cursor-pointer text-[11px] sm:text-sm md:text-lg font-semibold px-2 sm:px-6  py-1 sm:max-w-[232px] sm:w-full text-center ${subTab === 'Finished'
                      ? 'text-white bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] border-0'
                      : 'border-orange-600 bg-transparent text-orange-500 hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0'
                      }`}
                    onClick={() => handleSubTabClick('Finished')}>
                    <span className='font-medium text-sm lg:text-md'>Finished</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 mx-4 lg:col-span-12">
              <div className="">
                <div className=''>
                  {renderComponent()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-center'>
          <MainMenu />
        </div>
      </section>
    </>
  );
}

export default Conference_Call_Events;
