// import { useState, useEffect } from "react";
// import { useNavigate, Link } from "react-router-dom";
// import Cookies from "js-cookie";
// import axios from "axios";
// import MainMenu from "../components/MenuBottom/MainMenu";
// import ProfileModal from "../Modals/ProfileModal";
// import bg from "../assets/img/background-3.png";
// import logo from "../assets/img/logo.svg";
// import { IoMdSearch } from "react-icons/io";
// import { MdEmojiEmotions } from "react-icons/md";
// import NavBar from "../components/nav/navBar";
// import Header from "../components/headerBackground";
// const apiUrl = process.env.REACT_APP_API_URL;

// const Search = ({ fullName, profilePic }) => {
//   let initialSearchData = {
//     fullName: "",
//     about: "",
//     country: "",
//     city: "",
//     category: "",
//     subCategory: "",
//     interest_category: "",
//     interest_subCategory: "",
//   };

//   let initialProfileData = {
//     display: "none",
//     profilePic: "",
//     fullName: "",
//     email: "",
//     about: "",
//   };

//   const [searchTerm, setSearchTerm] = useState(initialSearchData);
//   const [users, setUsers] = useState([]);
//   const [categories, set_categories] = useState([]);
//   const [subCategories, set_subCategories] = useState([]);
//   const [countries, set_countries] = useState([]);
//   const loggedInUserId = Cookies.get('loggedInUserId');
//   const [loggedInUser, setLoggedInUser] = useState("");
//   const [profileData, setProfileData] = useState(initialProfileData);
//   const file_url = 'https://cgb-storage.blr1.digitaloceanspaces.com';
//   let defaultProfile = `${file_url}/profile-pictures/defaultProfile.png`;

//   let authToken = Cookies.get("authToken");
//   let user_type = Cookies.get("user_type");
//   let search = user_type === "Student" ? "Teacher" : "Student";

//   const getLoggedInUserDetails = async () => {
//     try {
//       const response = await axios.get(`${apiUrl}/api/user-info/get-users/${loggedInUserId}`, {
//         headers: {
//           "Authorization": `Bearer ${authToken}`
//         }
//       });
//       setLoggedInUser(response.data);
//     } catch (error) {
//       console.log('Error fetching user', error);
//     }
//   }

//   useEffect(() => {
//     getLoggedInUserDetails();
//   }, [authToken, loggedInUserId]);

//   useEffect(() => {
//     get_categories();
//     get_countries();
//   }, []);

//   async function get_categories() {
//     let response = await fetch(`${apiUrl}/api/categories/getAllCategory`);
//     let data = await response.json();

//     let categories = [];
//     for (let c of data) categories.push(c.name);

//     set_categories(categories);
//   }

//   async function get_subCategories(category) {
//     try {
//       let response = await axios.post(
//         `${apiUrl}/api/subCategories/getParticularSubCategory`,
//         { category_name: category }
//       );
//       let data = response.data;
//       let subcategories = [];

//       for (let sc of data) subcategories.push(sc.name);

//       set_subCategories(subcategories);
//     } catch (err) {
//       console.log(err);
//     }
//   }

//   async function get_countries() {
//     let response = await fetch(`${apiUrl}/api/${search}/getAllCountry`);
//     let countries = await response.json();
//     set_countries(countries);
//   }

//   const category = categories.map((category) => (
//     <option className="w-[100%]" value={category}>
//       {category}
//     </option>
//   ));

//   const subCategory = subCategories.map((subCategory) => (
//     <option className="w-[100%]" value={subCategory}>
//       {subCategory}
//     </option>
//   ));

//   const country = countries.map((country) => (
//     <option className="w-[100%]" value={country}>
//       {country}
//     </option>
//   ));

//   const handleUser = (e) => {
//     console.log("Click on user");
//   }

//   const suggestedUsers = users.map((user) => (
//     <div
//       className="flex flex-row justify-between font-primary shadow-md cursor-pointer rounded-full mx-[6px] pl-[10px] py-[10px] px-4 relative z-5 mb-2"
//       onClick={() => {
//         setProfileData({
//           ...user,
//           display: "flex",
//           profilePic: user.profilePic || defaultProfile,
//         });
//       }}
//     >
//       <div className="flex items-center gap-2">
//         <img
//           src={user.profilePic || defaultProfile}
//           alt="Profile photo"
//           className="rounded-full w-[50px] h-[50px] border"
//         />
//         <div>
//           <h1 className="font-semibold text-[#585858] text-sm sm:text-base">
//             {user.fullName}
//           </h1>
//           <p className="font-normal text-[11px] sm:text-sm text-[#585858]">
//             {user.about.length > 80 ? `${user.about.slice(0, 80)}..` : user.about}
//           </p>
//         </div>
//       </div>
//       {user_type === "Student" && (
//         <div className="flex flex-col justify-center items-center">
//           <MdEmojiEmotions size={20} color="orange" />
//           <p className="font-medium text-[15px] text-gradient">5.0</p>
//         </div>
//       )}
//     </div>
//   ));

//   const findUsers = async (e) => {
//     if (e) e.preventDefault();
//     try {
//       // Log search terms for debugging
//       console.log('Search Terms:', searchTerm);

//       const response = await axios.get(
//         `${apiUrl}/api/${search}/search`, 
//         {
//           params: {
//             fullName: searchTerm.fullName,
//             about: searchTerm.about,
//             city: searchTerm.city,
//             country: searchTerm.country,
//             category: searchTerm.category,
//             subCategory: searchTerm.subCategory
//           },
//           headers: {
//             Authorization: `Bearer ${authToken}`,
//           },
//         }
//       );
//       // Log response data for debugging
//       console.log('Search Response:', response.data);

//       setUsers(response.data.users_list);
//     } catch (error) {
//       console.log('Error fetching search results', error);
//     }
//   };

//   const handleSearch = (e) => {
//     setSearchTerm({ ...searchTerm, [e.target.name]: e.target.value });
//   };

//   const clearFilters = () => {
//     setSearchTerm(initialSearchData); // Reset search filters
//     setUsers([]); // Clear user list
//     set_categories([]); // Clear categories
//     get_categories();
//     set_subCategories([]); // Clear subcategories
//     set_countries([]); // Clear countries
//     get_countries();
//   };

//   return (
//     <>
//       <section className="mb-20 sm:mb-0 font-primary">
//         <NavBar />
//         <div>
//           <Header />
//           <div className="sm:mt-14 lg:mt-16 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 mb-8 overflow-hidden">
//             <div className="absolute w-full h-full z-5 top-0 left-0 bg-[rgb(0,0,0,0.1)]" style={{ display: profileData.display }} />
//             <div className="mt-[160px] sm:mt-0 md:grid grid-cols-3 gap-4 sm:min-h-[85vh] sm:py-[55px]">
//               <div className="flex sm:flex-col items-center justify-center sm:justify-start px-8 sm:px-0 mt-8 sm:mt-0 pb-9 shadow-md rounded-b-[50px] sm:pt-5">
//                 <div className="sm:rounded-[50px] sm:shadow-md sm:w-7/12 md:w-11/12 sm:border-2 sm:h-[281px] sm:border-orange-500 sm:flex flex-col items-center justify-center sm:px-3 lg:px-8">
//                   <div className="flex flex-col justify-center items-center mb-2">
//                     <img 
//                       src={loggedInUser.profilePic || defaultProfile}
//                       alt="Profile photo"
//                       className="rounded-full w-[75px] h-[75px] sm:w-[100px] sm:h-[100px] border"
//                     />
//                     <h1 className="font-semibold text-2xl text-[#585858]">{fullName}</h1>
//                     <p className="text-[#afafaf] text-sm font-normal">
//                       {user_type}
//                     </p>
//                   </div>
//                   <form className="flex-col mr-3 items-center" onSubmit={findUsers}>
//                     <div className="flex items-center">
//                       <div className="relative mt-2">
//                         <div className="absolute inset-y-0 left-0 pl-[15px] flex items-center pointer-events-none">
//                           <IoMdSearch color="#afafaf" size={20} />
//                         </div>
//                         <input
//                           type="text"
//                           className="pl-10 pr-4 py-3 border border-[#585858] text-sm w-full rounded-full"
//                           placeholder="Search by name"
//                           name="fullName"
//                           value={searchTerm.fullName}
//                           onChange={handleSearch}
//                         />
//                       </div>
//                       <button
//                         type="submit"
//                         className="bg-gradient text-white rounded-full px-6 py-2"
//                       >
//                         Go
//                       </button>
//                     </div>
//                     <div className="relative mt-2">
//                       <div className="absolute inset-y-0 left-0 pl-[15px] flex items-center pointer-events-none">
//                         <IoMdSearch color="#afafaf" size={20} />
//                       </div>
//                       <input
//                         type="text"
//                         className="pl-10 pr-4 py-3 border border-[#585858] text-sm w-full rounded-full"
//                         placeholder="Search by about"
//                         name="about"
//                         value={searchTerm.about}
//                         onChange={handleSearch}
//                       />
//                     </div>
//                   </form>
//                 </div>
//                 <div className="px-6 hidden sm:block">
//                   <div className="text-[14px] my-4 font-semibold text-[#585858]">
//                     Search by :
//                   </div>
//                   <div className="flex flex-wrap ">
//                     <div className="text-[12px]">
//                       <select
//                         name="category"
//                         className="max-w-[150px] bg-gradient px-2 py-1 sm:py-2 rounded-full mb-1 mr-1"
//                         onChange={(e) => {
//                           handleSearch(e);
//                           get_subCategories(e.target.value);
//                         }}
//                       >
//                         <option className="w-[100%]" value="">
//                           Select a category
//                         </option>
//                         {category}
//                       </select>
//                     </div>
//                     <div className="text-[12px]">
//                       <select
//                         name="subCategory"
//                         className="max-w-[160px] bg-gradient px-2 py-1 sm:py-2 rounded-full mb-1 mr-1"
//                         onChange={handleSearch}
//                       >
//                         <option className="w-[100%]" value="">
//                           Select a sub-category
//                         </option>
//                         {subCategory}
//                       </select>
//                     </div>
//                     <div className="text-[12px]">
//                       <select
//                         name="country"
//                         className="max-w-[150px] bg-gradient px-2 py-1 sm:py-2 rounded-full mb-1 mr-1"
//                         onChange={(e) => {
//                           handleSearch(e);
//                         }}
//                       >
//                         <option className="w-[100%]" value="">
//                           Select a country
//                         </option>
//                         {country}
//                       </select>
//                     </div>
//                   </div>
//                   <div className="flex lg:justify-end sm:justify-start lg:mt-0 sm:mt-2">
//                     <button
//                       className="bg-[#afafaf] text-white px-4 py-1 rounded-full mr-4"
//                       onClick={clearFilters}
//                     >
//                       Clear
//                     </button>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-span-2 sm:h-full sm:rounded-[25px] sm:shadow-md min-h-full sm:px-3 md:px-5">
//                 <div className="px-6 sm:hidden">
//                   <div className="text-[14px] my-4 font-semibold text-[#585858]">
//                     Search by :
//                   </div>
//                   <div className="flex flex-wrap">
//                     <div className="text-[12px]">
//                       <select
//                         name="category"
//                         className="max-w-[150px] bg-gradient px-2 py-1 rounded-full mb-1 mr-1"
//                         onChange={(e) => {
//                           handleSearch(e);
//                           get_subCategories(e.target.value);
//                         }}
//                       >
//                         <option className="w-[100%]" value="">
//                           Select a category
//                         </option>
//                         {category}
//                       </select>
//                     </div>
//                     <div className="text-[12px]">
//                       <select
//                         name="subCategory"
//                         className="max-w-[160px] bg-gradient px-2 py-1 rounded-full mb-1 mr-1"
//                         onChange={handleSearch}
//                       >
//                         <option className="w-[100%]" value="">
//                           Select a sub-category
//                         </option>
//                         {subCategory}
//                       </select>
//                     </div>
//                     <div className="text-[12px]">
//                       <select
//                         name="country"
//                         className="max-w-[150px] bg-gradient px-2 py-1 rounded-full mb-1 mr-1"
//                         onChange={(e) => {
//                           handleSearch(e);
//                         }}
//                       >
//                         <option className="w-[100%]" value="">
//                           Select a country
//                         </option>
//                         {country}
//                       </select>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="text-[14px] sm:text-[20px] mx-6 my-4 font-semibold text-[#585858]">
//                   Search results :
//                 </div>
//                 {users.length > 0 ? (
//                   <div className="overflow-y-auto relative" style={{ maxHeight: "calc(100vh - 200px)" }}>
//                     {suggestedUsers}
//                     <ProfileModal profileData={profileData} setProfileData={setProfileData} />
//                   </div>
//                 ) : (
//                   <div className="text-center text-[#585858] mt-5 font-normal text-base sm:text-lg">
//                     No users found with given data
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <MainMenu />
//     </>
//   );
// };

// export default Search;


import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import MainMenu from "../components/MenuBottom/MainMenu";
import ProfileModal from "../Modals/ProfileModal";
import bg from "../assets/img/background-3.png";
import logo from "../assets/img/logo.svg";
import { IoMdSearch } from "react-icons/io";
import { MdEmojiEmotions } from "react-icons/md";
import NavBar from "../components/nav/navBar";
import Header from "../components/headerBackground";
import Loading2 from "../components/Loading2";
import { FiChevronDown } from "react-icons/fi";
import { Helmet } from "react-helmet-async";
import MetaDecorator from "../utils/MetaDecorator";
const apiUrl = process.env.REACT_APP_API_URL;

const Search = ({ fullName, profilePic }) => {
  let initialSearchData = {
    searchTerm: "",
    country: "",
    city: "",
    category: "",
    subCategory: "",
    interest_category: "",
    interest_subCategory: "",
  };

  let initialProfileData = {
    display: "none",
    profilePic: "",
    fullName: "",
    email: "",
    about: "",
  };

  const [searchData, setSearchData] = useState(initialSearchData);
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const loggedInUserId = Cookies.get('loggedInUserId');
  const [loading, setLoading] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState("");
  const [profileData, setProfileData] = useState(initialProfileData);
  const fileUrl = 'https://cgb-storage.blr1.digitaloceanspaces.com';
  let defaultProfile = `${fileUrl}/profile-pictures/defaultProfile.png`;

  let authToken = Cookies.get("authToken");
  let userType = Cookies.get("user_type");
  let search = userType === "Student" ? "Teacher" : "Student";

  const getLoggedInUserDetails = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/user-info/get-users/${loggedInUserId}`, {
        headers: {
          "Authorization": `Bearer ${authToken}`
        }
      });
      setLoggedInUser(response.data);
    } catch (error) {
      console.log('Error fetching user', error);
    }
  }

  useEffect(() => {
    getLoggedInUserDetails();
  }, [authToken, loggedInUserId]);

  useEffect(() => {
    getCategories();
    getCountries();
  }, []);

  async function getCategories() {
    let response = await fetch(`${apiUrl}/api/categories/getAllCategory`);
    let data = await response.json();

    let categories = [];
    for (let c of data) categories.push(c.name);

    setCategories(categories);
  }

  async function getSubCategories(category) {
    try {
      let response = await axios.post(
        `${apiUrl}/api/subCategories/getParticularSubCategory`,
        { category_name: category }
      );
      let data = response.data;
      let subcategories = [];

      for (let sc of data) subcategories.push(sc.name);

      setSubCategories(subcategories);
    } catch (err) {
      console.log(err);
    }
  }

  async function getCountries() {
    let response = await fetch(`${apiUrl}/api/${search}/getAllCountry`);
    let countries = await response.json();
    setCountries(countries);
  }

  const category = categories.map((category) => (
    <option className="w-[100%]" value={category} key={category}>
      {category}
    </option>
  ));

  const subCategory = subCategories.map((subCategory) => (
    <option className="w-[100%]" value={subCategory} key={subCategory}>
      {subCategory}
    </option>
  ));

  const country = countries?.map((country) => (
    <option className="w-[100%]" value={country} key={country}>
      {country}
    </option>
  ));

  const handleUser = (e) => {
    console.log("Click on user");
  }

  const suggestedUsers = users.map((user) => (
    <div
      className="flex flex-row justify-between font-primary shadow-md cursor-pointer rounded-full mx-[6px] pl-[10px] py-[10px] px-4 relative z-5 mb-2"
      onClick={() => {
        setProfileData({
          ...user,
          display: "flex",
          profilePic: user.profilePic || defaultProfile,
        });
      }}
      key={user._id}
    >
      <div className="flex items-center gap-2">
        <img
          src={user.profilePic || defaultProfile}
          alt="Profile photo"
          className="rounded-full w-[50px] h-[50px] border"
        />
        <div>
          <h1 className="font-semibold text-[#585858] text-sm sm:text-base">
            {user.fullName}
          </h1>
          <p className="font-normal text-[11px] sm:text-sm text-[#585858]">
            {user.about.length > 80 ? `${user.about.slice(0, 80)}..` : user.about}
          </p>
        </div>
      </div>
      {userType === "Student" && (
        <div className="flex flex-col justify-center items-center">
          <MdEmojiEmotions size={20} color="orange" />
          <p className="font-medium text-[15px] text-gradient">5.0</p>
        </div>
      )}
    </div>
  ));

  const findUsers = async (e) => {
    if (e) e.preventDefault();
    try {
      setLoading(true)
      // Log search terms for debugging
      // console.log('Search Terms:', searchData);

      const response = await axios.get(
        `${apiUrl}/api/${search}/search`,
        {
          params: {
            fullName: searchData.searchTerm,
            about: searchData.searchTerm,
            city: searchData.city,
            country: searchData.country,
            category: searchData.category,
            subCategory: searchData.subCategory
          },
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      // Log response data for debugging
      // console.log('Search Response:', response.data);

      setUsers(response.data.users_list);
    } catch (error) {
      console.log('Error fetching search results', error);
    } finally {
      setLoading(false)
    }
  };

  const handleSearch = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
  };

  const clearFilters = () => {
    setSearchData(initialSearchData); // Reset search filters
    setUsers([]); // Clear user list
    setCategories([]); // Clear categories
    getCategories();
    setSubCategories([]); // Clear subcategories
    setCountries([]); // Clear countries
    getCountries();
  };
  const keywords = ["education fourm","search teacher","search student","elearning", "education", "online class"]; 
  return (
    <>
      {/* <Helmet>
        <title>Search - Career Guidance Buddy</title>
        <meta name="keywords" content="education fourm,search teacher,search student, education, online class,e-learning" />
      </Helmet> */}
      <MetaDecorator title={'Search - Career Guidance Buddy'} description={''} imageUrl={''} keywords={keywords} imageAlt={''} url={''}/>
            
      <section className="mb-20 sm:mb-0 font-primary">
        <NavBar />
        <div>
          <Header />
          <div className="sm:mt-14 lg:mt-16 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5 mb-8 overflow-hidden">
            <div className="absolute w-full h-full z-5 top-0 left-0 bg-[rgb(0,0,0,0.1)]" style={{ display: profileData.display }} />
            <div className="mt-[160px] sm:mt-0 md:grid grid-cols-3 gap-4 sm:min-h-[85vh] sm:py-[55px]">
              <div className="flex sm:flex-col items-center justify-center sm:justify-start px-8 sm:px-0 mt-8 sm:mt-0 pb-6 shadow-md rounded-b-[50px] sm:pt-5">
                <div className="sm:rounded-[50px] sm:shadow-md sm:w-7/12 md:w-11/12 sm:border-2 sm:h-[281px] sm:border-orange-500 sm:flex flex-col items-center justify-center sm:px-3 lg:px-4">
                  <div className="flex flex-col justify-center items-center mb-2">
                    <img
                      src={loggedInUser.profilePic || defaultProfile}
                      alt="Profile photo"
                      className="rounded-full w-[75px] h-[75px] lg:w-[100px] lg:h-[100px] sm:w-[80px] sm:h-[80px] border"
                    />
                    <h1 className="font-semibold text-2xl text-[#585858]">{loggedInUser.fullName}</h1>
                    <p className="text-[#afafaf] text-sm font-normal">
                      {loggedInUser.type}
                    </p>
                  </div>
                  <form className="flex-col mr-3 items-center" onSubmit={findUsers}>
                    <div className="flex lg:flex-row sm:flex-col items-center gap-2">
                      <div className="relative ">
                        <div className="absolute inset-y-0 left-0 pl-[12px] flex items-center pointer-events-none">
                          <IoMdSearch color="#afafaf" size={20} />
                        </div>
                        <input
                          type="text"
                          className="pl-8 pr-4 py-2 border border-[#585858] text-sm w-full rounded-full"
                          placeholder="Search by name or about"
                          name="searchTerm"
                          value={searchData.searchTerm}
                          onChange={handleSearch}
                        />
                      </div>
                      <button
                        type="submit"
                        className="bg-gradient text-white rounded-full px-6 lg:py-2 sm:py-1 py-2"
                      >
                        Go
                      </button>
                    </div>
                  </form>
                </div>
                <div className="px-6 hidden sm:block sm:w-10/12 md:w-full">
                  <div className="text-[14px] sm:text[16px] my-4 font-semibold text-[#585858]">
                    Search by :
                  </div>
                  <div className="flex flex-col w-full text-center gap-2 mb-2 justify-start self-start">
                    <div className="text-[12px] sm:text-[14px] lg:text-[16px] border-2 border-orange-500 relative flex items-center justify-center rounded-full">
                      <select
                        name="category"
                        className="appearance-none bg-white text-gradient font-semibold outline-none focus-outline-none px-1 py-1 sm:py-1 rounded-full text-center"
                        onChange={(e) => {
                          handleSearch(e);
                          getSubCategories(e.target.value);
                        }}
                      >
                        <option className="w-[100%]" value="">
                          Select a category
                        </option>
                        {category}
                      </select>
                      <FiChevronDown className='absolute top-1/2 right-5 transform -translate-y-1/2 text-orange-500'></FiChevronDown>
                    </div>
                    <div className="text-[12px] sm:text-[14px] lg:text-[16px] border-2 border-orange-500 relative flex items-center justify-center rounded-full px-2">
                      <select
                        name="subCategory"
                        className="appearance-none bg-white text-gradient font-semibold outline-none focus-outline-none px-1 py-1 sm:py-1 rounded-full text-center"
                        onChange={handleSearch}
                      >
                        <option className="w-[100%] text-white" value="">
                          Select a sub-category
                        </option>
                        {subCategory}
                      </select>
                      <FiChevronDown className='absolute top-1/2 right-2 transform -translate-y-1/2 text-orange-500'></FiChevronDown>
                    </div>
                    <div className="text-[12px] sm:text-[14px] lg:text-[16px] border-2 border-orange-500 relative flex items-center justify-center rounded-full px-2">
                      <select
                        name="country"
                        className=" appearance-none bg-white text-gradient font-semibold outline-none focus-outline-none px-1 py-1 sm:py-1 rounded-full text-center"
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      >
                        <option className="w-[100%] text-white" value="">
                          Select a country
                        </option>
                        {country}
                      </select>
                      <FiChevronDown className='absolute top-1/2 right-5 transform -translate-y-1/2 text-orange-500'></FiChevronDown>
                    </div>
                  </div>
                  <div className="flex lg:justify-end sm:justify-start lg:mt-0 sm:mt-2">
                    <button
                      className="bg-[#afafaf] text-white px-6 py-1 rounded-full "
                      onClick={clearFilters}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-span-2 sm:h-full sm:rounded-[25px] sm:shadow-md min-h-full sm:px-3 md:px-5">
                <div className="px-6 sm:hidden">
                  <div className="text-[14px] my-4 font-semibold text-[#585858]">
                    Search by :
                  </div>
                  <div className="flex flex-col w-full text-center gap-2 mb-2 justify-start self-start">
                    <div className="text-[12px] sm:text-[14px] lg:text-[16px] border-2 border-orange-500 relative flex items-center justify-center rounded-full">
                      <select
                        name="category"
                        className="appearance-none bg-white text-gradient font-semibold outline-none focus-outline-none px-1 py-1 sm:py-1 rounded-full text-center"
                        onChange={(e) => {
                          handleSearch(e);
                          getSubCategories(e.target.value);
                        }}
                      >
                        <option className="w-[100%]" value="">
                          Select a category
                        </option>
                        {category}
                      </select>
                      <FiChevronDown className='absolute top-1/2 right-5 transform -translate-y-1/2 text-orange-500'></FiChevronDown>
                    </div>
                    <div className="text-[12px] sm:text-[14px] lg:text-[16px] border-2 border-orange-500 relative flex items-center justify-center rounded-full px-2">
                      <select
                        name="subCategory"
                        className="appearance-none bg-white text-gradient font-semibold outline-none focus-outline-none px-1 py-1 sm:py-1 rounded-full text-center"
                        onChange={handleSearch}
                      >
                        <option className="w-[100%] text-white" value="">
                          Select a sub-category
                        </option>
                        {subCategory}
                      </select>
                      <FiChevronDown className='absolute top-1/2 right-2 transform -translate-y-1/2 text-orange-500'></FiChevronDown>
                    </div>
                    <div className="text-[12px] sm:text-[14px] lg:text-[16px] border-2 border-orange-500 relative flex items-center justify-center rounded-full px-2">
                      <select
                        name="country"
                        className=" appearance-none bg-white text-gradient font-semibold outline-none focus-outline-none px-1 py-1 sm:py-1 rounded-full text-center"
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      >
                        <option className="w-[100%] text-white" value="">
                          Select a country
                        </option>
                        {country}
                      </select>
                      <FiChevronDown className='absolute top-1/2 right-5 transform -translate-y-1/2 text-orange-500'></FiChevronDown>
                    </div>
                  </div>
                  <div className="flex lg:justify-end sm:justify-start lg:mt-0 sm:mt-2">
                    <button
                      className="bg-[#afafaf] text-white px-6 py-1 rounded-full "
                      onClick={clearFilters}
                    >
                      Clear
                    </button>
                  </div>
                </div>
                <div className="text-[14px] sm:text-[20px] mx-6 my-4 font-semibold text-[#585858]">
                  Search results :
                </div>
                <div>
                  {loading ? (
                    <Loading2 /> // Render loading spinner
                  ) : users.length > 0 ? (
                    <div className="overflow-y-auto relative" style={{ maxHeight: 'calc(100vh - 200px)' }}>
                      {suggestedUsers}
                      <ProfileModal profileData={profileData} setProfileData={setProfileData} />
                    </div>
                  ) : (
                    <div className="text-center text-[#585858] mt-5 font-normal text-base sm:text-lg">
                      No users found with given data
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MainMenu />
    </>
  );
};

export default Search;
