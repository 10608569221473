import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BsChevronRight } from "react-icons/bs";
import Cookies from "js-cookie";
import Empty_data from '../../Empty_data';
import Loading2 from '../../Loading2';
import { useNavigate } from 'react-router-dom';

const Main_course_page = () => {

  const [cards, SetCard] = useState([]);
  const [loading, setLoading] = useState(true);
  const authToken = Cookies.get("authToken");
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    Fetch_Course_Details();
  }, []);

  const Fetch_Course_Details = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/courses/get_courses`, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      });
      const data = response.data.courseWithTimezone;

      // Sort by Start_Date first, then by End_Date if Start_Date is the same
      const sortedCourses = data.sort((a, b) => {
        const startDateA = new Date(a.Start_Date);
        const startDateB = new Date(b.Start_Date);
        const endDateA = new Date(a.End_Date);
        const endDateB = new Date(b.End_Date);

        if (startDateA - startDateB !== 0) {
          return startDateA - startDateB;
        } else {
          return endDateA - endDateB;
        }
      });

      SetCard(sortedCourses);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  if (cards.length === 0 && !loading) {
    return <Empty_data />
  }

  if (loading) {
    return <Loading2 />
  }

  const handleCardClick = (card) => {
    navigate('/course_details_page', { state: { card } });
  }

  return (
    <div id="cards_container" className="flex flex-col w-full items-center px-5 overflow-y-auto h-[450px] sm:h-[496px]  font-primary">
      <div className="flex gap-5 flex-col justify-center items-center w-full" style={{ marginTop: "10px" }}>
        {cards.map((card, index) => (
          <div key={index} className="card rounded-[40px] p-5 cursor-pointer w-full lg:rounded-[20px] sm:flex sm:justify-between sm:flex-row-reverse" style={{ boxShadow: "0px 2px 6px 0px #00000040", marginBottom: "20px" }}
            onClick={() => handleCardClick(card)}
          >
            <div className="flex justify-end items-center mt-auto w-40">
              <div className='bg-[#D1FFD0] w-full px-2 py-2 text-center rounded-[20px] font-montserrat flex flex-col'>
                <p>{new Date(card.Start_Date).toLocaleDateString()}</p>
                <p>{`${card.Start_Time}`}</p>
              </div>
              <BsChevronRight className='sm:hidden ' />
            </div>
            <div className=''>
              <div className='sm:flex items-center gap-6'>
                <div className='flex flex-col'>
                  <div className="flex items-center  gap-3">
                    <div className="student_status">{card.Title}</div>
                    <BsChevronRight className='hidden sm:block ' />
                  </div>
                  <div><a href={card.link} className="text-[#AFAFAF] font-montserrat font-normal w-[10px] whitespace-nowrap overflow-hidden overflow-ellipsis text-sm">{card.URL}</a></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Main_course_page;
