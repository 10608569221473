import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Call_And_Conference/calender.css'
import logo from '../../../assets/img/logo.svg'
import bg from '../../../assets/img/background-2.png'
import { IoIosArrowDown } from "react-icons/io";
import { BsChevronLeft } from "react-icons/bs";
import Cookies from "js-cookie";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import MainMenu from '../../MenuBottom/MainMenu';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';
import NavBar from '../../nav/navBar';
import Loading2 from '../../Loading2';
import { Helmet } from 'react-helmet-async';


const Edit_Course = ({ setMessage, setShowMessage }) => {
  const location = useLocation();
  const { card: initialCard } = location.state || {};
  const [card, setCard] = useState(initialCard || {});
  const [fixed_amount, setFixedAmount] = useState(card.Price.Fixed_Amount);

  const [selectedDuration, setSelectDuration] = useState(card.Duration);
  const [isOpenDuration, setIsOpenDuration] = useState(false)
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(card.Start_Date);
  const [EndDate, setEntDate] = useState(card.End_Date);
  //console.log("Selected Date===>",date)
  const [selectedTime, setSelectedTime] = useState(card.Start_Time);
  const authToken = Cookies.get("authToken");
  const [currency, setCurrency] = useState(card.Price.currency);
  const [currencyCode, setCurrencyCode] = useState(card.Price.Currency_Code);
  const [paypal_fee, setPaypal_fee] = useState(card.Price.paypal_fee);
  const [isOpenCurrency, setIsOpenCurrency] = useState(false);
  const [User, setUser] = useState('');
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isEndDropdownVisible, setEndDropdownVisible] = useState(false);
  const [selectedMinute, setSelectedMinute] = useState('00');
  const [selectedHour, setSelectedHour] = useState(0);


  const [title, setTitle] = useState(card.Title);

  const [description, setDescription] = useState(card.Description);
  const [url, setUrl] = useState(card.URL);
  const [isIndia, setIsIndia] = useState(false);
  const [fetchcountry, setFetchcountry] = useState(true);

  const loggedInUserId = Cookies.get('loggedInUserId');
  const apiUrl = process.env.REACT_APP_API_URL;
  const [selectedImage, setSelectedImage] = useState(null);
  const [preview, setPreview] = useState(null);

  useEffect(() => {

    if (card.image && card.image[0]) {
      setPreview(card.image[0].url);
    }
  }, [card]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) { // Check if a file is selected
      setSelectedImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      // No file selected, clear the preview and selected image
      setSelectedImage(null);
      setPreview(preview);
    }
  };


  const fetchUserDetails = async (setCurrency, setCurrencyCode, setIsIndia, setFetchcountry, setError, setUser, User) => {
    try {
      // console.log(loggedInUserId)
      const response = await axios.get(`${apiUrl}/api/auth/user_details/${loggedInUserId}`);
      const user = response.data.user_Details;
      // console.log(response.data.user)
      setUser(response.data.user)

      // console.log(user.country)
      if (!user.country) {
        console.error('No country name found in user details');
        return; // Exit early if no country name is available
      }

      if (user.country === 'India') {
        setCurrency('₹');
        setCurrencyCode('INR');
        setPaypal_fee(0)
        setIsIndia(true);

      } else {
        setIsIndia(false);
      }
      setFetchcountry(false)
      console.log(User)
      console.log("user referral", User.referralCode)


    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {

    fetchUserDetails(setCurrency, setCurrencyCode, setIsIndia, setFetchcountry, setError, setUser, User);
  }, [loggedInUserId]);



  const handleInputChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  }

  const handleDoneClick = async () => {
    try {
      const data = {
        Id: card._id,
        Title: title,
        Description: description,
        Start_Date: selectedDate,
        End_Date: EndDate,
        Start_Time: selectedTime,
        URL: url,
        Price: {
          currency: currency,
          Fixed_Amount: fixed_amount,
          Currency_Code: currencyCode,
          paypal_fee: paypal_fee,
        },
        Duration: selectedDuration,
      };

      const formData = new FormData();
      formData.append('data', JSON.stringify(data)); // Send other data as JSON string
      formData.append('image', selectedImage); // Send file if applicable

      const response = await axios.post(`${apiUrl}/api/courses/edit_course`, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(response.data);

      navigate('/calender_page');
      setShowMessage(true);
      setMessage("Course Edited Successfully");

    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setShowMessage(true);
        setMessage(error.response.data.error);
      } else {
        setShowMessage(true);
        setMessage("An unexpected error occurred.");
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };





  // console.log('Time slot created:', slotResponse.data.newTimeSlot);
  //console.log(selectedDate,date)
  //console.log("paypal_fee",paypal_fee)

  // CALENDER SHOULD STARTS FORM TOMORROW
  //   const tomorrow = addDays(new Date(), 1);




  const handlePriceChange = (price) => {
    setFixedAmount(price);
  };


  const toggleDurationDropdown = () => {
    setIsOpenDuration(!isOpenDuration)
  }


  const handleBack = () => {
    navigate('/calender_page')
  }


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleEndDateChange = (date) => {
    setEntDate(date);
  };


  // BASED ON DURATION  AND START TIME CHANGE THE END TIME

  const handleDurationSelection = (duration) => {
    setSelectDuration(duration);
    setIsOpenDuration(false);
    //setEndTime(calculateEndTime(selectedTime, duration));
  }

  const currencyOptions = [

    { symbol: 'A$', code: 'AUD', paypal_fee: 0.30 }, // Australian Dollar
    { symbol: 'C$', code: 'CAD', paypal_fee: 0.30 }, // Canadian Dollar
    { symbol: '€', code: 'EUR', paypal_fee: 0.35 }, // European Euro
    { symbol: '£', code: 'GBP', paypal_fee: 0.20 }, // Pound Sterling
    { symbol: '¥', code: 'JPY', paypal_fee: 40.00 }, // Japanese Yen
    { symbol: '₽', code: 'RUB', paypal_fee: 10.00 },  // Russian ruble
    { symbol: 'S$', code: 'SGD', paypal_fee: 0.50 }, // Singapore Dollar
    { symbol: '$', code: 'USD', paypal_fee: 0.30 }, // United States Dollar

  ];



  const handleCurrency = (symbol, code, paypal_fee) => {
    setCurrency(symbol);
    setCurrencyCode(code);
    setIsOpenCurrency(false);
    //console.log("selected paypal_fee",paypal_fee)
    setPaypal_fee(paypal_fee)
  };


  const toggleDropdown = () => {
    setIsOpenCurrency(!isOpenCurrency);
  };

  const handleHourChange = (hour) => {

    const paddedHour = hour.toString().padStart(2, '0');

    setSelectedHour(paddedHour);

    const updatedTime = `${paddedHour}:${selectedMinute}`;
    setSelectedTime(updatedTime);


    setDropdownVisible(false)
  };

  const handleMinuteChange = (minute) => {

    const paddedMinute = minute.toString().padStart(2, '00');

    setSelectedMinute(paddedMinute);

    const updatedTime = `${selectedHour}:${paddedMinute}`;
    setSelectedTime(updatedTime);
    setDropdownVisible(false)

  };


  //   const handleEndHourChange = (hour) => {

  //     const paddedHour = hour.toString().padStart(2, '0');

  //     setSelectedHour(paddedHour);

  //     const updatedTime = `${paddedHour}:${selectedMinute}`;
  //     setEndTime(updatedTime);

  //     //setEndTime(calculateEndTime(updatedTime, selectedDuration));
  //     setDropdownVisible(false)
  //   };

  //   const handleEndMinuteChange = (minute) => {

  //     const paddedMinute = minute.toString().padStart(2, '00');

  //     setSelectedMinute(paddedMinute);

  //     const updatedTime = `${selectedHour}:${paddedMinute}`;
  //     setEndTime(updatedTime);
  //     setDropdownVisible(false)
  //     //setEndTime(calculateEndTime(updatedTime, selectedDuration));
  //   };


  const generateTimes = () => {
    const hours = [];
    const minutes = [];

    // Generate hours from 00 to 23
    for (let hour = 0; hour < 24; hour++) {
      hours.push(hour.toString().padStart(2, '0'));
    }

    // Generate minutes from 00 to 59
    for (let minute = 0; minute < 60; minute++) {
      minutes.push(minute.toString().padStart(2, '0'));
    }

    return { hours, minutes };
  };

  const { hours, minutes } = generateTimes();

  if (fetchcountry) {
    return <Loading2 />
  }


  return (
    <section class="mb-24 sm:mb-0 font-primary">
      <Helmet>
        <title>Edit Course - Career Guidance Buddy</title>
        <meta name="keywords" content="chat with student,chat with teacher, education, online class" />
      </Helmet>
      <NavBar />
      <div class='sm:mt-16 lg:mt-20 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5'>
        <div class="sm:hidden bg-contain bg-cover bg-no-repeat w-full h-[280px] sm:mx-auto sm:w-full"
          style={{ backgroundImage: `url("${bg}")` }}>
          <div class="flex justify-center items-center pt-[52px]">
            <img class="w-[189px]" src={logo} alt="logo" />
          </div>
        </div>


        <div className="lg:grid grid-cols-3 gap-4 sm:min-h-[85vh] sm:py-[55px] justify-center px-0">

          <div>
            <div className="flex items-center justify-between w-full mb-8">
              <div
                style={{ color: "#AFAFAF" }}
                className="flex items-center gap-4 cursor-pointer"
                onClick={handleBack}
              >
                <BsChevronLeft />
                <div style={{ fontWeight: 600 }}>Back</div>
              </div>
              <div className=" w-32 text-center  text-orange-400 font-bold">
                New Course
              </div>
            </div>

            <div
              className=" w-auto lg:w-11/12 h-12 rounded-full ml-2"
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              }}
            >
              <input
                type="text"
                placeholder="Title"
                value={title}
                className=" w-82 ml-8 mt-3 outline-none font-semibold text-lg text-black"
                onChange={handleInputChange}
              />
            </div>


            <div className="w-auto lg:w-13/12 h-40 mb-24 mt-4 rounded-3xl">
              <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center lg:w-11/12 h-60 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 hover:bg-gray-100 dark:border-gray-300 dark:hover:border-gray-300 dark:hover:bg-gray-200">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  {preview ? (
                    <img src={preview} alt="Selected" className="w-[400px] h-[235px] object-fit rounded-lg mt-1" />
                  ) : (
                    <>
                      <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                    </>
                  )}
                </div>

                <input id="dropzone-file" type="file" className="hidden" onChange={handleImageChange} />
              </label>
            </div>


            <div
              className="w-auto lg:w-11/12 h-40 mb-5 mt-4 rounded-3xl"
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              }}
            >
              <div className="px-5 py-4 text-slate-500 font-semibold">
                Description
              </div>
              <hr className="w-full text-slate-600 h-1" />
              <textarea
                value={description}
                placeholder="Course Details . . . . . . "
                className="w-full px-5 py-2 rounded-3xl outline-none overflow-y-auto resize-none h-24"
                onChange={handleDescptionChange}
              ></textarea>
            </div>

          </div>

          <div className="col-span-2">
            <div className="flex items-center sm:justify-center gap-5 mt-7">
              <div
                className="w-60 h-12 rounded-full ml-2 sm:mt-2 px-4 py-3"
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                Duration
              </div>
              <div>
                <div className="relative inline-block">
                  <div
                    className="bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 text-center py-3 rounded-full text-white w-32 h-12 mt-3 flex justify-center items-center gap-3 cursor-pointer"
                    onClick={toggleDurationDropdown}
                  >
                    {selectedDuration}{" "}
                    <IoIosArrowDown
                      className={`transform ${isOpenDuration ? "rotate-180" : ""
                        } transition-transform duration-200`}
                    />
                  </div>
                  {isOpenDuration && (
                    <div className="absolute mt-1 w-32 bg-white border rounded-lg shadow-lg z-10">
                      <div
                        className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleDurationSelection("1 Month")}
                      >
                        1 Month
                      </div>
                      <div
                        className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleDurationSelection("2 Months")}
                      >
                        2 Months
                      </div>
                      <div
                        className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleDurationSelection("3 Months")}
                      >
                        3 Months
                      </div>
                      <div
                        className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleDurationSelection("4 Months")}
                      >
                        4 Months
                      </div>
                      <div
                        className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleDurationSelection("5 Months")}
                      >
                        5 Months
                      </div>
                      <div
                        className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleDurationSelection("6 Months")}
                      >
                        6 Months
                      </div>
                      <div
                        className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleDurationSelection("7 Months")}
                      >
                        7 Months
                      </div>
                      <div
                        className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleDurationSelection("8 Months")}
                      >
                        8 Months
                      </div>
                      <div
                        className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleDurationSelection("9 Months")}
                      >
                        9 Months
                      </div>
                      <div
                        className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleDurationSelection("10 Months")}
                      >
                        10 Months
                      </div>
                      <div
                        className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleDurationSelection("11 Months")}
                      >
                        11 Months
                      </div>
                      <div
                        className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleDurationSelection("12 Months")}
                      >
                        12 Months
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-around gap-5 ml-2 mt-5">
              <div className="font-bold">Start</div>
              <div className="font-bold">End</div>
            </div>
            <div className="flex justify-around items-center mt-3 sm:-ml-8 ">
              <div className="flex lg:gap-4 gap-1">

                {/* <div className="custom-time-picker">
                <input
                  type="text"
                  value={selectedTime}
                  readOnly
                  className="bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 text-center rounded-full text-white text-md lg:w-32 w-20 h-12 py-2  cursor-pointer"
                />
                <input
                    type="time"
                    className="bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 text-center rounded-full text-white hover:text-white input-time text-md lg:w-32 w-20 h-12 py-2  cursor-pointer"
                    value={selectedTime}
                    // onChange={handleTimeChange}
                    />
              </div> */}

                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  className="bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 py-2 px-2 rounded-full text-white text-center text-md w-32 h-12 custom-date-picker"
                  dateFormat="d MMM yyyy"
                  minDate={new Date()}

                  withPortal
                />
                <div className="custom-time-picker">
                  <input
                    type="text"
                    value={selectedTime}
                    readOnly
                    placeholder='--:--'
                    className='bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 py-2 px-5 text-center rounded-full text-white text-md lg:w-32 w-16 h-12 lg:ml-10 ml-[-3] flex justify-center items-center placeholder:text-white'
                    onClick={() => setDropdownVisible(!isDropdownVisible)} // Toggle dropdown visibility on click
                  />
                  {isDropdownVisible && (
                    <div className="absolute top-[calc(100%_+_5px)] left-0 bg-white text-black p-1.5 border border-gray-300 rounded-md shadow-md z-1000 w-[120px] max-h-[300px] overflow-y-auto flex justify-center z-10">
                      {/* Hour column */}
                      <div className="hour-column overflow-y-auto">
                        {hours.map((hour, index) => (
                          <div key={index} onClick={() => handleHourChange(hour)}>
                            {hour === selectedHour ? (
                              <div className='bg-orange-500 p-2 text-center rounded-sm  my-1 mx-2 text-white cursor-pointer'>{hour}</div>
                            ) : (
                              <div className='hover:bg-orange-400  text-center ml-0 mr-2 mt-2 px-5 py-2 flex justify-center hover:text-white rounded-[50%] cursor-pointer mx-2'>{hour}</div>
                            )}
                          </div>
                        ))}
                      </div>
                      {/* Minute column */}
                      <div className="minute-column overflow-y-auto">
                        {minutes.map((minute, index) => (
                          <div key={index} onClick={() => handleMinuteChange(minute)}>
                            {minute === selectedMinute ? (
                              <div className='bg-orange-500 p-2 text-center rounded-sm  my-2 mx-2 text-white cursor-pointer '>{minute}</div>
                            ) : (
                              <div className='hover:bg-orange-400 ml-2  mt-2 px-5 py-2 flex justify-center  hover:text-white rounded-[50%] text-center cursor-pointer mx-2'>{minute}</div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

              </div>
              <div className="flex gap-2 items-center">
                <DatePicker
                  selected={EndDate}
                  onChange={handleEndDateChange}
                  className="bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 py-2 px-2 rounded-full text-white text-center text-md w-32 h-12 custom-date-picker"
                  dateFormat="d MMM yyyy"
                  withPortal
                />
                {/* <div className="custom-time-picker">
                <input
                    type="text"
                    value={card.End_Time}
                    readOnly
                    placeholder='--:--'
                    className='bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 py-2 px-5 text-center rounded-full text-white text-md lg:w-32 w-16 h-12 lg:ml-10 ml-[-3] flex justify-center items-center placeholder:text-white'
                    onClick={() => setEndDropdownVisible(!isEndDropdownVisible)} // Toggle dropdown visibility on click
                />
                {isEndDropdownVisible && (
                    <div className="absolute top-[calc(100%_+_5px)] left-0 bg-white text-black p-1.5 border border-gray-300 rounded-md shadow-md z-1000 w-[120px] max-h-[300px] overflow-y-auto flex justify-center z-10">
                    
                    <div className="hour-column overflow-y-auto">
                        {hours.map((hour, index) => (
                        <div key={index} onClick={() => handleEndHourChange(hour)}>
                            {hour === selectedHour ? (
                            <div className='bg-orange-500 p-2 text-center rounded-sm  my-1 mx-2 text-white cursor-pointer'>{hour}</div>
                            ) : (
                            <div className='hover:bg-orange-400  text-center ml-0 mr-2 mt-2 px-5 py-2 flex justify-center hover:text-white rounded-[50%] cursor-pointer mx-2'>{hour}</div>
                            )}
                        </div>
                        ))}
                    </div>

                    <div className="minute-column overflow-y-auto">
                    {minutes.map((minute, index) => (
                        <div key={index} onClick={() => handleEndMinuteChange(minute)}>
                        {minute === selectedMinute ? (
                            <div className='bg-orange-500 p-2 text-center rounded-sm  my-2 mx-2 text-white cursor-pointer '>{minute}</div>
                            ) : (
                            <div className='hover:bg-orange-400 ml-2  mt-2 px-5 py-2 flex justify-center  hover:text-white rounded-[50%] text-center cursor-pointer mx-2'>{minute}</div>
                        )}
                        </div>
                    ))}
                    </div>
                </div>
                )}
                </div>   */}
                {/* <div className="bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 py-2 px-5 text-center rounded-full text-white text-md lg:w-32 sm-w-16 h-12 lg:ml-10 ml-[-3] flex justify-center items-center">
                {endTime}
              </div> */}
              </div>
            </div>

            <div className="flex flex-col sm:flex-row items-center justify-center gap-10 w-full lg:mt-10 mt-0">
              <div className="flex justify-center items-center"></div>
              <div className="flex justify-center items-center gap-2 mb-4">
                <div
                  className="lg:w-44 w-32 text-center h-12 rounded-full  lg:ml-2 sm:ml-5  lg:mt-5 mt-0 px-4 py-3"
                  style={{
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                  }}
                >
                  Set Fee
                </div>
                <div>
                  <div className="flex items-center lg:mt-5 mt-0">
                    <div className="relative inline-block mr-2">
                      <div
                        className={`bg-gradient-to-b from-orange-500 via-orange-500 to-yellow-400 text-center py-3 rounded-full text-white lg:w-32 w-24  h-12 flex justify-center items-center lg:gap-10 gap-5 cursor-pointer ${isIndia ? "cursor-not-allowed" : ""
                          }`}
                        onClick={toggleDropdown}
                        disabled={isIndia}
                      >
                        {currency}{" "}
                        {!isIndia && (
                          <IoIosArrowDown
                            className={`transform ${isOpenCurrency ? "rotate-180" : ""
                              } transition-transform duration-200`}
                          />
                        )}
                      </div>
                      {isOpenCurrency && !isIndia && (
                        <div className="absolute right-0 mt-1 w-32 bg-white border rounded-lg shadow-lg">
                          {currencyOptions.map((cur) => (
                            <div
                              key={cur.code}
                              className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                              onClick={() => handleCurrency(cur.symbol, cur.code, cur.paypal_fee)}
                            >
                              {cur.symbol} {cur.code}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    <div className="inline-block">
                      <div
                        className="bg-white text-center py-3 rounded-full text-gray-800 lg:w-40 sm:w-20 h-12 flex justify-center items-center mr-3"
                        style={{
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                        }}
                      >
                        <span className="text-gray-800 px-2">{currency}</span>
                        <input
                          type="number"
                          className="bg-transparent text-gray-800 text-center w-28 placeholder-gray-500"
                          placeholder="Course Fee"
                          value={fixed_amount}
                          onChange={(e) => handlePriceChange(e.target.value)}
                          onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                        />
                      </div>
                    </div>
                    {/* <div className="inline-block">
                    <div
                      className="bg-white text-center py-3 rounded-full text-gray-800 lg:w-40 sm:w-20 h-12 flex justify-center items-center"
                      style={{
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                      }}
                    >
                      <span className="text-gray-800 px-2">{card.Price.currency}</span>
                      <input
                        type="number"
                        className="bg-transparent text-gray-800 text-center w-28 placeholder-gray-500 "
                        placeholder="Montly Fee"
                        value={card.Price.Monltly_Amount}
                        onChange={(e) => handleMonthlyChange(e.target.value)}
                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                      />
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="w-full h-16 mb-5 sm:mt-4 sm:rounded-2xl rounded-3xl lg:w-[750px] lg:mt-8 flex"
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              }}
            >
              <div className="px-5 py-4 text-slate-500 font-semibold">
                Meeting Link
              </div>
              <div className="h-8 w-[1px] bg-slate-400 mt-4"></div>
              <textarea
                value={url}
                placeholder="Link...."
                className="w-9/12 px-5 py-4 outline-none overflow-y-auto resize-none"
                onChange={handleUrlChange}
              ></textarea>
            </div>
            <div className="flex lg:justify-center justify-around gap-10 mb-5 lg:mt-8 ">
              <div
                className="border-2 border-orange-600 rounded-3xl py-2 px-12 bg-transparent text-orange-500 font-semibold hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 cursor-pointer flex justify-center gap-2 items-center shadow-lg"
                onClick={handleBack}
              >
                <span>Cancel</span>
              </div>
              <div
                className="border-2 border-orange-600 rounded-3xl py-2 px-12 bg-transparent text-orange-500 font-semibold hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] hover:border-0 cursor-pointer flex justify-center gap-2 items-center shadow-lg"
                onClick={handleDoneClick}
              >
                {/* <span>{loading ?
                              <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-white" role="status">
                              </div> : 'Done'}</span> */}Done

              </div>
            </div>
          </div>

        </div>

        {/* --------------------------------- BOTTOM MENU ----------------------------------- */}

        <div class="flex justify-center mt-10 sm:block">
          <MainMenu />
        </div>
      </div>

    </section>

  );
};

export default Edit_Course;