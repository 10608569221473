import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import PersonalDetails from "./personalDetails";
import Profession from "./profession";
import NavBar from "../../components/nav/navBar";
import { FaRegArrowAltCircleLeft, FaRegArrowAltCircleRight } from "react-icons/fa";
import Header from "../../components/headerBackground";
import Nav from "../../components/nav/nav";

const apiUrl = process.env.REACT_APP_API_URL;

const TeacherProfile = ({ setMessage, setShowMessage, profilePic , found }) => {
  const navigate = useNavigate();
  const loggedInUserId = Cookies.get('loggedInUserId');
  const [loggedInUser, setLoggedInUser] = useState("");
  const user_type = Cookies.get("user_type");
  const url = process.env.REACT_APP_API_URL;
  const [referralCode, setReferralCode] = useState('');
  const [currentPage, setCurrentPage] = useState("Personal details");
  const authToken = Cookies.get("authToken");

  const initialFormData = {
    fullName: "",
    email: "",
    country: "",
    state: "",
    city: "",
    verified: false,
    time_zone: "",
    linkedin_profile: "",
    instagram_profile: "",
    facebook_profile: "",
    x_profile: "",
    personal_website: "",
    about: "",
    institute: "",
    qualification: "",
    awards_and_certificates: "",
    category: "",
    subCategory: [],
    languages: [],
    profilePic: "",
    access_calendar: false,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [selectedFile, setSelectedFile] = useState(null);
  const file_url = 'https://cgb-storage.blr1.digitaloceanspaces.com';
  let defaultProfile = `${file_url}/profile-pictures/defaultProfile.png`;

  const containerRef = useRef(null);

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [currentPage]);

  async function getProfileData() {
    try {
      const response = await axios.get(
        `${apiUrl}/api/teacher/get-teacher-details`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const data = response.data;
      const teacher = data.teacher;
      const teacher_details = data.teacherDetails;

      if (teacher_details) {
        const {
          country,
          state,
          city,
          time_zone,
          linkedin_profile,
          instagram_profile,
          facebook_profile,
          x_profile,
          personal_website,
          about,
          institute,
          qualification,
          awards_and_certifications,
          category,
          subCategory,
          interest_category,
          interest_subCategory,
          willing_to_relocate,
          preferred_locations,
          languages,
          access_calendar,
        } = teacher_details;

        setFormData({
          ...formData,
          email: teacher.email,
          fullName: teacher.fullName,
          verified: teacher.verified,
          profilePic: teacher.profilePic,
          country: country,
          state: state,
          city: city,
          time_zone: time_zone,
          about: about,
          institute: institute,
          qualification: qualification,
          instagram_profile: instagram_profile,
          facebook_profile: facebook_profile,
          x_profile: x_profile,
          personal_website: personal_website,
          awards_and_certifications: awards_and_certifications,
          category: category,
          subCategory: subCategory,
          interest_category: interest_category,
          interest_subCategory: interest_subCategory,
          languages: languages,
          willing_to_relocate: willing_to_relocate,
          preferred_locations: preferred_locations,
          linkedin_profile: linkedin_profile,
          access_calendar: access_calendar,
        });
      } else {
        setFormData({
          ...formData,
          email: teacher.email,
          fullName: teacher.fullName,
          verified: teacher.verified,
          profilePic: teacher.profilePic,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  async function submitForm(e) {
    e.preventDefault();

    if (
      formData.fullName === "" ||
      formData.time_zone === "" ||
      formData.about === "" ||
      formData.institute === "" ||
      formData.qualification === ""
    ) {
      setShowMessage(true);
      setMessage("Please fill in the mandatory fields.");
      return;
    } else if (formData.languages.length === 0) {
      setShowMessage(true);
      setMessage("Please add at least one language.");
      return;
    }

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend[`${key}`] = value;
    });

    try {
      let response = await axios.post(
        `${apiUrl}/api/teacher/edit-profile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setShowMessage(true);
      setMessage("Profile updated successfully!");
      const user = response.data.user;
      // if (!user.referralCode) {
      //   navigate('/subscription');
      // } else {
      //   navigate("/cabinet");
      // }
      navigate("/cabinet")
    } catch (err) {
      console.log(err);
      setShowMessage(true);
      setMessage(err.response.data.error);
    }
  }

  useEffect(() => {
    getLoggedInUserDetails();
  }, [authToken, loggedInUserId]);

  const getLoggedInUserDetails = async () => {
    try {
      const response = await axios.get(`${url}/api/user-info/get-users/${loggedInUserId}`, {
        headers: {
          "Authorization": `Bearer ${authToken}`
        }
      });
      setReferralCode(response.data.referralCode);
      setLoggedInUser(response.data);
    } catch (error) {
      console.log('Error fetching user', error);
    }
  };

  const handleProfessionalDetailsClick = () => {
    if (formData.verified) {
      setCurrentPage("Profession");
    } else {
      setShowMessage(true);
      setMessage("Please verify your account to access Professional Details.");
      window.alert("Please verify your account to access Professional Details.");
      navigate("/verify", { state: { email: formData.email } });
    }
  }

  return (
    <>
      {found ? <NavBar /> : <Nav />}
      <div class="sm:mt-[520px] md:mt-10 lg:mt-14 sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5">
        <div>
          <Header />
          <div className="flex flex-col items-center mt-12 sm:mt-0 sm:px-5 w-full">
            <div className="mt-[100px] h-[65vh] sm:mt-5 flex flex-col w-full md:grid grid-cols-3 gap-3 sm:min-h-[85vh] sm:py-[55px] justify-center">
              <div className="w-full mx-auto h-fit p-[15px] sm:py-9 shadow-md rounded-[30px] sm:border border-orange-500">
                <div className="flex flex-col sm:flex flex-col justify-center items-center ">
                  {selectedFile != null ? (
                    <img
                      src={selectedFile}
                      alt="Profile"
                      className="rounded-full w-20 h-20 sm:w-20 sm:h-20 md:w-20 md:h-20 lg:w-34 lg:h-34"
                    />
                  ) : (
                    <img
                      src={
                        loggedInUser.profilePic !== "" && loggedInUser.profilePic !== undefined
                          ? loggedInUser.profilePic
                          : defaultProfile
                      }
                      alt="Profile"
                      className="rounded-full w-20 h-20 sm:w-20 sm:h-20 md:w-20 md:h-20 lg:w-34 lg:h-34 border"
                    />
                  )}
                  <h1 class="font-semibold text-lg lg:text-2xl sm:text-xl text-center text-[#585858]">
                    {formData.fullName}
                  </h1>
                  <p class="text-[#afafaf] text-center text-sm lg:text-base font-normal">Teacher</p>
                  <p className="text-[#585858] text-center text-sm lg:text-base font-normal">
                    {loggedInUser.referralCode ? `Referral Code: ${loggedInUser.referralCode}` : ''}
                  </p>
                </div>
                <div class="flex flex-row sm:flex-col gap-2 items-center justify-center mt-2">
                  <button
                    onClick={() => setCurrentPage("Personal details")}
                    className={
                      currentPage === "Personal details"
                        ? "shadow-md text-white sm:w-full font-semibold text-center w-full text-[14px] sm:text-[14px] lg:text-[18px] py-2 px-2 rounded-full bg-gradient"
                        : "shadow-md bg-white font-semibold sm:w-full text-center w-full text-[14px] sm:text-[14px] lg:text-[18px] py-2 px-2 rounded-full text-gradient"
                    }
                  >
                    Personal Details
                  </button>
                  <button
                    onClick={handleProfessionalDetailsClick}
                    className={
                      currentPage === "Profession"
                        ? "shadow-md text-white sm:w-full font-semibold text-center py-2 px-2 w-full text-[14px] sm:text-[14px] lg:text-[18px] rounded-full bg-gradient"
                        : "shadow-md bg-white font-semibold sm:w-full text-center py-2 px-2 w-full text-[14px] sm:text-[14px] lg:text-[18px] rounded-full text-gradient"
                    }
                  >
                    Professional Details
                  </button>
                </div>
              </div>

              <div ref={containerRef} className="col-span-2 overflow-y-auto max-h-transition grid grid-cols-1 gap-x-2 gap-y-4 p-2 sm:p-5 sm:drop-shadow-lg bg-white h-fit sm:overflow-hidden sm:min-h-[1400px] sm:max-h-fit rounded-[25px]" style={{ maxHeight: 'calc(100vh - 380px)' }}>
                <div className="">
                  {currentPage === "Personal details" ? (
                    <PersonalDetails
                      formData={formData}
                      setFormData={setFormData}
                      handleChange={handleChange}
                      setSelectedFile={setSelectedFile}
                      setCurrentPage={setCurrentPage}
                    />
                  ) : (
                    <Profession
                      formData={formData}
                      setFormData={setFormData}
                      handleChange={handleChange}
                      submitForm={submitForm}
                      setCurrentPage={setCurrentPage}
                      referralCode={referralCode}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherProfile;
