import React from 'react';
import { RiLogoutBoxRLine } from "react-icons/ri";
import Cookies from 'js-cookie';
import logo from "../../assets/img/dlogo.svg";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

function NavAdmin() {
    const navigate = useNavigate();
    const isAuthenticated = Cookies.get("adminAuthToken") !== undefined;

    const handleLogout = async () => {
        Cookies.remove("adminUserId");
        Cookies.remove("adminAuthToken");
        navigate("/admin/login");
    };

    return (
        <nav className="fixed top-0 z-50 w-full flex items-center justify-between h-[64px] w-full bg-gradient px-4 md:px-6 lg:px-12">
            <div className="flex items-center justify-between w-full max-w-[1300px] mx-auto">
                <div className="w-[200px] md:w-[240px] lg:w-[288px]">
                    <img className="w-full" src={logo} alt="logo" />
                </div>
                {isAuthenticated && (
                    <div className="flex items-center sm:gap-2" onClick={handleLogout}>
                        <span className="hidden text-[18px] sm:block text-white font-semibold">Log Out</span>
                        <RiLogoutBoxRLine
                            title="Logout"
                            className="cursor-pointer text-white hover:bg-white hover:text-orange-400 hover:font-bold hover:rounded-full hover:px-1 hover:py-1 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7"
                            size={28}
                            />
                    </div>
                )}
            </div>
        </nav>
    );
}

export default NavAdmin;
