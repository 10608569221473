import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../components/headerBackground';
import { FaPaperclip } from 'react-icons/fa';
import { AiOutlineSmile } from 'react-icons/ai';
import { IoIosSend } from "react-icons/io";
import { BsCheck2All } from 'react-icons/bs'
import axios from 'axios'; // Import Axios for API calls
import { Link, useParams } from 'react-router-dom'; // Import useParams from react-router-dom
import Cookies from 'js-cookie';
import EmojiPicker from 'emoji-picker-react';
import BlockUser from '../components/blockUser';
import MainMenu from '../components/MenuBottom/MainMenu';
import NavBar from '../components/nav/navBar';
import SideBar from '../components/SideBar';
import ChatFixedMenu from '../components/chatFixedMenu';
import { useSocket } from '../context/SocketContext';
// import FooterMain from '../components/nav/footerMain';
import { useNotifications } from '../context/NotificationContext';
import Loading2 from '../components/Loading2';
import { ChatContext } from '../App';
import { Helmet } from 'react-helmet-async';
import MetaDecorator from '../utils/MetaDecorator';
const ChatOpen = ({ setMessage, setShowMessage }) => {
  // const { otheruserID } = useParams(); // Get the conversationId from the URL params
  const [isBlocked, setIsBlocked] = useState(false);
  const chatContainerRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [conversation, setConversation] = useState('');
  const loggedInUserId = Cookies.get('loggedInUserId');
  const [showBlockOptions, setShowBlockOptions] = useState(false);
  const user_type = Cookies.get("user_type")
  const [otherUser, setOtherUser] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null); // State to store the selected file
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedEmoji, setSelectedEmoji] = useState('');
  const url = process.env.REACT_APP_API_URL;
  const { socket, onlineUsers, unreadCount, setUnreadCount } = useSocket();
  const file_url = 'https://cgb-storage.blr1.digitaloceanspaces.com';
  let defaultProfile = `${file_url}/profile-pictures/defaultProfile.png`
  const { markChatNotificationsAsRead } = useNotifications();
  const { setOtherUserID } = useContext(ChatContext);
  const otheruserID = localStorage.getItem('otheruserID');

  useEffect(() => {
    const storedOtherUserID = localStorage.getItem('otheruserID'); // Retrieve from localStorage
    if (storedOtherUserID) {
      setOtherUserID(storedOtherUserID);
    }
  }, [setOtherUserID]);

  useEffect(() => {
    if (!socket || !conversation || !messages) return;

    const lastMsgFromOtherUser = messages.length && messages[messages.length - 1].senderId !== loggedInUserId;
    // console.log(lastMsgFromOtherUser);
    if (lastMsgFromOtherUser) {
      socket.emit("markMessageAsRead", {
        conversationId: conversation._id,
        userId: otheruserID,
      });
    }

    const handleMessageRead = ({ conversationId }) => {
      if (conversation._id === conversationId) {
        setMessages((prev) => {
          const updatedMsg = prev.map((message) => {
            if (!message.read) {
              return {
                ...message,
                read: true,
              };
            }
            return message;
          });
          return updatedMsg;
        });

      }
    };
    socket.on("messageRead", handleMessageRead);
    return () => {
      socket.off("messageRead", handleMessageRead);
    };
  }, [socket, loggedInUserId, messages, conversation, otheruserID]);

  useEffect(() => {
    if (!socket) return;
    const handleNewMessage = (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    };
    // const handleUnreadCount = (count) => {
    //   setUnreadCount(count);
    // };
    socket.on("newMessageChat", handleNewMessage);
    // socket.on("unreadCount", handleUnreadCount);
    return () => {
      socket.off("newMessageChat", handleNewMessage);
      // socket.off("unreadCount", handleUnreadCount);
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) return;

    const handleMessageRead = ({ conversationId }) => {
      setUnreadCount((prevUnreadCount) => {
        const newUnreadCount = { ...prevUnreadCount };
        newUnreadCount[conversationId] = 0; // Set unread count to 0 for the read conversation
        return newUnreadCount;
      });
    };

    socket.on('messageRead', handleMessageRead);

    return () => {
      socket.off('messageRead', handleMessageRead);
    };
  }, [socket, setUnreadCount]);

  useEffect(() => {
    // Fetch messages for the specific conversation ID
    fetchMessages();
    //markChatNotificationsAsRead(loggedInUserId);
  }, [otheruserID]);
  // useEffect(() => {

  //   fetchMessages();
  //   markChatNotificationsAsRead(loggedInUserId);
  // }, []);
  useEffect(() => {
    // Check if the user is blocked when the component mounts
    checkBlockStatus();
  }, []);

  useEffect(() => {
    scrollToBottom();

  }, [messages]);

  const handleEmojiClick = (emojiObject) => {
    const emojiToAdd = emojiObject.emoji;
    const cursorPosition = inputMessage.length;
    const updatedMessage =
      inputMessage.slice(0, cursorPosition) +
      emojiToAdd +
      inputMessage.slice(cursorPosition);
    setInputMessage(updatedMessage);
    setShowEmojiPicker(false);
  };

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  const handleToggleBlockOptions = () => {
    setShowBlockOptions((prevShowBlockOptions) => !prevShowBlockOptions);
  };
  const handleFileChange = (e) => {
    // setSelectedFile(e.target.files[0]); // Update selected file state when a file is selected
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setSelectedFileName(file.name);
      setInputMessage((prevMessage) => `${prevMessage} [${file.name}]`);
    }
  };

  const handleAttachmentClick = () => {
    document.getElementById('fileInput').click(); // Trigger file input click event when attachment icon is clicked
  };
  const fetchMessages = async () => {
    try {
      setLoading(true);
      const authToken = Cookies.get('authToken');
      const response = await axios.get(`${url}/api/messages/${otheruserID}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.data && Array.isArray(response.data.messages)) {
        setMessages(response.data.messages);
        // console.log(messages)
        setOtherUser(response.data.otherUser);
        // console.log(response.data.conversation)
        const conversation = response.data.conversation;
        setConversation(conversation);
        markChatNotificationsAsRead(loggedInUserId);

      } else {
        console.error('API response is missing messages array:', response.data.messages);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
      // Handle the error here, such as displaying an error message to the user
    } finally {
      setLoading(false)
    }
  };
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }

  };
  const checkBlockStatus = async () => {
    try {
      const authToken = Cookies.get('authToken');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user-info/check-block/${otheruserID}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setIsBlocked(response.data.blocked);
    } catch (error) {
      console.error('Error checking block status:', error);
    }
  };

  const handleBlockUser = async () => {
    try {
      const authToken = Cookies.get('authToken');
      const action = isBlocked ? 'unblock' : 'block';
      const response = await axios.post(
        `${url}/api/user-info/${action}/${otheruserID}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        setIsBlocked(!isBlocked);
        // Show appropriate message after blocking/unblocking
        const blockMessage = isBlocked ? 'User unblocked successfully.' : 'User blocked successfully.';
        setShowMessage(true);
        setMessage(blockMessage);
      }
    } catch (error) {
      console.error('Error blocking/unblocking user:', error);
    }
  };
  const sendMessage = async () => {
    if (inputMessage.trim() !== '' || selectedFile) {
      setIsSending(true);
      try {
        const authToken = Cookies.get('authToken');
        const formData = new FormData();
        formData.append('message', inputMessage + selectedEmoji);
        if (selectedFile) {
          formData.append('file', selectedFile);
        }

        const response = await axios.post(
          `${url}/api/messages/send/${otheruserID}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'multipart/form-data', // Set content type for FormData
            },
          }
        );

        // Assuming the backend responds with an error message when the user is blocked
        if (response.status === 403) {
          setShowMessage(true);
          setMessage('You are blocked by this user. You cannot send a message.');
        } else {
          const newMessage = response.data.newMessage;
          // Update message status in the database
          const updatedMessage = { ...newMessage, read: false };
          const updatedMessages = [...messages, updatedMessage];
          setMessages(updatedMessages);
          setInputMessage('');
          setSelectedFile(null); // Reset selected file after sending
          setSelectedFileName(''); // Reset file name after sending
          scrollToBottom(); // Optionally scroll to bottom after sending message
        }
      } catch (error) {
        setShowMessage(true);
        setMessage('You cannot send a message to this student.');
        console.error('Error sending message:', error);
      } finally {
        setIsSending(false);
      }
    } else {
      setShowMessage(true)
      setMessage('Please enter a message or upload a file!');
    }
  };

  const handleSendMessage = () => {
    if (isBlocked) {
      setShowMessage(true);
      setMessage('You cannot send messages to this user because they are blocked. Unblock to send message');
      return; // Exit the function if the user is blocked
    }
    sendMessage(); // Call sendMessage if the user is not blocked
  };
  const handleBackspace = (e) => {
    if (e.keyCode === 8) {
      const textarea = e.target;
      const selectionStart = textarea.selectionStart;
      const selectionEnd = textarea.selectionEnd;

      // If there is a selection, remove the selected text
      if (selectionStart !== selectionEnd) {
        const updatedMessage = inputMessage.slice(0, selectionStart) + inputMessage.slice(selectionEnd);
        setInputMessage(updatedMessage);
      } else {
        // If there is no selection, handle the emoji removal or single character removal
        if (selectedEmoji && inputMessage.endsWith(selectedEmoji)) {
          const updatedMessage = inputMessage.slice(0, -selectedEmoji.length);
          setInputMessage(updatedMessage);
          setSelectedEmoji('');
        } else if (inputMessage.length > 0) {
          const updatedMessage = inputMessage.slice(0, -1);
          setInputMessage(updatedMessage);
        }
      }
      e.preventDefault();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    handleSendMessage(); // Call sendMessage function when form is submitted
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };
  const keywords = ["elearning", "education", "online class", "student","teacher","learning"]; 
  return (
    <>
      {/* <Helmet>
        <title>Chat - Career Guidance Buddy</title>
        <meta name="keywords" content="chat with student,chat with teacher, education, online class" />
      </Helmet> */}
      <MetaDecorator title={'Chat - Career Guidance Buddy'} description={'Chat with teacher and resolve doubts!'} imageUrl={''} keywords={keywords} imageAlt={''} url={''}/>
      <section className="mb-0 sm:mb-0">
        <NavBar />
        <div className='sm:mt-16 lg:mt-20 flex flex-col gap-2 font-primary items-center min-h-fit sm:mx-auto sm:w-full sm:max-w-[1296px] sm:px-5'>
          <Header />
          {/* <ChatFixedMenu className='mt-[620px]' otherUser={otherUser} defaultProfile={defaultProfile} handleToggleBlockOptions={handleToggleBlockOptions} isOnline={onlineUsers.includes(otheruserID)}/> */}
          <div className="w-full md:grid grid-cols-3 gap-4 sm:min-h-[90vh] sm:py-[40px] justify-center">
            {/* student details section */}
            <SideBar />
            <div className="w-full sm:w-auto col-span-2 sm:h-full sm:rounded-[25px] md:rounded-[25px] sm:shadow-md min-h-full sm:mt-6 md:mt-0">
              {/* name back profilepic */}
              <div className="flex justify-center items-center mt-[160px] sm:mt-5 bg-white rounded-[50px] mx-2 drop-shadow h-16">
                <ChatFixedMenu otherUser={otherUser} defaultProfile={defaultProfile} handleToggleBlockOptions={handleToggleBlockOptions} isOnline={onlineUsers.includes(otheruserID)} />
                {/* Block options */}
                {showBlockOptions && (
                  <BlockUser onBlockUser={handleBlockUser} isBlocked={isBlocked} />
                )}
              </div>
              {/* display messages */}
              {loading ?
                (
                  <div>
                    <Loading2 />
                  </div>
                ) : (
                  <div className='overflow-y-auto py-2 w-11/12 h-screen sm:w-full mx-auto space-y-4 sm:px-2' style={{ maxHeight: 'calc(100vh - 300px)' }} ref={chatContainerRef}>
                    <div className="flex flex-col space-y-4">
                      {messages.map((message, index) => (
                        <div key={index} className={`flex flex-col space-y-2 text-xs md:text-base max-w-xs mx-2 order-2  ${message.senderId === loggedInUserId ? 'self-end' : 'self-start'}`}>
                          {message.documents && message.documents.length > 0 ? (
                            // If documents exist, display them
                            <div className={`flex flex-col w-10/12 sm:w-full ${message.senderId === loggedInUserId ? 'self-end' : 'self-start'} `} >
                              <div className='flex flex-row items-center gap-1'>
                                {message.senderId !== loggedInUserId && (
                                  <img src={otherUser.profilePic !== "" && otherUser.profilePic !== undefined ? otherUser.profilePic : defaultProfile} alt="Other user" className="w-6 md:w-8 md:h-8 h-6 rounded-full order-1" />
                                )}
                                {message.documents.map((document, docIndex) => (
                                  <div key={docIndex} className='flex flex-row items-end gap-2 order-last bg-[#f1f1f1] rounded-2xl p-2' ref={chatContainerRef}>
                                    {document.url.match(/\.(jpg|jpeg|png|svg|gif|bmp|webp|tiff|ico)$/i) ? (
                                      // If it's an image, display it with an option to expand
                                      <div>
                                        <img
                                          src={document.url}
                                          alt={document.filename}
                                          onClick={() => window.open(document.url, '_blank')}
                                          className="cursor-pointer rounded-2xl max-w-full h-auto"
                                        />
                                      </div>
                                    ) : (
                                      // Handle other file types as needed
                                      <a href={document.url} download target='_blank' rel='noopener noreferrer'>
                                        {document.filename}
                                      </a>
                                    )}
                                    {message.senderId === loggedInUserId && (
                                      <BsCheck2All className={`w-[14px] h-[14px] sm:w-[16px] sm:h-[16px] self-end ${message.read ? 'text-blue-500' : ''}`} />
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : (
                            // If no documents, display the message
                            <div className='flex flex-row items-center gap-1'>
                              {message.senderId !== loggedInUserId && (
                                <img src={otherUser.profilePic !== "" && otherUser.profilePic !== undefined ? otherUser.profilePic : defaultProfile} alt="Other user" className="w-6 md:w-8 md:h-8 h-6 rounded-full order-1" />
                              )}
                              <div className={`flex flex-row items-center mx-1 px-3 py-2 sm:py-3 font-medium gap-1 rounded-[25px] inline-block order-last ${message.senderId === loggedInUserId ? ' bg-[#e4e4e4] text-[#585858] pl-[20px] rounded-br-none' : 'bg-[#f1f1f1] rounded-bl-none text-[#2c2c2c] text-left'}`}>
                                <span className=''>
                                  {message.message}
                                </span>
                                {message.senderId === loggedInUserId && (
                                  <BsCheck2All className={`w-[14px] h-[14px] sm:w-[16px] sm:h-[16px] self-end ${message.read ? 'text-blue-500' : ''}`} />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>)}
              {/* send message */}
              <form onSubmit={handleSubmit} action={`${url}/api/messages/send/${otheruserID}`} method="POST" encType="multipart/form-data" className="fixed bottom-0 w-full sm:w-full sm:static px-4 sm:px-4 pt-2 bg-white sm:bg-transparent mt-0 sm:mt-1 flex gap-0.5 sm:gap-3 md:gap-4 items-center justify-center w-full sm:w-full sm:static fixed sm:bottom-20 mb-2 sm:mb-0 md:mb-0">
                <div className="relative flex items-center sm:gap-2 w-11/12 h-12 sm:h-[56px] px-0 sm:px-4 bg-[#f1f1f1] py-2 sm:py-2 rounded-full">
                  <span className="inset-y-0 flex items-center">
                    <div className="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-gray-500 focus:outline-none">
                      <FaPaperclip size={16} className='text-2xl sm:text-3xl sm:w-5 sm:h-5 cursor-pointer md:text-3xl text-[#afafaf]' title='upload file' onClick={handleAttachmentClick} />
                      <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} />
                    </div>
                  </span>
                  <textarea type="text"
                    placeholder="Message"
                    className="flex-grow items-center py-3 h-12 sm:h-14 sm:py-4 text-[13px] sm:text-[16px] focus:outline-none focus:placeholder-[#afafaf] text-[#2c2c2c] font-medium placeholder-[#afafaf] bg-transparent"
                    value={inputMessage}
                    onChange={handleInputChange}
                    // onKeyDown={handleBackspace}
                    onKeyPress={handleKeyPress}
                    disabled={isSending} />
                  <div className="absolute sm:static right-0 items-center inset-y-0 flex">
                    <button type="button"
                      className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 focus:outline-none"
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                      disabled={isSending}>
                      <AiOutlineSmile className='text-2xl md:text-3xl text-[#afafaf] mr-2 sm:mr-0' />
                    </button>
                  </div>
                </div>
                <button type="submit" className="inline-flex items-center justify-center rounded-lg pl-1 sm:pl-0 py-2 transition duration-500 ease-in-out bg-white">
                  {isSending ? (
                    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-orange-500" role="status"></div>
                  ) : (
                    <IoIosSend className="text-[#ff9e48] w-12 md:w-12 h-10 md:h-[63px] m-0 p-0" title='send message' />
                  )}
                </button>
              </form>
              {showEmojiPicker && (
                <EmojiPicker
                  onEmojiClick={handleEmojiClick}
                  disableSearchBar={true}
                  disableSkinTonePicker={false}
                  native
                  emojiStyle=""
                  style={{ position: 'absolute', bottom: '60px' }}
                />
              )}
            </div>
          </div>
        </div>
        {/* <MainMenu /> */}
        {/* <FooterMain/> */}
      </section>
    </>
  );
}
export default ChatOpen;


