import React, { useState, useEffect } from 'react';
import {
  BsPersonCircle, BsFillCalendar2CheckFill, BsEmojiSmileFill,
  BsEmojiNeutral, BsEmojiWink, BsFillEmojiNeutralFill, BsEmojiSmile,
  BsFillEmojiWinkFill, BsFillEmojiSmileFill, BsEmojiFrown,
  BsEmojiFrownFill, BsFillEmojiLaughingFill, BsEmojiLaughing,
  BsCalendarDateFill
} from "react-icons/bs";
import { IoMdTime } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import Cookies from 'js-cookie';
import axios from "axios";
import Loading2 from '../../Loading2';
import Empty_data3 from '../../Empty_data3';
import moment from 'moment-timezone';

const FinishedEventsCall = () => {
  const [cardsData, setCardsData] = useState([]);
  const [cardStates, setCardStates] = useState({});
  const authToken = Cookies.get("authToken");
  const user_type = Cookies.get('user_type');
  const loggedInUserId = Cookies.get('loggedInUserId');
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [user_data, setUser_data] = useState('');
  const user_loggedInId = Cookies.get('loggedInUserId');
  const [user_time, setUser_time] = useState('');

  const handleEmojiClick = (cardId, selectedRating) => {
    setCardStates(prevState => ({
      ...prevState,
      [cardId]: { ...prevState[cardId], rating: selectedRating }
    }));
  };

  const handleReviewChange = (cardId, review) => {
    setCardStates(prevState => ({
      ...prevState,
      [cardId]: { ...prevState[cardId], review }
    }));
  };

  const handleSubmit = async (cardId) => {
    const { rating, review } = cardStates[cardId] || {};
    if (!rating || !review) {
      alert('Please select a rating and write a review.');
      return;
    }

    if (rating <= 3) {
      alert('Reviews with a rating of 3 or less will be marked as pending and require approval.');
    }
    try {
      const response = await axios.post(`${apiUrl}/api/call_review/rating_review`, {
        callId: cardId,
        rating,
        review,
        loggedInUserId
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });

      // console.log('Review posted successfully:', response.data);

      setCardStates(prevState => ({
        ...prevState,
        [cardId]: { rating: '', review: '' }
      }));
      setLoading(true);
      FetchConferenceDetails();

    } catch (error) {
      console.error('Error posting review:', error);
    }
  };

  useEffect(() => {
    fetch_user_Details();
    FetchConferenceDetails();
  }, []);

  const emojiComponents = {
    '1': <BsEmojiFrownFill className="text-orange-500" />,
    '2': <BsFillEmojiNeutralFill className="text-orange-500" />,
    '3': <BsFillEmojiWinkFill className="text-orange-500" />,
    '4': <BsEmojiSmileFill className="text-orange-500" />,
    '5': <BsFillEmojiLaughingFill className="text-orange-500" />,
  };

  const fetch_user_Details = async () => {
    try {
      const user_details = await axios.get(`${apiUrl}/api/auth/user_details/${user_loggedInId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      const user = user_details.data;
      console.log(user);
      setUser_data(user);
      setUser_time(user.user_Details.time_zone);
      console.log("Time Zone", user_time);
    } catch (error) {
      console.error('Server Error:', error.response.data);
    }
  };

  const FetchConferenceDetails = async () => {
    try {
      const endpoint = user_type === 'Teacher'
        ? `${apiUrl}/api/call/upcoming_allbooked_calls_teacher`
        : `${apiUrl}/api/call/upcoming_booked_calls`;
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      const data = response.data;
      console.log(data);

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      console.log("Today's date:", today);

      const filteredData = data.filter((conference) => {
        const conferenceEndTime = new Date(conference.Call_Date);
        const [endHour, endMinute] = conference.endTime.split(':').map(Number);
        conferenceEndTime.setHours(endHour, endMinute, 0, 0);
        const now = new Date();
        return conferenceEndTime < now;
      }).sort((a, b) => {
        const aEndTime = new Date(a.Call_Date);
        const [aEndHour, aEndMinute] = a.endTime.split(':').map(Number);
        aEndTime.setHours(aEndHour, aEndMinute, 0, 0);
        const bEndTime = new Date(b.Call_Date);
        const [bEndHour, bEndMinute] = b.endTime.split(':').map(Number);
        bEndTime.setHours(bEndHour, bEndMinute, 0, 0);
        return bEndTime - aEndTime;
      });

      console.log("Filtered Data for finished calls:", filteredData);

      let updatedData;
      if (user_type === 'Teacher') {
        updatedData = await Promise.all(filteredData.map(async (call) => {
          const callId = call._id;
          const data = { id: callId };
          const call_comments = await axios.post(`${apiUrl}/api/call_review/eventReview/comments`, data, {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          });
          const comments = call_comments.data;
          return {
            ...call,
            Call_Date: formatDateString(call.Call_Date),
            comments: comments,
          };
        }));
        setCardsData(updatedData);
        console.log('Finished calls details fetched in Teacher page:', updatedData);
      }

      if (user_type === 'Student') {
        updatedData = await Promise.all(filteredData.map(async (call) => {
          const callId = call._id;
          const studentId = loggedInUserId;
          const reviewResponse = await axios.get(`${apiUrl}/api/call_review/eventReview/${callId}/${studentId}`);
          const reviewData = reviewResponse.data;
          const reviewedByStudent = reviewData.submitted;
          const existingReview = reviewData.review;
          return {
            ...call,
            Call_Date: formatDateString(call.Call_Date),
            reviewedByStudent,
            existingReview,
            // startTime: formattedStartTime,
            // endTime: formattedEndTime,
          };
        }));
        setCardsData(updatedData);
        console.log('Finished calls details fetched in Student page:', updatedData);
        await console.log(cardsData)
      }
    } catch (error) {
      console.error('Error fetching conference details:', error);
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  const formatTime = (startTime, endTime) => {
    //console.log("Start time before conversion ==>", startTime);
    const startParts = startTime.split(':').map(part => parseInt(part));
    const startDate = new Date();
    startDate.setHours(startParts[0], startParts[1], 0, 0);


    const startOptions = { timeZone: user_time, hour12: false, hour: '2-digit', minute: '2-digit' };
    const formattedStartTime = new Intl.DateTimeFormat('en-US', startOptions).format(startDate);

    //console.log("End time before conversion ==>", endTime);
    let formattedEndTime = null;
    if (endTime) {
      const endParts = endTime.split(':').map(part => parseInt(part));
      const endDate = new Date();
      endDate.setHours(endParts[0], endParts[1], 0, 0);


      const endOptions = { timeZone: user_time, hour12: false, hour: '2-digit', minute: '2-digit' };
      formattedEndTime = new Intl.DateTimeFormat('en-US', endOptions).format(endDate);
      //console.log(user_time);
      //console.log("Start and end time after conversion ==>", formattedStartTime, formattedEndTime);
    }

    return `${formattedStartTime} - ${formattedEndTime}`;
  };

  // Inside your JSX:

  const formatDateString = (dateString) => {
    const eventDate = new Date(dateString);
    const options = { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = eventDate.toLocaleDateString('en-GB', options).replace(/\//g, '-');
    return formattedDate;
  };

  const renderCards = () => {
    return cardsData.map((event) => {
      const { rating, review } = cardStates[event._id] || {};
      return (
        <div className="overflow-y-auto w-full lg:w-full flex flex-col items-center font-primary" style={{ maxHeight: 'calc(100vh - 140px)' }}>
          <div
            key={event._id}
            className="rounded-[40px] p-[20px] mb-6 mt-1 flex flex-col w-[95%] sm:w-[85%] lg:w-[65%] lg:mr-2"
            style={{ boxShadow: '0px 2px 6px 0px #00000040' }}
          >
            <div className="pb-[20px]">
              <div className="flex items-center pb-[8px]">
                <BsPersonCircle className='text-orange-400 text-[1.2rem]' />
                <p className="pl-[5px] text-sm sm:text-base font-normal text-[#585858]">
                  {user_type === 'Student' ? (<div>
                    Organizer Name: {event.userName}
                  </div>) : (<div>
                    Booked Student Name: {event.userName}
                  </div>)}
                </p>
              </div>
              <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 sm:justify-between mt-3">
                <div className="flex items-center">
                  <BsCalendarDateFill className='text-orange-400 text-[1.2rem]' />
                  <p className="pl-[5px] text-sm sm:text-base font-normal text-[#585858] pr-[8px]">
                    Date: {event.Call_Date}
                  </p>
                </div>
                <div className="flex items-center">
                  <IoMdTime className='text-orange-400 text-[1.2rem]' />
                  <p className="pl-[5px] text-sm sm:text-base font-normal text-[#585858]">
                    {user_type === 'Student' ? (
                      <div>
                        {`Time: ${formatTime(event.startTime, event.endTime)}`}
                      </div>
                    ) : (<div>
                      Time : {event.startTime} - {event.endTime}
                    </div>)}

                  </p>
                </div>
              </div>
            </div>
            {user_type === 'Teacher' ? (
              <div>
                {event.comments && event.comments.reviews[0].approved ? (
                  <div className='shadow-xl rounded-full w-full flex gap-2 items-center p-2 mt-2 h-16 overflow-y-auto'>
                    <div className='border-2 border-orange-600 rounded-3xl flex items-center justify-center text-gradient text-md font-semibold gap-2 py-2 px-3 w-20 h-12'>
                      {event.comments.reviews[0].rating}.0
                      {emojiComponents[event.comments.reviews[0].rating]}
                    </div>
                    <div className='overflow-y-auto' style={{ maxWidth: 'calc(100vh -80px)' }}>
                      {event.comments.reviews[0].review}
                    </div>
                  </div>
                ) : (
                  <div className='flex justify-center text-gradient text-md font-semibold'>No comments</div>
                )}
              </div>
            ) : (
              <div>
                {!event.reviewedByStudent ? (
                  <div className='flex justify-center'>
                    <div className='flex flex-col gap-2 justify-center items-center'>
                      <span className='text-sm sm:text-base text-[#585858]'>Rate your experience</span>
                      <div className='flex gap-2'>
                        <div className="emoji-container" onClick={() => handleEmojiClick(event._id, 1)}>
                          {rating === 1 ? (
                            <BsEmojiFrownFill className="text-orange-500 icon text-sm sm:text-base lg:text-lg" />
                          ) : (
                            <BsEmojiFrown className="text-orange-500 icon cursor-pointer text-sm sm:text-base lg:text-lg" />
                          )}
                        </div>
                        <div className="emoji-container" onClick={() => handleEmojiClick(event._id, 2)}>
                          {rating === 2 ? (
                            <BsFillEmojiNeutralFill className='text-orange-500 icon text-sm sm:text-base lg:text-lg' />
                          ) : (
                            <BsEmojiNeutral className='text-orange-500 icon cursor-pointer text-sm sm:text-base lg:text-lg' />
                          )}
                        </div>
                        <div className='emoji-container' onClick={() => handleEmojiClick(event._id, 3)}>
                          {rating === 3 ? (
                            <BsFillEmojiWinkFill className='text-orange-500 icon text-sm sm:text-base lg:text-lg' />
                          ) : (
                            <BsEmojiWink className='text-orange-500 icon cursor-pointer text-sm sm:text-base lg:text-lg' />
                          )}
                        </div>
                        <div className='emoji-container' onClick={() => handleEmojiClick(event._id, 4)}>
                          {rating === 4 ? (
                            <BsEmojiSmileFill className='text-orange-500 icon text-sm sm:text-base lg:text-lg' />
                          ) : (
                            <BsEmojiSmile className='text-orange-500 icon cursor-pointer text-sm sm:text-base lg:text-lg' />
                          )}
                        </div>
                        <div className='emoji-container' onClick={() => handleEmojiClick(event._id, 5)}>
                          {rating === 5 ? (
                            <BsFillEmojiLaughingFill className='text-orange-500 icon text-sm sm:text-base lg:text-lg' />
                          ) : (
                            <BsEmojiLaughing className='text-orange-500 icon cursor-pointer text-sm sm:text-base lg:text-lg' />
                          )}
                        </div>
                      </div>
                      <div
                        name="review"
                        className="appearance-none border border-[#585858] text-[#585858] text-sm sm:text-base rounded-full px-[15px] py-[10px] sm:w-96 w-56"
                      >
                        <input
                          type="text"
                          className="w-full"
                          placeholder="Write your review"
                          value={review || ''}
                          onChange={(e) => handleReviewChange(event._id, e.target.value)}
                        />
                      </div>
                      <div
                        className="group relative flex items-center border-2 border-orange-600 rounded-3xl py-2 px-4 bg-transparent text-orange-500 hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] transition hover:border-none cursor-pointer"
                        onClick={() => handleSubmit(event._id)}
                      >
                        <div className="absolute left-4 flex items-center justify-center bg-orange-500 rounded-full p-2 h-8 w-8 opacity-100 group-hover:opacity-0 transition-opacity">
                          <TiTick className="text-white" style={{ fontSize: '20px' }} />
                        </div>
                        <div className="absolute left-4 flex items-center justify-center bg-white rounded-full p-2 h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity">
                          <TiTick className="text-orange-500" style={{ fontSize: '20px' }} />
                        </div>
                        <span className='ml-10'>Post Review</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='flex justify-center flex-col items-center gap-2'>
                    <span className='text-gradient font-semibold'>{event.existingReview.reviews[0] && event.existingReview.reviews[0].approved ? 'Submitted Review' : 'Pending Approval for review submitted'}</span>
                    {event.existingReview.reviews[0] && event.existingReview.reviews[0].approved &&
                      <div className='shadow-xl rounded-full w-full flex gap-2 items-center p-2'>
                        <div className='border-2 border-orange-600 rounded-3xl flex items-center justify-center text-gradient text-md font-semibold gap-2 py-2 px-3 w-20 h-12'>
                          {event.existingReview.reviews[0].rating}.0
                          {emojiComponents[event.existingReview.reviews[0].rating]}
                        </div>
                        <div className='overflow-y-auto' style={{ maxWidth: 'calc(100vh -80px)' }}>
                          {event.existingReview.reviews[0].review}
                        </div>
                      </div>}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  if (loading) {
    return <Loading2 />; // Add loading state if needed
  }

  if (hasError || cardsData.length === 0) {
    return <Empty_data3 />;
  }

  return (
    <div>
      {cardsData.length > 0 && renderCards()}
    </div>
  );
};

export default FinishedEventsCall;

