import React, { useState, useEffect } from 'react';
import {
  BsPersonCircle, BsCalendarDateFill, BsEmojiSmileFill,
  BsEmojiNeutral, BsEmojiWink, BsFillEmojiNeutralFill, BsEmojiSmile,
  BsFillEmojiWinkFill, BsFillEmojiSmileFill, BsEmojiFrown,
  BsEmojiFrownFill, BsFillEmojiLaughingFill, BsEmojiLaughing
} from "react-icons/bs";
import { SiGoogleclassroom } from "react-icons/si";
import { IoMdTime } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import Cookies from 'js-cookie';
import axios from "axios";
import Loading2 from '../../Loading2'
import Empty_data2 from '../../Empty_data2';
import moment from 'moment-timezone';

const FinishedEventsConference = () => {

  const [cardsData, setCardsData] = useState([]);
  const [cardStates, setCardStates] = useState({});
  const authToken = Cookies.get("authToken");
  const user_type = Cookies.get('user_type');
  const loggedInUserId = Cookies.get('loggedInUserId');
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [user_data, setUser_data] = useState('');

  const [user_time, setUser_time] = useState('')


  const apiUrl = process.env.REACT_APP_API_URL;

  const handleEmojiClick = (cardId, selectedRating) => {
    setCardStates(prevState => ({
      ...prevState,
      [cardId]: { ...prevState[cardId], rating: selectedRating }
    }));
  };

  const handleReviewChange = (cardId, review) => {
    setCardStates(prevState => ({
      ...prevState,
      [cardId]: { ...prevState[cardId], review }
    }));
  };

  const handleSubmit = async (cardId) => {
    const { rating, review } = cardStates[cardId] || {};
    if (!rating || !review) {
      alert('Please select a rating and write a review.');
      return;
    }
    if (rating <= 3) {
      alert('Reviews with a rating of 3 or less will be marked as pending and require approval.');
    }

    try {
      const response = await axios.post(`${apiUrl}/api/eventReview/rating_review`, {
        conferenceId: cardId,
        rating,
        review,
        loggedInUserId
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });

      // console.log('Review posted successfully:', response.data);

      setCardStates(prevState => ({
        ...prevState,
        [cardId]: { rating: '', review: '' }
      }));
      setLoading(true)
      setCardsData()
      FetchConferenceDetails();

    } catch (error) {
      console.error('Error posting review:', error);
      //setHasError(true)
    }
  };

  useEffect(() => {
    fetch_user_Details();
    FetchConferenceDetails();
  }, []);

  const emojiComponents = {
    '1': <BsEmojiFrownFill className="text-orange-500" />,
    '2': <BsFillEmojiNeutralFill className="text-orange-500" />,
    '3': <BsFillEmojiWinkFill className="text-orange-500" />,
    '4': <BsEmojiSmileFill className="text-orange-500" />,
    '5': <BsFillEmojiLaughingFill className="text-orange-500" />,
  };


  const fetch_user_Details = async () => {
    try {

      const user_details = await axios.get(`${apiUrl}/api/auth/user_details/${loggedInUserId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      })
      const user = user_details.data;
      console.log(user);
      console.log("Name", user.user.fullName)
      console.log("Eamil", user.user.email)
      setUser_data(user);
      setUser_time(user.user_Details.time_zone);
      console.log("Time Zone", user_time)


    } catch (error) {
      console.error('Server Error:', error.response.data);
    }
  }

  const FetchConferenceDetails = async () => {
    try {
      let data;
      if (user_type === 'Teacher') {
        const response = await axios.get(`${apiUrl}/api/conference/getConferenceDetails`, {
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
        });
        data = response.data;
      } else {
        const response = await axios.post(`${apiUrl}/api/conference/upcoming_booked_conferences`, { studentId: loggedInUserId }, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        });
        data = response.data;
      }
      console.log("Conference Details before filter in Finished  ===> ", data)
      if (user_type !== 'Teacher') {
        const detailedData = await Promise.all(data.map(async (conference) => {
          const conference_id = conference.students[0].conferenceId;
          console.log("confernceId", conference_id)
          const detailResponse = await axios.post(`${apiUrl}/api/conference/getdetails`, { conference_id }, {
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          });
          return detailResponse.data;
        }));

        data = detailedData;
      }
      console.log("Conference Details after fetching details of each conference in Finished  ===> ", data)



      const now = new Date();
      let filteredData;

      if (user_type === 'Teacher') {
        filteredData = data.filter((conference) => {
          const conferenceDate = new Date(conference.Conference_Date);
          console.log(conferenceDate);

          if (!conference.Start_Time || !conference.End_Time) {
            console.error('Start_Time or End_Time is undefined for conference:', conference);
            return false;
          }

          const [startHour, startMinute] = conference.Start_Time.split(':').map(Number);
          const [endHour, endMinute] = conference.End_Time.split(':').map(Number);


          const startTime = new Date(conferenceDate);
          startTime.setHours(startHour, startMinute, 0, 0);

          const endTime = new Date(conferenceDate);
          endTime.setHours(endHour, endMinute, 0, 0);

          return endTime < now;
        });

        console.log("Filtered Data ==>", filteredData);
      } else {
        filteredData = data.filter((conference) => {

          const conferenceData = conference.conferenceData;
          if (!conferenceData) {
            console.error('conferenceData is undefined for conference:', conference);
            return false;
          }

          console.log("Inside Filter  ==>", conferenceData);

          const conferenceDate = new Date(conferenceData.Conference_Date);
          console.log(conferenceDate);

          if (!conferenceData.Start_Time || !conferenceData.End_Time) {
            console.error('Start_Time or End_Time is undefined for conference:', conferenceData);
            return false;
          }

          const [startHour, startMinute] = conferenceData.Start_Time.split(':').map(Number);
          const [endHour, endMinute] = conferenceData.End_Time.split(':').map(Number);


          const startTime = new Date(conferenceDate);
          startTime.setHours(startHour, startMinute, 0, 0);

          const endTime = new Date(conferenceDate);
          endTime.setHours(endHour, endMinute, 0, 0);

          return endTime < now;
        });

        console.log("Filtered Data ==>", filteredData);

      }
      if (user_type === 'Teacher') {
        console.log("filtering or sorting ==>", filteredData)
        filteredData.sort((a, b) => new Date(b.Conference_Date) - new Date(a.Conference_Date));
      } else {
        filteredData.sort((a, b) => new Date(b.conferenceData.Conference_Date) - new Date(a.conferenceData.Conference_Date));
      }

      if (user_type === 'Teacher') {
        console.log("Inside Teacher")
        console.log("user_type", user_type)
        const updatedData = await Promise.all(filteredData.map(async (conference) => {
          const conferenceId = conference._id;
          console.log(conferenceId)
          const data = { id: conferenceId };
          const conference_comments = await axios.post(`${apiUrl}/api/eventReview/comments`, data, {

            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          })
          const comments = conference_comments.data;
          console.log(comments)
          return {
            ...conference,
            Conference_Date: formatDateString(conference.Conference_Date),
            comments: comments
          };

        }));
        setCardsData(updatedData);
        console.log('Finished conference details  fetched with comments:', updatedData);

      } if (user_type === 'Student') {
        console.log("Inside Student")
        const updatedData = await Promise.all(filteredData.map(async (conference) => {
          console.log(conference.conferenceData)
          const conferenceId = conference.conferenceData._id;
          console.log("ConferenceId inside filtered Data ==>", conferenceId)
          const studentId = loggedInUserId;
          const reviewResponse = await axios.get(`${apiUrl}/api/eventReview/${conferenceId}/${studentId}`);
          const reviewData = reviewResponse.data;

          const reviewedByStudent = reviewData.submitted;
          const existingReview = reviewData.review;


          return {
            ...conference,
            Conference_Date: formatDateString(conference.Conference_Date),
            reviewedByStudent,
            existingReview,

          };
        }));
        setCardsData(updatedData);
        console.log('Finished conference details fetched:', updatedData);
      }

    } catch (error) {
      console.error('Error fetching finished conference details:', error);
    } finally {
      setLoading(false);
    }
  };
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatDateString = (dateString) => {
    const eventDate = new Date(dateString);
    const options = { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = eventDate.toLocaleDateString('en-GB', options).replace(/\//g, '-');
    return formattedDate;
  };

  const formatTime = (startTime, endTime) => {
    // Parse start time
    console.log(startTime, endTime)
    const startParts = startTime.split(':').map(part => parseInt(part));
    const startDate = new Date();
    startDate.setHours(startParts[0], startParts[1], 0, 0);

    // Format start time in user's time zone
    const startOptions = { timeZone: user_time, hour12: false, hour: '2-digit', minute: '2-digit' };
    const formattedStartTime = startDate.toLocaleTimeString('en-US', startOptions);

    // Parse end time if available
    let formattedEndTime = null;
    if (endTime) {
      const endParts = endTime.split(':').map(part => parseInt(part));
      const endDate = new Date();
      endDate.setHours(endParts[0], endParts[1], 0, 0);

      // Format end time in user's time zone
      const endOptions = { timeZone: user_time, hour12: false, hour: '2-digit', minute: '2-digit' };
      formattedEndTime = endDate.toLocaleTimeString('en-US', endOptions);
    }

    return `${formattedStartTime} - ${formattedEndTime}`;
  };

  const renderCards = () => {
    return cardsData.map((event) => {
      const { rating, review } = cardStates[event.conferenceData?._id] || {};
      return (
        <div className="overflow-y-auto w-[97%] lg:w-full flex flex-col items-center ml-1 mr-1 font-primary" style={{ maxHeight: 'calc(100vh - 140px)' }}>
          <div
            key={event.conferenceData?._id}
            className="rounded-[40px] p-[20px] mb-6 mt-1 flex flex-col w-[95%] sm:w-[85%] lg:w-[65%] lg:mr-2"
            style={{ boxShadow: '0px 2px 6px 0px #00000040' }}
          >
            <div className="pb-[20px]">
              <div className="flex items-center ">
                <SiGoogleclassroom className='text-orange-400 sm:text-[1.2rem] text-[1rem]' />
                <p className="pl-[5px] text-sm sm:text-base font-normal text-[#585858]">
                  {user_type === 'Student' ? (
                    <div>Title: {event.conferenceData.Title}</div>
                  ) : (
                    <div>Title: {event.Title}</div>
                  )}
                </p>
              </div>
              {user_type === 'Student' ? (
                <div className="flex items-center mt-3">
                  <BsPersonCircle className='text-orange-400 sm:text-[1.2rem] text-[1rem]' />
                  <p className="pl-[5px] text-sm sm:text-base font-normal text-[#585858]">

                    <div>Teacher Name: {event.conferenceData.userId.fullName}</div>

                  </p>
                </div>) : ('')
              }
              <div className="flex sm:flex-row flex-col gap-1 sm:gap-0 sm:justify-between mt-3">
                <div className="flex items-center">
                  <BsCalendarDateFill className='text-orange-400 sm:text-[1.2rem] text-[1rem]' />
                  <p className="pl-[5px] text-sm sm:text-base font-normal text-[#585858] pr-[8px]">
                    {user_type === 'Teacher' ?
                      `Date: ${event.Conference_Date}` :
                      `Date: ${formatDateString(event.conferenceData.Conference_Date)}`
                    }

                  </p>
                </div>
                <div className="flex items-center">
                  <IoMdTime className='text-orange-400 sm:text-[1.2rem] text-[1rem]' />
                  <p className="pl-[5px] text-sm sm:text-base font-normal text-[#585858]">
                    {user_type === 'Teacher' ?
                      `Time: ${event.Start_Time} - ${event.End_Time}` :
                      `Time: ${formatTime(event.conferenceData.Start_Time, event.conferenceData.End_Time)}`
                    }
                  </p>
                </div>
              </div>
            </div>
            {user_type === 'Teacher' ? (
              <div>
                {event.comments && event.comments.length > 0 ? (
                  event.comments.map((comment, index) => (
                    comment.reviews && comment.reviews.length > 0 ? (
                      comment.reviews.map((review, reviewIndex) => (
                        <div key={review._id || reviewIndex} className='shadow-xl rounded-full w-full flex gap-2 items-center p-2 mt-2 h-16 overflow-y-auto'>
                          <div className='border-2 border-orange-600 rounded-3xl flex items-center justify-center text-gradient text-md font-semibold gap-2 py-2 px-3 w-20 h-12'>
                            {review.rating}.0
                            {emojiComponents[review.rating]}
                          </div>
                          <div className='overflow-y-auto' style={{ maxWidth: 'calc(100vh -80px)' }}>
                            {review.review}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div key={index} className='flex justify-center text-gradient text-md font-semibold'>No comments</div>
                    )
                  ))
                ) : (
                  <div className='flex justify-center text-gradient text-md font-semibold'>No comments</div>
                )}
              </div>
            ) : (
              <div>
                {!event.reviewedByStudent ? (
                  <div className='flex justify-center'>
                    <div className='flex flex-col gap-2 justify-center items-center'>
                      <p className="text-sm sm:text-base font-normal text-[#585858]">Rate your experience</p>
                      <div className='flex gap-2'>
                        <div className="emoji-container" onClick={() => handleEmojiClick(event.conferenceData?._id, 1)}>
                          {rating === 1 ? (
                            <BsEmojiFrownFill className="text-orange-500 icon text-sm sm:text-base lg:text-lg " />
                          ) : (
                            <BsEmojiFrown className="text-orange-500 icon cursor-pointer text-sm sm:text-base lg:text-lg" />
                          )}
                        </div>
                        <div className="emoji-container" onClick={() => handleEmojiClick(event.conferenceData?._id, 2)}>
                          {rating === 2 ? (
                            <BsFillEmojiNeutralFill className='text-orange-500 icon text-sm sm:text-base lg:text-lg ' />
                          ) : (
                            <BsEmojiNeutral className='text-orange-500 icon cursor-pointer text-sm sm:text-base lg:text-lg ' />
                          )}
                        </div>
                        <div className='emoji-container' onClick={() => handleEmojiClick(event.conferenceData?._id, 3)}>
                          {rating === 3 ? (
                            <BsFillEmojiWinkFill className='text-orange-500 icon text-sm sm:text-base lg:text-lg ' />
                          ) : (
                            <BsEmojiWink className='text-orange-500 icon cursor-pointer text-sm sm:text-base lg:text-lg ' />
                          )}
                        </div>
                        <div className='emoji-container' onClick={() => handleEmojiClick(event.conferenceData?._id, 4)}>
                          {rating === 4 ? (
                            <BsEmojiSmileFill className='text-orange-500 icon text-sm sm:text-base lg:text-lg ' />
                          ) : (
                            <BsEmojiSmile className='text-orange-500 icon cursor-pointer text-sm sm:text-base lg:text-lg ' />
                          )}
                        </div>
                        <div className='emoji-container' onClick={() => handleEmojiClick(event.conferenceData?._id, 5)}>
                          {rating === 5 ? (
                            <BsFillEmojiLaughingFill className='text-orange-500 icon text-sm sm:text-base lg:text-lg ' />
                          ) : (
                            <BsEmojiLaughing className='text-orange-500 icon cursor-pointer text-sm sm:text-base lg:text-lg ' />
                          )}
                        </div>
                      </div>
                      <div
                        name="review"
                        className="appearance-none border border-[#585858] text-[#585858] text-sm rounded-full px-[15px] py-[10px] sm:w-96 w-56"
                      >
                        <input
                          type="text"
                          className="w-full text-sm sm:text-base  "
                          placeholder="Write your review"
                          value={review || ''}
                          onChange={(e) => handleReviewChange(event.conferenceData?._id, e.target.value)}
                        />
                      </div>
                      <div
                        className="group relative flex items-center border-2 border-orange-600 rounded-3xl py-2 px-4 bg-transparent text-orange-500 hover:text-white hover:bg-gradient-to-r from-[#ff6535] via-[#ff9e48] to-[#ffce58] transition hover:border-none cursor-pointer"
                        onClick={() => handleSubmit(event.conferenceData?._id)}
                      >
                        <div className="absolute left-4 flex items-center justify-center bg-orange-500 rounded-full p-2  opacity-100 group-hover:opacity-0 transition-opacity">
                          <TiTick className="text-white" style={{ fontSize: '20px' }} />
                        </div>
                        <div className="absolute left-4 flex items-center justify-center bg-white rounded-full p-2  opacity-0 group-hover:opacity-100 transition-opacity">
                          <TiTick className="text-orange-500" style={{ fontSize: '20px' }} />
                        </div>
                        <span className='ml-10 font-semibold text-gradient'>Post Review</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='flex justify-center flex-col items-center gap-2'>
                    <span className='text-gradient font-semibold'>{event.existingReview.reviews[0] && event.existingReview.reviews[0].approved ? 'Submitted Review' : 'Pending Approval for review submitted'}</span>
                    {event.existingReview.reviews[0] && event.existingReview.reviews[0].approved &&
                      <div className='shadow-xl rounded-full w-full flex gap-2 items-center p-2'>
                        <div className='border-2 border-orange-600 rounded-3xl flex items-center justify-center text-gradient text-md font-semibold gap-2 py-2 px-3 w-20 h-12'>
                          {event.existingReview.reviews[0].rating}.0
                          {emojiComponents[event.existingReview.reviews[0].rating]}
                        </div>
                        <div className='overflow-y-auto text-sm sm:text-base lg:text-lg' style={{ maxWidth: 'calc(100vh -80px)' }}>
                          {event.existingReview.reviews[0].review}
                        </div>
                      </div>
                    }
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  if (loading) {
    return <Loading2 />
  }

  if (hasError || cardsData.length === 0) {
    // eslint-disable-next-line react/jsx-pascal-case
    return <Empty_data2 />
  }

  return (
    <div>
      {cardsData.length !== 0 && renderCards()}
    </div>
  );
};

export default FinishedEventsConference;


